import { ReviewChatbotFinding } from "@coherehealth/core-platform-api";
import { IReviewChatbotFindingHolder } from "components/ClinicalReview/Review/hooks/useAutomatedReviewChatbotFindings";
import { isGuardrailsFinding } from "components/ClinicalReview/reviewUtils/chatbotWebsocketTypeHelpers";
import ChatbotIncomingGuardrailsResponse from "./ChatbotIncomingGuardrailsResponse";
import ChatbotIncomingRegularResponse from "./ChatbotIncomingRegularResponse";

interface Props {
  setFeedback: (id: string, updatedChatbotFinding: Partial<ReviewChatbotFinding>) => void;
  findingHolder: IReviewChatbotFindingHolder;
}

export default function ChatbotSynchronousResponse({ setFeedback, findingHolder }: Props) {
  return isGuardrailsFinding(findingHolder.chatbotfinding) ? (
    <ChatbotIncomingGuardrailsResponse />
  ) : (
    <ChatbotIncomingRegularResponse setFeedback={setFeedback} findingHolder={findingHolder} />
  );
}
