import { useCallback } from "react";

import { ReviewChatbotFinding } from "@coherehealth/core-platform-api";
import { formatDate } from "util/dateUtils";
import {
  ReviewChatbotWebSocketResponse,
  WebSocketResponseSchema,
} from "components/ClinicalReview/reviewUtils/chatbotWebsocketTypeHelpers";

const useSynchonousReviewChatbotFindings = (
  serviceRequestId: string,
  reviewId: string,
  appendSynchronousChatbotFinding: (finding: ReviewChatbotFinding) => void
) => {
  const formChatMessageIntoFinding = useCallback(
    (data: ReviewChatbotWebSocketResponse): ReviewChatbotFinding => {
      const finding: ReviewChatbotFinding = {
        findingRecordId: undefined,
        findingInstant: formatDate(new Date()),
        serviceRequestId: serviceRequestId,
        value: data.answer,
        displayedQuestion: data.user_question,
        modelQuestion: data.user_question,
        manuallyInputPrompt: true,
        reviewId: reviewId,
        sources: data.sources,
        helpfulnessFeedback: undefined,
        helpfulnessFeedbackComments: undefined,
        guardrailsActivated: data.response_metadata?.stopReason === "guardrail_intervened",
      };

      return finding;
    },
    [reviewId, serviceRequestId]
  );

  const handleChatbotMessage = (data: ReviewChatbotWebSocketResponse) => {
    //adds the ReviewChatbotFinding version of the message to state for rendering
    const validatedData: ReviewChatbotWebSocketResponse = WebSocketResponseSchema.parse(data);
    const structuredFinding = formChatMessageIntoFinding(validatedData);
    appendSynchronousChatbotFinding(structuredFinding);
  };

  return {
    handleChatbotMessage,
  };
};

export { useSynchonousReviewChatbotFindings };
