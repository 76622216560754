import { Dispatch, useCallback, useContext, useEffect, useState } from "react";

import { ServiceRequestSelectionCardView } from "@coherehealth/common";
import {
  Patient,
  ServiceRequestResponse,
  useDeleteServiceRequestAttachment,
  useGetAllReviews,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import RemoveAttachmentModal from "./RemoveAttachmentModal";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import useDraftContinue from "components/ServiceRequest/DraftContinue";
import { FaxAuthorizationContext } from "util/context/FaxAuthorizationContext";

interface AttachedServiceRequestCardProps {
  serviceRequest: ServiceRequestResponse;
  serviceRequestAttachmentId: string;
  afterRemoveAttachment: () => void;
  selectedPatient?: Patient;
  setPatient?: Dispatch<Patient | undefined>;
}

/**
 * Given an id of a service request that the fax is attached to, renders a card w/ a remove attachment action
 */
export function AttachedServiceRequestCard({
  serviceRequest,
  serviceRequestAttachmentId,
  afterRemoveAttachment,
  selectedPatient,
  setPatient,
}: AttachedServiceRequestCardProps) {
  const {
    data: existingReviews,
    error: existingReviewsError,
    loading: loadingReviews,
  } = useGetAllReviews({
    serviceRequestId: serviceRequest.id,
    queryParams: { max: 20 },
  });
  const {
    mutate: removeAttachment,
    loading: removingAttachment,
    error: removeAttachmentError,
  } = useDeleteServiceRequestAttachment({ id: serviceRequest.id });
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName: serviceRequest?.healthPlanName || undefined,
    encounterType: serviceRequest?.encounterType,
    skipRequestTimingCheck: true,
    delegatedVendorName: serviceRequest?.delegatedVendor,
    srContent: serviceRequest,
  });

  const { handleServiceRequestSelection, handleNewlyAttachedServiceRequest, handleInitialAttachedServiceRequests } =
    useContext(FaxAuthorizationContext);

  const { enqueueSnackbar } = useSnackbar();
  const [removeModalOpen, setRemoveModalOpen] = useState(false);

  const onConfirmRemove = useCallback(async () => {
    await removeAttachment(serviceRequestAttachmentId);

    handleServiceRequestSelection?.(serviceRequest);
    handleNewlyAttachedServiceRequest?.(serviceRequest.id);
    handleInitialAttachedServiceRequests?.(serviceRequest.id);

    setRemoveModalOpen(false);
    afterRemoveAttachment();
  }, [
    afterRemoveAttachment,
    handleInitialAttachedServiceRequests,
    handleNewlyAttachedServiceRequest,
    handleServiceRequestSelection,
    removeAttachment,
    serviceRequest,
    serviceRequestAttachmentId,
  ]);

  useEffect(() => {
    if (setPatient && serviceRequest?.patient) {
      setPatient(serviceRequest.patient);
    }
  }, [setPatient, serviceRequest]);

  useEffect(() => {
    if (existingReviewsError) {
      enqueueSnackbar(`Failed to load reviews for service request: ${existingReviewsError.message}`, {
        variant: "error",
      });
    }
    if (removeAttachmentError) {
      enqueueSnackbar(`Failed to remove service request attachment: ${removeAttachmentError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, existingReviewsError, removeAttachmentError]);

  const draftContinue = useDraftContinue(serviceRequest, true);

  return (
    <>
      <ServiceRequestSelectionCardView
        serviceRequest={serviceRequest}
        mostRecentReview={existingReviews ? existingReviews[existingReviews.length - 1] : undefined}
        loadingReview={loadingReviews}
        onRemove={() => setRemoveModalOpen(true)}
        showRemoveButton
        showPatientAlert={!!selectedPatient && selectedPatient?.id !== serviceRequest?.patient?.id}
        isFacilityBasedServiceRequest={!!facilityBasedFeatureEnabled}
        draftContinue={draftContinue}
      />
      <RemoveAttachmentModal
        open={removeModalOpen}
        handleClose={() => setRemoveModalOpen(false)}
        removing={removingAttachment}
        onRemove={onConfirmRemove}
      />
    </>
  );
}
