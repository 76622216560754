import React, { ReactNode } from "react";
import { colorsLight } from "../../themes";
import useTheme from "@material-ui/core/styles/useTheme";

interface IProps {
  header?: boolean;
  disabled?: boolean;
  noTextWrap?: boolean;
  width: string;
  minWidth?: string | number;
  alignItems?: string;
  themedPaddingRight: number;
  className?: string;
  overflow?: string;
  children: ReactNode;
}

export default function TableCell({
  header,
  disabled,
  noTextWrap,
  width,
  minWidth,
  alignItems,
  themedPaddingRight,
  className,
  children,
  overflow,
}: IProps) {
  const theme = useTheme();
  return (
    <div
      style={{
        color: disabled ? colorsLight.white.disabled : "",
        minWidth: minWidth,
        width: width,
        paddingRight: theme.spacing(themedPaddingRight),
        display: "flex",
        alignItems: alignItems ?? "center",
        wordBreak: "break-word",
        overflow: overflow || "hidden",
        textOverflow: noTextWrap ? "ellipsis" : "",
        whiteSpace: noTextWrap ? "nowrap" : undefined,
      }}
      className={className}
    >
      {children}
    </div>
  );
}
