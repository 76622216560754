import React, { useState } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, Theme, useTheme } from "@material-ui/core/styles";
import { Body1 } from "../Typography";
import TableCell from "./TableCell";
import Divider from "@material-ui/core/Divider";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { CardContent, Collapse } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

interface Column<T> {
  name: string;
  width: string;
  minWidth?: string | number;
  alignItems?: string;
  themedPaddingRight: number;
  value: (arg: T, isExpanded?: boolean) => React.ReactNode;
}

interface IProps<T> {
  index: number;
  item: T;
  tableColumns: Column<T>[];
  onRowClick?: (row: T, index: number) => void;
  clickable?: boolean;
  slim?: boolean;
  hideHeaderDivider?: boolean;
  hideDividers?: number[];
  showFullInfo?: boolean;
  rowExpansion?: (row: T, index: number) => React.ReactNode;
  rowColor?: string;
  slimDividerNoBorders?: boolean;
  selectableRows?: boolean;
  selected?: boolean;
  onSelect?: (isSelected: boolean) => void;
}

export default function TableReadOnlyRow<T>({
  slim,
  index,
  item,
  hideHeaderDivider,
  tableColumns,
  onRowClick,
  clickable,
  showFullInfo,
  rowExpansion,
  hideDividers,
  rowColor,
  slimDividerNoBorders,
  selectableRows,
  selected,
  onSelect,
}: IProps<T>) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const hideCurrentDivider = hideDividers?.includes(index);

  return (
    <>
      {((slim && index === 0) || !slim) && !hideHeaderDivider && !hideCurrentDivider && (
        <Divider style={{ marginBottom: slim ? theme.spacing(1) : 0 }} />
      )}
      {slim && index !== 0 && slimDividerNoBorders && <Divider style={{ margin: "-0.5px 20px" }} />}
      <Row
        slim={Boolean(slim)}
        clickable={Boolean(clickable || onRowClick)}
        onClick={() => {
          if (onRowClick) {
            onRowClick(item, index);
          }
          // only expand if row has more to show
          if (rowExpansion && rowExpansion(item, index)) {
            setExpanded(!expanded);
          }
        }}
        style={{
          backgroundColor: expanded ? "#F5F5F5" : rowColor ? rowColor : "",
          borderLeft: expanded ? "4px solid #039EC3" : slimDividerNoBorders ? "none" : "4px solid rgba(0, 0, 0, 0)",
          paddingLeft: slimDividerNoBorders ? theme.spacing(2) : "0",
        }}
      >
        {selectableRows && (
          <TableCell width="50px" themedPaddingRight={1} overflow="visible">
            <Checkbox
              color="primary"
              checked={selected}
              onChange={(e) => onSelect && onSelect(e.target.checked)}
              onClick={(e) => e.stopPropagation()}
              inputProps={{ "aria-label": "select row" }}
            />
          </TableCell>
        )}
        {tableColumns.map((column, index) => {
          if (column.width !== "0%") {
            const val = column.value(item, expanded);
            const isString = typeof val === "string" || val === undefined;
            return (
              <TableCell key={index} {...column}>
                {isString ? (
                  showFullInfo ? (
                    <Body1>{val ? val : "--"}</Body1>
                  ) : (
                    <SlimTableText>{val ? val : "--"}</SlimTableText>
                  )
                ) : (
                  <div>{val}</div>
                )}
              </TableCell>
            );
          } else {
            return <div key={index} />;
          }
        })}
        {!!onRowClick && <ArrowForwardIosIcon />}
      </Row>
      {rowExpansion && rowExpansion(item, index) && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent style={{ padding: 0 }}>{rowExpansion(item, index)}</CardContent>
        </Collapse>
      )}
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Row = styled(({ clickable, slim, ...other }) => <div {...other} />)<Theme, { slim: boolean; clickable: boolean }>(
  ({ theme, clickable, slim }) => ({
    padding: slim ? theme.spacing(1, 0) : theme.spacing(2, 0),
    display: "flex",
    position: "relative",
    ...(clickable
      ? {
          cursor: "pointer",
          "&:hover": {
            opacity: 0.75,
          },
        }
      : {
          "&:hover": {
            opacity: 1,
          },
        }),
  })
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SlimTableText = styled(Body1)(({ theme }) => ({
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
}));
