import { CurrentReviewTabTypes } from "@coherehealth/common";
import { useCallback, useRef } from "react";

export interface IUsePreserveReviewRightColumnScrollPositionProps {}

export interface IUsePreserveReviewRightColumnScrollPosition {
  handleTabChange: (prevTabIndex: CurrentReviewTabTypes, newTabIndex: CurrentReviewTabTypes) => void;
  getTopLevelScrollDivRef: (tabIndex: CurrentReviewTabTypes | undefined) => React.RefObject<HTMLDivElement> | null;
  reviewAssistantScrollDivRef: React.RefObject<HTMLDivElement> | null;
}

const usePreserveReviewRightColumnScrollPosition = (): IUsePreserveReviewRightColumnScrollPosition => {
  const scrollPositionByTabRef = useRef<{ [key: string]: number }>({});

  const guidelinesScrollDivRef = useRef<HTMLDivElement | null>(null);
  const reviewScrollDivRef = useRef<HTMLDivElement | null>(null);
  const oosEmailScrollDivRef = useRef<HTMLDivElement | null>(null);
  const oonReviewScrollDivRef = useRef<HTMLDivElement | null>(null);
  const reviewAssistantScrollDivRef = useRef<HTMLDivElement | null>(null);

  const getEligibleDiv = useCallback((tabIndex: CurrentReviewTabTypes): HTMLDivElement | null => {
    switch (tabIndex) {
      case "GUIDELINES":
        return guidelinesScrollDivRef.current;
      case "REVIEW":
        return reviewScrollDivRef.current;
      case "OOSEMAIL":
        return oosEmailScrollDivRef.current;
      case "OON_REVIEW":
        return oonReviewScrollDivRef.current;
      case "REVIEW_ASSISTANT":
        return reviewAssistantScrollDivRef.current;
      default:
        return null;
    }
  }, []);

  const handleTabChange = useCallback(
    (prevTabIndex: CurrentReviewTabTypes, newTabIndex: CurrentReviewTabTypes) => {
      const prevDiv = getEligibleDiv(prevTabIndex);

      if (prevDiv && prevTabIndex) {
        scrollPositionByTabRef.current[prevTabIndex] = prevDiv.scrollTop;
      }

      setTimeout(() => {
        const prevScrollPosition = scrollPositionByTabRef.current[newTabIndex] || 0;

        const newDiv = getEligibleDiv(newTabIndex);
        if (newDiv) {
          newDiv.scrollTo({
            top: prevScrollPosition,
            behavior: "smooth",
          });
        }
      }, 700);
    },
    [getEligibleDiv]
  );

  const getTopLevelScrollDivRef = useCallback(
    (tabIndex: CurrentReviewTabTypes | undefined): React.RefObject<HTMLDivElement> | null => {
      switch (tabIndex) {
        case "GUIDELINES":
          return guidelinesScrollDivRef;
        case "REVIEW":
          return reviewScrollDivRef;
        case "OOSEMAIL":
          return oosEmailScrollDivRef;
        case "OON_REVIEW":
          return oonReviewScrollDivRef;
        case "REVIEW_ASSISTANT":
          // This is handled by the ReviewAssistant component
          return null;
        default:
          return null;
      }
    },
    []
  );

  return {
    handleTabChange,
    getTopLevelScrollDivRef,
    reviewAssistantScrollDivRef,
  };
};

export default usePreserveReviewRightColumnScrollPosition;
