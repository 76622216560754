import { COHERE_NAME, MEDICAL_MUTUAL_OF_OHIO_NAME, pendingReasonMessage } from "@coherehealth/common";
import {
  AuthStatus,
  AuthorizationResponse,
  P2PIntegratedSchedulingConfiguration,
  Patient,
  PendingReason,
  ServiceRequestCreatePayload,
  ServiceRequestResponse,
  ServiceRequestSearchResponse,
} from "@coherehealth/core-platform-api";
import { AlertProps } from "@material-ui/lab";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { compact } from "lodash";
import { Dispatch, SetStateAction, ReactNode } from "react";

// Configure whether an action is hidden, disabled, etc...
export interface ActionConfiguration {
  // Is the action disabled (greyed out)
  disabled: boolean;
  // Is the button invisible (style-only)
  hidden: boolean;
  // Completely prevents the rendering of this action (will not take up space)
  noRender?: boolean;
}

// Contains a list of overrides for the action configs
// I eventually want to move most business logic out of the ServiceRequestStatusDisplay component and have it all controlled by the parents through this prop.
export interface ServiceRequestStatusDisplayActionConfiguration {
  printActionConfiguration?: ActionConfiguration;
  startContinuationActionConfiguration?: ActionConfiguration;
  draftContinueActionConfiguration?: ActionConfiguration;
  draftDeleteActionConfiguration?: ActionConfiguration;
  editStatusActionConfiguration?: ActionConfiguration;
  /* 
    Please write any future actions here as configurable.
    Make sure to:
    - wrap the new button in `!noRender`
    - toggle visibility with `hidden`
    - toggle interactable with `disabled`
    */
}

export type ActionButtonT = {
  authorization?: AuthorizationResponse;
  serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse;
  limitVoidsAfterApprovalFF: boolean;
  isFinalDeterminationLetterPrintable: boolean;
  canCreateContinuation?: boolean;
  allowContinuations: boolean;
  isAuthViewOnlyUser: boolean;
  actionConfiguration: ServiceRequestStatusDisplayActionConfiguration;
  onContinuationClick?: () => Promise<void>;
  createNewAuthorizationLoading?: boolean;
  loadingContinuation?: boolean;
  onShowPrintSummary: () => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setHover?: Function;
  setEditModalOpen: Dispatch<SetStateAction<boolean>>;
  setDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  isInAuthBuilder?: boolean;
  isDraftSr: boolean;
  isStatusEditable: boolean;
  patient?: Patient;
  workflowId?: string;
  openServiceRequestForEditing?: () => void;
  onPotentialDuplicatesClose?: () => void;
};

export type StatusAlertT = {
  children: string | JSX.Element;
  useServiceRequestAuthStatus?: boolean;
  authorization: AuthorizationResponse | undefined;
  serviceRequest: ServiceRequestSearchResponse | ServiceRequestResponse;
  authStatus?: AuthStatus;
  allowContinuations: boolean;
  canViewOutreachOpportunities: boolean;
  limitVoidsAfterApprovalFF: boolean;
  isAuthViewOnlyUser: boolean;
  action: React.ReactNode;
  deleteServiceRequestHandler?: () => Promise<void>;
};

export type ServiceRequestStausDisplayT = {
  useServiceRequestAuthStatus?: boolean;
  authorization?: AuthorizationResponse | undefined;
  serviceRequest: ServiceRequestSearchResponse | ServiceRequestResponse;
  actionConfiguration: ServiceRequestStatusDisplayActionConfiguration;
  isInAuthBuilder?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onDeleteDraft?: Function;
  searchForPotentialDuplicates?: (srPayload: ServiceRequestCreatePayload) => Promise<boolean>;
  workflowId?: string;
  serviceRequestFormContents?: ServiceRequestFormContent[];
  getPotentialDuplicatesLoading?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEdit?: Function;
  onSubmission?: (sr: ServiceRequestResponse) => void;
  authorizationRefetch?: () => Promise<AuthorizationResponse | null>;
  openServiceRequestForEditing?: () => void;
  canCreateContinuation?: boolean;
  deleteServiceRequestHandler?: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setHover?: Function;
  onPotentialDuplicatesClose?: () => void;
};
export type AlertDetailsT = {
  severity: AlertProps["severity"] | "draft" | "withdrawn" | "integrationFailure" | "noLongerNeeded" | undefined;
  message: ReactNode;
};

export const hiddenStyle = { opacity: "0%" };
export const animatedHiddenStyle = { transition: "opacity 300ms", ...hiddenStyle };
export const shownStyle = { opacity: "100%" };
export const animatedShownStyle = { transition: "opacity 300ms", ...shownStyle };
export const actionIconStyle = { padding: "8px" };

export const statusNote = (
  authStatus?: AuthStatus,
  authorizationNote?: string,
  healthPlanName?: string,
  type?: string,
  pendingReason?: PendingReason,
  isCareRecommendationRequest?: boolean
) => {
  if (healthPlanName === MEDICAL_MUTUAL_OF_OHIO_NAME) {
    //for MMO, they want to see the authorizationNote surfaced here for all requests
    return authorizationNote ?? "";
  } else if (authStatus === "DENIED" || authStatus === "PARTIALLY_APPROVED") {
    const providerNote = authorizationNote ? ` • Note: ${authorizationNote}` : "";
    const statusDescription = authStatus === "DENIED" ? "denial" : "partial approval";
    return isCareRecommendationRequest
      ? ""
      : `To see what criteria led to the ${statusDescription}, please review the final determination letter${providerNote}`;
  } else if (authStatus === "PENDING" && type === "Pharmacy" && pendingReason === "PENDING_ASSESSMENT_SUBMISSION") {
    return "The assessment for this request needs to be completed before submitting";
  } else if (
    authStatus === "RECOMMENDED_DENIAL" ||
    authStatus === "RECOMMENDED_PARTIAL_APPROVAL" ||
    (authStatus === "PENDING" && pendingReason === "SCHEDULING_OUTREACH")
  ) {
    return "";
  } else {
    return authorizationNote ? `Note: ${authorizationNote}` : undefined;
  }
};

interface AlertBody {
  serviceRequest: ServiceRequestSearchResponse | ServiceRequestResponse;
  limitVoidsAfterApprovalFF: boolean;
  isAuthViewOnlyUser: boolean;
  allowContinuations: boolean;
  detailsText: string | JSX.Element;
  statusDetail: string;
  refreshDetail: JSX.Element;
  oonDetail: string | null;
  appealDetail: string | null;
  pharmacyAssessmentDetail: JSX.Element;
  continuationDraftStatusDetail?: string;
  authNumber?: string;
  authorization?: AuthorizationResponse;
  authStatus?: AuthStatus;
  pendingReason?: PendingReason;
  p2PScheduledDetail?: JSX.Element;
  p2PIntegratedSchedulingConfig?: P2PIntegratedSchedulingConfiguration;
  p2PDedicatedAuthStatusesEnabled?: boolean;
}

export const getAlertBody = ({
  serviceRequest,
  limitVoidsAfterApprovalFF,
  isAuthViewOnlyUser,
  allowContinuations,
  detailsText,
  statusDetail,
  refreshDetail,
  oonDetail,
  appealDetail,
  pharmacyAssessmentDetail,
  continuationDraftStatusDetail,
  authNumber,
  authorization,
  authStatus,
  pendingReason,
  p2PScheduledDetail,
  p2PIntegratedSchedulingConfig,
  p2PDedicatedAuthStatusesEnabled,
}: AlertBody) => {
  const { type } = serviceRequest;

  if (
    authStatus === "APPROVED" &&
    limitVoidsAfterApprovalFF &&
    serviceRequest.isSrNoLongerNeeded &&
    (!authorization?.serviceRequestsOnAuth ||
      authorization.serviceRequestsOnAuth.every(
        (sr) => (sr.authStatus === "APPROVED" && sr.isSrNoLongerNeeded === true) || sr.authStatus !== "APPROVED"
      )) &&
    !isAuthViewOnlyUser
  ) {
    return `${detailsText} • Note: Since this request was already decisioned, it's still considered approved but has been marked as no longer needed.`;
  } else if (authStatus === "APPROVED" && type === "Medical") {
    return (
      <>
        {authNumber ? statusDetail : refreshDetail}
        {oonDetail}
        {appealDetail}
      </>
    );
  } else if (
    allowContinuations &&
    authorization &&
    authorization.serviceRequestsOnAuth &&
    authorization.serviceRequestsOnAuth.length > 1 &&
    authStatus === "DRAFT" &&
    !isAuthViewOnlyUser
  ) {
    return <>{continuationDraftStatusDetail}</>;
  } else if (authStatus === "PENDING_ASSESSMENT") {
    return <>{pharmacyAssessmentDetail}</>;
  } else if (
    !!p2PIntegratedSchedulingConfig?.enabledP2PDetails &&
    p2PDedicatedAuthStatusesEnabled &&
    authStatus === "PENDING" &&
    pendingReason === "PEER_TO_PEER_REVIEW"
    // TODO: check serviceRequest appointment status
  ) {
    return <>{p2PScheduledDetail}</>;
  } else {
    return (
      <>
        {statusDetail}
        {oonDetail}
        {appealDetail}
      </>
    );
  }
};

export const getBannerDetails = (
  shouldHidePendingReason: boolean,
  limitVoidsAfterApprovalFF: boolean,
  canEditOONReview: boolean,
  allowContinuations: boolean,
  isAuthViewOnlyUser: boolean,
  showClinicalConversation: boolean,
  serviceRequest: ServiceRequestSearchResponse | ServiceRequestResponse,
  latestDeterminedSr: ServiceRequestResponse | undefined,
  isCohereOpsGroup: boolean | undefined,
  authorization: AuthorizationResponse | undefined,
  authStatus: AuthStatus | undefined,
  isInternalOrProviderCohereUser: boolean | undefined,
  pendingWithDisplayHealthPlan: boolean | undefined,
  p2PIntegratedSchedulingConfig?: P2PIntegratedSchedulingConfiguration,
  p2PDedicatedAuthStatusesEnabled?: boolean
): AlertDetailsT => {
  const { type, withdrawnReason, pendingReason, delegatedVendor, healthPlanName, healthPlanDisplayName } =
    serviceRequest;
  const delegatedVendorDisplayText =
    delegatedVendor === "HealthHelpV2" ? "HealthHelp" : healthPlanDisplayName || healthPlanName;
  const noLongerNeeded =
    authStatus === "APPROVED" &&
    limitVoidsAfterApprovalFF &&
    serviceRequest.isSrNoLongerNeeded &&
    (!authorization?.serviceRequestsOnAuth ||
      authorization.serviceRequestsOnAuth.every(
        (sr) => (sr.authStatus === "APPROVED" && sr.isSrNoLongerNeeded === true) || sr.authStatus !== "APPROVED"
      )) &&
    !isAuthViewOnlyUser;
  const approved =
    (authStatus === "APPROVED" && type === "Medical") || (authStatus === "APPROVED" && type === "Pharmacy");
  const integrationFailure =
    (authStatus === "WITHDRAWN" && withdrawnReason === "INTEGRATION_FAILURE") ||
    (authStatus === "PENDING" && type === "Pharmacy" && pendingReason === "PENDING_ASSESSMENT_SUBMISSION") ||
    authStatus === "PENDING_ASSESSMENT";
  const withdrawn = authStatus === "WITHDRAWN" || authStatus === "VOIDED" || authStatus === "DISMISSED";
  const error = authStatus === "DENIED" || authStatus === "PARTIALLY_APPROVED";
  const warning =
    (authStatus === "PENDING" && (type === "Pharmacy" || shouldHidePendingReason)) ||
    showClinicalConversation ||
    authStatus === "PENDING" ||
    authStatus === "INTAKE" ||
    authStatus === "RECOMMENDED_DENIAL" ||
    authStatus === "RECOMMENDED_PARTIAL_APPROVAL" ||
    authStatus === "PENDING_EXTERNAL_DETERMINATION" ||
    authStatus === "POST_DENIAL_PEER_TO_PEER" ||
    (!authStatus && latestDeterminedSr?.authStatus === "POST_DENIAL_PEER_TO_PEER");
  const draft =
    (allowContinuations &&
      authorization &&
      authorization.serviceRequestsOnAuth &&
      authorization.serviceRequestsOnAuth.length > 1 &&
      authStatus === "DRAFT" &&
      !isAuthViewOnlyUser) ||
    authStatus === "DRAFT";

  let alertDetails: AlertDetailsT = {
    severity: undefined,
    message: <>Approved</>,
  };

  if (noLongerNeeded) {
    alertDetails = {
      severity: "noLongerNeeded",
      message: <>No longer needed</>,
    };
  }
  if (approved && !noLongerNeeded) {
    alertDetails = {
      severity: undefined,
      message: <>Approved</>,
    };
  }
  if (integrationFailure) {
    if (authStatus === "WITHDRAWN" && withdrawnReason === "INTEGRATION_FAILURE") {
      alertDetails = {
        severity: "integrationFailure",
        message: <>Processed manually by Oscar</>,
      };
    } else if (authStatus === "PENDING" && type === "Pharmacy" && pendingReason === "PENDING_ASSESSMENT_SUBMISSION") {
      alertDetails = {
        severity: "integrationFailure",
        message: <>Action required: Complete assessment</>,
      };
    } else if (authStatus === "PENDING_ASSESSMENT") {
      alertDetails = {
        severity: "integrationFailure",
        message: <>Assessment not ready yet</>,
      };
    }
    return alertDetails;
  }
  if (withdrawn) {
    if (authStatus === "VOIDED") {
      alertDetails = { severity: "withdrawn", message: <>Voided</> };
    } else if (authStatus === "DISMISSED") {
      alertDetails = { severity: "withdrawn", message: <>Dismissed</> };
    } else if (authStatus === "WITHDRAWN") {
      alertDetails = { severity: "withdrawn", message: <>Withdrawn</> };
    }
    return alertDetails;
  }
  if (error) {
    if (authStatus === "DENIED") {
      alertDetails = { severity: "error", message: <>Denied</> };
    } else if (authStatus === "PARTIALLY_APPROVED") {
      alertDetails = { severity: "error", message: <>Partially Approved</> };
    }
    return alertDetails;
  }
  if (warning) {
    if (authStatus === "PENDING" && (type === "Pharmacy" || shouldHidePendingReason)) {
      alertDetails = {
        severity: "warning",
        message: <>Pending</>,
      };
    } else if (
      authStatus === "PENDING" &&
      pendingReason === "PEER_TO_PEER_REVIEW" &&
      p2PIntegratedSchedulingConfig?.enabledP2PDetails &&
      p2PDedicatedAuthStatusesEnabled
      // TODO: check the serviceRequest appointment status
    ) {
      alertDetails = {
        severity: "warning",
        message: <>Pending: Peer-to-peer scheduled</>,
      };
    } else if (
      pendingWithDisplayHealthPlan &&
      (authStatus === "PENDING_FACILITY_VERIFICATION" ||
        authStatus === "PENDING" ||
        authStatus === "PENDING_EXTERNAL_DETERMINATION" ||
        authStatus === "INTAKE" ||
        authStatus === "RECOMMENDED_DENIAL" ||
        authStatus === "RECOMMENDED_PARTIAL_APPROVAL" ||
        authStatus === "POST_DENIAL_PEER_TO_PEER")
    ) {
      let message = `Pending review from ${serviceRequest.healthPlanDisplayName || serviceRequest.healthPlanName}`;
      alertDetails = {
        severity: "warning",
        message: <>{message}</>,
      };
    } else if (showClinicalConversation) {
      alertDetails = {
        severity: "warning",
        message: <>Clinical Conversation</>,
      };
    } else if (
      authStatus === "PENDING" ||
      authStatus === "INTAKE" ||
      authStatus === "RECOMMENDED_DENIAL" ||
      authStatus === "RECOMMENDED_PARTIAL_APPROVAL"
    ) {
      const canViewFullPendReason = () => {
        const restrictedPendingReasons: PendingReason[] = [
          "NEW_HIRE_AUDIT_REVIEW",
          "OUT_OF_SCOPE",
          "OUT_OF_NETWORK_REVIEW",
          "LETTER_WRITING",
        ];
        const isRestrictedPendingReason = pendingReason && restrictedPendingReasons.includes(pendingReason);
        if (isRestrictedPendingReason) {
          if (["NEW_HIRE_AUDIT_REVIEW", "OUT_OF_SCOPE"].includes(pendingReason || "")) {
            return isCohereOpsGroup;
          } else if (pendingReason === "OUT_OF_NETWORK_REVIEW") {
            return canEditOONReview;
          } else if (pendingReason === "LETTER_WRITING") {
            return !isInternalOrProviderCohereUser;
          }
        } else {
          return true;
        }
      };
      const pendingText = canViewFullPendReason() ? pendingReasonMessage(pendingReason) : pendingReasonMessage("OTHER");

      let message = compact([`Pending${authStatus === "INTAKE" ? " (intake)" : ""}`, pendingText]).join(": ");
      alertDetails = {
        severity: "warning",
        message: <>{message}</>,
      };
    } else if (authStatus === "PENDING_EXTERNAL_DETERMINATION") {
      let message;
      if (pendingReason === "MISSING_CLINICAL_INFO") {
        message = "Pending: Missing information (clinical)";
        alertDetails = {
          severity: "warning",
          message: <>{message}</>,
        };
      } else if (delegatedVendor && delegatedVendor !== COHERE_NAME) {
        message = `Pending review by ${delegatedVendorDisplayText}`;
        alertDetails = {
          severity: "warning",
          message: <>{message}</>,
        };
      } else {
        message = `Pending external review${healthPlanName ? `: ${healthPlanName}` : ""}`;
        alertDetails = {
          severity: "warning",
          message: <>{message}</>,
        };
      }
    } else if (authStatus === "POST_DENIAL_PEER_TO_PEER") {
      alertDetails = {
        severity: "warning",
        message: <>In peer-to-peer review</>,
      };
    }

    return alertDetails;
  }
  if (draft) {
    if (
      allowContinuations &&
      authorization &&
      authorization.serviceRequestsOnAuth &&
      authorization.serviceRequestsOnAuth.length > 1 &&
      !isAuthViewOnlyUser
    ) {
      alertDetails = {
        severity: "draft",
        message: <>Continuation in draft</>,
      };
    } else {
      alertDetails = {
        severity: "draft",
        message: <>Draft</>,
      };
    }
    return alertDetails;
  }
  return alertDetails;
};
