import { RuleActions, RedirectRuleAction, RecommendChangeRuleAction } from "@coherehealth/core-platform-api";
import { actionChangesToBeApplied, GenericServiceRequest } from "./rule/applyActionToServiceRequest";

type OneActionType = RuleActions extends Array<infer T> ? T : never;

export type RedirectAction = RedirectRuleAction;
export const isRedirectAction = (ipt: OneActionType): ipt is RedirectAction => ipt.actionType === "Redirect";

export const isHardRedirectAction = (ipt: OneActionType): ipt is RedirectAction =>
  isRedirectAction(ipt) && !Boolean(ipt.isOptional);

type ApproveAction = Extract<OneActionType, { actionType: "APPROVE" }>;
const isApproveAction = (ipt: OneActionType): ipt is ApproveAction => ipt.actionType === "APPROVE";

type RecommendedChangeAction = Extract<OneActionType, { actionType: "RecommendChange" }>;
const isRecommendedChangeAction = (ipt: OneActionType): ipt is RecommendedChangeAction =>
  ipt.actionType === "RecommendChange";

type PendingAction = Extract<OneActionType, { actionType: "PENDING" }>;
export const isPendingAction = (ipt: OneActionType): ipt is PendingAction => ipt.actionType === "PENDING";

type PendForExternalDeterminationAction = SetValueAction & {
  objectAttribute: "authStatus";
  objectValue: { value: "PENDING_EXTERNAL_DETERMINATION" };
};
const isPendForExternalDetermination = (ipt: OneActionType): ipt is PendForExternalDeterminationAction =>
  (ipt as PendForExternalDeterminationAction)?.objectValue?.value === "PENDING_EXTERNAL_DETERMINATION";

/**
 * The way rule eval works is that the first action of a given type wins, so if the first
 * approve or pending rule is an approve rule, then the request will approve
 */
export function willApprove(ruleActions: RuleActions): boolean {
  const approveOrPendAction = ruleActions.find(
    (a) => isRecommendedChangeAction(a) || isApproveAction(a) || isPendingAction(a) || isPendForExternalDetermination(a)
  );
  return approveOrPendAction ? isApproveAction(approveOrPendAction) : false;
}

export function groupAuthAutoApproves(ruleActions: RuleActions): boolean {
  if (ruleActions.length === 0) {
    return false;
  }
  let rulesSplitBySrId: { [key: string]: string } = {};

  ruleActions.forEach((rule) => {
    if (rule.actionType && rule.srId && !rulesSplitBySrId[rule.srId]) {
      rulesSplitBySrId[rule.srId] = rule.actionType;
    }
  });
  const bulkActionTypes = Object.values(rulesSplitBySrId);
  const groupAutoApproves = bulkActionTypes.every((actionType) => actionType === "APPROVE");

  return groupAutoApproves;
}

export function isProviderFastTrack(ruleActions: RuleActions): boolean {
  if (ruleActions.length === 0) {
    return false;
  }
  const fastTrackIsBeingSetToTrue = ruleActions.some((rule) => {
    return (
      rule.actionType === "SetValue" &&
      rule.objectAttribute === "usesAFastTrackProvider" &&
      rule.objectValue?.value === true
    );
  });
  return fastTrackIsBeingSetToTrue;
}

type SetValueAction = Extract<OneActionType, { actionType: "SetValue" }>;
export const isSetValueAction = (ipt: OneActionType): ipt is SetValueAction => ipt.actionType === "SetValue";

export type RecommendChangeAction = RecommendChangeRuleAction;
export const isRecommendChangeAction = (ipt: OneActionType): ipt is RecommendChangeAction =>
  ipt.actionType === "RecommendChange";
export const isRecommendChangeActionWithAttribute = (ipt: OneActionType): ipt is RecommendChangeAction =>
  ipt.actionType === "RecommendChange" && Boolean(ipt.onAcceptAttribute);

export const isRecommendChangeActionWithOutAttribute = (ipt: OneActionType): ipt is RecommendChangeAction =>
  ipt.actionType === "RecommendChange" && Boolean(!ipt.onAcceptAttribute);

export type ValidDisplayMessageAction = RedirectAction | RecommendChangeAction;

export function removeRulesWhenPxCodesOnDifCarepaths(
  ruleActions: RuleActions | undefined,
  onlyReturnRedirectRules: boolean,
  serviceRequest: GenericServiceRequest,
  featureFlagCheck?: boolean
): RuleActions | undefined {
  let rulesTargeted = [
    "E Oscar Procedure Code",
    "E HCPCSonService",
    "E HCPCSonServicePhysicalTherapy",
    "E CVD HCPCs on Service",
    "E GHP Px on Service",
    "E SS HCPCs on Service",
  ];
  return ruleActions?.filter(
    (rule) =>
      (!onlyReturnRedirectRules && rule.actionType !== "Redirect") ||
      (rule.actionType === "Redirect" &&
        rule?.ruleName &&
        !rulesTargeted.includes(rule?.ruleName) &&
        actionChangesToBeApplied(serviceRequest, rule, featureFlagCheck))
  );
}

//takes a list of rule actions and a list of rule ids, and returns the former, filtering out those that also appear on the latter
export const deduplicatedRuleActions = (ruleActions: RuleActions, ruleIds: string[]): RuleActions =>
  ruleActions.filter((r) => !ruleIds.includes(r.ruleId || ""));
