import { InformativeModal } from "@coherehealth/common";
import { BaseReview } from "@coherehealth/core-platform-api";
import { Grid } from "@material-ui/core";
import { PatientInfoReadOnlyTextField } from "components/ClinicalReview/ClinicalReviewInfoPanel/PatientInformation/PatientInfoReadOnlyTextField";

export interface ReviewerDetailsModalI {
  modalOpen: boolean;
  onClose: (value: React.SetStateAction<boolean>) => void;
  reviewerDetails: BaseReview["reviewerDetails"];
  legacyReviewCompletedByName?: string;
}

export const ReviewerDetailsModal = ({
  modalOpen,
  onClose,
  reviewerDetails,
  legacyReviewCompletedByName,
}: ReviewerDetailsModalI) => {
  return (
    <InformativeModal
      open={modalOpen}
      onClose={onClose}
      headerText={"Reviewer details"}
      fullWidth
      children={
        <Grid container spacing={2} style={{ paddingTop: "5%" }}>
          <Grid item xs={8} container direction="column" spacing={2}>
            <Grid item>
              <PatientInfoReadOnlyTextField
                displayText={reviewerDetails?.reviewerName || legacyReviewCompletedByName}
                label="Name"
              />
            </Grid>
            <Grid item>
              <PatientInfoReadOnlyTextField displayText={reviewerDetails?.title} label="Title" />
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid container direction="column" spacing={2}>
              <Grid item style={{ height: "60px" }} />

              <Grid item>
                <PatientInfoReadOnlyTextField displayText={reviewerDetails?.specialties} label="Specialties" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};
