import { DateSelect, plusYears, today } from "@coherehealth/common";
import { PalCheckFormSectionProps, SectionHeader } from "./HealthPlanPalCheckFormSectionConfigs";
import Box from "@material-ui/core/Box";
import { useState } from "react";

export default function StartDateSection({
  priorAuthRequirements,
  setPriorAuthRequirements,
  resetPalResults,
  startDateCoverage,
  attemptedSubmit,
  authSubmissionStyling,
  formContent,
  setFormContent,
  facilityBased,
  minDateCheck,
}: PalCheckFormSectionProps) {
  const isInpatient = priorAuthRequirements?.encounterType === "INPATIENT";
  const { patientStatus, admissionDate, expectedAdmissionDate } = formContent || {};
  // This is not a single boolean because patientStatus can also be EXPIRED.
  const isCurrentlyAdmitted = isInpatient && (patientStatus === "CURRENTLY_ADMITTED" || patientStatus === "DISCHARGED");
  const isNotYetAdmitted = isInpatient && patientStatus === "NOT_YET_ADMITTED";
  const labelText = () => {
    if (!authSubmissionStyling) {
      return "Expected start date";
    } else if (isInpatient) {
      if (facilityBased) {
        return "Admission date";
      } else {
        return "Start date";
      }
    }
    return "Start date";
  };

  const [isDateInRange, setIsDateInRange] = useState(true);
  const dateChangeValidator = () => {
    if (!startDateCoverage?.inRange) {
      setIsDateInRange(false);
    }
  };

  // Inpatient uses admission date, while Outpatient uses start date.
  const datePickerValue = (): Date | null => {
    if (isCurrentlyAdmitted && admissionDate) {
      return admissionDate;
    } else if (isNotYetAdmitted && expectedAdmissionDate) {
      return expectedAdmissionDate;
    } else {
      return priorAuthRequirements.startDate || null;
    }
  };

  const updateAdmissionDate = (date: Date) => {
    if (!formContent || !setFormContent) {
      return;
    }

    // End date should come after admission date
    const endDate = formContent.endDate && date > formContent.endDate ? undefined : formContent.endDate;

    // Only 1 of admissionDate and expectedAdmissionDate should exist.
    if (isCurrentlyAdmitted && admissionDate) {
      setFormContent((prev) => ({
        ...prev,
        admissionDate: date,
        expectedAdmissionDate: undefined,
        endDate,
      }));
    } else if (isNotYetAdmitted && expectedAdmissionDate) {
      setFormContent((prev) => ({
        ...prev,
        admissionDate: undefined,
        expectedAdmissionDate: date,
        endDate,
      }));
    }
  };

  return (
    // Needs margins to fit designs. All sections designed with 20px padding, but this needs 16px...
    <Box display="flex" flexDirection="column" style={{ marginTop: "-4px" }}>
      {!authSubmissionStyling && <SectionHeader>When is the first procedure expected to start?</SectionHeader>}
      <DateSelect
        error={startDateCoverage ? !startDateCoverage?.inRange : attemptedSubmit && !priorAuthRequirements.startDate}
        helperText={
          (!startDateCoverage?.inRange && startDateCoverage?.messageToDisplay) ||
          (attemptedSubmit && !priorAuthRequirements.startDate && "Required") ||
          (!minDateCheck?.inRange && minDateCheck?.messageToDisplay)
        }
        minDateMessage=""
        maxDate={plusYears(10, today())}
        label={labelText()}
        value={datePickerValue()}
        onDateChange={(date) => {
          dateChangeValidator();
          setPriorAuthRequirements({ ...priorAuthRequirements, startDate: date });
          resetPalResults();
          isInpatient && updateAdmissionDate(date);
        }}
        attemptedSubmit={attemptedSubmit || !isDateInRange}
        showInternalErrorsOnBlur
      />
    </Box>
  );
}
