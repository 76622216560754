import { StatusAlert } from "../StatusAlert/StatusAlert";
import { useEffect, useState } from "react";
import {
  HEALTH_HELP_NAME,
  HEALTH_HELP_V2_NAME,
  InlineButton,
  formatDateStr,
  useConfiguration,
  useFeature,
} from "@coherehealth/common";
import { useAuthorized } from "authorization";
import {
  getInitialServiceRequest,
  getLatestServiceRequest,
  getLatestServiceRequestRequestingOonException,
} from "util/authorization";
import {
  isDraftServiceRequest,
  isGeisingerServiceRequest,
  isNegationAuthStatus,
  isTerminalNonNegationStatus,
  isTerminalStatus,
} from "util/serviceRequest";
import { getAuthStatusText } from "components/ClinicalReview/ClinicalReviewInfoPanel/ServiceRequestTable";
import { ActionButtons } from "../ActionButtons/ActionButtons";
import DeleteDraftServiceRequestModal from "components/PatientSummary/ServiceRequestSummaryCard/DeleteDraftServiceRequestModal";
import { ServiceRequestStausDisplayT, getAlertBody, statusNote } from "../StatusAlert/statusAlertUtils";
import ChangeServiceStatusModal from "../ChangeServiceStatusModal/ChangeServiceStatusModal";
import ServiceRequestPrintDialog from "../ServiceRequestPrintDialog/ServiceRequestPrintDialog";
import { AppealPayload, useGetAppeals } from "@coherehealth/core-platform-api";
import { usePatientStayTimelineEnabled } from "components/ServiceRequest/PatientStay/hooks/usePatientStayTimelineEnabled";
import { getWithdrawReason } from "../utils";

export const ServiceRequestStatusDisplay = ({
  useServiceRequestAuthStatus,
  authorization,
  serviceRequest,
  actionConfiguration,
  isInAuthBuilder,
  onDeleteDraft,
  searchForPotentialDuplicates,
  workflowId,
  serviceRequestFormContents,
  getPotentialDuplicatesLoading,
  onEdit,
  onSubmission,
  authorizationRefetch,
  deleteServiceRequestHandler,
  setHover,
  canCreateContinuation,
  onPotentialDuplicatesClose,
}: ServiceRequestStausDisplayT) => {
  const {
    pendingReason,
    cohereId,
    vendorIdentifier,
    delegatedVendor,
    authorizationNote,
    healthPlanName,
    type,
    initialDecisionDisposition,
  } = serviceRequest;
  const limitVoidsAfterApprovalFF = useFeature("LimitVoidsAfterApproval");
  const canViewOutreachOpportunities = useAuthorized("VIEW_OUTREACH_OPPORTUNITIES");
  const isAuthViewOnlyUser = useAuthorized("AUTH_VIEW_ONLY");
  const canEditHealthHelpStatus = useAuthorized("EDIT_HEALTH_HELP_STATUS");
  const isUserAllowedToMakePostDecisionAuthStatusUpdate = useAuthorized("POST_DECISION_AUTH_STATUS_UPDATE");

  const userCanEdit = useAuthorized("EDIT_SERVICE_REQUEST_STATUS");
  const isAllowPostDecisionAuthStatusUpdatesEnabled = useFeature("allowPostDecisionAuthStatusUpdates");
  const oonReviewConfig = useConfiguration(
    "outOfNetworkReviewConfiguration",
    serviceRequest.healthPlanName,
    serviceRequest.delegatedVendor
  );
  const oonReviewFeatureEnabled = oonReviewConfig?.enabled;
  const statusTransitionConfiguration = useConfiguration(
    "statusTransitionConfiguration",
    serviceRequest.healthPlanName,
    serviceRequest.delegatedVendor
  );
  const isPostDecisionUpdatesAllowed =
    statusTransitionConfiguration?.isPostDecisionUpdatesAllowed && isUserAllowedToMakePostDecisionAuthStatusUpdate;

  const allowContinuations = authorization?.allowContinuations ?? serviceRequest?.allowContinuations ?? true;

  const { authStatus } =
    allowContinuations && !useServiceRequestAuthStatus && authorization ? authorization : serviceRequest;
  const { authNumber } = allowContinuations && authorization?.authNumber ? authorization : serviceRequest;
  const { isCareRecommendationRequest } = authorization ?? serviceRequest?.requestor ?? {};
  const latestSr = authorization && getLatestServiceRequest(authorization)?.[1];
  const latestDraftSr = latestSr?.authStatus === "DRAFT" ? latestSr : undefined;

  const p2PIntegratedSchedulingConfig = useConfiguration(
    "P2PIntegratedSchedulingConfiguration",
    serviceRequest?.healthPlanName
  );
  const p2PDedicatedAuthStatusesEnabled = useFeature("P2PDedicatedAuthStatusesEnabled");

  const note = statusNote(
    authStatus,
    authorizationNote,
    healthPlanName,
    type,
    pendingReason,
    isCareRecommendationRequest
  );
  const finalStatusDetailFields: string[] = [];
  const shouldHidePendingReason =
    authStatus === "PENDING" &&
    (pendingReason === "ADMIN_VOID" || pendingReason === "AUDIT_REVIEW") &&
    !canViewOutreachOpportunities;

  if (authNumber) {
    finalStatusDetailFields.push(`Authorization #${authNumber}`);
  }

  const showOnlyInitialTrackingId = usePatientStayTimelineEnabled({ authorization, serviceRequest });

  if (allowContinuations && authorization) {
    // TODO: Handle pharmacy status detail for continuations (no idea how PA continuations work)
    if (cohereId && vendorIdentifier && delegatedVendor === "Agadia") {
      finalStatusDetailFields.push(`PA Case ID #${vendorIdentifier} (Tracking #${cohereId})`);
    }
    if (authorization && authorization.serviceRequestsOnAuth && authorization.serviceRequestsOnAuth.length > 0) {
      const [initialSr, continuationSrs] = getInitialServiceRequest(authorization);
      const initialCohereId = initialSr?.cohereId;
      const nonDraftContinuationCohereIds = continuationSrs
        .map((sr) => (sr.authStatus !== "DRAFT" ? sr.cohereId : undefined))
        .filter((cohereIdsOrUndefined) => cohereIdsOrUndefined !== undefined)
        .reverse();
      if (
        initialCohereId &&
        (!vendorIdentifier ||
          (vendorIdentifier && (delegatedVendor === HEALTH_HELP_NAME || delegatedVendor === HEALTH_HELP_V2_NAME)))
      ) {
        const trackingNumbers = [...(showOnlyInitialTrackingId ? [] : nonDraftContinuationCohereIds), initialCohereId];
        const trackingNumbersCaption = `Tracking #${trackingNumbers.join(", #")}`;
        finalStatusDetailFields.push(trackingNumbersCaption);
      }
    }
  } else {
    if (cohereId && vendorIdentifier && delegatedVendor === "Agadia") {
      finalStatusDetailFields.push(`PA Case ID #${vendorIdentifier} (Tracking #${cohereId})`);
    }
    if (
      cohereId &&
      (!vendorIdentifier ||
        (vendorIdentifier && (delegatedVendor === HEALTH_HELP_NAME || delegatedVendor === HEALTH_HELP_V2_NAME)))
    ) {
      finalStatusDetailFields.push(`Tracking #${cohereId}`);
    }
  }

  if (note && !shouldHidePendingReason) {
    finalStatusDetailFields.push(note);
  }

  const showPatientStayTimeline = usePatientStayTimelineEnabled({ authorization, serviceRequest });

  if (
    showPatientStayTimeline &&
    authorization?.nextReviewDate &&
    !isTerminalStatus({ authStatus: authorization.authStatus })
  ) {
    finalStatusDetailFields.push(`Next review date: ${formatDateStr(authorization.nextReviewDate)}`);
  }

  // Show reviewers when a request is previously approved
  const showPreviouslyApprovedRequestFeatureFlag = useFeature("showPreviouslyApprovedRequest");
  const canViewPreviouslyApprovedRequestUsers = useAuthorized("CAN_VIEW_PREVIOUSLY_APPROVED_REQUESTS");
  const showPreviouslyApprovedRequest =
    showPreviouslyApprovedRequestFeatureFlag && canViewPreviouslyApprovedRequestUsers;
  const formattedPreviouslyDecisionTimestamp = formatDateStr(authorization?.decisionTimestamp);
  if (showPreviouslyApprovedRequest && initialDecisionDisposition === "APPROVED" && isNegationAuthStatus(authStatus)) {
    finalStatusDetailFields.push(`Previously approved on ${formattedPreviouslyDecisionTimestamp}`);
  }
  const refreshPage = (e: any) => {
    e.stopPropagation();
    window.location.reload();
  };
  const refreshDetailStyle = { padding: "0px", lineHeight: "16px", marginBottom: "2px" };

  const withdrawReasonStr = getWithdrawReason(
    serviceRequest?.withdrawnReason,
    authStatus,
    serviceRequest?.reviewOutcomeWithdrawOptions
  );
  withdrawReasonStr && finalStatusDetailFields.push(withdrawReasonStr);

  const statusDetail = finalStatusDetailFields.join(" • ");
  const refreshDetail = (
    <div>
      {statusDetail} • Please check back later for the auth number or
      {
        <InlineButton component="span" style={refreshDetailStyle} onClick={refreshPage}>
          refresh
        </InlineButton>
      }
      the page now.
    </div>
  );
  const pharmacyAssessmentDetail = (
    <div>
      {statusDetail} • We're finding the relevant assessment for your request. Please check back later or
      {
        <InlineButton component="span" style={refreshDetailStyle} onClick={refreshPage}>
          refresh
        </InlineButton>
      }
      the page now.
    </div>
  );

  const p2PScheduledDetail = (
    <div>
      <p style={{ fontWeight: "bold", margin: 0 }}>
        Peer-to-peer scheduled on {"01/01/2000"} at {"01:00PM (GMT-3)"}
        {/* TODO: Get scheduled date from serviceRequest.appointment*/}
      </p>
      {statusDetail}
    </div>
  );

  const continuationDraftStatusDetail =
    allowContinuations && authorization && latestDraftSr && latestDraftSr.cohereId
      ? `Tracking #${latestDraftSr.cohereId}`
      : undefined;
  const detailsText = statusDetail || type === "Pharmacy" ? statusDetail : refreshDetail;
  let srRequestingOonException;
  if (serviceRequest.wasOonExceptionRequested) {
    srRequestingOonException = serviceRequest;
  } else if (authorization) {
    srRequestingOonException = getLatestServiceRequestRequestingOonException(authorization);
  }

  const oonStatuses: string[] = [];
  if (srRequestingOonException?.medicalNecessityDecision) {
    oonStatuses.push(`Medical Necessity: ${getAuthStatusText(srRequestingOonException.medicalNecessityDecision)}`);
  }
  if (srRequestingOonException?.oonExceptionDecision) {
    oonStatuses.push(`Out-of-Network Review: ${getAuthStatusText(srRequestingOonException.oonExceptionDecision)}`);
  }
  const shouldShowOonDetail =
    oonReviewFeatureEnabled && ["DENIED", "PARTIALLY_APPROVED"].includes(srRequestingOonException?.authStatus || "");
  const oonDetail = shouldShowOonDetail && oonStatuses.length ? "\n\n" + oonStatuses.join(" • ") : null;
  const canViewFinalDeterminationLetter = useAuthorized("FINAL_DETERMINATION_LETTER_DISPLAY");
  const { data: inProgressAppeals, refetch: getAppeals } = useGetAppeals({
    lazy: true,
  });

  useEffect(() => {
    const authServiceRequests = authorization?.serviceRequestsOnAuth?.map((sr) => sr.id);
    if (authServiceRequests) {
      getAppeals({
        queryParams: {
          serviceRequest: `in:${authServiceRequests}`,
          appealStatus: `eq:${"IN_PROGRESS" as AppealPayload["appealStatus"]}`,
        },
      });
    }
  }, [authorization?.serviceRequestsOnAuth, getAppeals]);

  const appealDetail = inProgressAppeals && inProgressAppeals.length > 0 ? "\nSent for appeal review" : null;

  const alertBody = getAlertBody({
    serviceRequest,
    limitVoidsAfterApprovalFF,
    isAuthViewOnlyUser,
    allowContinuations,
    detailsText,
    statusDetail,
    refreshDetail,
    oonDetail,
    appealDetail,
    pharmacyAssessmentDetail,
    continuationDraftStatusDetail,
    authNumber,
    authorization,
    authStatus,
    pendingReason,
    p2PScheduledDetail,
    p2PIntegratedSchedulingConfig,
    p2PDedicatedAuthStatusesEnabled,
  });

  const isDraftSr = isDraftServiceRequest(serviceRequest);
  const [showSrDetailsPrintout, setShowSrDetailsPrintout] = useState(false);
  const [open, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const onShowPrintSummary = () => setShowSrDetailsPrintout(true);
  const reviewerWithdrawalModalEnhanced = useFeature("reviewerWithdrawalModalEnhanced");
  const canViewEnhancedWithdrawalModal = useAuthorized("VIEW_ENHANCED_WITHDRAWAL_MODAL");
  const hasFailedIntegration = serviceRequest.integrationStatus === "FAILED";
  const ghpPostDecisionAuthStatusUpdatesAllowed =
    isAllowPostDecisionAuthStatusUpdatesEnabled &&
    isGeisingerServiceRequest(serviceRequest) &&
    isUserAllowedToMakePostDecisionAuthStatusUpdate;
  const isFinallyDetermined = Boolean(
    ["DENIED", "APPROVED", "PARTIALLY_APPROVED"].includes(serviceRequest.authStatus || "")
  );

  const isNotWithdrawn =
    serviceRequest?.authStatus !== "WITHDRAWN" &&
    serviceRequest?.authStatus !== "VOIDED" &&
    serviceRequest?.authStatus !== "DISMISSED";

  const isStatusEditableFeatureFlagCheck =
    (reviewerWithdrawalModalEnhanced && canViewEnhancedWithdrawalModal) ||
    ((isPostDecisionUpdatesAllowed || !isTerminalNonNegationStatus(serviceRequest)) &&
      isNotWithdrawn &&
      canViewEnhancedWithdrawalModal);
  const healthHelpUpdateAllowed =
    canEditHealthHelpStatus &&
    serviceRequest.delegatedVendor === HEALTH_HELP_NAME &&
    (serviceRequest.authStatus === "PENDING_EXTERNAL_DETERMINATION" || isFinallyDetermined);
  const isStatusEditable =
    !actionConfiguration.editStatusActionConfiguration?.disabled &&
    isStatusEditableFeatureFlagCheck &&
    (ghpPostDecisionAuthStatusUpdatesAllowed ||
      isPostDecisionUpdatesAllowed ||
      isStatusEditableFeatureFlagCheck ||
      healthHelpUpdateAllowed ||
      (userCanEdit &&
        (!isFinallyDetermined || hasFailedIntegration) &&
        serviceRequest.authStatus !== "PENDING_FACILITY_VERIFICATION" &&
        serviceRequest.authStatus !== "PENDING_EXTERNAL_DETERMINATION"));

  return (
    <div>
      <StatusAlert
        children={alertBody}
        authorization={authorization}
        serviceRequest={serviceRequest}
        authStatus={authStatus}
        allowContinuations={allowContinuations}
        canViewOutreachOpportunities={canViewOutreachOpportunities}
        limitVoidsAfterApprovalFF={limitVoidsAfterApprovalFF}
        isAuthViewOnlyUser={isAuthViewOnlyUser}
        deleteServiceRequestHandler={deleteServiceRequestHandler}
        action={
          <ActionButtons
            authorization={authorization}
            serviceRequest={serviceRequest}
            limitVoidsAfterApprovalFF={limitVoidsAfterApprovalFF}
            isFinalDeterminationLetterPrintable={
              isStatusEditable
                ? isFinallyDetermined && ghpPostDecisionAuthStatusUpdatesAllowed && canViewFinalDeterminationLetter
                : canViewFinalDeterminationLetter
            }
            allowContinuations={allowContinuations}
            isAuthViewOnlyUser={isAuthViewOnlyUser}
            actionConfiguration={actionConfiguration}
            onShowPrintSummary={onShowPrintSummary}
            setEditModalOpen={setEditModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            isDraftSr={isDraftSr}
            isStatusEditable={isStatusEditable}
            onPotentialDuplicatesClose={onPotentialDuplicatesClose}
            setHover={setHover}
            canCreateContinuation={canCreateContinuation}
          />
        }
      />

      {isStatusEditable && (
        <ChangeServiceStatusModal
          authorization={authorization}
          authorizationRefetch={authorizationRefetch}
          open={open}
          onEdit={() => {
            onEdit?.();
            setEditModalOpen(false);
          }}
          serviceRequest={serviceRequest}
          setEditModalOpen={setEditModalOpen}
        />
      )}
      <DeleteDraftServiceRequestModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDeleteDraft={onDeleteDraft}
        serviceRequestId={serviceRequest?.id}
        serviceRequest={serviceRequest}
        isInAuthBuilder={isInAuthBuilder}
        searchForPotentialDuplicates={searchForPotentialDuplicates}
        workflowId={workflowId}
        serviceRequestFormContents={serviceRequestFormContents}
        getPotentialDuplicatesLoading={getPotentialDuplicatesLoading}
      />
      <ServiceRequestPrintDialog
        showSrDetailsPrintout={showSrDetailsPrintout}
        serviceRequest={serviceRequest}
        setShowSrDetailsPrintout={setShowSrDetailsPrintout}
      />
    </div>
  );
};
