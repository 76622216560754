import {
  Body1,
  formatDateStr,
  H5,
  InlineButton,
  patientDisplayName,
  useFeature,
  parseDateFromISOStringWithoutFallback,
} from "@coherehealth/common";
import { Coverage, Patient, usePatientCoverages } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Divider, Grid, makeStyles } from "@material-ui/core";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import {
  coveragePriority,
  getCoverageBasedOnDate,
  getSortedListOfCoverages,
  removeDuplicateCoverages,
} from "util/patientUtils";
import {
  ageStringFromDobString,
  patientGenderDisplay,
  formatAnyPhone,
  singleLineAddress,
  preferredWrittenLanguageDisplay,
} from "util/demographicUtils";
import ClinicalReviewCard from "common/ClinicalReviewCard";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { generatePath } from "react-router";
import routes from "routes";
import { incrementClickCount, UserClickInfoClinicalReviewPage } from "@coherehealth/common";
import CoveragesTable from "../CoveragesTable";
import { PatientInfoReadOnlyTextField } from "./PatientInfoReadOnlyTextField";

interface Props {
  patient?: Patient | null;
  loadingPatientData?: boolean;
  crrDenials: boolean | undefined;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  startDate?: string;
  serviceRequestId: string;
}

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    margin: theme.spacing(2, 0, 2),
  },
  body1: {
    color: theme.palette.text.secondary,
  },
  column1Entry: {
    width: theme.spacing(18),
    marginRight: theme.spacing(4),
  },
  columnEntry: {
    marginRight: theme.spacing(3),
  },
}));

export default function PatientInformation({
  patient,
  crrDenials,
  setUserClickInfoTracking,
  startDate,
  serviceRequestId,
}: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(!crrDenials);
  const [tableCoverages, setTableCoverages] = useState<Coverage[]>();
  const { mutate: patientCoverages } = usePatientCoverages({});
  const logrocketUnmasking = useFeature("logrocketUnmasking");
  const classes = useStyles();

  const showPatientCoverageCoordationOfBenefitsSection = useFeature("showPatientCoverageCoordationOfBenefitsSection");

  useEffect(() => {
    let componentIsMounted = true;
    const getCoverages = async () => {
      if (patient && startDate && patient.coverages?.length) {
        const response = await patientCoverages({
          serviceRequestStartDate: startDate,
          dateOfBirth: patient.dateOfBirth,
          firstName: patient.firstName,
          lastName: patient.lastName,
          fullName: patient.name,
          healthPlanName: getCoverageBasedOnDate(parseDateFromISOStringWithoutFallback(startDate), patient)
            ?.healthPlanName,
        });
        if (componentIsMounted) {
          setTableCoverages(response || []);
        }
      }
    };
    if (patient && startDate && !tableCoverages) {
      getCoverages();
    }
    return () => {
      componentIsMounted = false;
    };
  }, [patient, startDate, tableCoverages, patientCoverages]);

  const priorityCoverages = coveragePriority(tableCoverages ? tableCoverages : []);
  const getOrderedCoverages = () => {
    const orderedCoverages = priorityCoverages
      ? priorityCoverages?.map((coverage, index) => {
          const orderedCoverage = coverage as OrderedCoverage;
          if (index === 0) {
            orderedCoverage.first = true;
          }
          return orderedCoverage;
        })
      : [];
    return orderedCoverages;
  };
  const orderedCoverages = getOrderedCoverages();

  const coverages = patient && getSortedListOfCoverages(patient);
  let activePrimaryCoverage: Coverage | undefined;
  if (coverages?.[0]?.planActive) {
    activePrimaryCoverage = coverages.shift();
  }
  const updateUserClickInfo = () => {
    setUserClickInfoTracking && incrementClickCount("PatientInformationCardToggle", "", -1, setUserClickInfoTracking);
  };
  const patientDOB = formatDateStr(patient?.dateOfBirth);
  const patientAge = ageStringFromDobString(patient?.dateOfBirth || "");
  const patientGender = patientGenderDisplay(patient?.gender);
  const patientPrefWrittenLang = preferredWrittenLanguageDisplay(patient?.languagePreference?.written);
  const patientPlanStart = formatDateStr(activePrimaryCoverage?.planStart);
  const patientPlanEnd = formatDateStr(activePrimaryCoverage?.planEnd);
  const patientPhoneNumber = formatAnyPhone(patient?.phones);
  const patientLineAddress = singleLineAddress(patient?.address);
  return (
    <ClinicalReviewCard
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      updateUserClickInfo={updateUserClickInfo}
      header={
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: 6 }}>
            <H5 data-public={!!logrocketUnmasking ? true : undefined}>Patient Information</H5>
          </Grid>
          <div style={{ marginRight: 24, marginBottom: isExpanded ? "" : 4 }}>
            <Body1 className={classes.body1}>{patient && patientDisplayName(patient)}</Body1>
          </div>
          <div>
            <Body1 className={classes.body1}>Member ID {patient?.memberId}</Body1>
          </div>
        </Grid>
      }
    >
      {isExpanded && (
        <Grid container>
          <div className={classes.column1Entry}>
            <PatientInfoReadOnlyTextField displayText={patientDOB} label="DOB" />
          </div>
          <div className={classes.columnEntry}>
            <PatientInfoReadOnlyTextField displayText={patientAge} label="Age" />
          </div>
          <div className={classes.columnEntry}>
            <PatientInfoReadOnlyTextField displayText={patientGender} label="Sex" />
          </div>
          <div className={classes.columnEntry}>
            <PatientInfoReadOnlyTextField displayText={patientPrefWrittenLang} label="Preferred written language" />
          </div>
          <div className={classes.columnEntry}>
            <PatientInfoReadOnlyTextField label="PCP grouper ID" displayText={activePrimaryCoverage?.grouperId} />
          </div>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <div className={classes.column1Entry}>
            <PatientInfoReadOnlyTextField label="Phone" displayText={patientPhoneNumber} />
          </div>
          <div className={classes.columnEntry}>
            <PatientInfoReadOnlyTextField label="Address" displayText={patientLineAddress} />
          </div>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          {showPatientCoverageCoordationOfBenefitsSection &&
          orderedCoverages &&
          removeDuplicateCoverages(orderedCoverages).length > 1 ? (
            <CoveragesTable coverages={removeDuplicateCoverages(orderedCoverages)} />
          ) : (
            <>
              <div className={classes.column1Entry}>
                <PatientInfoReadOnlyTextField label="Plan" displayText={activePrimaryCoverage?.healthPlanName} />
              </div>
              <div className={classes.columnEntry}>
                <PatientInfoReadOnlyTextField
                  label="Plan type"
                  displayText={
                    activePrimaryCoverage?.coverageProductType
                      ? activePrimaryCoverage?.coverageProductType
                      : activePrimaryCoverage?.productType || "--"
                  }
                />
              </div>
              <div className={classes.columnEntry}>
                <PatientInfoReadOnlyTextField
                  label="Plan ID"
                  displayText={activePrimaryCoverage?.planId ? activePrimaryCoverage.planId : " - "}
                />
              </div>
              <div className={classes.columnEntry}>
                <PatientInfoReadOnlyTextField
                  label="Membership type"
                  displayText={
                    activePrimaryCoverage?.coverageLineOfBusinessType
                      ? activePrimaryCoverage?.coverageLineOfBusinessType
                      : activePrimaryCoverage?.lineOfBusinessType
                  }
                />
              </div>
              <div className={classes.columnEntry}>
                <PatientInfoReadOnlyTextField
                  label="Plan year"
                  displayText={patientPlanStart + " - " + patientPlanEnd}
                />
              </div>
            </>
          )}
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <InlineButton
            data-testid="patient-summary-redirect-button"
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              setUserClickInfoTracking &&
                incrementClickCount("OpenPatientSummaryHyperLink", "", -1, setUserClickInfoTracking);
              window.open(
                generatePath(`${routes.PATIENT_SUMMARY}?reviewServiceRequestId=${serviceRequestId}`, {
                  patientId: patient?.id,
                })
              );
            }}
          >
            Open patient summary
          </InlineButton>
        </Grid>
      )}
    </ClinicalReviewCard>
  );
}

interface OrderedCoverage extends Coverage {
  first?: boolean;
}
