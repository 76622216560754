import { ReferralConfiguration, RequestFormConfigurationFieldSpecification } from "@coherehealth/core-platform-api";
import { ReferralRequestFormContent } from "components/ReferralManagement/RequestBuilder/ReferralRequestForm";

/**
 * Function that calculates the validity of a field on the service/referral request form
 * @param fieldConfig The field configuration that is saved to the service/referral request
 * @param requiredValidation Validation logic that is specific to when the field is REQUIRED
 * @param fieldRestrictions Restrictions that apply to a field; must be validated even when field is OPTIONAL
 * @returns boolean that represents if the field is valid
 */
export function fieldIsValid(
  fieldConfig?: RequestFormConfigurationFieldSpecification,
  requiredValidation?: boolean,
  fieldRestrictions?: boolean
): boolean {
  //if the fields are not passed in then set them to true by deafult
  const fieldRestrictionsCheck = typeof fieldRestrictions === "undefined" ? true : fieldRestrictions;
  const requiredValidationCheck = typeof requiredValidation === "undefined" ? true : requiredValidation;
  if (!fieldConfig || fieldConfig.fieldSpec === "OMIT") {
    //if the field does not show on the form then it should not block validation
    return true;
  } else if (fieldConfig.fieldSpec === "OPTIONAL") {
    //We should still block the user in the case where the field is optional but it violates a restriction on the field
    return fieldRestrictionsCheck;
  } else {
    return fieldRestrictionsCheck && requiredValidationCheck;
  }
}

export function referralFieldIsValid(
  fieldConfig: ReferralConfiguration,
  rrForm: ReferralRequestFormContent,
  requiredValidation?: boolean,
  fieldRestrictions?: boolean
): boolean {
  //if the fields are not passed in then set them to true by default
  const fieldRestrictionsCheck = typeof fieldRestrictions === "undefined" ? true : fieldRestrictions;
  const requiredValidationCheck = typeof requiredValidation === "undefined" ? true : requiredValidation;
  //Find if there is a dependency
  let filteredSpec = fieldConfig.fieldSpec;
  if (fieldConfig?.dependency) {
    const { name, value, fieldSpec } = fieldConfig.dependency;
    let dependencyFound = filterAndCompareStringProperties(rrForm, name, value);
    if (dependencyFound) {
      filteredSpec = fieldSpec;
    }
  }
  if (filteredSpec === "OMIT") {
    //if the field does not show on the form then it should not block validation
    return true;
  } else if (filteredSpec === "OPTIONAL") {
    //We should still block the user in the case where the field is optional but it violates a restriction on the field
    return fieldRestrictionsCheck;
  } else {
    return fieldRestrictionsCheck && requiredValidationCheck;
  }
}
//currently supports string type dependencies. Can extend to diff object types in future
export function filterAndCompareStringProperties(
  formContent: ReferralRequestFormContent,
  compareName: string,
  compareValue: string
): boolean {
  const value = formContent[compareName as keyof ReferralRequestFormContent];
  // Check if the property exists, is of type string, and matches the compareValue
  return typeof value === "string" && value === compareValue;
}
