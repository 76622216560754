import { Grid, Stack } from "@mui/material";

import {
  UseGetProvidersOrFacilitiesProps,
  useGetProvidersOrFacilities,
  useGetCoverageFieldsDistinctValuesByHealthPlan,
  TemporaryPatientFormField,
  useGetHealthPlan,
} from "@coherehealth/core-platform-api";
import {
  NewPatientFormSectionT,
  LINE_OF_BUSINESS_OPTIONS,
  CoveragesSectionT,
  ProviderOption,
  FormError,
  coverageStateResetForHealthPlan,
  formErrorResetForHealthPlan,
  addOptionalLabel,
  isOptionalField,
} from "../formOptions";
import { useContext, useEffect, useState } from "react";
import { RenderFormSection } from "./RenderFormSection";
import { coveragesGridStyles } from "../addNewPatientStyles";
import {
  getUserAccessibleHealthPlans,
  SelectOptionsHook,
  useFeature,
  useLazyLoadingQueryOptionsHook,
} from "@coherehealth/common";
import { providerFacilityDropdownRender } from "components/ServiceRequest/ProviderFacilityDropdownRender";
import { POLICY_UNITED_STATES_OPTIONS } from "@coherehealth/common";
import { UserContext } from "UserContext";
import { error as logError } from "logger";

interface CoveragesSectionFormState {
  coveragesInfo: CoveragesSectionT;
  formError: FormError;
  attemptedSubmit?: boolean;
  dispatch: React.Dispatch<any>;
  formOptionalFields?: TemporaryPatientFormField[];
}

export const CoveragesInfoFormSection = ({
  coveragesInfo,
  formError,
  attemptedSubmit,
  dispatch,
  formOptionalFields,
}: CoveragesSectionFormState) => {
  const healthPlan = coveragesInfo.HEALTH_PLAN;
  const [userOpsGroup, setUserOpsGroup] = useState<string | undefined>();
  const { data: healthPlans } = useGetHealthPlan({ lazy: !(userOpsGroup && userOpsGroup === "CYPRESS") });
  const { getUser } = useContext(UserContext);
  const [planStart, setPlanStart] = useState<Date | null>(null);
  const [planEnd, setPlanEnd] = useState<Date | null>(null);
  const [pcp, setPcp] = useState<ProviderOption | null>(null);
  const useLobCoveragesCatalogueForTempPatients = useFeature("useLobCoveragesCatalogueForTempPatients");
  let lineOfBusinessOptions = LINE_OF_BUSINESS_OPTIONS;

  // TODO: Decommission the changes reading the hardcoded list if we confirm that the FF is not affecting GHP
  if (!useLobCoveragesCatalogueForTempPatients && healthPlan === "BCBS South Carolina") {
    lineOfBusinessOptions = [
      ...LINE_OF_BUSINESS_OPTIONS,
      { id: "BCBS_STATE_HEALTH_PLAN", label: "BCBS STATE HEALTH PLAN" },
      { id: "BCBS_G&I_MED_SUP", label: "BCBS G&I - MED SUP" },
      { id: "BCBS_G&I_OTHER", label: "BCBS G&I - OTHER" },
      { id: "FEP", label: "FEP" },
      { id: "MEDICARE_ADVANTAGE", label: "MEDICARE ADVANTAGE" },
      { id: "BCBS_G&I_EXCHANGE", label: "BCBS G&I - EXCHANGE" },
      { id: "BCHP_LF", label: "BCHP - LF" },
      { id: "BCHP_FI", label: "BCHP - FI" },
      { id: "BCBS_MAJOR_GROUP_FI", label: "BCBS MAJOR GROUP - FI" },
      { id: "BCHP_Healthy_Blue", label: "BCHP - Healthy Blue" },
      { id: "NAL_BLUE_SOLUTIONS", label: "NAL - BLUE SOLUTIONS" },
      { id: "NATIONAL_ALLIANCE", label: "NATIONAL ALLIANCE" },
      { id: "BCHP_EXCHANGE", label: "BCHP - EXCHANGE" },
      { id: "BCHP_ASO", label: "BCHP - ASO" },
      { id: "BCBS_G&I_LF", label: "BCBS G&I - LF" },
      { id: "BCBS_MAJOR_GROUP_ASO", label: "BCBS MAJOR GROUP - ASO" },
    ];
  }

  useEffect(() => {
    getUser()
      ?.then((user) => {
        setUserOpsGroup(user?.opsGroup);
      })
      .catch((e) => {
        logError(e);
      });
  }, [getUser]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    dispatch({
      type: "UPDATE_COVERAGES_INFO",
      payload: { [fieldName]: fieldValue },
    });
    dispatch({
      type: "UPDATE_FORM_ERROR",
      payload: { [fieldName]: !fieldValue && !isOptionalField(fieldName, formOptionalFields) },
    });
  };
  const handleSelectChange = (fieldName: string, fieldValue?: string | null) => {
    dispatch({
      type: "UPDATE_COVERAGES_INFO",
      payload: { [fieldName]: fieldValue },
    });
    dispatch({
      type: "UPDATE_FORM_ERROR",
      payload: { [fieldName]: !fieldValue && !isOptionalField(fieldName, formOptionalFields) },
    });
  };

  useEffect(() => {
    const handleAutoCompleteChange = (pcp: ProviderOption | null) => {
      dispatch({
        type: "UPDATE_COVERAGES_INFO",
        payload: { PRIMARY_CARE_PROVIDER: pcp },
      });
      dispatch({
        type: "UPDATE_FORM_ERROR",
        payload: { PRIMARY_CARE_PROVIDER: !pcp && !isOptionalField("PRIMARY_CARE_PROVIDER", formOptionalFields) },
      });
    };
    if ((pcp && !coveragesInfo.PRIMARY_CARE_PROVIDER) || pcp !== coveragesInfo.PRIMARY_CARE_PROVIDER) {
      handleAutoCompleteChange(pcp);
    }
    // We just need to validate if the PCP field is optional or not, passing the whole array may cause a [] != []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coveragesInfo.PRIMARY_CARE_PROVIDER, dispatch, pcp, formOptionalFields?.includes("PRIMARY_CARE_PROVIDER")]);

  function getUseProviders(useCMSProviders: string): SelectOptionsHook<ProviderOption> {
    return function useProviders(selectOptionsParams) {
      const queryParams = {
        healthPlanName: healthPlan,
        useCMSProviders: useCMSProviders,
      };

      return useLazyLoadingQueryOptionsHook({
        useGetHook: (args: UseGetProvidersOrFacilitiesProps) => useGetProvidersOrFacilities({ ...args, debounce: 600 }),
        additionalQueryParams: { ...queryParams, recordType: "PROVIDER" },
        ...selectOptionsParams,
      });
    };
  }

  const {
    data: coverageFieldsValues,
    loading: coverageFieldsValuesLoading,
    refetch: getCoverageFieldsDistinctValuesByHealthPlan,
  } = useGetCoverageFieldsDistinctValuesByHealthPlan({
    lazy: true,
  });

  const coveragesSection: NewPatientFormSectionT[] = [
    {
      name: "PLAN_START_DATE",
      label: `Plan start date ${addOptionalLabel("PLAN_START_DATE", formOptionalFields)}`,
      input: "date",
      value: planStart as Date,
      onDateChange: (val) => setPlanStart(val),
      handleSelectChange: handleSelectChange,
      error: formError?.PLAN_START_DATE && attemptedSubmit && !isOptionalField("PLAN_START_DATE", formOptionalFields),
    },
    {
      name: "HEALTH_PLAN",
      label: `Health plan ${addOptionalLabel("HEALTH_PLAN", formOptionalFields)}`,
      input: "singleSelect",
      value: healthPlan || null,
      error: formError?.HEALTH_PLAN && attemptedSubmit && !isOptionalField("HEALTH_PLAN", formOptionalFields),
      useOptions: () => ({
        optionsLoading: false,
        options: userOpsGroup ? getUserAccessibleHealthPlans(userOpsGroup, healthPlans) : [],
        filterOptions: (options, state) =>
          options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase())),
      }),
      setSelectedValue: (fieldName: string, fieldValue: string | null) => {
        dispatch({
          type: "UPDATE_COVERAGES_INFO",
          payload: { ...coveragesInfo, [fieldName]: fieldValue, ...coverageStateResetForHealthPlan },
        });
        dispatch({
          type: "UPDATE_FORM_ERROR",
          payload: {
            ...formError,
            [fieldName]: !fieldValue && !isOptionalField(fieldName, formOptionalFields),
            ...formErrorResetForHealthPlan,
          },
        });
      },
    },
    // TODO: Decommission the changes reading the hardcoded list if we confirm that the FF is not affecting GHP
    useLobCoveragesCatalogueForTempPatients
      ? {
          name: "LINE_OF_BUSINESS",
          label: `Line of business ${addOptionalLabel("LINE_OF_BUSINESS", formOptionalFields)}`,
          input: "singleSelect",
          value: coveragesInfo.LINE_OF_BUSINESS ?? null,
          useOptions: () => ({
            optionsLoading: false,
            options: coverageFieldsValues?.lineOfBusinessOptions,
            filterOptions: (options, state) =>
              options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase())),
          }),
          setSelectedValue: handleSelectChange,
          error:
            formError?.LINE_OF_BUSINESS && attemptedSubmit && !isOptionalField("LINE_OF_BUSINESS", formOptionalFields),
        }
      : {
          name: "LINE_OF_BUSINESS",
          label: `Line of business ${addOptionalLabel("LINE_OF_BUSINESS", formOptionalFields)}`,
          input: "singleSelect",
          value: coveragesInfo.LINE_OF_BUSINESS ?? null,
          useOptions: () => ({
            optionsLoading: false,
            options: lineOfBusinessOptions.map((lob) => lob.label),
            filterOptions: (options, state) =>
              options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase())),
          }),
          setSelectedValue: handleSelectChange,
          error:
            formError?.LINE_OF_BUSINESS && attemptedSubmit && !isOptionalField("LINE_OF_BUSINESS", formOptionalFields),
        },
    {
      name: "PRODUCT_ID",
      label: `Product ID ${addOptionalLabel("PRODUCT_ID", formOptionalFields)}`,
      input: "singleSelect",
      value: coveragesInfo.PRODUCT_ID || null,
      useOptions: () => ({
        optionsLoading: coverageFieldsValuesLoading,
        options: coverageFieldsValues?.productIdOptions,
        filterOptions: (options, state) =>
          options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase())),
      }),
      setSelectedValue: handleSelectChange,
      error: formError?.PRODUCT_ID && attemptedSubmit && !isOptionalField("PRODUCT_ID", formOptionalFields),
    },
    {
      name: "MARKET",
      label: `Market ${addOptionalLabel("MARKET", formOptionalFields)}`,
      input: "singleSelect",
      value: coveragesInfo.MARKET ?? null,
      useOptions: () => ({
        optionsLoading: coverageFieldsValuesLoading,
        options: coverageFieldsValues?.marketOptions,
        filterOptions: (options, state) =>
          options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase())),
      }),
      setSelectedValue: handleSelectChange,
      error: formError?.MARKET && attemptedSubmit && !isOptionalField("MARKET", formOptionalFields),
    },
    {
      name: "LEGAL_ENTITY",
      label: `Legal entity ${addOptionalLabel("LEGAL_ENTITY", formOptionalFields)}`,
      input: "singleSelect",
      value: coveragesInfo.LEGAL_ENTITY ?? null,
      useOptions: () => ({
        optionsLoading: coverageFieldsValuesLoading,
        options: coverageFieldsValues?.legalEntityOptions,
        filterOptions: (options, state) =>
          options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase())),
      }),
      setSelectedValue: handleSelectChange,
      error: formError?.LEGAL_ENTITY && attemptedSubmit && !isOptionalField("LEGAL_ENTITY", formOptionalFields),
    },
    {
      name: "PLAN_END_DATE",
      label: `Plan end date ${addOptionalLabel("PLAN_END_DATE", formOptionalFields)}`,
      input: "date",
      onDateChange: (val) => setPlanEnd(val),
      value: planEnd as Date,
      handleSelectChange: handleSelectChange,
      error: formError?.PLAN_END_DATE && attemptedSubmit && !isOptionalField("PLAN_END_DATE", formOptionalFields),
    },

    {
      name: "PLAN_TYPE",
      label: `Plan type ${addOptionalLabel("PLAN_TYPE", formOptionalFields)}`,
      input: "singleSelect",
      value: coveragesInfo.PLAN_TYPE ?? null,
      useOptions: () => ({
        optionsLoading: coverageFieldsValuesLoading,
        options: coverageFieldsValues?.planTypeOptions,
        filterOptions: (options, state) =>
          options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase())),
      }),
      setSelectedValue: handleSelectChange,
      error: formError?.PLAN_TYPE && attemptedSubmit && !isOptionalField("PLAN_TYPE", formOptionalFields),
    },
    {
      name: "LINE_OF_BUSINESS_DESCRIPTION",
      label: `Line of business description ${addOptionalLabel("LINE_OF_BUSINESS_DESCRIPTION", formOptionalFields)}`,
      input: "singleSelect",
      value: coveragesInfo.LINE_OF_BUSINESS_DESCRIPTION ?? null,
      useOptions: () => ({
        optionsLoading: coverageFieldsValuesLoading,
        options: coverageFieldsValues?.lineOfBusinessDescriptionOptions,
        filterOptions: (options, state) =>
          options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase())),
      }),
      setSelectedValue: handleSelectChange,
      error:
        formError?.LINE_OF_BUSINESS_DESCRIPTION &&
        attemptedSubmit &&
        !isOptionalField("LINE_OF_BUSINESS_DESCRIPTION", formOptionalFields),
    },
    {
      name: "GROUP_ID",
      label: `Group ID ${addOptionalLabel("GROUP_ID", formOptionalFields)}`,
      input: "singleSelect",
      value: coveragesInfo.GROUP_ID ?? null,
      useOptions: () => ({
        optionsLoading: coverageFieldsValuesLoading,
        options: coverageFieldsValues?.groupIdOptions,
        filterOptions: (options, state) =>
          options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase())),
      }),
      setSelectedValue: handleSelectChange,
      error: formError?.GROUP_ID && attemptedSubmit && !isOptionalField("GROUP_ID", formOptionalFields),
    },
    {
      name: "MARKET_NUMBER",
      label: `Market number ${addOptionalLabel("MARKET_NUMBER", formOptionalFields)}`,
      input: "text",
      value: coveragesInfo.MARKET_NUMBER,
      handleInputChange: handleInputChange,
      error: formError?.MARKET_NUMBER && attemptedSubmit && !isOptionalField("MARKET_NUMBER", formOptionalFields),
    },
    {
      name: "PRIMARY_CARE_PROVIDER",
      label: `Primary care provider ${addOptionalLabel("PRIMARY_CARE_PROVIDER", formOptionalFields)}`,
      value: pcp,
      input: "providerOption",
      useOptions: getUseProviders("false") as SelectOptionsHook<ProviderOption>,
      renderOption: (option: ProviderOption) =>
        providerFacilityDropdownRender(option.name, option.tinList, option.npi, option.optionType),
      setSelectedValue: (value: ProviderOption | null) => setPcp(value),
      getOptionLabel: (option: ProviderOption) => `${option.name} / NPI - ${option.npi}`,
      error:
        formError?.PRIMARY_CARE_PROVIDER &&
        attemptedSubmit &&
        !isOptionalField("PRIMARY_CARE_PROVIDER", formOptionalFields),
    },
    {
      name: "STATE_OF_ISSUE",
      label: `State of issue ${addOptionalLabel("STATE_OF_ISSUE", formOptionalFields)}`,
      input: "singleSelect",
      value: coveragesInfo.STATE_OF_ISSUE ?? null,
      useOptions: () => ({
        optionsLoading: false,
        options: POLICY_UNITED_STATES_OPTIONS.map((state) => state.label),
        filterOptions: (options, state) =>
          options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase())),
      }),
      setSelectedValue: handleSelectChange,
      error: formError?.STATE_OF_ISSUE && attemptedSubmit && !isOptionalField("STATE_OF_ISSUE", formOptionalFields),
    },
    {
      name: "LINE_OF_BUSINESS_TYPE",
      label: `Line of business type ${addOptionalLabel("LINE_OF_BUSINESS_TYPE", formOptionalFields)}`,
      input: "singleSelect",
      value: coveragesInfo.LINE_OF_BUSINESS_TYPE ?? null,
      useOptions: () => ({
        optionsLoading: coverageFieldsValuesLoading,
        options: coverageFieldsValues?.coverageLineOfBusinessTypeOptions,
        filterOptions: (options, state) =>
          options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase())),
      }),
      setSelectedValue: handleSelectChange,
      error:
        formError?.LINE_OF_BUSINESS_TYPE &&
        attemptedSubmit &&
        !isOptionalField("LINE_OF_BUSINESS_TYPE", formOptionalFields),
    },
    {
      name: "COVERAGE_PRODUCT_TYPE",
      label: `Coverage product type ${addOptionalLabel("COVERAGE_PRODUCT_TYPE", formOptionalFields)}`,
      input: "singleSelect",
      value: coveragesInfo.COVERAGE_PRODUCT_TYPE ?? null,
      useOptions: () => ({
        optionsLoading: coverageFieldsValuesLoading,
        options: coverageFieldsValues?.coverageProductTypeOptions,
        filterOptions: (options, state) =>
          options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase())),
      }),
      setSelectedValue: handleSelectChange,
      error:
        formError?.COVERAGE_PRODUCT_TYPE &&
        attemptedSubmit &&
        !isOptionalField("COVERAGE_PRODUCT_TYPE", formOptionalFields),
    },
    {
      name: "DEPENDENT_CODE",
      label: `Dependent code ${addOptionalLabel("DEPENDENT_CODE", formOptionalFields)}`,
      input: "none",
      value: "00",
      options: [{ id: "00", label: "00" }],
    },
  ];

  useEffect(() => {
    if (healthPlan) {
      getCoverageFieldsDistinctValuesByHealthPlan({ queryParams: { healthPlanName: healthPlan } });
    }
  }, [healthPlan, getCoverageFieldsDistinctValuesByHealthPlan]);

  const columnOne = coveragesSection.slice(0, 6);
  const columnTwo = coveragesSection.slice(6, 12);
  const columnThree = coveragesSection.slice(12, 16);

  const gridStyles = {
    paddingRight: 2,
  };

  return (
    <>
      <Grid container columns={36} data-testid={"coverages-info-section"} spacing={2}>
        <Grid item width="33%">
          <Stack spacing={2}>
            <RenderFormSection
              section={{
                sectionData: coveragesInfo,
              }}
              inputs={columnOne}
              inputItemGridStyleProps={coveragesGridStyles}
            />
          </Stack>
        </Grid>
        <Grid item width="33%">
          <Stack spacing={2}>
            <RenderFormSection
              section={{
                sectionData: coveragesInfo,
              }}
              inputs={columnTwo}
              inputItemGridStyleProps={gridStyles}
            />
          </Stack>
        </Grid>
        <Grid item width="33%">
          <Stack spacing={2} paddingTop={9.3}>
            <RenderFormSection
              section={{
                sectionData: coveragesInfo,
              }}
              inputs={columnThree}
              inputItemGridStyleProps={gridStyles}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
