import {
  Body1,
  colorsLight,
  H1,
  H6,
  InformativeModal,
  InlineButton,
  PrimaryButton,
  TextField,
} from "@coherehealth/common";
import { Grid, makeStyles } from "@material-ui/core";
import { Row } from "components/ServiceRequestStatusDisplay/utils";
import LaunchIcon from "@material-ui/icons/Launch";
import { stringify as stringifyQueryString, parse as parseQueryString } from "qs";

import React, { useCallback, useEffect, useState } from "react";
import { useValidateIfEmailAlreadyExists } from "@coherehealth/core-platform-api";
import { isEmailValid } from "components/OrganizationOnboardingPage/shared";
import { useLocation, useNavigate } from "react-router";
import { getTINDisplayValue, isTinValid, updateTinFromDisplayValue } from "util/providerUtils";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialUserEmail: string;
}
const useStyles = makeStyles((theme) => ({
  header: {
    color: "#212936",
  },
  customContent: {
    width: 812,
    padding: theme.spacing(3, 3, 7, 3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  primaryButton: {
    width: "33%",
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 5),
    minWidth: theme.spacing(8),
  },
  patientLookupSubHeader: {
    color: theme.palette.text.secondary,
  },
  customMUIDialogContent: {
    background: colorsLight.background.light,
    padding: theme.spacing(3, 7),
  },
  linkDetails: {
    display: "flex",
    height: theme.spacing(4),
    alignItems: "center",
    gap: theme.spacing(1),
    flexShrink: 0,
    color: colorsLight.font.secondary,
  },
  registerationHeader: {
    textDecoration: "none",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: 400,
    paddingTop: "3px",
    paddingBottom: "1px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    // fontSize: "15.5px",
  },
  registerationGuideButton: {
    paddingTop: theme.spacing(0.3),
    paddingBottom: 0,
  },
  form: {
    justifyContent: "center",
  },
  formBodyRow: {
    // We want the width of the form elements to be 480, excluding the paddings of 12.
    // 480 + 12 + 12 = 504
    width: 504,
    flexBasis: 504,
  },
  subheaderRow: {
    padding: theme.spacing(0.5, 0, 1.5, 0), // replaces a Row's padding and makes the top margin 4 (adds up to 16 with the above row)
  },
  subBody: {
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export const SSORedirectModal = ({ open, setOpen, initialUserEmail }: Props) => {
  const [userEmail, setUserEmail] = React.useState<string>(initialUserEmail);
  const [emailEntryError, setEmailEntryError] = useState(false);
  const [validEmailError, setValidEmailError] = useState(false);
  const [organizationTin, setOrganizationTin] = useState<string>("");

  const {
    data: verifyIfEmailAlreadyExists,
    refetch: checkVerifyIfEmailAlreadyExists,
    loading: verifyIfEmailAlreadyExistsLoading,
  } = useValidateIfEmailAlreadyExists({
    queryParams: {
      email: userEmail,
    },
    lazy: true,
  });
  const doesEmailAlreadyExist =
    !verifyIfEmailAlreadyExistsLoading &&
    verifyIfEmailAlreadyExists?.userAlreadyExists &&
    !(initialUserEmail === userEmail);

  const continueDisabled =
    emailEntryError ||
    !isTinValid(organizationTin) ||
    !userEmail ||
    !organizationTin ||
    doesEmailAlreadyExist ||
    verifyIfEmailAlreadyExistsLoading;

  useEffect(() => {
    if (doesEmailAlreadyExist) {
      setValidEmailError(true);
    } else {
      setValidEmailError(false);
    }
  }, [doesEmailAlreadyExist]);

  const location = useLocation();
  const navigate = useNavigate();

  const handleVerifyClick = useCallback(async () => {
    try {
      if (!initialUserEmail) {
        const result = await checkVerifyIfEmailAlreadyExists();
        if (result?.userAlreadyExists) {
          return;
        }
      }

      const queryParams = location.search;
      const { fromIdp, ...otherParams } = parseQueryString(queryParams.replace(/^[?]/, ""));
      const returnTo = `${location.pathname}?${stringifyQueryString(otherParams)}`;

      const newQueryParams = stringifyQueryString({
        returnTo,
        organizationTin,
        userEmail,
      });

      setOpen(false);
      navigate(`/authenticated_organization_onboarding?${newQueryParams}`);
    } catch (error) {
      console.error("Error verifying account:", error);
    }
  }, [
    checkVerifyIfEmailAlreadyExists,
    initialUserEmail,
    location.pathname,
    location.search,
    navigate,
    organizationTin,
    setOpen,
    userEmail,
  ]);

  // Logic discussed here:
  // https://coherehealth.atlassian.net/browse/COH-6144?focusedCommentId=221394
  const disableEmailEdit = Boolean(initialUserEmail) && isEmailValid(initialUserEmail); // TBD: && email != "someplaceholdervalue"
  const classes = useStyles();
  return (
    <>
      <InformativeModal
        dialogWidth="md"
        noCloseIcon
        open={open}
        headerText={""} // styles wrong on the header so make it ourself below
        subHeaderElement={
          <Grid container spacing={3} component="form" className={classes.form}>
            <Row>
              <H1 className={classes.header}>Connect your account to an organization</H1>
            </Row>
            <div className={classes.subheaderRow}>
              <Body1 className={classes.patientLookupSubHeader}>
                This ensures secure access and streamlines team collaboration for better patient care.
              </Body1>
            </div>
            <Row className={classes.formBodyRow}>
              <TextField
                fullWidth
                label={"Practice TIN"}
                value={getTINDisplayValue(organizationTin)}
                onChangeValue={updateTinFromDisplayValue((formattedTin) => setOrganizationTin(formattedTin))}
              />
            </Row>
            <Row className={classes.formBodyRow}>
              <TextField
                fullWidth
                label={"Your email"}
                value={userEmail}
                onChangeValue={async (newEmail) => {
                  setUserEmail(newEmail);
                  setEmailEntryError(!isEmailValid(newEmail));
                  setValidEmailError(false);
                }}
                error={emailEntryError || (doesEmailAlreadyExist && validEmailError)}
                helperText={
                  doesEmailAlreadyExist && validEmailError ? "An account with this email already exists." : ""
                }
                disabled={disableEmailEdit}
              />
            </Row>
            <Row className={classes.formBodyRow}>
              <PrimaryButton fullWidth disabled={continueDisabled} onClick={handleVerifyClick}>
                Verify your account
              </PrimaryButton>
            </Row>
            <Row className={classes.formBodyRow}>
              <Body1 className={classes.subBody}>
                By verifying your account, you acknowledge that the TIN you register with will be visible to your
                organization and Cohere staff.
              </Body1>
            </Row>
            <Row className={classes.formBodyRow}>
              <div className={`${classes.linkDetails}`}>
                <H6 className={classes.registerationHeader}>Need help with registration?</H6>
                <InlineButton
                  className={`${classes.registerationGuideButton} ${classes.registerationHeader}`}
                  onClick={() => {
                    window.open(
                      "https://payerinfo.zendesk.com/hc/en-us/articles/11791874008471-How-do-I-register-for-a-Cohere-account#h_01HFE3GQEWSDQM7SSX6HGNDRKJ",
                      "_blank"
                    );
                  }}
                  startIcon={<LaunchIcon />}
                >
                  View our step-by-step guide
                </InlineButton>
              </div>
            </Row>
          </Grid>
        }
        noSubHeaderElementPadding
        customButtonStyle={classes.primaryButton}
        showDivider={false}
        customContentStyle={classes.customContent}
        onClose={() => {}}
      />
    </>
  );
};
