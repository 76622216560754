import trim from "lodash/trim";
import {
  UnitedStates,
  BaseReview,
  PreferredLabelFeedback,
  EditedPreferredLabel,
  FacilityCategory,
  GuidelinePolicyMap,
  Guideline,
  Indication,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { ButtonBase, Divider, makeStyles, styled } from "@material-ui/core";
import { colorsLight } from "../../themes";
import { H4, Body1, Caption } from "../Typography";
import Mustache from "mustache";
import { POLICY_UNITED_STATES_OPTIONS, dateAndTimeParsedFromDateStr, isGuidelineCriteriaMet } from "../../util";
import { Card } from "../../components";
import { useMemo, Dispatch, SetStateAction } from "react";

export function initialLetterState(parsedMarkupText: ParsedBlock[]) {
  const result: { [index: number]: string } = {};
  parsedMarkupText.forEach((item, index) => {
    result[index] = item.text || "";
  });

  return result;
}

export interface RenderDenialLanguageProps {
  parsedMarkupText: ParsedBlock[];
  translatedMdNoteEnabled: boolean;
  templateMapping: { [key: string]: string | number | undefined };
  letterState: { [key: number]: string };
}

export function renderBlockAsMarkup(block: ParsedBlock, textContent: string): string {
  let base = textContent;
  if (block.type === "DYNAMIC") {
    base = "(( " + textContent + " ))";
  } else if (block.type === "INSTRUCTIVE") {
    base = "<< " + textContent + " >>";
  } else if (block.type === "INJECTED") {
    base = "{{ " + textContent + " }}";
  } else if (block.type === "MDNOTE") {
    base = "{{ translatedMdNote }}";
  } else if (block.type === "GUIDELINES_DENIED") {
    base = "{{ guidelinesDenied }}";
  }
  return !block.staticSuffix ? base : `${base}${block.staticSuffix}`;
}

export function renderAsMarkup(blocks: ParsedBlock[], letterState: { [key: number]: string }): string {
  let result = "";
  blocks.forEach((block, index) => {
    const editedText = letterState[index];

    result += renderBlockAsMarkup(block, editedText);
  });
  return result;
}

export function renderDenialLanguage({
  parsedMarkupText,
  templateMapping,
  translatedMdNoteEnabled,
  letterState,
}: RenderDenialLanguageProps) {
  let compiledEditedDenialText = "";
  parsedMarkupText?.forEach((item, index) => {
    if (
      item.type === "INJECTED" ||
      item.type === "GUIDELINES_DENIED" ||
      (item.type === "MDNOTE" && translatedMdNoteEnabled)
    ) {
      const editedDenialText = Mustache.render("{{" + item.text + "}}", templateMapping);
      compiledEditedDenialText += htmlDecode(editedDenialText);
      if (editedDenialText && item.staticSuffix) {
        compiledEditedDenialText += item.staticSuffix;
      }
    } else if (!(item.type === "INSTRUCTIVE" || item.type === "MDNOTE")) {
      const text = letterState[index];
      compiledEditedDenialText += text;
      if (trim(text) && item.staticSuffix) {
        compiledEditedDenialText += item.staticSuffix;
      }
    }
  });
  return compiledEditedDenialText;
}

function htmlDecode(input: string) {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export function takeWhileCharEqual(char: string, s: string): string {
  let res = "";
  let input = s;
  while (input.startsWith(char)) {
    res += input[0];
    input = input.slice(1);
  }

  return res;
}

export function splitWhenCharsEnd(char: string, s: string): [string, string] {
  const prefix = takeWhileCharEqual(char, s);
  const rest = s.substring(prefix.length);
  return [prefix, rest];
}

export function splitSuffix(s: string) {
  const [spaces, newStr] = splitWhenCharsEnd(" ", s);
  const [newlines, staticStr] = splitWhenCharsEnd("\n", newStr);
  return [spaces + newlines, staticStr];
}

export function onSameLineAs(prevBlocks: ParsedBlock[]): boolean {
  const staticBlocks = prevBlocks.filter((block) => block.type === "STATIC");
  const prevStaticBlock = staticBlocks[staticBlocks.length - 1];
  if (prevStaticBlock && prevStaticBlock.text.endsWith("\n")) {
    return false;
  } else {
    return Boolean(prevStaticBlock);
  }
}

export function combineTrailingSuffix(parsedBlocks: ParsedBlock[]): ParsedBlock[] {
  const result: ParsedBlock[] = [];

  let i = 0;
  while (i < parsedBlocks.length) {
    const block = parsedBlocks[i];
    if (!["MDNOTE", "INJECTED", "INSTRUCTIVE", "DYNAMIC", "GUIDELINES_DENIED"].includes(block.type)) {
      result.push(block);
    } else {
      const nextBlock = parsedBlocks[i + 1];

      if (onSameLineAs(result)) {
        result.push(block);
      } else if (!nextBlock || nextBlock.type !== "STATIC") {
        result.push(block);
      } else {
        const [suffix, staticText] = splitSuffix(nextBlock.text);
        if (!suffix) {
          result.push(block);
        } else {
          const newBlock = {
            ...block,
            staticSuffix: suffix,
            endIndex: block.endIndex + suffix.length,
          };
          const modifiedNextBlock = {
            ...nextBlock,
            text: staticText,
            startIndex: nextBlock.startIndex + suffix.length,
          };
          result.push(newBlock);
          if (modifiedNextBlock.text) {
            result.push(modifiedNextBlock);
          }
          ++i;
        }
      }
    }
    ++i;
  }
  return result;
}

// utility function that parses a string of denials template language, and generates a list of blocks to render as a configurable, dynamic Denials Letter
export const parseMarkup = (
  markedUpDenialLanguage: string,
  mdNote?: string,
  setMdNote?: Dispatch<SetStateAction<string>>,
  guidelinesDenied?: string,
  setGuidelinesDenied?: Dispatch<SetStateAction<string>>
) => {
  const instructivePass = Mustache.parse(markedUpDenialLanguage, ["<<", ">>"]).filter(isValueBlock);
  const dynamicPass = Mustache.parse(markedUpDenialLanguage, ["((", "))"]).filter(isValueBlock);
  const injectedPass = Mustache.parse(markedUpDenialLanguage).filter(isValueBlock);

  const result: ParsedBlock[] = [
    ...instructivePass.map((item) => {
      return blockGenerator(item[1], "INSTRUCTIVE", item[2], item[3]);
    }),
    ...dynamicPass.map((item) => {
      return blockGenerator(item[1], "DYNAMIC", item[2], item[3]);
    }),
    ...injectedPass.map((item) => {
      if (item[1] === "translatedMdNote") {
        if (mdNote && setMdNote) {
          setMdNote(mdNote);
        }
        return blockGenerator(item[1], "MDNOTE", item[2], item[3]);
      }
      if (item[1] === "guidelinesDenied") {
        if (guidelinesDenied && setGuidelinesDenied) {
          setGuidelinesDenied(guidelinesDenied);
        }
        return blockGenerator(item[1], "GUIDELINES_DENIED", item[2], item[3]);
      }
      return blockGenerator(item[1], "INJECTED", item[2], item[3]);
    }),
  ].sort(generatedBlocKSorter);

  const extractedBlocks: [number, number][] = result.map((item) => {
    return [item.startIndex, item.endIndex];
  });

  extractedBlocks.unshift([0, 0]);
  extractedBlocks.push([markedUpDenialLanguage.length, markedUpDenialLanguage.length]);

  for (let index = 0; index < extractedBlocks.length - 1; index++) {
    let text = markedUpDenialLanguage.substring(extractedBlocks[index][1], extractedBlocks[index + 1][0]);
    text.length &&
      result.push(blockGenerator(text, "STATIC", extractedBlocks[index][1], extractedBlocks[index + 1][0]));
  }

  return combineTrailingSuffix(result.sort(generatedBlocKSorter));
};

type TemplateSpan = ReturnType<typeof Mustache.parse> extends Array<infer T> ? T : never;

const isValueBlock = (ipt: TemplateSpan) => ipt[0] === "name";

const generatedBlocKSorter = (a: ParsedBlock, b: ParsedBlock) => {
  return a.startIndex - b.startIndex;
};

// utility function that generates ParsedBlocks
export const blockGenerator = (
  blockText: string,
  textType: ParsedBlockTextType,
  blockStartIndex: number,
  blockEndIndex: number
) => {
  const block: ParsedBlock = {
    text: blockText,
    type: textType,
    startIndex: blockStartIndex,
    endIndex: blockEndIndex,
  };
  return block;
};

// type used to describe how to render ParsedBlock.text
export type ParsedBlockTextType = "STATIC" | "DYNAMIC" | "INSTRUCTIVE" | "INJECTED" | "MDNOTE" | "GUIDELINES_DENIED";

// type used to facilitate mapping parsed template string into components
export type ParsedBlock = {
  text: string;
  type: ParsedBlockTextType;
  startIndex: number;
  endIndex: number;
  staticPrefix?: string;
  staticSuffix?: string;
};

// utility functions to label a states field
export const statesOrderer = (statesArray: UnitedStates[]) => {
  return Array.from(new Set(statesArray))
    .filter((item) => !!item)
    .sort((stateOne, stateTwo) => stateOne.localeCompare(stateTwo));
};

export const statesLabelAbbreviator = (statesArray: string[]) =>
  statesArray.length === POLICY_UNITED_STATES_OPTIONS.length ? "All" : statesArray.join(", ");

// styled components below all used to render DenialLetter components in light themed styles in Convene
// eslint-disable-next-line cohere-react/no-mui-styled-import
export const SimulatedDenialLetterHeader = styled(H4)(({ theme }) => ({
  color: colorsLight.font.main,
  width: "100%",
  margin: theme.spacing(1, 0, 1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const SimulatedDenialLetterDivider = styled(Divider)({
  backgroundColor: colorsLight.gray.divider,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const SimulatedDenialLetterCaption = styled(Caption)({
  color: colorsLight.font.secondary,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const SimulatedDenialLetterInstructiveText = styled(Body1)({
  color: colorsLight.font.secondary,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const SimulatedDenialLetterInfoPill = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.04)",
  padding: theme.spacing(0.45, 1, 0.35, 1),
  boxShadow: "0px",
  borderRadius: theme.spacing(0.5),
  "&:before": {
    content: "none",
  },
  fontSize: 13,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const DenialLetterInfoCard = styled(Card)(({ theme }) => ({
  borderRadius: "10px",
  border: "none",
  padding: theme.spacing(4, 3, 2, 3),
  backgroundColor: colorsLight.background.card,
  boxShadow: "0px 2px 2px 0px #CFE2E7CC",
}));

const useStylesCaptionText = makeStyles((theme) => ({
  thinFont: {
    color: colorsLight.font.secondary,
  },
  thickFontConvene: {
    fontFamily: "Gilroy-Medium",
    color: colorsLight.font.secondary,
  },
  thickFont: {
    fontFamily: "Gilroy-Medium",
  },
  createdText: {
    paddingRight: theme.spacing(2),
  },
}));

interface ReviewTimeDateAuthorCaptionProps {
  reviewDateCreated: string;
  reviewLastUpdated: string;
  reviewCreatedByName: string;
  firstLineText?: string;
  useUpdatedDate?: boolean;
  conveneStyling?: boolean;
  clinicalReviewView?: boolean;
}

export function ReviewTimeDateAuthorCaption({
  reviewDateCreated,
  reviewLastUpdated,
  reviewCreatedByName,
  firstLineText,
  useUpdatedDate,
  conveneStyling,
  clinicalReviewView,
}: ReviewTimeDateAuthorCaptionProps) {
  const classes = useStylesCaptionText();
  const { date: createdDate, time: createdTime } = useMemo(
    () => dateAndTimeParsedFromDateStr(reviewDateCreated),
    [reviewDateCreated]
  );
  const { date: updatedDate, time: updatedTime } = useMemo(
    () => dateAndTimeParsedFromDateStr(reviewLastUpdated),
    [reviewLastUpdated]
  );
  return (
    <Caption className={classes.createdText}>
      <span className={classes.thinFont}>{firstLineText || "Started at "}</span>
      <span
        className={
          clinicalReviewView ? classes.thinFont : conveneStyling ? classes.thickFontConvene : classes.thickFont
        }
      >
        {useUpdatedDate ? updatedDate : createdDate}
      </span>
      <span className={classes.thinFont}> at </span>
      <span
        className={
          clinicalReviewView ? classes.thinFont : conveneStyling ? classes.thickFontConvene : classes.thickFont
        }
      >
        {useUpdatedDate ? updatedTime : createdTime}
      </span>
      <span className={classes.thinFont}> by </span>
      <span
        className={
          clinicalReviewView ? classes.thinFont : conveneStyling ? classes.thickFontConvene : classes.thickFont
        }
      >
        {reviewCreatedByName}
      </span>
    </Caption>
  );
}

interface ReviewCompletionTimeDateCaptionProps {
  multiCoverage: boolean;
  reviewDateCompleted: string;
  reviewOutcome: string;
  reviewStatus: BaseReview["reviewStatus"];
  reviewCompletedByName?: string;
  reviewCreatedByName?: string;
  clinicalReviewView?: boolean;
  shouldDecorateText?: boolean;
  handleClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export function ReviewCompletionTimeDateCaption({
  multiCoverage,
  reviewDateCompleted,
  reviewStatus,
  reviewOutcome,
  reviewCompletedByName,
  reviewCreatedByName,
  clinicalReviewView,
  shouldDecorateText,
  handleClick,
}: ReviewCompletionTimeDateCaptionProps) {
  const classes = useStylesCaptionText();
  const { date: completedDate, time: completedTime } = useMemo(
    () => dateAndTimeParsedFromDateStr(reviewDateCompleted),
    [reviewDateCompleted]
  );

  const isDiscarded = reviewStatus === "DISCARDED";
  return (
    <Caption>
      {multiCoverage ? (
        <span className={classes.thinFont}>Multi-coverage decision on </span>
      ) : reviewOutcome ? (
        <span className={classes.thinFont}>{isDiscarded ? "Discarded" : reviewOutcome} on </span>
      ) : (
        <span className={classes.thinFont}>{isDiscarded ? "Discarded" : "Completed"} on </span>
      )}
      <span className={clinicalReviewView ? classes.thinFont : classes.thickFont}>{completedDate}</span>
      <span className={classes.thinFont}> at </span>
      <span className={clinicalReviewView ? classes.thinFont : classes.thickFont}>{completedTime}</span>
      <span className={classes.thinFont}> by </span>
      <ButtonBase onClick={handleClick} disabled={!shouldDecorateText} style={{ fontFamily: "inherit" }}>
        <span
          className={clinicalReviewView ? classes.thinFont : classes.thickFont}
          style={{
            textDecoration: shouldDecorateText ? "underline" : "none",
            cursor: shouldDecorateText ? "pointer" : "default",
          }}
        >
          {reviewCompletedByName || reviewCreatedByName}
        </span>
      </ButtonBase>
    </Caption>
  );
}

export const displayProcedureCodePreferredLabel = (preferredLabel?: string | undefined) => {
  if (!preferredLabel || preferredLabel.trim().length === 0) {
    return "- -";
  } else {
    return preferredLabel;
  }
};

export const reduceToSimplePreferredLabelUpdate = (
  preferredLabelUpdate: PreferredLabelFeedback[]
): EditedPreferredLabel[] => {
  return preferredLabelUpdate.map((eachPreferredLabel) => ({
    code: eachPreferredLabel.code,
    label: eachPreferredLabel.submittedPreferredLabel,
  }));
};

export type EncounterTypeOptionId = FacilityCategory | "ALL";

export const containsAllEncounterTypes = (encounterTypes: FacilityCategory[]) => {
  return encounterTypes.length >= 2 && encounterTypes.includes("INPATIENT") && encounterTypes.includes("OUTPATIENT");
};

// Convert the template's encounterTypes field to a dropdown option.
export const parseEncounterTypesFromTemplate = (encounterTypes?: FacilityCategory[]): EncounterTypeOptionId => {
  // Having both is the same as having none. It means we don't do any filtering.
  if (!encounterTypes?.length || containsAllEncounterTypes(encounterTypes)) {
    return "ALL";
  }

  // If neither all nor none, there should be only 1 encounter type in the array.
  return encounterTypes?.[0];
};

// Convert a dropdown option back to an array of encounterTypes.
// We intentionally send an empty array to clear the field, since both is the same as none.
export const parseEncounterTypesToPayload = (selectionId: EncounterTypeOptionId): FacilityCategory[] => {
  return selectionId === "ALL" ? [] : [selectionId];
};

const removeDuplicates = (array: string[]): string[] => {
  return Array.from(new Set(array));
};

type GuidelineWithIndicationsInfo = {
  guideline: Guideline;
  checkedIndications: Indication[];
  unmetIndications: Indication[];
};

export const getGuidelinesDenied = (
  guidelinePolicyMaps: GuidelinePolicyMap[] | undefined,
  selectedGuidelineIds: string[] | undefined
): string => {
  if (!guidelinePolicyMaps || !selectedGuidelineIds || selectedGuidelineIds.length === 0) {
    return "";
  }
  const guidelinesWithIndicationsInfo: GuidelineWithIndicationsInfo[] = guidelinePolicyMaps.flatMap(
    (guidelinePolicyMap) => {
      if (!guidelinePolicyMap.guidelines) {
        return [];
      }
      return guidelinePolicyMap.guidelines.map((guideline) => ({
        guideline,
        checkedIndications: guidelinePolicyMap.checkedIndications || [],
        unmetIndications: guidelinePolicyMap.unmetIndications || [],
      }));
    }
  );

  const selectedGuidelines: GuidelineWithIndicationsInfo[] = guidelinesWithIndicationsInfo.filter(
    (guidelineWithIndicationInfo) =>
      guidelineWithIndicationInfo.guideline.id &&
      selectedGuidelineIds.includes(guidelineWithIndicationInfo.guideline.id)
  );

  const eligibleGuidelines: Guideline[] = selectedGuidelines
    .filter((guidelineWithIndicationInfo) => {
      const { guideline, checkedIndications, unmetIndications } = guidelineWithIndicationInfo;

      const isCriteriaMet = isGuidelineCriteriaMet(guideline.indications, checkedIndications, unmetIndications);

      return isCriteriaMet !== null && !isCriteriaMet;
    })
    .map((guidelineWithIndicationInfo) => guidelineWithIndicationInfo.guideline);

  const policyTitles: string[] = eligibleGuidelines
    .map((guideline: Guideline) => guideline.policyTitle)
    .filter(Boolean) as string[];

  if (policyTitles.length === 0) {
    return "";
  }

  return removeDuplicates(policyTitles)
    .map((title, index) => `${index + 1}. ${title.trim()}`)
    .join("\n");
};
