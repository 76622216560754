import { z } from "zod";
import { ReviewChatbotFinding } from "@coherehealth/core-platform-api";

const WebSocketResponseSchema = z.object({
  answer: z.string().optional(),
  user_question: z.string().optional(),
  response_metadata: z.object({
    stopReason: z.union([z.string(), z.null(), z.undefined()]).optional(),
  }),
  sources: z.array(
    z.object({
      attachment_id: z.string().optional(),
      page: z.number().optional(),
      score: z.number().optional(),
      span_text: z.string().optional(),
    })
  ),
});

const isGuardrailsFinding = (finding?: ReviewChatbotFinding) => Boolean(finding?.guardrailsActivated);

export type ReviewChatbotWebSocketResponse = z.infer<typeof WebSocketResponseSchema>;

export { WebSocketResponseSchema, isGuardrailsFinding };
