import { useEffect, useRef } from "react";
import { Body1, LoadingIndicator } from "@coherehealth/common";
import { ReviewChatbotFinding } from "@coherehealth/core-platform-api";
import { useChatbotStyles } from "./chatbotReviewAssistantStyles";
import { IReviewChatbotFindingHolder } from "components/ClinicalReview/Review/hooks/useAutomatedReviewChatbotFindings";
import ChatbotSynchronousFinding from "./ChatbotSynchronousFinding";

interface Props {
  loading: boolean;
  chatbotFindings: IReviewChatbotFindingHolder[];
  setFeedback: (id: string, updatedChatbotFinding: Partial<ReviewChatbotFinding>) => void;
  currentUserInput: string | undefined;
}

export default function ChatbotSynchronousContent({ currentUserInput, loading, chatbotFindings, setFeedback }: Props) {
  const classes = useChatbotStyles({});
  const lastDivRef = useRef<HTMLDivElement>(null);
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else if (lastDivRef.current?.scrollIntoView || (loading && currentUserInput)) {
      lastDivRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatbotFindings.length, currentUserInput, loading]);

  return (
    <>
      {chatbotFindings.map((findingHolder) => (
        <ChatbotSynchronousFinding setFeedback={setFeedback} findingHolder={findingHolder} />
      ))}
      {loading && currentUserInput && (
        <div>
          <div className={classes.outgoingMessageWrapper}>
            <div className={classes.outgoingMessage}>
              <Body1>{currentUserInput}</Body1>
            </div>
          </div>

          <div className={classes.incomingMessageWrapper}>
            <div className={classes.incomingMessage}>
              <Body1>
                <LoadingIndicator />
              </Body1>
            </div>
          </div>
        </div>
      )}
      <div ref={lastDivRef} />
    </>
  );
}
