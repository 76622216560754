import { Dispatch, SetStateAction, useCallback } from "react";
import { Caption, DateSelect } from "@coherehealth/common";
import { Patient } from "@coherehealth/core-platform-api";

// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {
  ContinuationConfiguration,
  FormConfiguration,
} from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import useAuthValidityWindowText from "hooks/useAuthValidityWindowText";
import { OscarServiceType } from "@coherehealth/core-platform-api";
import { DateSelectWidth, ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { CoverageCheck, getPatientHealthPlanName } from "util/patientUtils";
import { Grid } from "@material-ui/core";
import { isValid } from "date-fns";
import { useCalculateInitializedEndDate } from "components/AuthBuilder/common";
import PatientDateSelectionMessage from "components/AuthBuilder/FillFormsAuthSubmission/PatientDateSelectionMessage";

interface Props {
  minAdmissionDate: Date;
  minDuration?: false | 0 | Date | undefined;
  admissionDate: Date | undefined;
  expectedDischargeDate: Date | undefined;
  error: boolean;
  dateCreated?: string;
  patient: Patient | null;
  serviceType?: OscarServiceType;
  expectedAdmissionDate: Date | undefined;
  attemptedSubmit?: boolean;
  formContent?: ServiceRequestFormContent;
  formConfiguration: FormConfiguration | ContinuationConfiguration;
  startDateCoverage?: CoverageCheck;
  endDateCoverage?: CoverageCheck;
  isDraftAuth: boolean;
  onUserEdit?: Dispatch<ServiceRequestFormContent>;
  showExceededDurationLimitMessageErrorState: boolean | undefined;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  setClearEndDate?: (clearEndDate: boolean) => void;
  dateChangeValidator?: () => void;
  isStartDateInRange: boolean;
  setIsStartDateInRange: (date: boolean) => void;
  isEndDateInRange: boolean;
  setIsEndDateInRange: (date: boolean) => void;
  disabled?: boolean;
}

export default function AdmissionAndExpectedDischargeDateSelect({
  minAdmissionDate,
  minDuration,
  admissionDate,
  expectedDischargeDate,
  patient,
  dateCreated,
  serviceType,
  expectedAdmissionDate,
  attemptedSubmit,
  formContent,
  setFormContent,
  startDateCoverage,
  endDateCoverage,
  isDraftAuth,
  setClearEndDate,
  showExceededDurationLimitMessageErrorState,
  onUserEdit,
  error,
  dateChangeValidator,
  isStartDateInRange,
  setIsStartDateInRange,
  isEndDateInRange,
  setIsEndDateInRange,
  formConfiguration,
  disabled,
}: Props) {
  // only one of inpatientDays and expectedInpatientDays will be set; the former when dates are in the past and latter when dates are in the future
  const { spacing } = useTheme();
  const classes = useStyles();

  const { message: authWindowText } = useAuthValidityWindowText({
    patientId: patient?.id,
    startDate: admissionDate,
    isInpatient: true,
    serviceType,
  });

  const startDateChangeValidator = useCallback(() => {
    setIsStartDateInRange(Boolean(startDateCoverage?.inRange));
  }, [startDateCoverage, setIsStartDateInRange]);

  const endDateChangeValidator = useCallback(() => {
    setIsEndDateInRange(Boolean(endDateCoverage?.inRange));
  }, [endDateCoverage, setIsEndDateInRange]);
  /**
   * This sets the service request form content, but it also runs the onUserEdit callback.
   *
   * This is useful if we want to do something after the user explicitly edits the service request form content, not
   * just whenever the serviceRequestForm content changes (like from a PAR check effect or whatever)
   * @param newFormContent
   */
  const setFormContentOnUserEdit: Dispatch<SetStateAction<ServiceRequestFormContent>> = useCallback(
    (setStateAction) => {
      // In order to get the next state value to pass to onUserEdit, we actually need to call setFormContent
      // because we might in fact have a function, not a realized value
      setFormContent((prev) => {
        const newFormContent: ServiceRequestFormContent =
          typeof setStateAction === "function" ? setStateAction(prev) : setStateAction;
        onUserEdit?.(newFormContent);
        return newFormContent;
      });
    },
    [onUserEdit, setFormContent]
  );

  const setAdmissionDate = useCallback(
    (newStartDate) => {
      dateChangeValidator || startDateChangeValidator();
      formContent && setFormContentOnUserEdit({ ...formContent, admissionDate: newStartDate });
    },
    [dateChangeValidator, startDateChangeValidator, setFormContentOnUserEdit, formContent]
  );

  const setExpectedAdmissionDate = useCallback(
    (newStartDate) => {
      startDateChangeValidator();
      formContent &&
        setFormContentOnUserEdit({
          ...formContent,
          expectedAdmissionDate: newStartDate,
          startDate: newStartDate,
        });
    },
    [startDateChangeValidator, setFormContentOnUserEdit, formContent]
  );

  const dateCreatedParsed = dateCreated ? Date.parse(dateCreated) : new Date();

  const isPlanned = admissionDate instanceof Date && admissionDate > dateCreatedParsed;
  const expectedAdmissionDateValue = admissionDate || expectedAdmissionDate;
  const calculateInitializedEndDate = useCalculateInitializedEndDate(
    getPatientHealthPlanName(patient || undefined) || undefined
  );
  const endDateCalculation = useCallback(
    (admissionDate: Date) => {
      if (isValid(admissionDate) && isDraftAuth) {
        admissionDate ? setAdmissionDate(admissionDate) : setExpectedAdmissionDate(admissionDate);
        const updatedEndDate = calculateInitializedEndDate(
          admissionDate,
          formContent?.clinicalServices || [],
          patient?.coverages || []
        );
        if (updatedEndDate && updatedEndDate !== expectedDischargeDate) {
          return updatedEndDate;
        }
      }
      return formContent?.expectedDischargeDate;
    },
    [
      isDraftAuth,
      formContent?.expectedDischargeDate,
      formContent?.clinicalServices,
      setAdmissionDate,
      setExpectedAdmissionDate,
      calculateInitializedEndDate,
      patient?.coverages,
      expectedDischargeDate,
    ]
  );

  const startDateError = startDateCoverage
    ? !startDateCoverage?.inRange
    : attemptedSubmit && !formContent?.expectedAdmissionDate;
  const endDateError = endDateCoverage
    ? !endDateCoverage?.inRange
    : attemptedSubmit && !formContent?.expectedDischargeDate;

  return (
    <>
      <Grid container wrap="nowrap">
        <DateSelect
          onDateChange={(newStartDate) => {
            const updatedEndDate = endDateCalculation(newStartDate);

            setFormContent((currentFormContent) => {
              return {
                ...currentFormContent,
                startDate: expectedDischargeDate ? newStartDate : formContent?.startDate,
                expectedAdmissionDate: newStartDate,
                expectedDischargeDate: updatedEndDate ? updatedEndDate : expectedDischargeDate,
                endDate: updatedEndDate ? updatedEndDate : formContent?.endDate,
              };
            });
            startDateChangeValidator();
          }}
          label="Start date"
          value={expectedAdmissionDateValue || null}
          minDate={minAdmissionDate}
          error={error || startDateError}
          TextFieldProps={{
            className: classes.dateSelect,
          }}
          helperText={
            (!startDateCoverage?.inRange && startDateCoverage?.messageToDisplay) ||
            (attemptedSubmit && !expectedAdmissionDateValue && "Required")
          }
          attemptedSubmit={attemptedSubmit || !isStartDateInRange || showExceededDurationLimitMessageErrorState}
          disabled={disabled}
        />

        <>
          <Box component="span" display="flex" justifyContent="center" m="20px 0 0" width={spacing(1)}>
            -
          </Box>
          <DateSelect
            onDateChange={(newEndDate) => {
              setFormContent((currentFormContent) => {
                return {
                  ...currentFormContent,
                  expectedDischargeDate: newEndDate,
                };
              });
              endDateChangeValidator();
              setClearEndDate?.(false);
            }}
            label="End date"
            value={expectedDischargeDate || formContent?.endDate || null}
            minDate={minDuration || admissionDate || expectedAdmissionDate}
            minDateMessage={
              minDuration && formContent?.startDate && minDuration >= formContent?.startDate
                ? ""
                : "End date cannot be before start date"
            }
            error={(attemptedSubmit && !expectedDischargeDate) || endDateError}
            TextFieldProps={{
              className: `${classes.dateSelect} ${classes.dischargeDate}`,
            }}
            helperText={
              (!endDateCoverage?.inRange && endDateCoverage?.messageToDisplay) ||
              (attemptedSubmit && !formContent?.expectedDischargeDate && "Required")
            }
            attemptedSubmit={attemptedSubmit || !isEndDateInRange || showExceededDurationLimitMessageErrorState}
            disabled={disabled}
          />
        </>
      </Grid>
      {isPlanned && authWindowText && <DateNote>{authWindowText}</DateNote>}
      {formContent && (
        <Grid item xs={12} md={12}>
          <PatientDateSelectionMessage
            formContent={formContent}
            coverages={patient?.coverages || []}
            patient={patient}
            formFieldConfigurations={formConfiguration}
          />
        </Grid>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  dateSelect: {
    width: DateSelectWidth,
    marginRight: theme.spacing(1),
    height: "fit-content",
  },
  dischargeDate: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1.5),
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DateNote = styled(Caption)(({ theme }) => ({
  color: theme.palette.text.secondary,
  paddingLeft: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  paddingTop: theme.spacing(1),
}));
