import { formatDateStr } from "@coherehealth/common";
import {
  NurseReview,
  OutOfNetworkReview,
  ServiceRequestResponse,
  ServiceRequestSearchResponse,
} from "@coherehealth/core-platform-api";
import { ServiceCase } from "@coherehealth/qm-api";

type RnReviewOutcome = NurseReview["reviewOutcome"];

export type ExtendedRnReviewOutcome = RnReviewOutcome | "CANNOT_WORK";
export type ReviewReasonOutcomeOption = { id: string; label: string; subLabel: string; outcome: string };
export type ReviewOutcomeOption = {
  id: ExtendedRnReviewOutcome;
  label: string;
  submenu?: ReviewReasonOutcomeOption[];
  required?: boolean;
};

// Types for OON Exception Review submission modal dropdowns
export type OutOfNetworkReviewOutcome = OutOfNetworkReview["reviewOutcome"];
export type OutOfNetworkReviewOutcomeOption = { id: OutOfNetworkReviewOutcome; label: string };

export const unableToWorkCase = (): ServiceCase => {
  const unassignReason = "Unassigned - Unable to work";
  const serviceCase: ServiceCase = {
    caseStatus: "OPEN",
    assignee: {
      name: unassignReason,
      userName: unassignReason,
      salesforceId: "",
    },
  };
  return serviceCase;
};

export const helperTextForTATExtension = (
  TATExtensionDecision: string,
  isEligibleForTATExtension: boolean | undefined,
  serviceRequest: ServiceRequestSearchResponse | ServiceRequestResponse
) => {
  const helperText =
    !serviceRequest?.turnAroundTimeExtension && !isEligibleForTATExtension
      ? `This request is ineligible for a due date extension`
      : serviceRequest?.turnAroundTimeExtension && !isEligibleForTATExtension
      ? `Due date has been extended to ${
          serviceRequest?.urgency?.isExpedited
            ? formatDateStr(serviceRequest.turnAroundTimeExtension.newTurnAroundTime?.expeditedTatDueDate)
            : formatDateStr(serviceRequest.turnAroundTimeExtension.newTurnAroundTime?.regularTatDueDate)
        } on ${formatDateStr(serviceRequest.lastUpdated)}`
      : defaultHelperTextForTATExtension(TATExtensionDecision);
  return helperText;
};

const defaultHelperTextForTATExtension = (TATExtensionDecision: string) => {
  switch (TATExtensionDecision) {
    case "YES":
      return "You will not be able to reverse the extension after this step.";
    default:
      return "";
  }
};
