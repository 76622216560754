import {
  ProcedureCode,
  Unknown,
  ServiceRequestExpansion,
  ServiceRequestCommon,
  DiagnosisCode,
  SemanticProcedureCode,
  ServiceChangeOption,
  NudgeRangeofOptionsMetaData,
} from "@coherehealth/core-platform-api";
import unionBy from "lodash/unionBy";
import { ValidDisplayMessageAction } from "../rule";
import { extractOnAcceptValueArray, withId } from "@coherehealth/common";
import { procedureBucketDataModel } from "components/AuthBuilder/common";

/**
 * This horrifying monster of a type is basically a combination of the update and create payloads
 * for a service request body, together with the filled out representation.
 * The idea is that you can pass in either a pre-submission view of a service request or
 * a post-submission view, and get back something you can continue to treat the same in either case
 */
export type GenericServiceRequest = ServiceRequestCommon &
  Pick<
    ServiceRequestExpansion,
    | "clinicalService"
    | "palCategory"
    | "primaryDiagnosis"
    | "secondaryDiagnoses"
    | "procedureCodes"
    | "placeOfService"
    | "facility"
    | "orderingProvider"
    | "performingProvider"
    | "clinicalServices"
    | "patient"
  > & {
    semanticProcedureCode?: ProcedureCode[];
  };

export default function applyActionToServiceRequest(
  serviceRequest: GenericServiceRequest,
  action?: ValidDisplayMessageAction,
  acceptedDedicatedNudges?: string[],
  groupIds?: string[]
): Partial<GenericServiceRequest> | undefined {
  let newServiceRequest: Partial<GenericServiceRequest> | undefined = undefined;
  const isEncounterTypeNudge = action?.onAcceptAttribute === "encounterType";
  const placeOfService = serviceRequest.placeOfService;
  const validAccept = !isEncounterTypeNudge || Boolean(placeOfService);

  if (
    action?.onAcceptAction === "REPLACE_VALUE" &&
    action?.onAcceptAttribute &&
    typeof action?.onAcceptValue?.value !== "undefined"
  ) {
    const complexFieldUpdate = maybeApplyValuesToField(
      action.onAcceptAttribute,
      serviceRequest,
      action.onAcceptValue.value,
      action?.groupId || "",
      acceptedDedicatedNudges
    );
    if (complexFieldUpdate) {
      newServiceRequest = complexFieldUpdate;
      //below line isn't working for single facility category nudge
    } else if (serviceRequest[action.onAcceptAttribute as keyof GenericServiceRequest]) {
      if (isEncounterTypeNudge && validAccept) {
        newServiceRequest = {
          [action.onAcceptAttribute]: action.onAcceptValue.value,
          placeOfService: placeOfService,
        };
      } else if (!isEncounterTypeNudge) {
        newServiceRequest = {
          [action.onAcceptAttribute]: action.onAcceptValue.value,
        };
      }
    } else if (action.onAcceptAttribute === "urgency.isExpedited") {
      const isExpedited = Boolean(action.onAcceptValue.value);
      newServiceRequest = {
        urgency: {
          isExpedited: isExpedited,
          reasonNote: isExpedited && serviceRequest?.urgency?.reasonNote ? serviceRequest.urgency.reasonNote : "",
        },
      };
    } else if (action.onAcceptAttribute === "rangeOfOptions?SERVICE_CHANGE") {
      /*
        this condition is specifically for range of options
        onAcceptAttribute can only handle one attribute. this delimited attribute contains all the fields the intervention wants to update
      */

      if (acceptedDedicatedNudges) {
        const multiFieldUpdates = applyMultipleFieldUpdate(
          acceptedDedicatedNudges,
          serviceRequest,
          extractOnAcceptValueArray(action),
          groupIds
        );
        if (multiFieldUpdates) {
          newServiceRequest = multiFieldUpdates;
        }
      }
    }
  } else if (
    ["LIMIT_TO_VALUES", "ADD_VALUES", "REMOVE_VALUES"].includes(action?.onAcceptAction || "") &&
    action?.onAcceptAttribute
  ) {
    const sameCodes: any[] | undefined =
      action?.onAcceptAction === "LIMIT_TO_VALUES"
        ? action?.onAcceptValue?.reference === "clinicalService.procedureCodes"
          ? serviceRequest.procedureCodes?.filter((code) =>
              serviceRequest.clinicalService?.semanticProcedureCodes?.map((code) => code.code).includes(code.code)
            )
          : serviceRequest.procedureCodes?.filter((code) =>
              (action?.onAcceptValue?.value as ProcedureCode[]).map((cpt) => cpt.code).includes(code.code)
            )
        : action?.onAcceptAction === "ADD_VALUES"
        ? addValues(serviceRequest, action)
        : action?.onAcceptAction === "REMOVE_VALUES"
        ? serviceRequest.procedureCodes?.filter(
            (code) => !(action?.onAcceptValue?.value as ProcedureCode[])?.map((cpt) => cpt.code).includes(code.code)
          )
        : undefined;
    newServiceRequest = maybeApplyValuesToField(
      action.onAcceptAttribute,
      serviceRequest,
      sameCodes,
      action?.groupId || ""
    );
  }
  return newServiceRequest;
}
function addValues(serviceRequest: GenericServiceRequest, action: ValidDisplayMessageAction) {
  if (action?.onAcceptValue?.value instanceof Array) {
    action?.onAcceptValue?.value.forEach((x: any) => {
      x.units = serviceRequest.units;
    });
  }
  return unionBy(serviceRequest.procedureCodes, action?.onAcceptValue?.value as ProcedureCode[], (x) => x.code);
}
function maybeApplyValuesToField(
  fieldName: string,
  serviceRequest: GenericServiceRequest,
  values: Unknown | undefined,
  actionGroupId: string,
  actionsGroupIds?: string[]
) {
  if (fieldName === "secondaryDiagnoses" && serviceRequest.secondaryDiagnoses) {
    const validValues =
      values instanceof Array ? values.filter((x): x is DiagnosisCode => (x as DiagnosisCode).code !== undefined) : [];
    return {
      secondaryDiagnoses: validValues,
      secondarySemanticDiagnosisCodes: validValues,
    };
  } else if (
    fieldName === "procedureCodes" &&
    (serviceRequest.semanticProcedureCode || serviceRequest.procedureCodes)
  ) {
    const validValues =
      values instanceof Array ? values.filter((x): x is ProcedureCode => (x as ProcedureCode).code !== undefined) : [];
    return {
      procedureCodes: validValues,
      semanticProcedureCodes: validValues,
    };
  } else if (fieldName === "units" && (serviceRequest.semanticProcedureCode || serviceRequest.procedureCodes)) {
    const validValue = typeof values === "number" || typeof values === "string";
    if (!validValue) {
      return serviceRequest;
    }
    const valueInt = typeof values === "string" ? parseInt(values) : values;

    // only update units on codes where units are greater than the ones from the nudge. else keep as-is
    const updatedProcedureCodes =
      serviceRequest?.semanticProcedureCodes?.map((px) => {
        if (px.units && px.units > valueInt) {
          return { ...px, units: valueInt };
        } else {
          return { ...px };
        }
      }) || [];
    const procedureCodeBuckets = procedureBucketDataModel(updatedProcedureCodes.map(withId));

    const keys = Array.from(procedureCodeBuckets.keys());
    let serviceLevelUnits = 0;
    keys.forEach((cs) => {
      const clinicalServiceForGroup =
        serviceRequest?.clinicalServices?.find((_it) => _it?.id === cs || _it?.originalVersionId === cs) || undefined;
      const isUnitsOnPx = clinicalServiceForGroup ? !!clinicalServiceForGroup?.isUnitsOnPx : true;
      const units = isUnitsOnPx
        ? procedureCodeBuckets.get(cs)?.reduce((a, b) => a + (b.units ? b.units : 0), 0)
        : procedureCodeBuckets.get(cs)?.reduce((a, b) => (a > (b.units ? b.units : 0) ? a : b?.units || 0), 0);
      serviceLevelUnits += units || 0;
    });

    return {
      units: serviceLevelUnits,
      semanticProcedureCodes: updatedProcedureCodes,
      procedureCodes: updatedProcedureCodes,
    };
  }

  return undefined;
}

// range of options - service change
function applyMultipleFieldUpdate(
  acceptedDedicatedNudges: string[],
  serviceRequest: GenericServiceRequest,
  values: ServiceChangeOption[],
  groupIds?: string[]
) {
  const chosenOption = values.find((option) => option.id && acceptedDedicatedNudges.includes(option.id));
  let updatedValues: {
    units?: number;
    procedureCodes?: ProcedureCode[];
    semanticProcedureCodes?: SemanticProcedureCode[];
    nudgeRangeofOptionsMetaData?: NudgeRangeofOptionsMetaData;
  } = {};
  const validUnits = chosenOption?.units;
  if (serviceRequest.semanticProcedureCodes || serviceRequest.procedureCodes) {
    const validPxCode = chosenOption?.procedureCode;
    let updatedProcedureCodes: ProcedureCode[] = [];
    let totalUnits = 0;
    if (validPxCode && validUnits && groupIds) {
      // we do not have the groupId for the procedure/semantic procedure codes
      // in the rule. Since this id is the same as the clinicalService.id (if it exists)
      // add that value to the updated code groupId, and to drive the groupBy logic.
      // The nudge that invokes this function actually needs to change the SR pxCode, semanticPxCode
      // so we need a groupId for downstream functionality.
      groupIds.forEach((groupId) => {
        const pxCode = Object.assign({}, validPxCode);
        pxCode.groupId = groupId;
        pxCode.groupBy = "ClinicalService";
        pxCode.units = validUnits;
        updatedProcedureCodes.push(pxCode);
        totalUnits += validUnits;
      });
    }
    if (validPxCode) {
      updatedValues = {
        ...updatedValues,
        semanticProcedureCodes: updatedProcedureCodes,
        units: totalUnits,
      };
    }
    const isAcceptedRangeOfOptions: NudgeRangeofOptionsMetaData = {
      isAccepted: true,
      optionLabel: chosenOption?.label,
    };
    updatedValues = {
      ...updatedValues,
      nudgeRangeofOptionsMetaData: isAcceptedRangeOfOptions,
    };
    return updatedValues;
  }
}

export const actionChangesToBeApplied = (
  originalServiceRequest: GenericServiceRequest,
  redirectAction?: ValidDisplayMessageAction,
  featureFlagCheck?: boolean
) => {
  if (featureFlagCheck && redirectAction && redirectAction.onAcceptAttribute === "procedureCodes") {
    const transientRequest = applyActionToServiceRequest(originalServiceRequest, redirectAction);

    //compare the procedure codes
    const ogPxCodes = originalServiceRequest.semanticProcedureCodes?.map((px) => px.code) || [];
    const newPxCodes = transientRequest?.semanticProcedureCodes?.map((px) => px.code) || [];
    if (ogPxCodes.length !== newPxCodes.length) {
      return true; //action should be applied to request, changes could be made
    }
    const ogPxCodesSorted = [...ogPxCodes].sort();
    const newPxCodesSorted = [...newPxCodes].sort();
    //if every code in og px codes is in new px codes then action should not be applied to request as no change could be made
    return !ogPxCodesSorted.every((element, index) => element === newPxCodesSorted[index]);
  } else {
    return true;
  }
};

// legacy function for recalculation of units on procedure codes across different clinical services once a nudge is accepted
// keeping this one around should something change in the future.
export const calculateUpdatedProcedureCodes = (
  actionGroupId: string,
  serviceRequest: GenericServiceRequest,
  value: number,
  actionsGroupIds?: string[]
) => {
  let updatedProcedureCodes: ProcedureCode[] = [];
  let updatedTargetedPxCodes: ProcedureCode[] | undefined = [];
  let targetedPxCodes: ProcedureCode[] | undefined;
  let unTargetedPxCodes: ProcedureCode[] | undefined;
  if (actionsGroupIds) {
    //when received a list of clinical services that accepted nudges
    targetedPxCodes = serviceRequest?.semanticProcedureCodes?.filter((px) =>
      targetedPxCodes?.length === 0 ? px.groupId === "" : actionsGroupIds?.includes(px.groupId!)
    );
    unTargetedPxCodes = serviceRequest?.semanticProcedureCodes?.filter((px) =>
      targetedPxCodes?.length === 0 ? px.groupId !== "" : !actionsGroupIds?.includes(px.groupId!)
    );
  } else if (actionGroupId === "parent") {
    updatedProcedureCodes =
      serviceRequest?.semanticProcedureCodes?.map((px) => {
        return { ...px, units: value };
      }) || [];
  } else {
    targetedPxCodes = serviceRequest?.semanticProcedureCodes?.filter((px) =>
      targetedPxCodes?.length === 0 ? px.groupId === "" : px.groupId === actionGroupId
    );
    unTargetedPxCodes = serviceRequest?.semanticProcedureCodes?.filter((px) =>
      targetedPxCodes?.length === 0 ? px.groupId !== "" : px.groupId !== actionGroupId
    );
  }
  updatedTargetedPxCodes = targetedPxCodes?.map((px) => {
    return { ...px, units: value };
  });
  updatedProcedureCodes = [...(updatedTargetedPxCodes || []), ...(unTargetedPxCodes || [])];
  return updatedProcedureCodes;
};
