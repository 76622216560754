import { Body1 } from "@coherehealth/common";
import { useChatbotStyles } from "./chatbotReviewAssistantStyles";
import { IReviewChatbotFindingHolder } from "components/ClinicalReview/Review/hooks/useAutomatedReviewChatbotFindings";

interface Props {
  findingHolder: IReviewChatbotFindingHolder;
}

export default function ChatbotOutgoingMessage({ findingHolder }: Props) {
  const classes = useChatbotStyles({});
  return (
    <div className={classes.outgoingMessageWrapper}>
      <div className={classes.outgoingMessage}>
        <Body1>{findingHolder.chatbotfinding.displayedQuestion}</Body1>
      </div>
    </div>
  );
}
