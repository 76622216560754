import React, { Dispatch, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CohereLogo from "../images/CohereLogo.jpg";
import { Body1, colorsLight, H1, H6, InlineButton, PrimaryButton, TextField, useFeature } from "@coherehealth/common";
import { getTINDisplayValue, isTinValid, updateTinFromDisplayValue } from "util/providerUtils";
import { Link, useNavigate } from "react-router-dom";
import OnboardingSignUpBackgroundImage from "../images/OnboardingSignUpHero.png";
import usePostOnboardingRedirectUrl from "./usePostOnboardingUrl";
import { isEmailValid } from "./shared";
import LaunchIcon from "@material-ui/icons/Launch";

const useStyles = makeStyles((theme) => ({
  rightAnchor: {
    top: 0,
    left: "auto",
    right: 0,
    display: "flex",
    outline: 0,
    zIndex: 1,
    position: "fixed",
    overflowY: "auto",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    borderLeft: "1px solid #E5E5E5",
    height: "100%",
  },

  drawer: {
    width: "560px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(5),
    gap: theme.spacing(3),
  },
  logo: {
    paddingTop: theme.spacing(5),
    width: "21%",
    alignSelf: "center",
  },
  primaryButton: {
    margin: "12px 0px",
  },
  footer: {
    color: colorsLight.font.secondary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
  },
  linkDetails: {
    display: "flex",
    height: theme.spacing(4),
    alignItems: "center",
    gap: theme.spacing(1),
    flexShrink: 0,
    color: colorsLight.font.secondary,
  },
  signInButton: {
    minWidth: theme.spacing(6.25),
    textDecoration: "none",
    fontFamily: "Gilroy-SemiBold",
    paddingTop: theme.spacing(0.3),
    paddingBottom: 0,
  },
  registerationHeader: {
    textDecoration: "none",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: 400,
    paddingTop: "3px",
    paddingBottom: "1px",
  },
  registerationGuideButton: {
    paddingTop: theme.spacing(0.3),
    paddingBottom: 0,
  },
  iconColor: {
    padding: 0,
    color: theme.palette.primary.main,
  },
  tinDisclaimer: {
    color: colorsLight.font.light,
    fontFamily: "Gilroy-Medium",
  },
  pageHeader: {
    marginBottom: theme.spacing(1.5),
  },
  image: {
    position: "absolute",
    left: 0,
    right: 560,
    bottom: 0,
    top: 0,
    backgroundSize: "cover",
    backgroundImage: `url(${OnboardingSignUpBackgroundImage})`,
  },
  remindLaterButton: {
    marginTop: theme.spacing(1.5),
  },
  inlineButton: {
    padding: "0px 0px 1px 0px",
  },
  linkContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(3),
  },
  footerSso: {
    color: colorsLight.font.secondary,
  },
  inlineLink: {
    textDecoration: "none",
    color: "#039EC3",
    fontFamily: "Gilroy-SemiBold",
  },
}));

interface Props {
  onContinue: () => void;
  onContinueWithEmailValidation: () => void;
  continueLoading: boolean;
  userEmail: string;
  setUserEmail: Dispatch<string>;
  organizationTin: string;
  setOrganizationTin: Dispatch<string>;
  isSsoUser: boolean;
  setAttemptedSubmit: Dispatch<boolean>;
  initialUserEmail: string;
  verifyIfEmailAlreadyExistsLoading?: boolean;
  doesEmailAlreadyExist?: boolean;
  setHasCreateAnButtonClicked?: React.Dispatch<React.SetStateAction<boolean>>;
  isUserAlreadyExistInSystem?: boolean;
}

export default function SearchPage({
  onContinue,
  onContinueWithEmailValidation,
  continueLoading,
  userEmail,
  setUserEmail,
  organizationTin,
  setOrganizationTin,
  isSsoUser,
  initialUserEmail,
  verifyIfEmailAlreadyExistsLoading,
  doesEmailAlreadyExist,
  setAttemptedSubmit,
  isUserAlreadyExistInSystem,
  setHasCreateAnButtonClicked,
}: Props) {
  const classes = useStyles();
  const ssoUserOnboarding = useFeature("ssoUserOnboarding");
  const [emailEntryError, setEmailEntryError] = useState<boolean>(false);
  const [validEmailError, setValidEmailError] = useState(false);

  const continueDisabled =
    emailEntryError ||
    !isTinValid(organizationTin) ||
    !userEmail ||
    !organizationTin ||
    doesEmailAlreadyExist ||
    verifyIfEmailAlreadyExistsLoading ||
    isUserAlreadyExistInSystem;

  useEffect(() => {
    if (doesEmailAlreadyExist) {
      setValidEmailError(true);
    } else {
      setValidEmailError(false);
    }
  }, [doesEmailAlreadyExist]);

  const handleClick = () => {
    setHasCreateAnButtonClicked?.(true);
    onContinueWithEmailValidation();
  };

  const navigate = useNavigate();
  const { returnTo } = usePostOnboardingRedirectUrl();

  // Logic discussed here:
  // https://coherehealth.atlassian.net/browse/COH-6144?focusedCommentId=221394
  const disableEmailEdit = ssoUserOnboarding && Boolean(initialUserEmail) && isEmailValid(initialUserEmail); // TBD: && email != "someplaceholdervalue"

  return (
    <div>
      <div className={classes.image} />
      <div className={classes.rightAnchor}>
        <img src={CohereLogo} alt={""} className={classes.logo} />
        {isSsoUser && !ssoUserOnboarding ? (
          <Container className={classes.drawer}>
            <H1 className={classes.pageHeader}>Welcome to Cohere</H1>
            <H6 className={classes.footer}>
              <H6>
                Cohere is an authorization vendor for several health plans. If you're not sure if Cohere is the right
                platform,
              </H6>
              <InlineButton
                component="a"
                onClick={() => {
                  window.open("https://coherehealth.com/provider/resources/#faq");
                }}
                className={classes.inlineButton}
              >
                visit our website&nbsp;
              </InlineButton>
              to learn more. <br /> <br />
              To streamline your auth process, please verify your account.
            </H6>

            <PrimaryButton
              fullWidth
              className={classes.primaryButton}
              onClick={onContinue}
              loading={continueLoading}
              data-tracking-id="verify-my-account-button-on-splash-page"
            >
              Verify my account
            </PrimaryButton>
            <InlineButton
              fullWidth
              className={classes.remindLaterButton}
              onClick={() => {
                navigate(returnTo);
              }}
              data-tracking-id="remind-me-later-button-on-splash-page"
            >
              Remind me later
            </InlineButton>
          </Container>
        ) : (
          <Container className={classes.drawer}>
            {isSsoUser ? (
              <>
                <H1 className={isSsoUser ? "" : classes.pageHeader}>Welcome to Cohere</H1>
                <Body1 className={isSsoUser ? classes.footerSso : classes.footer}>
                  Cohere partners with several health plans to streamline authorizations and referrals. If you're unsure
                  whether Cohere is the right platform for you,
                  <a
                    className={classes.inlineLink}
                    href={"https://payerinfo.zendesk.com/hc/en-us"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    visit our help center{" "}
                  </a>
                  to learn more. <br /> <br />
                  Create an account to get started.
                </Body1>
              </>
            ) : (
              <H1>Sign up</H1>
            )}
            <TextField
              fullWidth
              label={"Practice TIN"}
              value={getTINDisplayValue(organizationTin)}
              onChangeValue={updateTinFromDisplayValue((formattedTin) => setOrganizationTin(formattedTin))}
            />
            <TextField
              fullWidth
              label={"Your email"}
              value={userEmail}
              onChangeValue={async (newEmail) => {
                setAttemptedSubmit(false);
                setUserEmail(newEmail);
                setEmailEntryError(!isEmailValid(newEmail));
                setValidEmailError(false);
              }}
              error={
                emailEntryError ||
                (doesEmailAlreadyExist && validEmailError && !verifyIfEmailAlreadyExistsLoading) ||
                isUserAlreadyExistInSystem
              }
              helperText={
                (doesEmailAlreadyExist && validEmailError && !verifyIfEmailAlreadyExistsLoading) ||
                isUserAlreadyExistInSystem
                  ? "An account with this email already exists."
                  : ""
              }
              onKeyPress={(event) => {
                if (event.key === "Enter" && !continueDisabled) {
                  onContinueWithEmailValidation();
                }
              }}
              disabled={disableEmailEdit}
            />
            <PrimaryButton fullWidth onClick={handleClick} disabled={continueDisabled} loading={continueLoading}>
              Create an account
            </PrimaryButton>
            <div className={classes.linkContainer}>
              <Body1 className={classes.tinDisclaimer}>
                By creating an account, you acknowledge that the TIN you register with will be visible to your
                organization and Cohere staff.
              </Body1>
              <div className={`${classes.linkDetails}`}>
                <H6 className={classes.registerationHeader}>Need help with registration?</H6>
                <InlineButton
                  className={`${classes.registerationGuideButton} ${classes.registerationHeader}`}
                  onClick={() => {
                    window.open(
                      "https://payerinfo.zendesk.com/hc/en-us/articles/11791874008471-How-do-I-register-for-a-Cohere-account#h_01HFE3GQEWSDQM7SSX6HGNDRKJ",
                      "_blank"
                    );
                  }}
                  startIcon={<LaunchIcon />}
                >
                  View our step-by-step guide
                </InlineButton>
              </div>
              {/* SSO users are already signed in. 
              Clicking this link will just redirect them back here because they haven't yet been onboarded. 
              Remove the sign in link for SSO users. */}
              {!isSsoUser && (
                <div className={classes.linkDetails}>
                  <H6 className={classes.registerationHeader}>Already have an account? </H6>
                  <Link to={"/"}>
                    <InlineButton className={classes.signInButton}>Sign in</InlineButton>
                  </Link>
                </div>
              )}
            </div>
          </Container>
        )}
      </div>
    </div>
  );
}
