import {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import {
  OptionsDecorator,
  SelectOptionsHook,
  SingleSelectForProviderFacilitySelect,
  useLazyLoadingQueryOptionsHook,
  useFeature,
  MiddleTruncateLabelWithEndornment,
} from "@coherehealth/common";
import {
  Facility,
  Provider,
  useGetProvidersOrFacilities,
  useCreateFacility,
  Patient,
  Location,
  useUpdateFacility,
  useUpsertManuallyEnteredProviderOrFacility,
  PracticeCommon,
  UseGetProvidersOrFacilitiesProps,
} from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthorized } from "authorization";
import { useSnackbar } from "notistack";
import listInsert from "util/listInsert";
import { canEditPractice } from "util/serviceRequest";

import {
  providerFacilityDropdownRender,
  providerFacilityAddressRenderer,
  providerFacilityTinRenderer,
} from "../../ProviderFacilityDropdownRender";
import ProviderFacilityModal from "../../ProviderFacilityModalManual";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { getCurrentCoverage, getPatientHealthPlanName } from "util/patientUtils";
import { FormConfiguration } from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { SuggestionContext } from "../../../AuthBuilder/SuggestionContext";
import { getLocationsForTin, ModalStatus } from "util/providerUtils";

// * Code tag RECENT_PROVIDERS_8B12CDB1
import Recents from "common/RecentProviders";
import { RecentProvidersContext } from "common/RecentProviders/context";
import { fieldIsValid } from "common/FormConfigUtils";

export type OptionType = "noResults" | "someResults" | "noResultsOscar";
export type FacilityOption = Facility & {
  optionType?: OptionType;
};

export type LocationOption = Location & {
  optionType?: OptionType;
  manuallyEditedAddress?: boolean;
};

export type TinOption = {
  optionType?: OptionType;
  tinManuallyCreated?: boolean;
  id: string;
  val: string;
};

type PracticeUpsertPayload = PracticeCommon & {
  recordType?: "PROVIDER" | "FACILITY";
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiAutocomplete-inputRoot": {
      color: theme.palette.warning.dark,
      borderColor: theme.palette.warning.dark,
    },
    "& .MuiFormLabel-root:not(.Mui-disabled)": {
      color: theme.palette.warning.dark,
    },
    "& .MuiSelect-selectMenu:not(.Mui-disabled)": {
      border: `1px solid ${theme.palette.warning.dark}`,
      borderRadius: 4,
    },
    "& .MuiFormHelperText-root": {
      color: theme.palette.warning.dark,
    },
    "&:hover, &:active, &:focus": {
      "& .MuiAutocomplete-inputRoot": {
        color: theme.palette.warning.dark,
        borderColor: theme.palette.warning.dark,
      },
    },
  },
  helperMsgLine: {
    paddingBottom: theme.spacing(1),
    display: "block",
  },
}));

function getUseFacilities(
  patient: Patient | undefined,
  useCMSProviders: string,
  ignoreTin: boolean
): SelectOptionsHook<FacilityOption> {
  return function useFacilities(selectOptionsParams) {
    const today = new Date();
    const healthPlanName = patient ? getPatientHealthPlanName(patient, today) : undefined;
    const patientCoverage = getCurrentCoverage(patient);
    const rpn = patientCoverage?.payerCustomFields?.find((field) => field.fieldName === "RPN")?.valueString;

    const preventUserCreatedFacilities = useFeature("preventUserCreatedFacilities");
    const addManualCreationOption: OptionsDecorator<FacilityOption> | undefined =
      healthPlanName === "Oscar"
        ? (loadedOptions, hasMoreOptions) => {
            if (!hasMoreOptions) {
              const manualOption: FacilityOption = {
                name: "",
                id: "",
                dateCreated: "",
                lastUpdated: "",
              };
              if (!loadedOptions || loadedOptions.length === 0) {
                manualOption.optionType = "noResultsOscar";
                return listInsert(loadedOptions || [], 0, manualOption);
              }
            }
            return loadedOptions;
          }
        : !preventUserCreatedFacilities
        ? (loadedOptions, hasMoreOptions) => {
            if (!hasMoreOptions) {
              const manualOption: FacilityOption = {
                name: "",
                id: "",
                dateCreated: "",
                lastUpdated: "",
              };
              if (!loadedOptions || loadedOptions.length === 0) {
                manualOption.optionType = "noResults";
                return listInsert(loadedOptions || [], 0, manualOption);
              } else {
                manualOption.optionType = "someResults";
                return listInsert(loadedOptions, loadedOptions.length, manualOption);
              }
            }
            return loadedOptions;
          }
        : undefined;

    const deDuplicateManualProviders = useFeature("deDuplicateManualProvidersInSearch");
    const queryParams = {
      healthPlanName: healthPlanName || undefined,
      useCMSProviders: useCMSProviders,
      ignoreTin: ignoreTin,
      deDuplicateManualProviders: deDuplicateManualProviders,
      rpn,
    };

    return useLazyLoadingQueryOptionsHook({
      useGetHook: (args: UseGetProvidersOrFacilitiesProps) => useGetProvidersOrFacilities({ ...args, debounce: 600 }),
      optionsDecorator: addManualCreationOption,
      additionalQueryParams: { ...queryParams, recordType: "FACILITY" },
      ...selectOptionsParams,
    });
  };
}

export function getLocationsWithOptions(
  locations: Location[] | null,
  enableManualAddress: boolean,
  selectedTin?: string | null,
  ignoreTin: boolean = false
): LocationOption[] {
  const locationsWithOptions: Array<LocationOption> = [];
  locations?.forEach((location) => {
    locationsWithOptions.push({ ...location, optionType: undefined });
  });
  const manualOption: LocationOption = {
    id: "",
    segments: undefined,
    tin: "",
    address: undefined,
    networks: undefined,
    phoneNumbers: undefined,
    faxNumbers: undefined,
    isOutOfNetwork: false,
    outOfNetworkExceptionReason: "",
    outOfNetworkExceptionComment: "",
    externalReferenceId: "",
  };

  if (locationsWithOptions.length === 0) {
    manualOption.optionType = "noResults";
  } else {
    manualOption.optionType = "someResults";
  }

  if (enableManualAddress && (selectedTin || ignoreTin)) {
    locationsWithOptions.push(manualOption);
  }
  return locationsWithOptions;
}

const getFacilityForUpdate = (
  currProvider: Facility,
  currentTin: string | null | undefined,
  currentAddress: Location | null | undefined,
  modalStatus: ModalStatus,
  payload: PracticeUpsertPayload,
  ignoreTin: boolean
): Facility & { recordType: "PROVIDER" | "FACILITY" } => {
  const selLoc = payload.selectedLocation as LocationOption;
  selLoc.manuallyEditedAddress = true;
  if (!currProvider.tinList) {
    currProvider.tinList = [];
  }
  if (!currProvider.locations) {
    currProvider.locations = [];
  }
  currProvider.lastUpdated = new Date().toISOString();
  switch (modalStatus) {
    case ModalStatus.AddTin:
      if (selLoc.tin) {
        currProvider.tinList?.push(selLoc.tin);
        currProvider.locations?.push(selLoc);
        currProvider.selectedLocation = selLoc;
      }
      break;
    case ModalStatus.EditTin:
      // remove existing tin from list
      if (currProvider && selLoc.tin && selLoc && currentTin) {
        let index = currProvider.tinList.indexOf(currentTin, 0);
        if (index > -1) {
          currProvider.tinList.splice(index, 1);
        }

        // change location associated with tin
        currProvider.locations.forEach(function (x) {
          if (x.tin === currentTin) {
            x.tin = selLoc.tin;
          }
        });

        currProvider.tinList.push(selLoc.tin);
        currProvider.selectedLocation = selLoc;
      }

      break;
    case ModalStatus.AddAddress:
      if (selLoc.tin || (ignoreTin && selLoc)) {
        currProvider.locations.push(selLoc);
        currProvider.selectedLocation = selLoc;
      }
      break;

    case ModalStatus.EditAddress:
      if (currProvider && (selLoc.tin || ignoreTin) && selLoc) {
        // remove location associated with tin
        const index = currProvider.locations.findIndex(
          (x) =>
            x.tin === currentTin &&
            x.address?.line1 === currentAddress?.address?.line1 &&
            x.address?.line2 === currentAddress?.address?.line2 &&
            x.address?.state === currentAddress?.address?.state &&
            x.address?.zipCode === currentAddress?.address?.zipCode &&
            x.phoneNumbers?.length === currentAddress?.phoneNumbers?.length &&
            JSON.stringify(x.phoneNumbers?.sort()) === JSON.stringify(currentAddress?.phoneNumbers?.sort()) &&
            x.faxNumbers?.length === currentAddress?.faxNumbers?.length &&
            JSON.stringify(x.faxNumbers?.sort()) === JSON.stringify(currentAddress?.faxNumbers?.sort())
        );
        if (index > -1) {
          currProvider.locations.splice(index, 1);
        }
        currProvider.locations.push(selLoc);
        currProvider.selectedLocation = selLoc;
      }
      break;
  }
  return { ...currProvider, recordType: payload.recordType || (payload.type as "PROVIDER" | "FACILITY") };
};

export function getTinWithOptions(tin: string | undefined | null): TinOption | null {
  if (tin === "") {
    return {
      optionType: "noResults",
      tinManuallyCreated: false,
      id: "",
      val: "",
    };
  } else if (tin) {
    return { optionType: undefined, tinManuallyCreated: undefined, id: tin, val: tin };
  }
  return null;
}

export function getTinListWithOptions(tinlist: string[] | undefined) {
  const tinListWithOtions: TinOption[] = [];
  if (tinlist) {
    for (const val of tinlist) {
      tinListWithOtions.push({ optionType: undefined, tinManuallyCreated: undefined, id: val, val: val });
    }
  }

  const manualOption: TinOption = {
    optionType: tinlist ? "someResults" : "noResults",
    tinManuallyCreated: false,
    id: "",
    val: "/TIN-undefined",
  };

  if (manualOption) {
    tinListWithOtions.push(manualOption);
  }
  return tinListWithOtions;
}

const FacilitySelectManual: FunctionComponent<{
  facilityError: boolean;
  npiError?: boolean;
  tinError?: boolean;
  addressError?: boolean;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  formContent: ServiceRequestFormContent;
  isSingleService?: boolean;
  isCohereFaxForm?: boolean;
  isOptional: boolean;
  patient?: Patient;
  showAddressField?: boolean;
  formConfiguration?: FormConfiguration;
  hideTinField?: boolean;
  showEditOption?: boolean;
  setFacilityExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
  canSkipValidationForNpiTin?: boolean;
}> = ({
  formContent,
  facilityError,
  npiError,
  tinError,
  addressError,
  setFormContent,
  isCohereFaxForm,
  isOptional,
  patient,
  showAddressField,
  formConfiguration,
  hideTinField = false,
  showEditOption,
  setFacilityExceptionRequest,
  disabled,
  canSkipValidationForNpiTin = undefined,
}) => {
  const classes = useStyles();
  type modalStatus = ModalStatus;

  const [modalOp, setModalOp] = useState<modalStatus>(ModalStatus.AddAddress);

  const enableManualProviderInfo: boolean = useFeature("manualProviderInfo");

  const { mutate: updateFacility, error: facilityUpdateError } = useUpdateFacility({
    id: formContent?.facility ? formContent?.facility.id : "",
  });

  const { mutate: upsertManuallyEnteredProviderOrFacility, error: upsertManuallyEnteredProviderOrFacilityError } =
    useUpsertManuallyEnteredProviderOrFacility({});

  const { suggestedFormContent } = useContext(SuggestionContext);

  const getAddress = (address: any): string => {
    let finalAddress: string = "";
    if (address) {
      if (address?.line1) {
        finalAddress = `${finalAddress}${address.line1}, `;
      }
      if (address?.line2) {
        finalAddress = `${finalAddress}${address.line2}, `;
      }
      if (address?.city) {
        finalAddress = `${finalAddress}${address.city}, `;
      }
      if (address?.state) {
        finalAddress = `${finalAddress}${address.state} - `;
      }
      if (address?.zipCode) {
        finalAddress = `${finalAddress}${address.zipCode}`;
      }
    }
    return finalAddress;
  };

  let helperMessages: ReactNode[] = [];
  let warningNeeded = false;
  if (facilityError) {
    helperMessages.push("Required");
  }
  let helperText;
  if (helperMessages.length === 1) {
    helperText = helperMessages[0];
  } else if (helperMessages.length > 1) {
    helperText = (
      <>
        {helperMessages.map((m, idx) => (
          <span key={idx} className={classes.helperMsgLine}>
            {m}
          </span>
        ))}
      </>
    );
  }

  const [facilityModalOpen, setFacilityModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const useCanEditPractice = useAuthorized("EDIT_SERVICE_REQUEST");

  const { mutate: createFacility, error: facilityCreationError } = useCreateFacility({});

  // * Code tag RECENT_PROVIDERS_8B12CDB1
  const { trackingStateClickChangeCheck } = useContext(RecentProvidersContext);

  useEffect(() => {
    if (facilityCreationError) {
      enqueueSnackbar(facilityCreationError.message, { variant: "error" });
    }
  }, [facilityCreationError, enqueueSnackbar]);

  useEffect(() => {
    if (facilityUpdateError || upsertManuallyEnteredProviderOrFacilityError) {
      enqueueSnackbar(facilityUpdateError?.message || upsertManuallyEnteredProviderOrFacilityError?.message, {
        variant: "error",
      });
    }
  }, [facilityUpdateError, enqueueSnackbar, upsertManuallyEnteredProviderOrFacilityError]);

  const healthPlanName = patient ? getPatientHealthPlanName(patient, new Date()) : undefined;
  const isFacilityNpiOptional = formConfiguration?.facilityNPI?.fieldSpec === "OPTIONAL";

  const updateFacilityForModalOp = useCallback(
    async (facilityForUpdate, facility) => {
      if (facility.type) {
        facilityForUpdate.lastUpdated = new Date().toISOString();
        await updateFacility({ ...facilityForUpdate });
      } else if (facility.recordType) {
        await upsertManuallyEnteredProviderOrFacility({
          npi: facilityForUpdate.npi || "",
          tinList: facilityForUpdate.tinList || [],
          locations: facilityForUpdate.locations || [],
          recordType: facilityForUpdate.recordType,
        });
      }
    },
    [updateFacility, upsertManuallyEnteredProviderOrFacility]
  );

  const createFacilityForModalOp = useCallback(
    async (facilityForUpdate, facility) => {
      if (facility.type) {
        facilityForUpdate.dateCreated = new Date().toISOString();
        const newFacility = await createFacility({ ...facilityForUpdate });
        facilityForUpdate.id = newFacility.id;
        facilityForUpdate.source = newFacility.source;
      } else if (facility.recordType) {
        const newFacility = await upsertManuallyEnteredProviderOrFacility({
          npi: facilityForUpdate.npi || "",
          tinList: facilityForUpdate.tinList || [],
          locations: facilityForUpdate.locations || [],
          recordType: facilityForUpdate.recordType,
        });
        facilityForUpdate.id = newFacility.id;
        facilityForUpdate.source = newFacility.source;
      }
    },
    [createFacility, upsertManuallyEnteredProviderOrFacility]
  );

  const updateFormContent = useCallback(
    (facilityForUpdate) => {
      setFormContent((prev) => ({
        ...prev,
        facilitySelectedTin: facilityForUpdate.selectedLocation?.tin,
        facilitySelectedAddress: facilityForUpdate.selectedLocation,
        facility: facilityForUpdate,
        selectedFacility: facilityForUpdate,
      }));
    },
    [setFormContent]
  );

  const handleSaveForExistingFacility = useCallback(
    async (facilityForUpdate, facility) => {
      if (formContent?.facility?.manuallyCreated) {
        await updateFacilityForModalOp(facilityForUpdate, facility);
      } else {
        facilityForUpdate.manuallyCreated = true;
        facilityForUpdate.createdByUser = false;
        await createFacilityForModalOp(facilityForUpdate, facility);
        updateFormContent(facilityForUpdate);
      }
    },
    [formContent?.facility, updateFacilityForModalOp, createFacilityForModalOp, updateFormContent]
  );

  const handleAddOperation = useCallback(
    async (facility, setEntry) => {
      let retVal: Provider = { id: "", dateCreated: "", lastUpdated: "" };
      if (facility.type) {
        retVal = await createFacility({
          ...facility,
        });
      } else if (facility.recordType) {
        retVal = await upsertManuallyEnteredProviderOrFacility({
          npi: facility.npi || "",
          tinList: facility.tinList || [],
          locations: facility.locations || [],
          recordType: facility.recordType,
        });
      }
      retVal.type = facility.type;
      retVal.selectedLocation = facility.selectedLocation;
      setFormContent((prev) => ({
        ...prev,
        facility: retVal,
        facilitySelectedTin: retVal.tinList ? retVal.tinList[0] : null,
        facilitySelectedAddress: !!facility.locations ? facility.locations[0] : null,
        selectedFacility: retVal,
      }));
      setEntry(retVal);
    },
    [createFacility, upsertManuallyEnteredProviderOrFacility, setFormContent]
  );

  const facilitySaveCallback = useCallback(
    async (facility: PracticeUpsertPayload, setEntry: (practice: Provider | Facility) => void) => {
      try {
        if (
          modalOp === ModalStatus.AddAddress ||
          modalOp === ModalStatus.EditAddress ||
          modalOp === ModalStatus.AddTin ||
          modalOp === ModalStatus.EditTin
        ) {
          if (formContent?.facility) {
            const facilityForUpdate = getFacilityForUpdate(
              formContent?.facility,
              formContent?.facilitySelectedTin,
              formContent?.facilitySelectedAddress,
              modalOp,
              facility,
              hideTinField
            );
            await handleSaveForExistingFacility(facilityForUpdate, facility);
            setEntry(facilityForUpdate);
          }
        } else if (modalOp === ModalStatus.Add) {
          await handleAddOperation(facility, setEntry);
        }
      } catch (error) {
        enqueueSnackbar("error occured while updating facility entity", { variant: "error" });
      }
    },
    [
      modalOp,
      formContent?.facility,
      formContent?.facilitySelectedTin,
      formContent?.facilitySelectedAddress,
      hideTinField,
      enqueueSnackbar,
      handleSaveForExistingFacility,
      handleAddOperation,
    ]
  );
  const isTinFieldDisabled = !Boolean(formContent?.facility) || disabled;
  const isAddressFieldDisabled =
    !(
      Boolean(formContent?.facility) &&
      (hideTinField ||
        Boolean(formContent?.facilitySelectedTin) ||
        (formContent?.facility?.source === "CMS" && !formContent?.facility?.manuallyCreated))
    ) || disabled;

  const TINValidation =
    formContent?.facility?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.selectedFacility?.selectedLocation?.tin
      ? canSkipValidationForNpiTin
      : formContent?.facility?.tinList?.length === 0
      ? true
      : Boolean(formContent?.selectedFacility?.selectedLocation?.tin) || Boolean(formContent?.facilitySelectedTin);
  const isTinInputValid = fieldIsValid(formConfiguration?.facilityTIN, TINValidation);
  return (
    <>
      <Grid
        item
        style={{
          flex: `1 1 560px`,
          minWidth: "200px",
        }}
      >
        <SingleSelectForProviderFacilitySelect<FacilityOption>
          disablePortal
          autoFilledIcon={
            isCohereFaxForm ||
            (suggestedFormContent?.facility?.facilityEntity
              ? suggestedFormContent.facility.facilityEntity.npi === formContent?.facility?.npi
              : false)
          }
          markSelectedOptions={false}
          error={facilityError || npiError}
          clearable={formConfiguration?.facilityNPI.fieldSpec === "OPTIONAL"}
          helperText={facilityError ? "Required" : npiError ? "NPI Required" : ""}
          label={`Performing facility or agency${isOptional ? " (optional)" : ""}`}
          reactLabel={
            <MiddleTruncateLabelWithEndornment
              leftLabel={"Performing facility or agency"}
              rightLabel={isOptional ? "(optional)" : ""}
            />
          }
          emptyLabel={
            <MiddleTruncateLabelWithEndornment
              leftLabel={
                hideTinField
                  ? "Performing facility or agency NPI or name"
                  : "Performing facility or agency NPI, TIN, or name"
              }
              rightLabel={isOptional ? "(optional)" : ""}
            />
          }
          useOptions={getUseFacilities(patient, "true", hideTinField)}
          getOptionLabel={({ name, npi }) => `${name} / NPI - ${npi || (isFacilityNpiOptional ? "N/A" : "")}`}
          renderOption={({ name, tinList, npi, optionType }) =>
            providerFacilityDropdownRender(
              name,
              tinList,
              npi,
              optionType,
              (tin: string | null) => setFormContent({ ...formContent, facilitySelectedTin: tin }),
              hideTinField,
              isFacilityNpiOptional
            )
          }
          withEditAdornment={canEditPractice(useCanEditPractice, formContent?.facility || null) && showEditOption}
          withWarnAdornment={warningNeeded}
          editAction={() => {
            setModalOp(ModalStatus.Edit);
            setFacilityModalOpen(true);
          }}
          selectedValue={formContent?.facility || null}
          setSelectedValue={async (facility) => {
            //synchronously mark this as false when a selection is made
            //is calculated elsewhere via useEffects after this update runs
            setFacilityExceptionRequest?.(false);
            const selectedTin = facility?.tinList?.length === 1 ? facility.tinList[0] : undefined;
            let locationsForTin = getLocationsForTin(selectedTin, facility, hideTinField);
            let selectedLocation: Location = {};
            if (locationsForTin && locationsForTin?.length === 1) {
              selectedLocation = locationsForTin[0];
            }
            if (
              hideTinField ||
              (facility?.source === "CMS" && !facility?.manuallyCreated && !selectedLocation.address)
            ) {
              if (facility?.locations && facility.locations.length > 0) {
                selectedLocation = facility.locations[0];
              }
            }
            //provider
            if (["noResults", "someResults"].includes(facility?.optionType || "")) {
              setFormContent((prev) => ({
                ...prev,
                facility: facility,
                facilitySelectedTin: undefined,
                facilitySelectedAddress: undefined,
                selectedFacility: undefined,
              }));
              setModalOp(ModalStatus.Add);
              setFacilityModalOpen(true);
            } else if (facility === null) {
              //clear provider form content if it is nullified by the user
              setFormContent((prev) => {
                return {
                  ...prev,
                  facilitySelectedAddress: null,
                  selectedFacility: null,
                  facility: null,
                  facilitySelectedTin: null,
                  facilityOONExceptionRequired: false,
                };
              });
            } else {
              const facilityCalc: Facility = {
                ...facility,
                selectedLocation: selectedLocation.address ? selectedLocation : undefined,
                type: "FACILITY",
              } as Facility;
              setFormContent((prev) => ({
                ...prev,
                facility: facilityCalc,
                facilitySelectedTin: facility?.tinList?.length === 1 ? facility.tinList[0] : null,
                facilitySelectedAddress: selectedLocation,
                selectedFacility: facilityCalc,
              }));
            }

            // * Code tag RECENT_PROVIDERS_8B12CDB1
            if (trackingStateClickChangeCheck) {
              trackingStateClickChangeCheck("Facility");
            }
          }}
          className={warningNeeded ? classes.root : undefined}
          data-testid="facility-npi"
          dataPublic={true}
          disabled={disabled}
        />
      </Grid>
      {(showAddressField || !hideTinField) && (
        <Grid
          item
          container
          spacing={2}
          style={{
            flex: `1 1 50%`,
          }}
        >
          {!hideTinField && (
            <Grid item style={{ flex: `1 1 auto`, minWidth: "167px" }}>
              <SingleSelectForProviderFacilitySelect<TinOption>
                disablePortal
                label={"TIN"}
                clearable={formConfiguration?.facilityTIN.fieldSpec === "OPTIONAL"}
                disabled={isTinFieldDisabled}
                markSelectedOptions={false}
                withEditAdornment={
                  Boolean(formContent?.facility?.manuallyCreated) &&
                  Boolean(formContent?.facilitySelectedTin) &&
                  Boolean(formContent?.facilitySelectedTin !== "N/A")
                }
                editAction={() => {
                  setModalOp(ModalStatus.EditTin);
                  setFacilityModalOpen(true);
                }}
                setSelectedValue={async (tin) => {
                  if (tin && tin.id !== "") {
                    //this code reduced flickering in screen
                    // we need to set the tin and address releated to tin
                    setFormContent((prev) => ({
                      ...prev,
                      facilitySelectedTin: tin.id,
                    }));

                    let locationsForTin = getLocationsForTin(tin.id, formContent?.facility);

                    let selectedLocation: Location = {};
                    if (locationsForTin && locationsForTin?.length === 1) {
                      selectedLocation = locationsForTin[0];
                    }
                    const selectedFacility: Facility = {
                      ...formContent?.facility,
                      selectedLocation: selectedLocation.address ? selectedLocation : undefined,
                    } as Facility;

                    setFormContent((prev) => ({
                      ...prev,
                      facilitySelectedAddress: selectedLocation,
                      selectedFacility: selectedFacility,
                    }));
                  } else if (["noResults", "someResults"].includes(tin?.optionType || "")) {
                    const facilityCalc: Facility = {
                      ...formContent?.facility,
                      selectedLocation: undefined,
                    } as Facility;
                    setFormContent((prev) => ({
                      ...prev,
                      facilitySelectedTin: undefined,
                      facilitySelectedAddress: undefined,
                      selectedFacility: facilityCalc,
                      facility: facilityCalc,
                    }));
                    setModalOp(ModalStatus.AddTin);
                    setFacilityModalOpen(true);
                  }

                  // * Code tag RECENT_PROVIDERS_8B12CDB1
                  if (trackingStateClickChangeCheck) {
                    trackingStateClickChangeCheck("Facility");
                  }
                }}
                useOptions={() => ({
                  options: getTinListWithOptions(formContent?.facility?.tinList),
                  optionsLoading: false,
                  filterOptions: (options, state) =>
                    options.filter((option) => option.val?.toLowerCase().includes(state.inputValue.toLowerCase())),
                })}
                data-testid="requesting--facility-provider-tin"
                selectedValue={getTinWithOptions(
                  formContent?.facilitySelectedTin || formContent?.selectedFacility?.selectedLocation?.tin
                )}
                getOptionLabel={({ val }) => val}
                renderOption={({ id, val, optionType }) => providerFacilityTinRenderer(id, val, optionType)}
                error={!isTinFieldDisabled && !isTinInputValid}
                helperText={!isTinFieldDisabled && !isTinInputValid && "Required"}
                dataPublic={false}
              />
            </Grid>
          )}
          {showAddressField && (
            <Grid item style={{ flex: `1 1 60%`, minWidth: "200px" }}>
              <SingleSelectForProviderFacilitySelect<LocationOption>
                disablePortal
                label={"Address"}
                disabled={isAddressFieldDisabled}
                clearable={formConfiguration?.facilityAddress.fieldSpec === "OPTIONAL"}
                markSelectedOptions={false}
                withEditAdornment={
                  Boolean(formContent?.facility?.manuallyCreated) &&
                  Boolean(formContent?.facilitySelectedAddress) &&
                  Boolean(formContent?.facilitySelectedAddress?.manuallyEditedAddress)
                }
                useOptions={() => ({
                  options: getLocationsWithOptions(
                    getLocationsForTin(formContent?.facilitySelectedTin, formContent?.facility, hideTinField),
                    enableManualProviderInfo,
                    formContent?.facilitySelectedTin,
                    hideTinField
                  ),
                  optionsLoading: false,
                  filterOptions: (options, state) =>
                    options.filter(
                      (option) =>
                        option?.address?.city?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.line1?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.line2?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.state?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.zipCode?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.optionType === "noResults" ||
                        option?.optionType === "someResults"
                    ),
                })}
                selectedValue={
                  formContent?.facilitySelectedAddress || formContent?.selectedFacility?.selectedLocation || null
                }
                getOptionLabel={({ address }) => getAddress(address)}
                //autoFilledIcon={isCohereFaxForm} todo:// its there in provider
                editAction={() => {
                  setModalOp(ModalStatus.EditAddress);
                  setFacilityModalOpen(true);
                }}
                setSelectedValue={(location) => {
                  if (["noResults", "someResults"].includes(location?.optionType || "")) {
                    const facilityCalc = {
                      ...formContent?.facility,
                      selectedLocation: undefined,
                      type: "FACILITY",
                    } as Facility;
                    setFormContent((prev) => ({
                      ...prev,
                      facilitySelectedAddress: undefined,
                      selectedFacility: facilityCalc,
                      facility: facilityCalc,
                    }));
                    setModalOp(ModalStatus.AddAddress);
                    setFacilityModalOpen(true);
                  } else {
                    const facilityCalc = {
                      ...formContent?.facility,
                      selectedLocation: location,
                      type: "FACILITY",
                    } as Facility;
                    setFormContent((prev) => ({
                      ...prev,
                      facilitySelectedAddress: { ...prev.facilitySelectedAddress, ...location },
                      selectedFacility: facilityCalc,
                      facility: facilityCalc,
                    }));
                  }

                  // * Code tag RECENT_PROVIDERS_8B12CDB1
                  if (trackingStateClickChangeCheck) {
                    trackingStateClickChangeCheck("Facility");
                  }
                }}
                renderOption={({ address, optionType }) => providerFacilityAddressRenderer(address, optionType)}
                error={
                  (!isAddressFieldDisabled && !formContent?.selectedFacility?.selectedLocation?.address) ||
                  (!isAddressFieldDisabled && !formContent?.facilitySelectedAddress)
                }
                helperText={
                  (!isAddressFieldDisabled &&
                    !formContent?.selectedFacility?.selectedLocation?.address &&
                    "Required") ||
                  (!isAddressFieldDisabled && !formContent?.facilitySelectedAddress && "Required")
                }
                className={warningNeeded && Boolean(helperText) ? classes.root : undefined}
                data-testid="facility-address"
                dataPublic={true}
              />
            </Grid>
          )}
        </Grid>
      )}

      {/* Code tag RECENT_PROVIDERS_8B12CDB1 */}
      <Grid item xs={12} style={{ padding: 0 }}>
        <Recents
          bucketName="PerformingFacilityOrAgency"
          setFormContent={setFormContent}
          facilityId={formContent?.facility?.id || formContent?.facility?.npi}
          healthPlan={healthPlanName}
        />
      </Grid>
      <ProviderFacilityModal
        open={facilityModalOpen}
        modalType="facility"
        patientHealthPlan={healthPlanName ? healthPlanName : undefined}
        modalStatus={modalOp}
        setSelection={async (params: PracticeUpsertPayload, practice: Provider | Facility) => {
          if (modalOp === ModalStatus.Edit) {
            let retVal: Facility = { id: "", dateCreated: "", lastUpdated: "" };
            if (params.type) {
              retVal = await updateFacility({
                id: practice?.id,
                dateCreated: practice?.dateCreated,
                lastUpdated: practice?.lastUpdated,
                ...params,
              });
            } else if (params.recordType) {
              // params is a ProviderFacilityCatalogue payload
              retVal = await upsertManuallyEnteredProviderOrFacility({
                id: practice?.id,
                npi: params.npi || "",
                tinList: params.tinList || [],
                locations: params.locations || [],
                recordType: params.recordType,
              });
            }
            retVal.type = params.type || params.recordType;
            retVal.selectedLocation = params.selectedLocation;
            setFormContent((prev) => ({
              ...prev,
              facility: retVal,
              selectedFacility: retVal,
              facilitySelectedTin: retVal.tinList ? retVal.tinList[retVal.tinList.length - 1] : null,
              facilitySelectedAddress: retVal.locations ? retVal.locations[retVal.locations.length - 1] : null,
            }));
          }
        }}
        savedPractice={formContent?.facility}
        setSelectedTin={undefined}
        onClose={() => {
          setFacilityModalOpen(false);
        }}
        onSave={facilitySaveCallback}
        selectedTin={formContent?.facilitySelectedTin ? formContent?.facilitySelectedTin : undefined}
        selectedLocation={
          formContent?.facilitySelectedAddress || formContent?.selectedFacility?.selectedLocation || null
        }
        hideTinField={hideTinField && formConfiguration?.facilityTIN?.fieldSpec !== "OMIT"}
      />
    </>
  );
};

export default FacilitySelectManual;
