import { H6, RadioGroup, RadioGroupOption, TextField } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { AdditionalInsurance, AdditionalInsuranceOptions } from "@coherehealth/core-platform-api";
import { Grid } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";

type Props = {
  otherInsurance: AdditionalInsurance[] | undefined;
  healthPlanName: string | undefined;
  defaultAdditionalInsurance: AdditionalInsurance | undefined;
  onChange: (selectedAdditionalInsurance: AdditionalInsurance | undefined) => void;
  disabled?: boolean;
};

export default function AdditionalInsuranceSection({
  otherInsurance,
  healthPlanName,
  defaultAdditionalInsurance,
  onChange,
  disabled = false,
}: Props) {
  const classes = useStyles();
  const headerText = `Additional insurance besides ${healthPlanName}`;

  const RadioGroupOptions: RadioGroupOption<AdditionalInsuranceOptions>[] = otherInsurance
    ? otherInsurance.map((insuranceOption: AdditionalInsurance) => ({
        id: insuranceOption.additionalInsurance === "OTHER" ? "OTHER" : insuranceOption.additionalInsurance || "NONE",
        label:
          insuranceOption.additionalInsurance === "OTHER" ? "Other" : insuranceOption.additionalInsuranceText || "None",
      }))
    : [];

  const [selectedAdditionalInsurance, setSelectedAdditionalInsurance] = useState<AdditionalInsurance | undefined>(
    defaultAdditionalInsurance
  );
  // invokes onChange function only once to set the DefaultValue during first render
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!initialized) {
      onChange(defaultAdditionalInsurance);
      setInitialized(true);
    }
  }, [initialized, onChange, defaultAdditionalInsurance]);

  const [textFiendLabel, setTextFieldLabel] = useState<string>(
    defaultAdditionalInsurance?.additionalInsurance === "OTHER" &&
      defaultAdditionalInsurance.additionalInsuranceText === ""
      ? "List other insurance"
      : "Other insurance"
  );

  const handleAdditionalInsuranceChange = useCallback(
    (options: AdditionalInsuranceOptions) => {
      const userSelectedAdditionalInsuranceText = getAdditionalInsuranceText(options, otherInsurance);
      // Single source of truth
      const updatedInsurance = {
        additionalInsurance: options,
        additionalInsuranceText: userSelectedAdditionalInsuranceText,
      };
      setSelectedAdditionalInsurance(updatedInsurance);
      onChange(updatedInsurance);
      // Update label based on selection
      setTextFieldLabel(options === "OTHER" ? "List other insurance" : "Other insurance");
    },
    [onChange, otherInsurance]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <CustomSectionHeaderH6 data-public>{headerText}</CustomSectionHeaderH6>
        <RadioGroup<AdditionalInsuranceOptions>
          row
          className={classes.AdditionalInsuranceTypeSelect}
          value={selectedAdditionalInsurance?.additionalInsurance || "NONE"}
          options={RadioGroupOptions}
          onChange={handleAdditionalInsuranceChange}
          disabled={disabled}
          data-public
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: "8px", display: "flex", alignItems: "center", alignSelf: "stretch" }}>
        {selectedAdditionalInsurance?.additionalInsurance === "OTHER" && (
          <TextField
            onBlur={(event) => {
              selectedAdditionalInsurance.additionalInsuranceText === ""
                ? setTextFieldLabel("List other insurance")
                : setTextFieldLabel("Other insurance");
            }}
            onFocus={(event) => {
              setTextFieldLabel("Other insurance");
            }}
            label={textFiendLabel}
            fullWidth
            value={selectedAdditionalInsurance.additionalInsuranceText || ""}
            onChange={(event) => {
              setSelectedAdditionalInsurance({
                additionalInsurance: "OTHER",
                additionalInsuranceText: event.target.value,
              });
              onChange({
                additionalInsurance: "OTHER",
                additionalInsuranceText: event.target.value,
              });
            }}
            disabled={disabled}
            data-public
          />
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  AdditionalInsuranceTypeSelect: {
    color: theme.palette.text.secondary,
  },
}));

const getAdditionalInsuranceText = (
  additionalInsurance: AdditionalInsuranceOptions,
  otherInsurance: AdditionalInsurance[] | undefined
) => {
  const option = otherInsurance
    ? otherInsurance.find((option) => option.additionalInsurance === additionalInsurance)
    : null;

  if (option) {
    return option.additionalInsurance === "OTHER" ? "" : option.additionalInsuranceText;
  } else {
    return "NONE";
  }
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CustomSectionHeaderH6 = styled(H6)(({ theme }) => ({
  marginBottom: "7px",
}));
