import React, { useState } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, styled } from "@material-ui/core";
import { Body2, TextField, Tooltip } from "@coherehealth/common";
import MuiErrorIcon from "@material-ui/icons/Error";
import { H6 } from "@coherehealth/design-system";

interface Props {
  isReadonly?: false;
  clinicalService?: string;
  requestedUnits: number;
  approvedUnits: number;
  setApprovedUnits: (approvedUnits: number) => void;
  healthPlanName: string;
}

interface ReadonlyProps {
  isReadonly: true;
  requestedUnits: number;
  approvedUnits: number;
  clinicalService?: string;
  healthPlanName: string;
}

export default function PartialUnitsApproval({
  requestedUnits,
  approvedUnits: parentApprovedUnits,
  clinicalService,
  healthPlanName,
  ...otherProps
}: Props | ReadonlyProps) {
  const [approvedUnits, setApprovedUnits] = useState<string>(
    !isNaN(parentApprovedUnits) ? String(parentApprovedUnits) : "0"
  );

  const unitsVal = Math.trunc(Number(approvedUnits));

  const unitValidationError = !isNaN(unitsVal) && unitsVal > requestedUnits;

  return (
    <UnitContainer>
      {clinicalService && <H6 style={{ paddingTop: "16px", paddingBottom: "16px" }}>{clinicalService}</H6>}
      <Body2 style={{ paddingBottom: 8 }}>{"Number of service dates"}</Body2>

      <Grid container spacing={3}>
        <Grid item xs={5}>
          <TextField disabled fullWidth type="text" label="Requested" value={requestedUnits} />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            disabled={otherProps.isReadonly}
            type="text"
            error={unitValidationError}
            label="Approved"
            InputProps={{
              endAdornment: unitValidationError && (
                <Tooltip title={"Cannot exceed requested units"} placement="top">
                  <ErrorIcon />
                </Tooltip>
              ),
            }}
            value={approvedUnits}
            onChange={(e) => {
              if (otherProps.isReadonly) {
                return;
              }

              let strVal = e.currentTarget.value;
              if (!strVal) {
                setApprovedUnits("");
                otherProps.setApprovedUnits(0);
              } else {
                const numVal = Math.trunc(Number(strVal));
                if (!isNaN(numVal)) {
                  strVal = Math.max(0, numVal).toString();
                  setApprovedUnits(strVal);
                  otherProps.setApprovedUnits(numVal);
                }
              }
            }}
          />
        </Grid>
      </Grid>
    </UnitContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const UnitContainer = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ErrorIcon = styled(MuiErrorIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}));
