import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";

export type Conversation = {
  salesforceToken?: string;
  conversationId?: string;
};

// Define the interface for the context
export interface ConversationContextInterface {
  saveConversationData: (conversation: Conversation) => void;
  endConversation: () => void;
}

// Default state for the context
const conversationDefaultState = {
  saveConversationData: () => {},
  endConversation: () => {},
} as ConversationContextInterface;

// Create the context
export const ConversationContext = createContext<ConversationContextInterface>(conversationDefaultState);

type ConversationContextProviderProps = {
  children: ReactNode;
};

// Context provider component
const ConversationContextProvider = ({ children }: ConversationContextProviderProps) => {
  const [conversation, setConversation] = useState<Conversation>({});

  const saveConversationData = useCallback((conversationData: Conversation) => {
    setConversation(conversationData);
  }, []);

  const endConversation = useCallback(async () => {
    if (!conversation.conversationId || !conversation.salesforceToken) {
      return;
    }

    const baseUrl = `${process.env.REACT_APP_SALESFORCE_PROVIDER_SCRT2_URL}/iamessage/v1/conversation/${conversation.conversationId}`;

    const response = await fetch(baseUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: conversation.salesforceToken,
      },
      mode: "cors",
      credentials: "same-origin",
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }
  }, [conversation.conversationId, conversation.salesforceToken]);

  const value = useMemo(() => ({ endConversation, saveConversationData }), [endConversation, saveConversationData]);

  return <ConversationContext.Provider value={value}>{children}</ConversationContext.Provider>;
};

export default ConversationContextProvider;

// Custom hook to use the ConversationContext
export const useConversationContext = () => {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error("useConversationContext must be used within a ConversationContextProvider");
  }
  return context;
};
