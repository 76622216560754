import { useState, useEffect, useCallback, SetStateAction, Dispatch, useMemo, useContext } from "react";

import {
  PlaceOfService,
  RuleActions,
  ServiceRequestResponse,
  AuthBuilderWorkflowStep,
  useUpdateServiceRequest,
  RecommendChangeRuleAction,
  useGetServiceRequestRuleActions,
  FacilityCategory,
  useDeleteServiceRequest,
  PossibleAttachmentNudgeReasons,
  WithAcceptAction,
  RuleActionCommon,
} from "@coherehealth/core-platform-api";
import {
  activityContextFromServiceRequest,
  activitySnapshotFromServiceRequest,
  convertRequestFieldsToTrackingField,
  useTrackUserInteraction,
} from "util/userActivityTracker";
import applyActionToServiceRequest from "util/rule/applyActionToServiceRequest";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { isRecommendChangeActionWithAttribute } from "util/rule";
import { TargetFieldLabel, formatDateToISODate } from "@coherehealth/common";
import { DEDICATED_NUDGE_STEP_TARGETS_FOR_ACTION, generateAcceptedNudgeMessages } from "util/NudgeUtils";
import {
  convertStringToAuthBuilderWorkflowStep,
  findDedicatedNudgeStepNudge,
  navigateToPS,
  navigateToSRSummary,
} from "../common";
import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";

interface Props {
  setWorkflowStep: (step: AuthBuilderWorkflowStep) => void;
  serviceRequests: ServiceRequestResponse[];
  canContinueToReviewPage: boolean;
  placeOfServiceToUpdateTo: PlaceOfService | undefined;
  careTypeToUpdateTo: FacilityCategory | undefined;
  dedicatedNudgeIds: string[];
  currentDedicatedNudgeTargetForAction: TargetFieldLabel | undefined;
  setDedicatedNudgeIds: Dispatch<SetStateAction<string[]>>;
  rulesActionsData: RuleActions | null;
  workflowId: string;
  dedicatedNudgeServiceRequestId: string;
  editServiceRequest: (sr: ServiceRequestResponse) => void;
  dedicatedNudgeAccepted: boolean;
  workflowStep: AuthBuilderWorkflowStep;
  patientId: string;
  serviceRequestId?: string;
  displayClearerDraftComponents: boolean;
  refetchActions: ReturnType<typeof useGetServiceRequestRuleActions>["refetch"];
  loadingActions: boolean;
  setCurrentDedicatedNudgeTargetForAction: Dispatch<SetStateAction<TargetFieldLabel | undefined>>;
  setDedicatedNudgeServiceRequestId: Dispatch<SetStateAction<string | undefined>>;
  dnsNudgeIndex: number;
  setDnsNudgeIndex: Dispatch<SetStateAction<number>>;
  dedicatedNudgeResponses: boolean[];
  setDedicatedNudgeResponses: Dispatch<SetStateAction<boolean[]>>;
  acceptedDedicatedNudges: string[] | undefined;
  setShowServiceChangeBanner: Dispatch<SetStateAction<boolean>>;
  formContent?: ServiceRequestFormContent;
  possibleAttachmentNudgeReasons?: PossibleAttachmentNudgeReasons[];
  currentRuleNudge?: RecommendChangeRuleAction;
  continueDisabledForAddAttachmentsDNS?: boolean;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  setFooterHeight: Dispatch<SetStateAction<number>>;
  updatedClinicalServices: string[] | undefined;
  dismissalReason: string;
}

export default function ReviewNudgeFooter({
  setWorkflowStep,
  canContinueToReviewPage,
  placeOfServiceToUpdateTo,
  careTypeToUpdateTo,
  dedicatedNudgeIds,
  currentDedicatedNudgeTargetForAction,
  setDedicatedNudgeIds,
  rulesActionsData,
  serviceRequests,
  workflowId,
  dedicatedNudgeServiceRequestId,
  editServiceRequest,
  dedicatedNudgeAccepted,
  workflowStep,
  patientId,
  serviceRequestId,
  displayClearerDraftComponents,
  refetchActions,
  loadingActions,
  setCurrentDedicatedNudgeTargetForAction,
  setDedicatedNudgeServiceRequestId,
  dnsNudgeIndex,
  setDnsNudgeIndex,
  dedicatedNudgeResponses,
  setDedicatedNudgeResponses,
  acceptedDedicatedNudges,
  setShowServiceChangeBanner,
  formContent,
  possibleAttachmentNudgeReasons,
  currentRuleNudge,
  continueDisabledForAddAttachmentsDNS,
  setFormContent,
  setFooterHeight,
  updatedClinicalServices,
  dismissalReason,
}: Props) {
  const trackUserInteraction = useTrackUserInteraction();

  const [serviceRequest, setServiceRequest] = useState<ServiceRequestResponse>(
    serviceRequests.find((s) => s.id === dedicatedNudgeServiceRequestId) || serviceRequests[0]
  );
  const [editableServiceRequest, setEditableServiceRequest] = useState<ServiceRequestResponse>(serviceRequest);
  const [staticServiceRequest, setStaticServiceRequest] = useState<ServiceRequestResponse>(serviceRequest);
  const [ruleActionsForDedicatedNudge, setRuleActionsForDedicatedNudge] = useState<RecommendChangeRuleAction>();
  const [waitingForTracking, setWaitingForTracking] = useState<boolean>(false);
  const [clickedSaveAndExit, setClickedSaveAndExit] = useState<boolean>(false);
  const navigate = useNavigate();

  const remainingTargetsForAction = useMemo(() => {
    const currentTargetIndex = currentDedicatedNudgeTargetForAction
      ? DEDICATED_NUDGE_STEP_TARGETS_FOR_ACTION.indexOf(currentDedicatedNudgeTargetForAction)
      : 0;
    return DEDICATED_NUDGE_STEP_TARGETS_FOR_ACTION.slice(currentTargetIndex + 1);
  }, [currentDedicatedNudgeTargetForAction]);

  const onFinalDnsNudgeTarget = useMemo(() => remainingTargetsForAction.length === 0, [remainingTargetsForAction]);

  const smartClient = useContext(SmartOnFhirContext);
  const inSmartOnFhirWorkflow = Boolean(smartClient);

  const navigateToPatientSummary = useCallback(() => {
    if (inSmartOnFhirWorkflow) {
      navigateToSRSummary(serviceRequestId || "", navigate, true);
    } else {
      navigateToPS(patientId, navigate, serviceRequestId);
    }
  }, [inSmartOnFhirWorkflow, navigate, patientId, serviceRequestId]);

  const fetchPreSubmissionPageAndRunRuleActions = useCallback(async () => {
    await refetchActions({
      pathParams: { id: editableServiceRequest?.id },
      queryParams: {
        displayTarget: "PRE_SUBMISSION_REVIEW",
        authStage: convertStringToAuthBuilderWorkflowStep("REVIEW"),
      },
    });
    setDedicatedNudgeIds([]);
    setCurrentDedicatedNudgeTargetForAction(undefined);
    setDnsNudgeIndex(0);
    setWorkflowStep(serviceRequests[0]?.requestType === "CONTINUATION" ? "REVIEW_CONTINUATION" : "REVIEW");
  }, [
    editableServiceRequest?.id,
    refetchActions,
    serviceRequests,
    setCurrentDedicatedNudgeTargetForAction,
    setDedicatedNudgeIds,
    setDnsNudgeIndex,
    setWorkflowStep,
  ]);

  const updateDnsStateAndRefetchRules = useCallback(async () => {
    if (Boolean(clickedSaveAndExit)) {
      // Terminate the workflow, and redirect to PS page
      navigateToPatientSummary();
      return;
    }

    //check if we will advance to the Submissino Review page
    await refetchActions({
      pathParams: { id: editableServiceRequest?.id },
      queryParams: {
        displayTarget: onFinalDnsNudgeTarget ? "PRE_SUBMISSION_REVIEW" : "REVIEW_NUDGES",
        authStage: convertStringToAuthBuilderWorkflowStep(onFinalDnsNudgeTarget ? "REVIEW" : "REVIEW_NUDGES"),
      },
    });

    if (onFinalDnsNudgeTarget) {
      setDedicatedNudgeIds([]);
      if (rulesActionsData && acceptedDedicatedNudges) {
        const acceptedDedicatedNudgeMessages = generateAcceptedNudgeMessages(rulesActionsData, acceptedDedicatedNudges);
        setShowServiceChangeBanner(Boolean(acceptedDedicatedNudgeMessages.includes("Swap service")));
      }
      setCurrentDedicatedNudgeTargetForAction(undefined);
      setDnsNudgeIndex(0);
      setWorkflowStep(serviceRequests[0]?.requestType === "CONTINUATION" ? "REVIEW_CONTINUATION" : "REVIEW");
    } else {
      if (rulesActionsData) {
        //skim for dns rules from given list
        const dedicatedNudge = findDedicatedNudgeStepNudge(
          rulesActionsData,
          remainingTargetsForAction,
          dedicatedNudgeIds
        );

        const stayOnDnsPage = !!dedicatedNudge && dedicatedNudge.found;

        if (!stayOnDnsPage) {
          // need to refetch PRE_SUBMISSION_REVIEW rules here
          fetchPreSubmissionPageAndRunRuleActions();
        } else {
          setDedicatedNudgeIds([...dedicatedNudgeIds, dedicatedNudge?.dedicatedNudgeId || ""]);
          setDedicatedNudgeServiceRequestId(editableServiceRequest.id);
          setCurrentDedicatedNudgeTargetForAction(dedicatedNudge?.target);
          setDnsNudgeIndex(dnsNudgeIndex + 1);
        }
      }
    }
  }, [
    acceptedDedicatedNudges,
    clickedSaveAndExit,
    dedicatedNudgeIds,
    dnsNudgeIndex,
    editableServiceRequest.id,
    fetchPreSubmissionPageAndRunRuleActions,
    navigateToPatientSummary,
    onFinalDnsNudgeTarget,
    refetchActions,
    remainingTargetsForAction,
    rulesActionsData,
    serviceRequests,
    setCurrentDedicatedNudgeTargetForAction,
    setDedicatedNudgeIds,
    setDedicatedNudgeServiceRequestId,
    setDnsNudgeIndex,
    setShowServiceChangeBanner,
    setWorkflowStep,
  ]);

  useEffect(() => {
    if (dedicatedNudgeServiceRequestId !== undefined && serviceRequest === undefined) {
      const setCurrentServiceRequest =
        serviceRequests.find((s) => s.id === dedicatedNudgeServiceRequestId) || serviceRequests[0];
      setServiceRequest(setCurrentServiceRequest);
      setEditableServiceRequest(setCurrentServiceRequest);
      setStaticServiceRequest(setCurrentServiceRequest);
    }
    if (!!dedicatedNudgeIds.length) {
      setRuleActionsForDedicatedNudge(
        rulesActionsData
          ?.filter(isRecommendChangeActionWithAttribute)
          .find((r) => r.ruleId === dedicatedNudgeIds[dnsNudgeIndex])
      );
    } else {
      // Todo: Does not surface more than one DNS nudge in regular workflow.
      // Jared is informed and is open to create a new bug ticket for CLIN-APPS.
      fetchPreSubmissionPageAndRunRuleActions();
    }
  }, [
    dedicatedNudgeIds,
    dedicatedNudgeServiceRequestId,
    dnsNudgeIndex,
    fetchPreSubmissionPageAndRunRuleActions,
    navigateToPatientSummary,
    rulesActionsData,
    serviceRequest,
    serviceRequests,
  ]);

  const { mutate: deleteServiceRequest } = useDeleteServiceRequest({});

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: updateServiceRequest,
    loading: updateServiceRequestLoading,
    error: updateServiceRequestError,
  } = useUpdateServiceRequest({
    id: "",
  });

  useEffect(() => {
    if (updateServiceRequestError) {
      enqueueSnackbar("Failed to update service request, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [updateServiceRequestError, enqueueSnackbar]);

  const onHandleInteraction = useCallback(
    async (retVal: ServiceRequestResponse, accepted: boolean) => {
      await trackUserInteraction({
        event: "FIELD_RECOMMENDED_CHANGE",
        stage: "DEDICATED_NUDGE_PAGE",
        interactionAccept: accepted,
        activityContext: {
          ...activityContextFromServiceRequest(editableServiceRequest),
          ruleId: ruleActionsForDedicatedNudge?.ruleId || "",
          workflowId: workflowId,
        },
        field: convertRequestFieldsToTrackingField(ruleActionsForDedicatedNudge?.onAcceptAttribute),
        beforeSnapshot: activitySnapshotFromServiceRequest(staticServiceRequest),
        afterSnapshot: activitySnapshotFromServiceRequest(retVal),
      });
      setWaitingForTracking(false);
    },
    [
      editableServiceRequest,
      ruleActionsForDedicatedNudge?.onAcceptAttribute,
      ruleActionsForDedicatedNudge?.ruleId,
      staticServiceRequest,
      trackUserInteraction,
      workflowId,
    ]
  );

  const handleNudgeAcceptance = useCallback(async () => {
    let retVal = serviceRequest;

    const newSR = applyActionToServiceRequest(
      {
        ...editableServiceRequest,
        placeOfService: placeOfServiceToUpdateTo ?? editableServiceRequest.placeOfService,
      },
      ruleActionsForDedicatedNudge,
      acceptedDedicatedNudges,
      updatedClinicalServices
    );
    if (newSR) {
      const conditionalProviderFacilityCatalogFields = {
        orderingProvider: undefined,
        performingProvider: undefined,
        facility: undefined,
      };
      setWaitingForTracking(true);
      retVal = await updateServiceRequest(
        {
          ...editableServiceRequest,
          ...newSR,
          ...conditionalProviderFacilityCatalogFields,
          patient: editableServiceRequest.patient?.id,
          carePathJourney: editableServiceRequest.carePathJourney?.id,
          clinicalService: newSR.clinicalService?.id || editableServiceRequest.clinicalService?.id,
          authCategory: editableServiceRequest.authCategory?.enumName,
          admissionSource: editableServiceRequest.admissionSource?.enumName,
          dischargedTo: editableServiceRequest.dischargedTo?.enumName,
          palCategory: (newSR.palCategory || editableServiceRequest.palCategory)?.id,
          primarySemanticDiagnosisCode: newSR.primaryDiagnosis || editableServiceRequest.primaryDiagnosis,
          placeOfService: (newSR.placeOfService || editableServiceRequest.placeOfService)?.id,
          reconClaim: undefined,
          semanticProcedureCodes: newSR.semanticProcedureCodes || editableServiceRequest.semanticProcedureCodes,
          workflowStep: workflowStep,
          startDate: formatDateToISODate(formContent?.startDate),
          endDate: formatDateToISODate(formContent?.endDate),
          admissionDate:
            careTypeToUpdateTo?.toLocaleUpperCase() === "OUTPATIENT"
              ? undefined
              : formatDateToISODate(formContent?.admissionDate),
          expectedAdmissionDate:
            careTypeToUpdateTo?.toLocaleUpperCase() === "OUTPATIENT"
              ? undefined
              : formatDateToISODate(formContent?.expectedAdmissionDate),
          dischargeDate:
            careTypeToUpdateTo?.toLocaleUpperCase() === "OUTPATIENT"
              ? undefined
              : formatDateToISODate(formContent?.dischargeDate),
          expectedDischargeDate:
            careTypeToUpdateTo?.toLocaleUpperCase() === "OUTPATIENT"
              ? undefined
              : formatDateToISODate(formContent?.expectedDischargeDate),
        },
        { pathParams: { id: editableServiceRequest?.id } }
      );
      setEditableServiceRequest(retVal);
      editServiceRequest(retVal);
      //recall rule actions
      //to do, make these review nudges calls so we can show next nudge sequentially
    } else if (
      currentDedicatedNudgeTargetForAction === "Does not submit / withdraw" &&
      rulesActionsData?.some(
        (rule: RuleActionCommon & WithAcceptAction) =>
          rule.groupId && rule.onAcceptAction === "WITHDRAW" && acceptedDedicatedNudges?.includes(rule.groupId)
      )
    ) {
      // cancel the auth request because the accepted nudge was "withdraw"
      await deleteServiceRequest(serviceRequest.id);
      navigateToPS(patientId || "", navigate, undefined);
      enqueueSnackbar("Your request has been deleted", { variant: "success" });
    }
    if (editableServiceRequest || newSR) {
      await updateDnsStateAndRefetchRules();
    }
    await onHandleInteraction(retVal, dedicatedNudgeAccepted);
  }, [
    acceptedDedicatedNudges,
    careTypeToUpdateTo,
    currentDedicatedNudgeTargetForAction,
    dedicatedNudgeAccepted,
    deleteServiceRequest,
    editServiceRequest,
    editableServiceRequest,
    enqueueSnackbar,
    formContent?.admissionDate,
    formContent?.dischargeDate,
    formContent?.endDate,
    formContent?.expectedAdmissionDate,
    formContent?.expectedDischargeDate,
    formContent?.startDate,
    navigate,
    onHandleInteraction,
    patientId,
    placeOfServiceToUpdateTo,
    ruleActionsForDedicatedNudge,
    rulesActionsData,
    serviceRequest,
    updateDnsStateAndRefetchRules,
    updateServiceRequest,
    updatedClinicalServices,
    workflowStep,
  ]);

  function isAllowedReason(reason?: string): reason is PossibleAttachmentNudgeReasons["reason"] {
    if (reason) {
      return [
        "DONT_KNOW_WHAT_INFO",
        "INFO_NOT_SPECIFIC_ENOUGH",
        "NO_ACCESS_TO_INFO",
        "DONT_HAVE_INFO",
        "OTHER",
      ].includes(reason);
    }
    return false;
  }

  const handleContinueClick = async () => {
    //resetting usedDedicatedNudgeIds for users to see dns page again back and forth in the workflow
    setDedicatedNudgeIds([]);
    setDedicatedNudgeResponses([]);

    let updatedServiceRequest: ServiceRequestResponse | null = null;

    if (dedicatedNudgeAccepted) {
      //if nudge is accepted, update SR and rerun rules
      //if final DNS nudge, run Review rules
      //else run DNS rules
      await handleNudgeAcceptance();
    } else {
      // Unique logic to handle the Missing Attachment DNS nudge. The nudge has a form where we set the value of `missingAttachmentsReason` and `missingAttachmentReasonText`.
      const reason = possibleAttachmentNudgeReasons?.[0]?.reason;
      const reasonText = possibleAttachmentNudgeReasons?.[0]?.reasonText;
      const ruleId = possibleAttachmentNudgeReasons?.[0]?.ruleId;
      if (reason && ruleId) {
        const newPossibleAttachmentNudgeReason: PossibleAttachmentNudgeReasons = {
          reason: isAllowedReason(reason) ? reason : undefined,
          reasonText: reasonText,
          ruleId: ruleId,
        };

        updatedServiceRequest = await updateServiceRequest(
          {
            workflowStep: workflowStep,
            possibleAttachmentNudgeReasons: [newPossibleAttachmentNudgeReason],
            nudgeRangeofOptionsMetaData: { isAccepted: false },
          },
          { pathParams: { id: serviceRequestId || "" } }
        );
      } else {
        updatedServiceRequest = await updateServiceRequest(
          {
            dismissalReason: dismissalReason,
            workflowStep: workflowStep,
            nudgeRangeofOptionsMetaData: { isAccepted: false },
          },
          { pathParams: { id: serviceRequestId || "" } }
        );
      }
      if (updatedServiceRequest) {
        editServiceRequest(updatedServiceRequest);
      }

      await updateDnsStateAndRefetchRules();
    }
    // Shouldn't need to rerun rules on a declined nudge
    // EXCEPT if we already know the next page is going to be the review page
  };

  const updateServiceRequestHelper = async () => {
    // Unique logic to handle the Missing Attachment DNS nudge. The nudge has a form where we set the value of `missingAttachmentsReason` and `missingAttachmentReasonText`.
    const reason = possibleAttachmentNudgeReasons?.[0]?.reason;
    const reasonText = possibleAttachmentNudgeReasons?.[0]?.reasonText;
    const ruleId = possibleAttachmentNudgeReasons?.[0]?.ruleId;
    if (reason && ruleId) {
      const newPossibleAttachmentNudgeReason: PossibleAttachmentNudgeReasons = {
        reason: isAllowedReason(reason) ? reason : undefined,
        reasonText: reasonText,
        ruleId: ruleId,
      };

      for (const sr of serviceRequests) {
        await updateServiceRequest(
          { workflowStep: workflowStep, possibleAttachmentNudgeReasons: [newPossibleAttachmentNudgeReason] },
          { pathParams: { id: sr?.id } }
        );
      }
    } else {
      for (const sr of serviceRequests) {
        await updateServiceRequest({ workflowStep: workflowStep }, { pathParams: { id: sr?.id } });
      }
    }
  };

  // Below useEffect is needed to forcefully re-render the page ONLY when clickedSaveAndExit state changes.
  useEffect(() => {
    const handleSaveAndExit = async () => {
      if (Boolean(dedicatedNudgeAccepted)) {
        // IF DNS nudge is accepted, apply the changes
        await handleNudgeAcceptance();
        await updateServiceRequestHelper();
      } else if (Boolean(clickedSaveAndExit)) {
        // IF DNS nudge is declined/ not addressed before save&exit, navigate to PS page
        /* 
        Reason for else-if: If else was to be used, the user will always be thrown back to PS page, 
        because of initial IF statement will always be false on initial render.
        */
        navigateToPatientSummary();
      }
    };
    handleSaveAndExit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedSaveAndExit]);

  return (
    <Footer
      onPrimaryButtonClick={handleContinueClick}
      primaryButtonDisabled={
        (!canContinueToReviewPage ||
          updateServiceRequestLoading ||
          waitingForTracking ||
          loadingActions ||
          continueDisabledForAddAttachmentsDNS) ??
        false
      }
      primaryButtonLoading={updateServiceRequestLoading || waitingForTracking || loadingActions}
      primaryButtonText="Continue"
      showSaveAndExitButton={displayClearerDraftComponents}
      onSaveAndExitClick={() => {
        setClickedSaveAndExit(true);
      }}
      saveAndExitButtonDisabled={updateServiceRequestLoading || waitingForTracking || !canContinueToReviewPage}
      setFooterHeight={setFooterHeight}
    />
  );
}
