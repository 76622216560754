import React, { useCallback } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";
import { Body1, SecondaryButton, colorsLight, Card, H6, Subtitle1, Body2 } from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
import NoPatientResults from "../images/NoPatientResults.svg";
import { CardProps as MuiCardProps } from "@material-ui/core/Card";
import { PostgridAddressVerificationResponseData } from "@coherehealth/core-platform-api";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Divider } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { convertToSentenceCase } from "util/providerUtils";

interface IPropsSearchResult {
  addressData: PostgridAddressVerificationResponseData | null;
  onSaveAddressFromSearchResults: () => void;
}

interface IPropsSearchResultCard {
  addressData: PostgridAddressVerificationResponseData;
  cardProps?: MuiCardProps;
  onSaveAddressFromSearchResults: () => void;
}

function PostGridAddressSearchResult({ addressData, onSaveAddressFromSearchResults }: IPropsSearchResult) {
  return (
    <>
      {addressData?.status === "failed" && !addressData?.details?.usMailingsDpvConfirmationIndicator ? (
        <EmptyResultsAddressLookup />
      ) : addressData?.status === "failed" && addressData?.details?.usMailingsDpvConfirmationIndicator ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PartialMatchAddressSearchResultCard
              addressData={addressData}
              onSaveAddressFromSearchResults={onSaveAddressFromSearchResults}
            />
          </Grid>
        </Grid>
      ) : addressData?.status === "corrected" ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Subtitle1 color="textSecondary">{"Select the recommended address below to proceed"}</Subtitle1>
          </Grid>
          <Grid item xs={12}>
            <CorrectedAddressSearchResultCard
              addressData={addressData}
              onSaveAddressFromSearchResults={onSaveAddressFromSearchResults}
            />
          </Grid>
        </Grid>
      ) : addressData?.status === "verified" ? (
        <ValidatedAddress />
      ) : null}
    </>
  );
}

function PartialMatchAddressSearchResultCard({
  addressData,
  cardProps = { elevation: 0 },
  onSaveAddressFromSearchResults,
}: IPropsSearchResultCard) {
  const { spacing } = useTheme();
  const handleClick = useCallback(() => {
    // Pass the variable value back to Component A
    onSaveAddressFromSearchResults();
  }, [onSaveAddressFromSearchResults]);
  return (
    <CardContainer {...cardProps}>
      <InfoBar />
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <InfoIcon style={{ color: colorsLight.info.light }} />
        </Grid>
        <Grid item xs={11}>
          <Body2 style={{ color: colorsLight.info.light }}>
            {"Please confirm this address is valid or edit your entry above"}
          </Body2>
          <Body1 color="textSecondary">
            {addressData.errors?.line1
              ? convertToSentenceCase(addressData.errors.line1[0])
              : addressData.errors?.generic
              ? convertToSentenceCase(addressData.errors.generic[0])
              : ""}
          </Body1>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={7} style={{ margin: "auto" }}>
          <div>
            <H6>{addressData.line1}</H6>
            {!!addressData.line2 && <H6>{addressData.line2}</H6>}
            <H6>
              {addressData.city +
                ", " +
                addressData.provinceOrState +
                " " +
                (addressData.details?.usPostnetBarcode?.slice(1, 6) || addressData.postalOrZip)}
            </H6>
          </div>
        </Grid>
        <Grid item xs={5}>
          <SaveAddressButtonContainer>
            <SecondaryButton
              data-testid="postgrid-set-address"
              style={{
                padding: spacing(2, 2.5),
              }}
              onClick={handleClick}
            >
              Confirm address
            </SecondaryButton>
          </SaveAddressButtonContainer>
        </Grid>
      </Grid>
    </CardContainer>
  );
}

function CorrectedAddressSearchResultCard({
  addressData,
  cardProps = { elevation: 0 },
  onSaveAddressFromSearchResults,
}: IPropsSearchResultCard) {
  const { spacing } = useTheme();
  const handleClick = useCallback(() => {
    // Pass the variable value back to Component A
    onSaveAddressFromSearchResults();
  }, [onSaveAddressFromSearchResults]);
  return (
    <CardContainer {...cardProps}>
      <Grid container>
        <Grid item xs={6} style={{ margin: "auto" }}>
          <div>
            <H6>{addressData.line1}</H6>
            {!!addressData.line2 && <H6>{addressData.line2}</H6>}
            <H6>{addressData.city + ", " + addressData.provinceOrState + " " + addressData.postalOrZip}</H6>
          </div>
        </Grid>
        <Grid item xs={6}>
          <SaveAddressButtonContainer>
            <SecondaryButton
              data-testid="postgrid-set-address"
              style={{
                padding: spacing(2, 2.5),
              }}
              onClick={handleClick}
            >
              Save address
            </SecondaryButton>
          </SaveAddressButtonContainer>
        </Grid>
      </Grid>
    </CardContainer>
  );
}

function EmptyResultsAddressLookup() {
  return (
    <EmptyResultContainer>
      <Row>
        <img alt="" src={NoPatientResults} />
        <NoResultsText>
          No addresses match your search criteria. Please check your information and try again.
        </NoResultsText>
      </Row>
    </EmptyResultContainer>
  );
}

function ValidatedAddress() {
  return (
    <EmptyResultContainer>
      <Row>
        <SuccessIcon />
        <NoResultsText>Your address has been validated.</NoResultsText>
      </Row>
    </EmptyResultContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SaveAddressButtonContainer = styled("div")(({ theme }) => ({
  textAlign: "right",
  margin: 0,
  position: "relative",
  top: "50%",
  mstransform: "translateY(-50%)",
  transform: "translateY(-50%)",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CardContainer = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  width: "100%",
  background: colorsLight.white.highEmphasis,
  borderRadius: theme.spacing(1),
  display: "flex",
  justifyContent: "space-between",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const EmptyResultContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& div": {
    margin: theme.spacing(3),
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const NoResultsText = styled(Body1)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "flex",
  marginLeft: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Row = styled("div")({
  display: "flex",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SuccessIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  fontSize: "28px",
  marginTop: "-3px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const InfoBar = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: 2,
  backgroundColor: theme.palette.info.light,
  top: 0,
  left: 0,
}));

export default PostGridAddressSearchResult;
