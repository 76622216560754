import { useEffect, useState } from "react";

import ChatbotReviewAssistantContent from "./ChatbotReviewAssistantContent";
import ChatbotReviewAssistantControlBar from "./ChatbotReviewAssistantControlBar";
import { useChatbotStyles } from "./chatbotReviewAssistantStyles";
import { useChatbotReviewWebsocket } from "components/ClinicalReview/Review/hooks/useChatbotReviewWebsocket";
import { IReviewChatbotFindingHolder } from "components/ClinicalReview/Review/hooks/useAutomatedReviewChatbotFindings";
import { ReviewType } from "@coherehealth/core-platform-api";
import { ReviewChatbotWebSocketResponse } from "components/ClinicalReview/reviewUtils/chatbotWebsocketTypeHelpers";

interface Props {
  chatbotFindings: IReviewChatbotFindingHolder[];
  setChatbotFindings: React.Dispatch<React.SetStateAction<IReviewChatbotFindingHolder[]>>;
  reviewChatbotUrl: string;
  handleChatbotMessage: (message: ReviewChatbotWebSocketResponse) => void;
  currentReview: ReviewType;
}

export default function ChatbotReviewAssistant({
  chatbotFindings,
  setChatbotFindings,
  reviewChatbotUrl,
  handleChatbotMessage,
  currentReview,
}: Props) {
  const classes = useChatbotStyles({});

  const { sendChatbotMessage, currentUserInput } = useChatbotReviewWebsocket(
    reviewChatbotUrl,
    handleChatbotMessage,
    currentReview
  );

  const [prompt, setPrompt] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [numFindings, setNumFindings] = useState(0);

  const handleSubmission = () => {
    sendChatbotMessage({ user_input: prompt });
    setPrompt("");
    setIsLoading(true);
  };

  useEffect(() => {
    if (chatbotFindings.length !== numFindings) {
      setNumFindings(chatbotFindings.length);
      setIsLoading(false);
    }
  }, [chatbotFindings.length, isLoading, numFindings]);

  return (
    <div className={classes.pageContainer}>
      <ChatbotReviewAssistantContent
        chatbotFindings={chatbotFindings}
        setChatbotFindings={setChatbotFindings}
        isLoading={isLoading}
        currentUserInput={currentUserInput}
      />
      <ChatbotReviewAssistantControlBar prompt={prompt} setPrompt={setPrompt} handleSubmission={handleSubmission} />
    </div>
  );
}
