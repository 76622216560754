import React, { createContext, useCallback, useRef, useState } from "react";
import usePreserveReviewRightColumnScrollPosition, {
  IUsePreserveReviewRightColumnScrollPosition,
} from "../Review/hooks/usePreserveReviewRightColumnScrollPosition";
import { LeftPanelTabs } from "./utils";
import {
  ReviewType,
  MdReview,
  NurseReview,
  PeerToPeerReview,
  ClinicalReviewChangeRequest,
  Coverage,
  Patient,
} from "@coherehealth/core-platform-api";
import { ReviewOutcomeOption } from "components/ServiceRequest/ReviewSection/util/QueueManagementReviewUtil";
import { Queue } from "@coherehealth/qm-api";

interface ClinicalReviewProviderProps {
  children: React.ReactNode;

  latestReviewLeadingToDenial: MdReview | NurseReview | PeerToPeerReview | null;
  latestChangeRequest: ClinicalReviewChangeRequest | null;
  latestChangeRequestReady?: boolean;
  clinicalReviewChangeRequestReady?: boolean;
  patientPrimaryCoverage?: Coverage | null;
  additionalActiveCoverages?: Coverage[] | null;
  isMultiCoverageReviewRequired: boolean;
  patient?: Patient | null;
  allowedReviewOutcomes: ReviewOutcomeOption[];
  currentReview?: ReviewType | null;
  healthPlanName?: string;
  delegatedVendorName?: string;
  queue?: ContextQueueProperties;
  leftColumnTab: LeftPanelTabs;
  setLeftColumnTab: React.Dispatch<React.SetStateAction<LeftPanelTabs>>;
  getAttachmentNameById: (attachmentId: string) => string | null;
  openAttachmentByName: (attachmentName: string) => void;
  openAttachment: (attachmentId: string) => void;
}

type ContextQueueProperties = Pick<Queue, "id" | "holdsEnabled" | "name" | "holdReasons">;

interface ClinicalReviewContextCommonAttachmentsState {
  getAttachmentNameById: (attachmentId: string) => string | null;
  openAttachmentByName: (attachmentName: string) => void;
  openAttachment: (attachmentId: string) => void;
  jumpToPageRef: React.MutableRefObject<((pageNumber: number) => void) | undefined>;
  registerJumpToPage: (jumpToPage: (pageNumber: number) => void) => void;
}

interface ClinicalReviewContextCommonReviewAssistantState {
  expandedFindingId: string | null;
  setExpandedFindingId: React.Dispatch<React.SetStateAction<string | null>>;
}

export type ClinicalReviewContextType = {
  latestReviewLeadingToDenial: MdReview | NurseReview | PeerToPeerReview | null;
  latestChangeRequest: ClinicalReviewChangeRequest | null;
  latestChangeRequestReady?: boolean;
  clinicalReviewChangeRequestReady?: boolean;
  patientPrimaryCoverage?: Coverage | null;
  additionalActiveCoverages?: Coverage[] | null;
  isMultiCoverageReviewRequired: boolean;
  patient?: Patient | null;
  allowedReviewOutcomes: ReviewOutcomeOption[];
  currentReview?: ReviewType | null;
  healthPlanName?: string;
  delegatedVendorName?: string;
  queue?: ContextQueueProperties;
  leftColumnTab: LeftPanelTabs;
  setLeftColumnTab: React.Dispatch<React.SetStateAction<LeftPanelTabs>>;
  reviewRightColumnPositionState: IUsePreserveReviewRightColumnScrollPosition;
  commonAttachmentsState: ClinicalReviewContextCommonAttachmentsState;
  commonReviewAssistantState: ClinicalReviewContextCommonReviewAssistantState;
};

const defaultState: ClinicalReviewContextType = {
  latestReviewLeadingToDenial: null,
  latestChangeRequest: null,
  latestChangeRequestReady: false,
  clinicalReviewChangeRequestReady: false,
  patientPrimaryCoverage: null,
  patient: null,
  isMultiCoverageReviewRequired: false,
  allowedReviewOutcomes: [],
  currentReview: null,
  leftColumnTab: "REQUEST_INFORMATION",
  setLeftColumnTab: () => {},
  reviewRightColumnPositionState: {
    handleTabChange: () => {},
    getTopLevelScrollDivRef: () => {
      return null;
    },
    reviewAssistantScrollDivRef: null,
  },
  commonAttachmentsState: {
    getAttachmentNameById: () => null,
    openAttachmentByName: () => {},
    openAttachment: () => {},
    registerJumpToPage: () => {},
    jumpToPageRef: { current: undefined },
  },
  commonReviewAssistantState: {
    expandedFindingId: null,
    setExpandedFindingId: () => {},
  },
};

export const ClinicalReviewContext = createContext<ClinicalReviewContextType>(defaultState);

export const ClinicalReviewProvider = ({
  children,
  allowedReviewOutcomes,
  isMultiCoverageReviewRequired,
  latestChangeRequest,
  latestReviewLeadingToDenial,
  latestChangeRequestReady,
  clinicalReviewChangeRequestReady,
  patientPrimaryCoverage,
  additionalActiveCoverages,
  patient,
  currentReview,
  healthPlanName,
  delegatedVendorName,
  queue,
  leftColumnTab,
  setLeftColumnTab,
  getAttachmentNameById,
  openAttachment,
  openAttachmentByName,
}: ClinicalReviewProviderProps) => {
  // Scroll position state for the right column
  const reviewRightColumnPositionState = usePreserveReviewRightColumnScrollPosition();
  // End scroll position state for the right column

  // Common attachments state
  const jumpToPageRef = useRef<((pageNumber: number) => void) | undefined>(undefined);

  const registerJumpToPage = useCallback((jumpToPage: (pageNumber: number) => void) => {
    jumpToPageRef.current = jumpToPage;
  }, []);

  const commonAttachmentsState: ClinicalReviewContextCommonAttachmentsState = {
    getAttachmentNameById,
    openAttachment,
    openAttachmentByName,
    registerJumpToPage,
    jumpToPageRef,
  };
  // End common attachments state

  // Review Assistant state
  const [expandedFindingId, setExpandedFindingId] = useState<string | null>(null);
  const commonReviewAssistantState: ClinicalReviewContextCommonReviewAssistantState = {
    expandedFindingId,
    setExpandedFindingId,
  };
  // End Review Assistant state

  return (
    <ClinicalReviewContext.Provider
      value={{
        reviewRightColumnPositionState,
        leftColumnTab,
        setLeftColumnTab,
        latestReviewLeadingToDenial,
        latestChangeRequest,
        latestChangeRequestReady,
        clinicalReviewChangeRequestReady,
        patientPrimaryCoverage,
        additionalActiveCoverages,
        patient,
        isMultiCoverageReviewRequired,
        allowedReviewOutcomes,
        currentReview,
        healthPlanName,
        delegatedVendorName,
        queue,
        commonAttachmentsState,
        commonReviewAssistantState,
      }}
    >
      {children}
    </ClinicalReviewContext.Provider>
  );
};
