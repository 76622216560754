import { Address, PhoneNumber, TemporaryPatientFormField } from "@coherehealth/core-platform-api";
import { TextField } from "@coherehealth/common";
import { useState } from "react";
import ContactAddressModal from "./ContactAddressModal";
import { Grid } from "@mui/material";
import { isOptionalField } from "../formOptions";

interface ContactAddressFormState {
  dispatch: React.Dispatch<any>;
  formOptionalFields?: TemporaryPatientFormField[];
  aorAddress?: boolean;
}

export type ContactAddressFields = Address &
  PhoneNumber & {
    email: string | null;
  };

export const ContactAddressFormSection = ({ dispatch, formOptionalFields, aorAddress }: ContactAddressFormState) => {
  const [searchText, setSearchText] = useState<string>();
  const [contactAddressModalOpen, setContactAddressModalOpen] = useState(false);

  function getAddressLabel(fields: ContactAddressFields | undefined): string {
    let finalAddress: string = "";
    if (fields) {
      if (fields?.line1) {
        finalAddress = `${finalAddress}${fields.line1}`;
      }
      if (fields?.line2) {
        finalAddress = `${finalAddress}, ${fields.line2}`;
      }
      if (fields?.city) {
        finalAddress = `${finalAddress}. ${fields.city}, `;
      }
      if (fields?.state) {
        finalAddress = `${finalAddress}${fields.state} `;
      }
      if (fields?.zipCode) {
        finalAddress = `${finalAddress}${fields.zipCode}`;
      }
    }
    return finalAddress;
  }

  const onModalSave = (fields: ContactAddressFields) => {
    if (fields) {
      if (fields?.line1 && fields?.city && fields?.state && fields?.zipCode) {
        dispatch({
          type: "UPDATE_CONTACT_INFO",
          payload: {
            ADDRESS_LINE_1: fields.line1,
            ADDRESS_LINE_2: fields.line2,
            CITY: fields.city,
            STATE: fields.state,
            ZIP_CODE: fields.zipCode,
            NUMBER: fields.number,
            EXTENSION: fields.extension,
            EMAIL: fields.email,
          },
        });
        dispatch({
          type: "UPDATE_FORM_ERROR",
          payload: {
            ADDRESS_LINE_1: !fields.line1 && !isOptionalField("ADDRESS_LINE_1", formOptionalFields),
            ADDRESS_LINE_2: false,
            CITY: !fields.city && !isOptionalField("CITY", formOptionalFields),
            STATE: !fields.state && !isOptionalField("STATE", formOptionalFields),
            ZIP_CODE: !fields.zipCode && !fields.zipCode && !isOptionalField("ZIP_CODE", formOptionalFields),
            NUMBER: false,
            EXTENSION: false,
            EMAIL: false,
          },
        });
      }
    }
    const finalAddress = getAddressLabel(fields);
    setSearchText(finalAddress);
  };

  return (
    <>
      <Grid key="CONTACT_ADDRESS" item xs={12} marginRight={aorAddress ? 0 : 8} data-testid="contact-address-section">
        <TextField
          fullWidth
          label={aorAddress ? "Representative's address" : "Contact address"}
          type="text"
          onClick={() => {
            setContactAddressModalOpen(true);
          }}
          value={searchText || ""}
        />
      </Grid>
      <ContactAddressModal
        open={contactAddressModalOpen}
        onClose={() => {
          setContactAddressModalOpen(false);
        }}
        onSave={onModalSave}
        aorAddress={aorAddress}
      />
    </>
  );
};
