import {
  formatDateStr,
  H6,
  InlineButton,
  parseDateFromISOString,
  parseDateFromISOStringWithoutFallback,
  plusDays,
} from "@coherehealth/common";
import {
  AuthorizationResponse,
  LevelOfCareResponse,
  PatientStatus,
  PatientStayDate,
  useGetLevelsOfCare,
} from "@coherehealth/core-platform-api";
import { isDelegatedVendorOakStreetHealth } from "util/patientUtils";
import { Caption } from "@coherehealth/design-system";
import { Box, Grid, makeStyles, useTheme } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import {
  addDays,
  areIntervalsOverlapping,
  differenceInDays,
  isEqual as datesAreEqual,
  isValid,
  isWithinInterval,
} from "date-fns";
import { isEqual, last } from "lodash";
import listRemove from "util/listRemove";
import listReplace from "util/listReplace";
import { PatientStayDateRange } from "common/SharedServiceRequestFormComponents";
import PatientStayDateRow, { StayPageVersion } from "./PatientStayDateRow";
import { PatientStaysErrorState } from "./usePatientStayDateOnCRR";
import { useFeature } from "@coherehealth/common";
import { useMemo } from "react";
import { warn as logWarning } from "logger";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import { usePatientContext } from "util/context/PatientContext";
import { FacilityCategory } from "@coherehealth/core-platform-api";

interface Props {
  version: StayPageVersion;
  healthPlanName: string;
  patientStayDateRanges: PatientStayDateRange[];
  setPatientStayDateRanges: (newStayDateRanges: PatientStayDateRange[]) => void;
  patientStaysErrorStates?: PatientStaysErrorState[];
  isMock?: boolean;
  levelsOfCare?: LevelOfCareResponse[];
  attemptedSubmit?: boolean;
  isDischarged?: boolean;
  authorization?: AuthorizationResponse;
  resetDischargedFields?: () => void;
  onAddOrRemoveDateRangeDecorator?: (updatedDateRanges: PatientStayDateRange[]) => PatientStayDateRange[];
  lastDecisionedDay?: Date;
  isRnPartialAdminApproval?: boolean;
  minimumFromDate?: Date;
  admissionDate: Date | null;
  patientStatus?: PatientStatus;
  isReview?: boolean;
  includePatientStayDatesOnPlannedAdmission?: boolean;
  encounterType?: FacilityCategory;
  disabled?: boolean;
  forceOneLine?: boolean;
}

const PatientStays = ({
  version,
  healthPlanName,
  patientStayDateRanges,
  setPatientStayDateRanges,
  patientStaysErrorStates,
  isMock,
  levelsOfCare,
  attemptedSubmit,
  isDischarged,
  authorization,
  resetDischargedFields,
  onAddOrRemoveDateRangeDecorator,
  lastDecisionedDay,
  isRnPartialAdminApproval = false,
  minimumFromDate,
  admissionDate,
  patientStatus,
  encounterType,
  isReview,
  includePatientStayDatesOnPlannedAdmission,
  disabled,
  forceOneLine,
}: Props) => {
  const { palette } = useTheme();
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  const classes = useStyles({ version, isFaxAuthBuilderFlow });
  const expandedReviewStayDatesEditsEnabled = useFeature("expandedReviewStayDatesEdits");
  const { patientData } = usePatientContext();
  const facilityBasedFlowForOakStreetHealthPlanFF = useFeature("facilityBasedFlowForOakStreetHealthPlan");

  const enableFacilityBasedFlowForOakStreetHealthPlan =
    facilityBasedFlowForOakStreetHealthPlanFF &&
    isDelegatedVendorOakStreetHealth(encounterType, healthPlanName, patientData, admissionDate)
      ? true
      : false;

  const { data: levelsOfCareResponse } = useGetLevelsOfCare({
    queryParams: {
      max: 20,
      healthPlanName,
      ...(enableFacilityBasedFlowForOakStreetHealthPlan && {
        delegatedVendorName: "Oak Street Health",
      }),
    },
    mock: isMock
      ? {
          data: levelsOfCare,
        }
      : undefined,
  });

  const removePatientStayDateRange = (index: number) => {
    if (patientStayDateRanges && patientStayDateRanges.length > index) {
      let newDateRanges = listRemove([...patientStayDateRanges], index) || [];
      if (onAddOrRemoveDateRangeDecorator) {
        newDateRanges = onAddOrRemoveDateRangeDecorator(newDateRanges);
      }
      setPatientStayDateRanges(newDateRanges);
    }
  };

  const updateDateRange = (
    {
      rangeStartDate: newStartDate,
      rangeEndDate: newEndDate,
      requestedLevelOfCareCode: newRequestedLevelOfCare,
      approvedLevelOfCareCode: newApprovedLevelOfCare,
      reviewStatus: newReviewStatus,
    }: Partial<PatientStayDateRange>,
    rowIndex: number
  ) => {
    const currentDateRanges: PatientStayDateRange[] = [...patientStayDateRanges];
    const currentDateRange = currentDateRanges[rowIndex];
    const newDateRanges = listReplace(currentDateRanges, rowIndex, {
      ...currentDateRange,
      rangeStartDate: newStartDate === null ? null : newStartDate || currentDateRange.rangeStartDate,
      rangeEndDate: newEndDate === null ? null : newEndDate || currentDateRange.rangeEndDate,
      requestedLevelOfCareCode:
        newRequestedLevelOfCare === null ? null : newRequestedLevelOfCare || currentDateRange.requestedLevelOfCareCode,
      approvedLevelOfCareCode:
        newApprovedLevelOfCare === null ? null : newApprovedLevelOfCare || currentDateRange.approvedLevelOfCareCode,
      reviewStatus: newReviewStatus === null ? null : newReviewStatus || currentDateRange.reviewStatus,
    });
    setPatientStayDateRanges(newDateRanges);
  };

  const addDateRange = () => {
    if (patientStayDateRanges.length === 0 && !!resetDischargedFields && isDischarged) {
      //If it is discharged and does not have current stay date ranges,
      //reset discharged fields values when adding a new date range
      resetDischargedFields();
    }
    const lastDateRangeStartDate = last(patientStayDateRanges)?.rangeStartDate;
    const lastDateRangeEndDate = last(patientStayDateRanges)?.rangeEndDate;
    const newDateRange = {
      rangeStartDate: lastDecisionedDay
        ? addDays(lastDecisionedDay, 1)
        : lastDateRangeEndDate
        ? addDays(lastDateRangeEndDate, 1)
        : lastDateRangeStartDate
        ? addDays(lastDateRangeStartDate, 1)
        : null, // todo fix
      rangeEndDate: null,
      requestedLevelOfCareCode: null,
      approvedLevelOfCareCode: null,
      reviewStatus: null,
    };
    let updatedPatientStayDateRanges = [...patientStayDateRanges, newDateRange];
    if (onAddOrRemoveDateRangeDecorator) {
      updatedPatientStayDateRanges = onAddOrRemoveDateRangeDecorator(updatedPatientStayDateRanges);
    }
    setPatientStayDateRanges(updatedPatientStayDateRanges);
  };

  const numDaysHelperText = useNumDaysHelperText({
    prevPatientStayDates: authorization?.patientStayDates,
    currStayDateRanges: patientStayDateRanges,
  });

  const firstRowMinDate = expandedReviewStayDatesEditsEnabled ? admissionDate : minimumFromDate;

  return (
    <Box className={classes.rootContainer}>
      {version !== "PatientStay" &&
        version !== "PatientStayResponsive" &&
        ((includePatientStayDatesOnPlannedAdmission && patientStatus === "NOT_YET_ADMITTED") ||
        patientStatus !== "NOT_YET_ADMITTED" ? (
          <H6 className={classes.header}>
            {version === "DecisionStay"
              ? "Decisioned stay"
              : expandedReviewStayDatesEditsEnabled
              ? "Review dates"
              : "Requested stay"}
          </H6>
        ) : null)}
      {patientStayDateRanges?.map((patientStayDateRange, i) => {
        const previousRowEndDate: Date | null | undefined = patientStayDateRanges[i - 1]?.rangeEndDate;
        let admissionDateErrorMessage: string | undefined = undefined;
        if (patientStaysErrorStates?.[i]?.admissionDateErrorForInitial) {
          admissionDateErrorMessage = `Date must match admission${
            admissionDate && ` (${formatDateStr(admissionDate)})`
          }`;
        }
        if (patientStaysErrorStates?.[i]?.admissionDateErrorForContinuation) {
          admissionDateErrorMessage = `Date can't be before admission${
            admissionDate && ` (${formatDateStr(admissionDate)})`
          }`;
        }

        return (
          <PatientStayDateRow
            key={`${patientStayDateRange.reviewStatus}_${patientStayDateRange.requestedLevelOfCareCode}_${i}`}
            rowIndex={i}
            disableRangeEndDate={
              expandedReviewStayDatesEditsEnabled ? false : isDischarged && i === patientStayDateRanges.length - 1
            }
            levelsOfCareResponse={levelsOfCareResponse}
            removePatientStayDateRange={removePatientStayDateRange}
            updateDateRange={updateDateRange}
            minDate={
              i === 0 && firstRowMinDate
                ? firstRowMinDate // first row, so use admission date as minimum
                : !!previousRowEndDate
                ? plusDays(1, previousRowEndDate) // n-th row, so use end date of (row n-1) + 1
                : undefined
            }
            patientStaysErrorState={patientStaysErrorStates?.[i]}
            version={version}
            attemptedSubmit={attemptedSubmit}
            isRnPartialAdminApproval={isRnPartialAdminApproval}
            admissionDateErrorMessage={admissionDateErrorMessage}
            patientStatus={patientStatus}
            disabled={disabled}
            forceOneLine={forceOneLine}
            {...patientStayDateRange}
          />
        );
      })}
      {attemptedSubmit &&
      patientStaysErrorStates &&
      (patientStaysErrorStates.some((errorState) => errorState.datesGap || errorState.datesOverlap) ||
        patientStaysErrorStates?.some((errorState) => errorState.toDateError && !errorState.dateEmptyError)) ? (
        <Grid item className={classes.errorContainer}>
          <Caption color={palette.error.dark}>{getErrorFieldMessage("datesGap")}</Caption>
        </Grid>
      ) : null}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={isDischarged ? classes.dischargedDateRangeContainer : classes.paCheckDateRangeContainer}
      >
        {(includePatientStayDatesOnPlannedAdmission && patientStatus === "NOT_YET_ADMITTED") ||
        patientStatus !== "NOT_YET_ADMITTED" ? (
          <Grid item>
            <InlineButton startIcon={<Add />} onClick={addDateRange} disabled={disabled}>
              Add a date range
            </InlineButton>
          </Grid>
        ) : null}{" "}
        {patientStayDateRanges.length > 0 && (
          <Grid item>
            <Caption color="text.secondary">{numDaysHelperText}</Caption>
          </Grid>
        )}
        {patientStayDateRanges.length === 0 && isDischarged && isReview && (
          <Grid item>
            <Caption color="text.secondary">
              A discharge with no review dates should have a review outcome of “withdrawn”
            </Caption>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PatientStays;

interface CssProps {
  version: StayPageVersion;
  isFaxAuthBuilderFlow?: boolean;
}

const useStyles = makeStyles((theme) => ({
  errorContainer: {
    padding: "8px",
  },
  dateRangeContainer: {
    padding: theme.spacing(1),
  },
  paCheckDateRangeContainer: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dischargedDateRangeContainer: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(-2),
  },
  rootContainer: {
    width: "100%",
  },
  header: {
    padding: ({ version, isFaxAuthBuilderFlow }: CssProps) =>
      isFaxAuthBuilderFlow ? "8px" : version === "RequestedStay" ? "0px 0px 8px 8px" : "0px 0px 8px 0px",
  },
}));

const getErrorFieldMessage = (errorField: string) => {
  switch (errorField) {
    case "dateEmptyError":
      return "Dates cannot be empty";
    case "datesOverlap":
    case "datesGap":
    case "nextToDatePriorToPrevBackDate":
      return "Dates must have no gaps or overlaps";
    case "decisionEmptyError":
      return "Decision cannot be empty";
    case "approvedLocEmptyError":
      return "Approved level of care cannot be empty";
    default:
      return "";
  }
};

export const aggregateStayDateByCommonFields = (
  patientStayDates: PatientStayDate[] | undefined
): PatientStayDateRange[] => {
  let patientStayDateRanges: PatientStayDateRange[] = [];
  if (!!patientStayDates?.length) {
    let fromDate = parseDateFromISOString(patientStayDates[0].date);
    let toDate = parseDateFromISOString(patientStayDates[0].date);
    let initialRequestedLoc = patientStayDates[0].requestedLevelOfCareCode;
    let initialReviewStatus = patientStayDates[0].reviewStatus;
    let initialApprovedLoc = patientStayDates[0].approvedLevelOfCareCode;
    let initialReviewDecision = patientStayDates[0]?.initialReviewDecision;
    for (let i = 1; i < patientStayDates.length; i++) {
      const currentPatientStayDate = patientStayDates[i];
      if (
        isEqual(initialRequestedLoc, currentPatientStayDate.requestedLevelOfCareCode) &&
        isEqual(initialApprovedLoc, currentPatientStayDate.approvedLevelOfCareCode) &&
        isEqual(initialReviewStatus, currentPatientStayDate.reviewStatus)
      ) {
        toDate = parseDateFromISOString(patientStayDates[i].date);
      } else {
        patientStayDateRanges = [
          ...patientStayDateRanges,
          {
            rangeStartDate: fromDate,
            rangeEndDate: toDate,
            requestedLevelOfCareCode: initialRequestedLoc ? initialRequestedLoc : null,
            approvedLevelOfCareCode: initialApprovedLoc ? initialApprovedLoc : null,
            reviewStatus: initialReviewStatus ? initialReviewStatus : null,
            initialReviewDecision: initialReviewDecision ? initialReviewDecision : null,
          },
        ];
        fromDate = parseDateFromISOString(patientStayDates[i].date);
        toDate = parseDateFromISOString(patientStayDates[i].date);
        initialRequestedLoc = patientStayDates[i].requestedLevelOfCareCode;
        initialReviewStatus = patientStayDates[i].reviewStatus;
        initialApprovedLoc = patientStayDates[i].approvedLevelOfCareCode;
      }
    }
    return [
      ...patientStayDateRanges,
      {
        rangeStartDate: fromDate,
        rangeEndDate: toDate,
        requestedLevelOfCareCode: initialRequestedLoc ? initialRequestedLoc : null,
        approvedLevelOfCareCode: initialApprovedLoc ? initialApprovedLoc : null,
        reviewStatus: initialReviewStatus ? initialReviewStatus : null,
        initialReviewDecision: initialReviewDecision ? initialReviewDecision : null,
      },
    ];
  } else {
    return []; //add initial admission date in this case
  }
};

// deAggregateStayDates will create an array with size equal to the number of days in the range.
// If we accidentily pass in a range like 0202-01-01 -> 2024-01-01 (i.e. year 202 -> 2024), this could cause the frontend
// to break. So impose a limit of 50 years. Hopefully someone isn't staying in the hospital for 50 years straight....
const MAX_RANGE_LENGTH = 365 * 50;

export const deAggregateStayDates = (stayDateRanges: PatientStayDateRange[]): PatientStayDate[] => {
  return stayDateRanges.flatMap((stayDateRange: PatientStayDateRange) => {
    const rangeStartDate = stayDateRange.rangeStartDate;
    const rangeEndDate = stayDateRange.rangeEndDate;
    let stayDates: PatientStayDate[] = [];
    if (rangeStartDate && rangeEndDate) {
      const numberOfDaysInRange = Math.abs(differenceInDays(rangeStartDate, rangeEndDate));
      if (numberOfDaysInRange > MAX_RANGE_LENGTH) {
        // See comment above MAX_RANGE_LENGTH definition
        logWarning(`Trying to de-aggregate a stay range with ${numberOfDaysInRange} days. Skipping.`);
        return [];
      }
      for (let date = rangeStartDate; date <= rangeEndDate; date = addDays(date, 1)) {
        stayDates = [
          ...stayDates,
          {
            date: date.toISOString(),
            requestedLevelOfCareCode: stayDateRange.requestedLevelOfCareCode
              ? stayDateRange.requestedLevelOfCareCode
              : undefined,
            reviewStatus: stayDateRange.reviewStatus ? stayDateRange.reviewStatus : undefined,
            approvedLevelOfCareCode: stayDateRange.approvedLevelOfCareCode
              ? stayDateRange.approvedLevelOfCareCode
              : undefined,
          },
        ];
      }
      return stayDates;
    } else {
      return [];
    }
  });
};

type Interval = { start: Date; end: Date };

const isValidInterval = (interval: Interval) => {
  return interval.start.getTime() <= interval.end.getTime();
};

export const dateRangesOverlap = (stayDateRanges: PatientStayDateRange[]): boolean[] => {
  if (stayDateRanges.length <= 1) {
    return [false];
  }

  let rowOverlapping: boolean[] = stayDateRanges.map((_, index) => false);

  for (let i = 0; i < stayDateRanges.length - 1; i++) {
    const leftStart = stayDateRanges[i].rangeStartDate;
    const leftEnd = stayDateRanges[i].rangeEndDate;
    for (let j = i + 1; j < stayDateRanges.length; j++) {
      const rightStart = stayDateRanges[j].rangeStartDate;
      const rightEnd = stayDateRanges[j].rangeEndDate;
      if (leftStart && leftEnd && rightStart && rightEnd) {
        const intervalLeft: Interval = { start: leftStart, end: leftEnd };
        const intervalRight: Interval = { start: rightStart, end: rightEnd };
        const intervalOverlapping =
          isValidInterval(intervalLeft) &&
          isValidInterval(intervalRight) &&
          areIntervalsOverlapping(intervalLeft, intervalRight, { inclusive: true });

        rowOverlapping[i] = rowOverlapping[i] || intervalOverlapping;
        rowOverlapping[j] = rowOverlapping[j] || intervalOverlapping;
      } else if (leftStart && leftEnd && (rightStart || rightEnd)) {
        const intervalLeft: Interval = { start: leftStart, end: leftEnd };
        const rightDate = rightStart || rightEnd;
        const intervalOverlapping =
          (rightDate && isValidInterval(intervalLeft) && isWithinInterval(rightDate, intervalLeft)) || false;

        rowOverlapping[i] = rowOverlapping[i] || intervalOverlapping;
        rowOverlapping[j] = rowOverlapping[j] || intervalOverlapping;
      } else if ((leftStart || leftEnd) && rightStart && rightEnd) {
        const leftDate = leftStart || leftEnd;
        const intervalRight: Interval = { start: rightStart, end: rightEnd };
        const intervalOverlapping =
          (leftDate && isValidInterval(intervalRight) && isWithinInterval(leftDate, intervalRight)) || false;

        rowOverlapping[i] = rowOverlapping[i] || intervalOverlapping;
        rowOverlapping[j] = rowOverlapping[j] || intervalOverlapping;
      }
    }
  }
  return rowOverlapping;
};

export const checkDateFormat = (date: Date | null): boolean => {
  return isValid(date) && date?.getFullYear().toString().length === 4;
};

function useNumDaysHelperText({
  prevPatientStayDates,
  currStayDateRanges,
}: {
  prevPatientStayDates?: PatientStayDate[];
  currStayDateRanges: PatientStayDateRange[];
}): string {
  const expandedReviewStayDatesEditsEnabled = useFeature("expandedReviewStayDatesEdits");

  const previouslyDecisionedDates = useMemo(
    () =>
      prevPatientStayDates
        ?.filter(({ reviewStatus }) => reviewStatus && ["APPROVED", "DENIED"].includes(reviewStatus))
        ?.map(({ date }) => parseDateFromISOStringWithoutFallback(date))
        ?.filter((date): date is Date => !!date),
    [prevPatientStayDates]
  );

  const currPatientStayDates = deAggregateStayDates(currStayDateRanges);

  let numPreviouslyDecisionedDaysBeingEdited = 0;
  for (const currPatientStayDate of currPatientStayDates) {
    const currPatientStayDateDate = parseDateFromISOStringWithoutFallback(currPatientStayDate.date);
    if (
      currPatientStayDateDate &&
      previouslyDecisionedDates?.find((date) => datesAreEqual(date, currPatientStayDateDate))
    ) {
      numPreviouslyDecisionedDaysBeingEdited++;
    }
  }

  // Additional days are the number of days being requested that are not previously decisioned
  let additionalDays = currPatientStayDates.length - numPreviouslyDecisionedDaysBeingEdited;

  let helperTextParts: string[] = [];
  if (additionalDays) {
    helperTextParts.push(
      `${additionalDays === 0 ? "--" : additionalDays} ${
        previouslyDecisionedDates?.length ? "additional" : "total"
      } day${additionalDays === 1 ? "" : "s"}`
    );
  } else if (!additionalDays && !numPreviouslyDecisionedDaysBeingEdited) {
    helperTextParts.push(`-- ${previouslyDecisionedDates?.length ? "additional" : "total"} days`);
  }
  if (numPreviouslyDecisionedDaysBeingEdited && expandedReviewStayDatesEditsEnabled) {
    helperTextParts.push(
      `${numPreviouslyDecisionedDaysBeingEdited} previously decisioned day${
        numPreviouslyDecisionedDaysBeingEdited === 1 ? "" : "s"
      }`
    );
  }

  return helperTextParts.join(", ");
}
