import React, { useState } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, Theme } from "@material-ui/core/styles";
import { Body1 } from "../Typography";
import { Card } from "../Card";
import { colorsDark } from "../../themes";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "./TableCell";
import { CardContent, Collapse } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

interface Column<T> {
  name: string;
  width: string;
  themedPaddingRight: number;
  value: (arg: T) => React.ReactNode;
  allowTextWrap?: boolean;
}

interface IProps<T> {
  index: number;
  item: T;
  tableColumns: Column<T>[];
  onRowClick?: (row: T, index: number, event: React.MouseEvent<Element, MouseEvent>) => void;
  clickable?: boolean;
  noTextWrap?: boolean;
  onDelete?: (row: T, index: number) => void;
  cardHeight?: "short" | "tall";
  wide?: boolean;
  rowExpansion?: (row: T, index: number) => React.ReactNode;
  rowColor?: string;
  rowHoverShadow?: string;
  hideRowHoverBorderColor?: boolean;
  rowActiveBackgroundColor?: string;
  show3linesAndThenEllipsis?: boolean;
  showTotalItems?: boolean;
  showWarningBorder?: boolean;
  selectableRows?: boolean;
  selected?: boolean;
  onSelect?: (isSelected: boolean) => void;
}

export default function TableRow<T>({
  index,
  item,
  tableColumns,
  onRowClick,
  clickable,
  noTextWrap,
  onDelete,
  cardHeight,
  rowExpansion,
  rowColor,
  wide,
  rowHoverShadow,
  hideRowHoverBorderColor,
  rowActiveBackgroundColor,
  show3linesAndThenEllipsis,
  showTotalItems,
  showWarningBorder,
  selectableRows,
  selected,
  onSelect,
}: IProps<T>) {
  const [expanded, setExpanded] = useState(false);
  let totalItemsCount = 0;
  const linesToDisplayBeforeDisplayingEllipsis = 3; //used in filter tab for Guidelines

  return (
    <div>
      <TableCard
        onClick={(event: React.MouseEvent<Element, MouseEvent>) => {
          if (onRowClick) {
            onRowClick(item, index, event);
          }
          setExpanded(!expanded);
        }}
        cardHeight={cardHeight}
        clickable={Boolean(clickable || onRowClick)}
        rowColor={rowColor}
        rowHoverShadow={rowHoverShadow}
        hideRowHoverBorderColor={hideRowHoverBorderColor}
        wide={wide}
        rowActiveBackgroundColor={rowActiveBackgroundColor}
        showWarningBorder={showWarningBorder}
      >
        {selectableRows && (
          <TableCell width="50px" themedPaddingRight={1} overflow="visible">
            <Checkbox
              color="primary"
              checked={selected}
              onChange={(e) => onSelect && onSelect(e.target.checked)}
              onClick={(e) => e.stopPropagation()}
              inputProps={{ "aria-label": "select row" }}
            />
          </TableCell>
        )}
        {tableColumns.map((column, index) => {
          if (column.width !== "0%") {
            const val = column.value(item);
            const isString = typeof val === "string" || val === undefined;
            //Used to count total number of items if you want it displayed
            if (isString && showTotalItems) {
              let itemsArray = val?.split(",");
              totalItemsCount = itemsArray?.length || 0;
            }
            // Allow column to set their own noTextWrap. This overrides noTextWrap props
            let noTextWrapStyle = column.allowTextWrap ? false : noTextWrap;
            return (
              <TableCell
                width={column.width}
                themedPaddingRight={column.themedPaddingRight}
                key={index}
                noTextWrap={noTextWrapStyle}
              >
                {isString ? (
                  //Added the styles twice here since they aren't inherited and was breaking the changes implemented in CLIN-1059
                  <div
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: noTextWrapStyle ? "nowrap" : show3linesAndThenEllipsis ? "pre-line" : undefined,
                      display: show3linesAndThenEllipsis ? "-webkit-box" : undefined,
                      WebkitBoxOrient: show3linesAndThenEllipsis ? "vertical" : undefined,
                      WebkitLineClamp: show3linesAndThenEllipsis ? linesToDisplayBeforeDisplayingEllipsis : undefined,
                      overflow: noTextWrapStyle || show3linesAndThenEllipsis ? "hidden" : undefined,
                      textOverflow: noTextWrapStyle || show3linesAndThenEllipsis ? "ellipsis" : undefined,
                    }}
                  >
                    <Body1
                      style={{
                        wordBreak: "break-word",
                        whiteSpace: noTextWrapStyle ? "nowrap" : show3linesAndThenEllipsis ? "pre-line" : undefined,
                        display: show3linesAndThenEllipsis ? "-webkit-box" : undefined,
                        WebkitBoxOrient: show3linesAndThenEllipsis ? "vertical" : undefined,
                        WebkitLineClamp: show3linesAndThenEllipsis ? linesToDisplayBeforeDisplayingEllipsis : undefined,
                        overflow: noTextWrapStyle || show3linesAndThenEllipsis ? "hidden" : undefined,
                        textOverflow: noTextWrapStyle || show3linesAndThenEllipsis ? "ellipsis" : undefined,
                      }}
                    >
                      {val ? val : "--"}
                    </Body1>
                    {showTotalItems && totalItemsCount > 1 ? (
                      <Body1 color="textSecondary">({totalItemsCount} total)</Body1>
                    ) : null}
                  </div>
                ) : (
                  <div style={{ width: "95%" }}>{val}</div>
                )}
              </TableCell>
            );
          } else {
            return <></>;
          }
        })}
        {!!onDelete && (
          <IconButton onClick={() => onDelete(item, index)} style={{ alignSelf: "flex-end", zIndex: 1 }}>
            <DeleteIcon />
          </IconButton>
        )}
      </TableCard>
      {rowExpansion && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>{rowExpansion(item, index)}</CardContent>
        </Collapse>
      )}
    </div>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const TableCard = styled(
  ({
    wide,
    cardHeight,
    clickable,
    rowColor,
    rowHoverShadow,
    hideRowHoverBorderColor,
    rowActiveBackgroundColor,
    showWarningBorder,
    ...other
  }) => <Card {...other} />
)<
  Theme,
  {
    wide?: boolean;
    cardHeight?: string;
    clickable: boolean;
    rowColor?: string;
    rowHoverShadow?: string;
    hideRowHoverBorderColor?: boolean;
    rowActiveBackgroundColor?: string;
    showWarningBorder?: boolean;
  }
>(
  ({
    theme,
    wide,
    cardHeight,
    clickable,
    rowColor,
    rowHoverShadow,
    hideRowHoverBorderColor,
    rowActiveBackgroundColor,
    showWarningBorder,
  }) => ({
    display: "flex",
    padding: theme.spacing(cardHeight === "tall" ? 4 : cardHeight === "short" ? 0.5 : 2, wide ? 2 : 3),
    marginBottom: theme.spacing(cardHeight === "tall" ? 4 : cardHeight === "short" ? 2 : 2),
    position: "relative",
    cursor: clickable ? "pointer" : "",
    borderLeft: showWarningBorder ? "12px solid #FFC107" : "none",
    borderTop: "none",
    borderBottom: "none",
    ...(clickable
      ? {
          "&:hover": {
            borderColor: hideRowHoverBorderColor ? "none" : colorsDark.gray.dark,
            boxShadow: rowHoverShadow ? rowHoverShadow : "none",
          },
          "&:active": {
            backgroundColor: rowActiveBackgroundColor ? rowActiveBackgroundColor : "none",
          },
        }
      : {}),
    ...(rowColor
      ? {
          backgroundColor: rowColor,
        }
      : {}),
  })
);
