import { Body1 } from "@coherehealth/common";
import { useChatbotStyles } from "./chatbotReviewAssistantStyles";

export default function ChatbotIncomingGuardrailsResponse() {
  const classes = useChatbotStyles({});

  return (
    <div className={classes.guardrailsResponseContainer}>
      <Body1 className={classes.guardrailsResponseText}>
        This question is outside of the Review Assistant’s scope. Review Assistant can help to locate and summarize
        evidence in attachments.
      </Body1>
    </div>
  );
}
