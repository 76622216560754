import React, { Dispatch, SetStateAction, useCallback } from "react";
import OnboardingQuestionCard from "./OnboardingQuestionCard";
import { H1, Checkbox, RadioGroup, colorsLight, useFeature } from "@coherehealth/common";
import { FormGroup, makeStyles } from "@material-ui/core";
import { ProviderOrgInfo } from "./shared";
import HeaderAndProgressBar from "./HeaderAndProgressBar";
import SpecialtiesMultiSelect from "./SpecialtiesMultiSelect";
import useHealthPlanOptions from "hooks/useHealthPlanOptions";

interface Props {
  providerOrgInfo: ProviderOrgInfo;
  setProviderOrgInfo: Dispatch<SetStateAction<ProviderOrgInfo>>;
  isAdmin: boolean | undefined;
  setAdminStatus: Dispatch<boolean>;
  isAvailityUser: boolean;
  attemptedSubmitOnClick?: boolean;
}

export default function ScopeQuestionsPage({
  providerOrgInfo,
  setProviderOrgInfo,
  isAdmin,
  setAdminStatus,
  isAvailityUser,
  attemptedSubmitOnClick,
}: Props) {
  const classes = useStyles();

  const onboardingRedesign = useFeature("onboardingRedesign");
  const setSpecialties = (specialties: string[]) => {
    setProviderOrgInfo((prev) => ({ ...prev, organizationSpecialties: specialties }));
  };

  const healthPlanOptionsWithNone = useHealthPlanOptions();

  /* 
    Note: These options should be in alphabetical order in a 2 column grid
    i.e. a,b,c,d,e,f => a,d,b,e,c,f
    Odd positions: a,b,c
    Even positions: d,e,f
  */
  const specialtyOptions = [
    { id: "Anesthesiology", label: "Anesthesiology" },
    { id: "Neurology and/or neurosurgery", label: "Neurology and/or neurosurgery" },
    { id: "Chiropathy", label: "Chiropathy" },
    { id: "Nursing home", label: "Nursing home" },
    { id: "Cardiology", label: "Cardiology" },
    { id: "Occupational therapy", label: "Occupational therapy" },
    { id: "Facility or hospital", label: "Facility or hospital" },
    { id: "Orthopedics", label: "Orthopedics" },
    { id: "General or family practice", label: "General or family practice" },
    { id: "Pain management", label: "Pain management" },
    { id: "General surgery", label: "General surgery" },
    { id: "Physical therapy", label: "Physical therapy" },
    { id: "Multiple specialty", label: "Multiple specialty" },
    { id: "Speech therapy", label: "Speech therapy" },
  ];

  const adminOptions = [
    { id: "true", label: "Yes" },
    { id: "false", label: "No" },
  ];

  const getHealthPlanNameById = useCallback(
    (id: string) => {
      return healthPlanOptionsWithNone.find((plan) => plan.id === id)?.name || "";
    },
    [healthPlanOptionsWithNone]
  );

  const getHealthPlanIdByName = useCallback(
    (name: string) => {
      return healthPlanOptionsWithNone.find((plan) => plan.name === name)?.id || "";
    },
    [healthPlanOptionsWithNone]
  );

  const setChecked = useCallback(
    (id: string, checked: boolean) => {
      const isCurrentlySelected = providerOrgInfo.healthPlans.map(getHealthPlanIdByName).filter(Boolean).includes(id);
      const selectedPlanName = getHealthPlanNameById(id);

      if (checked && !isCurrentlySelected) {
        if (id === "none") {
          setProviderOrgInfo({
            ...providerOrgInfo,
            healthPlans: [getHealthPlanNameById("none")],
          });
        } else {
          setProviderOrgInfo({
            ...providerOrgInfo,
            healthPlans: [
              ...providerOrgInfo.healthPlans.filter((planName) => planName !== getHealthPlanNameById("none")),
              selectedPlanName,
            ].filter(Boolean),
          });
        }
      }
      if (!checked && isCurrentlySelected) {
        setProviderOrgInfo({
          ...providerOrgInfo,
          healthPlans: providerOrgInfo.healthPlans.filter((planName) => planName !== selectedPlanName),
        });
      }
    },
    [getHealthPlanIdByName, getHealthPlanNameById, providerOrgInfo, setProviderOrgInfo]
  );

  return (
    <div className={classes.containerPadding}>
      <HeaderAndProgressBar stepNumber={1} isAvailityUser={isAvailityUser} />
      <div className={classes.titleText}>
        <H1>Check if Cohere is the right portal for your organization</H1>
      </div>
      <OnboardingQuestionCard
        titleText={"Do you plan on submitting prior authorizations with any of these health plans?"}
        displayQuestionSubtext={"Select all that apply"}
        isFieldRequired={!providerOrgInfo.healthPlans.length && attemptedSubmitOnClick}
      >
        <FormGroup className={classes.grayAnswerText}>
          {healthPlanOptionsWithNone.map(({ id, name }) => (
            <Checkbox
              key={id}
              label={name}
              checked={providerOrgInfo.healthPlans.includes(getHealthPlanNameById(id))}
              onChange={(checked) => {
                setChecked(id, checked);
              }}
              muiCheckboxProps={{
                className: classes.checkbox,
              }}
            />
          ))}
        </FormGroup>
      </OnboardingQuestionCard>
      {!onboardingRedesign && (
        <OnboardingQuestionCard
          titleText={"Which of the following specialties best describes your organization's clinical focus?"}
        >
          <RadioGroup
            className={classes.grayAnswerText}
            options={specialtyOptions}
            radioGroupWidth={6}
            value={providerOrgInfo?.organizationSpecialty}
            onChange={(val) => {
              setProviderOrgInfo({
                ...providerOrgInfo,
                organizationSpecialty: val,
              });
            }}
          />
        </OnboardingQuestionCard>
      )}
      {onboardingRedesign && (
        <SpecialtiesMultiSelect
          questionTitle={
            "Which specialties will your organization submit auths for (to any vendor or payer, not just Cohere)?"
          }
          highlightInfoFormErrors={false}
          isFieldRequired={!providerOrgInfo.organizationSpecialties.length && attemptedSubmitOnClick}
          setSpecialties={setSpecialties}
          specialties={providerOrgInfo.organizationSpecialties || []}
        />
      )}
      <OnboardingQuestionCard
        titleText={"Will you be an organization administrator?"}
        displayQuestionSubtext={
          "If you will be an organization admin, we can get you set up with an account right away. As an administrative user, you will be responsible for creating and deleting user accounts. After the account is set up, you will be able to add additional users as admins."
        }
        isFieldRequired={isAdmin === undefined && attemptedSubmitOnClick}
      >
        <RadioGroup
          className={classes.grayAnswerText}
          options={adminOptions}
          value={isAdmin ? "true" : isAdmin === false ? "false" : "indeterminate"}
          onChange={(val) => {
            setAdminStatus(val === "true");
          }}
        />
      </OnboardingQuestionCard>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  titleText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    display: "flex",
  },
  bannerTextPadding: {
    marginBottom: theme.spacing(1),
  },
  containerPadding: {
    paddingBottom: theme.spacing(10),
  },
  grayAnswerText: {
    color: colorsLight.font.secondary,
  },
  bullets: {
    margin: 0,
  },
  checkbox: {
    // tightens up the vertical spacing between checkboxes
    margin: theme.spacing(-0.5, 0),
  },
}));
