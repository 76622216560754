import {
  AdditionalInsurance,
  FacilityCategory,
  RequestTiming,
  useGetFacilityBasedRequestConfiguration,
  useGetOtherInsuranceConfiguration,
  useGetPostDecisionConfiguration,
  PostDecisionConfigurationResponse,
  useGetAutomatedDenialLetterConfiguration,
  AutomatedDenialLetterConfigurationResponse,
  FeatureConfigurationLevel,
  AdditionalCareParticipantConfig,
  useGetAdditionalCareParticipantsFeatureConfiguration,
  useGetAuthStatusAttachmentsTransitionConfiguration,
  AuthStatusAttachmentsTransitionConfigurationResponse,
  useGetLettersExpansionConfiguration,
  LettersExpansionConfigurationResponse,
  useGetScrubFieldsFromResponsesConfiguration,
  ServiceRequestEditConfig,
  AuthStatus,
  useGetServiceRequestEditConfiguration,
  FacilityBasedRequestConfigurationResponse,
  useGetTemporaryMemberAuthsConfiguration,
  TemporaryMemberAuthsConfigurationResponse,
  useGetContinuationConfiguration,
  ContinuationConfigurationResponse,
  TransferAuthorizationConfigurationResponse,
  useGetTransferAuthorizationConfiguration,
  Patient,
  useGetServiceRequestStateConfiguration,
  RequestType,
  EditableField,
  useGetFaxIntakeConfiguration,
  FaxIntakeConfigurationResponse,
  ShowPalStatusToReviewerConfigurationResponse,
  useGetShowPalStatusToReviewerConfiguration,
  NonPalCheckboxConfigurationResponse,
  useGetNonPalCheckboxConfiguration,
  useGetPatientCoverageRiskBearingEntityEditsAllowedConfiguration,
  PatientCoverageRiskBearingEntityEditsAllowedConfigurationResponse,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import { useContext, useEffect, useState } from "react";
import { UseMutateProps, UseMutateReturn } from "restful-react";
import { IGNORE_ERRORS, error as logError } from "logger";
import { ScrubTinsContext } from "../components/ScrubTinsContext";
import { useFeature } from "@coherehealth/common";
import { usePatientContext } from "../util/context/PatientContext";
import { User, UserContext } from "UserContext";
import { parseRolesFromUser } from "util/user";
import {
  ContinuationFieldName,
  FacilityBasedAuthSubmissionForm,
  ServiceRequestFieldName,
} from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { isDelegatedVendorOakStreetHealth } from "util/patientUtils";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { PriorAuthRequirements } from "components/AuthBuilder/common";

interface FacilityBasedRequestConfigurationProps {
  healthPlanName?: string;
  encounterType?: FacilityCategory;
  requestTiming?: RequestTiming;
  patientData?: Patient | null;
  priorAuthRequirements?: PriorAuthRequirements;
  skipRequestTimingCheck?: boolean; //This prop is to make sure isFacilityBasedAuth check is skipped before showing any facility feature changes to UI.
  delegatedVendorName?: string;
  srContent?: ServiceRequestResponse | ServiceRequestFormContent | null;
}
interface OtherInsuranceConfigurationProps {
  healthPlanName?: string;
}

interface ScrubFieldsFromResponsesConfigurationProps {
  healthPlanName?: string;
}

interface ServiceRequestEditConfigurationProps {
  authStatus?: AuthStatus;
  delegatedVendorName?: string;
  healthPlanName?: string;
}

interface CareParticipantConfigurationProps {
  healthPlanName?: string;
}

interface FetchError {
  data?: string | FetchError;
  message?: string;
}

interface ServiceRequestStateConfigurationProps {
  authStatus?: AuthStatus;
  healthPlanName?: string;
  delegatedVendorName?: string;
  requestType?: RequestType;
}

const shouldIgnoreErrorEntry = (error: FetchError, depth = 0): boolean => {
  const ignoreEntry = IGNORE_ERRORS.includes(error.message ?? "");
  if (!ignoreEntry && error.data && typeof error.data !== "string" && depth < 5) {
    return shouldIgnoreErrorEntry(error.data, depth + 1);
  }
  return ignoreEntry;
};

const useGetFacilityBasedRequestConfigurationByPayer = ({
  healthPlanName,
  encounterType,
  requestTiming, //TODO: remove requestTiming check we should not combine multiple condition
  skipRequestTimingCheck,
  delegatedVendorName,
  priorAuthRequirements,
  srContent,
}: FacilityBasedRequestConfigurationProps) => {
  const [config, setConfig] = useState<FacilityBasedRequestConfigurationResponse>();
  // Extract patient data from the Patient Context
  const { patientData } = usePatientContext();

  const { mutate: facilityBasedRequestConfigurationByPayer, loading: facilityBasedFeatureLoading } =
    useGetFacilityBasedRequestConfiguration({
      queryParams: {
        featureConfigurationLevel: "DELEGATED_VENDOR",
        healthPlanName: healthPlanName || "",
        delegatedVendorName: isDelegatedVendorOakStreetHealth(
          encounterType,
          healthPlanName,
          patientData,
          priorAuthRequirements?.startDate || srContent?.startDate || srContent?.admissionDate
        )
          ? "Oak Street Health"
          : delegatedVendorName,
      },
    });

  useEffect(() => {
    let unmounted = false;
    const getConfig = async () => {
      try {
        if (healthPlanName && !unmounted) {
          const config = await facilityBasedRequestConfigurationByPayer();
          !unmounted && setConfig(config); // Set the state data only if the component stills mounted after the fetch
        }
      } catch (err) {
        if (!shouldIgnoreErrorEntry(err as FetchError)) {
          logError("Could not fetch facility-Based-Request configurations for payer");
        }
      }
    };

    if (!unmounted) {
      getConfig();
    }

    return () => {
      unmounted = true;
    };
  }, [healthPlanName, facilityBasedRequestConfigurationByPayer]);

  // Retrieve the feature flag value for 'facilityBasedFlowForOakStreetHealthPlan'
  const facilityBasedFlowForOakStreetHealthPlanFF = useFeature("facilityBasedFlowForOakStreetHealthPlan");

  // Enable the facility-based flow for Oak Street Health Plan if the feature flag is enabled
  // and the patient is under a delegated vendor 'Oak Street Health'
  const enableFacilityBasedFlowForOakStreetHealthPlan =
    facilityBasedFlowForOakStreetHealthPlanFF &&
    isDelegatedVendorOakStreetHealth(
      encounterType,
      healthPlanName,
      patientData,
      priorAuthRequirements?.startDate || srContent?.startDate || srContent?.admissionDate
    )
      ? true
      : false;

  const facilityBasedFeatureEnabled =
    (config?.enabled &&
      encounterType === "INPATIENT" &&
      (!!skipRequestTimingCheck || ["CONCURRENT", "POST_SERVICE"].includes(requestTiming || ""))) ||
    enableFacilityBasedFlowForOakStreetHealthPlan;

  const checkEmptyRequestedLoc =
    (config?.levelOfCareIsMandatoryOnReviews && encounterType === "INPATIENT" && facilityBasedFeatureEnabled) || false;
  const includePatientStayDatesOnPlannedAdmission = config?.includePatientStayDatesOnPlannedAdmission;

  const loadingOrProcessing = facilityBasedFeatureLoading || !Boolean(config);

  return {
    facilityBasedFeatureEnabled,
    checkEmptyRequestedLoc,
    includePatientStayDatesOnPlannedAdmission,
    facilityBasedFeatureLoading: loadingOrProcessing,
  };
};

export const useGetOtherInsuranceConfigurationByPayer = ({ healthPlanName }: OtherInsuranceConfigurationProps) => {
  const [otherInsuranceEnabled, setOtherInsuranceEnabled] = useState<boolean>();
  const [otherInsurance, setOtherInsurance] = useState<AdditionalInsurance[]>();
  const { mutate: getOtherInsuranceConfiguration } = useGetOtherInsuranceConfiguration({
    queryParams: {
      featureConfigurationLevel: "HEALTH_PLAN",
      healthPlanName: healthPlanName || "",
    },
  });

  useEffect(() => {
    let unmounted = false;
    const getConfig = async () => {
      try {
        if (healthPlanName && !unmounted) {
          const config = await getOtherInsuranceConfiguration();
          const enabled = config.enabled;
          const options = config.otherInsuranceOptions;
          !unmounted && setOtherInsuranceEnabled(enabled); // Set the state data only if the component stills mounted after the fetch
          !unmounted && setOtherInsurance(options); // Set the state data only if the component stills mounted after the fetch
        }
      } catch (err) {
        if (!shouldIgnoreErrorEntry(err as FetchError)) {
          logError("Could not fetch other-insurance configurations for payer");
        }
      }
    };

    if (!unmounted) {
      getConfig();
    }

    return () => {
      unmounted = true;
    };
  }, [healthPlanName, getOtherInsuranceConfiguration]);

  return { otherInsuranceEnabled, otherInsurance };
};

//set the ScrubTinsContext based off the value returned from the /ScrubFieldsFromResponsesConfiguration/configureByHealthPlan request
export const useSetScrubTinsContextByHealthPlanConfiguration = ({
  healthPlanName,
}: ScrubFieldsFromResponsesConfigurationProps) => {
  const { setScrubTinsContextState } = useContext(ScrubTinsContext);
  const { mutate: getScrubFieldsFromResponsesConfiguration } = useGetScrubFieldsFromResponsesConfiguration({
    queryParams: {
      featureConfigurationLevel: "HEALTH_PLAN",
      healthPlanName: healthPlanName || "",
    },
  });

  useEffect(() => {
    // Avoid unnecessary fetches when healthPlanName
    if (!healthPlanName) {
      return;
    }

    let unmounted = false;
    const getConfig = async () => {
      try {
        if (healthPlanName && !unmounted) {
          const config = await getScrubFieldsFromResponsesConfiguration();
          const enabled = config.scrubTins ?? false;
          !unmounted && setScrubTinsContextState(enabled); // Set the state data only if the component stills mounted after the fetch
        }
      } catch (err) {
        if (!shouldIgnoreErrorEntry(err as FetchError)) {
          logError("Could not fetch scrubFieldsFromResponseConfiguration for payer");
        }
      }
    };

    if (!unmounted) {
      getConfig();
    }

    return () => {
      unmounted = true;
    };
  }, [healthPlanName, getScrubFieldsFromResponsesConfiguration, setScrubTinsContextState]);
};

export const useGetServiceRequestEditConfigurationByPayerAndAuthStatus = ({
  delegatedVendorName,
  healthPlanName,
  authStatus,
}: ServiceRequestEditConfigurationProps) => {
  const [srEditConfig, setSrEditConfig] = useState<ServiceRequestEditConfig>();

  const { mutate: getSrEditConfig } = useGetServiceRequestEditConfiguration({
    queryParams: {
      delegatedVendorName: delegatedVendorName,
      featureConfigurationLevel: "DELEGATED_VENDOR",
      healthPlanName: healthPlanName || "",
    },
  });

  useEffect(() => {
    let unmounted = false;
    const getConfig = async () => {
      try {
        if (healthPlanName && !unmounted) {
          const config = await getSrEditConfig();
          // Save the config corresponding with the given AuthStatus, otherwise save the default config
          const authStatusMappings = config.authStatusServiceRequestEditMappings;
          if (authStatus && authStatusMappings) {
            const mappingIndex = authStatusMappings?.findIndex((mapping) => mapping.authStatus === authStatus);
            if (mappingIndex !== undefined && mappingIndex > -1) {
              const newSrEditConfig = authStatusMappings[mappingIndex].serviceRequestEditConfig;
              // Disabled button tooltips fall back to the default if undefined (empty string will remain empty)
              if (newSrEditConfig && !newSrEditConfig.disableFormEditsMessage) {
                newSrEditConfig.disableFormEditsMessage = `You can't edit this service request when it has a status of ${authStatus.toLowerCase()}`;
              }
              if (newSrEditConfig && !newSrEditConfig.disableAttachmentEditsMessage) {
                newSrEditConfig.disableAttachmentEditsMessage = `You can’t edit attachments for a request that has been ${authStatus.toLowerCase()}`;
              }
              if (newSrEditConfig && !newSrEditConfig.disableClinicalAssessmentQuestionsMessage) {
                newSrEditConfig.disableClinicalAssessmentQuestionsMessage =
                  "You can't edit the clinical assessment after submission";
              }
              !unmounted && setSrEditConfig(newSrEditConfig); // Set the state data only if the component stills mounted after the fetch
            } else {
              !unmounted && setSrEditConfig(config.defaultAuthStatusConfig); // Set the state data only if the component stills mounted after the fetch
            }
          } else {
            !unmounted && setSrEditConfig(config.defaultAuthStatusConfig); // Set the state data only if the component stills mounted after the fetch
          }
        }
      } catch (err) {
        if (!shouldIgnoreErrorEntry(err as FetchError)) {
          logError("Could not fetch other-insurance configurations for payer");
        }
      }
    };

    if (!unmounted) {
      getConfig();
    }

    return () => {
      unmounted = true;
    };
  }, [authStatus, getSrEditConfig, healthPlanName]);

  return { srEditConfig };
};

export const useGetCareParticipantsConfigByHealthPlan = ({ healthPlanName }: CareParticipantConfigurationProps) => {
  const [careParticipantEnabled, setCareParticipantEnabled] = useState<boolean>();
  const [careParticipantConfig, setCareParticipantConfig] = useState<AdditionalCareParticipantConfig[]>();
  const { mutate: getCareParticipantConfiguration } = useGetAdditionalCareParticipantsFeatureConfiguration({
    queryParams: {
      featureConfigurationLevel: "HEALTH_PLAN",
      healthPlanName: healthPlanName || "",
    },
  });

  useEffect(() => {
    let unmounted = false;
    const getConfig = async () => {
      try {
        if (healthPlanName && !unmounted) {
          const config = await getCareParticipantConfiguration();
          const enabled = config.enabled;
          const options = config.additionalCareParticipantList;
          !unmounted && setCareParticipantEnabled(enabled); // Set the state data only if the component stills mounted after the fetch
          !unmounted && setCareParticipantConfig(options); // Set the state data only if the component stills mounted after the fetch
        }
      } catch (err) {
        if (!shouldIgnoreErrorEntry(err as FetchError)) {
          logError("Could not fetch careParticipants Configuration for the health plan");
        }
      }
    };

    if (!unmounted) {
      getConfig();
    }

    return () => {
      unmounted = true;
    };
  }, [healthPlanName, getCareParticipantConfiguration]);

  return { careParticipantEnabled, careParticipantConfig };
};

type GetPayorConfigByHealthPlanQueryParams = {
  featureConfigurationLevel: FeatureConfigurationLevel;
  healthPlanName: string;
};
type GetPayorConfigByDelegatedVendorQueryParams = {
  featureConfigurationLevel: FeatureConfigurationLevel;
  healthPlanName: string;
  delegatedVendorName: string;
};
/**
 * Wrapper around useMutate so it can be used with the useGet API
 * For basic retrieval of payor config objects at the health plan level
 * @param useMutateHook The original hook from restful-react
 * @param healthPlanName The health plan name, from the service request
 * @param description Description of the config; used for logging
 */
function useGetPayorConfigByHealthPlan<TData>(
  useMutateHook: (
    props: Omit<
      UseMutateProps<
        TData,
        unknown,
        GetPayorConfigByHealthPlanQueryParams | GetPayorConfigByDelegatedVendorQueryParams,
        void,
        void
      >,
      "path" | "verb"
    >
  ) => UseMutateReturn<
    TData,
    unknown,
    void,
    GetPayorConfigByHealthPlanQueryParams | GetPayorConfigByDelegatedVendorQueryParams,
    void
  >,
  healthPlanName: string = "",
  description?: string,
  delegatedVendorName?: string
) {
  const queryParams: GetPayorConfigByHealthPlanQueryParams = {
    featureConfigurationLevel: "HEALTH_PLAN",
    healthPlanName,
  };

  const queryParamsDelegatedVendor: GetPayorConfigByDelegatedVendorQueryParams = {
    featureConfigurationLevel: "DELEGATED_VENDOR",
    healthPlanName,
    delegatedVendorName: delegatedVendorName ?? "",
  };

  const { mutate, error, loading } = useMutateHook({
    queryParams: delegatedVendorName ? queryParamsDelegatedVendor : queryParams,
  });
  const [data, setData] = useState<TData | null>(null);

  useEffect(() => {
    let unmounted = false;
    const fetchData = async () => {
      try {
        if (healthPlanName && !unmounted) {
          const config = await mutate();
          !unmounted && setData(config); // Set the state data only if the component stills mounted after the mutation
        }
      } catch (error) {
        if (!shouldIgnoreErrorEntry(error as FetchError)) {
          let errorMessage = `Could not fetch ${description || "payor config"}`;
          errorMessage += healthPlanName ? ` for ${healthPlanName}` : "";
          logError(errorMessage);
        }
      }
    };

    // Only perform the fetch if the component was not unmounted, cancel it otherwise
    if (!unmounted) {
      fetchData();
    }

    return () => {
      unmounted = false;
    };
  }, [healthPlanName, description, mutate]);

  return {
    data,
    error,
    loading,
  };
}

export function useGetPostDecisionConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<PostDecisionConfigurationResponse>(
    useGetPostDecisionConfiguration,
    healthPlanName,
    "post-decision payor config"
  );
}

export function useGetAutomatedDenialLetterConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<AutomatedDenialLetterConfigurationResponse>(
    useGetAutomatedDenialLetterConfiguration,
    healthPlanName,
    "automated denial letter payor config"
  );
}

export function useGetAuthStatusAttachmentsTransitionConfigurationConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<AuthStatusAttachmentsTransitionConfigurationResponse>(
    useGetAuthStatusAttachmentsTransitionConfiguration,
    healthPlanName,
    "auth status attachments transition added payor config"
  );
}

export function useGetLettersExpansionConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<LettersExpansionConfigurationResponse>(
    useGetLettersExpansionConfiguration,
    healthPlanName,
    "letters expansion payor config"
  );
}

export function useGetTemporaryMemberAuthsConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<TemporaryMemberAuthsConfigurationResponse>(
    useGetTemporaryMemberAuthsConfiguration,
    healthPlanName,
    "temporary patients and members authorizations payor config"
  );
}

export function useGetContinuationConfigurationByPayer(healthPlanName: string, delegatedVendorName: string) {
  return useGetPayorConfigByHealthPlan<ContinuationConfigurationResponse>(
    useGetContinuationConfiguration,
    healthPlanName,
    "continuations payor config",
    delegatedVendorName
  );
}

export function useGetTransferAuthorizationConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<TransferAuthorizationConfigurationResponse>(
    useGetTransferAuthorizationConfiguration,
    healthPlanName,
    "transfer authorization payor config"
  );
}

export function useShowPalStatusToReviewerConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<ShowPalStatusToReviewerConfigurationResponse>(
    useGetShowPalStatusToReviewerConfiguration,
    healthPlanName,
    "config to enable showing pal list status to clinical reviewers"
  );
}

export function useGetPatientCoverageRiskBearingEntityEditsAllowedConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<PatientCoverageRiskBearingEntityEditsAllowedConfigurationResponse>(
    useGetPatientCoverageRiskBearingEntityEditsAllowedConfiguration,
    healthPlanName,
    "config to enable coverage-level and patient-levvel riskBearingEntity edits through the Patient Management portal"
  );
}

export const useGetServiceRequestStateConfigurationByPayerAndAuthStatus = ({
  healthPlanName,
  authStatus,
  delegatedVendorName,
  requestType,
}: ServiceRequestStateConfigurationProps) => {
  const [srStateConfig, setSrStateConfig] = useState<EditableField[]>();
  const [currUser, setUser] = useState<User>();
  const { getUser } = useContext(UserContext);
  const { mutate: getSrStateConfig } = useGetServiceRequestStateConfiguration({
    queryParams: {
      featureConfigurationLevel: "DELEGATED_VENDOR",
      healthPlanName: healthPlanName || "",
      delegatedVendorName: delegatedVendorName || "",
    },
  });
  useEffect(() => {
    getUser()
      ?.then((currentUser) => {
        setUser(currentUser);
      })
      .catch((error) => {
        console.log("error loading current user: ", error);
      });
  }, [getUser]);

  useEffect(() => {
    let unmounted = false;
    const getConfig = async () => {
      try {
        if (healthPlanName && !unmounted) {
          const config = await getSrStateConfig();
          // Save the config corresponding with the given AuthStatus, otherwise save the default config
          const authStatusMappings = config.authStatusServiceRequestStateMappings;
          if (authStatus && authStatusMappings && config.enabled) {
            const mappingIndex = authStatusMappings?.findIndex((mapping) => mapping.authStatus === authStatus);
            if (mappingIndex !== undefined && mappingIndex > -1) {
              if (requestType === "INITIAL") {
                setSrStateConfig(authStatusMappings[mappingIndex].INITIAL);
              } else {
                setSrStateConfig(authStatusMappings[mappingIndex].CONTINUATION);
              }
            }
          }
        }
      } catch (err) {
        if (!shouldIgnoreErrorEntry(err as FetchError)) {
          logError("Could not fetch service request state configurations for payer");
        }
      }
    };

    if (!unmounted) {
      getConfig();
    }

    return () => {
      unmounted = true;
    };
  }, [authStatus, getSrStateConfig, healthPlanName, requestType]);

  const isFieldDisabled = (
    fieldName: ServiceRequestFieldName | FacilityBasedAuthSubmissionForm | ContinuationFieldName
  ) => {
    if (!srStateConfig || srStateConfig.length === 0) {
      return false;
    }
    const userRoles = currUser ? parseRolesFromUser(currUser) : [];
    const allFieldsEditable = srStateConfig.find((ef) => ef.field === "ALL");
    if (allFieldsEditable && allFieldsEditable.editable) {
      if (!allFieldsEditable.roles) {
        return false;
      }
      if (
        userRoles.some((role) => {
          return allFieldsEditable.roles?.includes(role);
        })
      ) {
        return false;
      }
    }
    const editableField = srStateConfig.find((ef) => ef.field === fieldName);
    if (!editableField || !editableField.editable) {
      return true;
    }
    if (!editableField.roles) {
      return false;
    }
    return !userRoles.some((role) => {
      return editableField.roles?.includes(role);
    });
  };

  return { srStateConfig, isFieldDisabled };
};

export function useGetNonPalCheckboxConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<NonPalCheckboxConfigurationResponse>(
    useGetNonPalCheckboxConfiguration,
    healthPlanName,
    "nonPalCheckbox payor config"
  );
}

export function useGetFaxIntakeConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<FaxIntakeConfigurationResponse>(
    useGetFaxIntakeConfiguration,
    healthPlanName,
    "faxIntake payor config"
  );
}
export default useGetFacilityBasedRequestConfigurationByPayer;
