import type { AuthStatus, PatientStatus, AuthorizationResponse } from "@coherehealth/core-platform-api";
import type { StayGroup } from "../PatientStayTimeline/PatientStayTimelineItem";
import type { GroupedPatientStayDate } from "@coherehealth/core-platform-api";
import type { TimelineItem } from "../hooks/usePatientStayTimelineItems";
import type { Theme } from "@material-ui/core";

import { isTerminalStatus } from "util/serviceRequest";
import { getSortedServiceRequests } from "util/authorization";

export type StatusConfig = {
  patientStatus?: PatientStatus;
  authStatus?: AuthStatus;
  serviceRequestStatus?: AuthStatus;
};

/**
 * Main function to generate timeline groups based on authorization and stay dates
 * @param statusConfig - The status configuration containing patient and auth status
 * @param groupedStayDatesForTimeline - Array of grouped stay dates for timeline display
 * @returns Array of StayGroup objects representing timeline segments
 */
export const getTimelineGroups = (
  statusConfig: StatusConfig,
  groupedStayDatesForTimeline?: GroupedPatientStayDate[] | null
): StayGroup[] => {
  const { patientStatus, serviceRequestStatus = "PENDING" } = statusConfig;

  const hasStayDates = groupedStayDatesForTimeline && groupedStayDatesForTimeline?.length > 0;

  // Case 1: Not yet admitted patients without stay dates
  if (patientStatus === "NOT_YET_ADMITTED" && !hasStayDates) {
    return createNotYetAdmittedGroup(serviceRequestStatus);
  }

  // Case 2: Patients with stay dates
  if (hasStayDates) {
    return formatTimelineGroups(groupedStayDatesForTimeline, serviceRequestStatus);
  }

  return [];
};

/**
 * Gets the valid service request status for the timeline
 * @param authorization - The authorization response
 * @param groupedStayDatesForTimeline - The grouped stay dates for timeline
 * @returns The valid service request status
 */
export const getValidServiceRequestStatus = (
  authorization?: AuthorizationResponse,
  groupedStayDatesForTimeline?: GroupedPatientStayDate[] | null
) => {
  const sortedServiceRequests = getSortedServiceRequests(authorization);
  const timelineStatuses = ["WITHDRAWN", "VOIDED", "DISMISSED", "DRAFT"] as AuthStatus[];
  const firstValidServiceRequest = sortedServiceRequests?.find(
    (sr) => !timelineStatuses.includes(sr.authStatus as AuthStatus)
  );
  const hasTimelineStatus = Boolean(firstValidServiceRequest);

  const hasApprovedOrDeniedDays = groupedStayDatesForTimeline?.some(
    (stay) => stay.record?.status === "APPROVED" || stay.record?.status === "DENIED"
  );

  return {
    status: !hasApprovedOrDeniedDays ? firstValidServiceRequest?.authStatus : ("PENDING" as AuthStatus),
    hasValidStatus: hasTimelineStatus,
  };
};

/**
 * Creates a timeline group for patients not yet admitted
 * @param authStatus - The authorization status
 * @returns Array of StayGroup for not yet admitted patients
 */
const createNotYetAdmittedGroup = (authStatus: AuthStatus): StayGroup[] => {
  const isPending = ["PENDING", "PENDING REVIEW", "DRAFT"].includes(authStatus);

  const mainText = isPending
    ? "Admission review pending"
    : `Admit ${authStatus?.toLowerCase().replace(/_/g, " ") || ""}, additional stay day review pending`;

  return [
    {
      record: {
        status: authStatus,
        days: mainText,
        dates: undefined,
        hideStatus: true,
      },
    },
  ];
};

/**
 * Determines the admission status based on the grouped stay dates
 * @param groupedStayDates - Array of stay dates
 * @returns Admission status (APPROVED, DENIED, or PENDING)
 */
const determineAdmitStatus = (groupedStayDates: StayGroup[], serviceRequestStatus: AuthStatus): AuthStatus => {
  const isApproved = groupedStayDates.some((stay) => stay.record.status?.toUpperCase() === "APPROVED");
  const isDenied = groupedStayDates.every((stay) => stay.record.status?.toUpperCase() === "DENIED");

  if (isApproved || isDenied) {
    return isApproved ? "APPROVED" : "DENIED";
  }

  switch (serviceRequestStatus) {
    case "APPROVED":
      return "APPROVED";
    case "DENIED":
      return "DENIED";
    case "PARTIALLY_APPROVED":
      return "PARTIALLY_APPROVED";
    default:
      return "PENDING";
  }
};

/**
 * Formats timeline groups with admission stay information
 * @param authStatus - The authorization status
 * @param groupedStayDates - Array of stay dates
 * @returns Formatted array of StayGroup
 */
const formatTimelineGroups = (
  groupedStayDates: GroupedPatientStayDate[],
  serviceRequestStatus: AuthStatus
): StayGroup[] => {
  const groups: StayGroup[] = [...groupedStayDates].flatMap((stay) => (stay.record ? [{ record: stay.record }] : []));

  if (groups.length > 0) {
    const admitStatus = determineAdmitStatus(groups, serviceRequestStatus);
    groups.unshift(formatAdmitStay(admitStatus, groups[0]));
  }

  return groups;
};

/**
 * Formats a single admission stay
 * @param admitStatus - The determined admit status based on all stays
 * @param stay - The stay information
 * @returns Formatted StayGroup
 */
const formatAdmitStay = (admitStatus: AuthStatus, stay: StayGroup): StayGroup => {
  const admitStatusText = admitStatus?.toLowerCase() || "";
  const mainText = `Admission ${admitStatusText.replace(/_/g, " ")}`;

  return {
    record: {
      status: admitStatusText.toUpperCase(),
      days: mainText,
      dates: stay.record.dates ? stay.record.dates.split(" - ")[0] : undefined,
      hideStatus: true,
    },
  };
};

/**
 * Interface defining the style properties for timeline dots and lines
 */
type StatusColorMap = Record<string, { main: string; light: string; dark: string }>;

/**
 * Union type defining the different types of dots that can appear in the timeline
 * and their required properties
 */
type DotType =
  | { type: "start"; status: AuthStatus }
  | { type: "middle"; status: AuthStatus }
  | { type: "end"; status: AuthStatus; patientStatus: PatientStatus | undefined; authStatus: AuthStatus | undefined };

/**
 * Generates the style configuration for timeline dots based on their type and status
 * @param dot - The dot configuration object containing type and status information
 * @param statusColorMap - Map of status codes to their corresponding colors
 * @returns Style object for the dot
 */
export const getDotStyle = (dot: DotType, statusColorMap: StatusColorMap) => {
  const pendingStatus = ["PENDING", "PENDING REVIEW", "DRAFT"].includes(dot.status);
  const defaultDot = {
    borderColor: statusColorMap["PENDING"]?.dark,
    backgroundColor: "white",
  };

  switch (dot.type) {
    case "start":
      if (!pendingStatus) {
        return {
          ...defaultDot,
          borderColor: statusColorMap[dot.status.toUpperCase()]?.dark,
          backgroundColor: statusColorMap[dot.status.toUpperCase()]?.dark,
        };
      }
      return defaultDot;

    case "middle":
      if (!pendingStatus) {
        return {
          ...defaultDot,
          borderColor: statusColorMap[dot.status.toUpperCase()]?.dark,
          backgroundColor: statusColorMap[dot.status.toUpperCase()]?.dark,
        };
      }
      return defaultDot;

    case "end":
      if (dot.patientStatus === "DISCHARGED" && isTerminalStatus({ authStatus: dot.authStatus })) {
        return {
          ...defaultDot,
          borderColor: statusColorMap[dot.status.toUpperCase()]?.dark,
          backgroundColor: statusColorMap[dot.status.toUpperCase()]?.dark,
        };
      }
      return defaultDot;
  }
};

/**
 * Generates the style configuration for timeline line segments
 * @param status - The current status of the line segment
 * @param patientStatus - The overall patient status
 * @param statusColorMap - Map of status codes to their corresponding colors
 * @returns Style object for the line segment
 */
export const getLineStyle = (status: AuthStatus, statusColorMap: StatusColorMap) => {
  return {
    backgroundColor: statusColorMap[status.toUpperCase()]?.dark || statusColorMap.DEFAULT.dark,
  };
};

/**
 * Formats a date range string into a localized date range display
 * @param dateRange - A string containing two dates separated by " - " (e.g. "2023-01-01 - 2023-01-31")
 * @returns A formatted string with localized dates in the following cases:
 * - Empty string if input is empty or start date is missing
 * - Single date (e.g. "1/1/2023") if:
 *   - Only start date is provided
 *   - Start and end dates are the same
 * - Date range (e.g. "1/1/2023 - 1/31/2023") if:
 *   - Both start and end dates are provided and different
 */
export const formatDateRange = (dateRange: string | undefined) => {
  if (!dateRange) {
    return dateRange;
  }

  const [start, end] = dateRange.split(" - ").map((date) => (date ? date : null));
  if (!start) {
    return "";
  }

  return end && end !== start ? `${start} - ${end}` : start;
};

/**
 * Creates a timeline dot item
 */
const createDotItem = (
  dotType: "start" | "middle" | "end",
  status: AuthStatus,
  statusColorMap: StatusColorMap,
  statusConfig: StatusConfig
): TimelineItem => ({
  type: "dot",
  style: getDotStyle(
    {
      type: dotType,
      status,
      patientStatus: statusConfig.patientStatus,
      authStatus: statusConfig.serviceRequestStatus ?? statusConfig.authStatus,
    },
    statusColorMap
  ),
});

/**
 * Creates a timeline line item
 */
const createLineItem = (
  group: StayGroup["record"],
  numGroups: number,
  statusColorMap: StatusColorMap,
  options?: {
    isLast?: boolean;
    hasEndDot?: boolean;
    dischargedText?: string;
  }
): TimelineItem => ({
  type: "line",
  style: {
    ...getLineStyle((group.status?.toUpperCase() as AuthStatus) || "", statusColorMap),
    ...(options?.isLast && {
      width: `calc(100% - ${options.hasEndDot ? `${192 * (numGroups - 1)}px` : "4px"}`,
      overflow: "hidden",
    }),
  },
  mainText: `${group.days} ${group.hideStatus ? "" : group.status?.toLowerCase().replace(/_/g, " ") || ""}`,
  subText: formatDateRange(group.dates),
  locText:
    group.approvedLoc && group.approvedLoc !== "--"
      ? `LOC: ${group.approvedLoc}`
      : group.requestedLoc && group.requestedLoc !== "--"
      ? `LOC: ${group.requestedLoc}`
      : undefined,
  ...(options?.dischargedText && { dischargedText: options.dischargedText }),
});

/**
 * Gets discharge text based on patient status and auth status
 */
const getDischargeText = (statusConfig: StatusConfig): string | undefined => {
  if (statusConfig.patientStatus !== "DISCHARGED") {
    return undefined;
  }

  return isTerminalStatus({ authStatus: statusConfig.authStatus }) ? "Discharged" : "Pending discharge review";
};

/**
 * Builds the array of timeline items with appropriate styling and content
 */
export const buildTimelineItems = (
  timelineGroups: StayGroup[],
  statusConfig: StatusConfig,
  statusColorMap: StatusColorMap
): TimelineItem[] => {
  const timelineItems: TimelineItem[] = [];
  const hasEndDot = statusConfig.patientStatus === "DISCHARGED";

  timelineGroups.forEach((group, index) => {
    const isFirst = index === 0;
    const isLast = index === timelineGroups.length - 1;

    // Add dot
    timelineItems.push(
      createDotItem(
        isFirst ? "start" : "middle",
        (group.record.status?.toUpperCase() as AuthStatus) || "",
        statusColorMap,
        statusConfig
      )
    );

    // Add line
    timelineItems.push(
      createLineItem(group.record, timelineGroups.length, statusColorMap, {
        isLast,
        hasEndDot,
        dischargedText: isLast ? getDischargeText(statusConfig) : undefined,
      })
    );
  });

  // Add end dot if discharged
  if (hasEndDot) {
    timelineItems.push(
      createDotItem("end", (timelineGroups.at(-1)?.record.status as AuthStatus) || "", statusColorMap, statusConfig)
    );
  }

  return timelineItems;
};

/**
 * Returns a mapping of status types to their corresponding theme colors
 * @param theme - Material UI theme object containing color palette definitions
 * @returns Record mapping status strings to color objects with main, light and dark variants
 */
export const getStatusColorMap = (theme: Theme) => {
  const warningColors = {
    main: theme.palette.warning.main,
    light: theme.palette.warning.light,
    dark: theme.palette.warning.dark,
  };

  const successColors = {
    main: theme.palette.success.main,
    light: theme.palette.success.light,
    dark: theme.palette.success.dark,
  };

  const errorColors = {
    main: theme.palette.error.main,
    light: theme.palette.error.light,
    dark: theme.palette.error.dark,
  };

  return {
    APPROVED: successColors,
    "PARTIALLY APPROVED": errorColors,
    DENIED: errorColors,
    PENDING: warningColors,
    "PENDING REVIEW": warningColors,
    "PENDING CONCURRENT REVIEW": warningColors,
    DEFAULT: warningColors,
  } as Record<string, { main: string; light: string; dark: string }>;
};

/**
 * Gets the theme colors for a given status
 * @param status - The status string to get colors for
 * @param theme - Material UI theme object containing color palette definitions
 * @returns Object containing main, light and dark color variants for the status
 */
export const getStatusColors = (status: AuthStatus, theme: Theme) => {
  const statusColorMap = getStatusColorMap(theme);
  return statusColorMap[status?.toUpperCase()] || statusColorMap.DEFAULT;
};
