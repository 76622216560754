import React, { useCallback, useEffect, useState, SetStateAction, Dispatch, useContext } from "react";

import {
  PeerToPeerReview,
  useSubmitPeerToPeerReview,
  useUpdatePeerToPeerReview,
  useUserActivityBatchUpdate,
  useDiscardP2PReview,
  useUpdateServiceRequest,
  ReviewType,
  ServiceRequestResponse,
  ServiceRequestUpdatePayload,
  AuthStatus,
  PeerToPeerReviewSubmitPayload,
  PatientStayDate,
  useDeleteServiceRequest,
} from "@coherehealth/core-platform-api";
import { generatePath, useNavigate, useLocation } from "react-router-dom";
import {
  Alert,
  colorsLight,
  InformativeModal,
  useFeature,
  createBatchActivities,
  getEmptyUserClickTracking,
  queueMgmtBaseUrl,
} from "@coherehealth/common";
import { useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import GuidelineReview from "../GuidelineReview/GuidelineReview";
import MDReviewReadonly from "./MDReviewReadonly";
import MDReviewEdit from "./MDReviewEdit";
import ReviewHeader from "../ReviewHeader/ReviewHeader";
import { useTrackUserInteraction } from "util/userActivityTracker";
import { guidelineSnapshotFromReview } from "../index";
import { UNLISTED_GUIDELINE_ID } from "./GuidelinesUsedSection";
import routes from "routes";
import { useP2PReview, P2PReviewUpdate } from "components/ClinicalReview/reviewUtils/useReviews";
import { CREATE_MD_REVIEW_PARAM } from "util/queryParams";
import { useCompleteServiceCase, useCancelP2PCaseAndGenerateMDCase, ServiceCase } from "@coherehealth/qm-api";
import config from "api/config";
import {
  ReviewCommonProps,
  ReviewReadOnlyCommonProps,
  useContinueServiceRequestTillNextReviewDate,
  useGetUser,
} from "components/ClinicalReview/reviewUtils/utils";
import { ReviewDetailsCollapse } from "./MDReview";
import { last } from "lodash";
import DetailedHardRedirectClinicalReviewModal, {
  ClinicalReviewErrorDetails,
} from "../Modals/DetailedHardRedirectClinicalReviewModal";
import { defaultClinicalReviewErrorState, extractErrorDetails } from "../util/ReviewSectionUtil";
import { useProcedureCodesState } from "../util/useProcedureCodesState";
import usePersistAttachmentHighlights from "components/ClinicalReview/Review/hooks/usePersistAttachmentHighlights";
import { OutreachAttemptProps } from "../useOutreachAttempt";
import { format } from "date-fns";
import { useGetPostDecisionConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { redirectToPatientSummaryFromReview, routeToPatientSummaryFromReview } from "util/routeUtils/routeUtils";
import { MdModal } from "./MDReviewModal/MdModal";
import { error as logError, stringifyError } from "logger";
import { ClinicalReviewContext } from "components/ClinicalReview/reviewUtils/clinicalReviewPageUtils";
import { Box } from "@material-ui/core";
import useDraftOutreachAttempt from "../useDraftOutreachAttempt";
import useReadmission from "../common/hooks/useReadmission";
import { DisplayReviewOutcome } from "./MDReviewModal/mdReviewModalTypes";

/**
 * Please put common props to reviews inside ReviewCommon Props
 */
interface PeerToPeerReviewComponentProps extends ReviewCommonProps, Partial<OutreachAttemptProps> {
  serviceRequest: ServiceRequestResponse;
  serviceCase?: ServiceCase;
  p2pReview: PeerToPeerReview;
  existingReviews?: ReviewType[] | null;
  willAutoApprove?: boolean;
  newDenialsWorkflow?: boolean;
  isUsingClinicalConversation?: boolean;
  hasOutreachAttempt?: boolean;
  setHasOutreachAttempt?: React.Dispatch<React.SetStateAction<boolean>>;
  nextReviewDate?: string;
  sendOptionalDecisionLetter?: boolean;
  setSendOptionalDecisionLetter?: Dispatch<SetStateAction<boolean>>;
  saveReviewChatbotFindings?: () => void;
}

interface PeerToPeerReviewEditableProps extends Omit<PeerToPeerReviewComponentProps, "p2pReview" | "setP2PReview"> {
  peerToPeerReviewId: string;
}

interface PeerToPeerReviewEditorProps extends Omit<PeerToPeerReviewComponentProps, "p2pReview" | "setP2PReview"> {
  peerToPeerReview: PeerToPeerReview;
  setPeerToPeerReview: P2PReviewUpdate;
  existingReviews?: ReviewType[] | null;
}

/**
 * Please put common read only props to reviews inside ReviewReadOnlyCommonProps
 */
interface PeerToPeerReviewReadonlyProps extends ReviewReadOnlyCommonProps {
  serviceRequest: ServiceRequestResponse;
  peerToPeerReview: PeerToPeerReview;
  clinicalReviewView?: boolean;
  isUsingClinicalConversation?: boolean;
}
/**
 * The Peer-to-Peer review shares the *ReadOnly, *Edit, and *SubmissionModal components with the MD
 * Review, since their data-models are nearly identical. If the data model or view specs change, then
 * we should create new PeerToPeer{Edit, Readonly, SubmissionModal} components.
 * @constructor
 */

const PeerToPeerReviewComponent = ({
  p2pReview,
  isUsingClinicalConversation,
  ...otherProps
}: PeerToPeerReviewComponentProps) => {
  if (p2pReview?.reviewStatus === "DRAFT") {
    return <PeerToPeerReviewEditable {...otherProps} peerToPeerReviewId={p2pReview.id} />;
  } else if (p2pReview?.reviewStatus === "COMPLETE" || p2pReview?.reviewStatus === "DISCARDED") {
    return (
      <PeerToPeerReviewReadonly
        peerToPeerReview={p2pReview}
        isUsingClinicalConversation={isUsingClinicalConversation}
        {...otherProps}
      />
    );
  } else {
    return null;
  }
};

const PeerToPeerReviewEditable = ({ peerToPeerReviewId, ...otherProps }: PeerToPeerReviewEditableProps) => {
  const [p2preview, setp2pReview] = useP2PReview({ p2pReviewId: peerToPeerReviewId });
  if (!p2preview || !setp2pReview) {
    return null;
  }
  return <PeerToPeerReviewEditor {...otherProps} peerToPeerReview={p2preview} setPeerToPeerReview={setp2pReview} />;
};

const PeerToPeerReviewEditor = ({
  serviceRequest,
  serviceCase,
  peerToPeerReview,
  setPeerToPeerReview,
  existingReviews,
  expanded,
  toggleExpanded,
  onFinishEditing,
  willAutoApprove,
  newDenialsWorkflow,
  clinicalReviewView,
  userClickInfoTracking,
  setUserClickInfoTracking,
  searchInfos,
  setSearchInfos,
  reviewRef,
  copiedAttachmentText,
  pastedAttachmentTexts,
  setPastedAttachmentTexts,
  submitHighlights,
  submittingHighlights,
  getHighlightPayload,
  attachmentGuidelineTextHighlightsSnapshot,
  patientStayDateRanges,
  setPatientStayDateRanges,
  patientStaysErrorStates,
  isStayDatesValid,
  isDischargeDetailsValid,
  isDischarged,
  setIsDischarged,
  dischargeDetails,
  setDischargeDetails,
  buildAuthorizationDischargeDetails,
  facilityBasedFeatureEnabled,
  isUsingClinicalConversation,
  outreachAttempt,
  setOutreachAttempt,
  saveOutreachAttempt,
  outreachAttemptLoading,
  outreachFormConfig,
  outreachFormErrors,
  setOutreachFormErrors,
  validateOutreachForm,
  hasOutreachAttempt,
  setHasOutreachAttempt,
  authorization,
  nextReviewDate,
  checkEmptyRequestedLoc,
  continuationConfiguration,
  sendOptionalDecisionLetter,
  setSendOptionalDecisionLetter,
  saveReviewChatbotFindings,
}: PeerToPeerReviewEditorProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useGetUser();
  const userId = user?.sub;
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const caseId = search.get("caseId") || serviceCase?.id;
  const isInpatient = (authorization?.encounterType ?? serviceRequest?.encounterType) === "INPATIENT";
  const hasPendingServiceRequests = authorization?.serviceRequestsOnAuth?.some(
    (sr) => sr.authStatus === "PENDING" && sr.id !== serviceRequest.id
  );
  const draftServiceRequest = authorization?.serviceRequestsOnAuth?.find(
    (sr) => sr.authStatus === "DRAFT" && sr.id !== serviceRequest.id
  );

  const { mutate: completeServiceCase, loading: completingServiceCase } = useCompleteServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () => {
      window.location.assign(
        `${queueMgmtBaseUrl()}/case_complete/${caseId}?patientId=${
          serviceRequest?.patient?.id
        }&reviewServiceRequestId=${serviceRequest.id}`
      );
    },
  });

  const showDenialsInTheSameWindow = useFeature("showDenialsInTheSameWindow");
  const prePopulateDenialsTemplate = useFeature("prePopulateDenialsTemplate");

  const [errorDetails, setErrorDetails] = useState<ClinicalReviewErrorDetails>(
    defaultClinicalReviewErrorState(serviceRequest.id, peerToPeerReview.id)
  );
  const [openHardRedirectOnSubmitModal, setOpenHardRedirectOnSubmitModal] = useState<boolean>(false);
  const [duplicateReviewModalOpen, setDuplicateReviewModalOpen] = useState<boolean>(false);
  const [reviewSubmissionLoading, setReviewSubmissionLoading] = useState<boolean>(false);
  const { isReadmission, setIsReadmission, anchorAuthNumber, setAnchorAuthNumber } = useReadmission({
    serviceRequest,
    authorization,
  });
  const [hasReadmissionChanged, setHasReadmissionChanged] = useState<boolean>(false);
  const [hasErrorWithAnchorAuth, setHasErrorWithAnchorAuth] = useState<boolean>(false);
  const [displayCaseOutcome, setDisplayCaseOutcome] = useState<DisplayReviewOutcome>(peerToPeerReview.reviewOutcome);

  const isReadmissionFeatureEnabled = useFeature("readmissionIdentification");
  const multiCoverageReviews = useFeature("multiCoverageReviews");
  const { isMultiCoverageReviewRequired } = useContext(ClinicalReviewContext);

  const multiCoverageContent = multiCoverageReviews && isMultiCoverageReviewRequired;

  // For partial approval
  const {
    approvedProcedureCodes,
    pxCodesByClinicalService,
    approvedUnitsByClinicalService,
    updateApprovedUnitsByCS,
    updateSinglePxCodeUnit,
    approvedUnits,
    approvedPxCodesAreInvalid,
  } = useProcedureCodesState({ serviceRequest, existingReviews, currentReviewId: peerToPeerReview.id });

  const partialApprovedSemanticProcedureCodes = multiCoverageContent
    ? peerToPeerReview.approvedSemanticProcedureCodes
    : approvedProcedureCodes;

  const partialApprovedUnits = multiCoverageContent ? peerToPeerReview.approvedUnits : approvedUnits;

  const trackUserActivityInteraction = useTrackUserInteraction();

  const onFinishEditingInternal = useCallback(() => {
    toggleExpanded?.();
    onFinishEditing?.();
  }, [toggleExpanded, onFinishEditing]);

  const { mutate: postBatchAcivities } = useUserActivityBatchUpdate({});
  const { submitDraftHighlights, submittingDraftHighlights } = usePersistAttachmentHighlights();

  const { data: postDecisionConfig } = useGetPostDecisionConfigurationByPayer(serviceRequest.healthPlanName ?? "");

  const {
    mutate: updateServiceRequest,
    error: updateServiceRequestError,
    loading: updateServiceRequestLoading,
  } = useUpdateServiceRequest({ id: serviceRequest.id });

  const {
    mutate: cancelP2PCaseAndGenerateMDCase,
    error: cancelP2PCaseAndGenerateMDCaseError,
    loading: cancelP2PCaseAndGenerateMDCaseLoading,
  } = useCancelP2PCaseAndGenerateMDCase({
    base: config.QM_SERVICE_API_URL,
    id: caseId || "",
  });

  const {
    mutate: deleteServiceRequest,
    loading: deleteServiceRequestLoading,
    error: deleteServiceRequestError,
  } = useDeleteServiceRequest({});

  if (updateServiceRequestError) {
    const errorMsg = `Error updating service request: ${updateServiceRequestError.message}`;
    enqueueSnackbar(errorMsg, {
      variant: "error",
      preventDuplicate: true,
    });
    logError(`${errorMsg} ${stringifyError(updateServiceRequestError.data)}`);
  }
  if (cancelP2PCaseAndGenerateMDCaseError) {
    const errorMsg = `Error cancelling P2P case & creation of MD case: ${cancelP2PCaseAndGenerateMDCaseError.message}`;
    enqueueSnackbar(errorMsg, {
      variant: "error",
      preventDuplicate: true,
    });
    logError(`${errorMsg} ${stringifyError(cancelP2PCaseAndGenerateMDCaseError.data)}`);
  }
  if (deleteServiceRequestError) {
    const errorMsg = `Error deleting existing draft continuation: ${deleteServiceRequestError.message}`;
    enqueueSnackbar(errorMsg, {
      variant: "error",
      preventDuplicate: true,
    });
    logError(`${errorMsg} ${stringifyError(deleteServiceRequestError.data)}`);
  }

  // Hooks & callbacks to save a draft
  const {
    mutate: update,
    error: updateError,
    loading: updateLoading,
  } = useUpdatePeerToPeerReview({
    reviewId: peerToPeerReview.id,
    serviceRequestId: serviceRequest.id,
  });
  if (updateError) {
    if (updateError.status === 409) {
      setDuplicateReviewModalOpen(true);
    } else {
      const errorMsg = `Error updating md review: ${updateError.message}`;
      enqueueSnackbar(errorMsg, { variant: "error", preventDuplicate: true });
      logError(`${errorMsg} ${stringifyError(updateError.data)}`);
    }
  }
  const formatP2PReview = useCallback(() => {
    const usedGuidelineIds = peerToPeerReview.usedGuidelineIds || [""];
    const usedGuidelineIdSet = new Set(usedGuidelineIds);
    const wasUnlistedGuidelineUsed = usedGuidelineIdSet.has(UNLISTED_GUIDELINE_ID);
    usedGuidelineIdSet.delete("");
    usedGuidelineIdSet.delete(UNLISTED_GUIDELINE_ID);
    return {
      ...peerToPeerReview,
      reviewOutcome: peerToPeerReview.reviewOutcome,
      approvedUnits: partialApprovedUnits,
      approvedSemanticProcedureCodes: partialApprovedSemanticProcedureCodes,
      usedGuidelineIds: Array.from(usedGuidelineIdSet),
      wasUnlistedGuidelineUsed,
    };
  }, [peerToPeerReview, partialApprovedUnits, partialApprovedSemanticProcedureCodes]);
  const savePeerToPeerReviewDraft = useCallback(async () => {
    try {
      const res = await update(formatP2PReview());
      if (submitDraftHighlights) {
        await submitDraftHighlights();
      }
      userClickInfoTracking &&
        (await postBatchAcivities(
          createBatchActivities(
            "PTP_REVIEW",
            "REVIEW_SUBMISSION",
            "INTERACTION",
            userClickInfoTracking,
            searchInfos,
            pastedAttachmentTexts,
            userId,
            res.id,
            serviceRequest.cohereId,
            serviceRequest.id
          )
        ));
      setUserClickInfoTracking?.(getEmptyUserClickTracking());
      setSearchInfos?.([]);
      onFinishEditingInternal();
      setPeerToPeerReview(res);
      if (showDenialsInTheSameWindow) {
        navigate(
          generatePath(routes.DENIAL_REVIEW, {
            serviceRequestId: serviceRequest?.id,
          })
        );
      } else {
        window.open(
          generatePath(routes.DENIAL_REVIEW, {
            serviceRequestId: serviceRequest?.id,
          }),
          "_blank",
          "noopener"
        );
      }
    } catch {
      setIsSubmissionModalOpen(false);
      setDuplicateReviewModalOpen(true);
    }
  }, [
    update,
    formatP2PReview,
    userClickInfoTracking,
    postBatchAcivities,
    searchInfos,
    userId,
    serviceRequest.cohereId,
    serviceRequest?.id,
    setUserClickInfoTracking,
    setSearchInfos,
    submitDraftHighlights,
    onFinishEditingInternal,
    setPeerToPeerReview,
    showDenialsInTheSameWindow,
    navigate,
    pastedAttachmentTexts,
  ]);

  const { appendDraftOutreachAttemptToReview } = useDraftOutreachAttempt({
    hasOutreachAttempt,
    outreachAttempt,
  });

  const saveAndExitPeerToPeerReviewDraft = useCallback(async () => {
    try {
      const payload = appendDraftOutreachAttemptToReview(formatP2PReview());

      const res = await update(payload);
      if (submitDraftHighlights) {
        await submitDraftHighlights();
      }
      userClickInfoTracking &&
        (await postBatchAcivities(
          createBatchActivities(
            "PTP_REVIEW",
            "REVIEW_SUBMISSION",
            "INTERACTION",
            userClickInfoTracking,
            searchInfos,
            pastedAttachmentTexts,
            userId,
            res.id,
            serviceRequest.cohereId,
            serviceRequest.id
          )
        ));
      setUserClickInfoTracking?.(getEmptyUserClickTracking());
      setSearchInfos?.([]);
      onFinishEditingInternal();
      setPeerToPeerReview(res);
      redirectToPatientSummaryFromReview({
        serviceRequest,
        caseId,
        reviewId: peerToPeerReview.id,
        navigate,
      });
    } catch {
      setDuplicateReviewModalOpen(true);
    }
  }, [
    update,
    formatP2PReview,
    submitDraftHighlights,
    userClickInfoTracking,
    postBatchAcivities,
    searchInfos,
    pastedAttachmentTexts,
    userId,
    serviceRequest,
    setUserClickInfoTracking,
    setSearchInfos,
    onFinishEditingInternal,
    setPeerToPeerReview,
    peerToPeerReview.id,
    caseId,
    navigate,
    appendDraftOutreachAttemptToReview,
  ]);

  //Hooks & callbacks to submit a review
  const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false);
  const {
    mutate: submit,
    error: submitError,
    loading: submitLoading,
  } = useSubmitPeerToPeerReview({
    reviewId: peerToPeerReview.id,
  });
  useEffect(() => {
    if (submitError) {
      setIsSubmissionModalOpen(false);
      if (submitError.status === 409) {
        setDuplicateReviewModalOpen(true);
      } else {
        setErrorDetails(extractErrorDetails(submitError, serviceRequest.id, peerToPeerReview.id));
        setOpenHardRedirectOnSubmitModal(true);
      }
    }
  }, [submitError, serviceRequest.id, peerToPeerReview.id, setOpenHardRedirectOnSubmitModal, setIsSubmissionModalOpen]);

  const { continueServiceRequestTillNextReviewDate, loadingContinuation } = useContinueServiceRequestTillNextReviewDate(
    serviceRequest?.authorization?.id || "",
    facilityBasedFeatureEnabled
  );

  const submitPeerToPeerReview = useCallback(
    async (
      event: React.FormEvent<HTMLFormElement>,
      serviceRequestUpdates?: Partial<ServiceRequestUpdatePayload>,
      isPostDenialP2P?: boolean
    ) => {
      event.preventDefault();
      setReviewSubmissionLoading(true);
      if (peerToPeerReview.reviewOutcome) {
        if (submitHighlights) {
          await submitHighlights();
        }
        let payload: PeerToPeerReviewSubmitPayload = {
          ...formatP2PReview(),
          reviewOutcome: peerToPeerReview.reviewOutcome,
          denialStatus: "NOT_STARTED",
          guidelines: peerToPeerReview.guidelines || [],
          //keeping the acknowledgement true right now to autoCreateContinuation from consumer in future based on
          //payer we might need acknowledgement to create continaution as a result of final decision of service request
          reviewerCreateContinuationAcknowledgement: true,
        };

        if (isReadmissionFeatureEnabled) {
          payload = {
            ...payload,
            isReadmission: isReadmission,
            anchorAuthNumber: anchorAuthNumber,
          };
        }
        if (sendOptionalDecisionLetter && serviceRequest?.isMemberRequestedAuth) {
          payload = {
            ...payload,
            additionalRecipients: ["PROVIDER"],
          };
        }

        if (facilityBasedFeatureEnabled) {
          const dischargeDetails = buildAuthorizationDischargeDetails?.() || {};
          payload = {
            ...payload,
            nextReviewDate,
            actualDischargeDateTime: dischargeDetails?.actualDischargeDateTime,
            dischargedTo: dischargeDetails?.dischargedTo?.enumName,
            patientStatus: isDischarged ? "DISCHARGED" : serviceRequest.patientStatus,
            patientStayDates: serviceRequestUpdates?.patientStayDates,
          };
        }
        if (peerToPeerReview.readmissionOutcome === "OVERTURN" || (!isReadmission && hasReadmissionChanged)) {
          payload = {
            ...payload,
            claimsPaymentNote: "Correction: following P2P, the admissions are NOT RELATED",
          };
        }
        const res = await submit(payload);
        userClickInfoTracking &&
          (await postBatchAcivities(
            createBatchActivities(
              "PTP_REVIEW",
              "REVIEW_SUBMISSION",
              "INTERACTION",
              userClickInfoTracking,
              searchInfos,
              pastedAttachmentTexts,
              userId,
              res.id,
              serviceRequest.cohereId,
              serviceRequest.id
            )
          ));
        setUserClickInfoTracking?.(getEmptyUserClickTracking());
        setSearchInfos?.([]);
        onFinishEditingInternal();
        const postedOutreachAttempt = peerToPeerReview.nudgeAttempted && (await saveOutreachAttempt?.());
        const snap = res.guidelines ? guidelineSnapshotFromReview(res) : undefined;
        await trackUserActivityInteraction({
          event: "PTP_REVIEW",
          stage: "REVIEW_SUBMISSION",
          activityContext: { reviewId: res.id, serviceRequestId: serviceRequest.id },
          afterGuidelineSnapshot: snap,
          attachmentGuidelineTextHighlightsSnapshot: {
            ...attachmentGuidelineTextHighlightsSnapshot,
            reviewer: res.createdByName,
            dateCompleted: res.dateCompleted,
            reviewOutcome: res.reviewOutcome,
            textHighlights: getHighlightPayload?.() || undefined,
          },
        });

        const lastPatientStayDateRange = last(patientStayDateRanges);
        // Create Pre-Denial P2P reviews create continuations when autoCreateFinalDecisionContinuations is ON
        if (!isPostDenialP2P && !continuationConfiguration?.autoCreateFinalDecisionContinuations) {
          await continueServiceRequestTillNextReviewDate({
            serviceRequestId: serviceRequest.id,
            nextReviewDate,
            isDischarged,
            reviewOutcome: peerToPeerReview.reviewOutcome as AuthStatus,
            lastStayDateRange: lastPatientStayDateRange,
            triggerStatuses: ["APPROVED"],
            isReadmission,
            anchorAuthNumber,
          });
        } else if (!continuationConfiguration?.autoCreatePostDecisionContinuations) {
          // Create Post-Denial P2P reviews continuations when disablePostDenialOverturnContinuations is OFF
          const today = format(new Date(), "yyyy-MM-dd") + "T00:00:00.000Z";
          const overturnContinuationsEnabled = !postDecisionConfig?.disablePostDenialOverturnContinuations;

          // Handle post-denial continuation creation on P2P Overturn
          if (isInpatient && !hasPendingServiceRequests && overturnContinuationsEnabled) {
            const { requestedLevelOfCareCode, approvedLevelOfCareCode, reviewStatus, initialReviewDecision } =
              lastPatientStayDateRange ?? {};

            const patientStayDate: PatientStayDate = {
              date: today,
              reviewStatus: reviewStatus ?? undefined,
              initialReviewDecision: initialReviewDecision ?? undefined,
              requestedLevelOfCareCode: requestedLevelOfCareCode ?? undefined,
              approvedLevelOfCareCode: approvedLevelOfCareCode ?? undefined,
            };
            // In case that a continuation in draft status exists, delete it
            if (draftServiceRequest?.id) {
              await deleteServiceRequest(draftServiceRequest?.id);
            }
            // Create new continuation
            await continueServiceRequestTillNextReviewDate({
              serviceRequestId: serviceRequest.id,
              nextReviewDate: today,
              isDischarged,
              reviewOutcome: peerToPeerReview.reviewOutcome as AuthStatus,
              lastStayDateRange: lastPatientStayDateRange,
              triggerStatuses: ["APPROVED"],
              patientStatus: serviceRequest.patientStatus,
              patientStayDates: [patientStayDate],
              isReadmission,
              anchorAuthNumber,
            });
          }
        }

        if (caseId) {
          await completeServiceCase({
            reviewOutcome: peerToPeerReview.reviewOutcome,
            dateCompleted: new Date().toISOString(),
            caseNotes: peerToPeerReview.authorizationNote,
            reviewId: peerToPeerReview.id,
            ...(postedOutreachAttempt ? { outreachCaseOutcome: { outreachAttemptId: postedOutreachAttempt.id } } : {}),
          }).catch(() => {
            window.location.assign(
              `${queueMgmtBaseUrl()}/case_complete/${caseId}?patientId=${
                serviceRequest?.patient?.id
              }&reviewServiceRequestId=${serviceRequest.id}`
            );
          });
          setReviewSubmissionLoading(false);
          setIsSubmissionModalOpen(false);
        } else {
          setReviewSubmissionLoading(false);
          setIsSubmissionModalOpen(false);
          redirectToPatientSummaryFromReview({
            serviceRequest,
            reviewId: peerToPeerReview.id,
            navigate,
          });
        }
      } else {
        setReviewSubmissionLoading(false);
        throw new Error("Review outcome must not be empty to submit");
      }
    },
    [
      peerToPeerReview.reviewOutcome,
      peerToPeerReview.guidelines,
      peerToPeerReview.readmissionOutcome,
      peerToPeerReview.nudgeAttempted,
      peerToPeerReview.authorizationNote,
      peerToPeerReview.id,
      submitHighlights,
      formatP2PReview,
      isReadmissionFeatureEnabled,
      facilityBasedFeatureEnabled,
      isReadmission,
      hasReadmissionChanged,
      submit,
      userClickInfoTracking,
      postBatchAcivities,
      searchInfos,
      pastedAttachmentTexts,
      userId,
      serviceRequest,
      setUserClickInfoTracking,
      setSearchInfos,
      onFinishEditingInternal,
      saveOutreachAttempt,
      trackUserActivityInteraction,
      attachmentGuidelineTextHighlightsSnapshot,
      getHighlightPayload,
      patientStayDateRanges,
      continuationConfiguration?.autoCreateFinalDecisionContinuations,
      continuationConfiguration?.autoCreatePostDecisionContinuations,
      caseId,
      anchorAuthNumber,
      buildAuthorizationDischargeDetails,
      nextReviewDate,
      isDischarged,
      continueServiceRequestTillNextReviewDate,
      postDecisionConfig?.disablePostDenialOverturnContinuations,
      isInpatient,
      hasPendingServiceRequests,
      draftServiceRequest?.id,
      deleteServiceRequest,
      completeServiceCase,
      navigate,
      sendOptionalDecisionLetter,
    ]
  );

  // Hooks & callbacks to discard a review
  const {
    mutate: discard,
    error: discardError,
    loading: discarding,
  } = useDiscardP2PReview({
    reviewId: peerToPeerReview.id,
  });
  if (discardError) {
    const errorAny: any = discardError;
    const message = errorAny?.data?.message;
    const embeddedError = errorAny?.data?._embedded?.errors?.map((error: any) => error?.message).join("\n");
    const errorMessage = (
      <Box>
        <Box>Error discarding peer to peer review</Box>
        {embeddedError ? <Box>{embeddedError}</Box> : message && <Box>{message}</Box>}
      </Box>
    );
    enqueueSnackbar(errorMessage, {
      variant: "error",
      preventDuplicate: true,
    });
    logError(`Error discarding peer to peer review ${stringifyError(discardError.data)}`);
  }

  const discardP2PReview = useCallback(async () => {
    const res = await discard({
      ...peerToPeerReview,
    });
    userClickInfoTracking &&
      (await postBatchAcivities(
        createBatchActivities(
          "MD_REVIEW",
          "REVIEW_SUBMISSION",
          "INTERACTION",
          userClickInfoTracking,
          searchInfos,
          pastedAttachmentTexts,
          userId,
          res.id,
          serviceRequest.cohereId,
          serviceRequest.id
        )
      ));
    setUserClickInfoTracking?.(getEmptyUserClickTracking());
    setSearchInfos?.([]);
    onFinishEditingInternal();
    setPeerToPeerReview(res);
    redirectToPatientSummaryFromReview({
      serviceRequest,
      caseId,
      reviewId: peerToPeerReview.id,
      navigate,
    });
  }, [
    discard,
    peerToPeerReview,
    userClickInfoTracking,
    postBatchAcivities,
    searchInfos,
    pastedAttachmentTexts,
    userId,
    serviceRequest,
    setUserClickInfoTracking,
    setSearchInfos,
    onFinishEditingInternal,
    setPeerToPeerReview,
    caseId,
    navigate,
  ]);

  const approveWithoutP2PReview = useCallback(async () => {
    const mdCase = await cancelP2PCaseAndGenerateMDCase({});
    await updateServiceRequest({
      authStatus: "PENDING",
      pendingReason: "MD_REVIEW",
    });
    const res = await discard({
      ...peerToPeerReview,
    });
    userClickInfoTracking &&
      (await postBatchAcivities(
        createBatchActivities(
          "PTP_REVIEW",
          "REVIEW_SUBMISSION",
          "INTERACTION",
          userClickInfoTracking,
          searchInfos,
          pastedAttachmentTexts,
          userId,
          res.id,
          serviceRequest.cohereId,
          serviceRequest.id
        )
      ));
    setUserClickInfoTracking?.(getEmptyUserClickTracking());
    setSearchInfos?.([]);
    onFinishEditingInternal();
    setPeerToPeerReview(res);
    const queryParams = mdCase ? "&caseId=" + mdCase.id + "&approveWithoutP2P" : "";
    window.open(
      generatePath(routes.CLINICAL_REVIEW, { serviceRequestId: serviceRequest?.id }).concat(
        `?${CREATE_MD_REVIEW_PARAM}${queryParams}`
      ),
      "_self",
      "noopener"
    );
  }, [
    cancelP2PCaseAndGenerateMDCase,
    updateServiceRequest,
    discard,
    peerToPeerReview,
    userClickInfoTracking,
    postBatchAcivities,
    searchInfos,
    pastedAttachmentTexts,
    userId,
    serviceRequest.cohereId,
    serviceRequest?.id,
    setUserClickInfoTracking,
    setSearchInfos,
    onFinishEditingInternal,
    setPeerToPeerReview,
  ]);

  const finishReviewLoading =
    reviewSubmissionLoading ||
    submitLoading ||
    !!submittingHighlights ||
    !!submittingDraftHighlights ||
    completingServiceCase ||
    loadingContinuation ||
    !!outreachAttemptLoading ||
    deleteServiceRequestLoading;

  return (
    <div style={{ backgroundColor: theme.palette.background.paper, paddingBottom: 8 }} ref={reviewRef}>
      {willAutoApprove && (
        <Alert style={{ backgroundColor: "#F5F5F5", paddingTop: 28 }} severity="success">
          <div style={{ color: colorsLight.success.main }}>This request met auto-approval criteria</div>
        </Alert>
      )}
      <ReviewHeader
        toggleExpanded={toggleExpanded}
        expanded={expanded}
        reviewDateCreated={peerToPeerReview.dateCreated}
        reviewLastUpdated={peerToPeerReview.lastUpdated}
        reviewStatus={peerToPeerReview.reviewStatus}
        reviewOutcome={peerToPeerReview.reviewOutcome}
        reviewType={peerToPeerReview.reviewType}
        reviewId={peerToPeerReview.id}
        reviewCreatedByName={peerToPeerReview.createdByName}
        reviewDateCompleted={peerToPeerReview.dateCompleted}
        reviewCompletedByName={peerToPeerReview.reviewerDetails?.reviewerName || peerToPeerReview.completedByName}
        saveDraft={saveAndExitPeerToPeerReviewDraft}
        reviewText={peerToPeerReview.determinationNote}
        clinicalReviewView={clinicalReviewView}
        isUsingClinicalConversation={isUsingClinicalConversation}
        coverageLevelDetails={peerToPeerReview.coverageLevelDetails}
      />
      <ReviewDetailsCollapse in={expanded} timeout={100} unmountOnExit style={{ marginLeft: 16 }}>
        <MDReviewEdit
          serviceRequest={serviceRequest}
          review={peerToPeerReview}
          setReview={setPeerToPeerReview}
          openSubmissionModal={() => {
            if (facilityBasedFeatureEnabled) {
              setIsSubmissionModalOpen(!!isStayDatesValid?.());
            } else {
              setIsSubmissionModalOpen(true);
            }
          }}
          discarding={discarding}
          onDiscard={() => {
            discardP2PReview();
          }}
          onApproveWithoutP2P={approveWithoutP2PReview}
          approveWithoutP2PLoading={discarding || updateServiceRequestLoading || cancelP2PCaseAndGenerateMDCaseLoading}
          toggleExpanded={toggleExpanded}
          saveDraft={saveAndExitPeerToPeerReviewDraft}
          currentlySavingDraft={updateLoading}
          newDenialsWorkflow={newDenialsWorkflow}
          prePopulateDenialsTemplate={prePopulateDenialsTemplate}
          copiedAttachmentText={copiedAttachmentText}
          setPastedAttachmentTexts={setPastedAttachmentTexts}
          fromQueueManagment={caseId ? true : false}
          patientStayDateRanges={patientStayDateRanges}
          setPatientStayDateRanges={setPatientStayDateRanges}
          patientStaysErrorStates={patientStaysErrorStates}
          isDischarged={isDischarged}
          setIsDischarged={setIsDischarged}
          dischargeDetails={dischargeDetails}
          setDischargeDetails={setDischargeDetails}
          facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
          outreachAttempt={outreachAttempt}
          setOutreachAttempt={setOutreachAttempt}
          outreachFormConfig={outreachFormConfig}
          outreachFormErrors={outreachFormErrors}
          setOutreachFormErrors={setOutreachFormErrors}
          validateOutreachForm={validateOutreachForm}
          hasOutreachAttempt={hasOutreachAttempt}
          setHasOutreachAttempt={setHasOutreachAttempt}
          saveDraftWithRedirect={saveAndExitPeerToPeerReviewDraft}
          isReadmission={isReadmission}
          setIsReadmission={setIsReadmission}
          anchorAuthNumber={anchorAuthNumber}
          setAnchorAuthNumber={setAnchorAuthNumber}
          hasErrorWithAnchorAuth={hasErrorWithAnchorAuth}
          setHasErrorWithAnchorAuth={setHasErrorWithAnchorAuth}
          isReadmissionFeatureEnabled={isReadmissionFeatureEnabled}
          setHasReadmissionChanged={setHasReadmissionChanged}
          authorization={authorization}
          isStayDatesValid={isStayDatesValid}
          isDischargeDetailsValid={isDischargeDetailsValid || true}
          approvedUnits={approvedUnits}
          updateSinglePxCodeUnit={updateSinglePxCodeUnit}
          pxCodesByClinicalService={pxCodesByClinicalService}
          updateApprovedUnitsByCS={updateApprovedUnitsByCS}
          approvedUnitsByClinicalService={approvedUnitsByClinicalService}
          discardAndExitDisabled={finishReviewLoading || isSubmissionModalOpen}
          saveReviewChatbotFindings={saveReviewChatbotFindings}
        />
        <MdModal
          isReadmission={isReadmission}
          serviceRequest={serviceRequest}
          review={peerToPeerReview}
          newDenialsWorkflow={newDenialsWorkflow}
          setReview={(reviewUpdate) => setPeerToPeerReview(reviewUpdate as Partial<PeerToPeerReview>)}
          pxCodesByClinicalService={pxCodesByClinicalService}
          approvedUnitsByClinicalService={approvedUnitsByClinicalService}
          updateApprovedUnitsByCS={updateApprovedUnitsByCS}
          approvedUnits={approvedUnits}
          updateSinglePxCodeUnit={updateSinglePxCodeUnit}
          loading={finishReviewLoading}
          approvedPxCodesAreInvalid={approvedPxCodesAreInvalid}
          facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
          currentlySavingDraft={updateLoading}
          isDischarged={isDischarged}
          initialPatientStayDateRanges={patientStayDateRanges}
          setIsOpen={setIsSubmissionModalOpen}
          isOpen={isSubmissionModalOpen}
          checkEmptyRequestedLoc={checkEmptyRequestedLoc}
          onSubmit={submitPeerToPeerReview}
          isPostDenialP2P={peerToPeerReview.isPostDenialP2P}
          saveReviewDraft={savePeerToPeerReviewDraft}
          sendOptionalDecisionLetters={sendOptionalDecisionLetter}
          setSendOptionalDecisionLetter={setSendOptionalDecisionLetter}
          displayCaseOutcome={displayCaseOutcome}
          setDisplayCaseOutcome={setDisplayCaseOutcome}
          showDecisionExplanationDropdown={false}
        />
        <DetailedHardRedirectClinicalReviewModal
          isOpen={openHardRedirectOnSubmitModal}
          setIsOpen={setOpenHardRedirectOnSubmitModal}
          errorDetails={errorDetails}
        />
        <InformativeModal
          open={duplicateReviewModalOpen}
          onClose={() => {
            setDuplicateReviewModalOpen(false);
          }}
          headerText={"Review completed by another user"}
          additionalInfoText={"This review was completed by another user. Your review and notes will not be saved."}
          primaryButtonText={"Go to patient summary"}
          primaryButtonRoute={routeToPatientSummaryFromReview({
            serviceRequestId: serviceRequest.id,
            patientId: serviceRequest?.patient?.id,
          })}
        />
      </ReviewDetailsCollapse>
    </div>
  );
};
const PeerToPeerReviewReadonly = React.memo(
  ({
    serviceRequest,
    peerToPeerReview,
    expanded,
    clinicalReviewView,
    reviewRef,
    expandableGuidelines,
    reviewDetailsCollapseStyle,
    isUsingClinicalConversation,
    ...otherProps
  }: PeerToPeerReviewReadonlyProps) => {
    const prePopulateDenialsTemplate = useFeature("prePopulateDenialsTemplate");
    return (
      <div ref={reviewRef}>
        <ReviewHeader
          expanded={expanded}
          reviewDateCreated={peerToPeerReview.dateCreated}
          reviewLastUpdated={peerToPeerReview.lastUpdated}
          reviewStatus={peerToPeerReview.reviewStatus}
          reviewOutcome={peerToPeerReview.reviewOutcome}
          reviewType={peerToPeerReview.reviewType}
          reviewId={peerToPeerReview.id}
          reviewCreatedByName={peerToPeerReview.createdByName}
          reviewDateCompleted={peerToPeerReview.dateCompleted}
          reviewCompletedByName={peerToPeerReview.reviewerDetails?.reviewerName || peerToPeerReview.completedByName}
          clinicalReviewView={clinicalReviewView}
          reviewText={peerToPeerReview.determinationNote}
          isUsingClinicalConversation={isUsingClinicalConversation}
          coverageLevelDetails={peerToPeerReview.coverageLevelDetails}
          {...otherProps}
        />
        <ReviewDetailsCollapse
          in={expanded}
          timeout={100}
          unmountOnExit
          style={reviewDetailsCollapseStyle ? reviewDetailsCollapseStyle : clinicalReviewView ? { marginLeft: 16 } : {}}
        >
          <MDReviewReadonly
            mdReview={peerToPeerReview}
            serviceRequest={serviceRequest}
            prePopulateDenialsTemplate={prePopulateDenialsTemplate}
            hideReviewNotes={otherProps.authorizationReviewView}
          />
          <GuidelineReview
            id="tabpanel-0"
            review={peerToPeerReview}
            noClinicalInformationProvided={peerToPeerReview?.noClinicalInformationProvided}
            serviceRequest={serviceRequest}
            expandable={expandableGuidelines}
            authorizationReviewView={otherProps.authorizationReviewView}
          />
        </ReviewDetailsCollapse>
      </div>
    );
  }
);

export default PeerToPeerReviewComponent;
