import { useCallback, useState } from "react";
import {
  Body2,
  ReviewTimeDateAuthorCaption,
  ReviewCompletionTimeDateCaption,
  Sanitized,
  Body1,
  Caption,
  H6,
  useFeature,
} from "@coherehealth/common";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ButtonBase from "@material-ui/core/ButtonBase";
import {
  BaseReview,
  MdReviewCoverageLevelDetails,
  NurseReviewCoverageLevelDetails,
  PeerToPeerCoverageLevelDetails,
  ReviewType,
} from "@coherehealth/core-platform-api";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useTheme } from "@material-ui/core";
import { CommonReviewOutcome, reviewOutcomeLabel } from "components/ClinicalReview/reviewUtils/utils";
import { getReviewOutcomeTitle } from "util/eventHistoryUtil";
import { reviewHeaderConfig } from "./utils";
import { ReviewerDetailsModal } from "./ReviewerDetailsModal";

interface Props {
  toggleExpanded?: () => void;
  expanded?: boolean;
  reviewStatus: BaseReview["reviewStatus"];
  reviewType: ReviewType["reviewType"];
  reviewDateCreated: string;
  reviewLastUpdated: string;
  reviewId: string;
  reviewCreatedByName?: string;
  reviewCompletedByName?: string;
  reviewDateCompleted?: string;
  reviewOutcome?: string;
  reviewText?: string;
  saveDraft?: () => void;
  clinicalReviewView?: boolean;
  unexpandable?: boolean;
  buttonPadding?: string;
  isUsingClinicalConversation?: boolean;
  coverageLevelDetails?:
    | MdReviewCoverageLevelDetails[]
    | NurseReviewCoverageLevelDetails[]
    | PeerToPeerCoverageLevelDetails[];
  reviewerDetails?: BaseReview["reviewerDetails"];
}

const useStyles = makeStyles((theme) => ({
  button: ({ buttonPadding, unexpandable }: Partial<Props>) => ({
    marginTop: 0,
    marginRight: 1,
    marginLeft: 1,
    display: "flex",
    justifyContent: "space-between",
    textAlign: "start",
    padding: buttonPadding ? buttonPadding : theme.spacing(3, 3, unexpandable ? 0 : 2, 3),
    userSelect: "text",
    cursor: unexpandable ? "default" : "pointer",
  }),
  icon: ({ expanded }: Partial<Props>) => ({
    transition: theme.transitions.create("transform"),
    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
    height: theme.spacing(4),
    width: theme.spacing(4),
    padding: theme.spacing(0.5),
    border: "1px solid rgba(0, 0, 0, 0.10)",
    borderRadius: "100%",
    marginBottom: "auto",
  }),
  newDenialsIcon: ({ expanded }: Partial<Props>) => ({
    transition: theme.transitions.create("transform"),
    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginBottom: "auto",
  }),
  captionContainer: {
    display: "inline-block",
    flexDirection: "row",
    marginTop: theme.spacing(3),
  },
  newDenialsCaptionContainer: {
    display: "inline-block",
    flexDirection: "row",
    marginTop: theme.spacing(1.25),
  },
  clinicalReviewDateContainer: {
    margin: theme.spacing(2, 0, 2, 2),
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  clinicalReviewLabel: {
    margin: theme.spacing(0, 2, 0, 0),
  },
  clinicalReviewCaption: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  created: {},
  completed: {},
  discarded: {},
  createdBullet: {
    color: theme.palette.warning.dark,
    width: theme.spacing(1),
    height: theme.spacing(1),
    marginRight: theme.spacing(1),
    "&$created": {
      color: theme.palette.warning.dark,
    },
    "&$completed": {
      color: theme.palette.success.main,
    },
    "&$discarded": {
      color: theme.palette.error.dark,
    },
  },
  sanitized: {
    "& *": {
      margin: "0px",
    },
    "& br": {
      marginBottom: "19px",
      content: '""',
      display: "block",
    },
  },
}));

export default function ReviewHeader({
  toggleExpanded,
  reviewDateCreated,
  reviewLastUpdated,
  reviewCreatedByName,
  reviewDateCompleted,
  reviewCompletedByName,
  reviewStatus,
  reviewOutcome,
  reviewId,
  reviewType,
  reviewText,
  expanded,
  saveDraft,
  clinicalReviewView,
  unexpandable,
  buttonPadding,
  isUsingClinicalConversation,
  coverageLevelDetails,
  reviewerDetails,
}: Props) {
  const theme = useTheme();
  const classes = useStyles({ expanded, unexpandable, buttonPadding });
  const displayReviewerDetailsModal = useFeature("displayReviewerDetailsModal");
  const title = isUsingClinicalConversation ? "Clinical Conversation" : reviewHeaderConfig[reviewType].title;
  const [modalOpen, setModalOpen] = useState(false);
  const canSaveDraft = reviewType === "PeerToPeerReview";

  const onHeadingClick = useCallback(() => {
    if (unexpandable && displayReviewerDetailsModal) {
      return;
    } else if (canSaveDraft && reviewStatus === "DRAFT" && expanded) {
      saveDraft?.();
    } else {
      toggleExpanded?.();
    }
  }, [unexpandable, displayReviewerDetailsModal, canSaveDraft, reviewStatus, expanded, saveDraft, toggleExpanded]);

  return clinicalReviewView && reviewStatus === "DRAFT" ? (
    <div className={classes.clinicalReviewDateContainer}>
      <H6 className={classes.clinicalReviewLabel}>In-Progress Review</H6>
      <span className={classes.clinicalReviewCaption}>
        <ReviewTimeDateAuthorCaption
          reviewDateCreated={reviewDateCreated || ""}
          reviewLastUpdated={reviewLastUpdated || ""}
          reviewCreatedByName={reviewCreatedByName || ""}
          clinicalReviewView
        />
      </span>
    </div>
  ) : (
    <>
      <ButtonBase
        component="div"
        className={classes.button}
        onClick={onHeadingClick}
        disableRipple={unexpandable ? true : false}
      >
        <Grid container direction="column">
          <Grid item container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item container direction="row" alignItems="center" xs={11}>
              <Body2 style={{ paddingRight: 16 }}>{title}</Body2>

              <ReviewCompletionTimeDateCaption
                reviewDateCompleted={reviewDateCompleted || ""}
                reviewStatus={reviewStatus}
                reviewOutcome={reviewOutcomeLabel((reviewOutcome || "") as CommonReviewOutcome)}
                reviewCompletedByName={reviewerDetails?.reviewerName || reviewCompletedByName}
                reviewCreatedByName={reviewCreatedByName || ""}
                clinicalReviewView={clinicalReviewView || unexpandable}
                multiCoverage={(coverageLevelDetails?.length ?? 0) > 0}
                shouldDecorateText={displayReviewerDetailsModal}
                handleClick={(e) => {
                  e.stopPropagation();
                  setModalOpen(true);
                }}
              />
            </Grid>
            {reviewStatus !== "DRAFT" && !unexpandable && (
              <ExpandMoreIcon
                className={classes.newDenialsIcon}
                data-testid={`review-header-expand-icon-${reviewId}`}
              />
            )}
          </Grid>
          {reviewText && clinicalReviewView && (
            <Grid item>
              <Body1 component="div">
                <Sanitized
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textShadow: "0 0 0.5px rgba(0, 0, 0, 0.5)",
                    fontWeight: 400,
                    display: "-webkit-inline-box",
                    WebkitBoxOrient: "vertical",
                    lineClamp: !expanded ? 3 : undefined,
                    WebkitLineClamp: !expanded ? 3 : undefined,
                  }}
                  className={classes.sanitized}
                  __html={reviewText}
                />
              </Body1>
            </Grid>
          )}
          {coverageLevelDetails && clinicalReviewView && (
            <Grid item>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {coverageLevelDetails.map((cld, index) => (
                  <div key={index} style={{ marginRight: theme.spacing(3) }}>
                    <Caption style={{ color: theme.palette.text.secondary }}>
                      {cld.coverage?.coverageLineOfBusinessType} outcome
                    </Caption>
                    <Body1>{getReviewOutcomeTitle(cld.reviewOutcome || "")}</Body1>
                  </div>
                ))}
              </div>
            </Grid>
          )}
        </Grid>
      </ButtonBase>
      {displayReviewerDetailsModal && (
        <ReviewerDetailsModal
          modalOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          reviewerDetails={reviewerDetails}
          legacyReviewCompletedByName={reviewCompletedByName}
        />
      )}
    </>
  );
}
