import React, { useRef, SetStateAction, Dispatch } from "react";
import {
  Patient,
  ProcedureCode,
  ClinicalService,
  ServiceRequestResponse,
  CardExtensionProcedureCode,
  CardExtensionDetails,
} from "@coherehealth/core-platform-api";
import { PriorAuthRequirements, NonCohereCodes, RuleActionResult } from "../common";
import AuthRequirementsForm from "./AuthRequirementsForm";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Container, styled, Theme } from "@material-ui/core";
import { useMuiContainerStyles } from "@coherehealth/common";
import { AuthBuilderRequestorProps } from "components/AuthBuilder";
import { User } from "UserContext";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";

interface Props extends AuthBuilderRequestorProps {
  priorAuthRequirements: PriorAuthRequirements;
  setPriorAuthRequirements: Dispatch<SetStateAction<PriorAuthRequirements>>;
  palProcedureCodes: ProcedureCode[];
  setPalProcedureCodes: (codes: ProcedureCode[]) => void;
  nonPalProcedureCodes: ProcedureCode[];
  setNonPalProcedureCodes: (codes: ProcedureCode[]) => void;
  nonCohereCodes: NonCohereCodes[];
  setNonCohereCodes: (codesAndVendor: NonCohereCodes[]) => void;
  setCrdLogId: Dispatch<string>;
  noPxService: ClinicalService | undefined;
  setNoPxService: (service: ClinicalService | undefined) => void;
  patientData: Patient | null;
  importedEhrOrder?: ServiceRequestResponse;
  setImportedEhrOrder: (sr: ServiceRequestResponse) => void;
  currUser: User | undefined;
  setOpenNonPalConfirmationModal?: Dispatch<SetStateAction<boolean>>;
  setUserSelectedNonPalCode?: (value: boolean) => void;
  setCrdProcedureCodeResults: React.Dispatch<SetStateAction<CardExtensionProcedureCode[] | undefined>>;
  setCrdExtensionCardResults: React.Dispatch<SetStateAction<CardExtensionDetails | undefined>>;
  facilityBasedFeatureEnabled?: boolean;
  formContent?: ServiceRequestFormContent;
  setFormContent?: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  isFaxAuthBuilderFlow: boolean;
  fetchRedirectsAndRecommendChangeForProcedureCodes: () => Promise<RuleActionResult>;
}

export default function GatherRequirementsContainer({
  priorAuthRequirements,
  setPriorAuthRequirements,
  palProcedureCodes,
  setPalProcedureCodes,
  nonPalProcedureCodes,
  setNonPalProcedureCodes,
  nonCohereCodes,
  setNonCohereCodes,
  setCrdLogId,
  noPxService,
  setNoPxService,
  patientData,
  importedEhrOrder,
  setImportedEhrOrder,
  currUser,
  setOpenNonPalConfirmationModal,
  setUserSelectedNonPalCode,
  setCrdProcedureCodeResults,
  setCrdExtensionCardResults,
  facilityBasedFeatureEnabled,
  formContent,
  setFormContent,
  isFaxAuthBuilderFlow,
  fetchRedirectsAndRecommendChangeForProcedureCodes,
  ...requestorProps
}: Props) {
  const containerClasses = useMuiContainerStyles();
  const PALCheckStart = useRef<null | HTMLDivElement>(null);
  const scrollToPALCheck = async () => {
    // For some reason, if the current scroll top is at 0 (top of the page) then,
    // scrolling to the PALCheckStart.current?.offsetTop does not go to the expected position.
    // Scrolling a pixel down and then scrolling to `PALCheckStart.current?.offsetTop - 36` seems to fix it...
    window.scrollTo({ top: window.scrollY + 1 });
    await setTimeout(() => {
      if (PALCheckStart.current?.offsetHeight) {
        window.scrollTo({
          top: PALCheckStart.current?.offsetTop - 36,
          behavior: "smooth",
        });
      }
    }, 500);
  };

  return (
    <OuterWrapper data-testid="gather-requirement-outer-wrapper">
      <Wrapper isFaxAuthBuilderFlow={isFaxAuthBuilderFlow}>
        <Container classes={containerClasses} maxWidth="lg">
          <AuthRequirementsForm
            priorAuthRequirements={priorAuthRequirements}
            setPriorAuthRequirements={setPriorAuthRequirements}
            setPalProcedureCodes={setPalProcedureCodes}
            setNonPalProcedureCodes={setNonPalProcedureCodes}
            setNonCohereCodes={setNonCohereCodes}
            noPxService={noPxService}
            setNoPxService={setNoPxService}
            setCrdLogId={setCrdLogId}
            patientData={patientData}
            scrollToPALCheck={scrollToPALCheck}
            importedEhrOrder={importedEhrOrder}
            setImportedEhrOrder={setImportedEhrOrder}
            currUser={currUser}
            setCrdProcedureCodeResults={setCrdProcedureCodeResults}
            setCrdExtensionCardResults={setCrdExtensionCardResults}
            formContent={formContent}
            setFormContent={setFormContent}
            fetchRedirectsAndRecommendChangeForProcedureCodes={fetchRedirectsAndRecommendChangeForProcedureCodes}
            {...requestorProps}
          />
        </Container>
      </Wrapper>
    </OuterWrapper>
  );
}

interface StyleFeatureFlags {
  isFaxAuthBuilderFlow: boolean;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Wrapper = styled(({ isFaxAuthBuilderFlow, ...passthrough }) => <div {...passthrough} />)<
  Theme,
  StyleFeatureFlags
>(({ theme, isFaxAuthBuilderFlow }) => ({
  width: isFaxAuthBuilderFlow ? "100%" : "100vw",
  borderBottom: "none",
  backgroundColor: undefined,
  padding: isFaxAuthBuilderFlow ? theme.spacing(3, 0, 0, 0) : theme.spacing(5, 0, 0, 0),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OuterWrapper = styled("div")({
  overflow: "hidden",
});
