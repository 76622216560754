import React, { Dispatch, ForwardedRef, SetStateAction, useEffect, useState } from "react";
import {
  H4,
  InlineButton,
  PrimaryButton,
  useFeature,
  parseDateFromISOString,
  SingleSelectDropdown,
  InformativeModal,
  useConfiguration,
} from "@coherehealth/common";
import {
  MdReview,
  NurseReview,
  PeerToPeerReview,
  ServiceRequestResponse,
  useUpdateServiceRequest,
  useGetAllReviews,
  GuidelineSnapshot,
  GuidelineInfo,
  BaseReview,
  ReviewType,
  AuthorizationResponse,
  useGetLatestReviewLeadingToDenial,
  OutOfNetworkReview,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PhoneIcon from "@material-ui/icons/Phone";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import { useAuthorized } from "authorization";
import { useSnackbar } from "notistack";
import { generatePath, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import routes from "routes";
import NurseReviewComponent from "./NurseReview/NurseReview";
import { OutOfNetworkReviewReadonly } from "./OutOfNetworkReview/OutOfNetworkReview";
import MDReviewComponent from "./MDReview/MDReview";
import PeerToPeerReviewComponent from "./MDReview/PeerToPeerReview";
import DenyServiceRequestModal from "./Modals/DenyServiceRequestModal";
import PartiallyApproveServiceRequestModal from "./Modals/PartiallyApproveServiceRequestModal";
import { Box, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import PeerToPeerOutreachBanner from "./PeerToPeerOutreachBanner";
import { ReviewListTypes } from "../../ClinicalReview/Review/ClinicalReviewPage";
import { ReviewsProvider } from "../../ClinicalReview/reviewUtils/useReviews";
import {
  CREATE_MD_REVIEW_PARAM,
  CREATE_NURSE_REVIEW_PARAM,
  CREATE_OUT_OF_NETWORK_REVIEW_PARAM,
  CREATE_P2P_REVIEW_PARAM,
  CREATE_AUDIT_REVIEW_PARAM,
  CREATE_CPP_REVIEW_PARAM,
  CREATE_P2P_POST_DECISION_REVIEW_PARAM,
  APPEAL_LETTER_PARAM,
} from "../../../util/queryParams";
import {
  isServiceRequestCppEligible,
  nurseReviewButtonText,
  createOrContinue,
  useGetIsUsingClinicalConversation,
} from "./util/ReviewSectionUtil";
import AuditReviewComponent from "./AuditReview/AuditReview";
import { isTerminalStatus, mapAuthServiceRequestsToDropdownOptions } from "util/serviceRequest";
import { ReviewCorrection } from "./ReviewCorrection/ReviewCorrection";
interface Props {
  serviceRequest: ServiceRequestResponse;
  authorization?: AuthorizationResponse;
  onFinishEditing?: () => void;
  willAutoApprove?: boolean;
  targetReviewId?: string | null;
  setTargetReview?: Dispatch<SetStateAction<HTMLElement | null>>;
  crrCardView?: boolean;
  adhocLetter?: boolean;
}

export const guidelineSnapshotFromReview = (
  review: NurseReview | OutOfNetworkReview | MdReview | PeerToPeerReview | BaseReview
) => {
  const guidelineInfo = review.guidelines?.map((guideline) => {
    return {
      guidelineIds: guideline.guidelineIds,
      checkedIndications: guideline.checkedIndications?.map((ind) => ind.indicationHtml?.replace(/<[^>]+>/g, "")),
      guidelineType: guideline.policyType,
    };
  });
  const returnInfo = guidelineInfo as GuidelineInfo;
  const returnSnap = { guidelineInfo: returnInfo } as GuidelineSnapshot;
  return returnSnap;
};

const useStyles = makeStyles((theme) => ({
  inlineButton: {
    paddingRight: theme.spacing(2),
  },
}));

type ReviewSectionButton =
  | "LOG_OUTREACH_PAGE"
  | "CLINICAL_REVIEW"
  | "OUT_OF_NETWORK_REVIEW"
  | "NOTE"
  | "APPEAL_NOTE"
  | "MD_REVIEW"
  | "AD_HOC_LETTER"
  | "APPEAL_LETTER"
  | "P2P_REVIEW"
  | "DETERMINATION_LETTER"
  | "AUDIT_REVIEW"
  | "REVIEW_CORRECTION";

const ReviewSection = React.forwardRef(
  (
    {
      serviceRequest,
      authorization,
      onFinishEditing,
      willAutoApprove: initialWillAutoApprove,
      targetReviewId,
      setTargetReview,
      adhocLetter,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { enqueueSnackbar } = useSnackbar();

    const {
      data: existingReviews,
      error: existingReviewsError,
      loading: loadingExistingReviews,
      refetch: refetchReviews,
    } = useGetAllReviews({
      serviceRequestId: serviceRequest.id,
      queryParams: { max: 200 },
    });
    useEffect(() => {
      if (existingReviewsError) {
        enqueueSnackbar(`Failed to load reviews: ${existingReviewsError.message}`, { variant: "error" });
      }
    }, [enqueueSnackbar, existingReviewsError]);
    return (
      <ReviewsProvider initialReviews={existingReviews}>
        <ReviewSectionContent
          existingReviews={existingReviews}
          serviceRequest={serviceRequest}
          authorization={authorization}
          onFinishEditing={onFinishEditing}
          willAutoApprove={initialWillAutoApprove}
          targetReviewId={targetReviewId}
          setTargetReview={setTargetReview}
          refetchReviews={refetchReviews}
          reviewSectionRef={ref}
          loadingExistingReviews={loadingExistingReviews}
          adhocLetter={adhocLetter}
        />
      </ReviewsProvider>
    );
  }
);

export default ReviewSection;

interface ReviewSectionContentProps extends React.HTMLProps<HTMLDivElement> {
  existingReviews: ReviewType[] | null;
  loadingExistingReviews?: boolean;
  serviceRequest: ServiceRequestResponse;
  authorization?: AuthorizationResponse;
  shouldCreateNewNurseReview?: boolean;
  shouldCreateNewMdReview?: boolean;
  shouldCreateNewP2PReview?: boolean;
  onFinishEditing?: () => void;
  willAutoApprove?: boolean;
  targetReviewId?: string | null;
  setTargetReview?: Dispatch<SetStateAction<HTMLElement | null>>;
  refetchReviews?: () => Promise<unknown>;
  reviewSectionRef?: React.ForwardedRef<HTMLDivElement>;
  adhocLetter?: boolean;
}

export function ReviewSectionContent({
  existingReviews,
  loadingExistingReviews,
  serviceRequest,
  authorization,
  onFinishEditing,
  willAutoApprove: initialWillAutoApprove,
  targetReviewId,
  setTargetReview,
  refetchReviews,
  reviewSectionRef,
  adhocLetter,
  ...rest
}: ReviewSectionContentProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const canEditNurseReview = useAuthorized("EDIT_NURSE_REVIEW");
  const canEditMdReview = useAuthorized("EDIT_MD_REVIEW");
  const canEditPeerToPeerReview = useAuthorized("EDIT_P2P_REVIEW");
  const canEditFinalizedMDReview = useAuthorized("EDIT_FINALIZED_MD_REVIEW");
  const canSubmitDenial = useAuthorized("SUBMIT_DENIAL");
  const hasOONReviewPermission = useAuthorized("EDIT_OUT_OF_NETWORK_REVIEW");
  const completedReviews = existingReviews?.filter((review) => review.reviewStatus === "COMPLETE") || [];
  const oonReviewConfig = useConfiguration(
    "outOfNetworkReviewConfiguration",
    serviceRequest.healthPlanName,
    serviceRequest.delegatedVendor
  );
  const oonReviewConfigEnabled = oonReviewConfig?.enabled;
  const qioAppealLetterEnabled = useConfiguration(
    "lettersExpansionConfiguration",
    serviceRequest.healthPlanName,
    serviceRequest.delegatedVendor
  )?.qioAppealLetters;
  const canStartAppealLetter = useAuthorized("START_APPEAL_LETTER");
  const canEditOutOfNetworkReview = hasOONReviewPermission && oonReviewConfigEnabled;
  const auditReviewsAllowed = useFeature("auditReviews");
  const hasAuditPermission = useAuthorized("AUDIT_REVIEW");
  const canAuditReview = hasAuditPermission && auditReviewsAllowed;
  const canEditAReview = canEditNurseReview || canEditOutOfNetworkReview || canEditMdReview || canEditPeerToPeerReview;
  const canUseNewDenialsWorkflowBeta = useAuthorized("USE_NEW_DENIALS_WORKFLOW");
  const canUseNewDenialWorkflowGeneralAvailability =
    canEditMdReview || canEditPeerToPeerReview || canSubmitDenial || canEditFinalizedMDReview;
  const newDenialsWorkflow = canUseNewDenialsWorkflowBeta || canUseNewDenialWorkflowGeneralAvailability;

  const canUseClinicalReviewRedesign = useAuthorized("USE_CLINICAL_REVIEW_REDESIGN_GENERAL");
  const isUsingClinicalConversation = useGetIsUsingClinicalConversation(serviceRequest);
  const hideCareWebQIOnPatientSummary = useFeature("hideCareWebQIOnPatientSummary");
  const postDecisionP2PExpanded = useFeature("postDecisionP2PExpanded");
  const navigate = useNavigate();

  const [allReviews, setAllReviews] = useState<ReviewType[]>([]);

  // We only have separate "allReviews" v. "existingReviews" to differentiate when newly created reviews have finished loading
  useEffect(() => {
    if (existingReviews && existingReviews.length > 0) {
      setAllReviews(existingReviews);
    }
  }, [existingReviews, setAllReviews]);

  const getAllReviewsWithOutreaches = () => {
    if (
      (allReviews && allReviews.length > 0) ||
      (serviceRequest?.peerToPeerOutreaches && serviceRequest.peerToPeerOutreaches?.length > 0)
    ) {
      const peerToPeerOutreaches: ReviewListTypes =
        serviceRequest?.peerToPeerOutreaches?.map((outreach) => ({
          ...outreach,
          reviewType: "P2POutreach",
          reviewStatus: "",
        })) || [];
      const reviews = (allReviews || []) as ReviewListTypes;
      const listItems: ReviewListTypes = reviews
        .concat(peerToPeerOutreaches)
        .filter((review) => review.reviewStatus !== "DRAFT")
        .sort(
          (a, b) =>
            parseDateFromISOString(a.dateCompleted || a.dateCreated).getTime() -
            parseDateFromISOString(b.dateCompleted || b.dateCreated).getTime()
        );
      return listItems;
    }
    return [];
  };
  const allReviewsWithOutreaches = getAllReviewsWithOutreaches();

  // Here is some logic that we derive from the current list of all reviews, used below to gate the auto-create and create review buttons
  const finishedLoadingExistingReviews =
    existingReviews instanceof Array && allReviews.length === existingReviews.length;
  const hasSubmittedMDReview = Boolean(
    allReviews.find((review) => review.reviewType === "MdReview" && review.reviewStatus === "COMPLETE")
  );

  const hasDraftNurseReview = Boolean(
    allReviews.find((review) => review.reviewType === "NurseReview" && review.reviewStatus === "DRAFT")
  );
  const hasDraftOutOfNetworkReview = Boolean(
    allReviews.find((review) => review.reviewType === "OutOfNetworkReview" && review.reviewStatus === "DRAFT")
  );
  const hasDraftAuditReview = Boolean(
    allReviews.find((review) => review.reviewType === "AuditReview" && review.reviewStatus === "DRAFT")
  );
  const hasDraftMdReview = Boolean(
    allReviews.find((review) => review.reviewType === "MdReview" && review.reviewStatus === "DRAFT")
  );
  const hasDraftP2PReview = Boolean(
    allReviews.find((review) => review.reviewType === "PeerToPeerReview" && review.reviewStatus === "DRAFT")
  );

  const { data: mostRecentReviewLeadingToDenial } = useGetLatestReviewLeadingToDenial({
    id: serviceRequest?.id,
  });

  const hasCurrentDraftDenial = mostRecentReviewLeadingToDenial?.denialStatus === "DRAFT";
  const hasCurrentCompletedDenial = mostRecentReviewLeadingToDenial?.denialStatus === "COMPLETE";

  const numCompletedReviews = allReviews.filter(
    (review) => review.reviewStatus === "COMPLETE" && (review.reviewType !== "AuditReview" || canAuditReview)
  ).length;

  const sortedAuthServiceRequests = authorization?.serviceRequestsOnAuth?.sort(
    (a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
  ) || [serviceRequest];
  const sortedTerminalAuthServiceRequests = sortedAuthServiceRequests.filter(isTerminalStatus);
  const [selectedServiceRequest, setSelectedServiceRequest] = useState(sortedAuthServiceRequests[0]);

  const canAddContact = useAuthorized("ACCESS_LOG_OUTREACH_PAGE");
  const isCppReviewEligible = isServiceRequestCppEligible(serviceRequest?.healthPlanName || "");

  const genericNotesEnabled = useFeature("genericNotes");
  const genericNotesAuthorized = useAuthorized("EDIT_NOTES");
  const canAddGenericNote = genericNotesEnabled && genericNotesAuthorized;

  const { enabled: appealNoteEnabled } =
    useConfiguration("decisionAppealsConfiguration", serviceRequest.healthPlanName, serviceRequest.delegatedVendor) ??
    {};
  const appealNoteAuthorized = useAuthorized("EDIT_APPEAL");
  const isTerminalAuthStatus = isTerminalStatus(serviceRequest);
  // Can log appeal for any decisioned SR under the auth, not just the focused one
  const canLogAppealNote = appealNoteEnabled && appealNoteAuthorized && sortedTerminalAuthServiceRequests.length > 0;
  const showDeterminationLetterButton =
    canEditFinalizedMDReview && mostRecentReviewLeadingToDenial && !hasCurrentCompletedDenial && !isTerminalAuthStatus;

  const [showNurseReview, setShowNurseReview] = useState<createOrContinue>();
  const [showOutOfNetworkReview, setShowOutOfNetworkReview] = useState<createOrContinue>();
  const [showMdReview, setShowMdReview] = useState<createOrContinue>();
  const [showP2PReview, setShowP2PReview] = useState<createOrContinue>();
  const [open, setEditModalOpen] = useState<boolean>(false);
  const [clickedButton, setClickedButton] = useState<ReviewSectionButton>();

  const sortedAuthServiceRequestsToDisplay = (clickedButton?: ReviewSectionButton) =>
    clickedButton === "APPEAL_NOTE" ? sortedTerminalAuthServiceRequests : sortedAuthServiceRequests;

  useEffect(() => {
    if (canEditNurseReview) {
      if (!hasDraftNurseReview) {
        setShowNurseReview("CREATE");
      } else {
        setShowNurseReview("CONTINUE");
      }
    }
    if (canEditOutOfNetworkReview) {
      if (!hasDraftOutOfNetworkReview) {
        setShowOutOfNetworkReview("CREATE");
      } else {
        setShowOutOfNetworkReview("CONTINUE");
      }
    }
    if (canEditMdReview) {
      if (!hasDraftMdReview) {
        setShowMdReview("CREATE");
      } else {
        setShowMdReview("CONTINUE");
      }
    }
    if (canEditPeerToPeerReview) {
      if (!hasDraftP2PReview) {
        setShowP2PReview("CREATE");
      } else {
        setShowP2PReview("CONTINUE");
      }
    }
  }, [
    canEditNurseReview,
    hasDraftNurseReview,
    canEditOutOfNetworkReview,
    hasDraftOutOfNetworkReview,
    canEditMdReview,
    hasDraftMdReview,
    canEditPeerToPeerReview,
    hasDraftP2PReview,
  ]);
  const showDenyButton =
    finishedLoadingExistingReviews &&
    serviceRequest.authStatus === "RECOMMENDED_DENIAL" &&
    canSubmitDenial &&
    hasSubmittedMDReview &&
    !hasDraftP2PReview &&
    !newDenialsWorkflow;
  const showPartiallyApproveButton =
    finishedLoadingExistingReviews &&
    serviceRequest.authStatus === "RECOMMENDED_PARTIAL_APPROVAL" &&
    canSubmitDenial &&
    hasSubmittedMDReview &&
    !hasDraftP2PReview &&
    !newDenialsWorkflow;

  const [isDenyModalOpen, setIsDenyModalOpen] = useState(false);
  const [isPartialApproveModalOpen, setIsPartialApproveModalOpen] = useState(false);

  const {
    mutate: updateServiceRequest,
    loading: submitting,
    error: submitError,
  } = useUpdateServiceRequest({
    id: serviceRequest.id,
  });
  useEffect(() => {
    if (submitError) {
      enqueueSnackbar(`Error updating service request: ${submitError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, submitError]);

  if (!useAuthorized("VIEW_REVIEW")) {
    return null;
  }

  const onFinalizeDenial = async (event: any) => {
    event.preventDefault();
    await updateServiceRequest?.({
      authStatus: "DENIED",
    });
    onFinishEditing?.();
    setIsDenyModalOpen(false);
  };

  const onFinalizePartialApproval = async (event: any) => {
    event.preventDefault();
    await updateServiceRequest?.({
      authStatus: "PARTIALLY_APPROVED",
    });
    onFinishEditing?.();
    setIsPartialApproveModalOpen(false);
  };

  const redirectOrShowModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    clickedButton: ReviewSectionButton,
    srIdOverride?: string
  ) => {
    if (sortedAuthServiceRequestsToDisplay(clickedButton).length > 1) {
      setClickedButton(clickedButton);
      setEditModalOpen(true);
    } else {
      redirectToClickedButton(event, clickedButton, srIdOverride);
    }
  };

  const redirectToClickedButton = (
    event: MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    clickedButton: ReviewSectionButton,
    srIdOverride?: string
  ) => {
    const redirectRoute = getClickedButtonRoute(clickedButton, srIdOverride);
    if (event?.ctrlKey || event?.metaKey || clickedButton === "AD_HOC_LETTER") {
      window.open(redirectRoute, "_blank", "noopener");
      setEditModalOpen(false);
    } else {
      navigate(redirectRoute as string);
    }
  };

  const getClickedButtonRoute = (clickedButton: ReviewSectionButton, srIdOverride?: string) => {
    switch (clickedButton) {
      case "LOG_OUTREACH_PAGE":
        return generatePath(routes.LOG_OUTREACH_PAGE, { serviceRequestId: selectedServiceRequest?.id });
      case "CLINICAL_REVIEW":
        return generatePath(routes.CLINICAL_REVIEW, { serviceRequestId: selectedServiceRequest?.id }).concat(
          `?${isCppReviewEligible ? CREATE_CPP_REVIEW_PARAM : CREATE_NURSE_REVIEW_PARAM}`
        );
      case "OUT_OF_NETWORK_REVIEW":
        return generatePath(routes.CLINICAL_REVIEW, { serviceRequestId: selectedServiceRequest?.id }).concat(
          `?${CREATE_OUT_OF_NETWORK_REVIEW_PARAM}`
        );
      case "NOTE":
        return generatePath(routes.NEW_NOTE_PAGE, { serviceRequestId: selectedServiceRequest?.id });
      case "APPEAL_NOTE":
        return generatePath(routes.APPEAL_NOTE_PAGE, { serviceRequestId: srIdOverride || selectedServiceRequest?.id });
      case "MD_REVIEW":
        return generatePath(routes.CLINICAL_REVIEW, { serviceRequestId: selectedServiceRequest?.id }).concat(
          `?${CREATE_MD_REVIEW_PARAM}`
        );
      case "AD_HOC_LETTER":
        return generatePath(routes.ADHOC_LETTER, { serviceRequestId: selectedServiceRequest?.id });
      case "APPEAL_LETTER":
        return generatePath(routes.ADHOC_LETTER, { serviceRequestId: selectedServiceRequest?.id }).concat(
          `?${APPEAL_LETTER_PARAM}`
        );
      case "P2P_REVIEW":
        let queryParam;
        if (postDecisionP2PExpanded && selectedServiceRequest.authStatus === "POST_DENIAL_PEER_TO_PEER") {
          queryParam = CREATE_P2P_POST_DECISION_REVIEW_PARAM;
        } else {
          queryParam = CREATE_P2P_REVIEW_PARAM;
        }
        return generatePath(routes.CLINICAL_REVIEW, { serviceRequestId: selectedServiceRequest?.id }).concat(
          `?${queryParam}`
        );
      case "DETERMINATION_LETTER":
        return generatePath(routes.DENIAL_REVIEW, {
          serviceRequestId: selectedServiceRequest.id,
        });
      case "AUDIT_REVIEW":
        return generatePath(routes.CLINICAL_REVIEW, { serviceRequestId: selectedServiceRequest?.id }).concat(
          `?${CREATE_AUDIT_REVIEW_PARAM}`
        );
    }
  };

  return (
    <div ref={reviewSectionRef} {...rest}>
      <ReviewsHeader data-public>
        Completed Reviews {loadingExistingReviews ? <CircularProgress size={16} /> : `(${numCompletedReviews})`}
      </ReviewsHeader>
      {allReviewsWithOutreaches.map((review) => (
        <React.Fragment key={review.id}>
          {review.reviewType === "NurseReview" && (canEditAReview || review.reviewStatus !== "DRAFT") ? (
            <NurseReviewComponent
              key={review.id}
              nurseReview={review}
              serviceRequest={serviceRequest}
              expanded={false}
              onFinishEditing={onFinishEditing}
              willAutoApprove={initialWillAutoApprove}
              reviewRef={(newRef) => {
                if (!!targetReviewId) {
                  if (review.id === targetReviewId) {
                    setTargetReview?.(newRef);
                  }
                }
              }}
              unexpandable
              authorization={authorization}
            />
          ) : canEditOutOfNetworkReview &&
            review.reviewType === "OutOfNetworkReview" &&
            (canEditAReview || review.reviewStatus !== "DRAFT") ? (
            <OutOfNetworkReviewReadonly
              key={review.id}
              outOfNetworkReview={review}
              serviceRequest={serviceRequest}
              expanded={false}
              reviewRef={(newRef) => {
                if (!!targetReviewId) {
                  if (review.id === targetReviewId) {
                    setTargetReview?.(newRef);
                  }
                }
              }}
              unexpandable
            />
          ) : review.reviewType === "MdReview" && (canEditAReview || review.reviewStatus !== "DRAFT") ? (
            <MDReviewComponent
              key={review.id}
              mdReview={review}
              existingReviews={existingReviews}
              serviceRequest={serviceRequest}
              expanded={false}
              onFinishEditing={onFinishEditing}
              willAutoApprove={initialWillAutoApprove}
              newDenialsWorkflow={newDenialsWorkflow}
              allReviews={allReviews}
              reviewRef={(newRef) => {
                if (!!targetReviewId) {
                  if (review.id === targetReviewId) {
                    setTargetReview?.(newRef);
                  }
                }
              }}
              unexpandable
              authorization={authorization}
            />
          ) : review.reviewType === "PeerToPeerReview" && (canEditAReview || review.reviewStatus !== "DRAFT") ? (
            <PeerToPeerReviewComponent
              key={review.id}
              p2pReview={review}
              existingReviews={existingReviews}
              serviceRequest={serviceRequest}
              expanded={false}
              onFinishEditing={onFinishEditing}
              willAutoApprove={initialWillAutoApprove}
              newDenialsWorkflow={newDenialsWorkflow}
              reviewRef={(newRef) => {
                if (!!targetReviewId) {
                  if (review.id === targetReviewId) {
                    setTargetReview?.(newRef);
                  }
                }
              }}
              unexpandable
              isUsingClinicalConversation={isUsingClinicalConversation}
              authorization={authorization}
            />
          ) : ((review.reviewType === "AuditReview" && canAuditReview) ||
              (review.reviewType === "NewHireAuditReview" && hasAuditPermission) ||
              (review.reviewType === "OutOfScopeAuditReview" && hasAuditPermission)) &&
            review.reviewStatus !== "DRAFT" ? (
            <AuditReviewComponent
              auditReview={review}
              serviceRequest={serviceRequest}
              expanded={false}
              onFinishEditing={onFinishEditing}
              reviewRef={(newRef) => {
                if (!!targetReviewId) {
                  if (review.id === targetReviewId) {
                    setTargetReview?.(newRef);
                  }
                }
              }}
              unexpandable
            />
          ) : review.reviewType === "P2POutreach" ? (
            <PeerToPeerOutreachBanner
              dateCreated={review.dateCreated}
              attemptedTimestamp={review.attemptedTimestamp}
              outcome={review.outcome}
            />
          ) : (
            ""
          )}
        </React.Fragment>
      ))}
      {
        <>
          <ButtonFooterContainer>
            {showDenyButton && (
              <>
                <Grid item xs={6}>
                  <PrimaryErrorButton onClick={() => setIsDenyModalOpen(true)}>Deny</PrimaryErrorButton>
                </Grid>
                <DenyServiceRequestModal
                  loading={submitting}
                  isOpen={isDenyModalOpen}
                  setIsOpen={setIsDenyModalOpen}
                  onFinalizeDenial={onFinalizeDenial}
                />
              </>
            )}
            {showPartiallyApproveButton && (
              <>
                <Grid item xs={6}>
                  <PrimaryErrorButton onClick={() => setIsPartialApproveModalOpen(true)}>
                    Partially Approve
                  </PrimaryErrorButton>
                </Grid>
                <PartiallyApproveServiceRequestModal
                  // Drop and reload the modal when the request changes to prevent stale data
                  key={`${serviceRequest.id}:${serviceRequest.lastUpdated}`}
                  isReadonly
                  loading={submitting}
                  isOpen={isPartialApproveModalOpen}
                  setIsOpen={setIsPartialApproveModalOpen}
                  onFinalizeDenial={onFinalizePartialApproval}
                  serviceRequest={serviceRequest}
                />
              </>
            )}
            {
              <Grid item xs={12}>
                {canEditAReview && !hideCareWebQIOnPatientSummary && (
                  <InlineButton
                    component={Link}
                    to={{
                      pathname: generatePath(routes.CAREWEBQI, { serviceRequestId: serviceRequest.id }),
                    }}
                    target="_blank"
                    rel="noopener"
                    startIcon={<EditIcon />}
                    className={classes.inlineButton}
                  >
                    Use in CareWebQI
                  </InlineButton>
                )}
                {canAddContact && (
                  <>
                    <StyledInlineButton
                      onClick={(event) => {
                        redirectOrShowModal(event, "LOG_OUTREACH_PAGE");
                      }}
                      startIcon={<PhoneIcon />}
                    >
                      Add contact
                    </StyledInlineButton>

                    <InformativeModal
                      open={open}
                      scroll="body"
                      maxWidth="md"
                      onClose={(_) => {
                        setEditModalOpen(false);
                      }}
                      headerText={"Select a request to proceed"}
                      primaryButtonText={`Continue ${clickedButton ? `to ${buttonActionLabel[clickedButton]}` : ""}`}
                      primaryButtonAction={(event) => {
                        if (clickedButton) {
                          redirectToClickedButton(event, clickedButton);
                        }
                      }}
                      tertiaryButtonText="Cancel"
                      tertiaryButtonAction={() => setEditModalOpen(false)}
                      additionalInfoElement={
                        <Box style={{ paddingTop: 32, paddingBottom: 16, width: "100%" }}>
                          <SingleSelectDropdown
                            fullWidth
                            label={"Select a request"}
                            value={selectedServiceRequest.id}
                            options={mapAuthServiceRequestsToDropdownOptions(
                              sortedAuthServiceRequestsToDisplay(clickedButton)
                            )}
                            onChange={(serviceRequestId) => {
                              const selection =
                                sortedAuthServiceRequestsToDisplay(clickedButton).find(
                                  (sr) => sr.id === serviceRequestId
                                ) || selectedServiceRequest;
                              setSelectedServiceRequest(selection);
                            }}
                          />
                        </Box>
                      }
                      showDivider={false}
                    />
                  </>
                )}

                {(showNurseReview === "CREATE" || (showNurseReview === "CONTINUE" && canUseClinicalReviewRedesign)) && (
                  <StyledInlineButton
                    onClick={(event) => {
                      if (canUseClinicalReviewRedesign) {
                        redirectOrShowModal(event, "CLINICAL_REVIEW");
                      }
                    }}
                    startIcon={showNurseReview === "CREATE" ? <AddCircleIcon /> : <EditIcon />}
                  >
                    {nurseReviewButtonText(showNurseReview, isCppReviewEligible)}
                  </StyledInlineButton>
                )}
                {canEditOutOfNetworkReview &&
                  (showOutOfNetworkReview === "CREATE" || showOutOfNetworkReview === "CONTINUE") && (
                    <StyledInlineButton
                      onClick={(event) => {
                        redirectOrShowModal(event, "OUT_OF_NETWORK_REVIEW");
                      }}
                      startIcon={showOutOfNetworkReview === "CREATE" ? <AddCircleIcon /> : <EditIcon />}
                    >
                      {showOutOfNetworkReview === "CREATE"
                        ? "Add OON exception review"
                        : "Continue OON exception review"}
                    </StyledInlineButton>
                  )}
                {canAddGenericNote && (
                  <StyledInlineButton
                    onClick={(event) => redirectOrShowModal(event, "NOTE")}
                    startIcon={<AddCircleIcon />}
                  >
                    Add note
                  </StyledInlineButton>
                )}
                {(showMdReview === "CREATE" || (showMdReview === "CONTINUE" && canUseClinicalReviewRedesign)) && (
                  <StyledInlineButton
                    onClick={(event) => {
                      if (canUseClinicalReviewRedesign) {
                        redirectOrShowModal(event, "MD_REVIEW");
                      }
                    }}
                    startIcon={showMdReview === "CREATE" ? <AddCircleIcon /> : <EditIcon />}
                  >
                    {showMdReview === "CREATE" ? "Add MD clinical review" : "Continue MD clinical review"}
                  </StyledInlineButton>
                )}
                {adhocLetter && (
                  <StyledInlineButton
                    startIcon={<AddCircleIcon />}
                    onClick={(event) => {
                      redirectOrShowModal(event, "AD_HOC_LETTER");
                    }}
                  >
                    Add letter
                  </StyledInlineButton>
                )}
                {qioAppealLetterEnabled && canStartAppealLetter && (
                  <StyledInlineButton
                    startIcon={<AddCircleIcon />}
                    onClick={(event) => {
                      redirectOrShowModal(event, "APPEAL_LETTER");
                    }}
                  >
                    Add appeal notice
                  </StyledInlineButton>
                )}
                <ReviewCorrection {...{ serviceRequest, completedReviews }} />
                {(showP2PReview === "CREATE" || (showP2PReview === "CONTINUE" && canUseClinicalReviewRedesign)) && (
                  <StyledInlineButton
                    onClick={(event) => {
                      if (canUseClinicalReviewRedesign) {
                        redirectOrShowModal(event, "P2P_REVIEW");
                      }
                    }}
                    startIcon={showP2PReview === "CREATE" ? <AddCircleIcon /> : <EditIcon />}
                  >
                    {showP2PReview === "CREATE" ? "Add peer to peer review" : "Continue P2P clinical review"}
                  </StyledInlineButton>
                )}
                {canLogAppealNote && (
                  <StyledInlineButton
                    onClick={(event) => {
                      // Prevent users from attempting to log appeals on non-terminal SRs.
                      let selectedTerminalServiceRequest = selectedServiceRequest;
                      if (!isTerminalStatus(selectedServiceRequest)) {
                        const firstTerminalServiceRequest = sortedAuthServiceRequestsToDisplay("APPEAL_NOTE")[0];
                        if (firstTerminalServiceRequest) {
                          selectedTerminalServiceRequest = firstTerminalServiceRequest;
                          setSelectedServiceRequest(firstTerminalServiceRequest);
                        }
                      }
                      // Redirect URLs are generated before the next render.
                      // We can provide a different ID now, if selectedSR is going to change.
                      redirectOrShowModal(event, "APPEAL_NOTE", selectedTerminalServiceRequest?.id);
                    }}
                    startIcon={<AddCircleIcon />}
                  >
                    Log appeal note
                  </StyledInlineButton>
                )}
                {showDeterminationLetterButton && (
                  <StyledInlineButton
                    onClick={(event) => {
                      redirectOrShowModal(event, "DETERMINATION_LETTER");
                    }}
                    startIcon={hasCurrentDraftDenial ? <EditIcon /> : <AddCircleIcon />}
                  >
                    {hasCurrentDraftDenial ? "Continue determination letter" : "Add determination letter"}
                  </StyledInlineButton>
                )}
                {canAuditReview && (
                  <StyledInlineButton
                    onClick={(event) => {
                      redirectOrShowModal(event, "AUDIT_REVIEW");
                    }}
                    startIcon={hasDraftAuditReview ? <EditIcon /> : <AddCircleIcon />}
                  >
                    Audit
                  </StyledInlineButton>
                )}
                <StyledInlineButton
                  onClick={() => {
                    if (serviceRequest.id) {
                      window.location.assign(
                        generatePath(routes.VIEW_ONLY_REVIEW).concat(`?serviceRequestId=${serviceRequest.id}`)
                      );
                    }
                  }}
                  startIcon={<ViewIcon />}
                >
                  View all reviews
                </StyledInlineButton>
              </Grid>
            }
          </ButtonFooterContainer>
        </>
      }
    </div>
  );
}

const buttonActionLabel: Record<ReviewSectionButton, string> = {
  LOG_OUTREACH_PAGE: "contact",
  CLINICAL_REVIEW: "clinical review",
  OUT_OF_NETWORK_REVIEW: "OON exception review",
  NOTE: "note",
  MD_REVIEW: "MD clinical review",
  AD_HOC_LETTER: "letter",
  APPEAL_LETTER: "appeal letter",
  P2P_REVIEW: "peer to peer review",
  DETERMINATION_LETTER: "determination letter",
  AUDIT_REVIEW: "audit",
  APPEAL_NOTE: "appeal note",
  REVIEW_CORRECTION: "review correction",
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ButtonFooterContainer = styled((props) => <Grid container spacing={2} {...props} />)(({ theme }) => ({
  padding: theme.spacing(3, 3, 0),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledInlineButton = styled(InlineButton)(({ theme }) => ({
  paddingRight: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ReviewsHeader = styled(H4)(({ theme }) => ({
  padding: theme.spacing(0, 3, 2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PrimaryErrorButton = styled(PrimaryButton)(({ theme }) => ({
  minWidth: 190,
  height: 52,
  backgroundColor: theme.palette.error.dark,
  "&:hover,&:focus": {
    backgroundColor: theme.palette.error.main,
  },
}));
