import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Alert as MuiAlert, AlertTitle as MuiAlertTitle, AlertProps } from "@material-ui/lab";
import { colorsLight as colors, colorsDark, colorsLight } from "../../themes/colors";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import UndoIcon from "@material-ui/icons/Undo";
import InfoIcon from "@material-ui/icons/Info";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import PushPinIcon from "./PushPinIcon";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import Timer from "@material-ui/icons/Timer";

interface StyleProps {
  subtext?: boolean;
  message?: boolean;
  iconStyles?: object;
}
const useStylesAlert = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => {
    const messageSubtextPadding =
      props.subtext && props.message ? { padding: "0px" } : { padding: "11px 0px 10px 0px" };
    return {
      padding: theme.spacing(1, 2),
      borderRadius: "5px",
      minHeight: theme.spacing(7),
      "& .MuiAlert-icon": {
        padding: "0px",
        alignSelf: "center",
      },
      "& .MuiAlert-icon  > .MuiSvgIcon-root": {
        fontSize: theme.spacing(3),
      },
      "& .MuiAlert-message": {
        ...messageSubtextPadding,
      },
    };
  },
  standardSuccess: {
    backgroundColor: theme.palette.type === "light" ? theme.palette.success.light : colorsDark.success.light,
    "& .MuiAlertTitle-root": {
      color: theme.palette.type === "light" ? theme.palette.success.dark : colorsDark.success.dark,
    },
    "& $icon": {
      color: theme.palette.type === "light" ? theme.palette.success.main : colorsDark.success.main,
    },
  },
  standardWarning: {
    backgroundColor: theme.palette.type === "light" ? theme.palette.warning.light : colorsDark.warning.light,
    "& .MuiAlertTitle-root": {
      color: theme.palette.type === "light" ? theme.palette.warning.main : colorsDark.warning.dark,
    },
    "& $icon": {
      color: theme.palette.type === "light" ? theme.palette.warning.dark : colorsDark.warning.dark,
    },
  },
  standardError: {
    backgroundColor: theme.palette.type === "light" ? theme.palette.error.light : colorsDark.error.light,
    "& .MuiAlertTitle-root": {
      color: theme.palette.type === "light" ? theme.palette.error.main : colorsDark.error.dark,
    },
    "& $icon": {
      color: theme.palette.type === "light" ? theme.palette.error.main : colorsDark.error.main,
    },
  },
  standardInfo: {
    backgroundColor: theme.palette.type === "light" ? "#EAE9F4" : colorsDark.info.faint10,
    "& .MuiAlertTitle-root": {
      color: theme.palette.type === "light" ? colorsLight.brand.purple : colorsDark.info.dark,
    },
    "& $icon": {
      color: theme.palette.type === "light" ? colorsLight.brand.purple : colorsDark.info.dark,
    },
  },
  standardDraft: {
    backgroundColor: "#F5F5F5",
    "& .MuiAlertTitle-root": {
      color: colors.font.main,
    },
    "& $icon": {
      color: colors.font.secondary,
    },
  },
  noLongerNeeded: {
    backgroundColor: "#F5F5F5",
    "& .MuiAlertTitle-root": {
      color: colors.font.secondary,
    },
    "& $icon": {
      color: colors.font.secondary,
    },
  },
  standardWithdrawn: {
    backgroundColor: "#F5F5F5",
    "& .MuiAlertTitle-root": {
      color: colors.font.main,
    },
    "& $icon": {
      color: colors.font.secondary,
    },
  },
  standardRedirect: {
    border: "1px solid",
    borderColor: colorsDark.error.main,
    backgroundColor: colorsDark.font.primary,
    "&. MuiAlert-message": {
      fontSize: theme.spacing(2),
    },
    "& .MuiAlertTitle-root": {
      color: colors.font.main,
      fontSize: theme.spacing(2.25),
      fontWeight: 600,
    },
    "& .MuiAlert-icon": {
      alignSelf: "start !important",
    },
    "& $icon": {
      color: theme.palette.type === "light" ? theme.palette.error.main : colorsDark.error.main,
    },
  },
  message: {
    position: "relative",
    color: colors.black.mediumEmphasis,
    ...theme.typography.body1,
  },
  icon: (props) => ({
    marginRight: "16px",
    ...(props.iconStyles || {}),
  }),
}));

const useStylesAlertTitle = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => {
    const rootPadding = props.subtext && props.message ? { marginBottom: "2px" } : {};
    return { ...rootPadding, margin: "0px", ...theme.typography.body2 };
  },
  onlyTitle: {},
}));

export interface CohereAlertProps {
  /** Provide the 'title' content in this prop (instead of in a Mui `AlertTitle`), to get the proper styling. */
  message?: React.ReactNode;
  severity?: AlertProps["severity"] | "draft" | "withdrawn" | "integrationFailure" | "noLongerNeeded" | "redirect";
  iconStyles?: object;
  pendingWithDisplayHealthPlan?: boolean;
}
// Temporary export for storybook documentation
export function AlertForProps(props: CohereAlertProps) {}

const Alert = ({
  message,
  severity,
  iconStyles,
  pendingWithDisplayHealthPlan,
  children,
  className,
  ...props
}: CohereAlertProps & Omit<AlertProps, "severity" | "css">) => {
  const styleProps = { subtext: !!children, message: !!message, iconStyles };
  const { standardDraft, standardWithdrawn, noLongerNeeded, standardRedirect, ...alertClasses } = useStylesAlert({
    ...styleProps,
  });
  const extraClasses =
    severity === "draft"
      ? standardDraft
      : severity === "withdrawn" || severity === "integrationFailure" || severity === "noLongerNeeded"
      ? standardWithdrawn
      : severity === "redirect"
      ? standardRedirect
      : "";
  const alertTitleClasses = useStylesAlertTitle({ ...styleProps });
  const extraAlertTitleClass = !children ? alertTitleClasses.onlyTitle : "";
  const infoIconMaps =
    severity === "info" ? (
      <InfoIcon />
    ) : message === "Assessment not ready yet" ? (
      <Timer />
    ) : message === "Action required: Complete assessment" ? (
      <ErrorIcon />
    ) : severity === "draft" ? (
      <PushPinIcon />
    ) : severity === "integrationFailure" ? (
      <ErrorIcon />
    ) : severity === "noLongerNeeded" ? (
      <DoNotDisturbIcon />
    ) : severity === "redirect" ? (
      <DoNotDisturbOnIcon />
    ) : (
      <UndoIcon />
    );
  return (
    <MuiAlert
      className={`${extraClasses} ${className}`}
      classes={alertClasses}
      iconMapping={{
        error: <CancelIcon />,
        warning: <ErrorIcon />,
        success: <SuccessIcon />,
        info: infoIconMaps,
      }}
      severity={
        severity === "noLongerNeeded" ||
        severity === "draft" ||
        severity === "withdrawn" ||
        severity === "integrationFailure" ||
        severity === "redirect"
          ? "info"
          : severity
      }
      {...props}
    >
      {message && (
        <MuiAlertTitle className={extraAlertTitleClass} classes={{ root: alertTitleClasses.root }} data-public>
          {message}
        </MuiAlertTitle>
      )}
      {children}
    </MuiAlert>
  );
};

export default Alert;
