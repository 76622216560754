// There is a mismatch from QM -> CP on healthplans
// This is a backup to using the fax attachment health plan name

export const translateServiceCaseHealthPlan = (healthPlan?: string): string | undefined => {
  const healthPlanMap: Record<string, string> = {
    HEALTH_PARTNERS: "HEALTHPARTNERS",
    AVERA_HEALTH_PLANS: "Avera Health Plans",
    BCBS_SOUTH_CAROLINA: "BCBS South Carolina",
    AETNA: "Aetna",
  };

  return healthPlan ? healthPlanMap[healthPlan] ?? healthPlan : undefined;
};
