import React from "react";
import { makeStyles } from "@material-ui/core";
import { colorsLight } from "../../themes";
import { Body2 } from "../Typography";

interface Props {
  env: "local" | "dev" | "uat" | "intake" | "prod" | "demo" | "demo2" | "staging" | "preprod" | "unknown";
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  indicator: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
    width: "100%",
    backgroundColor: colorsLight.secondary.main,
  },
  text: {
    color: colorsLight.white.highEmphasis,
    textAlign: "center",
    padding: theme.spacing(1),
  },
}));

export default function EnviromentIndicator({ env, children }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.indicator}>
      <Body2 className={classes.text}>{env.toUpperCase()} ENVIRONMENT</Body2>
      {children}
    </div>
  );
}
