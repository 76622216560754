import { Coverage, UserActivity, useGetPatient, useUpdatePatientCoverages } from "@coherehealth/core-platform-api";
import { AppBar, Breadcrumbs, Divider } from "@material-ui/core";
import { Typography, Grid, Card, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import HeaderContainer from "components/AppHeader/HeaderContainer";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useGetPatientCoverageRiskBearingEntityEditsAllowedConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import {
  DateSelect,
  InlineButton,
  TextField as CohereTextField,
  formatDateToISODate,
  removeTimeFromDate,
  SingleSelectDropdown,
} from "@coherehealth/common";
import { Body2 } from "@coherehealth/design-system";
import {
  FormContainer,
  FormFieldContainer,
  HeaderTextContainer,
  FooterRow,
  WideButton,
  useEditPatientCoveragesStyles,
} from "../patientManagementStyles";
import { useAuthorized } from "authorization";
import { convertToTitleCase } from "util/textUtil";
import { useSnackbar } from "notistack";
import { assertIsApiError } from "util/api";
import { error } from "logger";
import { useTrackUserInteraction } from "util/userActivityTracker";
import { useGetUser } from "components/ClinicalReview/reviewUtils/utils";
import routes from "routes";

export const formattedDate = (dateSelection: null | Date) => {
  const date = dateSelection ? new Date(dateSelection) : null;
  const ignoreTime = date ? removeTimeFromDate(date) : null;
  const formatDate = ignoreTime ? formatDateToISODate(ignoreTime) : null;

  return formatDate === null ? "" : `${formatDate}`;
};

export const EditPatientCoverages = () => {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const canEditCoverage = useAuthorized("EDIT_PATIENT_COVERAGE");
  const editCoveragesStyles = useEditPatientCoveragesStyles({});
  const currentUser = useGetUser();
  const userId = currentUser?.sub || "";

  const patientId = params.patientId;
  const { data: patient, loading: patientDataLoading } = useGetPatient({
    id: patientId || "",
  });
  const [selectedPatient, setSelectedPatient] = useState(patient);
  const [updateForm, setUpdateForm] = useState<boolean>(false);
  const [coveragesFieldUpdates, setCoveragesFieldUpdates] = useState<Coverage[]>([]);
  const patientName = `${selectedPatient?.firstName} ${selectedPatient?.lastName}`;

  const dateStringToDate = (dateString: string) => {
    const [year, month, day] = dateString?.split("-").map(Number);
    const correctedDate = new Date(year, month - 1, day);
    return correctedDate;
  };
  const fallbackCoverageValues = (val: string | undefined) => {
    if (val) {
      return val;
    }
    return "--";
  };

  const { mutate: updatePatientCoverages, loading: updatePatientCoveragesLoading } = useUpdatePatientCoverages({
    id: "",
  });
  const { data: patientCoverageRiskBearingEntityEditsAllowed } =
    useGetPatientCoverageRiskBearingEntityEditsAllowedConfigurationByPayer(selectedPatient?.healthPlanName ?? "");
  const patientCoverageRiskBearingEntityEditsAllowedEnabled = patientCoverageRiskBearingEntityEditsAllowed?.enabled;
  const patientCoverageRiskBearingEntityAllowedValues =
    patientCoverageRiskBearingEntityEditsAllowed?.allowedValues || [];

  // Convert to DropdownOption type
  const patientCoverageRiskBearingEntityAllowedOptions = patientCoverageRiskBearingEntityAllowedValues.map((value) => ({
    id: value,
    value,
  }));

  const payload: UserActivity = {
    event: "PATIENT_COVERAGE_UPDATE",
    stage: "PATIENT_MANAGEMENT",
    type: "INTERACTION",
    interactionAccept: true,
    activityContext: {
      patientId: patientId,
      userId: userId,
    },
    beforeSnapshot: {
      mongoPatientId: patientId,
      coverageFieldUpdates: {},
    },
    afterSnapshot: {
      mongoPatientId: patientId,
      coverageFieldUpdates: coveragesFieldUpdates,
    },
  };
  const trackInteraction = useTrackUserInteraction();
  const trackCreatePatientActivity = async (): Promise<void> => {
    await trackInteraction(payload);
  };

  useEffect(() => {
    if (!patientDataLoading && !selectedPatient && patient) {
      setSelectedPatient(patient);
    }
  }, [patient, patientDataLoading, selectedPatient]);

  useEffect(() => {
    if (updateForm) {
      setSelectedPatient(selectedPatient);
      setUpdateForm(false);
    }
  }, [selectedPatient, updateForm]);

  const updatePatient = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (Boolean(selectedPatient?.memberId)) {
      try {
        if (selectedPatient) {
          setSelectedPatient(await updatePatientCoverages(selectedPatient, { pathParams: { id: selectedPatient.id } }));
          enqueueSnackbar(`Patient successfully updated!`, {
            variant: "success",
            className: editCoveragesStyles.snackbar,
          });
        }
      } catch (e) {
        assertIsApiError(e);
        enqueueSnackbar(`Patient failed to update...`, { variant: "error", className: editCoveragesStyles.snackbar });
        if (e.message !== "Failed to update: Aborted") {
          error(e);
        }
      }
    }
    await trackCreatePatientActivity();
  };
  const handleFieldUpdates = (fieldName: string, newFieldValue: string, idx: number) => {
    setCoveragesFieldUpdates((prev) => {
      const coverageUpdated = [...prev];
      if (!coverageUpdated[idx]) {
        coverageUpdated[idx] = {};
      }
      coverageUpdated[idx] = {
        ...coverageUpdated[idx],
        [fieldName]: newFieldValue,
      };
      return coverageUpdated;
    });
  };

  return (
    <Grid component="form" onSubmit={updatePatient} data-testid="edit-coverages-page">
      <HeaderContainer height={96}>
        <HeaderTextContainer>
          <Typography variant="h3" align="center" textAlign="center">
            {patientName}
          </Typography>
        </HeaderTextContainer>
      </HeaderContainer>
      <FormContainer>
        <Grid container item xs={12} marginLeft={61}>
          <Breadcrumbs data-testid="breadcrumbs-header">
            <Link color="inherit" to={routes.PATIENT_MANAGEMENT} style={{ textDecoration: "none" }}>
              <InlineButton style={{ marginLeft: 0, paddingLeft: 0 }}>Member search</InlineButton>
            </Link>
            <Body2>{patientName}</Body2>
          </Breadcrumbs>
        </Grid>
        <Grid container item xs={12} marginLeft={60} marginTop={2}>
          <Card className={editCoveragesStyles.formInputCard} data-testid="coverages-form-card">
            {canEditCoverage && patientCoverageRiskBearingEntityEditsAllowedEnabled && (
              <Typography variant="h6">Patient details</Typography>
            )}
            {canEditCoverage && patientCoverageRiskBearingEntityEditsAllowedEnabled && (
              <Grid>
                <Grid container item paddingTop={2} paddingBottom={2} spacing={4}>
                  <FormFieldContainer>
                    <SingleSelectDropdown
                      fullWidth
                      label="Risk Bearing Entity"
                      options={patientCoverageRiskBearingEntityAllowedOptions}
                      value={selectedPatient?.riskBearingEntity || ""}
                      onChange={(value) => {
                        if (selectedPatient !== undefined) {
                          let updatedPatient = selectedPatient;
                          if (updatedPatient) {
                            updatedPatient.riskBearingEntity = value;
                          }
                          setSelectedPatient(updatedPatient);
                          setUpdateForm(true);
                          // handlePatientFieldUpdates("riskBearingEntity", value);
                        }
                      }}
                    />
                  </FormFieldContainer>
                </Grid>
              </Grid>
            )}

            <Typography variant="h6">Coverage details</Typography>

            {selectedPatient?.coverages &&
              selectedPatient.coverages.map((coverage, idx) => {
                const shouldShowDivider =
                  selectedPatient?.coverages && idx !== 0 && idx !== selectedPatient?.coverages?.length;

                return (
                  <Grid key={idx}>
                    {shouldShowDivider && <Divider />}
                    <Grid container item paddingTop={2} spacing={4}>
                      <FormFieldContainer>
                        <DateSelect
                          label="Plan start date"
                          maxDate={new Date(Infinity)}
                          allowedInternalErrors={["invalidDate"]}
                          value={dateStringToDate(selectedPatient?.coverages?.[idx]?.planStart || "")}
                          onDateChange={(e) => {
                            let updatedPatient = selectedPatient;
                            let updatedStart = e;
                            if (updatedPatient.coverages && updatedPatient.coverages[idx]) {
                              updatedPatient.coverages[idx].planStart = formattedDate(updatedStart);
                            }
                            setSelectedPatient(updatedPatient);
                            setUpdateForm(true);
                            handleFieldUpdates("planStart", formattedDate(updatedStart), idx);
                          }}
                          TextFieldProps={{
                            fullWidth: true,
                            value: dateStringToDate(selectedPatient?.coverages?.[idx]?.planStart || ""),
                          }}
                        />
                      </FormFieldContainer>
                      <FormFieldContainer>
                        <DateSelect
                          value={dateStringToDate(selectedPatient?.coverages?.[idx]?.planEnd || "")}
                          onDateChange={(e) => {
                            let updatedPatient = selectedPatient;
                            let updatedEnd = e;
                            if (updatedPatient.coverages && updatedPatient.coverages[idx]) {
                              updatedPatient.coverages[idx].planEnd = formattedDate(updatedEnd);
                            }
                            setSelectedPatient(updatedPatient);
                            setUpdateForm(true);
                            handleFieldUpdates("planEnd", formattedDate(updatedEnd), idx);
                          }}
                          label="Plan end date"
                          maxDate={new Date(Infinity)}
                          allowedInternalErrors={["invalidDate"]}
                          TextFieldProps={{
                            fullWidth: true,
                            value: dateStringToDate(selectedPatient?.coverages?.[idx]?.planEnd || ""),
                          }}
                        />
                      </FormFieldContainer>
                      <FormFieldContainer>
                        {!canEditCoverage ? (
                          <TextField
                            fullWidth
                            label="Grouper ID"
                            className={editCoveragesStyles.readOnly}
                            value={fallbackCoverageValues(coverage.grouperId)}
                            InputProps={{
                              className: editCoveragesStyles.readOnlyInputStyles,
                            }}
                          />
                        ) : (
                          <CohereTextField
                            fullWidth
                            label="Grouper ID"
                            value={selectedPatient?.coverages?.[idx]?.grouperId || ""}
                            onChange={(e) => {
                              if (
                                selectedPatient !== undefined &&
                                selectedPatient.coverages &&
                                selectedPatient.coverages[idx]
                              ) {
                                let updatedPatient = selectedPatient;
                                if (updatedPatient.coverages && updatedPatient.coverages[idx]) {
                                  updatedPatient.coverages[idx].grouperId = e.target.value;
                                }
                                setSelectedPatient(updatedPatient);
                                setUpdateForm(true);
                                handleFieldUpdates("grouperId", e.target.value, idx);
                              }
                            }}
                          />
                        )}
                      </FormFieldContainer>
                      {canEditCoverage && patientCoverageRiskBearingEntityEditsAllowedEnabled && (
                        <FormFieldContainer>
                          <SingleSelectDropdown
                            fullWidth
                            label="Risk Bearing Entity"
                            options={patientCoverageRiskBearingEntityAllowedOptions}
                            value={selectedPatient?.coverages?.[idx]?.riskBearingEntity || ""}
                            onChange={(value) => {
                              if (
                                selectedPatient !== undefined &&
                                selectedPatient.coverages &&
                                selectedPatient.coverages[idx]
                              ) {
                                let updatedPatient = selectedPatient;
                                if (updatedPatient.coverages && updatedPatient.coverages[idx]) {
                                  updatedPatient.coverages[idx].riskBearingEntity = value;
                                }
                                setSelectedPatient(updatedPatient);
                                setUpdateForm(true);
                                handleFieldUpdates("riskBearingEntity", value, idx);
                              }
                            }}
                          />
                        </FormFieldContainer>
                      )}
                    </Grid>
                    <Grid container item paddingTop={2} spacing={4}>
                      <FormFieldContainer>
                        <TextField
                          fullWidth
                          label="Health plan"
                          value={fallbackCoverageValues(coverage.healthPlanName)}
                          className={editCoveragesStyles.readOnly}
                          InputProps={{
                            readOnly: true,
                            className: editCoveragesStyles.readOnlyInputStyles,
                          }}
                        />
                      </FormFieldContainer>
                      <FormFieldContainer>
                        <TextField
                          fullWidth
                          label="Plan type"
                          value={fallbackCoverageValues(coverage.planType)}
                          className={editCoveragesStyles.readOnly}
                          InputProps={{
                            readOnly: true,
                            className: editCoveragesStyles.readOnlyInputStyles,
                          }}
                        />
                      </FormFieldContainer>
                      <FormFieldContainer>
                        <TextField
                          fullWidth
                          label="State of issue"
                          value={fallbackCoverageValues(coverage.stateOfIssue)}
                          className={editCoveragesStyles.readOnly}
                          InputProps={{
                            readOnly: true,
                            className: editCoveragesStyles.readOnlyInputStyles,
                          }}
                        />
                      </FormFieldContainer>
                    </Grid>
                    <Grid container item paddingTop={2} spacing={4}>
                      <FormFieldContainer>
                        <TextField
                          fullWidth
                          label="Line of business type"
                          value={fallbackCoverageValues(coverage.lineOfBusinessType)}
                          className={editCoveragesStyles.readOnly}
                          InputProps={{
                            readOnly: true,
                            className: editCoveragesStyles.readOnlyInputStyles,
                            value: fallbackCoverageValues(coverage.lineOfBusinessType),
                          }}
                        />
                      </FormFieldContainer>
                      <FormFieldContainer>
                        <TextField
                          fullWidth
                          label="Line of business description"
                          value={fallbackCoverageValues(convertToTitleCase(coverage.lineOfBusinessDescription || ""))}
                          className={editCoveragesStyles.readOnly}
                          InputProps={{
                            readOnly: true,
                            className: editCoveragesStyles.readOnlyInputStyles,
                            multiline: true,
                            value: fallbackCoverageValues(convertToTitleCase(coverage.lineOfBusinessDescription || "")),
                          }}
                        />
                      </FormFieldContainer>
                    </Grid>
                  </Grid>
                );
              })}
          </Card>
        </Grid>
      </FormContainer>
      <AppBar className={editCoveragesStyles.footer} component="footer" data-testid="edit-coverages-footer">
        <FooterRow>
          <WideButton type="submit" loading={updatePatientCoveragesLoading}>
            Update
          </WideButton>
        </FooterRow>
      </AppBar>
    </Grid>
  );
};
