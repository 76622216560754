import { useFeature } from "@coherehealth/common";
import { Attachment, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Dispatch, SetStateAction } from "react";

const addAttachmentToSpecificRequest = (
  serviceRequests: ServiceRequestResponse[],
  attachment: Attachment
): ServiceRequestResponse[] => {
  if (!attachment.serviceRequest?.id) {
    return serviceRequests;
  }

  return serviceRequests.map((sr) =>
    sr.id === attachment.serviceRequest!.id ? { ...sr, attachments: [...(sr.attachments || []), attachment] } : sr
  );
};

const addAttachmentToAllRequests = (
  serviceRequests: ServiceRequestResponse[],
  attachment: Attachment
): ServiceRequestResponse[] => {
  return serviceRequests.map((sr) => ({
    ...sr,
    attachments: [...(sr.attachments || []), attachment],
  }));
};

export const useServiceRequestsUpdater = (setServiceRequests: Dispatch<SetStateAction<ServiceRequestResponse[]>>) => {
  const multiSingleService = useFeature("multiSingleService");

  const addAttachmentToServiceRequests = (attachment: Attachment) => {
    setServiceRequests((prevServiceRequests) =>
      multiSingleService
        ? addAttachmentToSpecificRequest(prevServiceRequests, attachment)
        : addAttachmentToAllRequests(prevServiceRequests, attachment)
    );
  };

  return { addAttachmentToServiceRequests };
};

export const useServiceRequestUpdater = (setServiceRequest?: Dispatch<SetStateAction<ServiceRequestResponse>>) => {
  const addAttachmentToServiceRequest = (attachment: Attachment) => {
    if (!setServiceRequest) {
      return;
    }

    setServiceRequest((prevServiceRequest) => {
      return {
        ...prevServiceRequest,
        attachments: [...(prevServiceRequest.attachments || []), attachment],
      };
    });
  };

  return { addAttachmentToServiceRequest };
};
