import React from "react";
import { Grid } from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import SelectedPatientInfo from "./SelectedPatientInfo";
import { H6 } from "../Typography";
import { Alert } from "../Alert";
import { Pill } from "../Pill";
import { Tooltip } from "../Tooltip";
import { formatDateStr } from "../../util/DateUtils";
import { authStatusPillVariant, getAuthStatusCopy } from "./ServiceRequestSelectionCard";
import { ClassNameMap } from "@material-ui/styles";
import { AuthCategoryResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { generatePath } from "react-router-dom";
import { useRef } from "react";
import { useIsOverflow } from "../../hooks/useIsOverflow";

export interface SelectionCardHeaderProps {
  classes: ClassNameMap;
  extractClinicalServiceText: (
    clinicalServices?: ServiceRequestResponse["clinicalServices"],
    authCategory?: AuthCategoryResponse
  ) => string;
  serviceRequest: ServiceRequestResponse | null;
  serviceCaseId?: string;
  showPatientAlert: boolean;
}

export function SelectionCardHeader(props: SelectionCardHeaderProps): JSX.Element {
  const { classes, extractClinicalServiceText, serviceCaseId, serviceRequest, showPatientAlert } = props;

  const {
    authCategory,
    authStatus,
    clinicalServices,
    dateCreated,
    delegatedVendor,
    healthPlanName,
    id,
    intakeTimestamp,
    patient,
    pendingReason,
  } = serviceRequest ?? {};

  const textElementRef = useRef<HTMLInputElement | null>(null);

  const { hoverStatus } = useIsOverflow(textElementRef);

  return (
    <>
      {showPatientAlert && (
        <Grid item xs={12}>
          <Alert severity="warning" message="The patient for this service request is different">
            {patient && <SelectedPatientInfo patient={patient!} />}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12} className={classes.gridPadding}>
        <div className={classes.cardHeaderLine1}>
          <Tooltip
            title={extractClinicalServiceText(clinicalServices, authCategory)}
            disableHoverListener={!hoverStatus}
            placement="top"
            classes={{ tooltip: classes.customWidth }}
            data-public
          >
            <div
              ref={textElementRef}
              className={
                hoverStatus
                  ? `${classes.h5BodyContainer} ${classes.hover} ${classes.zeroMargin}`
                  : `${classes.h5BodyContainer}`
              }
              data-public
            >
              <H6 data-public>{extractClinicalServiceText(clinicalServices, authCategory)}</H6>
            </div>
          </Tooltip>
          {!serviceCaseId && (
            <MuiLink
              variant="body2"
              color="primary"
              underline="none"
              href={generatePath("/service_request/:serviceRequestId", { serviceRequestId: id ?? "" })}
              target={"_blank"}
              className={classes.requestDetailsLink}
              data-public
            >
              Request details
            </MuiLink>
          )}
        </div>
        <div>
          {authStatus === "DRAFT" || authStatus === "PENDING_FACILITY_VERIFICATION" ? (
            <Pill
              className={classes.cardHeaderItem}
              label={`Created ${formatDateStr(dateCreated)}`}
              variant="info"
              data-public
            />
          ) : (
            <Pill
              className={classes.cardHeaderItem}
              label={`Submitted ${formatDateStr(intakeTimestamp)}`}
              variant="info"
              data-public
            />
          )}
          {!!authStatus && (
            <Pill
              className={classes.cardHeaderItem}
              label={getAuthStatusCopy(authStatus, pendingReason, delegatedVendor, healthPlanName)}
              variant={authStatusPillVariant[authStatus]}
              data-public
            />
          )}
        </div>
      </Grid>
    </>
  );
}
