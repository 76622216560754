import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState, useContext, useCallback } from "react";

import {
  OptionsDecorator,
  SelectOptionsHook,
  SingleSelectForProviderFacilitySelect,
  useLazyLoadingQueryOptionsHook,
  useFeature,
  MiddleTruncateLabelWithEndornment,
  formatDateToISODate,
} from "@coherehealth/common";
import {
  useGetProvidersOrFacilities,
  Provider,
  useCreateProvider,
  ClinicalService,
  Patient,
  AuthStatus,
  Location,
  useUpdateProvider,
  Facility,
  useUpsertManuallyEnteredProviderOrFacility,
  PracticeCommon,
  UseGetProvidersOrFacilitiesProps,
  useGetTransientServiceRequestRuleActions,
} from "@coherehealth/core-platform-api";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useAuthorized } from "authorization";
import { useSnackbar } from "notistack";
import listInsert from "util/listInsert";
import { canEditPractice } from "util/serviceRequest";

import {
  providerFacilityDropdownRender,
  providerFacilityAddressRenderer,
  providerFacilityTinRenderer,
} from "../../ProviderFacilityDropdownRender";
import ProviderFacilityModal from "../../ProviderFacilityModalManual";
import { RedirectRuleCheckFn, ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { getCurrentCoverage, getPatientHealthPlanName } from "util/patientUtils";
import { FormConfiguration } from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { SuggestionContext } from "../../../AuthBuilder/SuggestionContext";
import { getLocationsForTin, ModalStatus } from "util/providerUtils";

// * Code tag RECENT_PROVIDERS_8B12CDB1
import Recents from "common/RecentProviders";
import { RecentProvidersContext } from "common/RecentProviders/context";
import { convertStringToAuthBuilderWorkflowStep, PriorAuthRequirements } from "components/AuthBuilder/common";
import { isRecommendChangeAction, isRedirectAction, ValidDisplayMessageAction } from "util/rule";
import ServiceRequestFormRedirectModal from "../ServiceRequestFormRedirectModal";

type OptionType = "noResults" | "someResults";
export type ProviderOption = Provider & {
  optionType?: OptionType;
};

export type LocationOption = Location & {
  optionType?: OptionType;
  manuallyEditedAddress?: boolean;
};

export type TinOption = {
  optionType?: OptionType;
  tinManuallyCreated?: boolean;
  id: string;
  val: string;
};

type PracticeUpsertPayload = PracticeCommon & {
  recordType?: "PROVIDER" | "FACILITY";
};

export function getTinWithOptions(tin: string | undefined | null): TinOption | null {
  if (tin === "") {
    return {
      optionType: "noResults",
      tinManuallyCreated: false,
      id: "",
      val: "",
    };
  } else if (tin) {
    return { optionType: undefined, tinManuallyCreated: undefined, id: tin, val: tin };
  }
  return null;
}

export function getTinListWithOptions(tinlist: string[] | undefined) {
  const tinListWithOtions: TinOption[] = [];
  if (tinlist) {
    for (const val of tinlist) {
      tinListWithOtions.push({ optionType: undefined, tinManuallyCreated: undefined, id: val, val: val });
    }
  }

  const manualOption: TinOption = {
    optionType: tinlist ? "someResults" : "noResults",
    tinManuallyCreated: false,
    id: "",
    val: "/TIN-undefined",
  };

  if (manualOption) {
    tinListWithOtions.push(manualOption);
  }
  return tinListWithOtions;
}
export const useProviderStyles = makeStyles((theme) => ({
  root: {
    "& .MuiAutocomplete-inputRoot": {
      color: theme.palette.warning.dark,
      borderColor: theme.palette.warning.dark,
    },
    "& .MuiFormLabel-root:not(.Mui-disabled)": {
      color: theme.palette.warning.dark,
    },
    "& .MuiSelect-selectMenu:not(.Mui-disabled)": {
      border: `1px solid ${theme.palette.warning.dark}`,
      borderRadius: 4,
    },
    "& .MuiFormHelperText-root": {
      color: theme.palette.warning.dark,
    },
  },
  error: {
    "& .MuiAutocomplete-inputRoot": {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
    "& .MuiFormLabel-root:not(.Mui-disabled)": {
      color: theme.palette.error.main,
    },
    "& .MuiSelect-selectMenu:not(.Mui-disabled)": {
      border: `1px solid ${theme.palette.error.main}`,
    },
    "& .MuiFormHelperText-root": {
      color: theme.palette.error.main,
    },
  },
  searchIconButton: {
    position: "absolute",
    top: "20px",
    right: "12px",
  },
}));

function getUseProviders(
  patient: Patient | undefined,
  useCMSProviders: string,
  ignoreTin: boolean,
  selectedLocation: Location | undefined | null // PerformingProviderPracticeAddress
): SelectOptionsHook<ProviderOption> {
  return function useProviders(selectOptionsParams) {
    const today = new Date();
    const healthPlanName = patient ? getPatientHealthPlanName(patient, today) : undefined;

    const patientCoverage = getCurrentCoverage(patient);
    const rpn = patientCoverage?.payerCustomFields?.find((field) => field.fieldName === "RPN")?.valueString;

    const enableProviderAffiliationFiltering = useFeature("enableProviderAffiliationFiltering");
    const externalReferenceId: string | undefined = enableProviderAffiliationFiltering
      ? selectedLocation?.externalReferenceId
      : undefined;

    const preventUserCreatedProviders = useFeature("preventUserCreatedProviders");
    const addManualCreationOption: OptionsDecorator<ProviderOption> | undefined = !preventUserCreatedProviders
      ? (loadedOptions, hasMoreOptions) => {
          if (!hasMoreOptions) {
            const manualOption: ProviderOption = {
              name: "",
              id: "",
              dateCreated: "",
              lastUpdated: "",
            };
            if (!loadedOptions || loadedOptions.length === 0) {
              manualOption.optionType = "noResults";
              return listInsert(loadedOptions || [], 0, manualOption);
            } else {
              manualOption.optionType = "someResults";
              return listInsert(loadedOptions, loadedOptions.length, manualOption);
            }
          }
          return loadedOptions;
        }
      : undefined;

    const deDuplicateManualProviders = useFeature("deDuplicateManualProvidersInSearch");
    const queryParams = {
      healthPlanName: healthPlanName,
      useCMSProviders: useCMSProviders,
      ignoreTin: ignoreTin,
      deDuplicateManualProviders: deDuplicateManualProviders,
      externalReferenceId,
      rpn,
    };

    return useLazyLoadingQueryOptionsHook({
      useGetHook: (args: UseGetProvidersOrFacilitiesProps) => useGetProvidersOrFacilities({ ...args, debounce: 600 }),
      optionsDecorator: addManualCreationOption,
      additionalQueryParams: { ...queryParams, recordType: "PROVIDER" },
      ...selectOptionsParams,
    });
  };
}

export const getAddress = (address: any): string => {
  let finalAddress: string = "";
  if (address) {
    if (address?.line1) {
      finalAddress = `${finalAddress}${address.line1}, `;
    }
    if (address?.line2) {
      finalAddress = `${finalAddress}${address.line2}, `;
    }
    if (address?.city) {
      finalAddress = `${finalAddress}${address.city}, `;
    }
    if (address?.state) {
      finalAddress = `${finalAddress}${address.state} - `;
    }
    if (address?.zipCode) {
      finalAddress = `${finalAddress}${address.zipCode}`;
    }
  }
  return finalAddress;
};

export function getLocationsWithOptions(
  locations: Location[] | null,
  enableManualAddress: boolean,
  selectedTin?: string | null,
  ignoreTin: boolean = false
): LocationOption[] {
  const locationsWithOptions: Array<LocationOption> = [];
  locations?.forEach((location) => {
    locationsWithOptions.push({ ...location, optionType: undefined });
  });
  const manualOption: LocationOption = {
    id: "",
    segments: undefined,
    tin: "",
    address: undefined,
    networks: undefined,
    phoneNumbers: undefined,
    faxNumbers: undefined,
    isOutOfNetwork: false,
    outOfNetworkExceptionReason: "",
    outOfNetworkExceptionComment: "",
    externalReferenceId: "",
  };

  if (locationsWithOptions.length === 0) {
    manualOption.optionType = "noResults";
  } else {
    manualOption.optionType = "someResults";
  }

  if (enableManualAddress && (selectedTin || ignoreTin)) {
    locationsWithOptions.push(manualOption);
  }
  return locationsWithOptions;
}

const getProviderForUpdate = (
  currentProvider: Provider,
  currentTin: string | null | undefined,
  currentAddress: Location | null | undefined,
  modalStatus: ModalStatus,
  payload: PracticeUpsertPayload,
  ignoreTin: boolean = false
): Provider & { recordType: "PROVIDER" | "FACILITY" } => {
  const selLoc = payload.selectedLocation as LocationOption;
  selLoc.manuallyEditedAddress = true;
  if (!currentProvider.tinList) {
    currentProvider.tinList = [];
  }
  if (!currentProvider.locations) {
    currentProvider.locations = [];
  }
  currentProvider.lastUpdated = new Date().toISOString();
  switch (modalStatus) {
    case ModalStatus.AddTin:
      if (selLoc.tin) {
        currentProvider.tinList?.push(selLoc.tin);
        currentProvider.locations?.push(selLoc);
        currentProvider.selectedLocation = selLoc;
      }
      break;
    case ModalStatus.EditTin:
      // remove existing tin from list
      if (currentProvider && selLoc.tin && selLoc && currentTin) {
        let index = currentProvider.tinList.indexOf(currentTin, 0);
        if (index > -1) {
          currentProvider.tinList.splice(index, 1);
        }

        // change location associated with tin
        currentProvider.locations.forEach(function (x) {
          if (x.tin === currentTin) {
            x.tin = selLoc.tin;
          }
        });

        currentProvider.tinList.push(selLoc.tin);
        currentProvider.selectedLocation = selLoc;
      }

      break;
    case ModalStatus.AddAddress:
      if (selLoc.tin || (ignoreTin && selLoc)) {
        currentProvider.locations.push(selLoc);
        currentProvider.selectedLocation = selLoc;
      }
      break;

    case ModalStatus.EditAddress:
      if (currentProvider && (selLoc.tin || ignoreTin) && selLoc) {
        // remove location associated with tin
        const index = currentProvider.locations.findIndex(
          (x) =>
            x.tin === currentTin &&
            x.address?.line1 === currentAddress?.address?.line1 &&
            x.address?.line2 === currentAddress?.address?.line2 &&
            x.address?.state === currentAddress?.address?.state &&
            x.address?.zipCode === currentAddress?.address?.zipCode &&
            x.phoneNumbers?.length === currentAddress?.phoneNumbers?.length &&
            JSON.stringify(x.phoneNumbers?.sort()) === JSON.stringify(currentAddress?.phoneNumbers?.sort()) &&
            x.faxNumbers?.length === currentAddress?.faxNumbers?.length &&
            JSON.stringify(x.faxNumbers?.sort()) === JSON.stringify(currentAddress?.faxNumbers?.sort())
        );
        if (index > -1) {
          currentProvider.locations.splice(index, 1);
        }
        currentProvider.locations.push(selLoc);
        currentProvider.selectedLocation = selLoc;
      }
      break;
  }
  return { ...currentProvider, recordType: payload.recordType || (payload.type as "PROVIDER" | "FACILITY") };
};

export const OrderingProviderSelectManual: FunctionComponent<{
  error: boolean;
  npiError?: boolean;
  tinError?: boolean;
  addressError?: boolean;
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  sameProviders: boolean;
  clinicalService?: ClinicalService;
  patient?: Patient;
  dispatchRedirectRuleCheck: RedirectRuleCheckFn;
  setSameProviders: (b: boolean) => void;
  isCohereFaxForm?: boolean;
  showTinAndAddressField?: boolean;
  formConfiguration?: FormConfiguration;
  showEditOption?: boolean;
  setOrderingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  hideTinField?: boolean;
  disabled?: boolean;
  setPriorAuthRequirements?: Dispatch<SetStateAction<PriorAuthRequirements>>;
}> = ({
  error,
  npiError,
  tinError,
  addressError,
  formContent,
  setFormContent,
  setSameProviders,
  clinicalService,
  patient,
  dispatchRedirectRuleCheck,
  sameProviders,
  isCohereFaxForm,
  showTinAndAddressField,
  formConfiguration,
  showEditOption,
  setOrderingProviderExceptionRequest,
  hideTinField = false,
  disabled,
  setPriorAuthRequirements,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  type modalStatus = ModalStatus;

  const [modalOp, setModalOp] = useState<modalStatus>(ModalStatus.Edit);

  const enableManualProviderInfo: boolean = useFeature("manualProviderInfo");

  const { mutate: createProvider, error: providerCreationError } = useCreateProvider({});

  const { mutate: updateProvider, error: providerUpdateError } = useUpdateProvider({
    id: formContent?.orderingProvider ? formContent?.orderingProvider.id : "",
  });

  const { mutate: upsertManuallyEnteredProviderOrFacility, error: upsertManuallyEnteredProviderOrFacilityError } =
    useUpsertManuallyEnteredProviderOrFacility({});
  const { mutate: getTransientRuleActions } = useGetTransientServiceRequestRuleActions({});
  const { suggestedFormContent } = useContext(SuggestionContext);
  const [redirectModalOpen, setRedirectModalOpen] = useState<boolean>(false);
  const [redirectModalAction, setRedirectModalAction] = useState<ValidDisplayMessageAction>();

  // * Code tag RECENT_PROVIDERS_8B12CDB1
  const { trackingStateClickChangeCheck } = useContext(RecentProvidersContext);

  const fetchRuleActions = async () => {
    if (patient) {
      return await getTransientRuleActions({
        displayTarget: "ORDERING_PROVIDER",
        serviceRequest: {
          patient: patient,
          ...(!!formatDateToISODate(formContent.startDate)
            ? { startDate: formatDateToISODate(formContent.startDate) }
            : {}),
          encounterType: formContent.isInpatient ? "INPATIENT" : "OUTPATIENT",
          semanticProcedureCodes: formContent.procedureCodes,
          primarySemanticDiagnosisCode: formContent.primaryDiagnosisCode ?? undefined,
          secondarySemanticDiagnosisCodes: formContent.secondaryDiagnosisCodes,
          id: formContent.id ?? undefined,
        },
        authStage: convertStringToAuthBuilderWorkflowStep("PROVIDER_DETAILS"),
      });
    }
  };
  const fetchRedirectsAndRecommendChangeFromRuleActions = async () => {
    const ruleActions = await fetchRuleActions();
    const redirect = ruleActions?.find(isRedirectAction);
    const recommendChange = ruleActions?.find(isRecommendChangeAction);
    return { redirect, recommendChange };
  };

  useEffect(() => {
    if (providerCreationError) {
      enqueueSnackbar(providerCreationError.message, { variant: "error" });
    }
  }, [providerCreationError, enqueueSnackbar]);

  useEffect(() => {
    if (providerUpdateError || upsertManuallyEnteredProviderOrFacilityError) {
      enqueueSnackbar(providerUpdateError?.message || upsertManuallyEnteredProviderOrFacilityError?.message, {
        variant: "error",
      });
    }
  }, [providerUpdateError, enqueueSnackbar, upsertManuallyEnteredProviderOrFacilityError]);

  const [orderingProviderModalOpen, setOrderingProviderModalOpen] = useState(false);
  const useCanEditPractice = useAuthorized("EDIT_SERVICE_REQUEST");
  const classes = useProviderStyles();

  const healthPlanName = patient ? getPatientHealthPlanName(patient, new Date()) : undefined;
  const orderingProviderOptionalLabel =
    formConfiguration?.orderingProvider.fieldSpec === "OPTIONAL" ? " (optional)" : "";

  const isOrderProviderNpiOptional = formConfiguration?.orderingProviderNPI?.fieldSpec === "OPTIONAL";

  const updateProviderForModalOp = useCallback(
    async (providerForUpdate, provider) => {
      if (provider.type) {
        providerForUpdate.lastUpdated = new Date().toISOString();
        await updateProvider({ ...providerForUpdate });
      } else if (provider.recordType) {
        await upsertManuallyEnteredProviderOrFacility({
          npi: providerForUpdate.npi || "",
          tinList: providerForUpdate.tinList || [],
          locations: providerForUpdate.locations || [],
          recordType: providerForUpdate.recordType,
        });
      }
    },
    [updateProvider, upsertManuallyEnteredProviderOrFacility]
  );

  const createProviderForModalOp = useCallback(
    async (providerForUpdate, provider) => {
      if (provider.type) {
        providerForUpdate.dateCreated = new Date().toISOString();
        const newProvider = await createProvider({ ...providerForUpdate });
        providerForUpdate.id = newProvider.id;
        providerForUpdate.source = newProvider.source;
      } else if (provider.recordType) {
        const newProvider = await upsertManuallyEnteredProviderOrFacility({
          npi: providerForUpdate.npi || "",
          tinList: providerForUpdate.tinList || [],
          locations: providerForUpdate.locations || [],
          recordType: providerForUpdate.recordType,
        });
        providerForUpdate.id = newProvider.id;
        providerForUpdate.source = newProvider.source;
      }
    },
    [createProvider, upsertManuallyEnteredProviderOrFacility]
  );

  const updateProviderFormContent = useCallback(
    (providerForUpdate) => {
      setFormContent((prev) => ({
        ...prev,
        orderingProviderSelectedTin: providerForUpdate.selectedLocation?.tin,
        orderingProviderSelectedAddress: providerForUpdate.selectedLocation,
        selectedOrderingProvider: providerForUpdate,
        orderingProvider: providerForUpdate,
      }));
      if (sameProviders) {
        setFormContent((prev) => ({
          ...prev,
          performingProvider: providerForUpdate,
          selectedPerformingProvider: providerForUpdate,
          performingProviderSelectedTin: providerForUpdate.selectedLocation?.tin,
          performingProviderSelectedAddress: providerForUpdate.selectedLocation,
        }));
      }
    },
    [setFormContent, sameProviders]
  );

  const handleSaveForExistingProvider = useCallback(
    async (providerForUpdate, provider) => {
      if (formContent?.orderingProvider?.manuallyCreated) {
        await updateProviderForModalOp(providerForUpdate, provider);
      } else {
        providerForUpdate.manuallyCreated = true;
        providerForUpdate.createdByUser = false;
        await createProviderForModalOp(providerForUpdate, provider);
        updateProviderFormContent(providerForUpdate);
      }
    },
    [formContent?.orderingProvider, updateProviderForModalOp, createProviderForModalOp, updateProviderFormContent]
  );

  const handleAddOperation = useCallback(
    async (provider, setEntry) => {
      let retVal: Provider = { id: "", dateCreated: "", lastUpdated: "" };
      if (provider.type) {
        retVal = await createProvider({
          ...provider,
        });
      } else if (provider.recordType) {
        retVal = await upsertManuallyEnteredProviderOrFacility({
          npi: provider.npi || "",
          tinList: provider.tinList || [],
          locations: provider.locations || [],
          recordType: provider.recordType as "PROVIDER" | "FACILITY",
        });
      }
      retVal.type = provider.type;
      retVal.selectedLocation = provider.selectedLocation;
      setFormContent((prev) => ({
        ...prev,
        orderingProvider: retVal,
        orderingProviderSelectedTin: retVal.tinList?.length === 1 ? retVal.tinList[0] : null,
        orderingProviderSelectedAddress: !!retVal.locations ? retVal.locations[0] : null,
        selectedOrderingProvider: retVal,
      }));
      if (sameProviders) {
        setFormContent((prev) => ({
          ...prev,
          performingProvider: retVal,
          selectedPerformingProvider: retVal,
          performingProviderSelectedTin: retVal.selectedLocation?.tin,
          performingProviderSelectedAddress: retVal.selectedLocation,
        }));
      }
      setEntry(retVal);
    },
    [createProvider, upsertManuallyEnteredProviderOrFacility, setFormContent, sameProviders]
  );

  const providerSaveCallback = useCallback(
    async (provider: PracticeUpsertPayload, setEntry: (practice: Provider | Facility | null) => void) => {
      try {
        if (
          modalOp === ModalStatus.AddAddress ||
          modalOp === ModalStatus.EditAddress ||
          modalOp === ModalStatus.AddTin ||
          modalOp === ModalStatus.EditTin
        ) {
          if (formContent?.orderingProvider) {
            const providerForUpdate = getProviderForUpdate(
              formContent?.orderingProvider,
              formContent?.orderingProviderSelectedTin,
              formContent?.orderingProviderSelectedAddress,
              modalOp,
              provider,
              hideTinField
            );
            await handleSaveForExistingProvider(providerForUpdate, provider);
            setEntry(providerForUpdate);
          }
        } else if (modalOp === ModalStatus.Add) {
          await handleAddOperation(provider, setEntry);
        }
      } catch (error) {
        enqueueSnackbar("error occured while updating provider entity", { variant: "error" });
      } finally {
        setSameProviders(false);
      }
    },
    [
      modalOp,
      formContent?.orderingProvider,
      formContent?.orderingProviderSelectedTin,
      formContent?.orderingProviderSelectedAddress,
      hideTinField,
      enqueueSnackbar,
      handleSaveForExistingProvider,
      handleAddOperation,
      setSameProviders,
    ]
  );
  const handleRedirectModalClose = () => {
    setRedirectModalOpen(false);
  };
  return (
    <>
      <ServiceRequestFormRedirectModal
        open={redirectModalOpen}
        onClose={handleRedirectModalClose}
        redirectRuleAction={redirectModalAction}
        objectType={"service change"}
        formContent={formContent}
        setFormContent={setFormContent}
        isHardStop={redirectModalAction && redirectModalAction.actionType === "Redirect"}
        setPriorAuthRequirements={setPriorAuthRequirements}
      />
      <Grid
        item
        style={{
          flex: `1 1 560px`,
          minWidth: "200px",
        }}
      >
        <SingleSelectForProviderFacilitySelect<ProviderOption>
          disablePortal
          autoFilledIcon={
            isCohereFaxForm ||
            (suggestedFormContent?.orderingProvider?.providerEntity
              ? suggestedFormContent.orderingProvider.providerEntity.npi === formContent?.orderingProvider?.npi
              : false)
          }
          markSelectedOptions={false}
          error={error || npiError}
          clearable={formConfiguration?.orderingProviderNPI.fieldSpec === "OPTIONAL"}
          helperText={error ? "Required" : npiError ? "NPI Required" : ""}
          label={`Ordering provider${orderingProviderOptionalLabel}`}
          reactLabel={
            <MiddleTruncateLabelWithEndornment
              leftLabel={"Ordering provider"}
              rightLabel={orderingProviderOptionalLabel}
            />
          }
          emptyLabel={
            <MiddleTruncateLabelWithEndornment
              leftLabel={hideTinField ? "Ordering provider NPI or name" : "Ordering provider NPI, TIN, or name"}
              rightLabel={orderingProviderOptionalLabel}
            />
          }
          useOptions={getUseProviders(patient, "true", hideTinField, null)}
          getOptionLabel={({ name, npi }) => `${name} / NPI - ${npi || (isOrderProviderNpiOptional ? "N/A" : "")}`}
          renderOption={({ name, tinList, npi, optionType }) =>
            providerFacilityDropdownRender(
              name,
              tinList,
              npi,
              optionType,
              undefined,
              hideTinField,
              isOrderProviderNpiOptional
            )
          }
          withEditAdornment={
            canEditPractice(useCanEditPractice, formContent?.orderingProvider || null) && showEditOption
          }
          editAction={() => {
            setModalOp(ModalStatus.Edit);
            setOrderingProviderModalOpen(true);
          }}
          selectedValue={formContent?.orderingProvider || null}
          setSelectedValue={async (provider) => {
            //changes done by PRI but need to verify
            //synchronously mark this as false when a selection is made
            //is calculated elsewhere via useEffects after this update runs
            setOrderingProviderExceptionRequest?.(false);
            if (["noResults", "someResults"].includes(provider?.optionType || "")) {
              setFormContent((prev) => ({
                ...prev,
                orderingProvider: provider, // this is required to show /undefined value
                orderingProviderSelectedTin: undefined,
                orderingProviderSelectedAddress: undefined,
                selectedOrderingProvider: undefined,
              }));
              setModalOp(ModalStatus.Add);
              setOrderingProviderModalOpen(true);
            } else if (provider === null) {
              //clear ordering provider form content if it is nullified by the user
              setFormContent((prev) => {
                return {
                  ...prev,
                  orderingProviderSelectedAddress: null,
                  selectedOrderingProvider: null,
                  orderingProvider: null,
                  orderingProviderSelectedTin: null,
                  orderingProviderOONExceptionRequired: false,
                };
              });
            } else {
              const selectedTin = provider?.tinList?.length === 1 ? provider.tinList[0] : undefined;
              let locationsForTin = getLocationsForTin(selectedTin, provider, hideTinField);
              let selectedLocation: Location = {};
              if (locationsForTin && locationsForTin?.length === 1) {
                selectedLocation = locationsForTin[0];
              }
              if (
                hideTinField ||
                (provider?.source === "CMS" && !provider?.manuallyCreated && !selectedLocation.address)
              ) {
                if (provider?.locations && provider.locations.length > 0) {
                  selectedLocation = provider.locations[0];
                }
              }
              const selectedOrderingProvider: Provider = {
                ...provider,
                selectedLocation: selectedLocation,
                type: "PROVIDER",
              } as Provider;
              setFormContent((prev) => ({
                ...prev,
                orderingProvider: selectedOrderingProvider,
                orderingProviderSelectedTin: selectedTin,
                orderingProviderSelectedAddress: selectedLocation,
                selectedOrderingProvider: selectedOrderingProvider,
              }));
              if (selectedOrderingProvider?.npi) {
                if (patient) {
                  const { redirect, recommendChange } = await fetchRedirectsAndRecommendChangeFromRuleActions();
                  if (redirect || recommendChange) {
                    setRedirectModalOpen(true);
                    setRedirectModalAction(redirect || recommendChange);
                  }
                }
              }
            }
            // * Code tag RECENT_PROVIDERS_8B12CDB1
            if (trackingStateClickChangeCheck) {
              trackingStateClickChangeCheck("OrderingProvider");
            }

            setSameProviders(false);
          }}
          data-testid="requesting-provider"
          dataPublic={true}
          disabled={disabled}
        />
      </Grid>
      {showTinAndAddressField && (
        <Grid
          item
          container
          spacing={2}
          style={{
            flex: `1 1 50%`,
          }}
        >
          {!hideTinField && (
            <Grid item style={{ flex: `1 1 auto`, minWidth: "167px" }}>
              <SingleSelectForProviderFacilitySelect<TinOption>
                disablePortal
                label={"TIN"}
                clearable={formConfiguration?.orderingProviderTIN.fieldSpec === "OPTIONAL"}
                markSelectedOptions={false}
                withEditAdornment={
                  Boolean(formContent?.orderingProvider?.manuallyCreated) &&
                  Boolean(formContent?.orderingProviderSelectedTin) &&
                  Boolean(formContent?.orderingProviderSelectedTin !== "N/A")
                }
                editAction={() => {
                  setModalOp(ModalStatus.EditTin);
                  setOrderingProviderModalOpen(true);
                }}
                disabled={!Boolean(formContent?.orderingProvider) || disabled}
                setSelectedValue={async (tin) => {
                  if (tin && tin.id !== "") {
                    //this code reduced flickering in screen
                    // we need to set the tin and address releated to tin
                    setFormContent((prev) => ({
                      ...prev,
                      orderingProviderSelectedTin: tin.id,
                    }));

                    let locationsForTin = getLocationsForTin(tin.id, formContent?.orderingProvider);
                    let selectedLocation: Location = {};
                    if (locationsForTin && locationsForTin?.length === 1) {
                      selectedLocation = locationsForTin[0];
                    }

                    const selectedOrderingProvider: Provider = {
                      ...formContent?.orderingProvider,
                      selectedLocation: selectedLocation.address ? selectedLocation : undefined,
                    } as Provider;

                    setFormContent((prev) => ({
                      ...prev,
                      orderingProviderSelectedAddress: selectedLocation.address ? selectedLocation : undefined,
                      selectedOrderingProvider: selectedOrderingProvider,
                      orderingProvider: selectedOrderingProvider,
                    }));
                  } else if (["noResults", "someResults"].includes(tin?.optionType || "")) {
                    const orderingProviderCalc: Provider = {
                      ...formContent?.orderingProvider,
                      selectedLocation: undefined, // when tin is empty then we need location as empty
                    } as Provider;
                    setFormContent((prev) => ({
                      ...prev,
                      orderingProviderSelectedTin: undefined,
                      orderingProviderSelectedAddress: undefined,
                      selectedOrderingProvider: orderingProviderCalc,
                      orderingProvider: orderingProviderCalc,
                    }));
                    setModalOp(ModalStatus.AddTin);
                    setOrderingProviderModalOpen(true);
                  }

                  // * Code tag RECENT_PROVIDERS_8B12CDB1
                  if (trackingStateClickChangeCheck) {
                    trackingStateClickChangeCheck("OrderingProvider");
                  }

                  setSameProviders(false);
                }}
                helperText={tinError && "Required"}
                useOptions={() => ({
                  options: getTinListWithOptions(formContent?.orderingProvider?.tinList),
                  optionsLoading: false,
                  filterOptions: (options, state) =>
                    options.filter((option) => option.val.toLowerCase().includes(state.inputValue.toLowerCase())),
                })}
                className={tinError ? classes.error : undefined}
                data-testid="requesting--ordering-provider-tin"
                selectedValue={getTinWithOptions(
                  formContent?.orderingProviderSelectedTin ||
                    formContent?.selectedOrderingProvider?.selectedLocation?.tin
                )}
                error={tinError}
                getOptionLabel={({ val }) => val}
                renderOption={({ id, val, optionType }) => providerFacilityTinRenderer(id, val, optionType)}
                dataPublic={false}
              />
            </Grid>
          )}

          <Grid item style={{ flex: `1 1 60%`, minWidth: "200px" }}>
            <SingleSelectForProviderFacilitySelect<LocationOption>
              disablePortal
              label={"Address"}
              clearable={formConfiguration?.orderingProviderAddress.fieldSpec === "OPTIONAL"}
              disabled={
                !(
                  Boolean(formContent?.orderingProvider) &&
                  (hideTinField ||
                    Boolean(formContent?.orderingProviderSelectedTin) ||
                    (formContent?.orderingProvider?.source === "CMS" &&
                      !formContent?.orderingProvider?.manuallyCreated))
                ) || disabled
              }
              markSelectedOptions={false}
              useOptions={() => ({
                options: getLocationsWithOptions(
                  getLocationsForTin(
                    formContent?.orderingProviderSelectedTin,
                    formContent?.orderingProvider,
                    hideTinField
                  ),
                  enableManualProviderInfo,
                  formContent?.orderingProviderSelectedTin,
                  hideTinField
                ),
                optionsLoading: false,
                filterOptions: (options, state) =>
                  options.filter(
                    (option) =>
                      option?.address?.city?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                      option?.address?.line1?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                      option?.address?.line2?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                      option?.address?.state?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                      option?.address?.zipCode?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                      option?.optionType === "noResults" ||
                      option?.optionType === "someResults"
                  ),
              })}
              withEditAdornment={
                Boolean(formContent?.orderingProvider?.manuallyCreated) &&
                Boolean(formContent?.orderingProviderSelectedAddress) &&
                Boolean(formContent?.orderingProviderSelectedAddress?.manuallyEditedAddress)
              }
              selectedValue={
                formContent?.orderingProviderSelectedAddress ||
                formContent?.selectedOrderingProvider?.selectedLocation ||
                null
              }
              autoFilledIcon={isCohereFaxForm}
              helperText={addressError && "Required"}
              getOptionLabel={({ address }) => getAddress(address)}
              editAction={() => {
                setModalOp(ModalStatus.EditAddress);
                setOrderingProviderModalOpen(true);
              }}
              setSelectedValue={(location) => {
                let orderingProviderCalc: Provider;
                if (["noResults", "someResults"].includes(location?.optionType || "")) {
                  orderingProviderCalc = {
                    ...formContent?.orderingProvider,
                    selectedLocation: undefined,
                    type: "PROVIDER",
                  } as Provider;
                  setFormContent((prev) => ({
                    ...prev,
                    orderingProviderSelectedAddress: undefined,
                    selectedOrderingProvider: orderingProviderCalc,
                    orderingProvider: orderingProviderCalc,
                  }));
                  setModalOp(ModalStatus.AddAddress);
                  setOrderingProviderModalOpen(true);
                } else {
                  orderingProviderCalc = {
                    ...formContent?.orderingProvider,
                    selectedLocation: location,
                    type: "PROVIDER",
                  } as Provider;
                  setFormContent((prev) => ({
                    ...prev,
                    orderingProviderSelectedAddress: location,
                    selectedOrderingProvider: orderingProviderCalc,
                    orderingProvider: orderingProviderCalc,
                  }));
                }

                // * Code tag RECENT_PROVIDERS_8B12CDB1
                if (trackingStateClickChangeCheck) {
                  trackingStateClickChangeCheck("OrderingProvider");
                }

                setSameProviders(false);
              }}
              error={addressError}
              renderOption={({ address, optionType }) => providerFacilityAddressRenderer(address, optionType)}
              className={addressError ? classes.error : undefined}
              data-testid="requesting-provider-address"
              dataPublic={true}
            />
          </Grid>
        </Grid>
      )}

      {/* Code tag RECENT_PROVIDERS_8B12CDB1 */}
      <Grid item xs={12} style={{ padding: 0 }}>
        <Recents
          bucketName="OrderingProvider"
          setFormContent={setFormContent}
          setSameProviders={setSameProviders}
          orderingProviderId={formContent?.orderingProvider?.id || formContent?.orderingProvider?.npi}
          performingProviderId={formContent?.performingProvider?.id || formContent?.performingProvider?.npi}
          healthPlan={healthPlanName}
          fetchRedirectsAndRecommendChangeFromRuleActions={fetchRedirectsAndRecommendChangeFromRuleActions}
          setRedirectModalOpen={setRedirectModalOpen}
          setRedirectModalAction={setRedirectModalAction}
        />
      </Grid>
      <ProviderFacilityModal
        open={orderingProviderModalOpen}
        modalType="orderingProvider"
        patientHealthPlan={healthPlanName ? healthPlanName : undefined}
        modalStatus={modalOp}
        savedPractice={formContent?.orderingProvider}
        setSelection={async (params: PracticeUpsertPayload, practice: Provider | Facility) => {
          if (modalOp === ModalStatus.Edit) {
            let retVal: Provider = { id: "", dateCreated: "", lastUpdated: "" };
            if (params.type) {
              retVal = await updateProvider({
                id: practice?.id,
                dateCreated: practice?.dateCreated,
                lastUpdated: practice?.lastUpdated,
                ...params,
              });
            } else if (params.recordType) {
              retVal = await upsertManuallyEnteredProviderOrFacility({
                id: practice.id,
                npi: params.npi || "",
                tinList: params.tinList || [],
                locations: params.locations || [],
                recordType: params.recordType,
              });
            }
            retVal.type = params?.type;
            retVal.selectedLocation = params?.selectedLocation;
            setFormContent((prev) => ({
              ...prev,
              orderingProvider: retVal,
              selectedOrderingProvider: retVal,
              orderingProviderSelectedTin: retVal.tinList ? retVal.tinList[retVal.tinList.length - 1] : null,
              orderingProviderSelectedAddress: retVal.locations ? retVal.locations[retVal.locations.length - 1] : null,
            }));
            if (sameProviders) {
              setFormContent((prev) => ({
                ...prev,
                performingProvider: retVal,
                selectedPerformingProvider: retVal,
                performingProviderSelectedTin: retVal.tinList ? retVal.tinList[retVal.tinList.length - 1] : null,
                performingProviderSelectedAddress: retVal.locations
                  ? retVal.locations[retVal.locations.length - 1]
                  : null,
              }));
            }
            setSameProviders(false);
          }
        }}
        onClose={() => {
          setOrderingProviderModalOpen(false);
        }}
        onSave={providerSaveCallback}
        sameProviders={sameProviders}
        setBothProviders={undefined}
        selectedTin={formContent?.orderingProviderSelectedTin ? formContent?.orderingProviderSelectedTin : undefined}
        selectedLocation={
          formContent?.orderingProviderSelectedAddress ||
          formContent?.selectedOrderingProvider?.selectedLocation ||
          null
        }
        hideTinField={hideTinField && formConfiguration?.orderingProviderTIN?.fieldSpec !== "OMIT"}
      />
    </>
  );
};

export const PerformingProviderSelectManual: FunctionComponent<{
  providerError: boolean;
  npiError?: boolean;
  tinError?: boolean;
  addressError?: boolean;
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  sameProviders: boolean;
  setSameProviders: (b: boolean) => void;
  isCohereFaxForm?: boolean;
  authStatus: AuthStatus;
  initialPerformingProvider?: Provider;
  initialPerformingProviderTin?: string;
  isOptional: boolean;
  patient?: Patient;
  showAddressField?: boolean;
  formConfiguration?: FormConfiguration;
  hideTinField?: boolean;
  showEditOption?: boolean;
  setPerformingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
}> = ({
  providerError,
  npiError,
  tinError,
  addressError,
  formContent,
  setFormContent,
  sameProviders,
  setSameProviders,
  isCohereFaxForm,
  isOptional,
  patient,
  showAddressField,
  formConfiguration,
  hideTinField = false,
  showEditOption,
  setPerformingProviderExceptionRequest,
  disabled,
}) => {
  type modalStatus = ModalStatus;

  const [modalOp, setModalOp] = useState<modalStatus>(ModalStatus.Edit);

  const enableManualProviderInfo: boolean = useFeature("manualProviderInfo");

  const [performingProviderModalOpen, setPerformingProviderModalOpen] = useState(false);

  const { mutate: createProvider, error: providerCreationError } = useCreateProvider({});

  const { mutate: updateProvider } = useUpdateProvider({
    id: formContent?.performingProvider ? formContent?.performingProvider.id : "",
  });

  const { mutate: upsertManuallyEnteredProviderOrFacility } = useUpsertManuallyEnteredProviderOrFacility({});

  const { suggestedFormContent } = useContext(SuggestionContext);

  // * Code tag RECENT_PROVIDERS_8B12CDB1
  const { trackingStateClickChangeCheck } = useContext(RecentProvidersContext);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useProviderStyles();
  const useCanEditPractice = useAuthorized("EDIT_SERVICE_REQUEST");

  useEffect(() => {
    if (providerCreationError) {
      enqueueSnackbar(providerCreationError.message, { variant: "error" });
    }
  }, [providerCreationError, enqueueSnackbar]);

  const healthPlanName = patient ? getPatientHealthPlanName(patient, new Date()) : undefined;

  const isPerformingProviderNpiOptional = formConfiguration?.performingProviderNPI?.fieldSpec === "OPTIONAL";

  return (
    <>
      <Grid
        item
        style={{
          flex: `1 1 560px`,
          minWidth: "200px",
        }}
      >
        <SingleSelectForProviderFacilitySelect<ProviderOption>
          disablePortal
          clearable={formConfiguration?.performingProviderNPI.fieldSpec === "OPTIONAL"}
          error={providerError || npiError}
          autoFilledIcon={
            isCohereFaxForm ||
            (suggestedFormContent?.performingProvider?.providerEntity
              ? suggestedFormContent.performingProvider.providerEntity.npi === formContent?.performingProvider?.npi
              : false)
          }
          markSelectedOptions={false}
          helperText={providerError ? "Required" : npiError ? "NPI Required" : ""}
          label={`Performing provider or attending provider${isOptional ? " (optional)" : ""}`}
          reactLabel={
            <MiddleTruncateLabelWithEndornment
              leftLabel={"Performing provider or attending provider"}
              rightLabel={isOptional ? "(optional)" : ""}
            />
          }
          emptyLabel={
            <MiddleTruncateLabelWithEndornment
              leftLabel={
                hideTinField
                  ? "Performing or attending provider NPI or name"
                  : "Performing or attending provider NPI, TIN, or name"
              }
              rightLabel={isOptional ? "(optional)" : ""}
            />
          }
          useOptions={getUseProviders(
            patient,
            "true",
            hideTinField,
            formContent.selectedPerformingProviderPracticeAddress
          )}
          getOptionLabel={({ name, npi }) => `${name} / NPI - ${npi || (isPerformingProviderNpiOptional ? "N/A" : "")}`}
          renderOption={({ name, tinList, npi, optionType }) =>
            providerFacilityDropdownRender(
              name,
              tinList,
              npi,
              optionType,
              (tin: string | null) => setFormContent({ ...formContent, performingProviderSelectedTin: tin }),
              hideTinField,
              isPerformingProviderNpiOptional
            )
          }
          withEditAdornment={
            canEditPractice(useCanEditPractice, formContent?.performingProvider || null) &&
            !sameProviders &&
            showEditOption
          }
          editAction={() => {
            setModalOp(ModalStatus.Edit);
            setPerformingProviderModalOpen(true);
          }}
          selectedValue={formContent?.performingProvider || null}
          setSelectedValue={async (provider) => {
            //synchronously mark this as false when a selection is made
            //is calculated elsewhere via useEffects after this update runs
            setPerformingProviderExceptionRequest?.(false);
            const selectedTin = provider?.tinList?.length === 1 ? provider.tinList[0] : undefined;
            let locationsForTin = getLocationsForTin(selectedTin, provider, hideTinField);

            let selectedLocation: Location = {};
            if (locationsForTin && locationsForTin?.length === 1) {
              selectedLocation = locationsForTin[0];
            }
            if (
              hideTinField ||
              (provider?.source === "CMS" && !provider?.manuallyCreated && !selectedLocation.address)
            ) {
              if (provider?.locations && provider.locations.length > 0) {
                selectedLocation = provider.locations[0];
              }
            }
            // Removed fallback for fetching locations, and address for a provider if not present
            if (["noResults", "someResults"].includes(provider?.optionType || "")) {
              setFormContent((prev) => ({
                ...prev,
                performingProvider: provider,
                performingProviderSelectedTin: undefined,
                performingProviderSelectedAddress: undefined,
                selectedPerformingProvider: undefined,
              }));
              setModalOp(ModalStatus.Add);
              setPerformingProviderModalOpen(true);
            } else if (provider === null) {
              //clear provider form content if it is nullified by the user
              setFormContent((prev) => {
                return {
                  ...prev,
                  performingProviderSelectedAddress: null,
                  selectedPerformingProvider: null,
                  performingProvider: null,
                  performingProviderSelectedTin: null,
                  performingProviderOONExceptionRequired: false,
                };
              });
            } else {
              const performingProviderCalc: Provider = {
                ...provider,
                selectedLocation: selectedLocation,
                type: "PROVIDER",
              } as Provider;
              setFormContent((prev) => ({
                ...prev,
                performingProvider: provider,
                performingProviderSelectedTin: selectedTin,
                performingProviderSelectedAddress: selectedLocation.address ? selectedLocation : undefined,
                selectedPerformingProvider: performingProviderCalc,
              }));
            }

            // * Code tag RECENT_PROVIDERS_8B12CDB1
            if (trackingStateClickChangeCheck) {
              trackingStateClickChangeCheck("PerformingProvider");
            }

            setSameProviders(false);
          }}
          data-testid="performing-provider-npi"
          dataPublic={true}
          disabled={disabled}
        />
      </Grid>
      {(showAddressField || !hideTinField) && (
        <Grid
          item
          container
          spacing={2}
          style={{
            flex: `1 1 50%`,
          }}
        >
          {!hideTinField && (
            <Grid item style={{ flex: `1 1 auto`, minWidth: "167px" }}>
              <SingleSelectForProviderFacilitySelect<TinOption>
                disablePortal
                label={"TIN"}
                clearable={formConfiguration?.performingProviderTIN.fieldSpec === "OPTIONAL"}
                markSelectedOptions={false}
                disabled={!Boolean(formContent?.performingProvider) || disabled}
                withEditAdornment={
                  Boolean(formContent?.performingProvider?.manuallyCreated) &&
                  Boolean(formContent?.performingProviderSelectedTin) &&
                  Boolean(formContent?.performingProviderSelectedTin !== "N/A")
                }
                editAction={() => {
                  setModalOp(ModalStatus.EditTin);
                  setPerformingProviderModalOpen(true);
                }}
                helperText={tinError && "Required"}
                setSelectedValue={async (tin) => {
                  if (tin && tin?.id !== "") {
                    //this code reduced flickering in screen
                    setFormContent((prev) => ({
                      ...prev,
                      performingProviderSelectedTin: tin.id,
                    }));
                    let locationsForTin = getLocationsForTin(tin.id, formContent?.performingProvider);
                    let selectedLocation: Location = {};
                    if (locationsForTin && locationsForTin?.length === 1) {
                      selectedLocation = locationsForTin[0];
                    }

                    const selectedPerformingProvider: Provider = {
                      ...formContent?.performingProvider,
                      selectedLocation: selectedLocation.address ? selectedLocation : undefined,
                    } as Provider;

                    setFormContent((prev) => ({
                      ...prev,
                      performingProviderSelectedAddress: selectedLocation.address ? selectedLocation : undefined,
                      selectedPerformingProvider: selectedPerformingProvider,
                      performingProvider: selectedPerformingProvider,
                    }));
                  } else if (["noResults", "someResults"].includes(tin?.optionType || "")) {
                    const performingProviderCalc: Provider = {
                      ...formContent?.performingProvider,
                      selectedLocation: undefined,
                    } as Provider;
                    setFormContent((prev) => ({
                      ...prev,
                      performingProviderSelectedTin: undefined,
                      performingProviderSelectedAddress: undefined,
                      selectedPerformingProvider: performingProviderCalc,
                      performingProvider: performingProviderCalc,
                    }));
                    setModalOp(ModalStatus.AddTin);
                    setPerformingProviderModalOpen(true);
                  }

                  // * Code tag RECENT_PROVIDERS_8B12CDB1
                  if (trackingStateClickChangeCheck) {
                    trackingStateClickChangeCheck("PerformingProvider");
                  }

                  setSameProviders(false);
                }}
                //options={formContent?.PerformingProvider?.tinList?.map((val) => ({ id: val, label: val }))}
                useOptions={() => ({
                  options: getTinListWithOptions(formContent?.performingProvider?.tinList),
                  optionsLoading: false,
                  filterOptions: (options, state) =>
                    options.filter((option) => option.val.toLowerCase().includes(state.inputValue.toLowerCase())),
                })}
                className={tinError ? classes.error : undefined}
                data-testid="requesting-provider-tin"
                selectedValue={getTinWithOptions(
                  formContent?.performingProviderSelectedTin ||
                    formContent?.selectedPerformingProvider?.selectedLocation?.tin
                )}
                error={tinError}
                getOptionLabel={({ val }) => val}
                renderOption={({ id, val, optionType }) => providerFacilityTinRenderer(id, val, optionType)}
                dataPublic={false}
              />
            </Grid>
          )}
          {showAddressField && (
            <Grid item style={{ flex: `1 1 60%`, minWidth: "200px" }}>
              <SingleSelectForProviderFacilitySelect<LocationOption>
                disablePortal
                label={"Address"}
                clearable={formConfiguration?.performingProviderAddress.fieldSpec === "OPTIONAL"}
                disabled={
                  !(
                    Boolean(formContent?.performingProvider) &&
                    (hideTinField ||
                      Boolean(formContent?.performingProviderSelectedTin) ||
                      (formContent?.performingProvider?.source === "CMS" &&
                        !formContent?.performingProvider?.manuallyCreated))
                  ) || disabled
                }
                markSelectedOptions={false}
                withEditAdornment={
                  Boolean(formContent?.performingProvider?.manuallyCreated) &&
                  Boolean(formContent?.performingProviderSelectedAddress) &&
                  Boolean(formContent?.performingProviderSelectedAddress?.manuallyEditedAddress)
                }
                useOptions={() => ({
                  options: getLocationsWithOptions(
                    getLocationsForTin(
                      formContent?.performingProviderSelectedTin,
                      formContent?.performingProvider,
                      hideTinField
                    ),
                    enableManualProviderInfo,
                    formContent?.performingProviderSelectedTin,
                    hideTinField
                  ),
                  optionsLoading: false,
                  filterOptions: (options, state) =>
                    options.filter(
                      (option) =>
                        option?.address?.city?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.line1?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.line2?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.state?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.zipCode?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.optionType === "noResults" ||
                        option?.optionType === "someResults"
                    ),
                })}
                selectedValue={
                  formContent?.performingProviderSelectedAddress ||
                  formContent?.selectedPerformingProvider?.selectedLocation ||
                  null
                }
                autoFilledIcon={isCohereFaxForm}
                helperText={addressError && "Required"}
                getOptionLabel={({ address }) => getAddress(address)}
                editAction={() => {
                  setModalOp(ModalStatus.EditAddress);
                  setPerformingProviderModalOpen(true);
                }}
                setSelectedValue={(location) => {
                  if (["noResults", "someResults"].includes(location?.optionType || "")) {
                    const performingProviderCalc: Provider = {
                      ...formContent?.performingProvider,
                      selectedLocation: undefined,
                      type: "PROVIDER",
                    } as Provider;
                    setFormContent((prev) => ({
                      ...prev,
                      performingProviderSelectedAddress: undefined,
                      selectedPerformingProvider: performingProviderCalc,
                      performingProvider: performingProviderCalc,
                    }));
                    setModalOp(ModalStatus.AddAddress);
                    setPerformingProviderModalOpen(true);
                  } else {
                    const performingProviderCalc: Provider = {
                      ...formContent?.performingProvider,
                      selectedLocation: location,
                      type: "PROVIDER",
                    } as Provider;
                    setFormContent((prev) => ({
                      ...prev,
                      performingProviderSelectedAddress: { ...prev.performingProviderSelectedAddress, ...location },
                      selectedPerformingProvider: performingProviderCalc,
                      performingProvider: performingProviderCalc,
                    }));
                  }

                  // * Code tag RECENT_PROVIDERS_8B12CDB1
                  if (trackingStateClickChangeCheck) {
                    trackingStateClickChangeCheck("PerformingProvider");
                  }

                  setSameProviders(false);
                }}
                error={addressError}
                className={addressError ? classes.error : undefined}
                renderOption={({ address, optionType }) => providerFacilityAddressRenderer(address, optionType)}
                data-testid="performing-provider-address"
                dataPublic={true}
              />
            </Grid>
          )}
        </Grid>
      )}

      {/* Code tag RECENT_PROVIDERS_8B12CDB1 */}
      <Grid item xs={12} style={{ padding: 0 }}>
        <Recents
          bucketName="PerformingProvider"
          setFormContent={setFormContent}
          setSameProviders={setSameProviders}
          orderingProviderId={formContent?.orderingProvider?.id || formContent?.orderingProvider?.npi}
          performingProviderId={formContent?.performingProvider?.id || formContent?.performingProvider?.npi}
          healthPlan={healthPlanName}
        />
      </Grid>
      <ProviderFacilityModal
        open={performingProviderModalOpen}
        modalType="performingProvider"
        modalStatus={modalOp}
        patientHealthPlan={healthPlanName ? healthPlanName : undefined}
        savedPractice={formContent?.performingProvider}
        setSelection={async (params: PracticeUpsertPayload, practice: Provider | Facility) => {
          if (modalOp === ModalStatus.Edit) {
            let retVal: Provider = { id: "", dateCreated: "", lastUpdated: "" };
            if (params.type) {
              retVal = await updateProvider({
                id: practice?.id,
                dateCreated: practice?.dateCreated,
                lastUpdated: practice?.lastUpdated,
                ...params,
              });
            } else if (params.recordType) {
              retVal = await upsertManuallyEnteredProviderOrFacility({
                npi: params.npi || "",
                tinList: params.tinList || [],
                locations: params.locations || [],
                recordType: params.recordType,
              });
            }
            retVal.type = practice.type || params.recordType;
            retVal.selectedLocation = practice.selectedLocation;
            setFormContent((prev) => ({
              ...prev,
              performingProvider: retVal,
              selectedPerformingProvider: retVal,
              performingProviderSelectedTin: retVal.tinList ? retVal.tinList[retVal.tinList.length - 1] : null,
              performingProviderSelectedAddress: retVal.locations
                ? retVal.locations[retVal.locations.length - 1]
                : null,
            }));
          }
        }}
        setSelectedTin={undefined}
        onClose={() => {
          setPerformingProviderModalOpen(false);
        }}
        onSave={async (provider: PracticeUpsertPayload, setEntry: (practice: Provider | Facility | null) => void) => {
          if (
            modalOp === ModalStatus.AddAddress ||
            modalOp === ModalStatus.EditAddress ||
            modalOp === ModalStatus.AddTin ||
            modalOp === ModalStatus.EditTin
          ) {
            try {
              if (formContent?.performingProvider) {
                const providerForUpdate = getProviderForUpdate(
                  formContent?.performingProvider,
                  formContent?.performingProviderSelectedTin,
                  formContent?.performingProviderSelectedAddress,
                  modalOp,
                  provider,
                  hideTinField
                );
                //manual record add/update a tin/address
                if (formContent?.performingProvider.manuallyCreated) {
                  if (provider.type) {
                    providerForUpdate.lastUpdated = new Date().toISOString();
                    await updateProvider({ ...providerForUpdate });
                  } else if (provider.recordType) {
                    await upsertManuallyEnteredProviderOrFacility({
                      npi: providerForUpdate.npi || "",
                      tinList: providerForUpdate.tinList || [],
                      locations: providerForUpdate.locations || [],
                      recordType: providerForUpdate.recordType as "PROVIDER" | "FACILITY",
                    });
                  }
                } else {
                  //create a manual record and then switch
                  providerForUpdate.manuallyCreated = true;
                  providerForUpdate.createdByUser = false;
                  if ("type" in provider && provider?.type) {
                    providerForUpdate.dateCreated = new Date().toISOString();
                    const newProvider = await createProvider({ ...providerForUpdate });
                    providerForUpdate.id = newProvider.id;
                    providerForUpdate.source = newProvider.source;
                  } else if ("recordType" in provider && provider?.recordType) {
                    const newProvider = await upsertManuallyEnteredProviderOrFacility({
                      npi: providerForUpdate.npi || "",
                      tinList: providerForUpdate.tinList || [],
                      locations: providerForUpdate.locations || [],
                      recordType: providerForUpdate.recordType as "PROVIDER" | "FACILITY",
                    });
                    providerForUpdate.id = newProvider.id;
                    providerForUpdate.source = newProvider.source;
                  }
                }
                setFormContent((prev) => ({
                  ...prev,
                  performingProviderSelectedTin: providerForUpdate.selectedLocation?.tin,
                  performingProviderSelectedAddress: providerForUpdate.selectedLocation,
                  selectedPerformingProvider: providerForUpdate,
                  performingProvider: providerForUpdate,
                }));
                setEntry(providerForUpdate);
              }
            } catch (error) {
              enqueueSnackbar("error occured while updating provider entity", { variant: "error" });
            }
          } else if (modalOp === ModalStatus.Add) {
            let retVal: Provider = { id: "", dateCreated: "", lastUpdated: "" };
            if ("type" in provider && provider?.type) {
              retVal = await createProvider({
                ...provider,
              });
              retVal.type = provider.type;
              retVal.selectedLocation = provider.selectedLocation;
            } else if ("recordType" in provider && provider?.recordType) {
              retVal = await upsertManuallyEnteredProviderOrFacility({
                npi: provider.npi || "",
                tinList: provider.tinList || [],
                locations: provider.locations || [],
                recordType: provider.recordType as "PROVIDER" | "FACILITY",
              });
            }
            retVal.type = provider.type || provider.recordType;
            retVal.selectedLocation = provider.selectedLocation;
            setFormContent((prev) => ({
              ...prev,
              performingProvider: retVal,
              performingProviderSelectedTin: retVal.tinList?.length === 1 ? retVal.tinList[0] : null,
              performingProviderSelectedAddress: !!retVal.locations ? retVal.locations[0] : null,
              selectedPerformingProvider: retVal,
            }));
            setEntry(retVal);
            setSameProviders(false);
          }
        }}
        sameProviders={undefined}
        //todo: please check this functionality
        setBothProviders={undefined}
        selectedTin={
          formContent?.performingProviderSelectedTin ? formContent?.performingProviderSelectedTin : undefined
        }
        selectedLocation={
          formContent?.performingProviderSelectedAddress ||
          formContent?.selectedPerformingProvider?.selectedLocation ||
          null
        }
        hideTinField={hideTinField && formConfiguration?.performingProviderTIN?.fieldSpec !== "OMIT"}
      />
    </>
  );
};
