import React from "react";
import Grid from "@material-ui/core/Grid";
import config from "api/config";
import { AttachmentTypeLabel } from "../ServiceRequest/ReadonlyDetail/FormElements";
import { Body1, Caption, CopyToClipboard, formatDateStrWithTz, Pill } from "@coherehealth/common";
import { FileDownload, FileView } from "../AddAttachments";
import SingleAttachmentViewer from "components/SingleAttachmentViewer/SingleAttachmentViewer";
import { Attachment } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import { useAuthorized } from "authorization";
interface Props {
  attachment: Attachment;
  stage?: string;
  singleAttachmentViewerRedesign?: boolean;
  appealId?: string;
}

export default function AttachmentSummary({ attachment, stage, singleAttachmentViewerRedesign, appealId }: Props) {
  //currently handles either serviceRequest or referralRequest attachments
  const downloadAttachmentPath = attachment?.referralRequest
    ? `${config.SERVICE_API_URL}referralRequest/${attachment.referralRequest?.id}/attachment/${attachment.id}/download`
    : `${config.SERVICE_API_URL}serviceRequest/${attachment.serviceRequest?.id}/attachment/${attachment.id}/download`;
  const shouldShowAttachmentId = useAuthorized("VIEW_ATTACHMENT_ID");

  const attachmentBasename = getBasename(attachment.url || "");
  const appealLabel = appealId ? `Uploaded with Appeal #${appealId}` : null;
  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
        <Grid item xs="auto">
          {Boolean(attachment.type) && (
            <AttachmentTypeLabel color="textSecondary">{attachment.type}</AttachmentTypeLabel>
          )}
          <Body1 title={`Attachment ID ${attachment.id}`}>
            {attachment.name}
            {appealLabel && <Pill label={appealLabel} variant="info" style={{ marginLeft: "8px" }} />}
            {shouldShowAttachmentId && (
              <CopyToClipboard
                text={attachmentBasename}
                title={`Copy "system ID" ${attachmentBasename} to clipboard`}
              />
            )}
          </Body1>
          {attachment.createdByName ? (
            <Caption data-public>
              Uploaded on {formatDateStrWithTz(attachment.dateCreated)} by {attachment.createdByName}
            </Caption>
          ) : (
            <Caption data-public>Uploaded on {formatDateStrWithTz(attachment.dateCreated)}</Caption>
          )}
        </Grid>
        <AttachmentButtonContainer item xs="auto">
          {singleAttachmentViewerRedesign ? (
            <SingleAttachmentViewer attachment={attachment} />
          ) : (
            <FileView file={attachment} viewAttachmentPath={downloadAttachmentPath} canDelete={false} />
          )}
          <FileDownload downloadAttachmentPath={downloadAttachmentPath} file={attachment} stage={stage} />
        </AttachmentButtonContainer>
      </Grid>
    </Grid>
  );
}

function getBasename(url: string): string {
  const parts = url.split(/\//);
  if (parts) {
    return parts[parts.length - 1];
  }
  return "";
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AttachmentButtonContainer = styled(Grid)(({ theme }) => ({
  // pull buttons farther to the right so they line up with divider lines
  marginRight: theme.spacing(-2),
  display: "flex",
  alignItems: "center",
}));
