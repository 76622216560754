import React from "react";
import { ClinicalService, ProcedureCode } from "@coherehealth/core-platform-api";
import PartialUnitsApproval from "./PartialUnitsApproval";
import PartialProcedureCodeUnitsApproval from "./PartialProcedureCodeUnitsApproval";
import { UNCATEGORIZED_SERVICE } from "components/ServiceRequest/ReviewSection/util/useProcedureCodesState";

interface Props {
  clinicalServices: ClinicalService[];
  serviceRequestUnits: number;
  approvedServiceRequestUnits: number;
  healthPlanName: string;
  pxCodesByClinicalService: Map<string, ProcedureCode[]>;
  approvedUnitsByClinicalService: Map<string, number>;
  updateApprovedUnitsByCS: (clinicalServiceId: string, approvedUnits: number) => void;
  updateSinglePxCodeUnit: (unitsUpdate: string, updatedPxCode: ProcedureCode) => void;
}

export default function PartialApprovalFlexibleCodes({
  pxCodesByClinicalService,
  approvedUnitsByClinicalService,
  updateApprovedUnitsByCS,
  clinicalServices,
  approvedServiceRequestUnits,
  updateSinglePxCodeUnit,
  healthPlanName,
}: Props) {
  return (
    <>
      {Array.from(pxCodesByClinicalService.keys())
        .sort()
        .map((csIdOrUncategorized: string) => {
          const clinicalService = clinicalServices.find((c) => c.id === csIdOrUncategorized);
          const serviceName = clinicalService ? clinicalService.name : UNCATEGORIZED_SERVICE;

          const groupProcedureCodes = pxCodesByClinicalService.get(csIdOrUncategorized) ?? [];

          if (!groupProcedureCodes.length || !serviceName) {
            return null;
          }

          return clinicalService?.isUnitsOnPx || serviceName === UNCATEGORIZED_SERVICE ? (
            <PartialProcedureCodeUnitsApproval
              requestedProcedureCodes={groupProcedureCodes}
              approvedUnits={approvedServiceRequestUnits}
              serviceLevelUnits={false}
              updateSinglePxCodeUnit={updateSinglePxCodeUnit}
              clinicalService={serviceName}
              key={csIdOrUncategorized}
            />
          ) : (
            <React.Fragment key={csIdOrUncategorized}>
              <PartialUnitsApproval
                requestedUnits={groupProcedureCodes[0].units ?? 0}
                approvedUnits={approvedUnitsByClinicalService.get(csIdOrUncategorized) || 0}
                setApprovedUnits={(approvedUnits) => updateApprovedUnitsByCS(csIdOrUncategorized, approvedUnits)}
                healthPlanName={healthPlanName}
                clinicalService={serviceName}
              />
              <PartialProcedureCodeUnitsApproval
                requestedProcedureCodes={groupProcedureCodes}
                approvedUnits={approvedUnitsByClinicalService.get(csIdOrUncategorized) || 0}
                serviceLevelUnits={true}
                updateSinglePxCodeUnit={updateSinglePxCodeUnit}
              />
            </React.Fragment>
          );
        })}
    </>
  );
}
