import React from "react";
import { generatePath, Link } from "react-router-dom";
import routes from "routes";
import { Body1, Card, colorsLight, H6, patientDisplayName, SecondaryButton } from "@coherehealth/common";
import { Patient } from "@coherehealth/core-platform-api";
import { CardProps as MuiCardProps } from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { getPatientHealthDisplayName } from "common/PatientSearchCard/utils/getPatientHealthPlanDisplayName";

interface IProps {
  patient: Patient;
  cardProps?: MuiCardProps;
  /**
   * If this is present we will render a NavLink button that goes to the given link
   */
  selectLink?: string;
  /**
   * If this is present we will render a normal "button" with the given onClick action
   */
  selectOnClick?: (arg0: Patient) => void;
  /**
   * If we should render a loading state for the button (only applicable if selectOnClick is used)
   */
  selectActionLoading?: boolean;
  /**
   * If we should replace Gender and DOB information with a link to the Patient Summary Page
   */
  showSummaryLink?: boolean;
}
/**
 * Must provide a selectLink XOR selectOnClick
 */
function PatientManagementSearchResultCard({ patient, cardProps = { elevation: 0 }, selectOnClick }: IProps) {
  const healthPlanName = getPatientHealthDisplayName({ patient });
  return (
    <CardContainer {...cardProps}>
      <Grid container spacing={2}>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <>
              <H6>{patientDisplayName(patient)}</H6>
              {Boolean(healthPlanName) && <PatientDetails>{healthPlanName}</PatientDetails>}
              <PatientSummaryLinkContainer>
                <PatientSummaryLink to={generatePath(routes.PATIENT_SUMMARY, { patientId: patient.id })}>
                  Patient summary
                </PatientSummaryLink>
              </PatientSummaryLinkContainer>
            </>
          </Grid>
          <Grid item xs={6}>
            {selectOnClick && (
              <SecondaryButton fullWidth onClick={() => selectOnClick(patient)}>
                Select patient
              </SecondaryButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </CardContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PatientDetails = styled(Body1)(({ theme }) => ({
  color: colorsLight.font.secondary,
  paddingTop: theme.spacing(0.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PatientSummaryLink = styled(Link)(({ theme }) => ({
  ...theme.typography.body1,
  textDecoration: "none",
  color: theme.palette.primary.main,
  "&:visited": {
    color: theme.palette.primary.main,
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PatientSummaryLinkContainer = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CardContainer = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  width: "100%",
  background: colorsLight.white.highEmphasis,
  borderRadius: 8,
  display: "flex",
  justifyContent: "space-between",
}));

export default PatientManagementSearchResultCard;
