import { ReviewChatbotFinding } from "@coherehealth/core-platform-api";
import { IReviewChatbotFindingHolder } from "components/ClinicalReview/Review/hooks/useAutomatedReviewChatbotFindings";
import ChatbotOutgoingMessage from "./ChatbotOutgoingMessage";
import ChatbotSynchronousResponse from "./ChatbotSynchronousResponse";

interface Props {
  setFeedback: (id: string, updatedChatbotFinding: Partial<ReviewChatbotFinding>) => void;
  findingHolder: IReviewChatbotFindingHolder;
}

export default function ChatbotSynchronousFinding({ setFeedback, findingHolder }: Props) {
  return (
    <>
      <ChatbotOutgoingMessage findingHolder={findingHolder} />
      <ChatbotSynchronousResponse findingHolder={findingHolder} setFeedback={setFeedback} />
    </>
  );
}
