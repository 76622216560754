import { type AuthorizationResponse, type ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Box } from "@material-ui/core";

import useFetchPatientStayDates from "../hooks/useFetchPatientStayDates";

import PatientStayTimelineHeader from "./PatientStayTimelineHeader";
import { PatientStayTimelineItem } from "./PatientStayTimelineItem";
import { usePatientStayTimelineItems } from "../hooks/usePatientStayTimelineItems";
import { PatientStayTimelineControls } from "./PatientStayTimelineControls";

export interface PatientStayTimelineProps {
  authorization?: AuthorizationResponse;
  serviceRequest?: ServiceRequestResponse;
}

export function PatientStayTimeline({ authorization, serviceRequest }: PatientStayTimelineProps) {
  const { patientStayDatesHistory } = useFetchPatientStayDates(authorization?.id);
  const timelineItems = usePatientStayTimelineItems(authorization, patientStayDatesHistory);

  if (!authorization && !serviceRequest) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" padding="0">
      <PatientStayTimelineHeader authorization={authorization} serviceRequest={serviceRequest} />
      {timelineItems.length > 0 && (
        <PatientStayTimelineControls timelineItems={timelineItems}>
          {timelineItems.map((item, index) => (
            <PatientStayTimelineItem key={index} item={item} />
          ))}
        </PatientStayTimelineControls>
      )}
    </Box>
  );
}
