import { Box, Grid } from "@material-ui/core";
import { AuthorizationResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { format } from "date-fns";
import { DetailsField } from "util/referralRequest";
import { formatDateStr } from "@coherehealth/common";

interface PatientStayTimelineHeaderProps {
  authorization?: AuthorizationResponse;
  serviceRequest?: ServiceRequestResponse;
}

const formatPatientStatus = (status: string | undefined): string => {
  if (!status) {
    return "";
  }
  return status
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const getDischargeFieldValue = (authorization?: AuthorizationResponse) => {
  const dischargeSr = authorization?.serviceRequestsOnAuth?.find(
    (serviceRequest) =>
      serviceRequest.patientStatus === "DISCHARGED" && serviceRequest.dischargeDate && serviceRequest.dischargeTime
  );

  return dischargeSr?.dischargeDate && dischargeSr?.dischargeTime
    ? format(new Date(`${dischargeSr.dischargeDate} ${dischargeSr.dischargeTime}`), "MM/dd/yyyy HH:mm")
    : "--";
};
const sortServiceRequestsByAdmissionDateTime = (serviceRequests: Array<ServiceRequestResponse> | undefined) => {
  if (!serviceRequests) {
    return [];
  }

  return [...serviceRequests].sort((a, b) => {
    const aDate = a.admissionDate ? new Date(`${a.admissionDate} ${a.admissionTime}`) : null;
    const bDate = b.admissionDate ? new Date(`${b.admissionDate} ${b.admissionTime}`) : null;
    if (aDate && bDate) {
      return aDate.getTime() - bDate.getTime();
    }
    return 0;
  });
};

const getAdmissionFieldValue = (authorization?: AuthorizationResponse) => {
  const admissionSr = sortServiceRequestsByAdmissionDateTime(authorization?.serviceRequestsOnAuth)[0];
  return admissionSr?.admissionDate && admissionSr?.admissionTime
    ? format(new Date(`${admissionSr.admissionDate} ${admissionSr.admissionTime}`), "MM/dd/yyyy HH:mm")
    : "--";
};

const getAdmissionSourceFieldValue = (authorization?: AuthorizationResponse) => {
  const admissionSr = sortServiceRequestsByAdmissionDateTime(authorization?.serviceRequestsOnAuth)[0];
  return admissionSr?.admissionSource?.name || "--";
};

const PatientStayTimelineHeader = ({ authorization, serviceRequest }: PatientStayTimelineHeaderProps) => {
  const notYetAdmitted =
    authorization?.patientStatus === "NOT_YET_ADMITTED" ||
    (!authorization && serviceRequest?.patientStatus === "NOT_YET_ADMITTED");

  return (
    <Grid container spacing={2} style={{ paddingTop: "16px" }}>
      <Grid item xs={6}>
        <DetailsField
          label="Status"
          value={formatPatientStatus(authorization?.patientStatus || serviceRequest?.patientStatus)}
          style={{ paddingTop: 0, paddingBottom: 4 }}
        />
        {notYetAdmitted ? (
          <DetailsField
            label="Expected admission"
            value={serviceRequest?.expectedAdmissionDate ? formatDateStr(serviceRequest?.expectedAdmissionDate) : "--"}
          />
        ) : (
          <>
            <Box display="flex" alignItems="center" position="relative">
              <DetailsField
                label="Admission"
                value={getAdmissionFieldValue(authorization)}
                style={{ paddingTop: 0, paddingBottom: 4 }}
              />
            </Box>
            <DetailsField
              label="Admission source"
              value={getAdmissionSourceFieldValue(authorization)}
              style={{ paddingTop: 0, paddingBottom: 0 }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={6}>
        {notYetAdmitted ? (
          <DetailsField
            label="Expected discharge"
            value={serviceRequest?.expectedDischargeDate ? formatDateStr(serviceRequest?.expectedDischargeDate) : "--"}
          />
        ) : (
          <>
            <DetailsField
              label="Discharge"
              value={getDischargeFieldValue(authorization)}
              style={{ paddingTop: 0, paddingBottom: 4 }}
            />
            <DetailsField
              label="Discharged to"
              value={authorization?.dischargedTo?.name || serviceRequest?.dischargedTo?.name || "--"}
              style={{ paddingTop: 0, paddingBottom: 4 }}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default PatientStayTimelineHeader;
