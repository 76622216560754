import { Dispatch, SetStateAction, useEffect } from "react";
import { useSnackbar } from "notistack";
import AppBar from "@material-ui/core/AppBar";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, useTheme } from "@material-ui/core/styles";
import { useContext, useState } from "react";
import { UserContext, User } from "UserContext";
import { error as logError, stringifyError } from "logger";
import {
  PrimaryButton,
  SecondaryButton,
  DATE_FORMAT,
  formatDateToISODate,
  useFeature,
  Caption,
} from "@coherehealth/common";
import {
  ContentsContainer,
  OnboardingSteps,
  UserInfoState,
  ProviderOrgInfo,
  AvailityInfo,
  useRenewAccessToken,
  useJoinExistingOrganizationApi,
} from "./shared";
import {
  OnboardingPatientInfo,
  Organization,
  ServiceRequestSearchResponse,
  useCreateOnboardingUserActivity,
  useCreateSsoOrg,
  useGetUserById,
  useServiceRequestSearch,
  useVerifyPatientInfoAndCreateOrg,
  VerifyPatientInfoAndCreateOrgResponse,
} from "@coherehealth/core-platform-api";
import parse from "date-fns/parse";
import { useNavigate } from "react-router-dom";
import usePostOnboardingRedirectUrl from "./usePostOnboardingUrl";
import routes from "routes";
import { getDomainFromEmail } from "util/user";
import { isUniqueMemberId } from "./utils";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    top: "auto",
    bottom: 0,
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
}));

interface Props {
  currentStep: OnboardingSteps;
  setCurrentStep: Dispatch<SetStateAction<OnboardingSteps>>;
  canProceedToNextStep: boolean;
  highlightInfoFormErrors: boolean;
  setHighlightInfoFormErrors: Dispatch<SetStateAction<boolean>>;
  setIsUniqueMemberId: Dispatch<SetStateAction<boolean>>;
  onContinueFrom: (step: OnboardingSteps) => void;
  providerOrgInfo: ProviderOrgInfo;
  patientInfo?: OnboardingPatientInfo[];
  autoVerificationEnabled?: boolean;
  userInfo: UserInfoState;
  verifyInfoAndCreateOrg: ReturnType<typeof useVerifyPatientInfoAndCreateOrg>["mutate"];
  isOutOfScope?: boolean;
  userIsAdmin?: boolean;
  nullifyInvalidFields: () => void;
  selectedProviderOrgId: string;
  selectedProviderOrgName: string;
  availityInfo: AvailityInfo;
  setPatientInfoEntryValidation: Dispatch<SetStateAction<VerifyPatientInfoAndCreateOrgResponse>>;
  selectedProviderOrg: Organization;
  isAuthenticatedUser?: boolean;
  hasCreateAnButtonClicked?: boolean;
  attemptedSubmitOnClick?: boolean;
  setAttemptedSubmitOnClick?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OnboardingFooter({
  currentStep,
  setCurrentStep,
  canProceedToNextStep,
  highlightInfoFormErrors,
  setHighlightInfoFormErrors,
  setIsUniqueMemberId,
  onContinueFrom,
  providerOrgInfo,
  patientInfo,
  autoVerificationEnabled,
  userInfo,
  verifyInfoAndCreateOrg,
  isOutOfScope,
  userIsAdmin,
  nullifyInvalidFields,
  selectedProviderOrgId,
  selectedProviderOrgName,
  availityInfo,
  setPatientInfoEntryValidation,
  selectedProviderOrg,
  isAuthenticatedUser,
  hasCreateAnButtonClicked,
  setAttemptedSubmitOnClick,
  attemptedSubmitOnClick,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { returnTo } = usePostOnboardingRedirectUrl();
  const skipPHIPageIfUserIsExistingUserFF = useFeature("skipPHIPageIfUserIsExistingUser");

  const { mutate: createSsoOrg, loading: createSsoOrgLoading, error: createSsoOrgError } = useCreateSsoOrg({});

  const { buttonText, buttonTrackingAttribute } = getButtonAttributes(currentStep, availityInfo.isAvailityUser);

  const scopeQuestionsNextStep = isOutOfScope
    ? "OUT_OF_SCOPE_WARNING"
    : !userIsAdmin
    ? "NON_ADMIN_WARNING"
    : "USER_ORG_INFO";

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: trackUserInteraction } = useCreateOnboardingUserActivity({});

  const { renewAccessToken, oktaAuthClientLoading } = useRenewAccessToken();
  const { joinExistingOrganization, userJoinExistingOrganizationLoading, userJoinExistingOrganizationError } =
    useJoinExistingOrganizationApi({
      userInfo,
      isAvailityUser: availityInfo.isAvailityUser,
      autoVerificationEnabled,
      organizationId: selectedProviderOrgId,
      organizationTin: providerOrgInfo.tin,
      organizationName: selectedProviderOrgName,
      renewAccessToken,
      setCurrentStep,
    });
  const validateUserSpecialties = (userSpecialties: string[], orgSpecialties: string[]): string[] => {
    /**
     * If userSpecialties doesn't contain "Not applicable"
     * Validate that the list of userSpecialties is a subset of the orgSpecialties
     **/
    if (!userSpecialties.includes("Not applicable, I don’t submit authorizations")) {
      return orgSpecialties.filter((orgSpecialty) => userInfo.userSpecialties.includes(orgSpecialty));
    } else {
      return userSpecialties;
    }
  };

  const verifyPatientInfo = async () => {
    setPatientInfoEntryValidation?.({
      validated: true,
      validationResult: [],
    });
    const patientData: OnboardingPatientInfo[] = [];
    patientInfo?.forEach(({ memberId, memberDateOfBirth }) => {
      if (memberDateOfBirth) {
        const ISOdateOfBirth = formatDateToISODate(parse(memberDateOfBirth, DATE_FORMAT, new Date()));
        patientData.push({ memberId, memberDateOfBirth: ISOdateOfBirth });
      }
    });

    const userSpecialties: string[] = validateUserSpecialties(
      userInfo.userSpecialties,
      providerOrgInfo.organizationSpecialties
    );

    const newUserInfo: UserInfoState = { ...userInfo, userSpecialties };

    let validationStatus: VerifyPatientInfoAndCreateOrgResponse = {};

    if (currentStep === "VERIFICATION_BY_PATIENT_INFO_JOIN_ORG") {
      if (isUniqueMemberId(patientInfo)) {
        setIsUniqueMemberId(true);
        const response = await joinExistingOrganization(patientData);
        // If a new user was created then we consider this to be "validated"
        validationStatus = { validated: response.newUserCreated };
        if (response.newUserCreated) {
          setCurrentStep("VERIFICATION_SENT");
        }
      } else {
        setIsUniqueMemberId(false);
      }
    } else if (currentStep === "VERIFICATION_BY_PATIENT_INFO_CREATE_ORG" && canProceedToNextStep) {
      validationStatus = await verifyInfoAndCreateOrg(
        {
          memberIdValidationInfoList: patientData,
          newUserInfo: newUserInfo,
          newOrganizationInfo: providerOrgInfo,
          enableEmailAutoVerification: autoVerificationEnabled,
        },
        {}
      );
      if (validationStatus?.validated) {
        await trackUserInteraction({
          event: "ORGANIZATION_PATIENT_INFO_AUTO_VERIFICATION",
          stage: "ORGANIZATION_ONBOARDING_FLOW",
          type: "INTERACTION",
          activityContext: {
            onboardingAutoVerificationStatus: {
              userEmail: userInfo.email,
              organizationTin: providerOrgInfo.tin,
              organizationName: selectedProviderOrgName,
              successful: true,
            },
          },
        });
        setCurrentStep("VERIFICATION_SENT");
      }
    }

    setPatientInfoEntryValidation((prev) => ({ ...prev, ...validationStatus }));
  };
  const createAvailityOrg = async () => {
    const userSpecialties: string[] = validateUserSpecialties(
      userInfo.userSpecialties,
      providerOrgInfo.organizationSpecialties
    );
    const isOrgCreationDone = await createSsoOrg(
      {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        title: userInfo.title,
        email: userInfo.email,
        phoneNumber: userInfo.phoneNumber.number,
        phoneExtension: userInfo.phoneNumber.extension,
        faxNumber: userInfo.faxNumber?.number,
        userSpecialties: userSpecialties,
        newOrganizationInfo: providerOrgInfo,
        enableEmailAutoVerification: autoVerificationEnabled || false,
        isHealthcareProviderChecked: userInfo?.isHealthcareProviderChecked,
      },
      {}
    );
    if (isOrgCreationDone.validated) {
      /*
       * Send Tracker info with Organization Successful creation
       */
      // await trackUserInteraction({
      //   event: "ORGANIZATION_SSO_AUTO_VERIFICATION",
      //   stage: "ORGANIZATION_ONBOARDING_FLOW",
      //   type: "INTERACTION",
      //   activityContext: {
      //     onboardingAutoVerificationStatus: {
      //       userEmail: userInfo.email,
      //       organizationTin: providerOrgInfo.tin,
      //       organizationName: providerOrgInfo.name,
      //       successful: true,
      //     },
      //   },
      // });
      await renewAccessToken();
      navigate(returnTo);
      enqueueSnackbar("Your account has been verified", { variant: "success" });
    }
  };

  useEffect(() => {
    if (userJoinExistingOrganizationError) {
      let errorMessage = "Error joining organization";
      if (userJoinExistingOrganizationError.status === 409) {
        errorMessage = "Account already exists";
      }
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    }
    if (createSsoOrgError) {
      enqueueSnackbar(`Failed to create organization: ${createSsoOrgError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, userJoinExistingOrganizationError, createSsoOrgError]);

  const onExit = async () => {
    onContinueFrom(currentStep);
    switch (currentStep) {
      case "SELECTION":
        if (!hasCreateAnButtonClicked) {
          localStorage.setItem("exitOnboarding", "true");
          navigate(routes.DASHBOARD);
        } else {
          setCurrentStep("SEARCH");
        }
        break;
    }
  };

  const { getUser } = useContext(UserContext);
  const [currUser, setUser] = useState<User>();
  const [serviceRequests, setServiceRequests] = useState<ServiceRequestSearchResponse[]>([]);

  const { data: user, refetch: refetchUser } = useGetUserById({
    id: currUser?.sub || "",
    lazy: true,
    queryParams: {
      includeOrganizationName: true,
    },
  });

  useEffect(() => {
    if (currUser) {
      refetchUser();
    }
  }, [currUser, refetchUser]);

  useEffect(() => {
    if (Boolean(isAuthenticatedUser) && skipPHIPageIfUserIsExistingUserFF) {
      getUser()
        ?.then((currentUser) => {
          setUser(currentUser);
        })
        .catch((error): void => {
          logError(`error loading current user ${stringifyError(error)}`);
        });
    }
  }, [isAuthenticatedUser, skipPHIPageIfUserIsExistingUserFF, getUser]);

  const { mutate: serviceRequestSearch } = useServiceRequestSearch({
    onMutate(_, data) {
      setServiceRequests(data);
    },
  });

  useEffect(() => {
    if (currUser) {
      serviceRequestSearch({
        createdBy: `eq:${currUser.sub}`,
        offset: 0,
        query: "",
        sort: "lastUpdated:desc",
      });
    }
  }, [currUser, serviceRequestSearch]);

  const onContinue = async () => {
    onContinueFrom(currentStep);
    switch (currentStep) {
      case "SEARCH":
        setCurrentStep("SELECTION");
        break;
      case "SELECTION":
        setCurrentStep("SCOPE_QUESTIONS");
        break;
      case "SCOPE_QUESTIONS":
        if (canProceedToNextStep) {
          setAttemptedSubmitOnClick?.(false);
          setCurrentStep(scopeQuestionsNextStep);
        }
        break;
      case "USER_ORG_INFO":
        if (canProceedToNextStep) {
          setAttemptedSubmitOnClick?.(false);
          if (availityInfo.isAvailityUser) {
            await createAvailityOrg();
          } else {
            if (skipPHIPageIfUserIsExistingUserFF) {
              if (user && user?.isSsoUser && serviceRequests.length > 0) {
                navigate(routes.DASHBOARD);
              } else {
                setCurrentStep("VERIFICATION_BY_PATIENT_INFO_CREATE_ORG");
              }
            } else {
              setCurrentStep("VERIFICATION_BY_PATIENT_INFO_CREATE_ORG");
            }
          }
        } else {
          // There are some errors, so highlight them
          setHighlightInfoFormErrors(true);
        }
        break;
      case "VERIFICATION_BY_PATIENT_INFO_CREATE_ORG":
      case "VERIFICATION_BY_PATIENT_INFO_JOIN_ORG":
        await verifyPatientInfo();
        break;
      case "EXISTING_ORG_USER_INFO":
        if (canProceedToNextStep) {
          setAttemptedSubmitOnClick?.(false);
          if (
            availityInfo.isAvailityUser ||
            (autoVerificationEnabled &&
              selectedProviderOrg.emailAutoVerificationEnabled &&
              selectedProviderOrg.emailDomains?.includes(getDomainFromEmail(userInfo.email)))
          ) {
            const result = await joinExistingOrganization();
            if (result) {
              // set a flag in storage
              localStorage.setItem("skipRedirectBecauseUserFinishedOnboarding", "true");
            }
          } else {
            setCurrentStep("VERIFICATION_BY_PATIENT_INFO_JOIN_ORG");
          }
        } else {
          // There are some errors, so highlight them
          setHighlightInfoFormErrors(true);
        }
        break;
      case "NON_ADMIN_WARNING":
        if (availityInfo.isAvailityUser) {
          navigate(routes.DASHBOARD);
        } else {
          window.location.assign("https://coherehealth.com/");
        }
        break;
      case "OUT_OF_SCOPE_WARNING":
        window.location.assign("https://coherehealth.com/");
        break;
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onBack = () => {
    switch (currentStep) {
      case "SCOPE_QUESTIONS":
        setCurrentStep("SELECTION");
        break;
      case "USER_ORG_INFO":
        setCurrentStep("SCOPE_QUESTIONS");
        break;
      case "VERIFICATION_BY_PATIENT_INFO_CREATE_ORG":
        setCurrentStep("USER_ORG_INFO");
        break;
      case "VERIFICATION_BY_PATIENT_INFO_JOIN_ORG":
        setCurrentStep("EXISTING_ORG_USER_INFO");
        break;
      case "EXISTING_ORG_USER_INFO":
        setCurrentStep("SELECTION");
        break;
      case "OUT_OF_SCOPE_WARNING":
      case "NON_ADMIN_WARNING":
        nullifyInvalidFields();
        setCurrentStep("SCOPE_QUESTIONS");
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const proceedToNextStepDisabled =
    currentStep === "USER_ORG_INFO" || currentStep === "EXISTING_ORG_USER_INFO"
      ? highlightInfoFormErrors && !canProceedToNextStep
      : !canProceedToNextStep && attemptedSubmitOnClick;

  const handleOnClick = () => {
    setAttemptedSubmitOnClick?.(true);
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <AppBar classes={classes} component="footer" elevation={0}>
      <ContentsContainer>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {proceedToNextStepDisabled && (
            <Caption style={{ color: theme.palette.error.dark, display: "flex", alignItems: "center" }}>
              {"There are some errors with the details above."}
            </Caption>
          )}

          {currentStep === "SELECTION" ? (
            <ExitOnboardingButton onClick={onExit} warning>
              {buttonText}
            </ExitOnboardingButton>
          ) : (
            <BackButton onClick={onBack}>Back</BackButton>
          )}
          {currentStep !== "VERIFICATION_START" && currentStep !== "SELECTION" && (
            <RightButton
              onClick={handleOnClick}
              disabled={
                proceedToNextStepDisabled ||
                userJoinExistingOrganizationLoading ||
                createSsoOrgLoading ||
                oktaAuthClientLoading
              }
              loading={userJoinExistingOrganizationLoading || createSsoOrgLoading || oktaAuthClientLoading}
              data-tracking-id={buttonTrackingAttribute}
            >
              {buttonText}
            </RightButton>
          )}
        </div>
      </ContentsContainer>
    </AppBar>
  );
}

function getButtonAttributes(currentStep: OnboardingSteps, isAuthenticatedFlow: boolean) {
  switch (currentStep) {
    case "SELECTION":
      return { buttonText: "Exit onboarding", buttonTrackingAttribute: "exit-onboarding" };
    case "SCOPE_QUESTIONS":
      return { buttonText: "Proceed to the next step", buttonTrackingAttribute: "create-org-screeners" };
    case "EXISTING_ORG_USER_INFO":
      return { buttonText: "Submit", buttonTrackingAttribute: "join-existing-org-submit" };
    case "USER_ORG_INFO":
      if (isAuthenticatedFlow) {
        return { buttonText: "Submit", buttonTrackingAttribute: "create-org-submit" };
      } else {
        return { buttonText: "Proceed to the next step", buttonTrackingAttribute: "" };
      }

    case "VERIFICATION_BY_PATIENT_INFO_CREATE_ORG":
    case "VERIFICATION_BY_PATIENT_INFO_JOIN_ORG":
    case "VERIFICATION_START":
      return { buttonText: "Verify", buttonTrackingAttribute: "" };
    case "NON_ADMIN_WARNING":
      if (isAuthenticatedFlow) {
        return { buttonText: "Go to Cohere dashboard", buttonTrackingAttribute: "" };
      } else {
        return { buttonText: "Return to Cohere website", buttonTrackingAttribute: "" };
      }
    case "OUT_OF_SCOPE_WARNING":
      return { buttonText: "Return to Cohere website", buttonTrackingAttribute: "" };
    default:
      return { buttonText: "", buttonTrackingAttribute: "" };
  }
}

const getRightButtonStyles = ({ theme }: any): any => ({
  float: "right",
  margin: theme.spacing(2, 0),
  paddingRight: theme.spacing(7),
  paddingLeft: theme.spacing(7),
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RightButton = styled(PrimaryButton)(getRightButtonStyles);
// eslint-disable-next-line cohere-react/no-mui-styled-import
const ExitOnboardingButton = styled(SecondaryButton)(getRightButtonStyles);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const BackButton = styled(SecondaryButton)(({ theme }) => ({
  float: "right",
  margin: theme.spacing(2, 5),
  width: theme.spacing(20),
}));
