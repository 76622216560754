import { parseDateFromISOStringWithoutFallback } from "@coherehealth/common";
import { getCoverageBasedOnDate } from "./patientUtils";
import { ReviewType, ServiceRequestResponse } from "@coherehealth/core-platform-api";

const LICENCE_DEPENDENT_STATES = ["AZ", "FL", "MN", "ND", "OR", "TX"];

export const isLicenseNeededForLetter = (
  serviceRequest?: ServiceRequestResponse | null,
  review?: ReviewType | null
): boolean => {
  const dependentState = LICENCE_DEPENDENT_STATES.find(
    (state) =>
      state ===
      getCoverageBasedOnDate(parseDateFromISOStringWithoutFallback(serviceRequest?.startDate), serviceRequest?.patient)
        ?.stateOfIssue
  );
  if (dependentState === "TX") {
    return review?.reviewType === "PeerToPeerReview";
  } else {
    return !!dependentState;
  }
};
