import React, {
  Dispatch,
  ForwardedRef,
  PropsWithRef,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Caption,
  TertiaryButton,
  useStylesCard,
  Alert,
  parseDateFromISOString,
  SummaryCardDrugIcon,
  SummaryCardMedicalIcon,
  Pill,
  Tooltip,
  H4,
  extractClinicalServicesText,
  useGetAuthorizationByIdWithFallback,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import {
  ServiceRequestResponse,
  useGetVatNotification,
  VatNotification,
  UserResponse,
  ServiceRequestUpdatePayload,
  AuthorizationResponse,
} from "@coherehealth/core-platform-api";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ServiceRequestSummaryDetails from "./ServiceRequestSummaryDetails";
import { SRFormViewState } from "./ServiceRequestFormSection";
import { useAuthorized, useBelongsToOpsGroup } from "authorization";
import ServiceRequestRelationDisplay from "../../ServiceRequestRelationDisplay";
import InfoAlert from "../../../common/InfoAlert";
import { useSnackbar } from "notistack";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import {
  getTatEndDisplayText,
  getTatStartDisplayText,
  tatDisplayText,
  useAuthorizationFetch,
} from "components/ClinicalReview/reviewUtils/utils";
import useIsOverflow from "../../../hooks/useIsOverflow";
import AuthVisibilityMessage from "common/AuthVisibilityMessage";
import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import { AuthorizationSummaryDropDownMenu } from "../AuthorizationPatientSummary/AuthorizationSummaryCard/AuthorizationSummaryDropDownMenu/index";
import { usePatientSummarySharedStyles, useServiceRequestCardStyles } from "../patientSummaryStyles";
import { useGetLettersExpansionConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { ServiceRequestStatusDisplay } from "components/ServiceRequestStatusDisplay/StatusBanner/ServiceRequestStatusDisplay";
import PatientSearchModal from "common/UnifiedPatientSearchModal/UnifiedPatientSearchModal";
export interface ServiceSummaryCardProps {
  id?: string;
  serviceRequest: ServiceRequestResponse;
  onEdit?: () => Promise<ServiceRequestResponse[] | ServiceRequestResponse | null | void>;
  initialIsExpanded?: boolean;
  onShowSummaryPrintout?: (arg0: ServiceRequestResponse) => void;
  clinicalReviewView?: boolean;
  targetReviewId?: string | null;
  setTargetReview?: Dispatch<SetStateAction<HTMLElement | null>>;
  setServiceRequests?: React.Dispatch<React.SetStateAction<ServiceRequestResponse[]>>;
  onShowCallLog?: (
    arg0: ServiceRequestResponse,
    arg1: VatNotification | undefined | null,
    arg2: string | undefined
  ) => void;
  serviceRequestRefetch?: () => Promise<ServiceRequestResponse | null>;
  user?: UserResponse | null;
  loadExtendedSrView?: boolean;
  isEditView?: boolean;
  setUpdatedServiceRequestPayload?: Dispatch<SetStateAction<ServiceRequestUpdatePayload | undefined>>;
  setIsEditView?: Dispatch<SetStateAction<boolean>>;
  setServiceRequest?: Dispatch<SetStateAction<ServiceRequestResponse | undefined>>;
  isFaxEdit?: boolean;
  isFaxEditSRFlow?: boolean;
  authorizationServiceRequests?: ServiceRequestResponse[];
  authorizationFetchLoading?: boolean;
}

const ServiceRequestSummaryCard = React.forwardRef(
  (
    {
      id,
      serviceRequest,
      onEdit,
      onShowSummaryPrintout,
      initialIsExpanded = false,
      clinicalReviewView,
      onShowCallLog,
      targetReviewId,
      setTargetReview,
      serviceRequestRefetch,
      setServiceRequests,
      user,
      loadExtendedSrView = true,
      isEditView = false,
      setUpdatedServiceRequestPayload,
      setIsEditView,
      setServiceRequest,
      isFaxEdit,
      isFaxEditSRFlow,
      authorizationServiceRequests,
      authorizationFetchLoading,
      ...otherProps
    }: PropsWithRef<ServiceSummaryCardProps>,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(initialIsExpanded);
    // In order to not render any detail contents (like clinical summary)
    // when it's not needed, because there's a LOT there
    const [contentsVisible, setContentsVisible] = useState(isExpanded);
    const canViewEscalatedState = useAuthorized("VIEW_ESCALATED_STATE");
    const isCohereUser = useBelongsToOpsGroup("COHERE");
    const { refetch: srRefetch } = useGetServiceRequestByIdWithFallback({ id: serviceRequest.id, lazy: true });
    const { data: lettersExpansionConfig } = useGetLettersExpansionConfigurationByPayer(
      serviceRequest.healthPlanName || ""
    );
    const adhocLetter = lettersExpansionConfig?.adhocLetter;
    const singleAttachmentViewerRedesign = lettersExpansionConfig?.singleAttachmentViewerRedesign;
    const showNotificationViewer = lettersExpansionConfig?.notificationViewer;
    const getServiceRequest = useCallback(async () => {
      if (!loadExtendedSrView) {
        return;
      }

      const response = await srRefetch();
      if (response) {
        setServiceRequests?.((prev) => {
          if (prev) {
            const idx = prev?.findIndex((sr) => sr.id === response.id);
            if (idx >= 0) {
              const start = prev.slice(0, idx);
              const end = prev.slice(idx + 1);
              return [...start, response, ...end];
            } else {
              return [...prev];
            }
          } else {
            return [response];
          }
        });
        setContentsVisible(true);
      }
    }, [setServiceRequests, srRefetch, loadExtendedSrView]);

    useEffect(() => {
      if (contentsVisible && !isExpanded) {
        const timeout = setTimeout(() => {
          setContentsVisible(false);
        }, 1000);
        return clearTimeout(timeout);
      }
      if (!contentsVisible && isExpanded) {
        getServiceRequest();
      }
    }, [isExpanded, contentsVisible, serviceRequestRefetch, setServiceRequests, getServiceRequest]);

    useEffect(() => {
      if (initialIsExpanded) {
        getServiceRequest();
      }
    }, [getServiceRequest, initialIsExpanded]);

    const [srFormViewState, setSRFormViewState] = useState<SRFormViewState>(!isEditView ? "READ_ONLY" : "EDIT");

    const serviceCategoryName = serviceRequest.clinicalService?.serviceCategoryName;
    const hasServiceCategory = !!serviceCategoryName;
    const patientSummaryStyles = usePatientSummarySharedStyles({ hasServiceCategory, isFaxEditSRFlow });
    const serviceRequestCardStyles = useServiceRequestCardStyles({ hasServiceCategory, isFaxEditSRFlow });

    const { withHover, ...cardStyles } = useStylesCard({});

    const textElementRef = useRef<HTMLInputElement | null>(null);

    const { hoverStatus } = useIsOverflow(textElementRef);

    const icon =
      serviceRequest.type === "Pharmacy" ? (
        <SummaryCardDrugIcon />
      ) : serviceRequest.type === "Medical" ? (
        <SummaryCardMedicalIcon />
      ) : undefined;
    const scrollToClinicalAssessment =
      serviceRequest.type === "Pharmacy" &&
      serviceRequest.authStatus === "PENDING" &&
      serviceRequest.pendingReason === "PENDING_ASSESSMENT_SUBMISSION";

    const tatInfoReadyToDisplay = !authorizationFetchLoading;
    const hasAuthSrs = (authorizationServiceRequests?.length || 0) > 0;
    const getSrforTatDisplay = () => {
      if (hasAuthSrs && serviceRequest.encounterType === "INPATIENT") {
        const firstInitialSr = authorizationServiceRequests?.find((sr) => sr.requestType === "INITIAL");
        return firstInitialSr || serviceRequest;
      } else {
        return serviceRequest;
      }
    };
    const tatDueDisplayText = tatDisplayText(getSrforTatDisplay());
    const tatStartDisplayText = getTatStartDisplayText(getSrforTatDisplay());
    const updatedTatDueDisplayText = getTatEndDisplayText(getSrforTatDisplay());

    const serviceRequestIsRestricted = serviceRequest.isRestricted;
    const serviceRequestIsViewable = !serviceRequest.isRestricted && serviceRequest.hasRestrictedCodes;

    const smartClient = useContext(SmartOnFhirContext);
    const inSmartOnFhirWorkflow = Boolean(smartClient);
    const { authorization } = useAuthorizationFetch(serviceRequest);

    return (
      <>
        {clinicalReviewView ? (
          <>
            <div className={serviceRequestCardStyles.clinicalReviewPillContainer}>
              {serviceRequest.isEscalated && canViewEscalatedState && isCohereUser && (
                <div className={serviceRequestCardStyles.clinicalReviewPillSubContainer}>
                  <Pill label={"Escalated"} variant="error" />
                </div>
              )}
              {tatInfoReadyToDisplay ? (
                <Box display={"flex"} flexDirection={"column"}>
                  <Box mb={1}>
                    <Tooltip
                      placement="top"
                      title="Submission time is the user’s click for portal and phone requests, and the document receipt time for fax, email, and mail.
                     For these, TAT start can be edited pre-submission. Concurrent requests always show TAT start for the initial submission."
                    >
                      <div>
                        <Pill label={tatStartDisplayText} variant="info" />
                      </div>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip
                      placement="top"
                      title="Due date is auto-calculated based on state and federal regulations, line of business, and any health plan specific conditions.
                       Concurrent requests always show TAT end for the initial submission."
                    >
                      <div>
                        <Pill label={updatedTatDueDisplayText} variant="info" />
                      </div>
                    </Tooltip>
                  </Box>
                </Box>
              ) : (
                <CircularProgress size={12} />
              )}
            </div>
            {serviceRequest.clinicalService?.description && (
              <Caption className={patientSummaryStyles.serviceDescription}>
                {serviceRequest.clinicalService?.description}
              </Caption>
            )}
            {serviceRequest?.isAdvanceCoverageDetermination && (
              <Alert
                severity="warning"
                message="This request has been processed as an advanced coverage determination."
                className={patientSummaryStyles.acdAlert}
              >
                This is a Medicare Advantage private fee-for-service (PFFS) member.
              </Alert>
            )}
            <ServiceRequestStatusDisplay
              authorization={authorization}
              onDeleteDraft={onEdit}
              onEdit={onEdit}
              serviceRequest={serviceRequest}
              openServiceRequestForEditing={() => {
                setIsExpanded(true);
                setSRFormViewState("EDIT");
              }}
              canCreateContinuation={!serviceRequest.clinicalService?.hasUnitsOnPxChanged}
              actionConfiguration={{}}
            />
            {serviceRequest.serviceRequestRelations?.map((relation) => (
              <ServiceRequestRelationDisplay
                sourceServiceRequestId={serviceRequest.id}
                serviceRequestRelation={relation}
                patientId={serviceRequest.patient?.id}
                key={relation.id}
                className={patientSummaryStyles.relationDisplayContainer}
                isOnPatientSummary
              />
            ))}
          </>
        ) : (
          <div>
            {serviceRequest.reconClaim && (
              <InfoAlert message="Claim">Claim number: {serviceRequest.reconClaim.claimId}</InfoAlert>
            )}
            <Tooltip
              title="Access to this request is limited due to sensitive health information."
              disableHoverListener={!serviceRequestIsRestricted}
              disableFocusListener={!serviceRequestIsRestricted}
              placement="top"
            >
              <Accordion
                data-testid={id ? `${id}-accordion` : "service-request-summary-card-accordion"}
                className={
                  !isFaxEditSRFlow
                    ? `${serviceRequestCardStyles.serviceRequestAccordion} ${withHover}`
                    : `${serviceRequestCardStyles.serviceRequestAccordion}`
                }
                classes={cardStyles}
                expanded={isExpanded}
                ref={scrollToClinicalAssessment ? undefined : ref}
              >
                <div className={serviceRequestCardStyles.accordionHeader}>
                  <AccordionSummary
                    classes={{
                      root: patientSummaryStyles.summaryRoot,
                      content: patientSummaryStyles.serviceRequestSummary,
                      expanded: patientSummaryStyles.expanded,
                    }}
                  >
                    {!serviceRequestIsRestricted && (
                      <div className={patientSummaryStyles.rowContainer}>
                        <div className={patientSummaryStyles.iconContainer}>{icon}</div>
                        <div className={patientSummaryStyles.captionContainer}>
                          {hasServiceCategory && (
                            <Caption className={patientSummaryStyles.serviceCategoryCaption}>
                              {serviceCategoryName || ""}
                            </Caption>
                          )}
                          <Tooltip
                            title={extractClinicalServicesText(
                              serviceRequest.clinicalServices || [],
                              serviceRequest.authCategory,
                              serviceRequest.authSubcategory,
                              serviceRequest.palCategory
                            )}
                            disableHoverListener={!hoverStatus}
                            placement="top"
                            arrow
                            classes={{ tooltip: patientSummaryStyles.customWidth }}
                          >
                            <Grid
                              item
                              xs={12}
                              className={
                                hoverStatus
                                  ? `${patientSummaryStyles.styledBodyContainer} ${patientSummaryStyles.hover}`
                                  : `${patientSummaryStyles.styledBodyContainer}`
                              }
                              ref={textElementRef}
                            >
                              <H4 className={patientSummaryStyles.serviceName}>
                                {extractClinicalServicesText(
                                  serviceRequest.clinicalServices || [],
                                  serviceRequest.authCategory,
                                  serviceRequest.authSubcategory,
                                  serviceRequest.palCategory
                                )}
                              </H4>
                            </Grid>
                          </Tooltip>
                        </div>
                        {!isFaxEditSRFlow && (
                          <div className={patientSummaryStyles.buttonContainer}>
                            <ServiceRequestPrint
                              serviceRequest={serviceRequest}
                              onShowSummaryPrintout={onShowSummaryPrintout}
                              onShowCallLog={onShowCallLog}
                              showNotificationViewer={showNotificationViewer}
                            />
                            <TertiaryButton
                              className={patientSummaryStyles.expandToggleButton}
                              startIcon={
                                <ExpandMoreIcon
                                  className={
                                    isExpanded
                                      ? patientSummaryStyles.expandIconRotated
                                      : patientSummaryStyles.expandIconUnRotated
                                  }
                                />
                              }
                              disabled={!onShowSummaryPrintout}
                              onClick={() => setIsExpanded(!isExpanded)}
                            ></TertiaryButton>
                          </div>
                        )}
                      </div>
                    )}
                    {isExpanded && canViewEscalatedState && (
                      <div className={serviceRequestCardStyles.clinicalReviewPillContainer}>
                        {serviceRequest.isEscalated && isCohereUser && (
                          <div className={serviceRequestCardStyles.clinicalReviewPillSubContainer}>
                            <Pill label={"Escalated"} variant="error" />
                          </div>
                        )}
                        <div>
                          <Pill label={tatDueDisplayText} variant="info" />
                        </div>
                      </div>
                    )}
                    {serviceRequest.clinicalService?.description && (
                      <Caption className={`${patientSummaryStyles.serviceDescription}${isExpanded ? "" : " hidden"}`}>
                        {serviceRequest.clinicalService?.description}
                      </Caption>
                    )}
                    {serviceRequest?.isAdvanceCoverageDetermination && (
                      <Alert
                        severity="warning"
                        message="This request has been processed as an advanced coverage determination."
                        className={`${patientSummaryStyles.acdAlert}${isExpanded ? "" : " hidden"}`}
                      >
                        This is a Medicare Advantage private fee-for-service (PFFS) member.
                      </Alert>
                    )}
                    <ServiceRequestStatusDisplay
                      onDeleteDraft={onEdit}
                      onEdit={onEdit}
                      serviceRequest={serviceRequest}
                      openServiceRequestForEditing={() => {
                        setIsExpanded(true);
                        setSRFormViewState("EDIT");
                      }}
                      canCreateContinuation={!serviceRequest.clinicalService?.hasUnitsOnPxChanged}
                      actionConfiguration={{
                        printActionConfiguration: {
                          disabled: Boolean(serviceRequestIsRestricted),
                          hidden: Boolean(serviceRequestIsRestricted),
                        },
                        startContinuationActionConfiguration: {
                          disabled: Boolean(serviceRequestIsRestricted),
                          hidden: Boolean(serviceRequestIsRestricted),
                          noRender: inSmartOnFhirWorkflow,
                        },
                        draftContinueActionConfiguration: {
                          disabled: Boolean(serviceRequestIsRestricted),
                          hidden: Boolean(serviceRequestIsRestricted),
                        },
                        draftDeleteActionConfiguration: {
                          disabled: Boolean(serviceRequestIsRestricted),
                          hidden: Boolean(serviceRequestIsRestricted),
                          noRender: inSmartOnFhirWorkflow,
                        },
                        editStatusActionConfiguration: {
                          disabled: Boolean(serviceRequestIsRestricted),
                          hidden: Boolean(serviceRequestIsRestricted),
                          noRender: inSmartOnFhirWorkflow,
                        },
                      }}
                    />
                    {serviceRequest.serviceRequestRelations?.map((relation) => (
                      <ServiceRequestRelationDisplay
                        sourceServiceRequestId={serviceRequest.id}
                        serviceRequestRelation={relation}
                        patientId={serviceRequest.patient?.id}
                        key={relation.id}
                        className={patientSummaryStyles.relationDisplayContainer}
                        isOnPatientSummary
                      />
                    ))}
                  </AccordionSummary>
                  <AuthVisibilityMessage
                    serviceRequest={serviceRequest}
                    user={user}
                    isRestricted={serviceRequestIsRestricted}
                    isViewable={serviceRequestIsViewable}
                    visibilityToggleStatus={
                      serviceRequest?.restrictedDataSettings?.allowEditForAuthorizationParticipants
                    }
                  />
                </div>
                <div className={serviceRequestCardStyles.accordionCollapse}>
                  <AccordionDetails className={serviceRequestCardStyles.accordianDetails}>
                    {contentsVisible && (
                      <ServiceRequestSummaryDetails
                        serviceRequest={serviceRequest}
                        onEdit={onEdit}
                        srFormViewState={srFormViewState}
                        setSRFormViewState={setSRFormViewState}
                        targetReviewId={targetReviewId}
                        setTargetReview={setTargetReview}
                        initialIsExpanded={initialIsExpanded}
                        clinicalAssessmentSectionRef={scrollToClinicalAssessment ? ref : undefined}
                        populateDefaults={false}
                        setUpdatedServiceRequestPayload={setUpdatedServiceRequestPayload}
                        setIsEditView={setIsEditView}
                        serviceRequestRefetch={serviceRequestRefetch}
                        setServiceRequest={setServiceRequest}
                        isFaxEdit={isFaxEdit}
                        adhocLetter={adhocLetter}
                        singleAttachmentViewerRedesign={singleAttachmentViewerRedesign}
                        {...otherProps}
                      />
                    )}
                  </AccordionDetails>
                </div>
              </Accordion>
            </Tooltip>
          </div>
        )}
      </>
    );
  }
);

interface ServiceRequestPrintProps {
  serviceRequest: ServiceRequestResponse;
  onShowSummaryPrintout?: (arg0: ServiceRequestResponse) => void;
  onShowCallLog?: (
    arg0: ServiceRequestResponse,
    arg1: VatNotification | null | undefined,
    arg2: string | undefined
  ) => void;
  showNotificationViewer?: boolean;
}

export function ServiceRequestPrint({
  serviceRequest,
  onShowSummaryPrintout,
  onShowCallLog,
  showNotificationViewer,
}: ServiceRequestPrintProps) {
  const [printButtonAnchorEl, setPrintButtonAnchorEl] = useState<HTMLElement>();
  const [popSubmenu, setPopSubmenu] = useState<boolean>(false);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<HTMLElement | null>();
  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const [currentAndPreviousServiceRequests, setCurrentAndPreviousServiceRequests] =
    useState<ServiceRequestResponse[]>();
  const [currentAuthorization, setCurrentAuthorization] = useState<AuthorizationResponse>();
  const [generateCallLogPdf, setGenerateCallLogPdf] = useState<string | undefined>();
  const [vatNotification, setVatNotification] = useState<VatNotification[] | null>();
  const canViewFinalDeterminationLetter = useAuthorized("FINAL_DETERMINATION_LETTER_DISPLAY");

  const handleClickPrintButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setPrintButtonAnchorEl(event.currentTarget);
    if (canViewFinalDeterminationLetter) {
      vatNotificationCall();
    }
  };
  const handleClosePrintMenu = () => {
    setPrintButtonAnchorEl(undefined);
  };

  const handleServiceSummaryClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onShowSummaryPrintout?.(serviceRequest);
    handleClosePrintMenu();
    setPopSubmenu(false);
  };
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: vatInfo,
    refetch: vatNotificationCall,
    error: vatInfoError,
  } = useGetVatNotification({
    queryParams: { serviceRequestId: `eq:${serviceRequest?.id}`, patientId: `eq:${serviceRequest?.patient?.id || ""}` },
    lazy: true,
  });

  const callDisposition = (vn?: VatNotification | null) => {
    if (
      vn?.interventionResult === "CallCompleted" &&
      (vn?.status === "AUTHENTICATED" || vn?.status === "INBOUND_AUTHENTICATED")
    ) {
      return "Member verified identity and received verbal notification of service request approval";
    } else if (vn?.status === "ANSWERING_MACHINE") {
      return "Answering machine message was left with verbal notification of service request approval";
    }
  };
  useEffect(() => {
    if (vatInfoError) {
      if (vatInfoError.status === 403) {
        enqueueSnackbar("You do not have permission to view Vat", { variant: "error" });
      } else {
        enqueueSnackbar(`There was an error retrieving vat information ${vatInfoError.message}`, {
          variant: "error",
        });
      }
    }
  }, [vatInfoError, enqueueSnackbar]);

  useEffect(() => {
    if (vatInfo) {
      const callDispositionMessage = callDisposition(vatInfo.find((vn) => vn.successful));
      setGenerateCallLogPdf(callDispositionMessage);
      setVatNotification(vatInfo);
    }
  }, [vatInfo]);

  const { refetch: refetchAuthorization, error: getAuthorizationByIdError } = useGetAuthorizationByIdWithFallback({
    id: serviceRequest?.authorization?.id || "",
    lazy: true,
    queryParams: {
      view: "expanded",
    },
  });

  useEffect(() => {
    const getAuthorization = async () => {
      const authorization = await refetchAuthorization();
      const currentAndPreviousServiceRequests = authorization?.serviceRequestsOnAuth?.filter(
        (it) =>
          parseDateFromISOString(serviceRequest.dateCreated).getTime() >=
          parseDateFromISOString(it.dateCreated).getTime()
      );
      setCurrentAndPreviousServiceRequests(currentAndPreviousServiceRequests);
      if (authorization) {
        setCurrentAuthorization(authorization);
      }
    };
    if (serviceRequest?.authorization?.id) {
      getAuthorization();
    }
  }, [refetchAuthorization, serviceRequest?.authorization?.id, serviceRequest.dateCreated]);

  useEffect(() => {
    if (getAuthorizationByIdError) {
      enqueueSnackbar(`Error while getting authorization using auth Id ${getAuthorizationByIdError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, getAuthorizationByIdError]);

  const handleOpenSearchModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSearchModalOpen(true);
  };

  return (
    <>
      <AuthorizationSummaryDropDownMenu
        handlePrintClick={(e) => {
          e.stopPropagation();
          setPopSubmenu(true);
          setSubmenuAnchorEl(e.currentTarget);
        }}
        handleClosePrintMenu={handleClosePrintMenu}
        handleCloseSubmenu={(e: React.MouseEvent) => {
          e.stopPropagation();
          setPopSubmenu(false);
          setSubmenuAnchorEl(null);
        }}
        handleServiceSummaryClick={handleServiceSummaryClick}
        handleClickPrintButton={handleClickPrintButton}
        handleOpenSearchModal={handleOpenSearchModal}
        printButtonAnchorEl={printButtonAnchorEl}
        popoverOpen={popSubmenu}
        submenuAnchorEl={submenuAnchorEl}
        generateCallLogPdf={generateCallLogPdf}
        serviceRequest={serviceRequest}
        currentAndPreviousServiceRequests={currentAndPreviousServiceRequests}
        authorization={currentAuthorization}
        printButtonDisabled={!onShowSummaryPrintout}
        searchModalOpen={searchModalOpen}
        vatNotification={vatNotification}
        handleCloseSearchModal={() => setSearchModalOpen(false)}
        showNotificationViewer={showNotificationViewer}
      />
      <PatientSearchModal
        healthPlanName={serviceRequest?.healthPlanName ?? ""}
        open={searchModalOpen}
        onClose={() => setSearchModalOpen(false)}
        authorizationTransfer={{
          authorizationId: serviceRequest?.authorization?.id,
          displayAuthorizationTransfer: true,
        }}
      />
    </>
  );
}

export default ServiceRequestSummaryCard;
