import { Dispatch, ReactNode, SetStateAction, useState } from "react";

import {
  Body1,
  Caption,
  CohereTable,
  Column,
  InformativeModal,
  PaginateProps,
  TableWithPanels,
  TableWithPanelsColumn,
  colorsLight,
  formatDateStr,
} from "@coherehealth/common";
import { AuthorizationResponse, GroupedPatientStayDate, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { aggregateStayDateByCommonFields } from "components/ServiceRequest/PatientStay/PatientStays";
import { PatientStayDateRange } from "common/SharedServiceRequestFormComponents";
import { differenceInDays } from "date-fns";
import useFetchPatientStayDates from "../hooks/useFetchPatientStayDates";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Box, Divider, Grid, IconButton, styled } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

interface PatientStayModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  authorization?: AuthorizationResponse;
  currentServiceRequest?: ServiceRequestResponse;
}

type PatientStayDateRecords = NonNullable<GroupedPatientStayDate["record"]>;
type PatientStayRecordStatus = NonNullable<PatientStayDateRecords["status"]>;
type PatientStayDateDatas = NonNullable<GroupedPatientStayDate["data"]>;

const PatientStayModal = ({ open, setOpen, authorization }: PatientStayModalProps) => {
  const serviceRequestsOnAuth = authorization?.serviceRequestsOnAuth || [];
  const data = authorizationPatientStayDataMapper(serviceRequestsOnAuth);
  const { patientStayDatesHistory, expandedReviewStayDatesEdits } = useFetchPatientStayDates(authorization?.id);
  const [paginateParams, setPaginateParams] = useState<PaginateProps>({ max: 5, offset: 0 });

  const PatientStayDatesRowChildTable = ({ patientStayDates }: { patientStayDates: PatientStayDateRecords[] }) => {
    const hideDividers = [0];
    return (
      <Box>
        <CustomDivider>
          <StyledBody1>PREVIOUS DECISIONS</StyledBody1>
        </CustomDivider>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <s>
              <CohereTable<PatientStayDateRecords>
                tableColumns={patientStayCollapsibleColumns}
                data={patientStayDates}
                hideRowHoverBorderColor={true}
                cardHeight={"short"}
                readonly={true}
                omitHeader
                hideRowDividers={hideDividers}
                hideFinalDivider={true}
              />
            </s>
          </Box>
        </Box>
      </Box>
    );
  };

  const rowExpansion = (patientStayDates: GroupedPatientStayDate) => {
    if (!patientStayDates || patientStayDates.data?.length === 0) {
      return undefined;
    }

    return (
      <div style={{ backgroundColor: colorsLight.background.panel, borderLeft: "4px solid #039EC3" }}>
        <PatientStayDatesRowChildTable patientStayDates={patientStayDates.data || []} />
      </div>
    );
  };

  return (
    <InformativeModal
      headerText="Patient stay details"
      open={open}
      onClose={() => setOpen(false)}
      subHeaderElement={
        expandedReviewStayDatesEdits ? (
          <CohereTable<GroupedPatientStayDate>
            paginateParams={paginateParams}
            setPaginateParams={setPaginateParams}
            displayMax={paginateParams.max ? paginateParams.max - 1 : 5}
            tableColumns={patientStayCollapsibleColumnsTopLevel || []}
            data={patientStayDatesHistory || []}
            hideRowHoverBorderColor={true}
            cardHeight={"short"}
            readonly={true}
            rowExpansion={rowExpansion}
            paginateViaParams
            hideRowPerPageDropDown={true}
            headerMargin="16px 0px 16px 0px"
            dataPublic={true}
          />
        ) : (
          <div style={{ display: "flex", justifyContent: "center", padding: "16px 0px" }}>
            <TableWithPanels columns={patientStayColumns} panels={data.flatMap((stay) => stay || [])} />
          </div>
        )
      }
      primaryButtonAction={() => setOpen(false)}
      primaryButtonText="Close"
      maxWidth="lg"
      showDivider={false}
    />
  );
};

export const stayToTableEntryValueMapper = (patientStayDateRange: PatientStayDateRange): PatientStayDateRecords => {
  const dates =
    patientStayDateRange.rangeStartDate !== patientStayDateRange.rangeEndDate
      ? `${formatDateStr(patientStayDateRange.rangeStartDate || undefined)} - ${formatDateStr(
          patientStayDateRange.rangeEndDate || undefined
        )}`
      : `${formatDateStr(patientStayDateRange.rangeStartDate || undefined)}`;

  const dateCount =
    patientStayDateRange.rangeStartDate && patientStayDateRange.rangeEndDate
      ? differenceInDays(patientStayDateRange.rangeEndDate, patientStayDateRange.rangeStartDate) + 1
      : 0;

  const days = dateCount !== 1 ? `${dateCount} days` : `${dateCount} day`;
  const reviewStatus =
    patientStayDateRange.reviewStatus === "AMENDED"
      ? patientStayDateRange.initialReviewDecision
      : patientStayDateRange.reviewStatus;

  return {
    dates: tableItemWithFallback(dates),
    days: tableItemWithFallback(days),
    requestedLoc: tableItemWithFallback(patientStayDateRange.requestedLevelOfCareCode || undefined),
    approvedLoc: tableItemWithFallback(patientStayDateRange.approvedLevelOfCareCode || undefined),
    status: tableItemWithFallback(
      readableReviewStatus(reviewStatus as PatientStayRecordStatus)
    ) as PatientStayRecordStatus,
  };
};

export const authorizationPatientStayDataMapper = (serviceRequestsOnAuth: ServiceRequestResponse[] | undefined) => {
  const res: PatientStayDateRecords[] = [];

  if (serviceRequestsOnAuth) {
    const sortedSrs = serviceRequestsOnAuth
      .slice()
      .sort((a, b) => Date.parse(b.startDate || b.dateCreated) - Date.parse(a.startDate || a.dateCreated));
    // Filter SRs that is not voided or withdrawn
    sortedSrs.forEach((serviceRequest) => {
      const patientStayDateRanges =
        serviceRequest.authStatus !== "VOIDED" &&
        aggregateStayDateByCommonFields(serviceRequest.patientStayDates).reverse();

      if (patientStayDateRanges) {
        const tableData = patientStayDateRanges.map((stayChunk) => {
          if (stayChunk.reviewStatus !== "VOID") {
            return stayToTableEntryValueMapper(stayChunk);
          } else {
            return {
              dates: "",
              days: "",
              requestedLoc: "",
              approvedLoc: "",
              status: "" as PatientStayRecordStatus,
            };
          }
        });

        res.push(...tableData.filter((ele) => ele.dates !== ""));
      }
    });
  }
  return res;
};

const patientStayColumns: TableWithPanelsColumn<PatientStayDateRecords>[] = [
  { columnName: "Dates of stay", value: (stay) => stay.dates },
  { columnName: "# of days", value: (stay) => stay.days },
  { columnName: "Requested LOC", value: (stay) => stay.requestedLoc },
  { columnName: "Approved LOC", value: (stay) => stay.approvedLoc },
  { columnName: "Status", value: (stay) => stay.status },
];

const patientStayCollapsibleColumns: Column<NonNullable<GroupedPatientStayDate["record"]>>[] = [
  {
    name: "",
    value: () => false,
    width: "30px",
    themedPaddingRight: 2,
    header: <></>,
  },
  { name: "", value: () => false, themedPaddingRight: 2, width: "200px" },
  { name: "", value: () => false, themedPaddingRight: 2, width: "100px" },
  {
    name: "requestedLoc",
    label: "Requested LOC",
    value: (stay) => <StyledBody1>{stay.requestedLoc}</StyledBody1>,
    themedPaddingRight: 2,
    width: "150px",
  },
  {
    name: "approvedLoc",
    label: "Approved LOC",
    value: (stay) => <StyledBody1>{stay.approvedLoc}</StyledBody1>,
    themedPaddingRight: 2,
    width: "150px",
  },
  { name: "status", label: "Status", value: (stay) => stay.status, themedPaddingRight: 2, width: "125px" },
];

const renderExpandElement = (data: PatientStayDateDatas, isExpanded?: boolean) =>
  data && data.length > 0 ? (
    <>
      <IconButton style={{ padding: 0 }} color="primary" size="small">
        {isExpanded ? <ExpandLess style={{ fontSize: "20px" }} /> : <ExpandMore style={{ fontSize: "20px" }} />}
      </IconButton>
    </>
  ) : null;

const patientStayCollapsibleColumnsTopLevel: Column<GroupedPatientStayDate>[] = [
  {
    name: "expand",
    value: (stay, isExpanded) => renderExpandElement(stay.data || [], isExpanded),
    width: "30px",
    themedPaddingRight: 2,
    header: <></>,
  },
  {
    name: "dates",
    value: (stay) => <StyledBody1>{stay.record?.dates}</StyledBody1>,
    themedPaddingRight: 2,
    width: "210px",
    header: <Caption color="textSecondary">Dates of stay</Caption>,
  },
  {
    name: "days",
    value: (stay) => <StyledBody1>{stay.record?.days}</StyledBody1>,
    themedPaddingRight: 2,
    width: "100px",
    header: <Caption color="textSecondary"># of days</Caption>,
  },
  {
    name: "requestedLoc",
    value: (stay) => <StyledBody1>{stay.record?.requestedLoc}</StyledBody1>,
    themedPaddingRight: 2,
    width: "150px",
    header: <Caption color="textSecondary">Requested LOC</Caption>,
  },
  {
    name: "approvedLoc",
    value: (stay) => <StyledBody1>{stay.record?.approvedLoc}</StyledBody1>,
    themedPaddingRight: 2,
    width: "150px",
    header: <Caption color="textSecondary">Approved LOC</Caption>,
  },
  {
    name: "status",
    label: "Status",
    value: (stay) => <StyledBody1>{stay.record?.status}</StyledBody1>,
    themedPaddingRight: 2,
    width: "125px",
    header: <Caption color="textSecondary">Status</Caption>,
  },
];
const readableReviewStatus = (reviewStatus?: PatientStayRecordStatus | null) => {
  return reviewStatus === "APPROVED"
    ? "Approved"
    : reviewStatus === "DENIED"
    ? "Denied"
    : reviewStatus === "PENDING"
    ? "Pending review"
    : "";
};

const tableItemWithFallback = (entry?: string) => entry || "--";

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const StyledBody1 = styled(Body1)(({ theme }) => ({
  color: colorsLight.font.main,
}));

const CustomDivider = ({ children }: { children: ReactNode }) => {
  return (
    <Grid container alignItems="center" spacing={3} style={{ paddingLeft: "28px" }}>
      <Grid item>{children}</Grid>
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default PatientStayModal;
