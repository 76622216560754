import { useEffect, useState } from "react";
import {
  AuthorizationResponse,
  ServiceRequestResponse,
  ServiceRequestCommandPayload,
} from "@coherehealth/core-platform-api";
import { useFeature } from "@coherehealth/common";
interface FetchReviewOutcomeProps {
  serviceRequest?: ServiceRequestResponse;
  authorization?: AuthorizationResponse;
}

export default function useReadmission({ serviceRequest, authorization }: FetchReviewOutcomeProps) {
  const [isReadmission, setIsReadmission] = useState<boolean>(false);
  const [anchorAuthNumber, setAnchorAuthNumber] = useState<string>("");
  const showPatientStayTimeline = useFeature("showPatientStayTimeline");
  const wasReadmission = showPatientStayTimeline ? authorization?.isReadmission : serviceRequest?.isReadmission;
  const previousAnchorAuthNumber = showPatientStayTimeline
    ? authorization?.anchorAuthNumber
    : serviceRequest?.anchorAuthNumber;
  useEffect(() => {
    if (showPatientStayTimeline) {
      if (authorization?.isReadmission) {
        setIsReadmission(true);
      }
      if (authorization?.anchorAuthNumber) {
        setAnchorAuthNumber(authorization.anchorAuthNumber);
      }
    } else {
      if (serviceRequest?.isReadmission) {
        setIsReadmission(true);
      }
      if (serviceRequest?.anchorAuthNumber) {
        setAnchorAuthNumber(serviceRequest.anchorAuthNumber);
      }
    }
  }, [
    authorization?.anchorAuthNumber,
    authorization?.isReadmission,
    serviceRequest?.anchorAuthNumber,
    serviceRequest?.isReadmission,
    showPatientStayTimeline,
  ]);

  return {
    isReadmission,
    setIsReadmission,
    anchorAuthNumber,
    setAnchorAuthNumber,
    wasReadmission,
    previousAnchorAuthNumber,
  };
}

export const useCreateReadmissionPayload = () => {
  const showPatientStayTimeline = useFeature("showPatientStayTimeline");
  const createReadmissionPayload = (
    payload: ServiceRequestCommandPayload,
    continuationReadmission?: boolean,
    continuationAnchorAuthNumber?: string
  ) => {
    if (showPatientStayTimeline) {
      return {
        ...payload,
        isReadmission: continuationReadmission,
        anchorAuthNumber: continuationAnchorAuthNumber,
      };
    }
    return payload;
  };
  return createReadmissionPayload;
};
