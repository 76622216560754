import { PrimaryButton, SecondaryButton, TertiaryButton, useConfiguration, useFeature } from "@coherehealth/common";
import { useReviewControlPanelStyles } from "./reviewControlPanelStyles";
import ReviewControlPanelExitActions from "./ReviewControlPanelExitActions";
import { useCallback, useContext } from "react";
import { ClinicalReviewContext } from "components/ClinicalReview/reviewUtils/clinicalReviewPageUtils";

export type ReviewControlPanelProps = {
  handleDiscardAndExit?: () => void;
  discarding?: boolean;
  discardAndExitDisabled: boolean;
  handleSaveAndExit?: () => void;
  saveAndExitLoading: boolean;
  saveAndExitDisabled: boolean;
  handleFinishReview: () => void;
  finishButtonDisabled: boolean;
  canSaveDraft?: boolean;
  currentlySavingDraft?: boolean;
  saveForLaterDisabled?: boolean;
  handleHeadingClick?: () => void;
  classes?: {
    finishButton?: string;
    discardAndExitButton?: string;
    saveAndExitButton?: string;
    saveReviewButton?: string;
    discardReviewButton?: string;
  };
  reviewType?: string;
  submitting?: boolean;
  isAuditReview?: boolean;
  saveReviewChatbotFindings?: () => void;
};

export const ReviewControlPanel = ({
  handleDiscardAndExit,
  handleSaveAndExit,
  handleFinishReview,
  discarding,
  discardAndExitDisabled,
  reviewType,
  saveAndExitLoading,
  saveAndExitDisabled,
  finishButtonDisabled,
  canSaveDraft,
  currentlySavingDraft,
  saveForLaterDisabled,
  handleHeadingClick,
  submitting,
  isAuditReview,
  saveReviewChatbotFindings,
}: ReviewControlPanelProps) => {
  const { healthPlanName, delegatedVendorName, queue } = useContext(ClinicalReviewContext);
  const saveReviews = useFeature("saveReviews");
  const showServiceCaseUserHolds = useFeature("showServiceCaseUserHolds");
  const caseHoldConfig = useConfiguration("caseHoldConfiguration", healthPlanName, delegatedVendorName);
  const configEnabled = Boolean(caseHoldConfig?.enabled && queue?.holdsEnabled);

  const holdsEnabled = showServiceCaseUserHolds && configEnabled;

  const classes = useReviewControlPanelStyles({
    saveReviews,
  });
  const buttonLabels = {
    discardAndExit: "Discard and exit",
    saveAndExit: "Save and exit",
    finishReview: isAuditReview ? "Finish audit" : "Finish review",
    saveForLater: "Save for later",
    discardLabel: "Discard",
  };

  const doHandleSaveAndExit = useCallback(() => {
    if (saveReviewChatbotFindings) {
      saveReviewChatbotFindings();
    }
    if (handleSaveAndExit) {
      return handleSaveAndExit();
    }
  }, [handleSaveAndExit, saveReviewChatbotFindings]);

  const doHandleFinishReview = useCallback(() => {
    if (saveReviewChatbotFindings) {
      saveReviewChatbotFindings();
    }
    return handleFinishReview();
  }, [handleFinishReview, saveReviewChatbotFindings]);

  const doHandleHeadingClick = useCallback(() => {
    if (saveReviewChatbotFindings) {
      saveReviewChatbotFindings();
    }
    if (handleHeadingClick) {
      return handleHeadingClick();
    }
  }, [handleHeadingClick, saveReviewChatbotFindings]);

  return (
    <>
      {saveReviews ? (
        <>
          {holdsEnabled ? (
            <ReviewControlPanelExitActions
              handleSaveAndExit={doHandleSaveAndExit}
              handleDiscardAndExit={handleDiscardAndExit}
              discardAndExitDisabled={discardAndExitDisabled}
              saveAndExitDisabled={saveAndExitDisabled}
              saveAndExitLoading={saveAndExitLoading}
              submitting={submitting}
              discarding={discarding}
              holdReasons={queue?.holdReasons}
            />
          ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <TertiaryButton
                onClick={handleDiscardAndExit}
                loading={discarding}
                disabled={discardAndExitDisabled}
                className={classes?.discardAndExitButton}
                data-testid="controls-discard-and-exit"
              >
                {buttonLabels.discardAndExit}
              </TertiaryButton>
              <TertiaryButton
                data-public
                onClick={doHandleSaveAndExit}
                loading={saveAndExitLoading}
                disabled={saveAndExitDisabled}
                className={classes?.saveAndExitButton}
                data-testid="controls-save-and-exit"
              >
                {buttonLabels.saveAndExit}
              </TertiaryButton>
            </div>
          )}
          <PrimaryButton
            onClick={doHandleFinishReview}
            disabled={finishButtonDisabled}
            disableRipple
            className={classes?.finishButton}
            loading={submitting}
            data-testid="controls-finish-review-0"
          >
            {buttonLabels.finishReview}
          </PrimaryButton>
        </>
      ) : (
        <>
          {canSaveDraft ? (
            <SecondaryButton
              warning
              onClick={doHandleHeadingClick}
              loading={currentlySavingDraft}
              disabled={saveForLaterDisabled}
              className={classes?.saveAndExitButton}
              data-testid="controls-save-for-later"
            >
              {buttonLabels.saveForLater}
            </SecondaryButton>
          ) : (
            <TertiaryButton
              loading={discarding}
              disabled={saveForLaterDisabled}
              onClick={handleDiscardAndExit}
              className={classes?.discardReviewButton}
              data-testid="controls-discard"
            >
              {buttonLabels.discardLabel}
            </TertiaryButton>
          )}
          <PrimaryButton
            onClick={doHandleFinishReview}
            disableRipple
            disabled={finishButtonDisabled}
            className={classes?.finishButton}
            loading={submitting}
            data-testid="controls-finish-review-1"
          >
            {buttonLabels.finishReview}
          </PrimaryButton>
        </>
      )}
    </>
  );
};
