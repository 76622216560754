import React from "react";
import { NavLink, generatePath } from "react-router-dom";
import routes from "routes";
import { Body1, formatDateStr, SecondaryButton } from "@coherehealth/common";
import { Patient, RedirectRuleAction } from "@coherehealth/core-platform-api";
import { ImpressionTrackingProps } from "../ImpressionTracking";
import { TrackUserActivityProps, trackUserActivity } from "util/userActivityTracker";
import { usePatientSearchResultInteractionStyles } from "./PatientSearchResultInteractionStyles";
import { Grid } from "@material-ui/core";
import { AuthorizationTransferProps } from "common/UnifiedPatientSearchModal/UnifiedPatientSearchModal";

interface PatientSearchResultInteractionProps {
  authorizationTransfer?: AuthorizationTransferProps;
  healthPlanName?: string | null;
  onTransferAuth?: (event: React.MouseEvent, receiverPatientId: string) => void;
  patient: Patient;
  redirect?: RedirectRuleAction;
  selectActionLoading?: boolean;
  authSelectLink?: string;
  referralSelectLink?: string;
  selectOnClick?: (arg0: Patient) => void;
  showSummaryLink?: boolean;
  trackInteraction: (props: TrackUserActivityProps) => Promise<void>;
  userActivityParams: ImpressionTrackingProps;
}

export function PatientSearchResultInteraction(props: PatientSearchResultInteractionProps): JSX.Element | null {
  const {
    authorizationTransfer,
    healthPlanName,
    onTransferAuth,
    patient,
    redirect,
    selectActionLoading,
    authSelectLink,
    referralSelectLink,
    selectOnClick,
    showSummaryLink,
    trackInteraction,
    userActivityParams,
  } = props;
  const classes = usePatientSearchResultInteractionStyles();

  if (authorizationTransfer?.displayAuthorizationTransfer) {
    return (
      <div className={classes.authTransferButtonWrapper}>
        <SecondaryButton
          fullWidth
          className={classes.authTransferButton}
          onClick={(event: React.MouseEvent) => onTransferAuth?.(event, patient.id)}
        >
          Transfer
        </SecondaryButton>
      </div>
    );
  }
  if (authSelectLink && showSummaryLink) {
    return (
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={6}>
          {authSelectLink && showSummaryLink && (
            <SecondaryButton
              className={classes.startAuthOrReferralRequestButton}
              fullWidth
              component={NavLink}
              data-pendo-health-plan={healthPlanName ? healthPlanName + "-patient" : "noHealthPlan"}
              onClick={() => {
                healthPlanName
                  ? trackUserActivity(healthPlanName + "-patient")
                  : trackUserActivity(healthPlanName + "-noHealthPlan");
                redirect && trackInteraction({ ...userActivityParams, interactionAccept: false });
              }}
              to={generatePath(routes.AUTH_BUILDER, { patientId: patient.id })}
            >
              Start auth request
            </SecondaryButton>
          )}
        </Grid>
        <Grid item xs={6}>
          {referralSelectLink && patient.referralEligibility?.delegated && showSummaryLink && (
            <SecondaryButton
              className={classes.startAuthOrReferralRequestButton}
              fullWidth
              component={NavLink}
              data-pendo-health-plan={healthPlanName ? healthPlanName + "-patient" : "noHealthPlan"}
              onClick={() => {
                healthPlanName
                  ? trackUserActivity(healthPlanName + "-patient")
                  : trackUserActivity(healthPlanName + "-noHealthPlan");
                redirect && trackInteraction({ ...userActivityParams, interactionAccept: false });
              }}
              to={generatePath(routes.REFERRAL_BUILDER, { patientId: patient.id })}
            >
              Start referral
            </SecondaryButton>
          )}
        </Grid>
      </Grid>
    );
  }

  if (authSelectLink && !showSummaryLink) {
    return (
      <SecondaryButton
        fullWidth
        component={NavLink}
        onClick={() => {
          redirect && trackInteraction({ ...userActivityParams, interactionAccept: false });
        }}
        to={generatePath(routes.AUTH_BUILDER, { patientId: patient.id })}
        data-public
      >
        Select patient
      </SecondaryButton>
    );
  }

  if (selectOnClick) {
    return (
      <div className={classes.startAuthRequestButtonWrapper}>
        <Body1 className={classes.patientDetails}>DOB: {formatDateStr(patient.dateOfBirth)}</Body1>
        <SecondaryButton
          className={classes.tightSelectPatientButton}
          fullWidth
          onClick={() => selectOnClick(patient)}
          loading={selectActionLoading}
          data-public
        >
          Select patient
        </SecondaryButton>
      </div>
    );
  }

  return null;
}
