import { ReviewsProvider } from "../reviewUtils/useReviews";
import ClinicalReviewPage from "../Review/ClinicalReviewPage";
import ViewOnlyReviewPage from "../ViewOnlyReview/ViewOnlyReviewPage";
import { useGetAllReviews } from "@coherehealth/core-platform-api";
import { useParams, useSearchParams } from "react-router-dom";
import {
  HighlightProvider,
  HighlightReviewNoteProvider,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import { ReviewPageVersion, useGetUser } from "./utils";

import useOtherAuthorizations from "../ClinicalReviewInfoPanel/OtherServiceRequests/useOtherAuthorization";
import useClaimHistory from "components/ClaimHistory/useClaimHistory";

export interface Props {
  viewOnly?: boolean;
  reviewPageVersion?: ReviewPageVersion;
}

export function ClinicalReviewPageWrapper({ viewOnly, reviewPageVersion }: Props) {
  const { serviceRequestId } = useParams();
  const [search] = useSearchParams();

  const caseId = search.get("caseId") || "";
  const serviceRequestIdFromUrl = (viewOnly ? search.get("serviceRequestId") : serviceRequestId) || "";
  const origin = search.get("origin") || "";
  const isReviewQueue =
    origin === "qm_supervisor_dashboard" || origin === "supervisor_reviewQueue" || origin === "dashboard";
  const trackingNumberSearch = !!search.get("trackingNumberSearch");
  const {
    data: existingReviews,
    error: existingReviewsError,
    loading: loadingExistingReviews,
  } = useGetAllReviews({
    serviceRequestId: serviceRequestIdFromUrl,
    queryParams: { max: 200 },
  });
  const user = useGetUser();
  const getSerivceRequestProps = useGetServiceRequestByIdWithFallback({
    id: serviceRequestIdFromUrl,
  });
  const { data: serviceRequest } = getSerivceRequestProps;
  const otherAuthsProps = useOtherAuthorizations({ serviceRequest });
  const claimHistoryProps = useClaimHistory({ patientId: serviceRequest?.patient?.id });

  return (
    <ReviewsProvider initialReviews={existingReviews}>
      {viewOnly ? (
        <ViewOnlyReviewPage
          existingReviews={existingReviews}
          existingReviewsError={existingReviewsError}
          loadingExistingReviews={loadingExistingReviews}
          caseId={caseId}
          serviceRequestId={serviceRequestIdFromUrl}
          trackingNumberSearch={trackingNumberSearch}
          isReviewQueue={isReviewQueue}
          {...otherAuthsProps}
          {...claimHistoryProps}
        />
      ) : (
        <HighlightProvider currentUserId={user?.sub}>
          <HighlightReviewNoteProvider>
            <ClinicalReviewPage
              reviewPageVersion={reviewPageVersion}
              existingReviews={existingReviews}
              existingReviewsError={existingReviewsError}
              loadingExistingReviews={loadingExistingReviews}
              serviceRequestId={serviceRequestIdFromUrl}
              caseId={caseId}
              trackingNumberSearch={trackingNumberSearch}
              getSerivceRequestProps={getSerivceRequestProps}
              {...otherAuthsProps}
              {...claimHistoryProps}
            />
          </HighlightReviewNoteProvider>
        </HighlightProvider>
      )}
    </ReviewsProvider>
  );
}

export default ClinicalReviewPageWrapper;
