import React, { Dispatch } from "react";

import { DropdownOption, SingleSelectDropdown } from "@coherehealth/common";
import { useTheme } from "@material-ui/core";
import { AuthCategory, AuthCategoryResponse } from "@coherehealth/core-platform-api";

interface Props {
  onSelectAuthCategory: Dispatch<AuthCategory>;
  selectedAuthCategory: AuthCategory | null;
  authCategoryOptions: AuthCategoryResponse[];
  attemptedSubmit: boolean;
  isLoading: boolean;
  onPatientSummary?: boolean;
  isInpatient?: boolean;
  onProviderDetailsPage?: boolean;
  onFillFormsPage?: boolean;
  disabled?: boolean;
  isAutoFilled?: boolean;
}

interface AuthCategoryOption extends DropdownOption {
  id: AuthCategory;
}

export function AuthCategorySelect({
  selectedAuthCategory,
  onSelectAuthCategory,
  authCategoryOptions,
  attemptedSubmit,
  isLoading,
  onPatientSummary,
  isInpatient,
  onProviderDetailsPage,
  onFillFormsPage,
  disabled,
  isAutoFilled,
}: Props) {
  const hasError = attemptedSubmit && !selectedAuthCategory;
  const helperText = hasError ? "Required" : "";
  const sortedAuthCategories: AuthCategoryOption[] = authCategoryOptions
    .map((authCategoryResponse) => ({
      id: authCategoryResponse.enumName,
      label: (authCategoryResponse.name || authCategoryResponse.enumName) ?? "",
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const theme = useTheme();

  const authCategorySpacing = hasError ? 10 : 7;
  const isDisabled = disabled || onPatientSummary || onProviderDetailsPage || onFillFormsPage || isInpatient;
  const showAutoFillIcon = !isDisabled && isAutoFilled;
  return (
    <SingleSelectDropdown<AuthCategoryOption>
      error={hasError}
      helperText={helperText}
      label="Auth category"
      options={isLoading ? undefined : sortedAuthCategories}
      value={selectedAuthCategory ?? ""}
      onChange={onSelectAuthCategory}
      showLoadingIcon={isLoading}
      style={{ marginTop: "-4px", height: theme.spacing(authCategorySpacing) }}
      disabled={isDisabled}
      showAutoFillIcon={showAutoFillIcon}
      data-public
    />
  );
}
