import React from "react";
import { ReactNode } from "react";
import { Drawer, DrawerProps, IconButton } from "@material-ui/core";

// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Block, Cancel } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "tss-react/mui";
import { PrimaryButton } from "../PrimaryButton";
import { Body1, Body2, H4 } from "../Typography";
import { Card } from "../Card";

interface RigthDrawerProps extends DrawerProps {
  open: boolean;
  onClose: (save: boolean) => void;
  closeDisabled?: boolean;
  children: ReactNode;
  buttonText?: string;
  ctaErrorText?: string;
  useValidationFormat?: boolean;
  loading?: boolean;
  dynamicWidth?: number;
  saveAttemptError?: boolean;
}

const customStyles = makeStyles()((theme) => ({
  defaultBottomContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingBottom: theme.spacing(5),
    paddingRight: theme.spacing(4),
    flexWrap: "wrap",
  },
  validationBottomContainer: {
    display: "flex",
    alignItems: "start",
    width: "100%",
    paddingBottom: theme.spacing(5),
    paddingRight: theme.spacing(4),
    flexWrap: "wrap",
  },
  withCtaErrorTextStatementContainer: {
    paddingBottom: theme.spacing(10),
  },
  rightAlignedRow: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(1.5),
  },
  drawerCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: 0,
    paddingBottom: 0,
  },
  bottomDivider: {
    width: "100%",
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
  },
  bottomDividerRedirect: {
    width: "100%",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    bottom: 0,
    width: "100%",
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(8),
    alignItems: "center",
  },
  statementContainer: {
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: theme.spacing(4),
    height: "100%",
  },
  validationIcon: {
    marginRight: theme.spacing(1),
    height: theme.spacing(2),
  },
  errorIcon: {
    marginRight: theme.spacing(1),
  },
  justifiedIconAndText: {
    display: "flex",
    alignSelf: "start",
    justifyContent: "left",
    lineHeight: "normal",
  },
  centeredIconAndText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "15px",
    lineHeight: "normal",
  },
  closeIcon: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  error: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
  requiredMessage: {
    color: "#C62828",
    fontSize: "small",
    textAlign: "left",
  },
  cancel: {
    color: "#C62828",
    marginRight: theme.spacing(1),
  },
}));

export default function RightDrawer({
  open,
  onClose,
  children,
  buttonText,
  // an optional error message above "Save" button
  ctaErrorText,
  // a variation of the error state with left-justified text and a cancel icon
  useValidationFormat,
  closeDisabled,
  loading,
  dynamicWidth,
  saveAttemptError,
}: RigthDrawerProps) {
  const { classes } = customStyles();

  return (
    <Drawer anchor="right" open={open} onClose={() => onClose(false)} disableEnforceFocus>
      <Card style={{ width: dynamicWidth ? dynamicWidth : 485 }} className={classes.drawerCard}>
        <div className={classes.rightAlignedRow}>
          <IconButton onClick={() => onClose(false)} data-testid="close-right-drawer-btn">
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        <div
          className={`${classes.statementContainer} ${ctaErrorText ? classes.withCtaErrorTextStatementContainer : ""}`}
        >
          {children}
        </div>
        <div
          className={
            ctaErrorText && useValidationFormat ? classes.validationBottomContainer : classes.defaultBottomContainer
          }
        >
          <Divider className={`${saveAttemptError ? classes.bottomDividerRedirect : classes.bottomDivider} `} />
          {ctaErrorText && !useValidationFormat && (
            <Body1
              className={classes.centeredIconAndText}
              style={{
                width: dynamicWidth ? "50%" : "100%",
              }}
            >
              <Block className={classes.errorIcon} />
              {ctaErrorText}
            </Body1>
          )}
          {ctaErrorText && useValidationFormat && (
            <Body2 className={classes.justifiedIconAndText}>
              <Cancel className={classes.validationIcon} />
              {ctaErrorText}
            </Body2>
          )}
          {saveAttemptError && (
            <div className={classes.error}>
              <Cancel className={classes.cancel} />
              <H4 className={classes.requiredMessage}>Complete Required Fields</H4>
            </div>
          )}
          <PrimaryButton
            disabled={closeDisabled}
            fullWidth
            onClick={() => onClose(true)}
            style={{ width: dynamicWidth ? "50%" : "100%" }}
            loading={!!loading}
            data-testid="right-drawer-primary-btn"
          >
            {buttonText ?? "Save"}
          </PrimaryButton>
        </div>
      </Card>
    </Drawer>
  );
}
