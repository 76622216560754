import React, { Dispatch, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { TextField, RichTextEditor } from "@coherehealth/common";
import {
  OutOfNetworkReview,
  CopyPasteInfo,
  ServiceRequestResponse,
  OutOfNetworkReviewConfigurationResponse,
} from "@coherehealth/core-platform-api";
import { useTheme } from "@material-ui/core/styles";
import { OutOfNetworkReviewUpdate } from "components/ClinicalReview/reviewUtils/useReviews";
import { RIGHT_HAND_PANEL_SIZE, usePasteTracker } from "components/ClinicalReview/reviewUtils/utils";
import { Box } from "@mui/material";
import { ReviewOutreachForm } from "components/ClinicalReview/reviewUtils/ReviewOutreachForm";
import { OutreachAttemptProps } from "../useOutreachAttempt";
import { NetworkStatusOverride } from "./NetworkStatusOverride/NetworkStatusOverride";
import { NetworkStatusOverrideOptionType } from "./NetworkStatusOverride/NeworkStatusOverrideSingleSelect";
import { ReviewControlBoxInnerContainer, ReviewControlBoxOuterContainer } from "../MDReview/MDReviewEdit";
import { ReviewControlPanel } from "../ReviewControlPanel/ReviewControlPanel";
import { useMediaQuery } from "@material-ui/core";

interface OutOfNetworkReviewEditProps {
  serviceRequest: ServiceRequestResponse;
  outOfNetworkReview: OutOfNetworkReview;
  setOutOfNetworkReview: OutOfNetworkReviewUpdate;
  copiedAttachmentText?: CopyPasteInfo;
  setPastedAttachmentTexts?: Dispatch<React.SetStateAction<CopyPasteInfo[] | undefined>>;
  outreachAttemptProps?: Partial<OutreachAttemptProps>;
  facilityNetworkStatusOverride: NetworkStatusOverrideOptionType | undefined;
  setFacilityNetworkStatusOverride: (networkStatus: NetworkStatusOverrideOptionType | undefined) => void;
  performingProviderNetworkStatusOverride: NetworkStatusOverrideOptionType | undefined;
  setPerformingNetworkStatusOverride: (networkStatus: NetworkStatusOverrideOptionType | undefined) => void;
  oonErrorStates?: Partial<Record<keyof OutOfNetworkReview, boolean>>;
  hideFooter?: boolean;
  loadingDiscardOrSave: boolean;
  savingReview: boolean;
  discardingReview: boolean;
  disableFinishButton: () => boolean;
  finishReviewLoading: boolean;
  discardReview: (embededMode?: boolean) => Promise<void>;
  saveReviewDraft: (embededMode?: boolean) => Promise<void>;
  onFinishReview: () => void;
  oonReviewConfig?: OutOfNetworkReviewConfigurationResponse;
  saveReviewChatbotFindings?: () => void;
}

export default function OutOfNetworkReviewEdit({
  serviceRequest,
  outOfNetworkReview,
  setOutOfNetworkReview,
  copiedAttachmentText,
  setPastedAttachmentTexts,
  outreachAttemptProps,
  facilityNetworkStatusOverride,
  setFacilityNetworkStatusOverride,
  performingProviderNetworkStatusOverride,
  setPerformingNetworkStatusOverride,
  oonErrorStates,
  hideFooter,
  discardingReview,
  loadingDiscardOrSave,
  discardReview,
  saveReviewDraft,
  savingReview,
  onFinishReview,
  disableFinishButton,
  oonReviewConfig,
  saveReviewChatbotFindings,
}: OutOfNetworkReviewEditProps) {
  const theme = useTheme();
  const setValue = (fieldName: keyof OutOfNetworkReview) => (val: string) => {
    setOutOfNetworkReview({ [fieldName]: val });
  };
  const matchesRight = useMediaQuery(theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE));

  usePasteTracker(copiedAttachmentText, setPastedAttachmentTexts);

  const {
    outreachAttempt,
    setOutreachAttempt,
    outreachFormConfig,
    outreachFormErrors,
    setOutreachFormErrors,
    hasOutreachAttempt,
    setHasOutreachAttempt,
  } = outreachAttemptProps || {};

  const oonExceptionReasoningLabel = useRef(
    outOfNetworkReview?.oonExceptionReasoning
      ? "Review note • Pre-filled using text from attachments and previous reviews"
      : "Reviewer notes"
  );
  const networkStatusOverride = oonReviewConfig?.networkStatusOverride;
  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: theme.spacing(6) }} component="form">
        <Grid item xs={12} style={{ paddingBottom: theme.spacing(0) }}>
          <Box sx={{ mb: 3 }}>
            <RichTextEditor
              testid="oon-review-exception-reasoning"
              label={oonExceptionReasoningLabel.current}
              readonly={false}
              error={oonErrorStates?.oonExceptionReasoning || false}
              htmlValue={outOfNetworkReview.oonExceptionReasoning || ""}
              setHtmlValue={setValue("oonExceptionReasoning")}
              helperText={oonErrorStates?.oonExceptionReasoning ? "Required" : ""}
            />
          </Box>
          <TextField
            data-testid="oon-review-cpn"
            style={{ marginBottom: theme.spacing(3) }}
            label={"Claims payment instructions"}
            fullWidth
            multiline
            minRows={4}
            value={outOfNetworkReview.claimsPaymentNote || ""}
            onChangeValue={setValue("claimsPaymentNote")}
          />
        </Grid>

        {networkStatusOverride && (
          <NetworkStatusOverride
            facilityNetworkStatusOverride={facilityNetworkStatusOverride}
            performingProviderNetworkStatusOverride={performingProviderNetworkStatusOverride}
            setPerformingNetworkStatusOverride={setPerformingNetworkStatusOverride}
            setFacilityNetworkStatusOverride={setFacilityNetworkStatusOverride}
            oonReviewConfig={oonReviewConfig}
            oonErrorStates={oonErrorStates}
          />
        )}

        <ReviewOutreachForm
          showOutreachToggle
          serviceRequest={serviceRequest}
          hasOutreachAttempt={hasOutreachAttempt}
          setHasOutreachAttempt={setHasOutreachAttempt}
          outreachAttempt={outreachAttempt}
          setOutreachAttempt={setOutreachAttempt}
          outreachFormConfig={outreachFormConfig}
          outreachFormErrors={outreachFormErrors}
          setOutreachFormErrors={setOutreachFormErrors}
        />
      </Grid>
      {!hideFooter && (
        <ReviewControlBoxOuterContainer matchesRight={matchesRight}>
          <ReviewControlBoxInnerContainer>
            <ReviewControlPanel
              reviewType={outOfNetworkReview.reviewType}
              discarding={discardingReview}
              discardAndExitDisabled={loadingDiscardOrSave}
              handleDiscardAndExit={discardReview}
              handleSaveAndExit={saveReviewDraft}
              saveAndExitLoading={savingReview}
              saveAndExitDisabled={loadingDiscardOrSave}
              handleFinishReview={onFinishReview}
              finishButtonDisabled={disableFinishButton()}
              saveReviewChatbotFindings={saveReviewChatbotFindings}
            />
          </ReviewControlBoxInnerContainer>
        </ReviewControlBoxOuterContainer>
      )}
    </>
  );
}
