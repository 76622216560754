import { format, isValid } from "date-fns";

export const MIN_START_DATE = new Date(2020, 0, 1);
export const timePattern = /^([01]\d|2[0-3]):([0-5]\d)(:[0-5]\d)?$/;

const ISO_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
/**
 * Formats date object to default ISO_DATETIME_FORMAT string
 */
export const formatDate = (date?: Date, dateFormat?: string): string => {
  if (date && isValid(date)) {
    return format(date, dateFormat || ISO_DATETIME_FORMAT).toLowerCase();
  }
  return "";
};

/**
 * Compares two ISO date strings
 * @param a - ISO date time string
 * @param b - ISO date time string
 * @returns -1 if a is before b, 1 if a is after b, 0 if a is equal to b
 */

export const isoDateTimeComparator = (a: string | undefined, b: string | undefined): number => {
  try {
    if (a && b) {
      return new Date(a).getTime() - new Date(b).getTime();
    }
    return 0;
  } catch (error) {
    return 0;
  }
};
