import React from "react";

import { Event, EventHint, EventProcessor, Hub } from "@sentry/types";
import {
  init as sentryInit,
  captureException,
  setUser as sentrySetUser,
  captureMessage,
  Integrations,
  configureScope,
  reactRouterV6Instrumentation,
} from "@sentry/react";
import { Dedupe, ExtraErrorData } from "@sentry/integrations";
import config from "api/config";
import { BrowserTracing } from "@sentry/tracing";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router";
//@ts-ignore
import { _mergeOptions, _shouldDropEvent } from "@sentry/core/cjs/integrations/inboundfilters.js";

export const IGNORE_ERRORS = [
  "Failed to fetch: Failed to execute 'fetch' on 'Window': The user aborted a request.",
  "Failed to fetch: Failed to fetch",
  "Failed to fetch: Fetch is aborted",
  "Failed to fetch: The operation was aborted.",
  "Failed to fetch: The user aborted a request.",
  "Failed to fetch: signal is aborted without reason",
  `error executing fetch: [{"message":"Failed to fetch: Failed to fetch","data":"Failed to fetch"}]`,
  `error executing fetch: [{"message":"Failed to fetch: Failed to fetch","data":""}]`,
  "Method messenger.close does not exist",
  "Method webWidget:on.open does not exist", // This actually comes from LogRocket, https://support.zendesk.com/hc/en-us/community/posts/4943694623386-Method-webWidget-on-open-does-not-exist-
  "Non-Error promise rejection captured with keys: data, message",
  "Non-Error promise rejection captured with keys: data, message, status",
  "The client specified not to prompt, but the user is not logged in.",
  "ResizeObserver loop limit exceeded",
  "Non-Error exception captured with keys: data, message",
  "Expired token",
  "Failed to fetch: Aborted",
  "useGetOktaToken produced undefined access token after attempted refresh",
  "AccessTokenClaim produced no valid token in auth token manager",
  new RegExp("HTTP status 404 from .user.*userExtension"),
];
const inboundFilters = new Integrations.InboundFilters({ ignoreErrors: IGNORE_ERRORS });

function _init() {
  sentryInit({
    dsn: config.SENTRY_DSN,
    environment: config.SENTRY_ENVIRONMENT,
    release: `${config.SENTRY_ENVIRONMENT}-${config.CURRENT_GIT_HASH}`,
    maxBreadcrumbs: 20,
    integrations: [
      inboundFilters,
      new ExtraErrorDataWithIgnore(),
      new Integrations.Breadcrumbs({
        // Turn off some default breadcrumbs to avoid leakage of PHI
        console: true,
        dom: false,
        fetch: false,
        history: true,
        sentry: true,
        xhr: false,
      }),
      new Dedupe(),
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: config.SENTRY_SAMPLE_RATE,
  });
  configureScope((scope) => {
    scope.setTag("sentry_trace_id", scope.getTransaction()?.traceId);
  });
}

/**
 * By default, ExtraErrorData integration does not take into account
 * the InboundFilters ignoreErrors data, which is annoying, because
 * we're ignoring stuff like "User aborted a request", but getting it
 * anyway as an unhandled promise rejection error.
 */
class ExtraErrorDataWithIgnore extends ExtraErrorData {
  public setupOnce(addGlobalEventProcessor: (callback: EventProcessor) => void, getCurrentHub: () => Hub): void {
    addGlobalEventProcessor((event: Event, hint?: EventHint) => {
      const self = getCurrentHub().getIntegration(ExtraErrorDataWithIgnore);
      if (!self) {
        return event;
      }

      const serialized = typeof event.extra?.__serialized__ == "object" ? event.extra.__serialized__ : {};
      const serializedEvent: Event = { ...event, ...serialized };
      // Private shmivate - the point of this is that inboundFilters should be able
      // to share its knowledge with other filters!
      if (_shouldDropEvent(serializedEvent, _mergeOptions({}))) {
        return null;
      }
      return self.enhanceEventWithErrorData(event, hint);
    });
  }
}

/* Never used - uncomment if you want it
function _info(...msg: any[]) {
  console.info(...msg);
  if (msg && msg.length === 1 && typeof msg[0] === "string") {
    captureMessage(trimPayload(msg[0]), "info");
  } else {
    captureMessage(trimPayload(JSON.stringify(msg)), "info");
  }
}
*/

function _warn(...msg: any[]) {
  console.warn(...msg);
  if (msg && msg.length === 1 && typeof msg[0] === "string") {
    captureMessage(trimPayload(msg[0]), "warning");
  } else {
    captureMessage(trimPayload(JSON.stringify(msg)), "warning");
  }
}

function _error(ex: any) {
  console.error(ex);
  // Lower threshold for errors: they have a stack trace that also needs to fit in
  if (ex && ex.message?.toString().length > 500) {
    captureException(`[payload too large] ${ex.message.substring(0, 500)}`);
  } else {
    captureException(ex);
  }
}

// It's actually 2kb for the whole payload, so this gives a little wiggle room
const PAYLOAD_MAX_CHARS = 2000;
const trimPayload = (msg: string) => msg.substring(0, PAYLOAD_MAX_CHARS);

function _setUser({ id, email }: { id: string | undefined; email: string | undefined }) {
  sentrySetUser({
    id,
    email,
  });
}

function safeWrap<I extends any[], R extends any>(f: (...x: I) => R) {
  return (...args: I): R | undefined => {
    if (config.SENTRY_ENABLED) {
      try {
        return f(...args);
      } catch (e) {
        console.error(e);
      }
    }
  };
}

export function stringifyError(err: any): string {
  if (err instanceof Object) {
    return JSON.stringify(err, Object.getOwnPropertyNames(err));
  }
  return err.toString();
}

export const init = safeWrap(_init);
// Unused - uncomment if you want it
//export const info = safeWrap(_info);
export const warn = safeWrap(_warn);
export const error = safeWrap(_error);
export const setUser = safeWrap(_setUser);

const MONGO_ID_REGEXP = /[a-f0-9]{24}/i;
/**
 * Remove URL GET parameters and IDs from the request:
 * removing the parameters to prevent PHI leakage, but
 * replacing the IDs for better aggregation of errors
 */
export function scrubUrl(urlString?: string): string {
  if (urlString) {
    try {
      const url = new URL(urlString);
      return url.pathname.replace(MONGO_ID_REGEXP, "{id}");
    } catch (e) {
      return "invalid url";
    }
  }
  return "";
}
