import {
  AuthStatus,
  ClinicalAssessment,
  ClinicalAssessmentResponse,
  ClinicalService,
  PendingReason,
  ProcedureCode,
  ResponseOption,
} from "@coherehealth/core-platform-api";
import { SubQuestionVisibilityState } from "components/ClinicalAssessment/ClinicalAssessmentContext";
import { Dispatch, SetStateAction } from "react";
import {
  ClinicalAssessmentResponseWithSubQuestions,
  createConditionDataModel,
  evaluateDisplayCondition,
  filterClinicalQuestionsByDisplayCondition,
  SubQuestion,
} from "@coherehealth/common";

export interface SubQuestionVisibility {
  questionsId: string;
  isVisible: boolean;
}

export function clearNotShownClinicalQuestions(clinicalQuestions: ClinicalAssessmentResponse[]) {
  const idArray: string[] = [];
  filterClinicalQuestionsByDisplayCondition(clinicalQuestions).forEach((filteredQuestion) => {
    if (filteredQuestion.clinicalQuestion?.originalVersionId) {
      idArray.push(filteredQuestion.clinicalQuestion?.originalVersionId);
    }
  });

  clinicalQuestions.forEach((question) => {
    if (question.clinicalQuestion?.originalVersionId) {
      if (!idArray.includes(question.clinicalQuestion.originalVersionId)) {
        question.answers = [];
      }
    }
  });
  return clinicalQuestions;
}

export const mapClinicalResponseWithSubQuestion = (
  questions: ClinicalAssessmentResponse[]
): ClinicalAssessmentResponseWithSubQuestions[] => {
  let i = 0;
  let questionFormulatedWithSubQuestions: ClinicalAssessmentResponseWithSubQuestions[] = [];
  let defaultQuestionIds: string[] = [];
  defaultQuestionIds.push(questions[0]?.id || "");
  while (i < questions.length) {
    let currentQuestion = questions[i];
    if (defaultQuestionIds.includes(currentQuestion?.id || "")) {
      let defaultNextQuestion =
        currentQuestion?.clinicalQuestion?.externalAssessmentProperties?.defaultNextQuestionId || "";
      defaultQuestionIds.push(defaultNextQuestion);
      let subQuestion: SubQuestion[] = [];
      currentQuestion?.clinicalQuestion?.responseOptions?.forEach((option: ResponseOption) => {
        if (option?.externalAssessmentProperties?.nextQuestionId !== defaultNextQuestion) {
          let s = questions.find(
            //get the full question from the id
            (q: ClinicalAssessmentResponse) => q.id === option.externalAssessmentProperties?.nextQuestionId
          );
          if (s !== undefined && subQuestion.indexOf(s) === -1) {
            //add to subQuestion if not already present
            subQuestion.push({
              ...s,
              answerToRenderOn: option.optionId,
              questionToRenderOn: currentQuestion?.id,
            });
          }
        }
      });
      //add question to questionsWithSubQuestion array
      let questionWithSubQuestion: ClinicalAssessmentResponseWithSubQuestions = {
        ...currentQuestion,
        subQuestions: subQuestion,
      };
      questionFormulatedWithSubQuestions.push(questionWithSubQuestion);
    } else {
      //it is an identified subquestion
      let mainQuestion =
        questionFormulatedWithSubQuestions.find((q) =>
          q.clinicalQuestion?.responseOptions?.find(
            (o) => o?.externalAssessmentProperties?.nextQuestionId === currentQuestion?.id
          )
        ) ||
        questionFormulatedWithSubQuestions.find((q) => {
          return q.subQuestions?.find((sq) => {
            return (
              sq.clinicalQuestion?.responseOptions?.find(
                (o) => o?.externalAssessmentProperties?.nextQuestionId === currentQuestion?.id
              ) || sq?.clinicalQuestion?.externalAssessmentProperties?.defaultNextQuestionId === currentQuestion?.id
            );
          });
        });
      if (
        !defaultQuestionIds.includes(
          currentQuestion?.clinicalQuestion?.externalAssessmentProperties?.defaultNextQuestionId || ""
        )
      ) {
        /*if the current questions default next question is not a default question then it leads to another
        subQuestion and is likely a free text question*/
        let s = questions.find(
          (q: ClinicalAssessmentResponse) =>
            q.id === currentQuestion?.clinicalQuestion?.externalAssessmentProperties?.defaultNextQuestionId
        );
        let newSubQuestions = mainQuestion?.subQuestions;
        if (s !== undefined && newSubQuestions?.indexOf(s) === -1) {
          newSubQuestions?.push({ ...s, questionToRenderOn: currentQuestion?.id });
        }
      }
      currentQuestion?.clinicalQuestion?.responseOptions?.forEach((option: ResponseOption) => {
        if (!defaultQuestionIds.includes(option?.externalAssessmentProperties?.nextQuestionId || "")) {
          //add subquestion to SQ array
          let s = questions.find(
            (q: ClinicalAssessmentResponse) => q.id === option.externalAssessmentProperties?.nextQuestionId
          );
          let newSubQuestions = mainQuestion?.subQuestions;
          if (s !== undefined && newSubQuestions?.indexOf(s) === -1) {
            newSubQuestions?.push({ ...s, answerToRenderOn: option.optionId, questionToRenderOn: currentQuestion?.id });
          }
        }
      });
    }
    i++;
  }
  return questionFormulatedWithSubQuestions;
};

export const incompleteQuestions = (clinicalAssessment: ClinicalAssessment | null | undefined | void) => {
  if (!clinicalAssessment) {
    return [];
  }
  const clinicalQuestionsConditionDataModel = createConditionDataModel(clinicalAssessment.questions || []);

  return (
    clinicalAssessment.questions
      ?.filter(
        // So the tricky thing about multi-select questions is that
        // the answer may be "none of the above" - so
        // we'll never block on multi-select questions
        (question) => (question.answers || []).length === 0
      )
      ?.filter((question) => {
        // Do not include incomplete questions that are not displayed
        return (
          !question.clinicalQuestion?.displayCondition ||
          evaluateDisplayCondition(question.clinicalQuestion.displayCondition, clinicalQuestionsConditionDataModel)
        );
      }) || []
  );
};

export const incompleteRequiredQuestions = (
  clinicalAssessment: ClinicalAssessment | null | undefined | void,
  subQuestionsVisibility?: SubQuestionVisibility[]
) => {
  return incompleteQuestions(clinicalAssessment).filter((q) => {
    if (q.clinicalQuestion?.required && subQuestionsVisibility) {
      const foundSubQuestion = subQuestionsVisibility.find((sq) => sq.questionsId === q.id);
      if (foundSubQuestion) {
        return Boolean(q.clinicalQuestion?.required && foundSubQuestion.isVisible);
      } else {
        return Boolean(q.clinicalQuestion?.required);
      }
    } else {
      return Boolean(q.clinicalQuestion?.required);
    }
  });
};

/**
 * Given a clinical question and answers,
 * figure out which options are represented in the answers.
 * We need this for single- and multi-select questions because the representation
 * of answers and options is not quite the same
 */
export const selectedOptions = ({ clinicalQuestion, answers }: ClinicalAssessmentResponse, type?: string) => {
  if (type === "AGADIA") {
    return (
      clinicalQuestion?.responseOptions?.filter((cq) =>
        (answers || []).some((answer) => cq.optionId === answer.optionId)
      ) || []
    );
  } else {
    return (
      clinicalQuestion?.responseOptions?.filter((opt) => {
        return opt.observationCodes?.every(
          (code) =>
            code.system &&
            code.code &&
            (answers || []).some((a) => a.codes?.map((c) => c.system).includes(code.system || "")) &&
            (answers || []).some((a) => a.codes?.map((c) => c.code).includes(code.code || ""))
        );
      }) || []
    );
  }
};

/**
 * Given a clinical question and answers,
 * find an answer that matches the question.
 * A question and an answer match if they have the same code set.
 *
 * We grab the last element when there are multiple (thus the `arrayCopy.reverse().find`)
 * in case the back-end fails to disambiguate and sends us multiple copies of
 * responses
 */
export const nonSelectAnswerForQuestion = (
  { clinicalQuestion, answers }: ClinicalAssessmentResponse,
  type?: string
) => {
  if (type === "AGADIA") {
    return answers ? answers[0] : undefined;
  } else {
    return clinicalQuestion?.observationCodes
      ? [...(answers || [])]
          .reverse()
          .find((a) =>
            a.codes?.every((c) =>
              clinicalQuestion.observationCodes?.some((qc) => c.system === qc.system && c.code === qc.code)
            )
          )
      : undefined;
  }
};

/**
 * Construct and calculate units on procedure codes based on passed list,
 * @param procedureCodes Desired Procedure Code
 * @param isInpatient Desired Encounter Type Inpatient/Outpatient
 * @param serviceLevelUnits Current Service Level Units
 * @param prevServiceLevelUnits (Optional) Previous Service Level Units
 * @param prevIsInpatient (Optional) Previous Inpatient/Outpatient
 * @param clinicalService (Optional) The clinical service associated with the group
 * @param isUnitsOnPx If the clinical service applies units on the procedure code level
 * @param isContinuation (Optional) Are Procedure Codes being constructed for a continuation? In which case, return blank units on PX for initial continuation requests
 */
export const constructProcedureCodes = ({
  procedureCodes,
  isInpatient,
  serviceLevelUnits,
  prevServiceLevelUnits,
  prevIsInpatient,
  clinicalService,
  isUnitsOnPx,
  isContinuation = false,
}: {
  procedureCodes: ProcedureCode[];
  isInpatient: boolean;
  serviceLevelUnits: string;
  clinicalService?: ClinicalService;
  prevServiceLevelUnits?: string;
  prevIsInpatient?: boolean;
  isUnitsOnPx: boolean;
  isContinuation?: boolean;
}): ProcedureCode[] => {
  return procedureCodes
    .filter((code) => code !== undefined)
    .map((code) => {
      return {
        ...code,
        units:
          isUnitsOnPx && isContinuation && !code.units
            ? undefined
            : calculateUnits({
                code: code,
                prevIsInpatient: prevIsInpatient || isInpatient,
                isInpatient: isInpatient,
                prevServiceLevelUnits: prevServiceLevelUnits || "",
                serviceLevelUnits: serviceLevelUnits,
                isUnitsOnPx: isUnitsOnPx,
              }),
        defaultUnits: code.defaultUnits ?? 1,
        maxUnits: code.maxUnits ?? 1,
        unitType: code.unitType ?? "Units",
        groupId: clinicalService?.id || "",
        groupOriginalVersionId: clinicalService?.originalVersionId || "",
        groupBy: clinicalService?.id ? "ClinicalService" : undefined,
      };
    });
};

/**
 * Calculate units for procedure codes,
 * @param code Desired Procedure Code
 * @param prevIsInpatient Previous Inpatient/Outpatient
 * @param isInpatient Desired Encounter Type Inpatient/Outpatient
 * @param prevServiceLevelUnits Previous Service Level Units (Used in Fill In Details Page)
 * @param serviceLevelUnits Current Service Level Units
 */
const calculateUnits = ({
  code,
  prevIsInpatient,
  isInpatient,
  prevServiceLevelUnits,
  serviceLevelUnits,
  isUnitsOnPx = false,
}: {
  code: ProcedureCode;
  prevIsInpatient: boolean;
  isInpatient: boolean;
  prevServiceLevelUnits: string;
  serviceLevelUnits: string;
  isUnitsOnPx: boolean;
}): number => {
  const hasInPatientChanged = prevIsInpatient !== isInpatient;
  const hasServiceLevelUnitsChanged = prevServiceLevelUnits !== serviceLevelUnits;
  // Calculate units based on unitsOnPx FF
  if (isUnitsOnPx) {
    if (code.units) {
      return code.units;
    } else if (code.defaultUnits) {
      return code.defaultUnits;
    } else {
      return 1;
    }
  }
  if (!hasInPatientChanged) {
    if (hasServiceLevelUnitsChanged || !code.units) {
      return Number(serviceLevelUnits);
    }
    if (code.units) {
      return code.units;
    }
  }
  return 1;
};

/**
 * Updating SubQuestion Visibility State for Conditional Clinical Assessment,
 * @param sq SubQuestion to check on
 * @param subQuestions SubQuestion State to update and store with Visibility
 * @param updateSubQuestions Action to update SubQuestion State
 * @param setIsVisible Visibility state to set
 */
export const updateSubQuestionsVisibility = ({
  sq,
  subQuestions,
  updateSubQuestions,
  setIsVisible,
}: {
  sq: SubQuestion;
  subQuestions: SubQuestionVisibilityState;
  updateSubQuestions: Dispatch<SetStateAction<SubQuestionVisibilityState>>;
  setIsVisible: boolean;
}) => {
  const existingSubQuestion = subQuestions?.find((obj) => obj.questionsId === sq.id);
  if (existingSubQuestion) {
    // Check if should update subQuestion visibility
    if (existingSubQuestion.isVisible !== setIsVisible) {
      const updatedSubQuestions = subQuestions?.map((obj) => {
        if (obj.questionsId === sq.id) {
          return { ...obj, isVisible: setIsVisible };
        }
        return obj;
      });
      if (updatedSubQuestions) {
        updateSubQuestions([...updatedSubQuestions]);
      }
    }
  } else {
    // Add subQuestion to state
    const newSubQuestion: SubQuestionVisibility = {
      questionsId: sq.id || "",
      isVisible: setIsVisible,
    };
    updateSubQuestions((prev) => {
      if (prev) {
        return [...prev, newSubQuestion];
      } else {
        return [newSubQuestion];
      }
    });
  }
};

export function canEditAgadiaAssessment(authStatus?: AuthStatus, pendingReason?: PendingReason) {
  return authStatus === "DRAFT" || (authStatus === "PENDING" && pendingReason === "PENDING_ASSESSMENT_SUBMISSION");
}
