import { ValidDisplayMessageAction } from "util/rule";
import { Alert, Sanitized } from "@coherehealth/common";

interface PatientSearchRedirectMessageProps {
  action: ValidDisplayMessageAction;
}

export function PatientSearchRedirectMessage(props: PatientSearchRedirectMessageProps): JSX.Element {
  const { action } = props;
  const messageHtml = action.message || "--";
  return (
    <>
      <Alert message={action.title} severity="redirect">
        <Sanitized
          __html={
            action.message ? messageHtml : "This member cannot be selected. Please contact Cohere for more information"
          }
        />
      </Alert>
    </>
  );
}
