import { PropsWithChildren, useState } from "react";
import {
  ServiceRequestResponse,
  RuleActions,
  RecommendChangeRuleAction,
  AuthorizationResponse,
} from "@coherehealth/core-platform-api";
import {
  Body1,
  Caption,
  formatDateStr,
  H6,
  InlineButton,
  parseDateFromISOString,
  useConfiguration,
  useFeature,
} from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, makeStyles } from "@material-ui/core/styles";
import { GridSize } from "@material-ui/core";
import ContactInfoModal, { ContactInfoModalProps } from "components/ContactInfoModal";
import { safelyGetFormConfig } from "util/serviceRequest";
import { differenceInDays, format, parseISO } from "date-fns";
import {
  DEFAULT_FORM_CONFIG,
  FormConfiguration,
} from "../ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { isRecommendChangeActionWithAttribute } from "util/rule";
import ContentNudge from "./ContentNudge";
import { getPatientHealthPlanName } from "util/patientUtils";
import PatientStayModal from "components/ServiceRequest/PatientStay/PatientStayModal";
import { calculateDecisionedLengthOfStay, getSortedServiceRequests, requestTimingToString } from "util/authorization";
import { SRFormConfigFieldWrapper } from "common/FormConfigUtils";
interface Props {
  authorization?: AuthorizationResponse;
  serviceRequest: ServiceRequestResponse;
  hideFacilityState?: boolean;
  ruleActions?: RuleActions;
  isFacilityBasedAuth?: boolean;
}

const useStyle = makeStyles((theme) => ({
  OutOfNetworkExceptionReasonStyle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.75),
  },
  outOfNetworkBadgestyle: {
    marginLeft: theme.spacing(1.5),
  },
  facilityBasedHeader: {
    padding: "19px 0px 5.5px",
  },
}));

export default function RequestDetailsContinuation({
  authorization,
  serviceRequest,
  ruleActions,
  isFacilityBasedAuth,
}: Props) {
  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");

  const [modalState, setModalState] = useState<ContactInfoModalProps>({
    open: false,
    handleClose: () => {
      setModalState((prev) => ({ ...prev, open: false }));
    },
    type: "FACILITY",
  });

  const formConfiguration = safelyGetFormConfig(serviceRequest?.formConfiguration || DEFAULT_FORM_CONFIG);

  let datesOfServiceValue =
    serviceRequest.startDate &&
    formatDateStr(serviceRequest.startDate).concat(
      serviceRequest.endDate ? ` - ${formatDateStr(serviceRequest.endDate)}` : ""
    );

  return isFacilityBasedAuth ? (
    <>
      <FacilityBasedDetailsContinuation
        serviceRequest={serviceRequest}
        authorization={authorization}
        ruleActions={ruleActions}
        isFacilityBasedAuth={isFacilityBasedAuth}
        formConfiguration={formConfiguration}
      />
      <ContactInfoModal
        open={modalState.open}
        handleClose={modalState.handleClose}
        type={modalState.type}
        name={modalState.name}
        phones={modalState.phones}
        fax={modalState.fax}
        email={modalState.email}
        npi={modalState.npi}
        tinList={modalState.tinList}
        addresses={modalState.addresses}
        npiLabel={modalState.npiLabel}
      />
    </>
  ) : (
    <>
      {simplifiedServiceFrequency && (
        <>
          <SRFormConfigFieldWrapper {...formConfiguration?.admissionDischargeDate}>
            <DetailsField
              label={"Dates of service"}
              value={
                serviceRequest.admissionDate &&
                formatDateStr(serviceRequest.admissionDate).concat(
                  serviceRequest.expectedDischargeDate
                    ? ` - ${formatDateStr(serviceRequest.expectedDischargeDate)}`
                    : ""
                )
              }
            />

            <DetailsField
              label={"Inpatient days"}
              value={
                //subtracts the end date from the start date
                serviceRequest.expectedDischargeDate &&
                serviceRequest.admissionDate &&
                differenceInDays(
                  parseDateFromISOString(serviceRequest.expectedDischargeDate),
                  parseDateFromISOString(serviceRequest.admissionDate)
                ).toString()
              }
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper {...formConfiguration?.startEndDate}>
            <DetailsField label={"Dates of service"} value={datesOfServiceValue} />
          </SRFormConfigFieldWrapper>
        </>
      )}
      <SRFormConfigFieldWrapper {...formConfiguration?.urgency}>
        <DetailsField label={"Expedited"} value={serviceRequest.urgency?.isExpedited ? "Yes" : "No"} />
      </SRFormConfigFieldWrapper>
      <ContactInfoModal
        open={modalState.open}
        handleClose={modalState.handleClose}
        type={modalState.type}
        name={modalState.name}
        phones={modalState.phones}
        fax={modalState.fax}
        email={modalState.email}
        npi={modalState.npi}
        tinList={modalState.tinList}
        addresses={modalState.addresses}
        npiLabel={modalState.npiLabel}
      />
    </>
  );
}

interface FacilityBasedDetailsContinuationProps {
  serviceRequest: ServiceRequestResponse;
  authorization: AuthorizationResponse | undefined;
  ruleActions: RuleActions | undefined;
  isFacilityBasedAuth: boolean;
  formConfiguration: FormConfiguration;
}

const FacilityBasedDetailsContinuation = ({
  serviceRequest,
  authorization,
  ruleActions,
  isFacilityBasedAuth,
  formConfiguration,
}: FacilityBasedDetailsContinuationProps) => {
  const classes = useStyle();
  const [patientStayModalOpen, setPatientStayModalOpen] = useState<boolean>(false);

  const lengthOfApprovedStays = calculateDecisionedLengthOfStay(authorization?.serviceRequestsOnAuth);
  const lengthOfRequestedStays = serviceRequest.patientStayDates ? serviceRequest.patientStayDates.length : 0;

  const approvedStaysText =
    lengthOfApprovedStays > 0
      ? `${lengthOfRequestedStays > 0 ? "," : ""} ${lengthOfApprovedStays} day${
          lengthOfApprovedStays > 1 ? "s" : ""
        } previously decisioned`
      : "";
  const requestedStaysText = lengthOfRequestedStays
    ? `${lengthOfRequestedStays} day${lengthOfRequestedStays > 1 ? "s" : ""} requested`
    : ``;

  const sortedServiceRequestsOnAuth = getSortedServiceRequests(authorization);
  let latestDischargedDateTime: string | undefined;

  // Check if the array is not empty
  if (sortedServiceRequestsOnAuth?.length) {
    // Access the last ServiceRequestResponse and its properties
    const lastServiceRequest = sortedServiceRequestsOnAuth[sortedServiceRequestsOnAuth.length - 1];

    if (lastServiceRequest.dischargeDate && lastServiceRequest.dischargeTime) {
      latestDischargedDateTime = format(
        parseISO(`${lastServiceRequest.dischargeDate}T${lastServiceRequest.dischargeTime}`),
        "MM/dd/yyyy HH:mm"
      );
    }
  }

  const startDate = serviceRequest?.startDate ? new Date(serviceRequest?.startDate) : new Date();
  const healthPlanName = getPatientHealthPlanName(serviceRequest?.patient, startDate) || "";
  const facilityBasedConfig = useConfiguration(
    "facilityBasedRequestConfiguration",
    healthPlanName,
    serviceRequest?.delegatedVendor
  );

  return (
    <>
      {serviceRequest.patientStatus === "NOT_YET_ADMITTED" && (
        <SRFormConfigFieldWrapper {...formConfiguration?.urgency}>
          <DetailsField label={"Expedited"} value={serviceRequest.urgency?.isExpedited ? "Yes" : "No"} />
        </SRFormConfigFieldWrapper>
      )}
      <H6 className={classes.facilityBasedHeader}>Patient stay</H6>
      <SRFormConfigFieldWrapper forceShow={isFacilityBasedAuth}>
        <DetailsField label="Patient status" value={requestTimingToString(serviceRequest.patientStatus)} />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper forceShow={isFacilityBasedAuth && serviceRequest.patientStatus === "NOT_YET_ADMITTED"}>
        <DetailsField
          label="Expected admission date"
          value={serviceRequest?.expectedAdmissionDate ? formatDateStr(serviceRequest?.expectedAdmissionDate) : ""}
        />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper forceShow={isFacilityBasedAuth && serviceRequest.patientStatus !== "NOT_YET_ADMITTED"}>
        <DetailsField
          label="Admission source (optional)"
          value={serviceRequest.admissionSource?.name || "--"}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            ?.find((action) => action.onAcceptAttribute === "admissionSource")}
        />
        <DetailsField
          label="Admission date and time"
          value={
            serviceRequest.admissionDate && serviceRequest.admissionTime
              ? format(new Date(`${serviceRequest.admissionDate} ${serviceRequest.admissionTime}`), "MM/dd/yyyy HH:mm")
              : ""
          }
          ruleNudge={ruleActions?.filter(isRecommendChangeActionWithAttribute)?.find((action) => {
            return action.onAcceptAttribute === "admissionDate" || action.onAcceptAttribute === "admissionTime";
          })}
        />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper
        forceShow={
          isFacilityBasedAuth &&
          (serviceRequest.patientStatus !== "NOT_YET_ADMITTED" ||
            facilityBasedConfig?.includePatientStayDatesOnPlannedAdmission)
        }
      >
        <DetailsField label="Length of stay" value={`${requestedStaysText}${approvedStaysText}`}>
          {lengthOfApprovedStays + lengthOfRequestedStays > 0 && (
            <ContactButton onClick={() => setPatientStayModalOpen(true)}>View details</ContactButton>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper
        forceShow={isFacilityBasedAuth && !!authorization?.nextReviewDate && serviceRequest?.authStatus !== "DRAFT"}
      >
        <DetailsField label="Next review date" value={formatDateStr(authorization?.nextReviewDate)} />
      </SRFormConfigFieldWrapper>
      {serviceRequest.patientStatus === "DISCHARGED" && (
        <H6 className={classes.facilityBasedHeader}>Discharge Information</H6>
      )}
      <SRFormConfigFieldWrapper forceShow={isFacilityBasedAuth && serviceRequest.patientStatus === "DISCHARGED"}>
        <DetailsField
          label="Discharge date and time"
          value={authorization?.serviceRequestsOnAuth?.length ? latestDischargedDateTime : ""}
        />
        <DetailsField label="Discharged to" value={serviceRequest?.dischargedTo?.dischargedTo || ""} />
      </SRFormConfigFieldWrapper>
      <PatientStayModal authorization={authorization} open={patientStayModalOpen} setOpen={setPatientStayModalOpen} />
    </>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ContactButton = styled(InlineButton)(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  padding: "0",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Label = styled(Caption)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "inline-block",
  minWidth: "170px",
  marginRight: theme.spacing(5),
}));

const DetailsField = ({
  label,
  value,
  xs = 12,
  ruleNudge,
  children,
  subTextChildren,
}: PropsWithChildren<{
  label: string;
  value?: string;
  ruleNudge?: RecommendChangeRuleAction;
  xs?: GridSize;
  subTextChildren?: JSX.Element;
}>) => {
  const showContentBeingNudged = useFeature("showContentBeingNudged");
  const isNudgePresent = ruleNudge !== undefined;
  const classes = useStyles({ isNudgePresent, showContentBeingNudged });

  return (
    /*will change state to be based on if nudge has been accepted or not part of COH-2027*/
    <Grid
      item
      xs={xs}
      className={`${classes.grid}${isNudgePresent && showContentBeingNudged ? " nudgeLayout" : " nonNudgeLayout"}`}
    >
      <div className={classes.initialContainer}>
        <Label data-public>{label}</Label>
        <div className={classes.container}>
          <div className={classes.valueText}>
            <Body1 data-public>{value || "--"}</Body1>
            {showContentBeingNudged && ruleNudge && (
              <ContentNudge ruleNudge={ruleNudge} state={"pending"} type={"main"} />
            )}
            {children}
          </div>
          <div>{subTextChildren}</div>
        </div>
      </div>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  grid: {
    wordWrap: "break-word",
    "&.nonNudgeLayout": {
      paddingBottom: "13px",
    },
    "&.nudgeLayout": {
      paddingBottom: theme.spacing(2),
    },
  },
  valueText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  component: {
    "&.nudgeLayout": {
      minHeight: "32px",
      alignItems: "center",
      display: "flex",
    },
  },
  initialContainer: {
    display: "flex",
    flexDirection: "row",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));
