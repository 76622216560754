import { Body1, H6 } from "@coherehealth/common";
import { Divider } from "@material-ui/core";
import { ChatbotFeedbackMechanism } from "./ChatbotFeedbackMechanism";
import ChatbotPromptLabeler from "./ChatbotPromptLabeler";
import { useChatbotStyles } from "./chatbotReviewAssistantStyles";
import { useCallback, useContext, useMemo } from "react";
import { IReviewChatbotFindingHolder } from "components/ClinicalReview/Review/hooks/useAutomatedReviewChatbotFindings";
import { ReviewChatbotFinding } from "@coherehealth/core-platform-api";
import Markdown from "react-markdown";
import ChatbotAnswerSources from "./ChatbotAnswerSources";
import { ClinicalReviewContext } from "components/ClinicalReview/reviewUtils/clinicalReviewPageUtils";

interface Props {
  chatbotFindings: IReviewChatbotFindingHolder[];
  setFeedback: (id: string, updatedChatbotFinding: Partial<ReviewChatbotFinding>) => void;
}

export default function ChatbotSeededContent({ chatbotFindings, setFeedback }: Props) {
  const classes = useChatbotStyles({});

  const {
    commonReviewAssistantState: { expandedFindingId, setExpandedFindingId },
  } = useContext(ClinicalReviewContext);

  const expandedFindingHolder: IReviewChatbotFindingHolder | undefined = useMemo(
    () => chatbotFindings.find((item) => item.id === expandedFindingId),
    [chatbotFindings, expandedFindingId]
  );

  const handleExpand = useCallback(
    (id: string) => {
      setExpandedFindingId((prevId) => (prevId === id ? null : id));
    },
    [setExpandedFindingId]
  );

  return (
    <div className={classes.seededContentWrapper}>
      <H6 className={classes.title}>Select a topic or ask a question below</H6>
      {chatbotFindings.map((item) => (
        <ChatbotPromptLabeler
          key={item.id}
          chatbotFinding={item.chatbotfinding}
          isExpanded={expandedFindingId === item.id}
          setIsExpanded={() => handleExpand(item.id)}
        />
      ))}
      {expandedFindingId && expandedFindingHolder && (
        <>
          <Divider className={classes.divider} />
          <Body1>
            <Markdown>{expandedFindingHolder.chatbotfinding.value ?? ""}</Markdown>
          </Body1>

          <ChatbotAnswerSources chatbotFinding={expandedFindingHolder.chatbotfinding} />

          <ChatbotFeedbackMechanism
            chatbotFinding={expandedFindingHolder.chatbotfinding}
            setFeedback={(updatedChatbotFinding: Partial<ReviewChatbotFinding>) =>
              setFeedback(expandedFindingId, updatedChatbotFinding)
            }
          />
        </>
      )}
    </div>
  );
}
