import { InformativeModal, TertiaryButton } from "@coherehealth/common";
import { makeStyles, Typography } from "@material-ui/core";
import ClipboardMissingInfo from "components/images/ClipboardMissingInfo";

const NetworkStatusTransitionModal = ({
  openNetworkStatusTransitionModal,
  setOpenNetworkStatusTransitionModal,
}: any) => {
  const handleClickContinueNetworkStatusModal = async () => {
    if (openNetworkStatusTransitionModal) {
      const handleSaveOperation = openNetworkStatusTransitionModal.bind(null);
      setOpenNetworkStatusTransitionModal(null);
      await handleSaveOperation();
    }
  };

  const handleCloseNetworkStatusModal = () => {
    setOpenNetworkStatusTransitionModal(null);
  };
  const useStyles = makeStyles((theme) => ({
    primaryButton: {
      padding: "12px 24px",
      minWidth: "120px",
      height: "48px",
      marginTop: "16px",
    },
    tertiaryButton: {
      padding: "4px 16px",
      minWidth: "120px",
      height: "32px",
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  return (
    <InformativeModal
      headerText={"The edits to the provider details will change the network status"}
      subHeaderText={
        <Typography variant="body1">
          {`Your provider edits will update network status for one or more providers, but this request’s status will
        not change. Click 'Continue' to confirm these changes.`}
        </Typography>
      }
      icon={<ClipboardMissingInfo />}
      primaryButtonText="Continue"
      showDivider={false}
      primaryButtonAction={handleClickContinueNetworkStatusModal}
      open={!!openNetworkStatusTransitionModal}
      onClose={handleCloseNetworkStatusModal}
      customButtonStyle={classes.primaryButton}
    >
      <TertiaryButton onClick={handleCloseNetworkStatusModal} className={classes.tertiaryButton}>
        {"Cancel"}
      </TertiaryButton>
    </InformativeModal>
  );
};

export default NetworkStatusTransitionModal;
