import React from "react";
import { Card, Grid } from "@material-ui/core";
import { CardTextField } from "../CardTextField";
import { getSelectedRequestActionButtons } from "../ServiceRequestSelectionCard";
import { ClassNameMap } from "@material-ui/styles";
import {
  AuthCategoryResponse,
  BaseReview,
  PatientStatus,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import { ErrorSelectionCard } from "./ErrorSelectionCard";
import { SelectionCardHeader } from "./SelectionCardHeader";
import { formatDateStr, getReviewDatesRange } from "../../util/DateUtils";

export interface InpatientSelectionCardProps {
  authorizationLoading?: boolean;
  cardButtonComponent: JSX.Element;
  serviceRequestSelectionCardStyles: (props?: any) => ClassNameMap;
  extractClinicalServiceText: (
    clinicalServices?: ServiceRequestResponse["clinicalServices"],
    authCategory?: AuthCategoryResponse
  ) => string;
  loadingReview?: boolean;
  loadingServiceRequest?: boolean;
  loadingContinuation?: boolean;
  mostRecentReview?: BaseReview;
  onContinuationClick?: () => Promise<void>;
  onEditClick?: () => void;
  reviewNotesComponent: JSX.Element;
  serviceRequest: ServiceRequestResponse | null;
  serviceCaseId?: string;
  showCreateContinuation?: boolean;
  showPatientAlert: boolean;
  showEditRequest?: boolean;
  continueDraftComponent?: JSX.Element;
  draftWarningComponent?: JSX.Element;
}

export function InpatientSelectionCard(props: InpatientSelectionCardProps): JSX.Element {
  const {
    authorizationLoading,
    cardButtonComponent,
    extractClinicalServiceText,
    loadingContinuation,
    loadingServiceRequest,
    onContinuationClick,
    onEditClick,
    serviceCaseId,
    serviceRequest,
    showCreateContinuation,
    showPatientAlert,
    showEditRequest,
    serviceRequestSelectionCardStyles,
    continueDraftComponent,
    draftWarningComponent,
  } = props;

  const {
    admissionDate,
    authNumber,
    cohereId,
    dischargeDate,
    encounterType,
    expectedAdmissionDate,
    facility,
    patientStatus,
    patientStayDates,
    primaryDiagnosis,
    secondaryDiagnoses,
    procedureCodes,
  } = serviceRequest ?? {};

  const classes = serviceRequestSelectionCardStyles();

  const isInpatient = encounterType === "INPATIENT";

  const reviewDatesRange = getReviewDatesRange(patientStayDates);

  const cptCodes = procedureCodes?.map((px) => px.code)?.join(", ");

  if (!serviceRequest) {
    return (
      <ErrorSelectionCard
        serviceRequestSelectionCardStyles={serviceRequestSelectionCardStyles}
        loadingServiceRequest={!!loadingServiceRequest}
      />
    );
  }

  return (
    <Card className={classes.serviceRequestSelectionCard}>
      <Grid container spacing={2}>
        <SelectionCardHeader
          classes={classes}
          extractClinicalServiceText={extractClinicalServiceText}
          serviceCaseId={serviceCaseId}
          serviceRequest={serviceRequest}
          showPatientAlert={showPatientAlert}
        />

        {isInpatient && (
          <Grid item xs={4}>
            <CardTextField label="Facility" singleLine={true} content={facility?.name ?? ""} />
          </Grid>
        )}
        {isInpatient && (
          <Grid item xs={5} data-public data-testid="review_dates">
            <CardTextField label="Review dates" content={reviewDatesRange} data-public />
          </Grid>
        )}
        {(authNumber || isInpatient) && (
          <Grid item xs={3}>
            <CardTextField label="Auth number" content={authNumber} />
          </Grid>
        )}
        {!isInpatient && (
          <Grid item xs={3} data-public>
            <CardTextField label="Tracking number" content={cohereId} data-public />
          </Grid>
        )}
        {isInpatient && (
          <Grid item xs={4} data-public data-testid="patient_status">
            <CardTextField label="Patient status" content={getPatientStatusCaption(patientStatus)} data-public />
          </Grid>
        )}
        {isInpatient && admissionDate && (
          <Grid item xs={5}>
            <CardTextField label="Admission date" content={formatDateStr(admissionDate)} />
          </Grid>
        )}
        {isInpatient && !admissionDate && (
          <Grid item xs={5}>
            <CardTextField label="Expected admission date" content={formatDateStr(expectedAdmissionDate)} />
          </Grid>
        )}
        {isInpatient && (
          <Grid item xs={3}>
            <CardTextField label="Discharge date" content={formatDateStr(dischargeDate)} />
          </Grid>
        )}
        {isInpatient && (
          <Grid item xs={4} data-public data-testid="ip_primary_dx">
            <CardTextField label="Primary diagnosis" content={primaryDiagnosis?.code} data-public />
          </Grid>
        )}
        <Grid item xs={5} data-public data-testid="ip_px_codes">
          <CardTextField label="Procedure codes" content={cptCodes} data-public />
        </Grid>
        {!isInpatient && (
          <Grid item xs={3} data-public>
            <CardTextField
              label="Primary diagnosis"
              content={`${primaryDiagnosis?.code} - ${primaryDiagnosis?.description}`}
              data-public
            />
          </Grid>
        )}
        {secondaryDiagnoses && (
          <Grid item xs={3} data-public data-testid="ip_secondary_dx">
            <CardTextField
              label="Secondary diagnosis"
              content={`${secondaryDiagnoses?.map((diagnosis) => diagnosis.code).join(",")}`}
              data-public
            />
          </Grid>
        )}
        <Grid item xs={12} className={classes.cardButtons}>
          {cardButtonComponent}
          {getSelectedRequestActionButtons(
            serviceRequest.authStatus,
            authorizationLoading,
            continueDraftComponent,
            showCreateContinuation,
            onContinuationClick,
            loadingContinuation,
            showEditRequest,
            onEditClick
          )}
        </Grid>
        {serviceRequest.authStatus === "DRAFT" && draftWarningComponent && (
          <Grid item xs={12}>
            {draftWarningComponent}
          </Grid>
        )}
      </Grid>
    </Card>
  );
}

/**
 * Produces an space separated capitalized string for a given underline uppercase.
 * @param patientStatus PatientStatus object with current status of the patient
 * @returns formatted string for the given patientStatus object
 */
function getPatientStatusCaption(patientStatus?: PatientStatus): string {
  if (!patientStatus) {
    return "";
  }
  return `${patientStatus.charAt(0)}${patientStatus.substring(1).toLocaleLowerCase().replaceAll("_", " ")}`;
}
