import { useContext, useEffect, useRef, useState } from "react";
import { useFeature } from "@coherehealth/common";
import { User, UserContext } from "UserContext";
import { useGetUserById, UserResponse } from "@coherehealth/core-platform-api";
import { error as logError } from "logger";
import { isBackOfficeUser } from "./user";
import { useLocation } from "react-router";
import { ConversationContext } from "ConversationProvider";

// Defining a global interface for the Salesforce chatbot
declare global {
  interface Window {
    embeddedservice_bootstrap: any;
    embedded_svc: any;
  }
}

const orgId = process.env.REACT_APP_SALESFORCE_PROVIDER_ORG_ID;
const domain = process.env.REACT_APP_SALESFORCE_PROVIDER_DOMAIN;
const scrt2URL = process.env.REACT_APP_SALESFORCE_PROVIDER_SCRT2_URL;
const scriptUrl = process.env.REACT_APP_SALESFORCE_PROVIDER_SCRIPT;
const chatbotEnv = process.env.REACT_APP_SALESFORCE_PROVIDER_CHATBOT_ENV;
const chatBotButton = process.env.REACT_APP_SALESFORCE_PROVIDER_CHATBOT_BUTTON_URL;

const visiblePages = [
  "dashboard", // Dashboard route
  "patient_summary", // Patient summary route
  "auth_builder", // Auth submission workflow route
  "referrals/build", // Referral submission workflow route
  "organization_management", // Organization management page route
];

export const useChatWidgetForPHI = (): void => {
  const isSalesForceBotEnabled = useFeature("enableSalesForceBotForPHI");
  const [currUser, setCurrentUser] = useState<User>();
  const [backOfficeUser, setBackOfficeUser] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useState<UserResponse>();
  const { getUser } = useContext(UserContext);
  const { refetch: fetchUserDetails } = useGetUserById({
    id: "",
    lazy: true,
  });

  const contextConversation = useContext(ConversationContext);
  const scriptLoaded = useRef(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const currentUser = await getUser();
        if (currentUser) {
          setCurrentUser(currentUser);
          if (currentUser.sub) {
            const user = await fetchUserDetails({ pathParams: { id: currentUser.sub } });
            setBackOfficeUser(isBackOfficeUser(currentUser));
            if (user?.email) {
              setCurrentUserInfo(user);
            }
          }
        }
      } catch (error) {
        logError(error);
      }
    };

    fetchUserData();
  }, [getUser, fetchUserDetails]);

  const originalFetch = window.fetch;
  window.fetch = async (...args) => {
    const [resource, config] = args;
    const targetUrl = `${scrt2URL}/iamessage/v1/conversation`;
    if (typeof resource === "string" && resource.startsWith(targetUrl)) {
      if (config && config?.body && config?.headers) {
        let salesforceToken = "";
        const conversationId = JSON.parse(config?.body as string)?.conversationId;
        if (config.headers instanceof Headers) {
          salesforceToken = config.headers.get("Authorization") || "No Authorization Header";
        } else {
          const headersObject = config.headers as Record<string, string>;
          salesforceToken =
            headersObject["Authorization"] || headersObject["authorization"] || "No Authorization Header";
        }
        contextConversation.saveConversationData({ conversationId, salesforceToken });
      }
    }
    return originalFetch(...args);
  };

  const location = useLocation(); // Track the current route
  let iframeInterval: NodeJS.Timeout | null = null;
  const pathSegments = window.location.pathname.split("/").filter(Boolean); // Removes empty strings from leading "/"
  const isVisiblePage = visiblePages.some((page) => {
    if (page === "referrals/build") {
      return window.location.pathname.includes(page); // Special handling for referrals/build
    }
    return pathSegments.some((segment) => segment === page); // Default check for other pages
  });
  useEffect(() => {
    let script: HTMLScriptElement | null = null;

    function initEmbeddedMessaging() {
      try {
        if (!window.embeddedservice_bootstrap) {
          return;
        }

        window.embeddedservice_bootstrap.settings.language = "en_US";
        window.embeddedservice_bootstrap.init(orgId, chatbotEnv, domain, { scrt2URL: scrt2URL });
        window.embeddedservice_bootstrap.settings.hideChatButtonOnLoad = true;

        // Listen for when the messaging API is ready
        window.addEventListener("onEmbeddedMessagingReady", () => {
          setHiddenPreChatFields(); // Set hidden pre-chat fields
        });

        window.addEventListener("onEmbeddedMessagingButtonCreated", () => {
          const customButton = document.getElementById("customChatButton");
          if (customButton) {
            customButton.style.display = "block";
          }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
        iframeInterval = setInterval(() => {
          const iframes = document.querySelectorAll("iframe");
          if (iframes.length > 0 && isVisiblePage) {
            iframes.forEach((iframe) => {
              if (
                iframe.id.includes("embeddedMessaging") ||
                iframe.id.includes("mbeddedMessagingFilePreviewFrame") ||
                iframe.id.includes("embeddedMessagingFrame")
              ) {
                iframe.style.display = "none";
                iframe.style.position = "fixed";
                iframe.style.zIndex = "1000";
                iframe.style.visibility = "visible";
                iframe.style.opacity = "1";
                iframe.style.right = "50px"; // Remove default right positioning
                iframe.style.bottom = "50px"; // Keep it near the bottom
                if (
                  pathSegments.some((segment) => segment === "auth_builder") ||
                  (pathSegments.includes("referrals") && pathSegments.includes("build"))
                ) {
                  iframe.style.display = "none";
                  iframe.style.bottom = "100px"; // Keep it near the bottom
                }
                clearInterval(iframeInterval!); // Stop the interval once iframes are found
              } else if (iframe.id.includes("embeddedMessagingSiteContextFrame")) {
                iframe.style.position = "absolute";
                iframe.style.left = "0px"; // Move it back on-screen
              }

              // Hide the minimized chat state (if present)
              const minimizedState = iframe.contentWindow?.document.querySelector(".embeddedmessaging-minimized-state");
              if (minimizedState) {
                (minimizedState as HTMLElement).style.display = "none";
              }
            });
          }
        }, 500);
      } catch (err) {}
    }

    if (isSalesForceBotEnabled && backOfficeUser) {
      // allow only for back office users
      const initialScriptUrl = scriptUrl || "";

      if (orgId && domain && scrt2URL && scriptUrl && chatbotEnv) {
        const scriptId = "chatbotScript";
        if (!document.getElementById(scriptId) && !scriptLoaded.current) {
          scriptLoaded.current = true;
          script = document.createElement("script");
          script.id = scriptId;
          script.src = initialScriptUrl;
          script.type = "text/javascript";
          script.onload = () => {
            initEmbeddedMessaging();
          };
          document.body.appendChild(script);
        }
      }
    }

    function removeChatbot() {
      clearInterval(iframeInterval!);
      if (iframeInterval) {
        clearInterval(iframeInterval);
        iframeInterval = null;
      }
      // Find and remove the chatbot iframe
      // Hide all chatbot-related iframes
      const iframes = document.querySelectorAll("iframe");
      iframes.forEach((iframe) => {
        if (iframe.id.includes("embeddedMessagingSiteContextFrame")) {
          // Remove this specific iframe
          iframe.remove();
        } else if (
          iframe.id.includes("embeddedMessaging") ||
          iframe.id.includes("embeddedMessagingSiteContextFrame") ||
          iframe.title.includes("Salesforce")
        ) {
          iframe.style.opacity = "0";
          iframe.style.visibility = "hidden";
        }
      });

      // Additionally hide the chatbot container if it exists
      const chatbotContainer = document.querySelector('[class*="embeddedMessaging"]') as HTMLElement;
      if (chatbotContainer) {
        chatbotContainer.style.opacity = "0";
        chatbotContainer.style.visibility = "hidden";
      }
    }

    // Ensure we remove the chatbot on route change if it's not a visible page
    if (!isVisiblePage) {
      removeChatbot();
    }

    return () => {
      const pathSegments = window.location.pathname.split("/").filter(Boolean); // Removes empty strings from leading "/"
      const isVisiblePage = visiblePages.some((page) => {
        if (page === "referrals/build") {
          return window.location.pathname.includes(page); // Special handling for referrals/build
        }
        return pathSegments.some((segment) => segment === page); // Default check for other pages
      });
      if (script) {
        document.body.removeChild(script);
      }
      if (!isVisiblePage) {
        removeChatbot();
      }
      clearInterval(iframeInterval!); // Clear interval if active
    };
  }, [backOfficeUser, isSalesForceBotEnabled, setHiddenPreChatFields, isVisiblePage, location.pathname]);

  useEffect(() => {
    if (window.embeddedservice_bootstrap) {
      window.embeddedservice_bootstrap.settings.hideChatButtonOnLoad = true;
      window.embeddedservice_bootstrap.utilAPI.hideChatButton();
    }
  }, []);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      button.embeddedMessagingConversationButton {
        display: none !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    if (isSalesForceBotEnabled && backOfficeUser) {
      const customButton = document.createElement("button");
      customButton.id = "customChatButton";
      customButton.style.position = "fixed";
      customButton.style.bottom = "74px";
      customButton.style.right = "8px";
      customButton.style.width = "100px";
      customButton.style.height = "98px";
      customButton.style.textAlign = "center";
      customButton.style.backgroundImage = `url(${chatBotButton})`;
      customButton.style.backgroundSize = "100px 98px";
      customButton.style.backgroundPosition = "center";
      customButton.style.backgroundRepeat = "no-repeat";
      customButton.style.backgroundSize = "contain";
      customButton.style.backgroundColor = "transparent";
      customButton.style.boxShadow = "none";
      customButton.style.border = "none";
      customButton.style.cursor = "pointer";
      customButton.style.transition = "opacity 0.3s";

      let isLoading = false; // Track loading state

      customButton.onclick = () => {
        if (isLoading) {
          return;
        } // Prevent multiple clicks

        isLoading = true;
        customButton.style.opacity = "0.5"; // Indicate loading state

        window.embeddedservice_bootstrap.settings.hideChatButtonOnLoad = true;
        if (window.embeddedservice_bootstrap.utilAPI) {
          window.embeddedservice_bootstrap.utilAPI
            .launchChat()
            .then(() => {
              // Success: Reset button state after chat is fully loaded
              isLoading = false;
              customButton.style.opacity = "1"; // Restore normal state
            })
            .catch(() => {
              // Error: Reset loading state
              isLoading = false;
              customButton.style.opacity = "1";
            });
        }
      };

      document.body.appendChild(customButton);

      return () => {
        document.body.removeChild(customButton);
      };
    }
  }, [backOfficeUser, isSalesForceBotEnabled]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function setHiddenPreChatFields() {
    if (window.embeddedservice_bootstrap?.prechatAPI) {
      window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
        Name: currUser?.name,
        Email: currUser?.email,
        id: currUser?.sub,
        role: currUser?.roles,
        organization: currentUserInfo?.organizationHealthPlans,
      });

      // Example of removing fields that should not be sent
      window.embeddedservice_bootstrap.prechatAPI.removeHiddenPrechatFields(["Customer_ID"]);
    }
  }
};
