import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { HighlightReviewNoteStateContext } from "@coherehealth/common";
import { ReviewType } from "@coherehealth/core-platform-api";
import { LeftPanelTabs } from "components/ClinicalReview/reviewUtils/utils";

interface HighlightReviewProps {
  currentReview?: ReviewType;
  automatedReviewNoteEnabled: boolean;
  highlightAttachmentName?: string;
  highlightAttachmentHash?: string;
  attachmentOpenLoaded?: boolean;
  attachmentNameOpen?: string;
  tabOpen?: LeftPanelTabs;
  openAttachmentByName?: (name: string) => void;
  setTabOpen: React.Dispatch<React.SetStateAction<LeftPanelTabs>>;
}

const highlightAttachmentNameQueryParam = "highlightAttachmentName";
const highlightAttachmentHashQueryParam = "highlightAttachmentHash";

export const useAutomatedReviewNoteHighlights = ({
  automatedReviewNoteEnabled,
  currentReview,
  highlightAttachmentName,
  highlightAttachmentHash,
  attachmentOpenLoaded,
  attachmentNameOpen,
  tabOpen,
  openAttachmentByName,
  setTabOpen,
}: HighlightReviewProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { initializeHighlights, jumpToHighlight } = useContext(HighlightReviewNoteStateContext);

  useEffect(() => {
    if (
      automatedReviewNoteEnabled &&
      currentReview &&
      (currentReview.reviewType === "MdReview" || currentReview.reviewType === "NurseReview") &&
      currentReview.automatedReviewNote
    ) {
      initializeHighlights?.(currentReview.automatedReviewNote);
    }
  }, [automatedReviewNoteEnabled, currentReview, initializeHighlights]);

  /* 
    If the highlight link was clicked in the review note then this will pick up the data 
    from the query params to take the user to the highlighted section in the attachment
  */
  useEffect(() => {
    if (automatedReviewNoteEnabled && highlightAttachmentName && highlightAttachmentHash) {
      if (tabOpen !== "ATTACHMENTS") {
        setTabOpen("ATTACHMENTS");
      }
      openAttachmentByName?.(highlightAttachmentName as string);

      if (attachmentNameOpen === highlightAttachmentName && attachmentOpenLoaded) {
        jumpToHighlight?.(highlightAttachmentHash as string);

        // Need to remove the params after picking them in order to avoid them to be included again the next time
        const url = new URL(`${window.location.origin}${location.pathname}${location.search}`);
        const searchParams = url.searchParams;
        searchParams.delete(highlightAttachmentNameQueryParam);
        searchParams.delete(highlightAttachmentHashQueryParam);
        const newPath = `${location.pathname}?${searchParams.toString()}${location.hash || ""}`;

        navigate(newPath);
      }
    }
  }, [
    highlightAttachmentHash,
    highlightAttachmentName,
    location,
    jumpToHighlight,
    navigate,
    openAttachmentByName,
    automatedReviewNoteEnabled,
    tabOpen,
    attachmentOpenLoaded,
    setTabOpen,
    attachmentNameOpen,
  ]);
};
