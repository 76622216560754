import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Card, FullPageBanner, H1, H2, H6 } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import HeaderAndProgressBar from "./HeaderAndProgressBar";
import PurpleClipboardAuth from "../images/PurpleClipboardAuth";
import { useTheme, makeStyles, Divider, Grid } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { OnboardingSteps, ProviderOrgInfo, UserInfoState } from "./shared";
import ManuallyVerifyButton from "./ManuallyVerifyButton";
import ConfirmationModal from "./ConfirmationModal";
import { useManuallyCreateOrganization } from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { membersRequiredCount } from "./utils";

interface Props {
  setCurrentStep: Dispatch<SetStateAction<OnboardingSteps>>;
  onContinueFrom: (step: OnboardingSteps) => void;
  providerOrgInfo: ProviderOrgInfo;
  userInfo: UserInfoState;
}

export default function OnboardingVerificationHome({
  setCurrentStep,
  onContinueFrom,
  providerOrgInfo,
  userInfo,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    mutate: manuallyCreateOrganization,
    loading: manuallyCreateOrganizationLoading,
    error: manuallyCreateOrganizationError,
  } = useManuallyCreateOrganization({});

  const theme = useTheme();
  const classes = useStyles();

  const patientInfoLength = membersRequiredCount;
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

  const continueToManualReview = async () => {
    await manuallyCreateOrganization({
      npi: providerOrgInfo.npi,
      orgName: providerOrgInfo.name,
      userName: `${userInfo.firstName} ${userInfo.lastName}`,
      userPhone: userInfo.phoneNumber,
      userEmail: userInfo.email,
      userSpecialties: userInfo.userSpecialties,
      otherTins: [{ name: providerOrgInfo.name, tin: providerOrgInfo.tin }, ...providerOrgInfo.otherTins],
      organizationAddress: providerOrgInfo.primaryAddress,
      specialty: providerOrgInfo.organizationSpecialty,
      organizationSpecialties: providerOrgInfo.organizationSpecialties,
      structure: providerOrgInfo.organizationStructure,
      healthPlans: providerOrgInfo.healthPlans,
    });
    setCurrentStep("VERIFICATION_UNDER_REVIEW");
  };

  useEffect(() => {
    if (manuallyCreateOrganizationError) {
      let errorMessage = "Error creating organization";
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, manuallyCreateOrganizationError]);

  return (
    <Container>
      <HeaderAndProgressBar stepNumber={3} />
      <Title>How would you like to be verified?</Title>
      <H6 className={classes.subHeader}>
        Cohere takes the privacy of patient information seriously. Choose a verification method below.
      </H6>
      <FullPageBanner bannerIcon={<PurpleClipboardAuth />} backgroundColor={"#EAE9F4"} padding={theme.spacing(4)}>
        <H2 className={classes.bannerTextPadding}>Auto-verification</H2>
        <H6>Verify your identity automatically and instantly gain access to our authorization portal.</H6>
      </FullPageBanner>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <SelectionCard
            className={classes.selectionCard}
            // todo do we want this step at all?
            onClick={() => setCurrentStep("VERIFICATION_BY_PATIENT_INFO_CREATE_ORG")}
          >
            <HeadlineContainer>
              <PersonIcon color="action" className={classes.personIcon} />
              <H6>Verify by patient info</H6>
            </HeadlineContainer>
            <Body3>Provide the Member ID and DOB of {patientInfoLength} Humana patients.</Body3>
          </SelectionCard>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ManuallyVerifyButton onClick={() => setConfirmationModalOpen(true)} />
          <ConfirmationModal
            open={confirmationModalOpen}
            setOpen={setConfirmationModalOpen}
            onClickPrimary={() => {
              setConfirmationModalOpen(false);
            }}
            onClickSecondary={continueToManualReview}
            onContinueFrom={onContinueFrom}
            tertiaryButtonLoading={manuallyCreateOrganizationLoading}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Title = styled(H1)(({ theme }) => ({
  margin: theme.spacing(5.5, 0, 1, 0),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Container = styled("div")(({ theme }) => ({ paddingBottom: theme.spacing(6) }));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SelectionCard = styled(Card)(({ theme }) => ({
  borderRadius: "8px",
  padding: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeadlineContainer = styled(Container)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "left",
  padding: "0",
  marginBottom: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Body3 = styled("div")(({ theme }) => ({
  color: "#5E6572",
  fontFamily: "Gilroy-Medium",
  fontSize: "13px",
  marginRight: theme.spacing(32),
}));

const useStyles = makeStyles((theme) => ({
  subHeader: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontFamily: "Gilroy-Semibold",
  },
  bannerTextPadding: {
    marginBottom: theme.spacing(1),
  },
  personIcon: {
    marginTop: "5px",
    marginLeft: "5px",
    marginBottom: "5px",
    marginRight: "29px",
  },
  selectionCard: {
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0px 4px 13px rgba(0,0,0,0.08)",
      borderColor: theme.palette.primary.main,
      "& $personIcon": {
        color: theme.palette.primary.main,
      },
    },
  },
}));
