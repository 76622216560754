import React, { useState } from "react";
import { InformativeModal } from "@coherehealth/common";
import { DialogProps } from "@material-ui/core/Dialog";
import StartContinuationIcon from "./images/StartContinuationIcon";
import { ErrorMessage, ServiceRequestResponse } from "@coherehealth/core-platform-api";

import DetailedHardRedirectClinicalAuthModal, {
  ClinicalAuthErrorDetails,
} from "./DetailedHardRedirectClinicalAuthModal";
import { extractAuthErrorDetails } from "./ServiceRequest/ReviewSection/util/ReviewSectionUtil";
import { GetDataError } from "restful-react";

interface ContinuationProps extends DialogProps {
  open: boolean;
  primaryButtonActions: () => void;
  tertiaryButtonAction: () => void;
  primaryButtonLoading: boolean;
  serviceRequest: ServiceRequestResponse;
}

const StartContinuationModal = ({
  open,
  onClose,
  primaryButtonActions,
  tertiaryButtonAction,
  primaryButtonLoading,
  serviceRequest,
}: ContinuationProps) => {
  const useContinuationModal = ({
    serviceRequestId,
    primaryButtonActions,
  }: {
    serviceRequestId: string;
    primaryButtonActions: () => void;
  }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [openHardRedirectOnSubmitModal, setOpenHardRedirectOnSubmitModal] = useState(false);
    const [errorDetails, setErrorDetails] = useState<ClinicalAuthErrorDetails>(
      defaultClinicalAuthErrorState(serviceRequestId)
    );
    const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(true);

    const handlePrimaryButtonClick = async () => {
      if (isSubmitting) {
        return;
      }

      try {
        setIsSubmitting(true);
        setErrorDetails(defaultClinicalAuthErrorState(serviceRequestId));
        await primaryButtonActions();
      } catch (e) {
        setIsSubmitting(false);
        setIsSubmissionModalOpen(false);
        const error = e as GetDataError<ErrorMessage>;
        setErrorDetails(extractAuthErrorDetails(error, serviceRequestId));
        setOpenHardRedirectOnSubmitModal(true);
      } finally {
        setIsSubmitting(false);
      }
    };

    return {
      isSubmitting,
      openHardRedirectOnSubmitModal,
      setOpenHardRedirectOnSubmitModal,
      errorDetails,
      isSubmissionModalOpen,
      setIsSubmissionModalOpen,
      handlePrimaryButtonClick,
    };
  };

  const {
    isSubmitting,
    openHardRedirectOnSubmitModal,
    setOpenHardRedirectOnSubmitModal,
    errorDetails,
    isSubmissionModalOpen,
    handlePrimaryButtonClick,
  } = useContinuationModal({
    serviceRequestId: serviceRequest?.id || "",
    primaryButtonActions,
  });

  const isPrimaryButtonDisabled = primaryButtonLoading || isSubmitting;

  return (
    <>
      <InformativeModal
        open={open && isSubmissionModalOpen}
        onClose={onClose}
        icon={<StartContinuationIcon />}
        headerText="The changes you’ve made need to be submitted as a continuation request"
        subHeaderText="Note: Your auth number will remain the same"
        showDivider={false}
        primaryButtonText="Start continuation"
        primaryButtonAction={handlePrimaryButtonClick}
        primaryButtonLoading={primaryButtonLoading}
        tertiaryButtonText="Go back"
        tertiaryButtonAction={tertiaryButtonAction}
        primaryButtonDisabled={isPrimaryButtonDisabled}
      />
      <DetailedHardRedirectClinicalAuthModal
        isOpen={openHardRedirectOnSubmitModal}
        setIsOpen={setOpenHardRedirectOnSubmitModal}
        errorDetails={errorDetails}
      />
    </>
  );
};

const defaultClinicalAuthErrorState = (serviceRequestId: string) => {
  return {
    status: 500,
    message: "An unknown error occurred",
    serviceRequestId,
  };
};

export default StartContinuationModal;
