import type { AuthorizationResponse, GroupedPatientStayDate } from "@coherehealth/core-platform-api";

import {
  getTimelineGroups,
  buildTimelineItems,
  getStatusColorMap,
  getValidServiceRequestStatus,
} from "../utils/patientStayTimelineUtils";
import { useTheme } from "@material-ui/core";
import { useMemo } from "react";

/**
 * Defines the visual styling properties for timeline items
 */
interface TimelineItemStyle {
  backgroundColor?: string;
  borderColor?: string;
  width?: string;
  overflow?: string;
}

/**
 * Represents a line segment in the timeline with text content
 */
interface LineItem {
  type: "line";
  style: TimelineItemStyle;
  mainText: string;
  subText?: string;
  locText?: string;
  dischargedText?: string;
}

/**
 * Represents a dot marker in the timeline
 */
interface DotItem {
  type: "dot";
  style: TimelineItemStyle;
}

/**
 * Union type representing either a line segment or dot in the timeline
 */
export type TimelineItem = LineItem | DotItem;

/**
 * Hook that generates the configuration for timeline visualization
 * @param authorization - The authorization response containing patient status and auth details
 * @param groupedStayDatesForTimeline - Array of grouped stay dates for timeline display
 * @returns Array of timeline items (dots and lines) with their styling and content
 */
export const usePatientStayTimelineItems = (
  authorization?: AuthorizationResponse,
  groupedStayDatesForTimeline?: GroupedPatientStayDate[] | null
): TimelineItem[] => {
  const theme = useTheme();

  const timelineItems = useMemo(() => {
    if (!authorization) {
      return [];
    }

    const { status: serviceRequestStatus, hasValidStatus } = getValidServiceRequestStatus(
      authorization,
      groupedStayDatesForTimeline
    );

    if (!hasValidStatus) {
      return [];
    }

    const { authStatus, patientStatus } = authorization;
    const statusConfig = { authStatus, patientStatus, serviceRequestStatus };

    const statusColorMap = getStatusColorMap(theme);
    const timelineGroups = getTimelineGroups(statusConfig, groupedStayDatesForTimeline);
    return buildTimelineItems(timelineGroups, statusConfig, statusColorMap);
  }, [authorization, groupedStayDatesForTimeline, theme]);

  return timelineItems;
};
