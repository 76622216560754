import React, { useEffect, useState } from "react";
import ReactRichTextEditor, { EditorValue, ToolbarConfig } from "react-rte";

// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core";
import { Body1, Caption } from "../Typography";
import { colorsLight, colorsDark } from "../../themes";
// @ts-ignore
import { getTextAlignBlockMetadata, getTextAlignStyles, getTextAlignClassName } from "react-rte";
import { useTheme } from "@material-ui/core";
import { EditorState } from "draft-js";

interface Props {
  htmlValue: string;
  label?: string;
  setHtmlValue: (arg0: string) => void;
  readonly: boolean;
  error?: boolean;
  helperText?: string;
  showTextAlignButtons?: boolean;
  testid?: string;
  showFontSizeButtons?: boolean;
  customEditorState?: EditorState;
  setEditorState?: (state?: EditorState) => void;
}

interface StyleProps {
  readonly: boolean;
  error: boolean;
  hasLabel: boolean;
  hasValue: boolean;
  hasFocus: boolean;
}

const useStyles = makeStyles((theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return {
    root: {
      transition: theme.transitions.create(["border-color"]),
      border: ({ readonly, error, hasFocus }: StyleProps) =>
        readonly
          ? "none"
          : error
          ? `1px solid ${theme.palette.error.dark}`
          : hasFocus
          ? `1px solid ${theme.palette.primary.main}`
          : `1px solid ${theme.palette.divider}`,
      "&:hover,&:focus": {
        border: ({ readonly, error }: StyleProps) =>
          readonly
            ? "none"
            : error
            ? `1px solid ${theme.palette.error.dark}`
            : `1px solid ${theme.palette.primary.main}`,
      },
      borderRadius: "4px",
      display: "flex",
      flexDirection: "column-reverse",
      marginBottom: ({ readonly }: StyleProps) => (readonly ? "auto" : theme.spacing(2)),
      backgroundColor: isDarkTheme ? theme.palette.background.default : "auto",
    },
    editor: {
      paddingTop: ({ hasLabel }: StyleProps) => (hasLabel ? theme.spacing(2) : 0),
      fontFamily: theme.typography.fontFamily,
      minHeight: ({ readonly }: StyleProps) => (readonly ? "auto" : 160),
      ...theme.typography.body1,
      "& .DraftEditor-editorContainer": {
        border: ({ readonly }: StyleProps) => (readonly ? "none" : "auto"),
      },
      "& .public-DraftEditor-content": {
        paddingLeft: ({ readonly }: StyleProps) => (readonly ? 0 : 10),
        minHeight: 160,
        "overflow-wrap": "anywhere !important",
      },
    },
    toolbar: {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: "none",
      margin: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      zIndex: 1,
      "& > div": {
        // this targets the button group div (I can't put a custom class on it)
        marginBottom: 0,
        "&:not(:last-child)": {
          borderRight: `1px solid ${theme.palette.divider}`,
        },
      },
      "& button": {
        // this targets the individual buttons, I can't make a custom class for it because there
        // is no way to assign a custom class to the link buttons
        display: "flex",
        alignItems: "center",
        border: "none",
        borderRadius: "4px !important",
        padding: theme.spacing(0, 0.5),
        "&:hover:not([disabled])": {
          opacity: 1,
          background: isDarkTheme ? colorsDark.gray.dark : theme.palette.action.hover,
        },
        margin: `${theme.spacing(0, 0.5)} !important`,
      },
      "& span": {
        display: "flex",
        alignItems: "center",
        border: "none",
        borderRadius: "4px !important",
        padding: theme.spacing(0, 0.5),
        "&:hover:not([disabled])": {
          opacity: 1,
          background: isDarkTheme ? colorsDark.gray.dark : theme.palette.action.hover,
        },
        margin: `${theme.spacing(0, 0.5)} !important`,
      },
    },
    whiteButton: {
      background: theme.palette.background.paper,
      opacity: 0.6 /* The text in these buttons are actually an image, so changing color doesn't work, this approximates the font.secondary color */,
    },
    activeButton: {
      background: colorsLight.gray.dark,
    },
    label: {
      color: ({ error }: StyleProps) => (error ? theme.palette.error.dark : colorsLight.black.mediumEmphasis),
      position: "absolute",
      width: "90%",
      transform: ({ hasValue, hasFocus }: StyleProps) =>
        hasValue || hasFocus ? "translate(12px, 10px) scale(0.75)" : "translate(12px, 20px) scale(1)",
      transformOrigin: "top left",
      transition: theme.transitions.create(["transform"]),
    },
  };
});

export function stripHTMl(htmlString: string) {
  const elemStr = document.createElement("DIV");
  elemStr.innerHTML = htmlString;
  return elemStr.textContent || elemStr.innerText;
}

export default function RichTextEditor({
  htmlValue,
  label,
  readonly,
  setHtmlValue,
  error,
  helperText,
  showTextAlignButtons,
  testid,
  showFontSizeButtons,
  customEditorState,
  setEditorState,
}: Props) {
  const { palette } = useTheme();
  const [appliedInlineStyles, setAppliedInlineStyles] = useState<Array<string>>([]);
  const [activeBlockType, setActiveBlockType] = useState<string>();
  const [hasFocus, setHasFocus] = useState(false);
  const isDarkTheme = palette.type === "dark";
  const [internalError, setInternalError] = useState(error);

  useEffect(() => {
    setInternalError(error);
  }, [error]);

  const classes = useStyles({
    readonly: readonly,
    error: internalError || false,
    hasLabel: Boolean(label),
    hasValue: Boolean(stripHTMl(htmlValue) || customEditorState),
    hasFocus,
  });

  const [value, setValue] = useState(
    customEditorState
      ? ReactRichTextEditor.createEmptyValue().setEditorState(customEditorState)
      : ReactRichTextEditor.createValueFromString(
          htmlValue,
          "html",
          showTextAlignButtons ? { customBlockFn: getTextAlignBlockMetadata } : undefined
        )
  );

  const onChange = (newValue: EditorValue) => {
    setValue(newValue);
    setHtmlValue(
      newValue
        .toString("html", showTextAlignButtons ? { blockStyleFn: getTextAlignStyles } : undefined)
        .replaceAll("“", '"')
        .replaceAll("”", '"')
        .replaceAll("‘", "'")
        .replaceAll("’", "'")
    );
    // This is some magic needed to highlight the toolbar style buttons appropriately for our custom design.
    const editorState = newValue.getEditorState();
    setAppliedInlineStyles(editorState.getCurrentInlineStyle().toArray());
    const currKey = editorState.getSelection().getAnchorKey();
    setActiveBlockType(editorState.getCurrentContent().getBlockForKey(currKey)?.getType());
    setHasFocus(editorState.getSelection().getHasFocus());
    setEditorState?.(editorState);
  };

  const toolbarConfig: ToolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: showFontSizeButtons
      ? ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "BLOCK_TYPE_DROPDOWN", "BLOCK_ALIGNMENT_BUTTONS"]
      : ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "BLOCK_ALIGNMENT_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      {
        label: "Bold",
        style: "BOLD",
        className: isDarkTheme
          ? classes.activeButton
          : appliedInlineStyles.includes("BOLD")
          ? classes.activeButton
          : classes.whiteButton,
      },
      {
        label: "Italic",
        style: "ITALIC",
        className: isDarkTheme
          ? classes.activeButton
          : appliedInlineStyles.includes("ITALIC")
          ? classes.activeButton
          : classes.whiteButton,
      },
      {
        label: "Underline",
        style: "UNDERLINE",
        className: isDarkTheme
          ? classes.activeButton
          : appliedInlineStyles.includes("UNDERLINE")
          ? classes.activeButton
          : classes.whiteButton,
      },
      {
        label: "StrikeThrough",
        style: "STRIKETHROUGH",
        className: isDarkTheme
          ? classes.activeButton
          : appliedInlineStyles.includes("STRIKETHROUGH")
          ? classes.activeButton
          : classes.whiteButton,
      },
    ],
    BLOCK_TYPE_DROPDOWN: [
      {
        label: "Normal",
        style: "unstyled",
        className: isDarkTheme
          ? classes.activeButton
          : appliedInlineStyles.includes("unstyled")
          ? classes.activeButton
          : classes.whiteButton,
      },
      {
        label: "Heading Large",
        style: "header-one",
        className: isDarkTheme
          ? classes.activeButton
          : appliedInlineStyles.includes("header-one")
          ? classes.activeButton
          : classes.whiteButton,
      },
      {
        label: "Heading Medium",
        style: "header-two",
        className: isDarkTheme
          ? classes.activeButton
          : appliedInlineStyles.includes("header-two")
          ? classes.activeButton
          : classes.whiteButton,
      },
      {
        label: "Heading Small",
        style: "header-three",
        className: isDarkTheme
          ? classes.activeButton
          : appliedInlineStyles.includes("header-three")
          ? classes.activeButton
          : classes.whiteButton,
      },
    ],
    BLOCK_TYPE_BUTTONS: [
      {
        label: "UL",
        style: "unordered-list-item",
        className: isDarkTheme
          ? classes.activeButton
          : activeBlockType === "unordered-list-item"
          ? classes.activeButton
          : classes.whiteButton,
      },
      {
        label: "OL",
        style: "ordered-list-item",
        className: isDarkTheme
          ? classes.activeButton
          : activeBlockType === "ordered-list-item"
          ? classes.activeButton
          : classes.whiteButton,
      },
    ],
    BLOCK_ALIGNMENT_BUTTONS: showTextAlignButtons
      ? [
          { label: "Align Left", style: "ALIGN_LEFT", className: isDarkTheme ? classes.activeButton : "" },
          { label: "Align Center", style: "ALIGN_CENTER", className: isDarkTheme ? classes.activeButton : "" },
          { label: "Align Right", style: "ALIGN_RIGHT", className: isDarkTheme ? classes.activeButton : "" },
        ]
      : [],
  };

  return (
    <div style={{ position: "relative" }} data-testid={testid}>
      {Boolean(label) && (
        <Body1 className={classes.label} color="textSecondary">
          {label}
        </Body1>
      )}
      <ReactRichTextEditor
        className={classes.root}
        editorClassName={classes.editor}
        toolbarClassName={classes.toolbar}
        readOnly={readonly}
        toolbarConfig={toolbarConfig}
        value={value}
        onChange={onChange}
        blockStyleFn={showTextAlignButtons ? getTextAlignClassName : undefined}
      />
      {error && helperText && <ErrorCaption>{helperText}</ErrorCaption>}
      {!error && helperText && <Caption>{helperText}</Caption>}
    </div>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ErrorCaption = styled(Caption)(({ theme }) => ({
  color: theme.palette.error.dark,
}));
