import React, { useEffect, useState, useMemo } from "react";

import { useGetFaxAttachment, useGetFaxAttachmentDownload } from "@coherehealth/core-platform-api";
import { useBinaryFileFromResponse } from "@coherehealth/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DocumentViewer from "components/DocumentViewer";
import { useLocation, PathMatch } from "react-router-dom";
import { useSnackbar } from "notistack";

import { FaxAttachmentContext } from "./FaxAttachmentContext";
import { useIsFaxAuthBuilderWorkflow, useIsFaxEditSRWorkflow } from "util/attachmentUtil";
import { useChatWidgetForPHI } from "util/chatWidgetForPHI";

interface Props {
  match: PathMatch<string> | null;
}

export default function FaxAttachmentPageContent({ match }: Props) {
  const faxId = match?.params.faxId || "";

  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const caseId = search.get("caseId") || "";
  const queueId = search.get("queueId") || "";
  const origin = search.get("origin") || "";

  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  const isFaxEditSRFlow = useIsFaxEditSRWorkflow(location);
  const [hideSidebar, setHideSidebar] = useState(false);

  const isFaxReferralsBuilderFlow = location.pathname.includes("/fax_referral_builder/");

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: faxDownload,
    loading: faxDownloadLoading,
    error: faxDownloadError,
  } = useGetFaxAttachmentDownload({
    id: faxId,
    queryParams: {
      preferOcrEnhanced: true,
    },
  });

  const {
    data: faxInfo,
    loading: faxInfoLoading,
    error: faxInfoError,
    refetch: refetchFaxInfo,
  } = useGetFaxAttachment({
    id: faxId,
  });

  useEffect(() => {
    if (faxDownloadError) {
      enqueueSnackbar(`Error downloading fax: ${faxDownloadError.message}`, { variant: "error" });
    }
  }, [faxDownloadError, enqueueSnackbar]);

  useEffect(() => {
    if (faxInfoError) {
      enqueueSnackbar(`Error downloading fax information: ${faxInfoError.message}`, { variant: "error" });
    }
  }, [faxInfoError, enqueueSnackbar]);

  useChatWidgetForPHI();

  const { fileUrl, fileBlob } = useBinaryFileFromResponse(faxDownload);

  return (
    <FaxAttachmentContext.Provider value={{ faxDbId: faxId, caseId, origin, queueId, faxAttachment: faxInfo }}>
      <Dialog fullScreen open={true} disableEnforceFocus>
        {(faxInfoLoading || faxDownloadLoading) && <CircularProgress />}
        {faxInfo && fileUrl && (
          <DocumentViewer
            faxType="INCOMING_FAX"
            documentInfo={faxInfo}
            refreshDocumentInfo={refetchFaxInfo}
            url={fileUrl}
            canDelete={false}
            handleClose={() => {
              setHideSidebar(true);
            }}
            hideSidebar={hideSidebar}
            blob={fileBlob}
            isFaxAuthBuilderFlow={isFaxAuthBuilderFlow}
            isFaxEditSRFlow={isFaxEditSRFlow}
            match={match}
            location={location}
            search={search}
            isFaxReferralsBuilderFlow={isFaxReferralsBuilderFlow}
          />
        )}
      </Dialog>
    </FaxAttachmentContext.Provider>
  );
}
