import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MuiFormControl, { FormControlProps } from "@material-ui/core/FormControl";
import MuiFormLabel from "@material-ui/core/FormLabel";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import MuiFormHelperText from "@material-ui/core/FormHelperText";
import MuiRadio, { RadioProps } from "@material-ui/core/Radio";
import MuiRadioGroup from "@material-ui/core/RadioGroup";
import { colorsLight, colorsDark } from "../themes/colors";
import uniqueId from "lodash/uniqueId";

const useGroupStyles = makeStyles((theme: Theme) => {
  return {
    row: {
      display: "inline-flex",
      flexWrap: "nowrap",
    },
  };
});

const useStylesLabel = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";

  return createStyles({
    root: {
      marginBottom: theme.spacing(1),
      color: theme.palette.text.secondary,
      "&$focused": {
        color: isDarkTheme ? colorsLight.white.highEmphasis : theme.palette.text.secondary,
      },
    },

    focused: {},
    error: {},
  });
});
const useStylesHelper = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return createStyles({
    root: {
      "&$error": {
        color: isDarkTheme ? theme.palette.error.main : theme.palette.error.dark,
      },
    },
    error: {},
  });
});

const commonOptionLabelStyles = (theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return {
    root: {
      flex: 1,
      marginLeft: 0,
      marginRight: 0,
      padding: theme.spacing(1.5, 2),
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.divider,
      color: theme.palette.text.primary,
      backgroundColor: isDarkTheme ? colorsDark.background.dark : colorsLight.background.whiteHighEmphasis,
      transition: theme.transitions.create(["color", "background-color"]),
      "& span": {
        display: "inline-flex",
        margin: "auto",
        textAlign: "center",

        whiteSpace: "nowrap",
        maxWidth: 200,
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      "&:first-child": {
        borderRadius: "4px 0 0 4px",
      },
      "&:last-child": {
        borderRadius: "0 4px 4px 0",
      },
    },
  };
};
const useStylesOptionLabel = makeStyles((theme: Theme) => {
  return createStyles(commonOptionLabelStyles(theme));
});

const useStylesCheckedOptionLabel = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  const common = commonOptionLabelStyles(theme);
  return createStyles({
    ...common,
    root: {
      ...common.root,
      color: theme.palette.primary.main,
      backgroundColor: isDarkTheme ? colorsDark.background.light : colorsLight.background.dark,
    },
  });
});

export interface CondensedRadioGroupOption {
  id: string;
  label: React.ReactNode;
  disabled?: boolean;
}
export interface CondensedRadioGroupProps<T extends string> {
  /** Label for the group of radio buttons. */
  label?: React.ReactNode;
  /** Style overrides for label */
  labelClasses?: object;
  /** Helper text for the group of radio buttons. */
  helperText?: React.ReactNode;
  /** Array of options to display as radio buttons. `{ id: string, label: string, disabled?: boolean }` */
  options: CondensedRadioGroupOption[];
  /** 'id' of the selected option. */
  value?: T;
  /** Function: the 'id' of the selected option is provided as the first argument. */
  onChange: (arg0: T) => any;
  /** By default, radio buttons are displayed in a column. Set this to true to display in a row. */
  optionsLabelClasses?: object;
}

// Temporary export for storybook documentation
export const CondensedRadioGroupForProps = (props: CondensedRadioGroupProps<string>) => {};

// omit 'css' prop to avoid Typescript error making it required
export function CondensedRadioGroup<T extends string>(
  props: CondensedRadioGroupProps<T> & Omit<FormControlProps, "onChange" | "css">
) {
  const {
    label,
    helperText,
    options,
    value,
    onChange,
    labelClasses: additionalLabelClasses,
    optionsLabelClasses: additionalOptionsLabelClasses,
    ...formControlProps
  } = props;
  const [labelId] = useState(() => uniqueId("RadioGroup"));
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event?.target?.value as T);
  };
  const groupClasses = useGroupStyles();
  const labelClasses = { ...useStylesLabel(), ...additionalLabelClasses };
  const optionsLabelClasses = { ...useStylesOptionLabel(), additionalOptionsLabelClasses };
  const checkedOptionsLabelClasses = { ...useStylesCheckedOptionLabel(), additionalOptionsLabelClasses };
  const helperClasses = useStylesHelper();
  return (
    <MuiFormControl component="fieldset" {...(formControlProps as any)}>
      {label && (
        <MuiFormLabel id={labelId} classes={labelClasses}>
          {label}
        </MuiFormLabel>
      )}
      <MuiRadioGroup
        aria-labelledby={labelId}
        row
        value={value}
        onChange={handleChange}
        aria-required={Boolean(formControlProps?.required)}
        classes={groupClasses}
      >
        {options.map((option) => (
          <MuiFormControlLabel
            key={option.id}
            value={option.id}
            control={<InvisibleRadio />}
            label={<span>{option.label}</span>}
            disabled={formControlProps.disabled || option.disabled}
            classes={option.id === value ? checkedOptionsLabelClasses : optionsLabelClasses}
          />
        ))}
      </MuiRadioGroup>
      <MuiFormHelperText classes={helperClasses}>{helperText}</MuiFormHelperText>
    </MuiFormControl>
  );
}

function InvisibleRadio(props: RadioProps) {
  return (
    <MuiRadio
      {...props}
      style={{ width: 0, height: 0, padding: 0, margin: 0, appearance: "none" }}
      checkedIcon={<div style={{ display: "none" }} />}
      icon={<div style={{ display: "none" }} />}
    />
  );
}
