import { Grid, makeStyles, Typography } from "@material-ui/core";
import { calculateWithdrawnOptionNormalizedValue, getWithdrawnReasonsForRadioGroup } from "../utils";
import {
  ReviewOutcomeWithdrawOption,
  ServiceRequestResponse,
  ServiceRequestSearchResponse,
} from "@coherehealth/core-platform-api";
import ReasoningRadioGroup from "./ReasoningRadioGroup";
import { useAuthorized } from "authorization";
import { withdrawnReasonOptions } from "util/serviceRequest";

interface EnhancedWithdrawalReasonFormProps {
  value?: string;
  onChange: (reason: string) => void;
  serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse;
}

export default function EnhancedWithdrawalReasonForm(props: EnhancedWithdrawalReasonFormProps): JSX.Element {
  const { value, onChange, serviceRequest } = props;
  const SectionsToDisplay: JSX.Element[] = [];

  const canViewEnhancedWithdrawalModal = useAuthorized("VIEW_ENHANCED_WITHDRAWAL_MODAL");

  const classes = useStyles();

  const withdrawnReasonSelectOptions = () => {
    const withdrawOptions =
      serviceRequest.reviewOutcomeWithdrawOptions ||
      withdrawnReasonOptions(canViewEnhancedWithdrawalModal, serviceRequest.id, !!serviceRequest.reconClaim);

    let filteredWithdrawOptions: ReviewOutcomeWithdrawOption[] = [];
    // The configuration currently supports withdrawal reasons for users that visualize the new user experience
    // and users that will continue visualizing the old user experience, so we need to filter those options
    // accordingly.
    const withdrawnReasonsWithSubselectors = new Set<string>(
      withdrawOptions
        ?.filter((withdrawOption) => Boolean(withdrawOption.withdrawalRequestorId))
        .map((withdrawOption) => withdrawOption.id)
    );

    filteredWithdrawOptions = withdrawOptions?.filter(
      (withdrawOption) =>
        withdrawOption.withdrawalRequestorId || !withdrawnReasonsWithSubselectors.has(withdrawOption.id)
    );

    return filteredWithdrawOptions;
  };

  // TODO: During COD-4633 implementation we must handle the requestor from the Withdrawal (provider/patient)
  const withdrawnReasons = withdrawnReasonSelectOptions()?.filter((withdrawnReason) => {
    return withdrawnReason.cancellationType === "WITHDRAWN";
  });

  const voidingReasons = withdrawnReasonSelectOptions()?.filter((withdrawnReason) => {
    return withdrawnReason.cancellationType === "VOIDED";
  });

  const dismissedReasons = withdrawnReasonSelectOptions()?.filter((withdrawnReason) => {
    return withdrawnReason.cancellationType === "DISMISSED";
  });

  let preprocessedValued = calculateWithdrawnOptionNormalizedValue(value, withdrawnReasonSelectOptions);

  const updateWithdrawnVoidDismissedReasons = (reason: string) => {
    onChange(reason);
  };

  const AccessNotification = (
    <Typography className={classes.radioGroupOptionDek} variant="body1" gutterBottom>
      Available to auth submitter
    </Typography>
  );

  // VOIDING reasons
  if (voidingReasons?.length > 0) {
    const VoidingReasonsComponent = (
      <ReasoningRadioGroup
        className={classes.radioGroupOption}
        dek={
          <>
            Cancellation of a request for administrative reasons Notification <strong>will not be</strong> sent.
          </>
        }
        options={getWithdrawnReasonsForRadioGroup(voidingReasons, AccessNotification)}
        onChange={updateWithdrawnVoidDismissedReasons}
        title={"Reasons that result in a void"}
        value={preprocessedValued}
        key="voiding-reasons-radio-group"
      />
    );
    SectionsToDisplay.push(VoidingReasonsComponent);
  }

  // WITHDRAWAL reasons
  if (withdrawnReasons?.length > 0) {
    const WithdrawnReasonsComponent = (
      <ReasoningRadioGroup
        className={classes.radioGroupOption}
        dek={
          <>
            Cancellation of a request by a member or provider. Notification <strong>will be</strong> sent.
          </>
        }
        options={getWithdrawnReasonsForRadioGroup(withdrawnReasons, AccessNotification)}
        onChange={updateWithdrawnVoidDismissedReasons}
        title={"Reasons that result in a withdrawal"}
        value={preprocessedValued}
        key="withdrawal-reasons-radio-group"
      />
    );
    SectionsToDisplay.push(WithdrawnReasonsComponent);
  }

  // DISMISSAL reasons
  if (dismissedReasons?.length > 0) {
    const DismissedReasonsComponent = (
      <ReasoningRadioGroup
        className={classes.radioGroupOption}
        dek={
          <>
            Cancellation of a request for administrative reasons. Notification <strong>will be</strong> sent.
          </>
        }
        options={getWithdrawnReasonsForRadioGroup(dismissedReasons, AccessNotification)}
        onChange={updateWithdrawnVoidDismissedReasons}
        title={"Reasons that result in a dismissal"}
        value={preprocessedValued}
        key="dismissal-reasons-radio-group"
      />
    );
    SectionsToDisplay.push(DismissedReasonsComponent);
  }

  const layoutNumberOfColumns = SectionsToDisplay.length > 1 ? 2 : 1;

  return (
    <Grid container direction="row" className={classes.row}>
      {layoutNumberOfColumns === 1 && (
        <Grid container direction="column">
          {SectionsToDisplay[0]}
        </Grid>
      )}
      {layoutNumberOfColumns !== 1 && (
        <>
          <Grid item xs={6} className={classes.paddingRight}>
            {SectionsToDisplay.slice(0, 1)}
          </Grid>
          <Grid item xs={6} className={classes.paddingLeft}>
            {SectionsToDisplay.slice(1, SectionsToDisplay.length)}
          </Grid>
        </>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  radioGroupOptionDek: {
    fontSize: 13,
    color: theme.palette.info.main,
    margin: 0,
    padding: 0,
  },
  radioGroupOption: {
    fontSize: 15,
    color: theme.palette.text.secondary,
  },
  paddingTop: {
    paddingTop: theme.spacing(6 / 8),
  },
  paddingLeft: {
    paddingLeft: theme.spacing(16 / 8),
  },
  paddingRight: {
    paddingRight: theme.spacing(16 / 8),
  },
  row: {
    "& h6:nth-of-type(2)": {
      paddingTop: theme.spacing(26 / 8),
    },
  },
}));
