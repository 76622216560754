import { Box, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { prettyJson } from "./ConfigurationEditor";
import { Editor } from "@monaco-editor/react";
import { InlineButton } from "@coherehealth/common";
import { assertIsApiError } from "util/api";

interface IProps {
  json: string;
  onUpdateJson: (updatedJson: string) => void;
  loading?: boolean;
}

function JsonEditor({ json, onUpdateJson, loading }: IProps) {
  const { enqueueSnackbar } = useSnackbar();
  const onFormatBlock = () => {
    try {
      const prettyJsonRule = prettyJson(JSON.parse(json), null);
      onUpdateJson(prettyJsonRule);
      return prettyJsonRule;
    } catch (e) {
      assertIsApiError(e);
      enqueueSnackbar("Invalid JSON: " + e.message, { variant: "error" });
    }
  };

  const onChange = (val: string | undefined) => {
    if (val) {
      onUpdateJson(val);
    }
  };

  return (
    <Box sx={{ mb: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <InlineButton onClick={onFormatBlock}>Format Block</InlineButton>
          <Box
            sx={{
              border: "1px solid",
              borderColor: "gray.divider",
              "&:hover": {
                borderColor: "primary.main",
              },
            }}
          >
            <Editor
              defaultLanguage="json"
              value={json}
              onChange={onChange}
              options={{
                lineNumbers: "on",
                folding: true,
                scrollBeyondLastLine: false,
                minimap: { enabled: true },
                tabSize: 2,
                fontSize: 15,
                insertSpaces: true,
                formatOnPaste: true,
                formatOnType: true,
              }}
              height={"60vh"}
              width={"70vw"}
              loading={<CircularProgress />}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default JsonEditor;
