import React, { useState, useCallback } from "react";
import { useAuthorized } from "authorization";
import { useAuth } from "hooks/useAuth";
import { formatDateStrWithTz, H5 } from "@coherehealth/common";
import NotificationFileActions from "./NotificationFileActions";
import { Body3, InlineButton, Checkbox, colorsLight, colorsDark } from "@coherehealth/common";
import { makeStyles, Box, Slide, Divider } from "@material-ui/core";
import {
  getDeliveryInfo,
  NotificationViewerItem,
  isFinalDeterminationLetterNotification,
} from "./util/notificationUtil";
import {
  isLettersExpansionNotification,
  isServiceSummaryNotification,
  getRecipient,
  createDisplayName,
  capitalizeAndRemoveUnderscore,
} from "./util/notificationUtil";
import EcoFriendlyIcon from "components/images/EcoFriendlyIcon.svg";

interface NotificationViewerSidePanelProps {
  notifications: NotificationViewerItem[];
  setNotifications?: React.Dispatch<React.SetStateAction<NotificationViewerItem[]>>;
  handleNotificationClick: (index: number, landingPage?: number) => void;
  notificationIndexOpen?: number;
  openSidePanel?: boolean;
  sidePanelRef?: (node: React.ReactNode) => void;
  jumpToPage?: (pageIndex: number) => void;
  closeDialogFunction?: () => void;
  paperlessOptedIn?: boolean;
}

export default function NotificationViewerSidePanel({
  notifications,
  setNotifications,
  handleNotificationClick,
  notificationIndexOpen,
  openSidePanel,
  sidePanelRef,
  jumpToPage,
  closeDialogFunction,
  paperlessOptedIn,
}: NotificationViewerSidePanelProps) {
  const classes = useStyles({});
  const { oktaAuth } = useAuth();
  const canViewNotificationStatus = useAuthorized("VIEW_LETTER_STATUS");

  const [selectedNotifications, setSelectedNotifications] = useState<NotificationViewerItem[]>([]);

  // Checkbox selection actions
  const containsNotification = (notifications: NotificationViewerItem[], notification: NotificationViewerItem) =>
    notifications.some((n) => n.data.id === notification.data.id);

  const areAllNotificationsSelected =
    !!notifications.length && notifications.every((n) => containsNotification(selectedNotifications, n));

  const toggleSelectNotification = (notification: NotificationViewerItem) => {
    if (containsNotification(selectedNotifications, notification)) {
      // Unselect
      setSelectedNotifications(selectedNotifications.filter((n) => n.data.id !== notification.data.id));
    } else {
      // Select
      setSelectedNotifications([...selectedNotifications, notification]);
    }
  };

  const toggleSelectAllNotifications = () => {
    setSelectedNotifications(selectedNotifications.length ? [] : notifications);
  };

  // Data fetching
  interface UpdatedNotificationInfo {
    notificationId: string;
    fileUrl: string;
    convertedToPDF: boolean;
    isOcr: boolean;
  }

  const fetchNotification = useCallback(
    async (notification: NotificationViewerItem): Promise<UpdatedNotificationInfo | undefined> => {
      if (notification.downloadPath) {
        // Unlike in NotificationViewerShell, we don't need preferOcrEnhanced or
        // the FHIR SMART token - those are only needed for Attachments.
        const response = await fetch(notification.downloadPath, {
          headers: {
            Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          },
        });

        if (response.ok) {
          const blob = await response.blob();
          const fileUrl = window.URL.createObjectURL(blob);
          const isOcr = response.headers.get("content-disposition")?.endsWith('ml-ocrenhance.pdf"') || false;
          const convertedToPDF = blob.type === "application/pdf" && notification.contentType !== "application/pdf";

          const updatedInfo: UpdatedNotificationInfo = {
            notificationId: notification.data.id || "",
            fileUrl,
            convertedToPDF,
            isOcr,
          };
          return updatedInfo;
        }
      }
    },
    [oktaAuth]
  );

  /*
   * Before a user can print/download file, it must be fetched from S3
   * and stored as a blob (`fileUrl` variable).
   *
   * Previously, the user would view and download 1 attachment at a time.
   * With multi-select, users can now select files that haven't been viewed and
   * don't yet have a fileUrl. Therefore we need to fetch them first.
   *
   * This function "fills in" missing fileUrls for select notifications.
   */
  const fetchMissingFileUrls = useCallback(
    async (notificationsToCheck: NotificationViewerItem[]) => {
      const notificationsWithoutFileUrl = notificationsToCheck.filter((notification) => !notification.fileUrl);

      if (notificationsWithoutFileUrl.length) {
        const fetchNotificationPromises = notificationsWithoutFileUrl.map((notification) =>
          fetchNotification(notification)
        );
        const updatedNotificationsMetadata = await Promise.all(fetchNotificationPromises);

        const updatedSelectedNotifications = selectedNotifications.map((prevNotification) => {
          const updatedMetadata = updatedNotificationsMetadata.find(
            (n) => n?.notificationId === prevNotification.data.id
          );
          if (updatedMetadata) {
            return {
              ...prevNotification,
              fileUrl: updatedMetadata.fileUrl,
              convertedToPDF: updatedMetadata.convertedToPDF,
              isOcr: updatedMetadata.isOcr,
            };
          } else {
            return prevNotification;
          }
        });
        setSelectedNotifications?.(updatedSelectedNotifications);

        const updatedNotifications = notifications.map((prevNotification) => {
          const updatedMetadata = updatedNotificationsMetadata.find(
            (n) => n?.notificationId === prevNotification.data.id
          );
          if (updatedMetadata) {
            return {
              ...prevNotification,
              fileUrl: updatedMetadata.fileUrl,
              convertedToPDF: updatedMetadata.convertedToPDF,
              isOcr: updatedMetadata.isOcr,
            };
          } else {
            return prevNotification;
          }
        });
        setNotifications?.(updatedNotifications);

        /*
         * State won't be updated until next render, but we need it immediately
         * if we want to Print or Download files. So we return the new data.
         */
        return updatedSelectedNotifications;
      } else {
        // There were no notifications to update.
        return selectedNotifications;
      }
    },
    [fetchNotification, notifications, selectedNotifications, setNotifications]
  );

  const paperlessBannerText = "Paperless notifications ON, some letters may not be displayed here";

  return (
    <Slide in={openSidePanel} direction="right" mountOnEnter unmountOnExit ref={sidePanelRef}>
      <div className={classes.sidePanel}>
        <NotificationSidePanelHeader
          selectedNotifications={selectedNotifications}
          areAllNotificationsSelected={areAllNotificationsSelected}
          toggleSelectAllNotifications={toggleSelectAllNotifications}
        />
        <Divider style={{ alignSelf: "stretch" }} />
        {paperlessOptedIn && (
          <div className={classes.paperlessBanner}>
            <img alt="" src={EcoFriendlyIcon} className={classes.paperlessSettingIcon} />
            <H5>{paperlessBannerText}</H5>
          </div>
        )}

        <div style={{ alignSelf: "stretch" }}>
          {notifications?.map((notification, notificationIndex) => (
            <NotificationSidePanelItem
              key={notification.data.id}
              notification={notification}
              handleNotificationClick={handleNotificationClick}
              notificationIndex={notificationIndex}
              isOpen={notificationIndex === notificationIndexOpen}
              jumpToPage={jumpToPage}
              selected={containsNotification(selectedNotifications, notification)}
              toggleSelectNotification={toggleSelectNotification}
              showStatus={canViewNotificationStatus}
            />
          ))}
        </div>
        <div className={classes.fileActionsContainer}>
          <NotificationFileActions
            selectedNotifications={selectedNotifications}
            closeDialogFunction={closeDialogFunction}
            fetchMissingFileUrls={fetchMissingFileUrls}
          />
        </div>
      </div>
    </Slide>
  );
}

interface NotificationSidePanelItemProps {
  notification: NotificationViewerItem;
  handleNotificationClick: (index: number, landingPage?: number) => void;
  notificationIndex: number;
  isOpen: boolean;
  jumpToPage?: (pageIndex: number) => void;
  selected?: boolean;
  toggleSelectNotification?: (notification: NotificationViewerItem) => void;
  showStatus?: boolean;
}

export function NotificationSidePanelItem({
  notification,
  handleNotificationClick,
  notificationIndex,
  isOpen,
  jumpToPage,
  selected,
  toggleSelectNotification,
  showStatus = true,
}: NotificationSidePanelItemProps) {
  const classes = useStyles({ isOpen });

  const recipient = getRecipient(notification);

  const DATE_FNS_FORMAT_STR = "MM/dd/yy 'at' hh:mm:ss a (z)";
  const dateCreated = formatDateStrWithTz(notification.data?.dateCreated, undefined, DATE_FNS_FORMAT_STR);
  const { deliveryMode, sentTimestamp } = getDeliveryInfo(notification) ?? {};
  const sentTimestampFormatted = formatDateStrWithTz(sentTimestamp, undefined, DATE_FNS_FORMAT_STR);

  const isServiceSummary = isServiceSummaryNotification(notification);
  // FinalDeterminationLetterAttachment objects aren't created until after
  // Matrix mails them, so we shouldn't display dateCreated.
  const shouldShowWrittenOnCaption =
    !isServiceSummary && Boolean(dateCreated) && !isFinalDeterminationLetterNotification(notification);
  const shouldShowSentOnCaption = !isServiceSummary && Boolean(sentTimestampFormatted) && deliveryMode === "MAILED";

  // Notifications don't have a singular status; they have an Array of status
  // history, so we show the latest one.
  let status = "";
  if (isLettersExpansionNotification(notification)) {
    const statusList = notification.data.statusList;
    if (statusList?.length) {
      status = capitalizeAndRemoveUnderscore(statusList[statusList.length - 1]);
    }
  }

  return (
    <Box className={classes.attachmentBox} data-testid={`notification-side-panel-item-${notification.data.id || ""}`}>
      <Checkbox
        checked={selected}
        onChange={() => toggleSelectNotification?.(notification)}
        className={classes.checkbox}
      />
      <Box>
        <InlineButton
          className={classes.attachmentNameButton}
          onClick={() => {
            if (!((window.getSelection()?.toString().length || 0) > 0)) {
              if (!isOpen) {
                handleNotificationClick(notificationIndex);
              } else {
                jumpToPage?.(0);
              }
            }
          }}
        >
          {createDisplayName(notification)}
        </InlineButton>
        <Box style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          {isServiceSummary && <Body3 className={classes.attachmentDateCaption}>{`Created on ${dateCreated}`}</Body3>}
          {shouldShowWrittenOnCaption && (
            <Body3 className={classes.attachmentDateCaption}>{`Written on ${dateCreated}`}</Body3>
          )}
          {shouldShowSentOnCaption && (
            <Body3 className={classes.sentTimestampCaption}>{`Sent on ${sentTimestampFormatted}`}</Body3>
          )}
          {recipient && <Body3 className={classes.recipientCaption}>{`${recipient} Facing`}</Body3>}
          {showStatus && status && <Body3 className={classes.attachmentStatusCaption}>{`Status: ${status}`}</Body3>}
        </Box>
      </Box>
    </Box>
  );
}

interface NotificationSidePanelHeaderProps {
  selectedNotifications?: NotificationViewerItem[];
  areAllNotificationsSelected?: boolean;
  toggleSelectAllNotifications?: () => void;
}

function NotificationSidePanelHeader({
  selectedNotifications,
  areAllNotificationsSelected,
  toggleSelectAllNotifications,
}: NotificationSidePanelHeaderProps) {
  const classes = useStyles({});

  return (
    <Box className={classes.headerBox}>
      <Checkbox
        checked={areAllNotificationsSelected}
        onChange={() => toggleSelectAllNotifications?.()}
        className={classes.checkbox}
      />
      <Box>
        <InlineButton className={classes.headerNameButton} onClick={() => toggleSelectAllNotifications?.()}>
          {`Notifications (${selectedNotifications?.length || 0} selected)`}
        </InlineButton>
      </Box>
    </Box>
  );
}

type StyleProps = {
  isOpen?: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles((theme) => ({
  sidePanel: {
    height: "100%",
    width: "400px",
    paddingLeft: "16px",
    paddingRight: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "start",
    overflowY: "scroll",
  },
  // "Notifications (X selected)" header
  headerBox: {
    marginTop: "8px",
    marginBottom: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  headerNameButton: {
    color: "#000000",
    "&:hover, &:focus": {
      color: "#000000",
    },
    overflowWrap: "anywhere",
    textAlign: "left",
    marginTop: "-2px",
  },
  // Individual notification items
  attachmentBox: {
    marginTop: "12px",
    marginBottom: "12px",
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
  },
  attachmentNameButton: {
    color: colorsLight.font.main,
    "&:hover, &:focus": {
      color: colorsLight.font.main,
      textDecoration: "underline",
    },
    overflowWrap: "anywhere",
    textAlign: "left",
    textDecoration: ({ isOpen }: StyleProps) => (isOpen ? "underline" : "none"),
    marginTop: "4px",
  },
  attachmentDateCaption: {
    color: colorsDark.gray.dark,
  },
  recipientCaption: {
    color: colorsDark.gray.dark,
  },
  sentTimestampCaption: {
    color: colorsDark.gray.dark,
  },
  attachmentStatusCaption: {
    color: colorsLight.info.main,
  },
  checkbox: {
    width: "32px",
    minWidth: "32px",
  },
  fileActionsContainer: {
    marginTop: "auto",
    marginBottom: "30px",
    paddingTop: "12px",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  paperlessSettingIcon: {
    color: colorsLight.font.main,
    marginRight: theme.spacing(2),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  paperlessBanner: {
    display: "flex",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: theme.spacing(1, 2),
    alignSelf: "stretch",
    borderRadius: theme.spacing(0.5),
    background: colorsLight.background.panel,
  },
}));
