export type DeploymentEnvironment =
  | "local"
  | "dev"
  | "demo2"
  | "demo"
  | "uat"
  | "intake"
  | "staging"
  | "prod"
  | "preprod"
  | "unknown";

export function environmentAbbreviation(): DeploymentEnvironment {
  //IE-11 doesn't recognize window.location.origin in some cases, so we fallback to hostname
  //see https://tosbourn.com/a-fix-for-window-location-origin-in-internet-explorer/
  const origin = window.location?.origin || window.location?.hostname || "";
  if (origin.includes("localhost")) {
    return "local";
  }
  if (origin.includes("authtool-dev")) {
    return "dev";
  }
  if (origin.includes("careplanning-dev")) {
    return "dev";
  }
  if (origin.includes("queue-mgmt-dev")) {
    return "dev";
  }
  if (origin.includes("demo2")) {
    return "demo2";
  }
  if (origin.includes("demo")) {
    return "demo";
  }
  if (origin.includes("uat")) {
    return "uat";
  }
  if (origin.includes("authtool-intake")) {
    return "intake";
  }
  if (origin.includes("staging")) {
    return "staging";
  }
  if (origin.includes("preprod")) {
    return "preprod";
  }
  if (origin.includes("next.cohere")) {
    return "prod";
  }
  if (origin.includes("missioncontrol.cohere")) {
    return "prod";
  }
  if (origin.includes("care.cohere")) {
    return "prod";
  }
  return "unknown";
}

export function shouldDisplayEnvironmentIndicator(env: DeploymentEnvironment | undefined) {
  return env && !["prod", "unknown"].includes(env);
}

export function shouldDisplayActuatorInfo(env: DeploymentEnvironment | undefined) {
  return env && !["prod", "unknown", "demo", "demo2", "preprod"].includes(env);
}

// https://stackoverflow.com/questions/48182912/how-to-detect-browser-with-angular
export const isIE = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

export function conveneBaseUrl(): string {
  const env = environmentAbbreviation();
  switch (env) {
    case "prod":
      return "http://missioncontrol.coherehealth.com";
    case "local":
      // This is, at best, a convention
      return "http://localhost:3001";
    case "staging":
      return "https://mc-staging.coherehealth.com";
    case "preprod":
      return "https://mc-preprod.coherehealth.com";
    case "demo":
      return "https://mc-demo.coherehealth.com";
    case "demo2":
      return "https://mc-demo2.coherehealth.com";
    case "uat":
      return "https://careplanning-uat.coherehealth.com";
    case "dev":
      return "https://careplanning-dev.coherehealth.com";
    default:
      return window?.location?.origin?.replace("authtool", "careplanning");
  }
}

export function getConveneBaseUrlFromEnvironment(environment: string): string {
  switch (environment) {
    case "prod":
      return "http://missioncontrol.coherehealth.com";
    case "local":
      return window.location.origin;
    case "staging":
      return "https://mc-staging.coherehealth.com";
    case "preprod":
      return "https://mc-preprod.coherehealth.com";
    case "demo":
      return "https://mc-demo.coherehealth.com";
    case "demo2":
      return "https://mc-demo2.coherehealth.com";
    case "uat":
      return "https://careplanning-uat.coherehealth.com";
    case "dev":
      return "https://careplanning-dev.coherehealth.com";
    default:
      return window?.location?.origin?.replace("authtool", "careplanning");
  }
}

export function authorizeBaseUrl(): string {
  const env = environmentAbbreviation();
  switch (env) {
    case "prod":
      return "http://next.coherehealth.com";
    case "local":
      // This is, at best, a convention
      return "http://localhost:3000";
    case "staging":
      return "https://next-staging.coherehealth.com";
    case "preprod":
      return "https://next-preprod.coherehealth.com";
    case "demo":
      return "https://next-demo.coherehealth.com";
    case "demo2":
      return "https://next-demo2.coherehealth.com";
    case "dev":
      return "https://authtool-dev.coherehealth.com";
    case "uat":
      return "https://authtool-uat.coherehealth.com";
    default:
      return window?.location?.origin?.replace("careplanning", "authtool");
  }
}

export function queueMgmtBaseUrl(): string {
  const env = environmentAbbreviation();
  switch (env) {
    case "prod":
      return "https://care.coherehealth.com";
    case "local":
      // This is, at best, a convention
      return "http://localhost:3002";
    case "preprod":
      return "https://queue-mgmt-preprod.coherehealth.com";
    case "demo":
      return "https://queue-mgmt-demo.coherehealth.com";
    case "demo2":
      return "https://queue-mgmt-demo2.coherehealth.com";
    case "dev":
      return "https://queue-mgmt-dev.coherehealth.com";
    case "uat":
      return "https://queue-mgmt-uat.coherehealth.com";
    default:
      return window?.location?.origin?.replace("careplanning", "authtool");
  }
}
