import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import Footer from "../Footer";
import {
  AuthBuilderWorkflowStep,
  CarePathJourney,
  ClinicalService,
  Patient,
  ProcedureCode,
  ServiceRequestCreatePayload,
  ServiceRequestResponse,
  useUpdateServiceRequest,
  useUpdateUserExtension,
  CardExtensionProcedureCode,
  CardExtensionDetails,
} from "@coherehealth/core-platform-api";
import {
  navigateToPS,
  navigateToSRSummary,
  NonCohereCodes,
  PriorAuthRequirements,
  useDoPalCheck,
  useSetCrdLogIdAndUpdateServiceRequest,
} from "../common";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { useConfiguration, useFeature, withDefaultUnits } from "@coherehealth/common";
import { payloadFromSRFormContent } from "util/serviceRequest";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { User } from "UserContext";
import { TrackUserActivityProps, useTrackUserInteraction } from "util/userActivityTracker";

import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
// * Code tag RECENT_PROVIDERS_8B12CDB1
import { updateLocalStorageRecents } from "common/RecentProviders/utility";
import { RecentProvidersContext } from "common/RecentProviders/context";
import { getPatientHealthPlanName } from "util/patientUtils";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";

interface Props {
  saveAndExitButtonDisabled?: boolean;
  showSecondaryButton?: boolean;
  setWorkflowStep: Dispatch<AuthBuilderWorkflowStep>;
  patient: Patient | null;
  workflowStep: AuthBuilderWorkflowStep;
  carePathJourney?: CarePathJourney;
  editServiceRequest: (serviceRequestResponse: ServiceRequestResponse, facilityBasedWorkflowEnabled: boolean) => void;
  setFooterHeight: Dispatch<SetStateAction<number>>;
}

interface UseCreateRequirementsPalCheckProps extends Props {
  canCheckPAL?: boolean;
  priorAuthRequirements: PriorAuthRequirements;
  noPxService?: ClinicalService;
  setPriorAuthRequirements: Dispatch<SetStateAction<PriorAuthRequirements>>;
  setPalProcedureCodes: Dispatch<ProcedureCode[]>;
  setNonPalProcedureCodes: Dispatch<ProcedureCode[]>;
  setNonCohereCodes: Dispatch<NonCohereCodes[]>;
  setCrdLogId: Dispatch<string>;
  setCrdProcedureCodeResults: Dispatch<CardExtensionProcedureCode[] | undefined>;
  setCrdExtensionCardResults: Dispatch<CardExtensionDetails | undefined>;
  attemptedServiceRequestFormsSubmit: boolean;
  serviceRequestFormContents: ServiceRequestFormContent[];
  setAttemptedServiceRequestFormsSubmit: (b: boolean) => void;
  oonLoading: boolean;
  providerDetailsFormsCanBeSubmitted: boolean[];
  userFaxExtension?: string;
  currUser?: User;
  workflowId?: string;
  patientData: Patient | null;
}

const ProviderDetailsFooter = ({
  setWorkflowStep,
  noPxService,
  patient,
  priorAuthRequirements,
  setPriorAuthRequirements,
  setPalProcedureCodes,
  setNonPalProcedureCodes,
  setNonCohereCodes,
  setCrdLogId,
  setCrdProcedureCodeResults,
  setCrdExtensionCardResults,
  attemptedServiceRequestFormsSubmit,
  serviceRequestFormContents,
  setAttemptedServiceRequestFormsSubmit,
  oonLoading,
  providerDetailsFormsCanBeSubmitted,
  workflowStep,
  carePathJourney,
  editServiceRequest,
  userFaxExtension,
  currUser,
  workflowId,
  patientData,
  setFooterHeight,
}: UseCreateRequirementsPalCheckProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const trackUserActivityInteraction = useTrackUserInteraction();

  // * Code tag RECENT_PROVIDERS_8B12CDB1
  const {
    orderingProviderTrackingState,
    performingProviderTrackingState,
    facilityTrackingState,
    setOrderingProviderTrackingState,
    setPerformingProviderTrackingState,
    setFacilityTrackingState,
  } = useContext(RecentProvidersContext);
  const useRecentProviderDetails = useFeature("recentProviderDetails");
  const healthPlanName = getPatientHealthPlanName(patientData || undefined, priorAuthRequirements.startDate) || "";
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName,
    encounterType: priorAuthRequirements.encounterType,
    priorAuthRequirements,
    skipRequestTimingCheck: true,
    srContent: serviceRequestFormContents[0],
  });

  const recentlyUsedProviderConfiguration = useConfiguration("recentlyUsedProviderConfiguration", healthPlanName) ?? {};

  const [, setAttemptedSubmit] = useState(false);

  const serviceRequestFormsSubmitLoading = serviceRequestFormContents.length === 0;
  const hasPxCodes =
    priorAuthRequirements?.desiredProcedureCodes && priorAuthRequirements?.desiredProcedureCodes?.length > 0;

  const canSubmitServiceRequestForms = !providerDetailsFormsCanBeSubmitted.includes(false);
  const invalidForms = !canSubmitServiceRequestForms && attemptedServiceRequestFormsSubmit;
  const canContinue = !invalidForms && !serviceRequestFormsSubmitLoading && !oonLoading;

  const {
    mutate: updateServiceRequest,
    loading: updateServiceRequestLoading,
    error: updateServiceRequestError,
  } = useUpdateServiceRequest({ id: "" });

  const setCrdLogIdAndUpdateServiceRequest = useSetCrdLogIdAndUpdateServiceRequest({
    setCrdLogId,
    serviceRequestId: serviceRequestFormContents[0]?.id,
    updateServiceRequest,
  });

  const { doPalCheck, palCheckLoading } = useDoPalCheck({
    canCheckPAL: hasPxCodes || (serviceRequestFormContents[0]?.isInpatient && facilityBasedFeatureEnabled),
    noPxService,
    patientId: patient?.id || "",
    patientData: patient,
    setAttemptedSubmit,
    setNonCohereCodes,
    setNonPalProcedureCodes,
    setPalProcedureCodes,
    setPriorAuthRequirements,
    setCrdLogId: setCrdLogIdAndUpdateServiceRequest,
    setCrdProcedureCodeResults,
    setCrdExtensionCardResults,
    serviceRequestFormContents: serviceRequestFormContents,
  });

  const {
    mutate: updateUser,
    loading: userUpdateLoading,
    error: userUpdateError,
  } = useUpdateUserExtension({
    id: currUser?.sub || "",
  });

  useEffect(() => {
    if (updateServiceRequestError) {
      //temporary replace with servRequestFailedSnackbar
      enqueueSnackbar("Failed to update service request, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [updateServiceRequestError, enqueueSnackbar]);

  const continueButtonChecks = async () => {
    setAttemptedServiceRequestFormsSubmit(true);
    if (canSubmitServiceRequestForms) {
      await patchServiceRequest();
      await doPalCheck(priorAuthRequirements);
      setWorkflowStep("SERVICE_DETAILS");

      // * Code tag RECENT_PROVIDERS_8B12CDB1
      const healthPlanName = getPatientHealthPlanName(patient || undefined);
      if (useRecentProviderDetails && healthPlanName) {
        updateLocalStorageRecents(serviceRequestFormContents, healthPlanName, recentlyUsedProviderConfiguration);
        // * Submit tracking events for Recent Providers
        if (orderingProviderTrackingState) {
          trackUserActivityInteraction({
            stage: "SELECT_RECENT",
            event: orderingProviderTrackingState,
          });
        }
        if (performingProviderTrackingState) {
          trackUserActivityInteraction({
            stage: "SELECT_RECENT",
            event: performingProviderTrackingState,
          });
        }
        if (facilityTrackingState) {
          trackUserActivityInteraction({
            stage: "SELECT_RECENT",
            event: facilityTrackingState,
          });
        }
        // * Reset Recent Providers tracking state
        if (setOrderingProviderTrackingState) {
          setOrderingProviderTrackingState("RECENT_OP_NOT_CLICKED");
        }
        if (setPerformingProviderTrackingState) {
          setPerformingProviderTrackingState("RECENT_PP_NOT_CLICKED");
        }
        if (setFacilityTrackingState) {
          setFacilityTrackingState("RECENT_F_NOT_CLICKED");
        }
      }
    }
  };

  const patchServiceRequest = async () => {
    //patch the service request here
    const sr = { ...serviceRequestFormContents[0], workflowStep: workflowStep };
    let mostRecentlyUsedFaxNumber = serviceRequestFormContents[0]?.followUpFaxNumber?.number;
    let mostRecentlyUsedFaxNumberSRId = serviceRequestFormContents[0]?.id;
    const originalPxCodes = priorAuthRequirements?.desiredProcedureCodes?.filter((pxCode) => {
      return !serviceRequestFormContents[0]?.procedureCodes?.find((palProcedureCodes) => {
        return pxCode.code === palProcedureCodes.code;
      });
    });
    const semanticProcedureCodes = originalPxCodes
      ? [...serviceRequestFormContents[0]?.procedureCodes, ...originalPxCodes.map(withDefaultUnits)]
      : [...serviceRequestFormContents[0]?.procedureCodes];
    const payload: ServiceRequestCreatePayload = {
      ...payloadFromSRFormContent(sr, true, undefined, workflowId, false),
      semanticProcedureCodes,
    };
    if (sr?.id) {
      const response = await updateServiceRequest(
        { ...payload, carePathJourney: carePathJourney?.id },
        { pathParams: { id: sr.id } }
      );

      if (response) {
        editServiceRequest(response, !!facilityBasedFeatureEnabled);

        //if we asked for a fax number compare most recently user fax number for the user
        //and update accordingly
        if (mostRecentlyUsedFaxNumber && mostRecentlyUsedFaxNumber !== userFaxExtension) {
          await updateUser({ mostRecentlyUsedFaxNumber }, { pathParams: { id: currUser?.sub || "" } });
          if (!userUpdateError) {
            const userActivityInteractionPayload: TrackUserActivityProps = {
              event: "UPDATE_REQUESTOR_FAX",
              stage: "AUTH_CREATION",
              beforeSnapshot: { requestorFaxNumber: userFaxExtension },
              afterSnapshot: { requestorFaxNumber: mostRecentlyUsedFaxNumber },
            };
            userActivityInteractionPayload.activityContext = {
              patientId: patient?.id,
              serviceRequestId: mostRecentlyUsedFaxNumberSRId,
              workflowId: workflowId,
            };
            trackUserActivityInteraction({ ...userActivityInteractionPayload });
          }
        }
      }
    }
  };

  const smartClient = useContext(SmartOnFhirContext);
  const inSmartOnFhirWorkflow = Boolean(smartClient);

  const onSaveAndExitClick = async () => {
    await patchServiceRequest();
    if (inSmartOnFhirWorkflow) {
      navigateToSRSummary(serviceRequestFormContents[0]?.id || "", navigate, true);
    } else {
      navigateToPS(patient?.id || "", navigate, serviceRequestFormContents[0]?.id || "");
    }
  };

  return (
    <Footer
      primaryButtonText={"Continue"}
      onPrimaryButtonClick={() => {
        continueButtonChecks();
      }}
      primaryButtonDisabled={!canContinue || palCheckLoading || updateServiceRequestLoading || userUpdateLoading}
      primaryButtonLoading={palCheckLoading || updateServiceRequestLoading || userUpdateLoading || oonLoading}
      errorCaptionText={"There are some errors with the details above."}
      showError={invalidForms}
      showSaveAndExitButton={true}
      onSaveAndExitClick={onSaveAndExitClick}
      saveAndExitButtonDisabled={palCheckLoading || updateServiceRequestLoading || userUpdateLoading}
      setFooterHeight={setFooterHeight}
    />
  );
};

export default ProviderDetailsFooter;
