import {
  FaxAttachment,
  ReferralRequestResponse,
  ReferralRequestWorflowStep,
  Requestor,
  useReferralRequestFormConfiguration,
} from "@coherehealth/core-platform-api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PathMatch, useLocation, useMatch, Location, useNavigate } from "react-router";
import routes from "routes";
import { useGetReferralRequestFormConfiguration } from "../FormContentSpecification/ReferralRequestFormContentSpecifications";
import ReferralRequestDetailsContainer from "./RequestDetails";
import { ReferralRequestFormContent, Row } from "./ReferralRequestForm";
import {
  defaultFormContent,
  rrFormContentFromResponse,
  useGetPatientWithReferralEligibility,
} from "util/referralRequest";
import ReferralHeader from "./ReferralHeader";
import { AppBar, Box, CircularProgress, Container, Grid, makeStyles, useTheme } from "@material-ui/core";
import { containerSmallBreakpoint, InformativeModal, useMuiContainerStyles } from "@coherehealth/common";
import ReferralRequestDetailsFooter from "./RequestDetails/Footer";
import { Helmet } from "react-helmet-async";
import { getCoverageBasedOnDate } from "util/patientUtils";
import { useSearchParams } from "react-router-dom";
import ReviewContainer from "./Review";
import ReferralReviewFooter from "./Review/ReferralReviewFooter";
import { useSnackbar } from "notistack";
import { useAuthorized } from "authorization";
import RequestorCard from "components/Requestor/RequestorCard";
import { useDeleteReferralRequest } from "@coherehealth/core-platform-api";
import ReferralCancelIcon from "components/images/ReferralCancelIcon";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";
import { useGetFaxAttachmentFromUrl } from "components/AuthBuilder/common";
import { useIsFaxReferralsWorkflow } from "util/attachmentUtil";
import { ReferralsNavigationContext } from "components/DocumentViewer/FaxAttachment/FaxReferralsBuilderPage/ReferralsNavigationContext";
import { useGetReturnToUrl } from "util/queryParams";
import { useChatWidgetForPHI } from "util/chatWidgetForPHI";

export default function ReferralRequestPage() {
  const match = useMatch(routes.REFERRAL_BUILDER);
  const location = useLocation();

  // Fax info received as url params when redirected from Fax intake workflow
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  useChatWidgetForPHI();
  const qmCaseId = search.get("caseId");
  const queueId = search.get("queueId") || "";
  const faxId = search.get("faxId") || "";
  return (
    <>
      <Helmet>
        <title>Cohere | New Referral</title>
      </Helmet>
      <ReferralBuilderContent
        match={match}
        location={location}
        search={search}
        qmCaseId={qmCaseId}
        queueId={queueId}
        faxId={faxId}
      />
      ;
    </>
  );
}

interface ReferralUrlParams {
  qmCaseId: string | null;
  queueId: string;
  faxId: string;
}

interface Props extends ReferralUrlParams {
  match: PathMatch<string> | null;
  location: Location;
  search: URLSearchParams;
}

interface FooterStyleProps {
  isFaxReferralsFlow?: boolean;
}

interface RowStyleProps {
  requestorFormAuthorized?: boolean;
}

const useFooterStyles = makeStyles((theme) => ({
  root: ({ isFaxReferralsFlow }: FooterStyleProps) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    top: "auto",
    bottom: 0,
    width: isFaxReferralsFlow ? containerSmallBreakpoint : "100%",
  }),
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
}));

const useRowStyles = makeStyles((theme) => ({
  row: ({ requestorFormAuthorized }: RowStyleProps) => ({
    marginBottom: !requestorFormAuthorized ? theme.spacing(2) : 0,
  }),
}));

export function ReferralBuilderContent({ match, location, faxId, queueId, qmCaseId, search }: Props) {
  const returnToUrl = useGetReturnToUrl(faxId);

  const faxData: FaxAttachment | null = useGetFaxAttachmentFromUrl();
  const isFaxReferralsFlow = useIsFaxReferralsWorkflow(location);
  const theme = useTheme();
  const containerClasses = useMuiContainerStyles();
  const footerClasses = useFooterStyles({ isFaxReferralsFlow });
  const { enqueueSnackbar } = useSnackbar();
  const [footerHeight, setFooterHeight] = useState(0);
  const patientId = match?.params.patientId;
  const patientData = useGetPatientWithReferralEligibility(patientId || "");
  const isAdministrative =
    patientData?.referralEligibility?.delegated === true &&
    patientData?.referralEligibility?.referralRequired === false;
  const coverage = getCoverageBasedOnDate(new Date(), patientData ?? undefined);
  const healthPlanName = coverage?.healthPlanName;
  const { data: configSpec } = useReferralRequestFormConfiguration({
    queryParams: { patientId: patientId, isAdministrative: isAdministrative },
    lazy: false,
  });
  const formConfigBasedOnPatientCoverage = useGetReferralRequestFormConfiguration(
    patientData,
    isAdministrative,
    configSpec
  );
  const [workflowStep, setWorkflowStepInternal] = useState<ReferralRequestWorflowStep>("REFERRAL_REQUEST_DETAILS");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const usePathWithReferralBuilderStep = (workflowStep: ReferralRequestWorflowStep) => {
    const path = useCallback(() => {
      const params = new URLSearchParams(searchParams);
      params.set("referralBuilderStep", workflowStep);
      const searchPart = params.toString();
      return `?${searchPart}`;
    }, [workflowStep]);
    return path();
  };
  const curStep = usePathWithReferralBuilderStep(workflowStep);
  const [rrFormsCanBeSubmitted, setRRFormsCanBeSubmitted] = useState<boolean>(false);
  const [rrFormContent, setRRFormContent] = useState<ReferralRequestFormContent>(defaultFormContent);
  const [referralRequest, setReferralRequest] = useState<ReferralRequestResponse>();
  const [attemptedReferralRequestFormsSubmit, setAttemptedReferralRequestFormsSubmit] = useState(false);
  const [hasPerformingSpecialistAttestation, setHasPerformingSpecialistAttestation] = useState(false);
  const [hasFacilityAttestation, setHasFacilityAttestation] = useState(false);
  const { mutate: deleteReferralRequest, error: deleteReferralError } = useDeleteReferralRequest({});
  const footerOffset = footerHeight + (isFaxReferralsFlow ? theme.spacing(4) : theme.spacing(60.25));

  const requestorFormAuthorized = useAuthorized("REQUESTOR_FORM");
  const rowClasses = useRowStyles({ requestorFormAuthorized });
  const [requestor, setRequestor] = useState<Requestor | undefined>({
    requestorType: "ORDERING_PROVIDER",
    channel: "PORTAL",
  });
  const [userUpdatedTat] = useState<boolean>(false);

  const setWorkflowStep = useCallback((step: ReferralRequestWorflowStep) => {
    setWorkflowStepInternal(step);
  }, []);
  const addReferralRequest = (rr: ReferralRequestResponse, newDraftRRId = "") => {
    if (newDraftRRId) {
      setReferralRequest((prevReferral) => ({ ...prevReferral, ...rr, id: newDraftRRId }));
    }
  };

  const handleCancel = async () => {
    if (workflowStep === "REFERRAL_REQUEST_REVIEW") {
      await deleteReferralRequest(rrFormContent.id || "");
    }

    if (isFaxReferralsFlow) {
      navigate(returnToUrl);
    } else {
      window.location.href = routes.REFERRAL_DASHBOARD;
    }
  };

  /* 
    editReferralRequest functionality handles editions made to current request in the backward workflow
    Testable after implementing INTER-760
  */

  const editReferralRequest = (rr: ReferralRequestResponse) => {
    setReferralRequest((prevReferral) => {
      if (prevReferral?.id === rr.id) {
        return rr;
      }
      return prevReferral;
    });

    setRRFormContent((currContent) => {
      if (currContent.id === rr.id) {
        let request = rrFormContentFromResponse(rr);
        if (request.primarySemanticDiagnosisCode !== currContent.primarySemanticDiagnosisCode) {
          request = {
            ...request,
            primarySemanticDiagnosisCode: currContent.primarySemanticDiagnosisCode || null,
          };
        }
        if (
          (request.secondarySemanticDiagnosisCodes || [])?.length <
          (currContent.secondarySemanticDiagnosisCodes || [])?.length
        ) {
          request = {
            ...request,
            secondarySemanticDiagnosisCodes: currContent.secondarySemanticDiagnosisCodes || [],
          };
        }
        if (request.selectedFacility?.npi !== currContent.selectedFacility?.npi) {
          request = {
            ...request,
            selectedFacility: currContent.selectedFacility || null,
            // Todo: INTER-760 - Add OON check refresh logic here
          };
        } else {
          if (request.facilitySelectedTin !== currContent.facilitySelectedTin) {
            request = {
              ...request,
              facilitySelectedTin: currContent.facilitySelectedTin,
            };
          }
          if (request.facilitySelectedAddress !== currContent.facilitySelectedAddress) {
            request = {
              ...request,
              facilitySelectedAddress: currContent.facilitySelectedAddress,
            };
          }
        }
        if (request.selectedPerformingSpecialist?.npi !== currContent.selectedPerformingSpecialist?.npi) {
          request = {
            ...request,
            selectedPerformingSpecialist: currContent.selectedPerformingSpecialist || null,
            // Todo: INTER-760 - Add OON check refresh logic here
          };
        } else {
          if (request.performingSpecialistSelectedTin !== currContent.performingSpecialistSelectedTin) {
            request = {
              ...request,
              performingSpecialistSelectedTin: currContent.performingSpecialistSelectedTin,
            };
          }
          if (request.performingSpecialistSelectedAddress !== currContent.performingSpecialistSelectedAddress) {
            request = {
              ...request,
              performingSpecialistSelectedAddress: currContent.performingSpecialistSelectedAddress,
            };
          }
        }
        if (request.selectedReferringProvider?.npi !== currContent.selectedReferringProvider?.npi) {
          request = {
            ...request,
            selectedReferringProvider: currContent.selectedReferringProvider || null,
            // Todo: INTER-760 - Add OON check refresh logic here
          };
        } else {
          if (request.referringProviderSelectedTin !== currContent.referringProviderSelectedTin) {
            request = {
              ...request,
              referringProviderSelectedTin: currContent.referringProviderSelectedTin,
            };
          }
          if (request.referringProviderSelectedAddress !== currContent.referringProviderSelectedAddress) {
            request = {
              ...request,
              referringProviderSelectedAddress: currContent.referringProviderSelectedAddress,
            };
          }
        }
        if (request.specialty !== currContent.specialty) {
          request = {
            ...request,
            specialty: currContent.specialty,
          };
        }
        if (request.startDate !== currContent.startDate) {
          request = {
            ...request,
            startDate: currContent.startDate,
          };
        }
        return request;
      }
      return currContent;
    });
  };

  const navigateBack = async () => {
    if (workflowStep === "REFERRAL_REQUEST_REVIEW") {
      setWorkflowStep("REFERRAL_REQUEST_DETAILS");
      setAttemptedReferralRequestFormsSubmit(false);
    }
  };

  useEffect(() => {
    if (deleteReferralError) {
      enqueueSnackbar(`Error canceling referral request: ${deleteReferralError?.message || ""}`, { variant: "error" });
    }
  }, [enqueueSnackbar, deleteReferralError]);

  useEffect(() => {
    if (curStep) {
      navigate(curStep, { replace: true, state: location.state });
    }
  }, [curStep, location.state, navigate]);

  //auto-populate requestor channel to fax if referral creation from fax workflow
  useEffect(() => {
    if (!!faxId) {
      setRequestor((currentRequestor) => ({
        ...currentRequestor,
        channel: "FAX",
        requestorType: "ORDERING_PROVIDER",
      }));
    }
  }, [faxId]);

  let pageContents: JSX.Element;
  let footerContents: JSX.Element;

  switch (workflowStep) {
    case "REFERRAL_REQUEST_DETAILS":
      pageContents = formConfigBasedOnPatientCoverage ? (
        <div style={{ marginBottom: footerOffset }}>
          <ReferralRequestDetailsContainer
            attemptSubmit={attemptedReferralRequestFormsSubmit}
            rrformContent={rrFormContent}
            setRRFormContent={setRRFormContent}
            rrFormConfiguration={formConfigBasedOnPatientCoverage}
            setReferralRequestCanBeSubmitted={setRRFormsCanBeSubmitted}
            patientData={patientData ?? undefined}
            coverage={coverage}
            isAdministrative={isAdministrative}
            hasPerformingSpecialistAttestation={hasPerformingSpecialistAttestation}
            setHasPerformingSpecialistAttestation={setHasPerformingSpecialistAttestation}
            hasFacilityAttestation={hasFacilityAttestation}
            setHasFacilityAttestation={setHasFacilityAttestation}
          />
        </div>
      ) : (
        <></>
      );
      footerContents = (
        <ReferralRequestDetailsFooter
          setCancelModalOpen={setCancelModalOpen}
          patientId={patientId || ""}
          healthPlanName={healthPlanName || ""}
          isAdministrative={isAdministrative}
          rrFormContent={rrFormContent}
          setRRFormContent={setRRFormContent}
          workflowStep={workflowStep}
          setWorkflowStep={setWorkflowStep}
          addReferralRequest={addReferralRequest}
          editReferralRequest={editReferralRequest}
          setAttemptedReferralRequestFormsSubmit={setAttemptedReferralRequestFormsSubmit}
          referralRequestFormCanBeSubmitted={rrFormsCanBeSubmitted}
          setFooterHeight={setFooterHeight}
          userUpdatedTat={userUpdatedTat}
          requestor={requestor}
        />
      );
      break;
    case "REFERRAL_REQUEST_REVIEW":
      if (!referralRequest) {
        enqueueSnackbar(`No referral request found`, { variant: "error", preventDuplicate: true });
        pageContents = <></>;
        footerContents = <></>;
      } else if (!formConfigBasedOnPatientCoverage) {
        enqueueSnackbar(`No referral request configuration found`, { variant: "error", preventDuplicate: true });
        pageContents = <></>;
        footerContents = <></>;
      } else {
        pageContents = <ReviewContainer referralRequest={referralRequest} isAdministrative={isAdministrative} />;
        footerContents = (
          <ReferralReviewFooter
            referralRequest={referralRequest}
            requestor={requestor}
            setFooterHeight={setFooterHeight}
            attemptedSubmitServices={attemptedReferralRequestFormsSubmit}
            setAttemptedReferralRequestFormsSubmit={setAttemptedReferralRequestFormsSubmit}
            setCancelModalOpen={setCancelModalOpen}
          />
        );
      }
      break;
    default:
      pageContents = <></>;
      footerContents = <></>;
  }

  return (
    <FaxAttachmentContext.Provider
      value={{
        faxDbId: faxId,
        caseId: qmCaseId ?? undefined,
        queueId,
        faxAttachment: faxData,
        healthPlanName: healthPlanName ?? undefined,
      }}
    >
      <ReferralsNavigationContext.Provider
        value={{
          workflowStep: workflowStep,
          navigateBack: navigateBack,
          isFaxReferralsFlow: isFaxReferralsFlow,
          handleCancel: handleCancel,
        }}
      >
        <div>
          <Row className={rowClasses.row}>
            <ReferralHeader
              headerHeightWithNavButtons={theme.spacing(12)}
              workflowStep={workflowStep}
              navigateBack={navigateBack}
              patient={patientData ?? undefined}
            />
          </Row>
          <Container classes={containerClasses} maxWidth="lg">
            <Grid container>
              <Row>
                {requestorFormAuthorized && (
                  <Box
                    paddingTop={isFaxReferralsFlow ? theme.spacing(1.625) : theme.spacing(2)}
                    marginBottom={theme.spacing(-1.65)}
                  >
                    <RequestorCard requestor={requestor} setRequestor={setRequestor} isContinuationWorkflow={false} />
                  </Box>
                )}
              </Row>
              <Row>
                {!patientData ? (
                  <Box display="flex" justifyContent="center" width="100%" marginTop={theme.spacing(2)}>
                    <CircularProgress size={40} />
                  </Box>
                ) : (
                  pageContents
                )}
              </Row>
            </Grid>
          </Container>
          <Row>
            <AppBar classes={footerClasses} component="footer" elevation={0}>
              <Container classes={isFaxReferralsFlow ? undefined : containerClasses}>
                <div>{footerContents}</div>
              </Container>
            </AppBar>
          </Row>

          <InformativeModal
            open={cancelModalOpen}
            onClose={() => setCancelModalOpen(false)}
            icon={<ReferralCancelIcon />}
            headerText="Are you sure you want to cancel this referral?"
            subHeaderText="Your progress will not be saved."
            primaryButtonText="Yes, cancel"
            primaryButtonAction={handleCancel}
            tertiaryButtonText="No, return to referral"
            tertiaryButtonAction={() => setCancelModalOpen(false)}
          />
        </div>
      </ReferralsNavigationContext.Provider>
    </FaxAttachmentContext.Provider>
  );
}
