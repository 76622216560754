import React, { useEffect, useMemo, useState } from "react";
import { AGADIA_NAME, H6, InlineButton, Tooltip, useFeature } from "@coherehealth/common";
import EditIcon from "@material-ui/icons/Edit";
import { Grid, Divider, makeStyles, Theme, createStyles } from "@material-ui/core";
import AttachmentSummary from "./AttachmentSummary";
import {
  AppealResponse,
  Attachment,
  AuthorizationResponse,
  ReferralRequestResponse,
  ServiceRequestResponse,
  useGetAppealsForAuthorization,
} from "@coherehealth/core-platform-api";
import { useAuthorized } from "authorization";
import { removeEmptyAttachments, getAttachmentString } from "util/attachmentUtil";
import AttachmentSectionHeader from "./AttachmentSectionHeader";
import { isFinallyDeterminedUtility, isNegationAuthStatus } from "util/serviceRequest";
import { useGetServiceRequestEditConfigurationByPayerAndAuthStatus } from "hooks/useGetFeatureConfigurations";
import useOpsEditDisabled from "hooks/useOpsEditDisabled";
import { mapAttachmentsToAppeals } from "../ClinicalReview/reviewUtils/useAttachment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridAttachmentSection: {
      "&.MuiGrid-item": {
        padding: "0 0 10px 0",
      },
    },
    gridNoMargin: {
      margin: 0,
    },
    gridNoPadding: {
      "&.MuiGrid-item": {
        padding: 0,
      },
    },
    gridTopBottomPadding: {
      padding: theme.spacing(1, 0),
    },
    gridTopBottomPaddingNoMargin: {
      "&.MuiGrid-item": {
        padding: theme.spacing(1, 0),
        margin: 0,
      },
    },
  })
);

interface Props {
  authorization?: AuthorizationResponse;
  // If this is an Authorization, serviceRequest is the latest Service Request
  serviceRequest?: ServiceRequestResponse;
  //referralRequest and serviceRequest should be mutually exclusive and should not be both available
  referralRequest?: ReferralRequestResponse;
  setViewState: (state: "READ_ONLY" | "EDIT") => void;
  singleAttachmentViewerRedesign?: boolean;
}

export default function AttachmentSectionReadOnly({
  authorization,
  serviceRequest,
  referralRequest,
  setViewState,
  singleAttachmentViewerRedesign,
}: Props) {
  const classes = useStyles();
  const srEditsPayerConfigurable = useFeature("srEditsPayerConfigurable");
  //below currently not implemented for referrals (yet) so edit config requests will not fire for referral requests(healthPlanName must be present for edit config requests to trigger)
  const { srEditConfig } = useGetServiceRequestEditConfigurationByPayerAndAuthStatus({
    healthPlanName: serviceRequest?.healthPlanName,
    authStatus: serviceRequest?.authStatus,
  });
  const hasEditAttachmentsPermission = useAuthorized("EDIT_ATTACHMENTS");
  const isDecisioned =
    (serviceRequest && isFinallyDeterminedUtility(serviceRequest?.authStatus)) ||
    (referralRequest && isFinallyDeterminedUtility(referralRequest?.referralStatus));
  const isNegatedStatus =
    (serviceRequest && isNegationAuthStatus(serviceRequest?.authStatus)) ||
    (referralRequest && isNegationAuthStatus(referralRequest?.referralStatus));
  const isPartAuthWithTerminalStatus =
    isNegatedStatus && authorization?.serviceRequestsOnAuth?.every((sr) => isNegationAuthStatus(sr.authStatus));
  const canAddAfterTerminalStatus = useAuthorized("ADD_ATTACHMENT_AFTER_TERMINAL_STATUS");

  const editCheck = srEditsPayerConfigurable
    ? srEditConfig?.disableAttachmentEdits && !canAddAfterTerminalStatus
    : ((isDecisioned || isNegatedStatus) && !canAddAfterTerminalStatus) || isPartAuthWithTerminalStatus;
  const isDecisionedAttachmentsDisabled = useOpsEditDisabled(serviceRequest) || editCheck;
  const decisionedTooltipText = isDecisionedAttachmentsDisabled
    ? srEditConfig?.disableAttachmentEditsMessage || ""
    : "";

  // Gather attachments from all ServiceRequests in the Authorization
  const authorizationAttachments: Attachment[] = [];
  //TODO may need to modify this for referrals once they are linked to authorizations
  if (authorization && authorization.serviceRequestsOnAuth) {
    authorization.serviceRequestsOnAuth.forEach((sr) => {
      sr.attachments?.forEach((attachment) => {
        authorizationAttachments.push(attachment);
      });
    });
  }
  //get all service request attachments if serviceRequest was passed in, otherwise grab all attachments associated with referralRequest
  if (!authorization && !referralRequest && serviceRequest) {
    serviceRequest.attachments?.forEach((attachment) => {
      authorizationAttachments.push(attachment);
    });
  }
  if (!authorization && !serviceRequest && referralRequest) {
    referralRequest.attachments?.forEach((attachment) => {
      authorizationAttachments.push(attachment);
    });
  }

  const attachmentList = removeEmptyAttachments(authorizationAttachments) || [];

  const [appealsForAuth, setAppealsForAuth] = useState<AppealResponse[]>([]);

  const { refetch: refetchAppealsForAuth } = useGetAppealsForAuthorization({
    authorizationId: authorization?.id || "",
    lazy: true,
  });

  useEffect(() => {
    if (authorization?.id) {
      refetchAppealsForAuth().then((data: AppealResponse[] | null) => {
        if (data) {
          setAppealsForAuth(data);
        }
      });
    }
  }, [authorization?.id, refetchAppealsForAuth]);

  const attachmentToAppealMap = useMemo(() => mapAttachmentsToAppeals(appealsForAuth), [appealsForAuth]);

  const onEditAttachmentsClick = () => {
    setViewState("EDIT");
  };

  return (
    <Grid container item xs={12} justifyContent="space-between" spacing={3} className={classes.gridNoMargin}>
      <Grid item xs={6} className={classes.gridAttachmentSection}>
        <AttachmentSectionHeader attachmentsList={attachmentList} serviceRequest={serviceRequest} />
      </Grid>
      {hasEditAttachmentsPermission && (
        // Hide edit button if user does not have edit role or if this is on a referralRequest (this will likely change in near future as we add on to referralRequest functionality)
        <Grid className={classes.gridNoPadding}>
          <Tooltip title={decisionedTooltipText} placement="top" arrow>
            <span>
              <InlineButton
                startIcon={<EditIcon />}
                onClick={onEditAttachmentsClick}
                disabled={
                  // disabled will show the edit button in a disabled state, this is dependent on the srEditConfig and authStatus
                  isDecisionedAttachmentsDisabled ||
                  // TODO: clean up this hard-coded Agadia-specific logic (use the payer config instead. See ServiceRequestEditConfiguration for details)
                  (serviceRequest?.delegatedVendor === AGADIA_NAME && serviceRequest?.authStatus !== "DRAFT") ||
                  serviceRequest?.isRestricted
                }
              >
                Edit
              </InlineButton>
            </span>
          </Tooltip>
        </Grid>
      )}
      <Grid container item xs={12} spacing={2} className={classes.gridTopBottomPaddingNoMargin}>
        <>
          {
            <AttachmentsWithoutClassificationResults
              serviceRequest={serviceRequest}
              attachments={attachmentList}
              attachmentToAppealMap={attachmentToAppealMap}
              singleAttachmentViewerRedesign={singleAttachmentViewerRedesign}
            />
          }
        </>
      </Grid>
    </Grid>
  );
}

function AttachmentsWithoutClassificationResults({
  serviceRequest,
  attachments,
  singleAttachmentViewerRedesign,
  attachmentToAppealMap,
}: {
  serviceRequest?: ServiceRequestResponse;
  attachments: Attachment[];
  singleAttachmentViewerRedesign?: boolean;
  attachmentToAppealMap: Record<string, string>;
}) {
  const classes = useStyles();
  return (
    <>
      {attachments.length > 0 ? (
        <>
          {attachments.map((attachment, idx) => (
            <React.Fragment key={attachment.id}>
              <AttachmentSummary
                attachment={attachment}
                appealId={attachmentToAppealMap[attachment.id]}
                stage="PATIENT_SUMMARY"
                singleAttachmentViewerRedesign={singleAttachmentViewerRedesign}
              />
              {idx < (attachments.length || 0) - 1 && (
                <Grid item xs={12} className={classes.gridTopBottomPadding}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </>
      ) : (
        <Grid item xs={12} className={classes.gridNoPadding}>
          <H6 data-public>No {getAttachmentString(serviceRequest)}</H6>
        </Grid>
      )}
    </>
  );
}
