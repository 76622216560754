import {
  OutreachAttempt,
  ServiceRequestResponse,
  usePostOutreachAttempt,
  usePostOutreachOpportunity,
} from "@coherehealth/core-platform-api";
import { useGetUser } from "components/ClinicalReview/reviewUtils/utils";
import { useSnackbar } from "notistack";
import { useEffect, useState, useCallback, useRef } from "react";
import { isPhoneNumberValid } from "util/phoneUtils";
import { error as logError } from "logger";
import { stripHTMl, useFeature } from "@coherehealth/common";

interface UseOutreachAttempt {
  serviceRequest: ServiceRequestResponse;
  reviewId?: string;
  defaultOutreachOverride?: Partial<OutreachAttempt>;
  draftOutreachAttempt?: OutreachAttempt | null;
}

export interface OutreachAttemptProps {
  outreachAttempt: OutreachAttempt;
  setOutreachAttempt: React.Dispatch<React.SetStateAction<OutreachAttempt>>;
  saveOutreachAttempt: () => Promise<OutreachAttempt | undefined>;
  outreachAttemptLoading: boolean;
  outreachFormConfig: OutreachFormFields;
  outreachFormErrors: OutreachFormFields;
  setOutreachFormErrors: React.Dispatch<React.SetStateAction<OutreachFormFields>>;
  validateOutreachForm: () => boolean;
  hasOutreachAttempt: boolean;
  setHasOutreachAttempt: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Custom hook to manage outreach attempt.
 */
const useOutreachAttempt = ({
  serviceRequest,
  reviewId,
  defaultOutreachOverride,
  draftOutreachAttempt,
}: UseOutreachAttempt): OutreachAttemptProps => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useGetUser();
  const initialRender = useRef(true);
  const draftOutreachAttemptEnabled = useFeature("draftOutreachAttempt");
  const [outreachAttempt, setOutreachAttempt] = useState<OutreachAttempt>(
    draftOutreachAttempt && draftOutreachAttemptEnabled
      ? {
          ...draftOutreachAttempt,
          scheduledSend: new Date().toISOString(),
          serviceRequestId: serviceRequest?.id,
          reviewId: reviewId,
          healthPlanName: serviceRequest?.healthPlanName || "",
        }
      : {
          scheduledSend: new Date().toISOString(),
          serviceRequestId: serviceRequest?.id,
          channel: "PHONE",
          manual: true,
          providerPhoneNumber: serviceRequest?.orderingProvider?.phoneNumbers?.[0] || {},
          reviewId: reviewId,
          healthPlanName: serviceRequest?.healthPlanName || "",
          recipient: "ORDERING_PROVIDER",
          contactType: "OUTBOUND",
          ...defaultOutreachOverride,
        }
  );
  const [hasOutreachAttempt, setHasOutreachAttempt] = useState<boolean>(false);

  const {
    error: outreachAttemptError,
    mutate: postOutreachAttempt,
    loading: postingOutreachAttempt,
  } = usePostOutreachAttempt({
    outreachOpportunityId: "",
  });

  const {
    error: outreachOpportunityError,
    mutate: postOutreachOpportunity,
    loading: postingOutreachOpportunity,
  } = usePostOutreachOpportunity({});

  useEffect(() => {
    if (outreachAttemptError) {
      enqueueSnackbar("Failed to upload outreach attempt, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
    if (outreachOpportunityError) {
      enqueueSnackbar("Failed to upload outreach opportunity, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [outreachOpportunityError, enqueueSnackbar, outreachAttemptError]);

  useEffect(() => {
    if (!initialRender.current || !draftOutreachAttemptEnabled) {
      return;
    }
    initialRender.current = false;

    const hasDraftOutreachAttempt = Boolean(draftOutreachAttempt);
    setHasOutreachAttempt(hasDraftOutreachAttempt);
  }, [draftOutreachAttempt, setHasOutreachAttempt, draftOutreachAttemptEnabled]);

  const saveOutreachAttempt = useCallback(async () => {
    const opportunity = await postOutreachOpportunity({
      serviceRequest: {
        id: serviceRequest?.id,
        dateCreated: serviceRequest?.dateCreated,
        lastUpdated: serviceRequest?.lastUpdated,
      },
      status: "READY",
      manualOpportunityTrigger: "MISSING_INFO",
      healthPlanName: serviceRequest?.healthPlanName || "",
    });
    const result = await withRetry<OutreachAttempt, typeof postOutreachAttempt>(postOutreachAttempt, 3)(
      {
        ...outreachAttempt,
        outreachOpportunity: opportunity,
        completedDate: new Date().toISOString(),
        completedBy: user?.sub,
        completedByName: user?.name,
        contactType: reviewId ? "OUTBOUND" : outreachAttempt.contactType,
        healthPlanName: serviceRequest?.healthPlanName,
      },
      { pathParams: { outreachOpportunityId: opportunity.id || "" } }
    );
    return result;
  }, [outreachAttempt, postOutreachAttempt, postOutreachOpportunity, reviewId, serviceRequest, user?.name, user?.sub]);

  const outreachAttemptLoading = postingOutreachOpportunity || postingOutreachAttempt;

  const outreachFormConfig = getOutreachDisplayConfig(outreachAttempt, !!reviewId);
  const [outreachFormErrors, setOutreachFormErrors] = useState<OutreachFormFields>(defaultOutreachErrors);

  const validateAndSetErrorFields = useCallback(() => {
    const { formIsValid, errorStates } = validateOutreachForm(outreachFormConfig, outreachAttempt);
    setOutreachFormErrors(errorStates);
    return formIsValid;
  }, [outreachFormConfig, outreachAttempt]);

  return {
    outreachAttempt,
    setOutreachAttempt,
    saveOutreachAttempt,
    outreachAttemptLoading,
    outreachFormConfig,
    outreachFormErrors,
    setOutreachFormErrors,
    validateOutreachForm: validateAndSetErrorFields,
    hasOutreachAttempt,
    setHasOutreachAttempt,
  };
};

export default useOutreachAttempt;

// TODO: add tests, fix type inference, move to utils file
function withRetry<T, F extends (...params: any) => Promise<T>>(fn: F, retryTimes = 3) {
  const wrapped = async (...args: Parameters<F>) => {
    let result: T | undefined = undefined;
    let success: boolean = false;
    do {
      try {
        result = await fn(...args);
        success = true;
      } catch (e) {
        logError(e);
        retryTimes = retryTimes - 1;
      }
    } while (!success && retryTimes > 0);

    return result;
  };

  return wrapped;
}

export interface OutreachFormFields {
  contactType: boolean;
  outreachType: boolean;
  attemptNumber: boolean;
  recipient: boolean;
  channel: boolean;
  providerPhoneNumber: boolean;
  memberPhoneNumber: boolean;
  providerEmail: boolean;
  memberEmail: boolean;
  providerFaxNumber: boolean;
  memberFaxNumber: boolean;
  nudgeInfo: boolean;
  outreachNotes: boolean;
  outreachOutcome: boolean;
}

/**
  this function determines what fields should be visible based on userInput from outreachAttempt object, and if the 
  outreach is being added from a clinical review. It returns an object where each key maps to an outreach field and
  the boolean value determines if that field should be displayed.
*/
const getOutreachDisplayConfig = (outreachAttempt: OutreachAttempt, fromClinicalReview = false): OutreachFormFields => {
  const isMIClinical = outreachAttempt.outreachType === "MISSING_INFO_CLINICAL";
  const isNudge = outreachAttempt.outreachType === "NUDGES";
  const isP2PScheduling = outreachAttempt.outreachType === "SCHEDULING_OUTREACH";
  const isInBoundContact = outreachAttempt.contactType === "INBOUND";
  const phoneOutreachChannel = outreachAttempt.channel === "PHONE";
  const emailOutreachChannel = outreachAttempt.channel === "EMAIL";
  const faxOutreachChannel = outreachAttempt.channel === "FAX";
  const providerAttempt = outreachAttempt.recipient === "ORDERING_PROVIDER";
  const memberAttempt = ["MEMBER", "MEMBER_REPRESENTATIVE"].includes(outreachAttempt.recipient ?? "");

  const outreachFormConfig: OutreachFormFields = {
    contactType: !fromClinicalReview,
    outreachType: true,
    attemptNumber: (isMIClinical || isP2PScheduling) && !isInBoundContact,
    recipient: isInBoundContact || (!isP2PScheduling && !isNudge),
    channel: true,
    providerPhoneNumber: phoneOutreachChannel && (providerAttempt || isNudge || isP2PScheduling),
    memberPhoneNumber: phoneOutreachChannel && memberAttempt && !isNudge && !isP2PScheduling,
    providerEmail: emailOutreachChannel && (providerAttempt || isNudge || isP2PScheduling),
    memberEmail: emailOutreachChannel && memberAttempt && !isNudge && !isP2PScheduling,
    providerFaxNumber: faxOutreachChannel && (providerAttempt || isNudge || isP2PScheduling),
    memberFaxNumber: faxOutreachChannel && memberAttempt && !isNudge && !isP2PScheduling,
    nudgeInfo: isNudge,
    outreachNotes: true,
    outreachOutcome: !(isNudge || isInBoundContact || isP2PScheduling),
  };
  return outreachFormConfig;
};

/**
  this function confirms that all required fields in the outreachAttempt are present and valid.
  Required fields are those which are visible to the user according to the displayFieldConfig.
  It returns an object containing a formIsValid boolean which indicates whether the entire outreach form is valid,
  and an errorStates object which denotes whether each field is valid or not.
*/
const validateOutreachForm = (
  displayFieldConfig: OutreachFormFields,
  outreachAttempt: OutreachAttempt
): { formIsValid: boolean; errorStates: OutreachFormFields } => {
  const updatedErrorStates: OutreachFormFields = { ...defaultOutreachErrors };
  if (displayFieldConfig.contactType) {
    if (!outreachAttempt.contactType) {
      updatedErrorStates.contactType = true;
    }
  }
  if (displayFieldConfig.outreachType) {
    if (!outreachAttempt.outreachType) {
      updatedErrorStates.outreachType = true;
    }
  }
  if (displayFieldConfig.attemptNumber) {
    if (!outreachAttempt.attemptNumber) {
      updatedErrorStates.attemptNumber = true;
    }
  }
  if (displayFieldConfig.recipient) {
    if (!outreachAttempt.recipient) {
      updatedErrorStates.recipient = true;
    }
  }
  if (displayFieldConfig.channel) {
    if (!outreachAttempt.channel) {
      updatedErrorStates.channel = true;
    }
  }
  if (displayFieldConfig.providerPhoneNumber) {
    if (!outreachAttempt.providerPhoneNumber || !isPhoneNumberValid(outreachAttempt.providerPhoneNumber?.number)) {
      updatedErrorStates.providerPhoneNumber = true;
    }
  }
  if (displayFieldConfig.memberPhoneNumber) {
    if (!outreachAttempt.memberPhoneNumber || !isPhoneNumberValid(outreachAttempt.memberPhoneNumber?.number)) {
      updatedErrorStates.memberPhoneNumber = true;
    }
  }
  if (displayFieldConfig.providerEmail) {
    if (!outreachAttempt.providerEmail) {
      updatedErrorStates.providerEmail = true;
    }
  }
  if (displayFieldConfig.memberEmail) {
    if (!outreachAttempt.memberEmail) {
      updatedErrorStates.memberEmail = true;
    }
  }
  if (displayFieldConfig.providerFaxNumber) {
    if (!outreachAttempt.providerFaxNumber || !isPhoneNumberValid(outreachAttempt.providerFaxNumber?.number)) {
      updatedErrorStates.providerFaxNumber = true;
    }
  }
  if (displayFieldConfig.memberFaxNumber) {
    if (!outreachAttempt.memberFaxNumber || !isPhoneNumberValid(outreachAttempt.memberFaxNumber?.number)) {
      updatedErrorStates.memberFaxNumber = true;
    }
  }
  if (displayFieldConfig.nudgeInfo) {
    if (!outreachAttempt.nudgeInfo) {
      updatedErrorStates.nudgeInfo = true;
    }
  }
  if (displayFieldConfig.outreachNotes) {
    if (!stripHTMl(outreachAttempt.outreachNotes || "")) {
      updatedErrorStates.outreachNotes = true;
    }
  }
  if (displayFieldConfig.outreachOutcome) {
    if (!outreachAttempt.outreachOutcome) {
      updatedErrorStates.outreachOutcome = true;
    }
  }
  const formIsInvalid = Object.values(updatedErrorStates).some((hasError) => hasError);

  return { formIsValid: !formIsInvalid, errorStates: updatedErrorStates };
};

const defaultOutreachErrors: OutreachFormFields = {
  contactType: false,
  outreachType: false,
  attemptNumber: false,
  recipient: false,
  channel: false,
  providerPhoneNumber: false,
  memberPhoneNumber: false,
  providerEmail: false,
  memberEmail: false,
  providerFaxNumber: false,
  memberFaxNumber: false,
  nudgeInfo: false,
  outreachNotes: false,
  outreachOutcome: false,
};
