import { Coverage, Patient } from "@coherehealth/core-platform-api";

interface GetPatientHealthDisplayNameProps {
  latestCoverage?: Coverage;
  patient: Patient;
  showCoverageEndDate?: boolean;
}

export function getPatientHealthDisplayName({
  patient,
  latestCoverage,
  showCoverageEndDate,
}: GetPatientHealthDisplayNameProps) {
  let healthPlanDisplayName = patient.coverage?.healthPlanDisplayName || patient.coverage?.healthPlanName;
  let rbe = patient.coverage?.riskBearingEntity;

  if (showCoverageEndDate && latestCoverage) {
    healthPlanDisplayName = latestCoverage.healthPlanDisplayName
      ? latestCoverage.healthPlanDisplayName
      : latestCoverage.healthPlanName;
    rbe = latestCoverage.riskBearingEntity;
  }

  healthPlanDisplayName =
    rbe && healthPlanDisplayName && rbe !== healthPlanDisplayName
      ? `${healthPlanDisplayName} - ${rbe}`
      : healthPlanDisplayName;

  return healthPlanDisplayName;
}
