import { OutreachAttempt, ReviewType } from "@coherehealth/core-platform-api";
import { useFeature } from "@coherehealth/common";
import { useCallback } from "react";

interface UseDraftOutreachAttempt {
  outreachAttempt: OutreachAttempt | undefined | null;
  hasOutreachAttempt: boolean | undefined;
}

export interface IDraftOutreachAttemptProps {
  appendDraftOutreachAttemptToReview: <T extends ReviewType>(initialReview: T) => T;
}

const useDraftOutreachAttempt = ({
  hasOutreachAttempt,
  outreachAttempt,
}: UseDraftOutreachAttempt): IDraftOutreachAttemptProps => {
  const draftOutreachAttemptEnabled = useFeature("draftOutreachAttempt");

  const appendDraftOutreachAttemptToReview = useCallback(
    <T extends ReviewType>(initialReview: T): T => {
      let review = { ...initialReview };

      if (outreachAttempt && hasOutreachAttempt && draftOutreachAttemptEnabled) {
        review = { ...review, draftOutreachAttempt: { ...outreachAttempt, outreachOpportunity: undefined } };
      } else {
        review = { ...review, draftOutreachAttempt: null };
      }

      return review;
    },
    [hasOutreachAttempt, outreachAttempt, draftOutreachAttemptEnabled]
  );

  return { appendDraftOutreachAttemptToReview };
};

export default useDraftOutreachAttempt;
