import { PropsWithChildren, ReactNode } from "react";
import { H1, H3 } from "@coherehealth/common";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface Props {
  heading?: string;
  headingBold?: string;
  message?: string;
  error?: ReactNode;
  icon?: ReactNode;
  form?: ReactNode;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconDiv: {
      display: "flex",
      alignItems: "center",
      "@media (max-width: 1024px)": {
        width: 100,
        height: 100,
        marginRight: theme.spacing(2),
      },
    },
    heroHeading: {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(2),
      "@media (max-width: 1024px)": {
        display: "none",
      },
    },
    heroHeadingResponsive: {
      color: theme.palette.text.secondary,
      display: "none",
      marginBottom: theme.spacing(2),
      "@media (max-width: 1024px)": {
        display: "block",
      },
    },
    heroText: {
      color: theme.palette.text.secondary,
    },
    dashboardResultsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "@media (max-width: 1024px)": {
        flexDirection: "column",
        alignItems: "start",
      },
      padding: theme.spacing(5),
    },
    dashboardResultsBorder: {
      border: "1px solid #E5E5E5",
      boxSizing: "border-box",
      borderRadius: 5,
    },
    dashboardResultsResponsiveHeader: {
      "@media (max-width: 1024px)": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        overflowWrap: "break-word",
        maxWidth: "calc(100% - 100px)",
      },
    },
    verticalSpacer: {
      marginLeft: theme.spacing(5),
      "@media (max-width: 1024px)": {
        marginLeft: 0,
      },
    },
    wrapContent: {
      maxWidth: "calc(100% - 250px)",
      overflowWrap: "break-word",
      "@media (max-width: 1024px)": {
        maxWidth: "100%",
      },
    },
    wrapContent2: {
      maxWidth: "100%",
      overflowWrap: "break-word",
      "@media (max-width: 1024px)": {
        maxWidth: "100%",
      },
    },
  })
);

export default function DashboardResults({
  heading,
  message,
  headingBold,
  icon,
  form,
  children,
  error,
  style,
}: PropsWithChildren<Props>) {
  const classes = useStyles();
  return (
    <div className={classes.dashboardResultsBorder} style={style}>
      <div className={classes.dashboardResultsContainer}>
        <div className={classes.dashboardResultsResponsiveHeader}>
          {icon && <div className={classes.iconDiv}>{icon}</div>}
          <div className={icon ? classes.wrapContent : classes.wrapContent2}>
            <H1 className={classes.heroHeadingResponsive}>
              {heading}
              <span style={{ color: "#212936" }}>{headingBold ? `${headingBold}` : ""}</span>
            </H1>
          </div>
        </div>
        {icon && <div className={classes.verticalSpacer} />}
        <div className={icon ? classes.wrapContent : classes.wrapContent2}>
          <H1 className={classes.heroHeading}>
            {heading}
            <span style={{ color: "#212936" }}>{headingBold ? `${headingBold}` : ""}</span>
          </H1>
          <H3 className={classes.heroText}>{message}</H3>
          {form}
          {children}
        </div>
      </div>
      {error}
    </div>
  );
}
