import { ReviewChatbotFinding, useGetServiceRequestReviewChatbotFindings } from "@coherehealth/core-platform-api";
import { useState, useEffect, useCallback } from "react";
import { isoDateTimeComparator } from "util/dateUtils";
import { v4 as uuidv4 } from "uuid";

export interface IReviewChatbotFindingHolder {
  id: string;
  chatbotfinding: ReviewChatbotFinding;
  isNewSynchronousFinding: boolean;
}

const useAutomatedReviewChatbotFindings = (
  serviceRequestId: string,
  reviewId: string,
  isReviewChatbotEnabled: boolean
) => {
  const [chatbotFindings, setChatbotFindings] = useState<IReviewChatbotFindingHolder[]>([]);

  const { data, error, refetch } = useGetServiceRequestReviewChatbotFindings({
    serviceRequestId: serviceRequestId,
    queryParams: {
      onlyLatestNonManualWithAllManual: true,
    },
    lazy: true,
  });

  useEffect(() => {
    if (isReviewChatbotEnabled) {
      refetch();
    }
  }, [isReviewChatbotEnabled, refetch]);

  useEffect(() => {
    if (data) {
      // Keep SR async findings AND manually inputted for the review
      const findings = data
        .filter(
          (finding) => !finding.manuallyInputPrompt || (finding.manuallyInputPrompt && finding.reviewId === reviewId)
        )
        .sort((a, b) => isoDateTimeComparator(a.findingInstant, b.findingInstant));

      const findingHolderList = findings.map((finding) => ({
        id: finding.findingRecordId!,
        chatbotfinding: finding,
        isNewSynchronousFinding: false,
      }));

      setChatbotFindings(findingHolderList);
    }
  }, [data, reviewId]);

  const appendSynchronousChatbotFinding = useCallback(
    (finding: ReviewChatbotFinding) => {
      const findingHolder: IReviewChatbotFindingHolder = {
        id: uuidv4(),
        chatbotfinding: finding,
        isNewSynchronousFinding: true,
      };
      setChatbotFindings((previous) => [...previous, findingHolder]);
    },
    [setChatbotFindings]
  );

  return { chatbotFindings, setChatbotFindings, appendSynchronousChatbotFinding, error };
};

export { useAutomatedReviewChatbotFindings };
