import { Alert, Body1, Caption, DateTextField, H1, H6, TextField } from "@coherehealth/common";
import {
  Address,
  OnboardingPatientInfo,
  PhoneNumber,
  useManuallyCreateOrganization,
  VerifyPatientInfoAndCreateOrgResponse,
} from "@coherehealth/core-platform-api";
import { Box, Divider, Grid, makeStyles } from "@material-ui/core";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import listReplace from "util/listReplace";
import { useDebouncedValidation } from "../../hooks/useDebouncedValidation";
import PersonIcon from "../images/PersonIcon";
import ConfirmationModal from "./ConfirmationModal";
import HeaderAndProgressBar from "./HeaderAndProgressBar";
import ManuallyVerifyButton from "./ManuallyVerifyButton";
import {
  AvailityInfo,
  FormCard,
  isMemberDobValid,
  isMemberIdLengthValid,
  OnboardingSteps,
  ProviderOrgInfo,
  useJoinExistingOrganizationApi,
  useRenewAccessToken,
  UserInfoState,
} from "./shared";
interface Props {
  isUniqueMemberId: boolean;
  patientInfo?: OnboardingPatientInfo[];
  setPatientInfo: Dispatch<OnboardingPatientInfo[]>;
  setCurrentStep: Dispatch<SetStateAction<OnboardingSteps>>;
  onContinueFrom: (step: OnboardingSteps) => void;
  providerOrgInfo: ProviderOrgInfo;
  userInfo: UserInfoState;
  patientInfoEntryValidation: VerifyPatientInfoAndCreateOrgResponse;
  currentStep: OnboardingSteps;
  selectedProviderOrgId: string;
  selectedProviderOrgName: string;
  autoVerificationEnabled: boolean;
  availityInfo: AvailityInfo;
  attemptedSubmitOnClick?: boolean;
}

type ManualVerficationOrgData = {
  npi: string;
  orgName: string;
  userName: string;
  userPhone: PhoneNumber;
  userEmail: string;
  title: string;
  userSpecialties: string[];
  otherTins: { name: string; tin: string }[];
  organizationAddress: Address;
  specialty: string;
  organizationSpecialties: string[];
  structure: string;
  healthPlans?: string[];
};
export default function OnboardingVerificationPatient({
  isUniqueMemberId,
  patientInfo,
  setPatientInfo,
  setCurrentStep,
  onContinueFrom,
  providerOrgInfo,
  userInfo,
  patientInfoEntryValidation,
  currentStep,
  selectedProviderOrgId,
  selectedProviderOrgName,
  autoVerificationEnabled,
  availityInfo,
  attemptedSubmitOnClick,
}: Props) {
  const {
    mutate: manuallyCreateOrganization,
    loading: manuallyCreateOrganizationLoading,
    error: manuallyCreateOrganizationError,
  } = useManuallyCreateOrganization({});
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const { renewAccessToken } = useRenewAccessToken();
  const { joinExistingOrganization } = useJoinExistingOrganizationApi({
    userInfo,
    isAvailityUser: availityInfo.isAvailityUser,
    autoVerificationEnabled,
    organizationId: selectedProviderOrgId,
    organizationTin: providerOrgInfo.tin,
    organizationName: selectedProviderOrgName,
    renewAccessToken,
    setCurrentStep,
  });
  const continueToManualReview = async () => {
    if (currentStep === "VERIFICATION_BY_PATIENT_INFO_JOIN_ORG") {
      try {
        await joinExistingOrganization();
      } catch (error: any) {
        if (error.status === 409) {
          const errorMessage = "Account already exists";
          enqueueSnackbar(errorMessage, { variant: "error" });
        }
        throw error; //throwing error since it blocks the UI from continuing
      }
    } else if (currentStep === "VERIFICATION_BY_PATIENT_INFO_CREATE_ORG") {
      let manualOrgData: ManualVerficationOrgData = {
        npi: providerOrgInfo.npi,
        orgName: providerOrgInfo.name,
        userName: `${userInfo.firstName} ${userInfo.lastName}`,
        userPhone: userInfo.phoneNumber,
        userEmail: userInfo.email,
        title: userInfo.title,
        userSpecialties: userInfo.userSpecialties,
        healthPlans: providerOrgInfo.healthPlans,
        otherTins: [{ name: providerOrgInfo.name, tin: providerOrgInfo.tin }, ...providerOrgInfo.otherTins],
        organizationAddress: providerOrgInfo.primaryAddress,
        specialty: providerOrgInfo.organizationSpecialty,
        organizationSpecialties: providerOrgInfo.organizationSpecialties,
        structure: providerOrgInfo.organizationStructure,
      };
      await manuallyCreateOrganization(manualOrgData);
    }
    setCurrentStep("VERIFICATION_UNDER_REVIEW");
  };
  const backToVerificationHome = () => {
    setCurrentStep("VERIFICATION_START");
  };
  useEffect(() => {
    if (manuallyCreateOrganizationError) {
      let errorMessage = "Error creating organization";
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, manuallyCreateOrganizationError]);
  const { validated: patientInfoValidated, validationResult: patientInfoValidationResults } =
    patientInfoEntryValidation;
  const noValidationResults = !patientInfoValidationResults || patientInfoValidationResults?.length === 0;
  return (
    <Container className={classes.verifyAccountcontainer}>
      <HeaderAndProgressBar stepNumber={3} />
      <Title>Verify your account</Title>
      <H6 className={classes.subHeader}>
        Please enter information for 5 members from any of the in scope health plans to verify that you have access to
        your organization’s protected health information. Cohere already has access to this information, and entering it
        here does not violate HIPAA.
      </H6>
      <FormCard className={classes.formCard}>
        <Body1 className={classes.cardMessage}>
          Member IDs should use letters and numbers only. Do not include a dependent code, dashes, or special
          characters.
        </Body1>
        {!isUniqueMemberId && (
          <Alert
            severity="error"
            message="Information doesn't match our records for one or more members"
            className={classes.alertBanner}
          >
            Please ensure that member information is correct or use manual verification instead.
          </Alert>
        )}
        {isUniqueMemberId && !patientInfoValidated && noValidationResults && (
          <Alert
            severity="error"
            message="One or more of the members that you entered is not valid for verification"
            className={classes.alertBanner}
          >
            Please update your member information, or use manual verification instead.
          </Alert>
        )}
        <Grid container spacing={3}>
          {patientInfo?.map(({ memberId, memberDateOfBirth }, idx) => {
            const setMemberId = (newMemberId: string) => {
              setPatientInfo(listReplace(patientInfo, idx, { memberId: newMemberId, memberDateOfBirth }));
            };
            const setDateOfBirth = (newDOB: string) => {
              setPatientInfo(listReplace(patientInfo, idx, { memberId, memberDateOfBirth: newDOB }));
            };
            const validationError = patientInfoValidationResults?.[idx] === false;
            return (
              <PatientInfoRow
                memberId={memberId}
                memberDateOfBirth={memberDateOfBirth}
                setMemberId={setMemberId}
                setDateOfBirth={setDateOfBirth}
                idx={idx}
                key={`patient-info-row-${idx}`}
                validationError={validationError}
                attemptedSubmitOnClick={attemptedSubmitOnClick}
              />
            );
          })}
        </Grid>
      </FormCard>
      <ManuallyVerifyButton onClick={() => setConfirmationModalOpen(true)} />
      <ConfirmationModal
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        onClickPrimary={backToVerificationHome}
        onClickSecondary={continueToManualReview}
        onContinueFrom={onContinueFrom}
        tertiaryButtonLoading={manuallyCreateOrganizationLoading}
      />
      <Grid container>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Container>
  );
}
// eslint-disable-next-line cohere-react/no-mui-styled-import
const Title = styled(H1)(({ theme }) => ({
  margin: theme.spacing(5.5, 0, 2, 0),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const ErrorCaption = styled(Caption)(({ theme }) => ({
  color: theme.palette.error.dark,
  marginLeft: theme.spacing(3),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const Container = styled("div")(({ theme }) => ({ paddingBottom: theme.spacing(6) }));
const useStyles = makeStyles((theme) => ({
  container: {
    width: theme.spacing(102),
    marginLeft: "auto",
    marginRight: "auto",
    "& .MuiFormLabel-root": {
      width: "100%",
    },
  },
  verifyAccountcontainer: {
    width: theme.spacing(128),
    marginLeft: "auto",
    marginRight: "auto",
    "& .MuiFormLabel-root": {
      width: "100%",
    },
  },
  subHeader: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    fontFamily: "Gilroy-Semibold",
  },
  formCard: {
    marginBottom: theme.spacing(5),
    borderRadius: "8px",
    padding: theme.spacing(5),
  },
  cardMessage: {
    marginBottom: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  personRow: {
    display: "flex",
    alignItems: "center",
  },
  memberId: {
    flex: 1,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  dateOfBirth: {
    flex: 1,
  },
  alertBanner: {
    marginBottom: theme.spacing(2),
  },
}));
interface PatientInfoRowProps {
  memberId?: string;
  memberDateOfBirth?: string;
  setMemberId: Dispatch<string>;
  setDateOfBirth: Dispatch<string>;
  idx: number;
  validationError: boolean;
  attemptedSubmitOnClick?: boolean;
}

function PatientInfoRow({
  memberId,
  memberDateOfBirth,
  setMemberId,
  setDateOfBirth,
  idx,
  validationError,
  attemptedSubmitOnClick,
}: PatientInfoRowProps) {
  const isMemberIdValidDebounced = useDebouncedValidation({
    validation: () => isMemberIdLengthValid(memberId) || !memberId,
    deps: [memberId],
  });
  const isMemberDobValidDebounced = useDebouncedValidation({
    validation: () => isMemberDobValid(memberDateOfBirth) || !memberDateOfBirth,
    deps: [memberDateOfBirth],
  });
  const classes = useStyles();
  const [focused, setFocused] = useState(false);
  const [memNo, setMemNo] = useState<number>();
  return (
    <>
      <Grid item xs={12} className={classes.personRow}>
        <PersonIcon />
        <Grid item container xs={12}>
          <Grid item container xs={12}>
            <TextField
              onFocus={() => {
                setFocused(true);
                setMemNo(idx);
              }}
              onBlur={() => setFocused(false)}
              label={(focused && memNo === idx) || !!memberId ? "Member ID (Ex: 123456789 or H123456789)" : "Member ID"}
              value={memberId}
              onChangeValue={updateMemberIdFromDisplayValue(setMemberId)}
              className={classes.memberId}
              error={(attemptedSubmitOnClick && !memberId) || !isMemberIdValidDebounced || validationError}
              helperText={
                !isMemberIdValidDebounced
                  ? "Member ID must be less than 25 characters"
                  : attemptedSubmitOnClick && !memberId
                  ? "Required"
                  : ""
              }
            />
            <DateTextField
              label="Member date of birth"
              addDatePatternToLabel
              value={memberDateOfBirth}
              onChangeValue={setDateOfBirth}
              className={classes.dateOfBirth}
              error={(attemptedSubmitOnClick && !memberDateOfBirth) || !isMemberDobValidDebounced || validationError}
              helperText={
                !isMemberDobValidDebounced
                  ? "Member DOB must be in format MM/DD/YYYY"
                  : attemptedSubmitOnClick && !memberDateOfBirth
                  ? "Required"
                  : ""
              }
            />
          </Grid>
          {validationError && (
            <Box mt={1}>
              <ErrorCaption>This Member ID and date of birth you have entered does not match our records</ErrorCaption>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}
function updateMemberIdFromDisplayValue(setMemberId: Dispatch<string>) {
  return (memberId: string) => {
    // only allow up to 25 digit only characters and digits
    let formattedMemberId = memberId.replace(/[^A-Za-z0-9\d]/g, "");
    if (formattedMemberId.length > 25) {
      formattedMemberId = formattedMemberId.slice(0, 25);
    }
    setMemberId(formattedMemberId);
  };
}
