import { Body2, useFeature, useNetworkCheckStatusStyles } from "@coherehealth/common";
import { GetOutofNetworkCheckResponse, NetworkMessage, NetworkType } from "@coherehealth/core-platform-api";
import { Grid, makeStyles } from "@material-ui/core";
import { FlexGridItem } from "common/SharedServiceRequestFormComponents";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  InNetworkDisplay,
  OonCheckLoadingDisplay,
  UnknownNetworkDisplay,
} from "components/ServiceRequest/ServiceRequestForm/components/NetworkCheckStatusDisplay";
import { ReferralPayorConfiguration } from "./referralPayorConfiguration";

interface ReferralNetworkCheckProps {
  oonLoading?: boolean;
  networkType?: NetworkType;
  isSelectedAddressOON?: boolean;
  oonCheckPerformed: boolean;
  oonCheckData?: GetOutofNetworkCheckResponse;
  referralPayorConfiguration: ReferralPayorConfiguration;
  messages?: NetworkMessage | undefined;
}

const useCustomStyles = makeStyles((theme) => ({
  warningIcon: {
    color: theme.palette.warning.dark,
    fontSize: "20px",
    marginRight: "8px",
  },
  outOfNetworkContainer: {
    display: "flex",
  },
  outOfNetworkText: {
    fontFamily: "Gilroy-SemiBold",
    color: theme.palette.warning.dark,
    fontSize: "15px",
  },
  unknownNetworkDiv: {
    paddingBottom: theme.spacing(1),
  },
}));

//Network check display for referral requests
const ReferralNetworkCheckStatusDisplay = ({
  oonLoading,
  networkType,
  isSelectedAddressOON,
  oonCheckPerformed,
  referralPayorConfiguration,
  messages,
}: ReferralNetworkCheckProps) => {
  const classes = useCustomStyles();
  const useRMConfig = useFeature("referralManagementConfigurator");
  if (!oonLoading) {
    if (Boolean(networkType)) {
      // with network type unknown can be determined
      if (networkType === "OON") {
        return (
          <ReferralOutOfNetworkDisplay
            oonDisplayText={
              useRMConfig ? messages?.outOfNetworkMessage : referralPayorConfiguration.outOfNewtorkMessage
            }
          />
        );
      } else if (networkType === "INN") {
        return useRMConfig ? <InNetworkDisplayConfig messages={messages} /> : <InNetworkDisplay />;
      } else if (networkType === "Unknown") {
        return (
          <div className={classes.unknownNetworkDiv}>
            <UnknownNetworkDisplay
              unknownDisplayText={useRMConfig ? messages?.unknownMessage : referralPayorConfiguration.unknownMessage}
            />
          </div>
        );
      }
    } else if (isSelectedAddressOON !== undefined) {
      //if the isSelectedAddressOON value was filled in after the check
      if (isSelectedAddressOON) {
        // no network type but the address was marked as OON
        return <ReferralOutOfNetworkDisplay oonDisplayText={referralPayorConfiguration.outOfNewtorkMessage} />;
      } else if (oonCheckPerformed && !isSelectedAddressOON) {
        // no network type, address is NOT OON and the network check is done
        return <InNetworkDisplay />;
      }
    }

    return <></>;
  }
  return <OonCheckLoadingDisplay />;
};

interface ReferralOONDisplayProps {
  oonDisplayText: string | undefined;
}
interface ReferralINDisplayProps {
  messages: NetworkMessage | undefined;
}
const ReferralOutOfNetworkDisplay = ({ oonDisplayText }: ReferralOONDisplayProps) => {
  const classes = useCustomStyles();
  const networkCheckClasses = useNetworkCheckStatusStyles();
  return (
    <FlexGridItem container>
      <Grid item xs={12}>
        <div className={classes.outOfNetworkContainer}>
          <ErrorIcon className={classes.warningIcon} />
          <div className={networkCheckClasses.verticallyCenteredContainer}>
            <div className={networkCheckClasses.verticallyCenteredItem}>
              <Body2 className={classes.outOfNetworkText}>{oonDisplayText}</Body2>
            </div>
          </div>
        </div>
      </Grid>
    </FlexGridItem>
  );
};
const InNetworkDisplayConfig = ({ messages }: ReferralINDisplayProps) => {
  const networkCheckClasses = useNetworkCheckStatusStyles();
  return (
    <div>
      <div className={networkCheckClasses.oonStatusContainerRoot}>
        <div className={networkCheckClasses.oonStatusContainer}>
          <div className={networkCheckClasses.iconContainer}>
            <CheckCircleIcon className={networkCheckClasses.successIcon} />
          </div>
          <div className={networkCheckClasses.verticallyCenteredContainer}>
            <div className={networkCheckClasses.verticallyCenteredItem}>
              <Body2 className={networkCheckClasses.oonStatusText}>{messages?.inNetworkMessage}</Body2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralNetworkCheckStatusDisplay;
