import {
  Coverage,
  DiagnosisCode,
  Facility,
  Location,
  Patient,
  Provider,
  ReferralStatus,
  ReferraRequestSpecialty,
} from "@coherehealth/core-platform-api";
import { ComponentProps, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  ReferralFormConfiguration,
  ReferralRequestFieldNames,
  useGetReferralRequestFormConfigurationWithSpecialtyType,
} from "../FormContentSpecification/ReferralRequestFormContentSpecifications";
import { checkOnetimeRequestCoverage } from "util/patientUtils";
import { Grid, makeStyles, useTheme } from "@material-ui/core";
import { fieldIsValid, referralFieldIsValid, SRFormConfigFieldWrapper } from "common/FormConfigUtils";
import { isBackOfficeUser } from "util/user";
import { User, UserContext } from "UserContext";
import {
  Alert,
  Checkbox,
  colorsLight,
  DateSelect,
  formatDateStr,
  H4,
  H6,
  parseDateFromISOString,
  removeTimeFromDate,
  SingleSelectDropdown,
  subtractDays,
  useFeature,
} from "@coherehealth/common";
import {
  PrimaryDiagnosisCodeSelect,
  SecondaryDiagnosisCodeSelect,
} from "components/ServiceRequest/ServiceRequestForm/components/DiagnosisCodeSelect";
import { cohereBaseTheme } from "@coherehealth/design-system";
import { PerformingSpecialistSelect, ReferringProviderSelect } from "./ProviderFacilityUtils/ProviderSelect";
import { FacilitySelect } from "./ProviderFacilityUtils/FacilitySelect";

import ReferralNetworkCheckStatusDisplay from "components/ReferralManagement/common/ReferralNetworkCheckStatusDisplay";
import {
  useReferralFacilityOutOfNetworkCheck,
  useReferralPerformingSpecialistOutOfNetworkCheck,
} from "components/ReferralManagement/common/referralNetworkChecksUtils";
import { isOonProviderOrFacility, isUnknownNetworkType, OonExceptionReasonOptions } from "util/serviceRequest";
import {
  validatePerformingSpecialistAddress,
  validatePerformingSpecialistNPI,
  validatePerformingSpecialistTin,
} from "util/referralRequest";
import { getReferralPayorConfiguration } from "../common/referralPayorConfiguration";
import SpecialtySelect from "util/SpecialtySelect";
import { useLocation } from "react-router";
import { useIsFaxReferralsWorkflow } from "util/attachmentUtil";

const MAX_SECONDARY_DIAGNOSES = 9;

export type ReferralRequestFormContent = {
  id?: string;
  startDate?: Date;
  cohereId?: string;
  primarySemanticDiagnosisCode: DiagnosisCode | null;
  secondarySemanticDiagnosisCodes?: DiagnosisCode[];
  selectedReferringProvider: Provider | null;
  referringProviderSelectedAddress?: Location | null;
  referringProviderSelectedTin?: string | null;
  selectedFacility: Facility | null;
  facilitySelectedAddress?: Location | null;
  facilitySelectedTin?: string | null;
  facilityOONExceptionRequired?: boolean;
  selectedPerformingSpecialist?: Provider | null;
  performingSpecialistSelectedAddress?: Location | null;
  performingSpecialistSelectedTin?: string | null;
  specialty?: string | undefined;
  referralStatus?: ReferralStatus;
  healthPlanName?: string;
  medicalOrBehavioral?: string | undefined;
};

interface IProps {
  rrFormContent: ReferralRequestFormContent;
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>;
  rrFormConfiguration: ReferralFormConfiguration;
  patient?: Patient;
  attemptSubmit: boolean;
  setReferralRequestCanBeSubmitted: (b: boolean) => void;
  onUserEdit?: Dispatch<ReferralRequestFormContent>;
  hasPerformingSpecialistAttestation: boolean;
  setHasPerformingSpecialistAttestation?: Dispatch<SetStateAction<boolean>>;
  hasFacilityAttestation: boolean;
  setHasFacilityAttestation: Dispatch<SetStateAction<boolean>>;
  isAdministrative: boolean;
  coverage: Coverage | null;
}

interface RRFormStylesProps {
  isFaxReferralsFlow?: boolean;
}

export const useRRFormStyles = makeStyles((theme) => ({
  card: ({ isFaxReferralsFlow }: RRFormStylesProps) => ({
    marginTop: isFaxReferralsFlow ? theme.spacing(9.25) : theme.spacing(13),
    padding: isFaxReferralsFlow ? theme.spacing(3, 0, 0, 0) : theme.spacing(3),
    display: "flex",
  }),
  specialtyContainer: { maxWidth: "50%", paddingBottom: theme.spacing(3), paddingRight: theme.spacing(1) },
  h4: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    color: colorsLight.font.main,
  },
  h4Diagnosis: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    color: colorsLight.font.main,
  },
  h6: {
    paddingBottom: theme.spacing(2),
  },
  successAlert: {
    "& .MuiSvgIcon-root": {
      height: theme.spacing(2),
      width: theme.spacing(2),
      color: cohereBaseTheme.palette.success.main,
      marginRight: -theme.spacing(1),
    },
    "&.MuiAlert-root": {
      padding: theme.spacing(0),
      minHeight: theme.spacing(2),
    },
    "& .MuiAlertTitle-root": {
      color: cohereBaseTheme.palette.success.dark,
    },
    alignItems: "center",
    marginBottom: theme.spacing(2),
    height: theme.spacing(2.25),
    background: "transparent",
  },
  warningAlert: {
    "& .MuiSvgIcon-root": {
      height: theme.spacing(2),
      width: theme.spacing(2),
      color: cohereBaseTheme.palette.font.secondary,
      marginRight: -theme.spacing(1),
    },
    "&.MuiAlert-root": {
      padding: theme.spacing(0),
      minHeight: theme.spacing(2),
    },
    "& .MuiAlertTitle-root": {
      color: cohereBaseTheme.palette.font.secondary,
    },
    alignItems: "center",
    marginBottom: theme.spacing(2),
    height: theme.spacing(2.25),
    background: "transparent",
  },
  checkbox: {
    "& .MuiFormControlLabel-root": {
      height: theme.spacing(3),
    },
  },
  checkboxGrid: {
    padding: theme.spacing(2, 0, 2, 0),
  },
}));

export default function ReferralRequestForm({
  rrFormContent,
  setRRFormContent,
  rrFormConfiguration,
  patient,
  attemptSubmit = false,
  setReferralRequestCanBeSubmitted,
  onUserEdit,
  hasPerformingSpecialistAttestation,
  setHasPerformingSpecialistAttestation,
  hasFacilityAttestation,
  setHasFacilityAttestation,
  isAdministrative,
  coverage,
}: IProps) {
  const MIN_START_DATE = parseDateFromISOString(coverage?.planStart);
  const MAX_START_DATE = parseDateFromISOString(coverage?.planEnd);
  const theme = useTheme();
  const location = useLocation();
  const isFaxReferralsFlow = useIsFaxReferralsWorkflow(location);
  const classes = useRRFormStyles({ isFaxReferralsFlow });
  const [isStartDateInRange, setIsStartDateInRange] = useState<boolean>(true);
  const [isSoloPractitionerChecked, setIsSoloPractitionerChecked] = useState<boolean>(false);
  const setCanBeSubmitted = useCallback(
    (canBeSubmitted: boolean) => setReferralRequestCanBeSubmitted(canBeSubmitted),
    [setReferralRequestCanBeSubmitted]
  );
  const { getUser } = useContext(UserContext);
  const [user, setUser] = useState<User>();
  getUser()?.then((currentUser) => {
    setUser(currentUser);
  });

  const referralPayorConfiguration = useMemo(() => {
    return getReferralPayorConfiguration({ isAdministrative, coverage });
  }, [isAdministrative, coverage]);
  /* 
    Validations for referral request fields
  */

  /* Start date validations */
  const startDateExceedsMinStartDate = rrFormContent?.startDate && rrFormContent.startDate >= MIN_START_DATE;
  const hasStartDate = Boolean(rrFormContent.startDate);
  const isUserBackOffice = isBackOfficeUser(user);
  const isBCBSevenDays =
    coverage?.healthPlanName === "BCBS South Carolina" && isUserBackOffice && hasStartDate
      ? removeTimeFromDate(subtractDays(7, removeTimeFromDate(new Date()))) >
        removeTimeFromDate(rrFormContent.startDate as Date)
      : false;
  const hasValidStartDate = (rrFormContent.startDate ?? MAX_START_DATE) <= MAX_START_DATE;
  /* DiagnosisCode validations */
  const hasPrimarySemanticDiagnosisCode = Boolean(rrFormContent.primarySemanticDiagnosisCode);
  const doesNotExceedMaxSecondarySemanticDiagnosisCode = !!rrFormContent.secondarySemanticDiagnosisCodes
    ? rrFormContent?.secondarySemanticDiagnosisCodes?.length <= MAX_SECONDARY_DIAGNOSES
    : true;
  const hasSecondarySemanticDiagnosisCodes = !!rrFormContent?.secondarySemanticDiagnosisCodes
    ? rrFormContent.secondarySemanticDiagnosisCodes.length > 0
    : true;

  /*ReferringProvider validations */
  const hasReferringProvider = Boolean(rrFormContent.selectedReferringProvider);
  const hasReferringProviderNpi = Boolean(rrFormContent.selectedReferringProvider?.npi);
  const hasReferringProviderTin =
    rrFormContent?.selectedReferringProvider?.tinList?.length === 0
      ? true
      : Boolean(rrFormContent?.referringProviderSelectedTin);
  const hasReferringProviderAddress = Boolean(
    rrFormContent.referringProviderSelectedAddress?.address ||
      rrFormContent.selectedReferringProvider?.selectedLocation?.address
  );

  /* Facility validations */
  const hasFacility = Boolean(rrFormContent.selectedFacility);
  const hasFacilityNpi = Boolean(rrFormContent.selectedFacility?.npi);
  const hasFacilityTin =
    rrFormContent?.selectedFacility?.tinList?.length === 0 ? true : Boolean(rrFormContent.facilitySelectedTin);
  const hasFacilityAddress = Boolean(
    rrFormContent.facilitySelectedAddress?.address || rrFormContent.selectedFacility?.selectedLocation?.address
  );

  /* Specialty validation */
  const hasSpecialty = Boolean(rrFormContent.specialty);

  /* Performing specialist validations */
  const hasPerformingSpecialist = Boolean(rrFormContent.selectedPerformingSpecialist);
  const hasPerformingSpecialistNpi = Boolean(rrFormContent.selectedPerformingSpecialist?.npi);
  const hasPerformingSpecialistTin =
    rrFormContent?.selectedPerformingSpecialist?.tinList?.length === 0
      ? true
      : Boolean(rrFormContent.performingSpecialistSelectedTin);
  const performingSpecialistTinLength = rrFormContent.selectedPerformingSpecialist?.tinList?.length;
  const hasPerformingSpecialistAddress = Boolean(
    rrFormContent.performingSpecialistSelectedAddress?.address ||
      rrFormContent.selectedPerformingSpecialist?.selectedLocation?.address
  );

  const updatedFormConfig = useGetReferralRequestFormConfigurationWithSpecialtyType(
    patient || null,
    isAdministrative,
    rrFormContent.medicalOrBehavioral
  );

  /* 
    Create a validation map which returns a boolean based on below conditions:
    1. None of the "REQUIRED" fields are empty
    2. Validations for each individual fields satisfy or not

    Returns a boolean for each field present in formContent
  */
  const VALIDATION_MAP: Record<ReferralRequestFieldNames, boolean> = {
    startDate: fieldIsValid(
      rrFormConfiguration.startDate,
      Boolean(hasValidStartDate && startDateExceedsMinStartDate && hasStartDate && !isBCBSevenDays)
    ),
    primarySemanticDiagnosisCode: fieldIsValid(
      rrFormConfiguration.primarySemanticDiagnosisCode,
      hasPrimarySemanticDiagnosisCode
    ),
    secondarySemanticDiagnosisCodes: fieldIsValid(
      rrFormConfiguration.secondarySemanticDiagnosisCodes,
      hasSecondarySemanticDiagnosisCodes && doesNotExceedMaxSecondarySemanticDiagnosisCode
    ),
    selectedReferringProvider: fieldIsValid(rrFormConfiguration.selectedReferringProvider, hasReferringProvider),
    referringProviderNPI: fieldIsValid(rrFormConfiguration.referringProviderNPI, hasReferringProviderNpi),
    referringProviderTIN: fieldIsValid(rrFormConfiguration.referringProviderTIN, hasReferringProviderTin),
    referringProviderAddress: fieldIsValid(rrFormConfiguration.referringProviderAddress, hasReferringProviderAddress),
    selectedFacility: fieldIsValid(rrFormConfiguration.selectedFacility, hasFacility),
    facilityAddress: fieldIsValid(rrFormConfiguration.facilityAddress, hasFacilityAddress),
    facilityTIN: fieldIsValid(rrFormConfiguration.facilityTIN, hasFacilityTin),
    facilityNPI: fieldIsValid(rrFormConfiguration.facilityNPI, hasFacilityNpi),
    specialty: fieldIsValid(rrFormConfiguration.specialty, hasSpecialty),
    selectedPerformingSpecialist: fieldIsValid(
      updatedFormConfig?.selectedPerformingSpecialist || rrFormConfiguration.selectedPerformingSpecialist,
      hasPerformingSpecialist
    ),
    performingSpecialistAddress: validatePerformingSpecialistAddress(
      updatedFormConfig || rrFormConfiguration,
      rrFormContent,
      hasPerformingSpecialistAddress,
      false
    ),
    performingSpecialistTIN: validatePerformingSpecialistTin(
      updatedFormConfig || rrFormConfiguration,
      rrFormContent,
      performingSpecialistTinLength,
      hasPerformingSpecialistTin
    ),
    performingSpecialistNPI: validatePerformingSpecialistNPI(
      updatedFormConfig || rrFormConfiguration,
      rrFormContent,
      hasPerformingSpecialistNpi,
      false
    ),
    outOfNetworkCheck: true,
    outOfNetworkFacility: true,
    outOfNetworkPerformingSpecialist: true,
    facilityOutOfNetworkStatusDisplay: true,
    performingSpecialistOutOfNetworkStatusDisplay: true,
    performingSpecialistAttestation: fieldIsValid(rrFormConfiguration.performingSpecialistAttestation, true),
    performingSpecialistAttestationReason: hasPerformingSpecialistAttestation
      ? fieldIsValid(
          { fieldSpec: "REQUIRED" },
          !!rrFormContent?.selectedPerformingSpecialist?.selectedLocation?.outOfNetworkExceptionReason
        )
      : fieldIsValid(rrFormConfiguration?.performingSpecialistAttestationReason),
    facilityAttestation: fieldIsValid(rrFormConfiguration.facilityAttestation, true),
    facilityAttestationReason: hasFacilityAttestation
      ? fieldIsValid(
          { fieldSpec: "REQUIRED" },
          !!rrFormContent?.selectedFacility?.selectedLocation?.outOfNetworkExceptionReason
        )
      : fieldIsValid(rrFormConfiguration?.facilityAttestationReason),
  };
  /* 
    Create a validation map which returns a boolean based on below conditions:
    1. None of the "REQUIRED" fields are empty
    2. Validations for each individual fields satisfy or not

    Returns a boolean for each field present in formContent
  */
  const RM_CONFIG_VALIDATION_MAP: Record<ReferralRequestFieldNames, boolean> = {
    startDate: referralFieldIsValid(
      rrFormConfiguration.startDate,
      rrFormContent,
      Boolean(hasValidStartDate && startDateExceedsMinStartDate && hasStartDate && !isBCBSevenDays)
    ),
    primarySemanticDiagnosisCode: referralFieldIsValid(
      rrFormConfiguration.primarySemanticDiagnosisCode,
      rrFormContent,
      hasPrimarySemanticDiagnosisCode
    ),
    secondarySemanticDiagnosisCodes: referralFieldIsValid(
      rrFormConfiguration.secondarySemanticDiagnosisCodes,
      rrFormContent,
      hasSecondarySemanticDiagnosisCodes && doesNotExceedMaxSecondarySemanticDiagnosisCode
    ),
    selectedReferringProvider: referralFieldIsValid(
      rrFormConfiguration.selectedReferringProvider,
      rrFormContent,
      hasReferringProvider
    ),
    referringProviderNPI: referralFieldIsValid(
      rrFormConfiguration.referringProviderNPI,
      rrFormContent,
      hasReferringProviderNpi
    ),
    referringProviderTIN: referralFieldIsValid(
      rrFormConfiguration.referringProviderTIN,
      rrFormContent,
      hasReferringProviderTin
    ),
    referringProviderAddress: referralFieldIsValid(
      rrFormConfiguration.referringProviderAddress,
      rrFormContent,
      hasReferringProviderAddress
    ),
    selectedFacility: referralFieldIsValid(rrFormConfiguration.selectedFacility, rrFormContent, hasFacility),
    facilityAddress: referralFieldIsValid(rrFormConfiguration.facilityAddress, rrFormContent, hasFacilityAddress),
    facilityTIN: referralFieldIsValid(rrFormConfiguration.facilityTIN, rrFormContent, hasFacilityTin),
    facilityNPI: referralFieldIsValid(rrFormConfiguration.facilityNPI, rrFormContent, hasFacilityNpi),
    specialty: referralFieldIsValid(rrFormConfiguration.specialty, rrFormContent, hasSpecialty),
    selectedPerformingSpecialist: referralFieldIsValid(
      rrFormConfiguration.selectedPerformingSpecialist,
      rrFormContent,
      hasPerformingSpecialist
    ),
    performingSpecialistAddress: validatePerformingSpecialistAddress(
      rrFormConfiguration,
      rrFormContent,
      hasPerformingSpecialistAddress,
      true
    ),
    performingSpecialistTIN: validatePerformingSpecialistTin(
      rrFormConfiguration,
      rrFormContent,
      performingSpecialistTinLength,
      hasPerformingSpecialistTin
    ),
    performingSpecialistNPI: validatePerformingSpecialistNPI(
      rrFormConfiguration,
      rrFormContent,
      hasPerformingSpecialistNpi,
      true
    ),
    outOfNetworkCheck: true,
    outOfNetworkFacility: true,
    outOfNetworkPerformingSpecialist: true,
    facilityOutOfNetworkStatusDisplay: true,
    performingSpecialistOutOfNetworkStatusDisplay: true,
    performingSpecialistAttestation: referralFieldIsValid(
      rrFormConfiguration.performingSpecialistAttestation,
      rrFormContent,
      true
    ),
    performingSpecialistAttestationReason: hasPerformingSpecialistAttestation
      ? referralFieldIsValid(
          { fieldSpec: "REQUIRED" },
          rrFormContent,
          !!rrFormContent?.selectedPerformingSpecialist?.selectedLocation?.outOfNetworkExceptionReason
        )
      : referralFieldIsValid(rrFormConfiguration?.performingSpecialistAttestationReason, rrFormContent),
    facilityAttestation: referralFieldIsValid(rrFormConfiguration.facilityAttestation, rrFormContent, true),
    facilityAttestationReason: hasFacilityAttestation
      ? referralFieldIsValid(
          { fieldSpec: "REQUIRED" },
          rrFormContent,
          !!rrFormContent?.selectedFacility?.selectedLocation?.outOfNetworkExceptionReason
        )
      : referralFieldIsValid(rrFormConfiguration?.facilityAttestationReason, rrFormContent),
  };
  const useRMConfig = useFeature("referralManagementConfigurator");
  const validateFields = () => {
    const rrFormConfig = useRMConfig ? rrFormConfiguration : updatedFormConfig || rrFormConfiguration;
    if (rrFormConfig) {
      const rrFormConfigKeys = Object.keys(rrFormConfig) as ReferralRequestFieldNames[];
      for (const key of rrFormConfigKeys) {
        if (useRMConfig) {
          if (!RM_CONFIG_VALIDATION_MAP[key]) {
            return false;
          }
        } else {
          if (!VALIDATION_MAP[key]) {
            return false;
          }
        }
      }
      return true;
    }
    //should never happen, but if it does we should block the user from trying to submit
    return false;
  };

  const isValid = validateFields();

  const handleFormValidation = useCallback(
    (isFormConfigValid: boolean) => {
      setCanBeSubmitted(isFormConfigValid);
    },
    [setCanBeSubmitted]
  );

  useEffect(() => {
    handleFormValidation(isValid);
  }, [handleFormValidation, isValid]);

  const startDateCoverage = useMemo(() => {
    if (!!coverage) {
      if (isBCBSevenDays) {
        setIsStartDateInRange(false);
        return {
          inRange: false,
          messageToDisplay: "Referral start date cannot be more than 7 days ago.",
        };
      }
      if (rrFormContent.startDate) {
        return checkOnetimeRequestCoverage([coverage], rrFormContent.startDate);
      }
    }
  }, [coverage, isBCBSevenDays, rrFormContent.startDate]);

  const dateChangeValidator = () => {
    if (!startDateCoverage?.inRange) {
      setIsStartDateInRange(false);
    }
  };

  useEffect(() => {
    if (
      rrFormConfiguration.primarySemanticDiagnosisCode.fieldSpec === "OMIT" &&
      rrFormConfiguration.secondarySemanticDiagnosisCodes.fieldSpec === "OMIT"
    ) {
      setRRFormContent((oldContents) => ({
        ...oldContents,
        primarySemanticDiagnosisCode: null,
        secondarySemanticDiagnosisCodes: [],
      }));
    }
  }, [
    rrFormConfiguration.primarySemanticDiagnosisCode.fieldSpec,
    rrFormConfiguration.secondarySemanticDiagnosisCodes.fieldSpec,
    setRRFormContent,
  ]);

  // Below useEffect clears our performingSpecialist and facility to reset the OON exception reason
  useEffect(() => {
    if (!rrFormConfiguration.selectedFacility) {
      setRRFormContent((prevContent: ReferralRequestFormContent) => ({
        ...prevContent,
        facilitySelectedAddress: null,
        facilitySelectedTin: null,
      }));
    }
    if (!rrFormConfiguration.selectedReferringProvider) {
      setRRFormContent((prevContent: ReferralRequestFormContent) => ({
        ...prevContent,
        referringProviderSelectedAddress: null,
        referringProviderSelectedTin: null,
      }));
    }
    if (!rrFormConfiguration.selectedPerformingSpecialist) {
      setRRFormContent((prevContent: ReferralRequestFormContent) => ({
        ...prevContent,
        performingSpecialistSelectedAddress: null,
        performingSpecialistSelectedTin: null,
      }));
    }
  }, [
    rrFormConfiguration.selectedFacility,
    rrFormConfiguration.selectedPerformingSpecialist,
    rrFormConfiguration.selectedReferringProvider,
    setRRFormContent,
  ]);

  /**
   * Below function sets the referralRequest form content, but it also runs the onUserEdit callback.
   * @param newRRFormContent
   */
  const setRRFormContentOnUserEdit: Dispatch<SetStateAction<ReferralRequestFormContent>> = useCallback(
    (setStateAction) => {
      setRRFormContent((prevRRFormContent) => {
        const newRRFormContent: ReferralRequestFormContent =
          typeof setStateAction === "function" ? setStateAction(prevRRFormContent) : setStateAction;
        onUserEdit?.(newRRFormContent);
        return newRRFormContent;
      });
    },
    [onUserEdit, setRRFormContent]
  );

  const {
    oonCheckData: facilityOonCheckData,
    oonCheckLoading: facilityOonCheckLoading,
    performedOONCheck: facilityOONCheckPerformed,
  } = useReferralFacilityOutOfNetworkCheck({
    rrFormContent,
    setRRFormContent,
    rrFormConfiguration,
    runOONCheckOnManuallyCreatedProviderFacility:
      referralPayorConfiguration.runOONCheckOnManuallyCreatedProviderFacility,
    patient: patient || undefined,
    hideTinField: useRMConfig
      ? rrFormConfiguration.facilityTIN.fieldSpec === "OMIT"
      : referralPayorConfiguration.hideTinField,
  });

  const {
    oonCheckData: specialistOonCheckData,
    oonCheckLoading: specialistOonCheckLoading,
    performedOONCheck: specialistOONCheckPerformed,
  } = useReferralPerformingSpecialistOutOfNetworkCheck({
    rrFormContent,
    setRRFormContent,
    rrFormConfiguration,
    runOONCheckOnManuallyCreatedProviderFacility:
      referralPayorConfiguration.runOONCheckOnManuallyCreatedProviderFacility,
    patient: patient || undefined,
    hideTinField: useRMConfig
      ? rrFormConfiguration.performingSpecialistTIN.fieldSpec === "OMIT"
      : referralPayorConfiguration.hideTinField,
  });

  return (
    <>
      <Grid container spacing={2} justifyContent="space-around" alignItems="center">
        <SRFormConfigFieldWrapper {...rrFormConfiguration.startDate}>
          <Row>
            {isAdministrative ? (
              <Alert
                className={classes.warningAlert}
                message={"Doesn't require submission in most cases"}
                severity="warning"
              />
            ) : (
              <Alert
                className={classes.successAlert}
                message={"Requires submission through Cohere"}
                severity="success"
              />
            )}
            <DateSelect
              label={"Start date"}
              value={rrFormContent?.startDate ?? null}
              minDate={MIN_START_DATE}
              maxDate={MAX_START_DATE}
              onDateChange={(date: Date) => {
                dateChangeValidator();
                setRRFormContentOnUserEdit({ ...rrFormContent, startDate: date });
              }}
              minDateMessage={
                !startDateCoverage?.inRange
                  ? `Coverage doesn't start until ${formatDateStr(
                      MIN_START_DATE instanceof Date ? MIN_START_DATE : ""
                    )}`
                  : ""
              }
              maxDateMessage={!startDateCoverage?.inRange ? "Date too far in the future" : ""}
              helperText={
                (attemptSubmit && !rrFormContent.startDate && "Required") ||
                ((!rrFormContent.startDate || startDateCoverage?.inRange) &&
                  !attemptSubmit &&
                  "Referrals are valid for 6 months") ||
                isBCBSevenDays
                  ? startDateCoverage?.messageToDisplay
                  : "Referrals are valid for 6 months"
              }
              error={
                startDateCoverage
                  ? !startDateCoverage.inRange
                  : attemptSubmit &&
                    (useRMConfig ? !RM_CONFIG_VALIDATION_MAP["startDate"] : !VALIDATION_MAP["startDate"])
              }
              attemptedSubmit={attemptSubmit || !isStartDateInRange}
              inputProps={{ width: theme.spacing(35.5) }}
            />
          </Row>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...rrFormConfiguration.primarySemanticDiagnosisCode}>
          <Row>
            <H4 className={classes.h4Diagnosis}>Diagnosis codes</H4>
            <PrimaryDiagnosisCodeSelect
              label="Primary diagnosis code"
              emptyLabel="Primary diagnosis code"
              error={
                attemptSubmit &&
                (useRMConfig
                  ? !RM_CONFIG_VALIDATION_MAP["primarySemanticDiagnosisCode"]
                  : !VALIDATION_MAP["primarySemanticDiagnosisCode"])
              }
              primaryDiagnosisCode={rrFormContent?.primarySemanticDiagnosisCode || null}
              setPrimaryDiagnosisCode={(code: DiagnosisCode | null) =>
                setRRFormContentOnUserEdit({ ...rrFormContent, primarySemanticDiagnosisCode: code })
              }
            />
          </Row>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...rrFormConfiguration.secondarySemanticDiagnosisCodes}>
          <Row>
            <SecondaryDiagnosisCodeSelect
              label={"Secondary diagnosis codes"}
              emptyLabel="Secondary diagnosis codes"
              error={
                (attemptSubmit &&
                  (useRMConfig
                    ? !RM_CONFIG_VALIDATION_MAP["secondarySemanticDiagnosisCodes"]
                    : !VALIDATION_MAP["secondarySemanticDiagnosisCodes"])) ||
                !doesNotExceedMaxSecondarySemanticDiagnosisCode
              }
              secondaryDiagnosisCodes={rrFormContent?.secondarySemanticDiagnosisCodes || []}
              setSecondaryDiagnosisCodes={(codes: DiagnosisCode[]) =>
                setRRFormContentOnUserEdit({ ...rrFormContent, secondarySemanticDiagnosisCodes: codes })
              }
              isOptional={rrFormConfiguration.secondarySemanticDiagnosisCodes.fieldSpec === "OPTIONAL"}
              disablePlaceholder={true}
            />
          </Row>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...rrFormConfiguration.selectedReferringProvider}>
          <Row style={{ paddingBottom: theme.spacing(4) }}>
            <H4 className={classes.h4}>Providers</H4>
            <H6 className={classes.h6}>Referring provider</H6>
            <ReferringProviderSelect
              error={
                attemptSubmit &&
                (useRMConfig
                  ? !RM_CONFIG_VALIDATION_MAP["selectedReferringProvider"]
                  : !VALIDATION_MAP["selectedReferringProvider"])
              }
              npiError={
                attemptSubmit &&
                (useRMConfig
                  ? RM_CONFIG_VALIDATION_MAP["selectedReferringProvider"] &&
                    !RM_CONFIG_VALIDATION_MAP["referringProviderNPI"]
                  : VALIDATION_MAP["selectedReferringProvider"] && !VALIDATION_MAP["referringProviderNPI"])
              }
              tinError={
                attemptSubmit &&
                (useRMConfig
                  ? RM_CONFIG_VALIDATION_MAP["selectedReferringProvider"] &&
                    !RM_CONFIG_VALIDATION_MAP["referringProviderTIN"]
                  : VALIDATION_MAP["selectedReferringProvider"] && !VALIDATION_MAP["referringProviderTIN"])
              }
              addressError={
                attemptSubmit &&
                (useRMConfig
                  ? RM_CONFIG_VALIDATION_MAP["selectedReferringProvider"] &&
                    RM_CONFIG_VALIDATION_MAP["referringProviderTIN"] &&
                    !RM_CONFIG_VALIDATION_MAP["referringProviderAddress"]
                  : VALIDATION_MAP["selectedReferringProvider"] &&
                    VALIDATION_MAP["referringProviderTIN"] &&
                    !VALIDATION_MAP["referringProviderAddress"])
              }
              rrFormContent={rrFormContent}
              setRRFormContent={setRRFormContent}
              patient={patient}
              hideTinField={
                useRMConfig
                  ? rrFormConfiguration.referringProviderTIN.fieldSpec === "OMIT"
                  : referralPayorConfiguration.hideTinField
              }
            />
          </Row>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...rrFormConfiguration.selectedPerformingSpecialist}>
          <Row>
            <H6 className={classes.h6}>{`Specialist`}</H6>
            <div className={classes.specialtyContainer}>
              <SpecialtySelect
                clearable={rrFormConfiguration.specialty.fieldSpec === "OPTIONAL"}
                selectedValue={rrFormContent.specialty || undefined}
                setSelectedValue={async (specialitySelected: ReferraRequestSpecialty | null) => {
                  if (specialitySelected === null) {
                    setRRFormContent((prevContent) => ({
                      ...prevContent,
                      specialty: undefined,
                      medicalOrBehavioral: undefined,
                    }));
                  } else {
                    setRRFormContent((prevContent) => ({
                      ...prevContent,
                      specialty: specialitySelected?.name,
                      medicalOrBehavioral: specialitySelected?.medicalOrBehavioral,
                    }));
                  }
                }}
                healthPlanName={coverage?.healthPlanName}
                markSelectedOptions={false}
              />
            </div>
            <PerformingSpecialistSelect
              providerError={
                attemptSubmit &&
                (useRMConfig
                  ? !RM_CONFIG_VALIDATION_MAP["selectedPerformingSpecialist"]
                  : !VALIDATION_MAP["selectedPerformingSpecialist"])
              }
              npiError={
                attemptSubmit &&
                (useRMConfig
                  ? RM_CONFIG_VALIDATION_MAP["selectedPerformingSpecialist"] &&
                    !RM_CONFIG_VALIDATION_MAP["performingSpecialistNPI"]
                  : VALIDATION_MAP["selectedPerformingSpecialist"] && !VALIDATION_MAP["performingSpecialistNPI"])
              }
              isSoloPractitionerChecked={isSoloPractitionerChecked}
              setIsSoloPractitionerChecked={setIsSoloPractitionerChecked}
              tinError={
                attemptSubmit &&
                (useRMConfig
                  ? RM_CONFIG_VALIDATION_MAP["selectedPerformingSpecialist"] &&
                    !RM_CONFIG_VALIDATION_MAP["performingSpecialistTIN"]
                  : VALIDATION_MAP["selectedPerformingSpecialist"] && !VALIDATION_MAP["performingSpecialistTIN"])
              }
              addressError={
                attemptSubmit &&
                (useRMConfig
                  ? RM_CONFIG_VALIDATION_MAP["selectedPerformingSpecialist"] &&
                    RM_CONFIG_VALIDATION_MAP["performingSpecialistTIN"] &&
                    !RM_CONFIG_VALIDATION_MAP["performingSpecialistAddress"]
                  : VALIDATION_MAP["selectedPerformingSpecialist"] &&
                    VALIDATION_MAP["performingSpecialistTIN"] &&
                    !VALIDATION_MAP["performingSpecialistAddress"])
              }
              rrFormContent={rrFormContent}
              setRRFormContent={setRRFormContent}
              formConfiguration={rrFormConfiguration}
              patient={patient}
              setHasPerformingSpecialistAttestation={setHasPerformingSpecialistAttestation}
              hideTinField={
                useRMConfig
                  ? rrFormConfiguration.performingSpecialistTIN.fieldSpec === "OMIT"
                  : referralPayorConfiguration.hideTinField
              }
            />
            <SRFormConfigFieldWrapper
              {...(useRMConfig
                ? rrFormConfiguration.outOfNetworkPerformingSpecialist
                : rrFormConfiguration.outOfNetworkCheck)}
            >
              <SRFormConfigFieldWrapper {...rrFormConfiguration.performingSpecialistOutOfNetworkStatusDisplay}>
                <Grid item container xs={12} spacing={2} style={{ paddingTop: theme.spacing(1) }}>
                  <ReferralNetworkCheckStatusDisplay
                    oonLoading={specialistOonCheckLoading}
                    networkType={
                      rrFormContent?.selectedPerformingSpecialist?.selectedLocation?.networkType || undefined
                    }
                    isSelectedAddressOON={rrFormContent?.selectedPerformingSpecialist?.selectedLocation?.isOutOfNetwork}
                    oonCheckPerformed={specialistOONCheckPerformed}
                    oonCheckData={specialistOonCheckData}
                    referralPayorConfiguration={referralPayorConfiguration}
                    messages={rrFormConfiguration.selectedPerformingSpecialist.networkMessage}
                  />
                </Grid>
              </SRFormConfigFieldWrapper>
            </SRFormConfigFieldWrapper>
            {((useRMConfig && rrFormConfiguration.performingSpecialistAttestation.fieldSpec !== "OMIT") ||
              (!useRMConfig && referralPayorConfiguration.shouldShowAttestation)) &&
              (isOonProviderOrFacility(rrFormContent.selectedPerformingSpecialist?.selectedLocation) ||
                isUnknownNetworkType(rrFormContent.selectedPerformingSpecialist?.selectedLocation?.networkType)) && (
                <SRFormConfigFieldWrapper {...rrFormConfiguration.performingSpecialistAttestation}>
                  <Grid item xs={12} className={classes.checkboxGrid}>
                    <Checkbox
                      className={classes.checkbox}
                      label={
                        <span color={"text.secondary"}>
                          I attest that the provider is out of network with a valid exception reason (optional)
                        </span>
                      }
                      checked={hasPerformingSpecialistAttestation}
                      onChange={(attestationValue) =>
                        handlePerformingSpecialistAttestationChanges(
                          setRRFormContent,
                          rrFormContent,
                          setHasPerformingSpecialistAttestation,
                          attestationValue,
                          undefined
                        )
                      }
                    />
                  </Grid>
                </SRFormConfigFieldWrapper>
              )}
            {hasPerformingSpecialistAttestation &&
              ((!useRMConfig && !!referralPayorConfiguration.oonAttestationReasons) ||
                (useRMConfig && rrFormConfiguration.performingSpecialistAttestation.fieldSpec !== "OMIT")) &&
              (isOonProviderOrFacility(rrFormContent.selectedPerformingSpecialist?.selectedLocation) ||
                isUnknownNetworkType(rrFormContent.selectedPerformingSpecialist?.selectedLocation?.networkType) ||
                (specialistOONCheckPerformed && !specialistOonCheckData)) && (
                <SRFormConfigFieldWrapper {...rrFormConfiguration.performingSpecialistAttestationReason}>
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <SingleSelectDropdown
                      value={
                        rrFormContent?.selectedPerformingSpecialist?.selectedLocation?.outOfNetworkExceptionReason || ""
                      }
                      label={
                        rrFormContent?.selectedPerformingSpecialist?.selectedLocation?.outOfNetworkExceptionReason || ""
                          ? "Reason"
                          : "Select reason"
                      }
                      options={
                        useRMConfig
                          ? OonExceptionReasonOptions(
                              rrFormConfiguration.performingSpecialistAttestationReason?.reasons
                                ? Object.fromEntries(
                                    rrFormConfiguration.performingSpecialistAttestationReason?.reasons.map((item) => [
                                      item,
                                      item,
                                    ])
                                  )
                                : {}
                            )
                          : referralPayorConfiguration.oonAttestationReasons
                          ? OonExceptionReasonOptions(referralPayorConfiguration.oonAttestationReasons)
                          : []
                      }
                      data-tracking-id="exception-reason-dropdown"
                      error={
                        attemptSubmit &&
                        (useRMConfig
                          ? !RM_CONFIG_VALIDATION_MAP["performingSpecialistAttestationReason"]
                          : !VALIDATION_MAP["performingSpecialistAttestationReason"])
                      }
                      onChange={(reason) => {
                        handlePerformingSpecialistAttestationChanges(
                          setRRFormContent,
                          rrFormContent,
                          setHasPerformingSpecialistAttestation,
                          undefined,
                          reason
                        );
                      }}
                    />
                  </Grid>
                </SRFormConfigFieldWrapper>
              )}
          </Row>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...rrFormConfiguration.selectedFacility}>
          <Row style={{ paddingBottom: theme.spacing(3) }}>
            <FacilitySelect
              facilityError={
                attemptSubmit &&
                (useRMConfig ? !RM_CONFIG_VALIDATION_MAP["selectedFacility"] : !VALIDATION_MAP["selectedFacility"])
              }
              isSoloPractitionerChecked={isSoloPractitionerChecked}
              npiError={
                attemptSubmit &&
                (useRMConfig
                  ? RM_CONFIG_VALIDATION_MAP["selectedFacility"] && !RM_CONFIG_VALIDATION_MAP["facilityNPI"]
                  : VALIDATION_MAP["selectedFacility"] && !VALIDATION_MAP["facilityNPI"])
              }
              tinError={
                attemptSubmit &&
                (useRMConfig
                  ? RM_CONFIG_VALIDATION_MAP["selectedFacility"] && !RM_CONFIG_VALIDATION_MAP["facilityTIN"]
                  : VALIDATION_MAP["selectedFacility"] && !VALIDATION_MAP["facilityTIN"])
              }
              addressError={
                attemptSubmit &&
                (useRMConfig
                  ? RM_CONFIG_VALIDATION_MAP["selectedFacility"] &&
                    RM_CONFIG_VALIDATION_MAP["facilityTIN"] &&
                    !RM_CONFIG_VALIDATION_MAP["facilityAddress"]
                  : VALIDATION_MAP["selectedFacility"] &&
                    VALIDATION_MAP["facilityTIN"] &&
                    !VALIDATION_MAP["facilityAddress"])
              }
              rrFormContent={rrFormContent}
              setRRFormContent={setRRFormContent}
              formConfiguration={rrFormConfiguration}
              patient={patient}
              setHasFacilityAttestation={setHasFacilityAttestation}
              hideTinField={useRMConfig ? rrFormConfiguration.facilityTIN.fieldSpec === "OMIT" : false}
              label="Specialist practice"
            />
            <SRFormConfigFieldWrapper
              {...(useRMConfig ? rrFormConfiguration.outOfNetworkFacility : rrFormConfiguration.outOfNetworkCheck)}
            >
              <SRFormConfigFieldWrapper {...rrFormConfiguration.facilityOutOfNetworkStatusDisplay}>
                <Grid item container xs={12} spacing={2} style={{ paddingTop: theme.spacing(1) }}>
                  <ReferralNetworkCheckStatusDisplay
                    oonLoading={facilityOonCheckLoading}
                    networkType={rrFormContent?.selectedFacility?.selectedLocation?.networkType || undefined}
                    isSelectedAddressOON={rrFormContent?.selectedFacility?.selectedLocation?.isOutOfNetwork}
                    oonCheckPerformed={facilityOONCheckPerformed}
                    oonCheckData={facilityOonCheckData}
                    referralPayorConfiguration={referralPayorConfiguration}
                    messages={rrFormConfiguration.selectedFacility.networkMessage}
                  />
                </Grid>
              </SRFormConfigFieldWrapper>
            </SRFormConfigFieldWrapper>
            {((useRMConfig && rrFormConfiguration.facilityAttestation.fieldSpec !== "OMIT") ||
              (!useRMConfig && referralPayorConfiguration.shouldShowAttestation)) &&
              (isOonProviderOrFacility(rrFormContent.selectedFacility?.selectedLocation) ||
                isUnknownNetworkType(rrFormContent.selectedFacility?.selectedLocation?.networkType)) && (
                <SRFormConfigFieldWrapper {...rrFormConfiguration.facilityAttestation}>
                  <Grid item xs={12} className={classes.checkboxGrid}>
                    <Checkbox
                      className={classes.checkbox}
                      label={
                        <span color={"text.secondary"}>
                          I attest that the provider is out of network with a valid exception reason (optional)
                        </span>
                      }
                      checked={hasFacilityAttestation}
                      onChange={(attestationValue) =>
                        handleFacilityAttestationChanges(
                          setRRFormContent,
                          rrFormContent,
                          setHasFacilityAttestation,
                          attestationValue,
                          undefined
                        )
                      }
                    />
                  </Grid>
                </SRFormConfigFieldWrapper>
              )}
            {hasFacilityAttestation &&
              ((!useRMConfig && !!referralPayorConfiguration.oonAttestationReasons) ||
                (useRMConfig && rrFormConfiguration.facilityAttestation.fieldSpec !== "OMIT")) &&
              (isOonProviderOrFacility(rrFormContent.selectedFacility?.selectedLocation) ||
                isUnknownNetworkType(rrFormContent.selectedFacility?.selectedLocation?.networkType) ||
                (facilityOONCheckPerformed && !facilityOonCheckData)) && (
                <SRFormConfigFieldWrapper {...rrFormConfiguration.facilityAttestationReason}>
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <SingleSelectDropdown
                      value={rrFormContent?.selectedFacility?.selectedLocation?.outOfNetworkExceptionReason || ""}
                      label={
                        rrFormContent?.selectedFacility?.selectedLocation?.outOfNetworkExceptionReason || ""
                          ? "Reason"
                          : "Select reason"
                      }
                      options={
                        useRMConfig
                          ? OonExceptionReasonOptions(
                              rrFormConfiguration.facilityAttestationReason?.reasons
                                ? Object.fromEntries(
                                    rrFormConfiguration.facilityAttestationReason?.reasons.map((item) => [item, item])
                                  )
                                : {}
                            )
                          : referralPayorConfiguration.oonAttestationReasons
                          ? OonExceptionReasonOptions(referralPayorConfiguration.oonAttestationReasons)
                          : []
                      }
                      data-tracking-id="exception-reason-dropdown"
                      error={
                        attemptSubmit &&
                        (useRMConfig
                          ? !RM_CONFIG_VALIDATION_MAP["facilityAttestationReason"]
                          : !VALIDATION_MAP["facilityAttestationReason"])
                      }
                      onChange={(reason) => {
                        handleFacilityAttestationChanges(setRRFormContent, rrFormContent, undefined, undefined, reason);
                      }}
                    />
                  </Grid>
                </SRFormConfigFieldWrapper>
              )}
          </Row>
        </SRFormConfigFieldWrapper>
      </Grid>
    </>
  );
}

export const Row = (props: ComponentProps<typeof Grid>) => <Grid item xs={12} {...props} />;

const handlePerformingSpecialistAttestationChanges = async (
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>,
  rrFormContent: ReferralRequestFormContent,
  setHasPerformingSpecialistAttestation: Dispatch<SetStateAction<boolean>> | undefined,
  attestationValue: boolean | undefined,
  reason: string | undefined
) => {
  if (attestationValue !== undefined) {
    if (!attestationValue) {
      updateRRFormPerformingSpecialistAttestationReason(setRRFormContent, rrFormContent, "");
    }
    setHasPerformingSpecialistAttestation?.(attestationValue);
  } else if (reason !== undefined) {
    updateRRFormPerformingSpecialistAttestationReason(setRRFormContent, rrFormContent, reason);
  }
};

const updateRRFormPerformingSpecialistAttestationReason = (
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>,
  rrFormContent: ReferralRequestFormContent,
  reason: string
) => {
  setRRFormContent({
    ...rrFormContent,
    performingSpecialistSelectedAddress: {
      ...rrFormContent?.performingSpecialistSelectedAddress,
      outOfNetworkExceptionReason: reason,
    },
    selectedPerformingSpecialist: {
      ...rrFormContent?.selectedPerformingSpecialist,
      id: rrFormContent?.selectedPerformingSpecialist?.id || "",
      dateCreated: rrFormContent?.selectedPerformingSpecialist?.dateCreated || "",
      lastUpdated: rrFormContent?.selectedPerformingSpecialist?.lastUpdated || "",
      selectedLocation: {
        ...rrFormContent?.selectedPerformingSpecialist?.selectedLocation,
        outOfNetworkExceptionReason: reason,
      },
      type: rrFormContent?.selectedPerformingSpecialist?.type || "PROVIDER",
    },
  });
};

const handleFacilityAttestationChanges = (
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>,
  rrFormContent: ReferralRequestFormContent,
  setHasFacilityAttestation: Dispatch<SetStateAction<boolean>> | undefined,
  attestationValue: boolean | undefined,
  reason: string | undefined
) => {
  if (attestationValue !== undefined) {
    if (!attestationValue) {
      updateRRFormFacilityAttestationReason(setRRFormContent, rrFormContent, "");
    }
    setHasFacilityAttestation?.(attestationValue);
  } else if (reason !== undefined) {
    updateRRFormFacilityAttestationReason(setRRFormContent, rrFormContent, reason);
  }
};

const updateRRFormFacilityAttestationReason = (
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>,
  rrFormContent: ReferralRequestFormContent,
  reason: string
) => {
  setRRFormContent({
    ...rrFormContent,
    facilitySelectedAddress: {
      ...rrFormContent?.facilitySelectedAddress,
      outOfNetworkExceptionReason: reason,
    },
    selectedFacility: {
      ...rrFormContent?.selectedFacility,
      id: rrFormContent?.selectedFacility?.id || "",
      dateCreated: rrFormContent?.selectedFacility?.dateCreated || "",
      lastUpdated: rrFormContent?.selectedFacility?.lastUpdated || "",
      selectedLocation: {
        ...rrFormContent?.selectedFacility?.selectedLocation,
        outOfNetworkExceptionReason: reason,
      },
      type: rrFormContent?.selectedFacility?.type || "FACILITY",
    },
  });
};
