import React, { createContext, Dispatch, SetStateAction, useState, useCallback } from "react";
import { HighlightArea, highlightPlugin, RenderHighlightsProps, HighlightPlugin } from "@react-pdf-viewer/highlight";
import {
  AutomatedReviewNote,
  AutomatedReviewNoteFinding,
  AutomatedReviewNoteNode,
} from "@coherehealth/core-platform-api";
import hash from "hash.js";

type AttachmentId = string;

export interface ReviewNoteHighlight {
  id: string;
  attachmentId: AttachmentId;
  highlightArea: HighlightArea;
}

interface HighlightReviewNoteProviderProps {
  children: React.ReactNode;
}

export interface HighlightReviewNoteStateContextProps {
  initializeHighlights: ((automatedReviewNote: AutomatedReviewNote) => void) | undefined;
  getHighlights: (() => ReviewNoteHighlight[]) | undefined;
  attachmentId: AttachmentId | undefined;
  setAttachmentId: Dispatch<SetStateAction<AttachmentId | undefined>> | undefined;
  highlightReviewNotePluginInstance: HighlightPlugin | undefined;
  jumpToHighlight: ((hash: string) => void) | undefined;
}

const defaultHighlightState = {
  initializeHighlights: undefined,
  getHighlights: undefined,
  attachmentId: undefined,
  setAttachmentId: undefined,
  highlightReviewNotePluginInstance: undefined,
  jumpToHighlight: undefined,
};

export const HighlightReviewNoteStateContext =
  createContext<HighlightReviewNoteStateContextProps>(defaultHighlightState);

const renderNestedNode = (automatedReviewNoteNode: AutomatedReviewNoteNode) => {
  let highlights = [] as ReviewNoteHighlight[];
  if (!automatedReviewNoteNode.children?.length) {
    for (const item of automatedReviewNoteNode.nodes || []) {
      if (item.highlights) {
        for (const highlight of item.highlights) {
          /* 
            Some highlights can have the same id and this is expected, since a single highlighted paragraph
            can be made of many highlight areas, so at the moment of jumping to the highlight
            we select just the first one with the provided id.
          */
          highlights.push({
            id: createHighlightHash(automatedReviewNoteNode.findingName, item),
            attachmentId: item.attribution.attachmentId,
            highlightArea: highlight,
          } as ReviewNoteHighlight);
        }
      }
    }
  } else {
    for (const child of automatedReviewNoteNode.children) {
      highlights = [...highlights, ...renderNestedNode(child)];
    }
  }

  return highlights;
};

/*
  This works as a unique ID for each highlighted paragraph, it must match with
  the hash generated when initializing the automated review note for the RichTextEditor
*/
const createHighlightHash = (findingName: string, reviewNoteFinding: AutomatedReviewNoteFinding): string => {
  const hashValue = hash
    .sha256()
    .update(JSON.stringify({ name: findingName, location: reviewNoteFinding.highlights }))
    .digest("hex");

  return hashValue.slice(0, 8);
};

/*
  Handles the highlighting in the attachments based on the automated review note attribution info
*/
export const HighlightReviewNoteProvider = ({ children }: HighlightReviewNoteProviderProps) => {
  const [highlights, setHighlights] = useState<ReviewNoteHighlight[]>([]);
  const [attachmentId, setAttachmentId] = useState<AttachmentId | undefined>();

  const initializeHighlights = useCallback((automatedReviewNote: AutomatedReviewNote) => {
    if (automatedReviewNote.length) {
      let highlights = [] as ReviewNoteHighlight[];
      for (const node of automatedReviewNote) {
        highlights = [...highlights, ...renderNestedNode(node)];
      }
      setHighlights(highlights);
    }
  }, []);

  const getHighlights = useCallback(() => {
    return highlights.filter((highlight) => attachmentId && highlight.attachmentId === attachmentId);
  }, [highlights, attachmentId]);

  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {getHighlights?.()
        .filter((highlight) => highlight.highlightArea.pageIndex === props.pageIndex)
        .map((highlight, idx) => (
          <React.Fragment key={`${highlight.id}-${idx}`}>
            <div
              key={idx}
              className="highlight"
              style={Object.assign(
                {},
                {
                  background: "#FFDFA1",
                  mixBlendMode: "multiply",
                  zIndex: 1,
                  cursor: "pointer",
                  border: "1px solid #DE7800",
                  borderRadius: "1px",
                },
                props.getCssProperties(highlight.highlightArea, props.rotation)
              )}
            />
          </React.Fragment>
        ))}
    </div>
  );

  const highlightReviewNotePluginInstance = highlightPlugin({
    renderHighlights,
  });

  const { jumpToHighlightArea } = highlightReviewNotePluginInstance;

  const jumpToHighlight = (hash: string): void => {
    const highlight = highlights.find((highlight) => highlight.id === hash);
    if (highlight && highlight.highlightArea) {
      jumpToHighlightArea(highlight.highlightArea);
    }
  };

  return (
    <HighlightReviewNoteStateContext.Provider
      value={{
        initializeHighlights,
        getHighlights,
        attachmentId,
        setAttachmentId,
        highlightReviewNotePluginInstance,
        jumpToHighlight,
      }}
    >
      {children}
    </HighlightReviewNoteStateContext.Provider>
  );
};
