import { useChatbotStyles } from "./chatbotReviewAssistantStyles";
import ChatbotSeededContent from "./ChatbotSeededContent";
import ChatbotSynchronousContent from "./ChatbotSynchronousContent";
import { IReviewChatbotFindingHolder } from "components/ClinicalReview/Review/hooks/useAutomatedReviewChatbotFindings";
import { ReviewChatbotFinding } from "@coherehealth/core-platform-api";
import { useContext } from "react";
import { ClinicalReviewContext } from "components/ClinicalReview/reviewUtils/clinicalReviewPageUtils";

interface Props {
  chatbotFindings: IReviewChatbotFindingHolder[];
  setChatbotFindings: React.Dispatch<React.SetStateAction<IReviewChatbotFindingHolder[]>>;
  isLoading: boolean;
  currentUserInput: string | undefined;
}

export default function ChatbotReviewAssistantContent({
  chatbotFindings,
  setChatbotFindings,
  isLoading,
  currentUserInput,
}: Props) {
  const {
    reviewRightColumnPositionState: { reviewAssistantScrollDivRef },
  } = useContext(ClinicalReviewContext);

  const classes = useChatbotStyles({});

  const seededChatbotFindings = chatbotFindings.filter((finding) => !finding.chatbotfinding.manuallyInputPrompt);
  const synchronousChatbotFindings = chatbotFindings.filter((finding) => finding.chatbotfinding.manuallyInputPrompt);

  const setFeedback = (id: string, updatedChatbotFinding: Partial<ReviewChatbotFinding>) => {
    setChatbotFindings((prevState: IReviewChatbotFindingHolder[]) => {
      return prevState.map((item: IReviewChatbotFindingHolder) => {
        if (item.id === id) {
          return { ...item, chatbotfinding: { ...item.chatbotfinding, ...updatedChatbotFinding } };
        }
        return item;
      });
    });
  };

  return (
    <div ref={reviewAssistantScrollDivRef} className={classes.reviewAssistantWrapper}>
      <div className={classes.reviewAssistantWrapperInner}>
        <ChatbotSeededContent chatbotFindings={seededChatbotFindings} setFeedback={setFeedback} />
        <ChatbotSynchronousContent
          loading={isLoading}
          chatbotFindings={synchronousChatbotFindings}
          setFeedback={setFeedback}
          currentUserInput={currentUserInput}
        />
      </div>
    </div>
  );
}
