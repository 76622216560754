import { useContext, useEffect } from "react";
import { InlineIconButton } from "./InlineIconButton";
import {
  ServiceRequestCommandPayload,
  useAddServiceRequestOnAuthorization,
  useCreateAuthorization,
  useGetAuthValidityWindow,
} from "@coherehealth/core-platform-api";
import { useCalculateInitializedEndDate } from "components/AuthBuilder/common";
import { useTrackUserInteraction } from "util/userActivityTracker";
import { formatDateToISODate } from "@coherehealth/common";
import { continuationWorkflowPath } from "util/serviceRequest";
import { useNavigate } from "react-router";
import { PotentialDuplicateContext } from "components/AuthBuilder/GatherRequirements/PotentialDuplicateContextProvider";
import { useSnackbar } from "notistack";
import { InlineActionButton } from "./InlineActionButton";
import { ActionButtonT } from "../StatusAlert/statusAlertUtils";
import { useCreateReadmissionPayload } from "components/ServiceRequest/ReviewSection/common/hooks/useReadmission";

export const ActionButtons = ({
  serviceRequest,
  isInAuthBuilder,
  isAuthViewOnlyUser,
  actionConfiguration,
  limitVoidsAfterApprovalFF,
  allowContinuations,
  isFinalDeterminationLetterPrintable,
  authorization,
  isDraftSr,
  onShowPrintSummary,
  setEditModalOpen,
  setDeleteModalOpen,
  isStatusEditable,
  patient,
  setHover,
  openServiceRequestForEditing,
  canCreateContinuation,
  onPotentialDuplicatesClose,
}: ActionButtonT) => {
  const { healthPlanName } = serviceRequest;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const createReadmissionPayload = useCreateReadmissionPayload();

  const { data: authValidityWindowData, refetch: refetchAuthValidityWindowData } = useGetAuthValidityWindow({
    lazy: true,
  });
  const trackUserActivityInteraction = useTrackUserInteraction();
  const payload: ServiceRequestCommandPayload = {
    authStatus: "DRAFT",
    urgency: { isExpedited: false },
    units: 0,
    requestType: "CONTINUATION",
    workflowStep: "FILL_FORMS_CONTINUATION",
    encounterType: serviceRequest?.encounterType || authorization?.encounterType,
    continuationGeneratedFromServiceRequest: {
      id: serviceRequest.id,
    },
  };
  function navigateToContinuationWorkflowStep(
    serviceRequestId: string,
    patientId: string,
    authorizationId: string,
    workflowId: string | null
  ) {
    const { pathname, search } = continuationWorkflowPath(patientId, serviceRequestId);

    if (workflowId) {
      const url = new URL(window.location.origin + pathname + search);
      window.open(url.href, "_blank");
    } else {
      navigate(
        {
          pathname,
          search,
        },
        {
          state: {
            isContinuationWorkflow: true,
            authorizationId,
          },
        }
      );
    }
  }
  const {
    mutate: createNewAuthorization,
    loading: createNewAuthorizationLoading,
    error: createNewAuthorizationError,
  } = useCreateAuthorization({});
  const {
    mutate: createNewServiceRequestOnAuth,
    loading: loadingContinuation,
    error: createContinuationError,
  } = useAddServiceRequestOnAuthorization({
    id: serviceRequest.authorization?.id || "",
    requestOptions: { headers: { Accept: "application/json" } },
  });
  const workflowId = useContext(PotentialDuplicateContext);

  const calculateInitializedEndDate = useCalculateInitializedEndDate(healthPlanName);
  const onContinuationClick = async () => {
    if (!serviceRequest.authorization?.id) {
      authorization = await createNewAuthorization({
        patient: serviceRequest.patient?.id,
        healthPlanName: healthPlanName,
        serviceRequestIds: [serviceRequest.id],
      });
    }
    // creates a new empty draft SR on the authorization and directs the user to the fill forms continuation page for the newly created draft
    const today = new Date();
    let endDate;
    const initializedEndDate = calculateInitializedEndDate(
      today,
      serviceRequest.clinicalServices || [],
      patient?.coverages || []
    );

    // if in the duplicate modal run an analytics event
    if (workflowId) {
      trackUserActivityInteraction({
        event: "START_CONTINUATION_DUPLICATE",
        stage: "DUPLICATE_SUBMISSION_REVIEW",
        activityContext: {
          serviceRequestId: serviceRequest.id,
          workflowId: workflowId,
        },
      });
    }

    if (initializedEndDate) {
      endDate = initializedEndDate;
    }
    if (serviceRequest?.patient?.id && serviceRequest?.startDate) {
      await refetchAuthValidityWindowData({
        queryParams: { patient: serviceRequest.patient.id, startDate: serviceRequest.startDate },
      });
    }
    const lastValidDate = authValidityWindowData?.validityWindowEndDate
      ? new Date(authValidityWindowData.validityWindowEndDate)
      : endDate;
    payload.endDate = formatDateToISODate(lastValidDate);
    const readmissionPayload = createReadmissionPayload(
      payload,
      serviceRequest?.isReadmission,
      serviceRequest?.anchorAuthNumber
    );
    try {
      const addDraftSRResponse = await createNewServiceRequestOnAuth(readmissionPayload, {
        pathParams: { id: serviceRequest.authorization?.id || authorization?.id || "" },
      });

      navigateToContinuationWorkflowStep(
        addDraftSRResponse?.id,
        addDraftSRResponse?.patient?.id || "",
        addDraftSRResponse?.authorization?.id || "",
        workflowId
      );
    } catch (error) {
      if (createContinuationError) {
        enqueueSnackbar(
          createContinuationError.status === 422
            ? "A draft continuation request might already exist for #" + serviceRequest.cohereId
            : createContinuationError.message,
          { variant: "error" }
        );
      }
    }
  };

  useEffect(() => {
    if (allowContinuations && createNewAuthorizationError) {
      enqueueSnackbar("Failed to create an authorization, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  });
  return (
    <>
      <InlineIconButton
        serviceRequest={serviceRequest}
        limitVoidsAfterApprovalFF={limitVoidsAfterApprovalFF}
        isFinalDeterminationLetterPrintable={isFinalDeterminationLetterPrintable}
        allowContinuations={allowContinuations}
        isAuthViewOnlyUser={isAuthViewOnlyUser}
        actionConfiguration={actionConfiguration}
        createNewAuthorizationLoading={createNewAuthorizationLoading}
        loadingContinuation={loadingContinuation}
        onShowPrintSummary={onShowPrintSummary}
        setEditModalOpen={setEditModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        authorization={authorization}
        isDraftSr={isDraftSr}
        isStatusEditable={isStatusEditable}
        onContinuationClick={onContinuationClick}
        setHover={setHover}
        openServiceRequestForEditing={openServiceRequestForEditing}
        canCreateContinuation={canCreateContinuation}
      />
      <InlineActionButton
        serviceRequest={serviceRequest}
        limitVoidsAfterApprovalFF={limitVoidsAfterApprovalFF}
        isFinalDeterminationLetterPrintable={isFinalDeterminationLetterPrintable}
        allowContinuations={allowContinuations}
        isAuthViewOnlyUser={isAuthViewOnlyUser}
        actionConfiguration={actionConfiguration}
        createNewAuthorizationLoading={createNewAuthorizationLoading}
        loadingContinuation={loadingContinuation}
        onShowPrintSummary={onShowPrintSummary}
        setEditModalOpen={setEditModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        authorization={authorization}
        isDraftSr={isDraftSr}
        isStatusEditable={isStatusEditable}
        onContinuationClick={onContinuationClick}
        onPotentialDuplicatesClose={onPotentialDuplicatesClose}
        setHover={setHover}
        openServiceRequestForEditing={openServiceRequestForEditing}
      />
    </>
  );
};
