import { Location, PracticeCommon } from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { Dispatch } from "react";

export function isNpiValid(npi?: string): boolean {
  return npi?.length === 10;
}

export function isZipcodeValid(zipcode?: string): boolean {
  return zipcode?.length === 5 && /^\d+$/.test(zipcode);
}

export function getTINDisplayValue(tin?: string) {
  if (!tin) {
    return "";
  }
  if (tin.length >= 3) {
    const tinWithoutDashes = tin.replaceAll("-", "");
    return [tinWithoutDashes.slice(0, 2), "-", tinWithoutDashes.slice(2, tinWithoutDashes.length)].join("");
  } else {
    return tin;
  }
}

export function updateTinFromDisplayValue(setTin: Dispatch<string>) {
  return (displayedTin: string) => {
    // only allow up to 9 digit only characters
    let formattedTin = displayedTin.replace(/[^\d]/g, "");
    if (formattedTin.length > 9) {
      formattedTin = formattedTin.slice(0, 9);
    }
    setTin(formattedTin);
  };
}

export function isValidEmailDomainList(domains: string[], invalidEmailDomainList: string[]) {
  const filteredDomains = domains.filter((domain) => invalidEmailDomainList.includes(domain));
  return filteredDomains.length > 0;
}

export const isTinValid = (tin: string): boolean => {
  return tin.length === 9 && /^\d+$/.test(tin);
};

export const extractDomainFromEmail = (text: string) => {
  const emailSplit = text.split("@");
  if (emailSplit.length !== 2) {
    return text;
  }
  return text.substring(text.indexOf("@") + 1);
};

export const emailDomainFormatter = (text: string) => {
  if (isValidEmailDomain(text)) {
    return `@${text}`;
  }
  if (isValidEmail(text)) {
    return `@${extractDomainFromEmail(text)}`;
  }
  return text;
};

export const isValidEmail = (email: string) => {
  const validEmail = /^[^\s@]*@[^\s@]+\.com$/;
  return validEmail.test(email);
};

export const isValidEmailDomain = (domain: string) => {
  const validEmailDomain = /^([a-z0-9]+(-[a-z0-9]+)*\.[a-z]{3,})+$/;
  return validEmailDomain.test(domain);
};

export enum ModalStatus {
  Edit = "edit",
  Summary = "summary",
  EditAddress = "editAddress",
  AddAddress = "addAddress",
  Add = "add",
  EditTin = "editTin",
  AddTin = "addTin",
}

export function convertToSentenceCase(string: string): string {
  let lowerCaseString = string.toLowerCase();
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
}

//This method is used for setting the exception reason & comments to blank in case exception is not required
export const handleChangeOnProviderField = (formContent: ServiceRequestFormContent) => {
  return {
    ...formContent,
    orderingProviderSelectedAddress: {
      ...formContent?.orderingProviderSelectedAddress,
      outOfNetworkExceptionReason: "",
      outOfNetworkExceptionComment: "",
    },
    selectedOrderingProvider: {
      ...formContent?.selectedOrderingProvider,
      id: formContent?.selectedOrderingProvider?.id || "",
      dateCreated: formContent?.selectedOrderingProvider?.dateCreated || "",
      lastUpdated: formContent?.selectedOrderingProvider?.lastUpdated || "",
      selectedLocation: {
        ...formContent?.selectedOrderingProvider?.selectedLocation,
        outOfNetworkExceptionReason: "",
        outOfNetworkExceptionComment: "",
      },
      type: formContent?.selectedOrderingProvider?.type || "PROVIDER",
    },
  };
};

//This method is used for setting the exception reason case exception is filled by the user
export const handleChangeOnExceptionReasonField = (formContent: ServiceRequestFormContent, reason: string) => {
  return {
    ...formContent,
    orderingProviderSelectedAddress: {
      ...formContent?.orderingProviderSelectedAddress,
      outOfNetworkExceptionReason: reason,
    },
    selectedOrderingProvider: {
      ...formContent?.selectedOrderingProvider,
      id: formContent?.selectedOrderingProvider?.id || "",
      dateCreated: formContent?.selectedOrderingProvider?.dateCreated || "",
      lastUpdated: formContent?.selectedOrderingProvider?.lastUpdated || "",
      selectedLocation: {
        ...formContent?.selectedOrderingProvider?.selectedLocation,
        outOfNetworkExceptionReason: reason,
      },
      type: formContent?.selectedOrderingProvider?.type || "PROVIDER",
    },
  };
};

//This method is used for setting the exception comment case exception is filled by the user
export const handleChangeOnExceptionCommentField = (formContent: ServiceRequestFormContent, comment: string) => {
  return {
    ...formContent,
    orderingProviderSelectedAddress: {
      ...formContent?.orderingProviderSelectedAddress,
      outOfNetworkExceptionComment: comment,
    },
    selectedOrderingProvider: {
      ...formContent?.selectedOrderingProvider,
      id: formContent?.selectedOrderingProvider?.id || "",
      dateCreated: formContent?.selectedOrderingProvider?.dateCreated || "",
      lastUpdated: formContent?.selectedOrderingProvider?.lastUpdated || "",
      selectedLocation: {
        ...formContent?.selectedOrderingProvider?.selectedLocation,
        outOfNetworkExceptionComment: comment,
      },
      type: formContent?.selectedOrderingProvider?.type || "PROVIDER",
    },
  };
};

export const isExceptionCommentRequired = (formContent: ServiceRequestFormContent) => {
  return (
    formContent?.selectedOrderingProvider?.selectedLocation?.isOutOfNetwork &&
    formContent?.selectedOrderingProvider?.selectedLocation?.outOfNetworkExceptionReason ===
      "Other (comment is required)"
  );
};

export const getLocationsForTin = (
  tin: string | null | undefined,
  practice?: PracticeCommon | null,
  ignoreTin: boolean = false
): Location[] => {
  const locationsForTin: Location[] = [];
  if ((ignoreTin || tin) && practice && practice.locations) {
    for (const practiceLoc of practice.locations) {
      if (practiceLoc.tin === tin || ignoreTin) {
        locationsForTin.push(practiceLoc);
      }
    }
  }
  if (locationsForTin.length === 0 && practice && practice.locations) {
    if (practice.source === "CMS" && !practice.manuallyCreated && practice.locations.length > 0) {
      locationsForTin.push(practice.locations[0]);
    }
  }
  return locationsForTin;
};
