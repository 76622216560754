import { ClinicalReviewContext } from "components/ClinicalReview/reviewUtils/clinicalReviewPageUtils";
import { useContext } from "react";

interface UseServiceStatusCodeValidationProps {
  reviewOutcome?: string;
  serviceStatusCode?: string;
}

/**
 * Hook to validate if the serviceStatusCode is properly set based on the current review outcome requirements
 * Returns a boolean indicating if the button should be disabled due to serviceStatusCode requirements
 */
export default function useServiceStatusCodeValidation({
  reviewOutcome,
  serviceStatusCode,
}: UseServiceStatusCodeValidationProps) {
  const { allowedReviewOutcomes } = useContext(ClinicalReviewContext);
  const currentOutcome = allowedReviewOutcomes?.find((outcome) => outcome.id === reviewOutcome);
  const serviceStatusCodeDisabled = Boolean(currentOutcome?.required && !serviceStatusCode);

  return {
    serviceStatusCodeDisabled,
    currentOutcome,
  };
}
