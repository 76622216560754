import { useFeature } from "@coherehealth/common";
import { Patient, ReferralConfiguration, ReferralManagementFormConfiguration } from "@coherehealth/core-platform-api";
import { getCoverageBasedOnDate } from "util/patientUtils";

export type ReferralRequestFieldNames =
  | "startDate"
  | "primarySemanticDiagnosisCode"
  | "secondarySemanticDiagnosisCodes"
  | "selectedReferringProvider"
  | "referringProviderNPI"
  | "referringProviderTIN"
  | "referringProviderAddress"
  | "specialty"
  | "selectedFacility"
  | "selectedPerformingSpecialist"
  | "outOfNetworkCheck"
  | "outOfNetworkFacility"
  | "outOfNetworkPerformingSpecialist"
  | "facilityOutOfNetworkStatusDisplay"
  | "facilityAddress"
  | "facilityTIN"
  | "facilityNPI"
  | "facilityAttestation"
  | "facilityAttestationReason"
  | "performingSpecialistOutOfNetworkStatusDisplay"
  | "performingSpecialistAddress"
  | "performingSpecialistTIN"
  | "performingSpecialistNPI"
  | "performingSpecialistAttestation"
  | "performingSpecialistAttestationReason";

export type ReferralFormConfiguration = {
  [key in ReferralRequestFieldNames]: ReferralConfiguration;
};

type ReferralFormConfig = ReferralFormConfiguration | null;

export function useGetReferralRequestFormConfiguration(
  patient: Patient | null,
  isAdministrative: boolean,
  configSpec: void | ReferralManagementFormConfiguration | null
): ReferralFormConfiguration | null {
  // Determine active coverage based on today's date.
  const asOfToday = new Date();
  const coveragePlan = patient && getCoverageBasedOnDate(asOfToday, patient);
  const DEFAULT_RR_CONFIG_SPEC: ReferralFormConfiguration = {
    startDate: { fieldSpec: "REQUIRED" },
    primarySemanticDiagnosisCode: { fieldSpec: "REQUIRED" },
    secondarySemanticDiagnosisCodes: { fieldSpec: "OPTIONAL" },
    selectedReferringProvider: { fieldSpec: "REQUIRED" },
    referringProviderNPI: { fieldSpec: "REQUIRED" },
    referringProviderTIN: { fieldSpec: "REQUIRED" },
    referringProviderAddress: { fieldSpec: "REQUIRED" },
    specialty: { fieldSpec: "REQUIRED" },
    selectedFacility: { fieldSpec: "REQUIRED" },
    facilityAddress: { fieldSpec: "REQUIRED" },
    facilityTIN: { fieldSpec: "REQUIRED" },
    facilityNPI: { fieldSpec: "REQUIRED" },
    facilityAttestation: { fieldSpec: "OPTIONAL" },
    facilityAttestationReason: { fieldSpec: "OPTIONAL" },
    selectedPerformingSpecialist: { fieldSpec: "OPTIONAL" },
    performingSpecialistAddress: { fieldSpec: "OPTIONAL" },
    performingSpecialistTIN: { fieldSpec: "OPTIONAL" },
    performingSpecialistNPI: { fieldSpec: "OPTIONAL" },
    outOfNetworkCheck: { fieldSpec: "REQUIRED" },
    outOfNetworkFacility: { fieldSpec: "REQUIRED" },
    outOfNetworkPerformingSpecialist: { fieldSpec: "OPTIONAL" },
    facilityOutOfNetworkStatusDisplay: { fieldSpec: "REQUIRED" },
    performingSpecialistOutOfNetworkStatusDisplay: { fieldSpec: "OPTIONAL" },
    performingSpecialistAttestation: { fieldSpec: "OPTIONAL" },
    performingSpecialistAttestationReason: { fieldSpec: "OPTIONAL" },
  };

  const isOakStreetHMO =
    coveragePlan?.riskBearingEntity === "Oak Street Health" && coveragePlan.coverageProductType === "HMO";

  const isBCBS = coveragePlan?.healthPlanName === "BCBS South Carolina";

  let RR_CONFIG_SPEC_OVERRIDE: ReferralFormConfig = null;

  if (isAdministrative || isOakStreetHMO) {
    RR_CONFIG_SPEC_OVERRIDE = DEFAULT_RR_CONFIG_SPEC;
  }
  if (isBCBS) {
    RR_CONFIG_SPEC_OVERRIDE = {
      ...DEFAULT_RR_CONFIG_SPEC,
      facilityTIN: { fieldSpec: "OMIT" },
      selectedPerformingSpecialist: { fieldSpec: "REQUIRED" },
      performingSpecialistAddress: { fieldSpec: "REQUIRED" },
      performingSpecialistTIN: { fieldSpec: "REQUIRED" },
      performingSpecialistNPI: { fieldSpec: "REQUIRED" },
    };
  }

  const useRMConfig = useFeature("referralManagementConfigurator");

  if (useRMConfig) {
    if (!configSpec?.formFieldConfigurations) {
      return RR_CONFIG_SPEC_OVERRIDE;
    }

    for (const key in RR_CONFIG_SPEC_OVERRIDE) {
      if (configSpec?.formFieldConfigurations.hasOwnProperty(key)) {
        RR_CONFIG_SPEC_OVERRIDE[key as ReferralRequestFieldNames] = configSpec?.formFieldConfigurations?.[key];
      }
    }
  }
  return RR_CONFIG_SPEC_OVERRIDE;
}

export function useGetReferralRequestFormConfigurationWithSpecialtyType(
  patient: Patient | null,
  isAdministrative: boolean,
  medicalOrBehavioral: string | undefined
): ReferralFormConfiguration | null {
  let defaultConfig = useGetReferralRequestFormConfiguration(patient, isAdministrative, null);
  const asOfToday = new Date();
  const coveragePlan = patient && getCoverageBasedOnDate(asOfToday, patient);
  let overrideConfig: ReferralFormConfig = null;
  if (
    defaultConfig &&
    medicalOrBehavioral === "Behavioral Health" &&
    coveragePlan?.healthPlanName === "BCBS South Carolina"
  ) {
    overrideConfig = {
      ...defaultConfig,
      selectedPerformingSpecialist: { fieldSpec: "OPTIONAL" },
      performingSpecialistAddress: { fieldSpec: "OPTIONAL" },
      performingSpecialistTIN: { fieldSpec: "OPTIONAL" },
      performingSpecialistNPI: { fieldSpec: "OPTIONAL" },
    };
    return overrideConfig;
  }

  return defaultConfig;
}
