import { CompositeDecorator, ContentState, EditorState, Modifier, SelectionState, convertFromHTML } from "draft-js";
import { useLocation, useNavigate } from "react-router";
import { colorsLight } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";

const textAlignRegex = /<([^>]+) style="[^"]*text-align:\s*(.*?);[^"]*"[^>]*>/g; //ex: <div style="text-align: center;">Some content here</div>
const reviewNoteAttributionInfoRegex = /\[([^[]+?),\s*p(\d+),\s*([a-f0-9]+)\]/gm; //ex: [Jane Smith MPI Spect.pdf, p1, 24c65952]
const reviewNoteAttributionIdRegex = /(.*?),\s*[\da-fA-F]{8}\]$/; // ex: 24c65952] from the above text including the ']'

/*
  To make the automated review note attribution info clickable from inside the RichTextEditor - this will render a link that will
  add the highlight hash to the search URI when clicked so the respective component can pick it up 
  and take the user to the highlighted section in the attachment.
*/

const AutomatedReviewNoteAttributionLinkComponent = (props: any) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const { highlightAttachmentName, highlightAttachmentHash } = props.contentState.getEntity(props.entityKey).getData();

  const url = new URL(`${location.pathname}${location.search}`, window.location.origin);
  const searchParams = url.searchParams;

  searchParams.set("highlightAttachmentName", highlightAttachmentName);
  searchParams.set("highlightAttachmentHash", highlightAttachmentHash);

  const newPath = `${location.pathname}?${searchParams.toString()}${location.hash || ""}`;

  return (
    <a
      href={newPath}
      onClick={() => {
        navigate(newPath);
      }}
      rel="noopener noreferrer"
      className={classes.highlightLink}
    >
      {props.children}
    </a>
  );
};

const automatedReviewNoteAttributionlinkStrategy = (contentBlock: any, callback: any, contentState: any) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === "CUSTOM_LINK";
  }, callback);
};

const convertHtmlToRichTextEditorState = (html: any) => {
  const blocksFromHTML = convertFromHTML(html);
  const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

  const contentStateWithEntities = contentState.getBlockMap().reduce((updatedContentState: any, block: any) => {
    let content = updatedContentState;
    const text = block.getText();
    const blockKey = block.getKey();

    // To replicate the functionality of getTextAlignBlockMetadata
    let textAlignMatch;
    while ((textAlignMatch = textAlignRegex.exec(text)) !== null) {
      const alignment = textAlignMatch[1]; // e.g., 'center', 'right'
      content = content.setIn([blockKey, "data"], content.getIn([blockKey, "data"]).merge({ "text-align": alignment }));
    }

    /*
      This will pick up the automated review note attribution info and create an entity 
      based on it so that we can create a clickable link to highlight this info in the attachments.
      The regex matches this format: [Jane Smith MPI Spect.pdf, p1, 24c65952]
    */
    let match;
    while ((match = reviewNoteAttributionInfoRegex.exec(text)) !== null) {
      const [matchedText, a, , id] = match;
      const start = match.index;
      const end = start + matchedText.length;

      /* 
        After getting all the info from the text, we remove the last part that represents the highlight id
        so the user does not see it, and the displayed text becomes something like this [Jane Smith MPI Spect.pdf, p1]
      */
      const modifiedText = matchedText.replace(reviewNoteAttributionIdRegex, "$1]");

      const contentStateWithNewText = Modifier.replaceText(
        content,
        SelectionState.createEmpty(blockKey).merge({
          anchorOffset: start,
          focusOffset: end,
        }),
        modifiedText
      );

      const selectionState = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: start,
        focusOffset: start + modifiedText.length,
      });

      const contentStateWithEntity = contentStateWithNewText.createEntity("CUSTOM_LINK", "MUTABLE", {
        highlightAttachmentName: a,
        highlightAttachmentHash: id,
      });

      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      content = Modifier.applyEntity(contentStateWithEntity, selectionState, entityKey);
    }

    return content;
  }, contentState);

  return EditorState.createWithContent(
    contentStateWithEntities,
    new CompositeDecorator([
      {
        strategy: automatedReviewNoteAttributionlinkStrategy,
        component: AutomatedReviewNoteAttributionLinkComponent,
      },
    ])
  );
};

const useStyles = makeStyles(() => ({
  highlightLink: {
    color: colorsLight.primary.main,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export { convertHtmlToRichTextEditorState, AutomatedReviewNoteAttributionLinkComponent };
