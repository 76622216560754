import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import AttachmentReadOnly from "./AttachmentReadOnly";
import AttachmentEdit from "./AttachmentEdit";
import { SRFormViewState } from "../PatientSummary/ServiceRequestSummaryCard/ServiceRequestFormSection";
import {
  AuthorizationResponse,
  ReferralRequestResponse,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";

interface Props {
  authorization?: AuthorizationResponse;
  serviceRequest?: ServiceRequestResponse;
  setServiceRequest?: Dispatch<SetStateAction<ServiceRequestResponse>>;
  referralRequest?: ReferralRequestResponse;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEdit?: Function;
  allowMultipleAttachmentFiles?: boolean;
  fileSizeLimit?: number;
  onSaveAttachments?: () => void;
  singleAttachmentViewerRedesign?: boolean;
}

export default function AttachmentSection({
  authorization,
  serviceRequest,
  setServiceRequest,
  referralRequest,
  onEdit,
  allowMultipleAttachmentFiles,
  fileSizeLimit,
  onSaveAttachments,
  singleAttachmentViewerRedesign,
}: Props) {
  const [viewState, setViewState] = useState<SRFormViewState>("READ_ONLY");

  const handleAttachmentUpdate = useCallback(() => {
    onEdit?.();
  }, [onEdit]);

  return viewState === "READ_ONLY" ? (
    <AttachmentReadOnly
      authorization={authorization}
      serviceRequest={serviceRequest}
      referralRequest={referralRequest}
      setViewState={setViewState}
      singleAttachmentViewerRedesign={singleAttachmentViewerRedesign}
    />
  ) : (
    <AttachmentEdit
      authorization={authorization}
      serviceRequest={serviceRequest}
      setServiceRequest={setServiceRequest}
      referralRequest={referralRequest}
      setViewState={setViewState}
      onUpdateAttachments={handleAttachmentUpdate}
      allowMultipleAttachmentFiles={allowMultipleAttachmentFiles}
      fileSizeLimit={fileSizeLimit}
      onSaveAttachments={onSaveAttachments}
    />
  );
}
