import { colorsLight } from "@coherehealth/common";
import { makeStyles, Theme } from "@material-ui/core";

export interface ChatbotStylesI {
  displayFeedBackOptions?: boolean;
  isExpanded?: boolean;
}
export const useChatbotStyles = makeStyles<Theme, ChatbotStylesI>((theme) => ({
  icon: {
    color: theme.palette.action.disabledBackground,
    transition: "color 0.3s ease",
    "&:hover": {
      color: theme.palette.text.tertiary,
    },
    display: "flex",
    alignItems: "center",
    gap: 4,
    cursor: "pointer",
  },
  feedbackMechnaismContainer: {
    display: "flex",
    alignItems: "flex-start",
    alignContent: "flex-start",
    gap: 8,
    alignSelf: "stretch",
    flexWrap: "wrap",
    paddingTop: 8,
    paddingBottom: 0,
  },
  unsatifactoryOptsContainer: { display: "flex", justifyContent: "center", alignItems: "flex-start", gap: 8 },
  thumbsContainer: {
    display: "flex",
    width: 40,
    alignItems: "center",
    gap: 4,
    flexShrink: 0,
  },
  activeThumbDown: {
    color: theme.palette.text.tertiary,
  },
  activeIcon: {
    color: theme.palette.text.tertiary,
  },
  activeLookIcon: {
    color: theme.palette.text.tertiary,
  },
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 156px)",
  },
  reviewAssistantWrapper: {
    padding: "16px 24px 16px 24px",
    flex: "1 1 0",
    background: "#FCFCFC",
    overflowY: "auto",
  },
  reviewAssistantWrapperInner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    flex: "0 0 89%",
    padding: "0px",
  },
  textFieldInput: {
    padding: "18px 12px 18px 12px !important",
  },
  innerControlBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
  },
  buttonWrapper: {
    paddingRight: theme.spacing(1),
  },
  button: {
    padding: theme.spacing(1),
  },
  outerControlBar: {
    display: "flex",
    minHeight: 72,
    padding: "8px 24px 8px 24px",
    justifyContent: "flex-start",
    alignItems: "center",
    boxShadow: "0px -5px 10px -5px rgba(0, 0, 0, 0.2)",
    bottom: 0,
    marginLeft: 0,
    marginRight: -16,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1000,
  },
  title: {
    color: theme.palette.text.tertiary,
    marginBottom: theme.spacing(1.25),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  seededContentWrapper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: "1px solid var(--Backgrounds-Input-Divider, #E5E5E5)",
  },

  outgoingMessageWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  incomingMessageWrapper: {
    display: "flex",
    justifyContent: "flex-start",
  },
  outgoingMessage: {
    backgroundColor: "#F3F3F3",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    border: "1px solid var(--Backgrounds-Input-Divider, #E5E5E5)",
    maxWidth: "85%",
  },
  incomingMessage: {
    backgroundColor: theme.palette.background.paper,
    padding: "16px 16px 16px 16px",
    borderRadius: theme.spacing(1),
    border: "1px solid var(--Backgrounds-Input-Divider, #E5E5E5)",
    maxWidth: "85%",
  },
  guardrailsResponseContainer: {
    marginTop: theme.spacing(1),
  },
  guardrailsResponseText: {
    margin: theme.spacing(2, 5),
    textAlign: "center",
    color: "#5E6572",
  },
  incomingMessageParagraph: {
    margin: 0,
    "& p": {
      margin: 0,
    },
  },
  sourcesWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    padding: "16px 0px 8px 0px",
  },
  sourcesTitle: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "15px",
    letterSpacing: "0.25px",
    color: "#DE7800",
  },
  sourceItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 4,
    backgroundColor: "#FFDFA1",
    cursor: "pointer",
    borderRadius: "4px",
    "&:hover": {
      background: "#FFDFA180",
    },
    padding: theme.spacing(0.5, 0.5, 0.5, 1),
  },
  sourceActionIcon: {
    height: "18px !important",
    width: "18px !important",
    color: "#DE780080",
  },
  sourceItemText: {
    color: colorsLight.font.primary,
    fontWeight: 500,
  },
}));
