import { TextField } from "@coherehealth/common";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { RIGHT_HAND_PANEL_SIZE } from "components/ClinicalReview/reviewUtils/utils";
import { IconButton } from "components/Requestor/common";
import { useChatbotStyles } from "./chatbotReviewAssistantStyles";
import { Dispatch, SetStateAction } from "react";

interface Props {
  prompt: string;
  setPrompt: Dispatch<SetStateAction<string>>;
  handleSubmission: () => void;
}

export default function ChatbotReviewAssistantControlBar({ prompt, setPrompt, handleSubmission }: Props) {
  const classes = useChatbotStyles({});
  const theme = useTheme();
  const hasRightPanelSpace = useMediaQuery(theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE));
  return (
    <div className={classes.outerControlBar} style={{ width: hasRightPanelSpace ? 712 : "calc(100vw - 728px)" }}>
      <div className={classes.innerControlBar}>
        <TextField
          multiline
          minRows={1}
          maxRows={8}
          className={classes.textField}
          placeholder="Ask the review chatbot to help find or summarize information"
          value={prompt}
          onChangeValue={setPrompt}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmission();
            }
          }}
          InputProps={{
            className: classes.textFieldInput,
          }}
        />
        <div className={classes.buttonWrapper}>
          <IconButton onClick={handleSubmission} className={classes.button}>
            <Send />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
