import { Body1 } from "@coherehealth/design-system";
import { Box, CircularProgress } from "@material-ui/core";
import { Grid } from "@mui/material";
import ClinicalReviewCard from "common/ClinicalReviewCard";
import { incrementClickCount } from "@coherehealth/common";
import { useState } from "react";
import { NotesProps } from "./notesCardTypes";
import { NotesDisplay } from "./NotesDisplay/NotesDisplay";
import { NotesCardHeader } from "./NotesCardHeader";
import { Note } from "@coherehealth/core-platform-api";

const NotesCard = ({
  notes,
  existingReviews,
  notesLoading,
  serviceRequest,
  setUserClickInfoTracking,
  noteConfig,
}: NotesProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const updateUserClickInfo = () => {
    setUserClickInfoTracking && incrementClickCount("NotesCardToggle", "", -1, setUserClickInfoTracking);
  };
  const reviewsWithCPN = existingReviews?.filter((r) => r.claimsPaymentNote && r.reviewStatus === "COMPLETE");
  const reviewClaimsPaymentNotes: Note[] | undefined =
    reviewsWithCPN?.map((e) => {
      return {
        id: e.id,
        serviceRequest: { id: e.serviceRequest?.id },
        dateCreated: e.dateCompleted,
        createdByName: e.reviewerDetails?.reviewerName || e.completedByName,
        claimsPaymentNote: e.claimsPaymentNote,
        healthPlanName: e.healthPlanName,
        noteType: "CLAIMS_PAYMENT",
      };
    }) || [];
  const claimsPaymentNoteType = notes?.filter((note) => note.noteType === "CLAIMS_PAYMENT") || [];
  const claimsPaymentNoteData = [...reviewClaimsPaymentNotes, ...claimsPaymentNoteType];
  const claimsPaymentNoteLength = claimsPaymentNoteData?.length !== 0 ? claimsPaymentNoteData?.length : 0;

  return (
    <ClinicalReviewCard
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      header={
        <NotesCardHeader
          notes={notes}
          notesLoading={notesLoading}
          existingClaimsPaymentNoteData={claimsPaymentNoteData}
          view="NOTES"
          serviceRequest={serviceRequest}
        />
      }
      updateUserClickInfo={updateUserClickInfo}
      autoScroll
    >
      <Box display="flex" flexDirection="column">
        <NotesDisplay
          notes={notes}
          notesLoading={notesLoading}
          serviceRequest={serviceRequest}
          reviewClaimsPaymentNotes={reviewClaimsPaymentNotes}
          view="NOTES"
          noteConfig={noteConfig}
        />
        {notes?.length === 0 && claimsPaymentNoteLength === 0 && (
          <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 20, marginBottom: 20 }}>
            {notesLoading ? (
              <CircularProgress />
            ) : (
              <Body1 sx={{ color: "text.secondary" }}>There are no notes to display.</Body1>
            )}
          </Grid>
        )}
      </Box>
    </ClinicalReviewCard>
  );
};

export default NotesCard;
