import { ServiceRequestCreatePayload, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { useState } from "react";

export const hasNetworkStatusChanged = (
  formContent: ServiceRequestFormContent,
  serviceRequest: ServiceRequestResponse
) => {
  if (
    formContent.selectedOrderingProvider?.selectedLocation?.networkType !==
    serviceRequest.selectedOrderingProvider?.selectedLocation?.networkType
  ) {
    return true;
  }
  if (
    formContent.selectedPerformingProvider?.selectedLocation?.networkType !==
    serviceRequest.selectedPerformingProvider?.selectedLocation?.networkType
  ) {
    return true;
  }
  if (
    formContent.selectedFacility?.selectedLocation?.networkType !==
    serviceRequest.selectedFacility?.selectedLocation?.networkType
  ) {
    return true;
  }
  return false;
};

export const useNetworkStatusTransitionModal = ({ handleSaveOperation, serviceRequest }: any) => {
  const [openNetworkStatusTransitionModal, setOpenNetworkStatusTransitionModal] = useState<
    (() => Promise<void>) | null
  >(null);

  const handleClickSaveButton = async (sr: ServiceRequestCreatePayload, formContent: ServiceRequestFormContent) => {
    if (hasNetworkStatusChanged(formContent, serviceRequest)) {
      setOpenNetworkStatusTransitionModal(() => async () => await handleSaveOperation(sr, formContent));
      return;
    }
    await handleSaveOperation(sr, formContent);
  };

  return { openNetworkStatusTransitionModal, setOpenNetworkStatusTransitionModal, handleClickSaveButton };
};
