import { useConfiguration, useFeature } from "@coherehealth/common";
import type { AuthorizationResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";

type Authorization = Partial<Pick<AuthorizationResponse, "encounterType" | "healthPlanName">>;
type ServiceRequest = Partial<
  Pick<ServiceRequestResponse, "encounterType" | "healthPlanName"> & {
    delegatedVendor: string;
  }
>;

interface UsePatientStayTimelineEnabledProps {
  authorization: Authorization | null | undefined;
  serviceRequest: ServiceRequest | null | undefined;
}

export const usePatientStayTimelineEnabled = ({
  authorization,
  serviceRequest,
}: UsePatientStayTimelineEnabledProps) => {
  const healthPlanName = serviceRequest?.healthPlanName;
  const delegatedVendor = serviceRequest?.delegatedVendor;
  const encounterType = serviceRequest?.encounterType || authorization?.encounterType;

  const facilityBasedConfigEnabled = useConfiguration(
    "facilityBasedRequestConfiguration",
    healthPlanName,
    delegatedVendor
  );

  const isFeatureEnabled = useFeature("showPatientStayTimeline");
  const isInpatient = encounterType === "INPATIENT";
  const isFacilityBasedConfigEnabled = facilityBasedConfigEnabled?.enabled;

  if (!healthPlanName || !delegatedVendor || !encounterType) {
    return false;
  }

  return isFeatureEnabled && isInpatient && isFacilityBasedConfigEnabled;
};
