import {
  FindingRecordPostRequest,
  ReviewChatbotFinding,
  ReviewChatbotFindingSource,
} from "@coherehealth/core-platform-api";

export const HELPFULNESS_FEEDBACK_COMMENTS_SEPERATOR = ";;;;";
export const ML_REVIEW_CHATBOT_FINDING_TYPE_NAME = "MlReviewChatbotFinding";

function mapHelpfulnessFeedbackComments(helpfulnessFeedbackComments?: string[]): string | undefined {
  const helpfulnessFeedbackCommentsList: string[] = helpfulnessFeedbackComments || [];

  return helpfulnessFeedbackCommentsList.length > 0
    ? helpfulnessFeedbackCommentsList.join(HELPFULNESS_FEEDBACK_COMMENTS_SEPERATOR)
    : undefined;
}

export function mapChatbotFindingToFindingRecordRequest(
  chatbotFinding: ReviewChatbotFinding
): FindingRecordPostRequest {
  const helpfulnessFeedbackComments: string | undefined = mapHelpfulnessFeedbackComments(
    chatbotFinding.helpfulnessFeedbackComments
  );
  const sources: string | undefined = chatbotFinding.sources?.length
    ? JSON.stringify(chatbotFinding.sources)
    : undefined;

  return {
    findingTypeName: ML_REVIEW_CHATBOT_FINDING_TYPE_NAME,
    findingRecordId: chatbotFinding.findingRecordId,
    findingInstant: chatbotFinding.findingInstant!,
    cohereEntityId: chatbotFinding.serviceRequestId!,
    value: chatbotFinding.value!,
    findingTypeExtensions: {
      displayedQuestion: chatbotFinding.displayedQuestion,
      modelQuestion: chatbotFinding.modelQuestion,
      sources,
      manuallyInputPrompt: chatbotFinding.manuallyInputPrompt,
      reviewId: chatbotFinding.reviewId,
      helpfulnessFeedback: chatbotFinding.helpfulnessFeedback,
      helpfulnessFeedbackComments,
    },
  };
}

export interface IReviewChatbotSource {
  attachmentName: string | null;
  attachmentId: string;
  page: number;
}

export function getEligibleReviewChatbotSources(
  sources: ReviewChatbotFindingSource[] | undefined,
  getAttachmentNameById: (attachmentId: string) => string | null
): IReviewChatbotSource[] {
  if (!sources) {
    return [];
  }

  const eligibleSources: IReviewChatbotSource[] = [];

  for (const source of sources) {
    const isValid = source.attachment_id && Boolean(source.page);
    if (!isValid) {
      continue;
    }

    const hasSource: boolean = eligibleSources.some(
      (el) => el.attachmentId === source.attachment_id && el.page === source.page
    );

    if (!hasSource) {
      eligibleSources.push({
        attachmentName: getAttachmentNameById(source.attachment_id!) ?? null,
        attachmentId: source.attachment_id!,
        page: source.page!,
      });
    }
  }
  return eligibleSources;
}
