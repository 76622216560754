import { ServiceDeterminationProcedureCodeBucket } from "@coherehealth/core-platform-api";
import { PriorAuthRequirements } from "../common";

const groupedPalProcedureCodesCount = (buckets: ServiceDeterminationProcedureCodeBucket[]) => {
  return buckets.reduce(
    (accumulator, bucket) => accumulator + (bucket?.procedureCodes ? bucket.procedureCodes.length : 0),
    0
  );
};

//this calculation is so that we can make sure that all desired proceedure codes have been given a bucket
export const doesNotAccountForProcedureCodes = (
  buckets: ServiceDeterminationProcedureCodeBucket[],
  priorAuthRequirements: PriorAuthRequirements
) => {
  return (
    (priorAuthRequirements?.desiredProcedureCodes ? priorAuthRequirements.desiredProcedureCodes.length : 0) >
    groupedPalProcedureCodesCount(buckets)
  );
};
