import { H4, SecondaryButton, Subtitle2 } from "@coherehealth/common";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { makeStyles } from "@material-ui/core";

interface NoResultsProps {
  title: string;
  canAddPlaceholderMember?: boolean;
  temporaryMemberAuthsEnabled?: boolean;
  createFaxNotice: () => void;
  hideResults: boolean;
}

const useStyles = makeStyles((theme) => ({
  patientListNoResults: {
    paddingBottom: theme.spacing(4),
  },
}));

export default function NoFaxLookupResultsCard({
  title,
  canAddPlaceholderMember,
  temporaryMemberAuthsEnabled,
  createFaxNotice,
  hideResults,
}: NoResultsProps) {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.patientListNoResults}>
      <Grid item xs={8}>
        <H4>{title}</H4>
      </Grid>
      {canAddPlaceholderMember && temporaryMemberAuthsEnabled ? (
        <>
          <Grid item xs={12}>
            <Subtitle2>
              Check your information and search again, or create a fax notice, or add a patient with a temporary ID
            </Subtitle2>
          </Grid>
          <Grid item xs={12}>
            <SecondaryButton
              startIcon={<OpenInNewIcon />}
              fullWidth
              component={Link}
              to="/add_new_patient"
              target={"_blank"}
            >
              Add patient with a temporary ID
            </SecondaryButton>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Subtitle2>Check your information and search again, or send a fax notice back to the provider</Subtitle2>
          </Grid>
          <Grid item xs={12}>
            <SecondaryButton fullWidth onClick={createFaxNotice} disabled={hideResults}>
              Create fax notice
            </SecondaryButton>
          </Grid>
        </>
      )}
    </Grid>
  );
}
