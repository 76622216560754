import { Chip, colorsLight } from "@coherehealth/common";
import { ReviewChatbotFinding } from "@coherehealth/core-platform-api";
import { useTheme } from "@material-ui/core";
import { useChatbotStyles } from "./chatbotReviewAssistantStyles";

interface Props {
  chatbotFinding: ReviewChatbotFinding;
  isExpanded: boolean;
  setIsExpanded: () => void;
}

export default function ChatbotPromptLabeler({ chatbotFinding, isExpanded, setIsExpanded }: Props) {
  const classes = useChatbotStyles({ isExpanded });
  const theme = useTheme();

  return (
    <Chip
      label={chatbotFinding.displayedQuestion || ""}
      onClick={setIsExpanded}
      size="small"
      className={classes.chip}
      customLabelColor={isExpanded ? theme.palette.primary.dark : colorsLight.font.secondary}
      customBackgroundColor={isExpanded ? colorsLight.primary.faint20 : theme.palette.table.panelBackground}
      customHoverColor="#E5E5E5"
    />
  );
}
