import { Body1, Checkbox, colorsLight, Tooltip } from "@coherehealth/common";
import { Grid, makeStyles } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

interface CareRecommendationSectionProps {
  setIsCareRecommendationPatient: React.Dispatch<React.SetStateAction<boolean>>;
  isCareRecommendationPatient: boolean;
}

export function CareRecommendationSection(props: CareRecommendationSectionProps): JSX.Element {
  const { setIsCareRecommendationPatient, isCareRecommendationPatient } = props;
  return (
    <Grid item xs={12} style={{ paddingBottom: 0, paddingTop: 4 }}>
      <Checkbox
        label={<CareRecommendationLabel />}
        checked={isCareRecommendationPatient}
        onChange={() => setIsCareRecommendationPatient((prevState) => !prevState)}
      />
    </Grid>
  );
}

function CareRecommendationLabel(): JSX.Element {
  const classes = useStyles();
  return (
    <Body1 color={"textSecondary"} className={classes.infoLabel}>
      This member is a care recommendation patient
      <Tooltip
        title={"Care recommendation patients require fewer fields, but don't support letters and claims."}
        placement="top"
      >
        <InfoOutlinedIcon className={classes.infoIcon} />
      </Tooltip>
    </Body1>
  );
}

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    color: colorsLight.font.light,
    height: theme.spacing(2),
    width: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
  infoLabel: {
    display: "flex",
    alignItems: "center",
  },
}));
