import { Checkbox, InformativeModal, SingleSelectDropdown, TextField } from "@coherehealth/common";
import { GetAllowedPendingReasonsResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Grid } from "@material-ui/core";
import { IntegrationStatuses } from "components/IntegrationManagement/ServiceRequestIntegrationSummary/EditIntegrationStatus";
import { ModalManagerProps, ReviewCorrectionPayload } from "./hooks/useReviewCorrection";
import { UseGetProps } from "restful-react";
import { NextReviewDateSelect } from "../common/NextReviewDateSelect";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import InpatientNextReviewDateCollection from "../AuditReview/InpatientNextReviewDateCollection";
import { Dispatch, SetStateAction } from "react";

type ReviewCorrectionModalProps = {
  open: boolean;
  onClose: () => void;
  primaryButtonDisabled: boolean;
  reviewCorrectionPayload: ReviewCorrectionPayload;
  setModalManager: React.Dispatch<React.SetStateAction<ModalManagerProps>>;
  setReviewCorrectionPayload: React.Dispatch<React.SetStateAction<ReviewCorrectionPayload>>;
  refetchPendingReasons: (
    options?: Partial<Omit<UseGetProps<GetAllowedPendingReasonsResponse, unknown, void, unknown>, "lazy">> | undefined
  ) => Promise<any>;
  correctionActionOptions: { id: string; label: string }[];
  pendingReasonOptions: { id: string; label: string }[];
  serviceRequest: ServiceRequestResponse;
  nextReviewDate: Date | null;
  setNextReviewDate: Dispatch<SetStateAction<Date | null>>;
  nextReviewDateAckknowledged: boolean;
  setNextReviewDateAcknowledged: Dispatch<SetStateAction<boolean>>;
};

const allowedAuthStatuses = [
  { id: "APPROVED", label: "Approved" },
  { id: "PARTIALLY_APPROVED", label: "Partially approved" },
  { id: "DENIED", label: "Denied" },
];

const getMatrixIntegrationStatusOptions = () => {
  return Object.entries(IntegrationStatuses).map(([key, value]) => ({
    id: key,
    label: value,
  }));
};

export const ReviewCorrectionModal = ({
  open,
  onClose,
  primaryButtonDisabled,
  reviewCorrectionPayload,
  setModalManager,
  refetchPendingReasons,
  correctionActionOptions,
  pendingReasonOptions,
  setReviewCorrectionPayload,
  serviceRequest,
  nextReviewDate,
  setNextReviewDate,
  nextReviewDateAckknowledged,
  setNextReviewDateAcknowledged,
}: ReviewCorrectionModalProps) => {
  const handleReviewCorrectionsButtonClick = () => {
    setModalManager((prev) => ({ ...prev, openReviewCorrectionModal: false, openConfirmationModal: true }));
  };
  const handleChangeCorrectionAction = (actionType: "MOVE_FORWARD" | "MOVE_BACKWARD") => {
    if (actionType === "MOVE_BACKWARD") {
      refetchPendingReasons();
    }
    setReviewCorrectionPayload((prev) => ({ ...prev, actionType }));
  };
  const { healthPlanName, encounterType, requestTiming, delegatedVendor } = serviceRequest;
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName,
    encounterType,
    requestTiming,
    skipRequestTimingCheck: true,
    delegatedVendorName: delegatedVendor,
    srContent: serviceRequest,
  });
  const isNrdShown =
    facilityBasedFeatureEnabled &&
    ["CURRENTLY_ADMITTED", "NOT_YET_ADMITTED"].includes(serviceRequest.patientStatus as string);
  const isNrdRequired = isNrdShown && serviceRequest.patientStatus === "CURRENTLY_ADMITTED";
  const getActionTypeCaption = () => {
    if (reviewCorrectionPayload.actionType === "MOVE_FORWARD") {
      return "This will progress the review to a determined status, bypassing any letter automation.";
    } else if (reviewCorrectionPayload.actionType === "MOVE_BACKWARD") {
      return "This will reverse the workflow and return the review to a pending status.";
    } else {
      return "Mail and/or fax (behavioral health only) notifications will be sent when reviews enter any peer-to-peer status.";
    }
  };
  return (
    <InformativeModal
      open={open}
      onClose={onClose}
      headerText="Review correction"
      primaryButtonDisabled={primaryButtonDisabled}
      primaryButtonAction={handleReviewCorrectionsButtonClick}
      inlineButtonText="Cancel"
      inlineButtonAction={onClose}
      additionalInfoElement={
        <Grid
          style={{
            paddingTop: 32,
            paddingBottom: 16,
            width: "100%",
            gap: 16,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <>
            <SingleSelectDropdown
              fullWidth
              required
              helperText={
                reviewCorrectionPayload.actionType ? (
                  <span style={{ color: "#6F7580" }}>{getActionTypeCaption()}</span>
                ) : (
                  ""
                )
              }
              label="Correction type"
              value={reviewCorrectionPayload.actionType}
              options={correctionActionOptions}
              onChange={(actionType: string) =>
                handleChangeCorrectionAction(actionType as "MOVE_FORWARD" | "MOVE_BACKWARD")
              }
            />
            {reviewCorrectionPayload.actionType === "MOVE_FORWARD" && (
              <>
                <SingleSelectDropdown
                  fullWidth
                  required
                  label="Auth status"
                  value={reviewCorrectionPayload.authStatus || ""}
                  options={allowedAuthStatuses}
                  onChange={(authStatus: string) => setReviewCorrectionPayload((prev) => ({ ...prev, authStatus }))}
                />
                <SingleSelectDropdown
                  fullWidth
                  label="Matrix integration status"
                  value={reviewCorrectionPayload.integrationMatrixStatus || ""}
                  options={getMatrixIntegrationStatusOptions()}
                  onChange={(matrixIntegrationStatus: string) =>
                    setReviewCorrectionPayload((prev) => ({ ...prev, matrixIntegrationStatus }))
                  }
                />
                {isNrdShown && !isNrdRequired && (
                  <InpatientNextReviewDateCollection
                    nrdAcknowledged={nextReviewDateAckknowledged}
                    setNrdAcknowledged={setNextReviewDateAcknowledged}
                    nextReviewDate={nextReviewDate}
                    setNextReviewDate={setNextReviewDate}
                  />
                )}
                {isNrdRequired && (
                  <>
                    <Checkbox label={"Continued stay must be reviewed"} checked disabled onChange={(checked) => {}} />
                    <NextReviewDateSelect nextReviewDate={nextReviewDate} setNextReviewDate={setNextReviewDate} />
                  </>
                )}
              </>
            )}
            {reviewCorrectionPayload.actionType === "MOVE_BACKWARD" && (
              <SingleSelectDropdown
                fullWidth
                label="Pending reason"
                required
                value={reviewCorrectionPayload.pendingReason || ""}
                options={pendingReasonOptions}
                onChange={(pendingReason: string) => setReviewCorrectionPayload((prev) => ({ ...prev, pendingReason }))}
              />
            )}
            <TextField
              label="Correction reason"
              fullWidth
              required
              multiline
              data-testid="correction-reason-text-field"
              minRows={4}
              value={reviewCorrectionPayload.correctionReason || ""}
              onChangeValue={(correctionReason: string) =>
                setReviewCorrectionPayload((prev) => ({ ...prev, correctionReason }))
              }
            />
          </>
        </Grid>
      }
      showDivider={false}
    />
  );
};
