import { ComponentProps, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Checkbox, H2, Modal, PrimaryButton, SingleSelectDropdown, TextField, colorsLight } from "@coherehealth/common";
import { MdReview, NurseReview, PeerToPeerReview, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { SaveReviewDeterminationUpdatesProps } from "../MDReview/DenialLetterContent";

type ReviewOutcome = (MdReview | PeerToPeerReview | NurseReview)["reviewOutcome"] | "CANNOT_WORK";

export type ReviewReasonOutcomeOption = {
  id: string;
  label: string;
  subLabel?: string;
};

interface OutcomeContextCollectionModalProps {
  isOpen: boolean;
  setIsOpen: (nextValue: boolean) => void;
  review?: MdReview | PeerToPeerReview | NurseReview | null;
  serviceRequest: ServiceRequestResponse | null;
  getServiceRequestOnClick?: () => void;
  saveReviewDeterminationUpdates: (
    saveReviewDeterminationUpdatesProps: SaveReviewDeterminationUpdatesProps
  ) => Promise<void>;
}

export default function OutcomeContextCollectionModal({
  isOpen,
  setIsOpen,
  review,
  serviceRequest,
  getServiceRequestOnClick = () => {},
  saveReviewDeterminationUpdates,
}: OutcomeContextCollectionModalProps) {
  const [denialReason, setDenialReason] = useState<string | undefined>(review?.serviceStatusCode);
  const [claimsPaymentNote, setClaimsPaymentNote] = useState<string>(review?.claimsPaymentNote || "");
  const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);
  const [sendOptionalDecisionLetter, setSendOptionalDecisionLetters] = useState<boolean>(false);

  const submissionDisabled = (): boolean => {
    return clickedSubmit || !denialReason;
  };

  const handleSubmit = async () => {
    setClickedSubmit(true);
    if (review?.id && review?.serviceRequest && denialReason) {
      await saveReviewDeterminationUpdates({
        stayOnPage: true, // we will be opening preview modal after this so need to keep on the same page
        serviceStatusCode: denialReason,
        claimsPaymentNote,
        additionalRecipients: sendOptionalDecisionLetter ? ["PROVIDER"] : [],
      });
    }

    setClickedSubmit(false);
    setIsOpen(false);
    getServiceRequestOnClick();
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Grid container spacing={5} component="form" onSubmit={handleSubmit}>
          <Row>
            <H2>Add outcome details</H2>
          </Row>
          <Row>
            <TextField
              label="Outcome"
              value={outcomeLabelMappings.filter((opt) => opt.id === review?.reviewOutcome).at(0)?.label}
              InputProps={{ readOnly: true, disableUnderline: true }}
              variant="standard"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Row>
          <Row>
            <SingleSelectDropdown
              fullWidth
              label="Denial reason"
              options={denialReasonOptions}
              value={denialReason}
              onChange={setDenialReason}
              maxMenuHeight={200}
              menuWidth={470}
            />
          </Row>
          <Row>
            <TextField
              label={"Claims payment notes"}
              fullWidth
              hiddenLabel
              multiline
              value={claimsPaymentNote}
              onChangeValue={setClaimsPaymentNote}
            />
          </Row>
          {serviceRequest?.isMemberRequestedAuth && (
            <Row>
              <Checkbox
                onChange={() => setSendOptionalDecisionLetters?.(!sendOptionalDecisionLetter)}
                label={
                  <span
                    style={{
                      fontSize: "15px",
                      color: colorsLight.font.secondary,
                      textAlign: "left",
                    }}
                  >
                    Send decision notification to the provider, in addition to the member who submitted the request
                  </span>
                }
              />
            </Row>
          )}
          <Row>
            <PrimaryButton
              data-testid="Review modal submission button"
              loading={clickedSubmit}
              disabled={submissionDisabled()}
              onClick={handleSubmit}
            >
              Preview determination letter
            </PrimaryButton>
          </Row>
        </Grid>
      </Modal>
    </>
  );
}

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;
interface OutcomeLabelMapping {
  id: NonNullable<ReviewOutcome>;
  label: string;
}

const outcomeLabelMappings: OutcomeLabelMapping[] = [
  { id: "RECOMMENDED_DENIAL", label: "Recommended denial" },
  { id: "RECOMMENDED_PARTIAL_APPROVAL", label: "Recommended partial approval" },
  { id: "ADMINISTRATIVE_DENIAL", label: "Administrative denial" },
];

// Used in NurseReviewSubmissionModal for "Deny without pending to MD"
// until this Modal is extended to support RN reviews.
export const denialReasonOptions: ReviewReasonOutcomeOption[] = [
  {
    id: "8M00",
    label: "Not medically necessary",
    subLabel: "8M00",
  },
  {
    id: "DN00",
    label: "Not a covered benefit",
    subLabel: "DN00",
  },
];
