import { Chip, InputAdornment, ListSubheader, makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { colorsDark } from "../../themes";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";
import { Caption } from "../Typography";
import SearchIcon from "@material-ui/icons/Search";
import { useStylesLookupEndAdornment } from "./commonAutocomplete";
import { TextField } from "../TextField";
import { CohereMenuItem } from "../../components/Dropdown/CohereMenuItem";

export interface Option {
  id: string;
  label: string;
}

type AutocompleteWithCheckboxesProps = {
  options: Option[];
  setQuery: any;
  loading?: boolean;
  selectedOptions: Option[];
  setSelectedOptions: any;
  showError?: boolean;
  errorText?: string;
  label?: string;
};

const useStyles = makeStyles((theme) => ({
  chip: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    backgroundColor: "#039EC314", // Blue background for the chip
    color: theme.palette.primary.main, // Text color for contrast
    maxWidth: theme.spacing(30),
  },
  clearIcon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    color: colorsDark.gray.light,
  },
  listSubheader: {
    marginTop: theme.spacing(0.25),
    height: theme.spacing(6),
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dropdown: {
    maxHeight: theme.spacing(30),
    overflowY: "auto",
    backgroundColor: "#191F29",
    width: "inherit",
  },
  menuItem: {
    marginBottom: theme.spacing(1),
    width: "100%",
    alignItems: "center",
    backgroundColor: "inherit",
    height: "100%",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  textField: {
    "& .MuiInputAdornment-root": {
      marginTop: "0", // Align with the label at the top
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    "& .MuiOutlinedInput-root": {
      padding: 0, // Remove padding inside the input container
    },
    "& .MuiInputBase-input": {
      width: "100%", // Make the input text span the entire width
    },
  },
}));

export function AutocompleteWithCheckboxes({
  options,
  setQuery,
  loading,
  selectedOptions,
  setSelectedOptions,
  showError,
  errorText,
  label,
}: AutocompleteWithCheckboxesProps): JSX.Element {
  const classes = useStyles();
  const endAdornmentClasses = useStylesLookupEndAdornment();

  const handleChange = (event: any, value: any) => {
    setSelectedOptions(value);
  };

  const unselectedOptions = options.filter((o) => !selectedOptions.includes(o));

  return (
    <Autocomplete
      multiple
      options={[...selectedOptions, ...unselectedOptions]} // Ensure selected items appear first
      getOptionLabel={(option) => option.label}
      value={selectedOptions}
      onChange={(e, v) => {
        handleChange(e, v);
      }}
      onInputChange={(event, value) => setQuery(value)} // Pass input to parent query
      renderInput={(params) => (
        <TextField
          {...params}
          error={showError}
          helperText={showError ? errorText : ""}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon className={endAdornmentClasses.largeIcon} />
              </InputAdornment>
            ),
          }}
          placeholder="search for more"
          className={classes.textField}
        />
      )}
      renderTags={(value: Option[], getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={option.id}
            label={option.label}
            {...getTagProps({ index })}
            className={classes.chip}
            deleteIcon={<ClearIcon className={classes.clearIcon} />}
          />
        ))
      }
      groupBy={(option) => (selectedOptions.includes(option) ? "Selected" : "Unselected")}
      renderGroup={(params) => {
        const { key, group, children } = params;
        return (
          <React.Fragment key={key}>
            {group === "Selected" && selectedOptions.length > 0 && (
              <ListSubheader className={classes.listSubheader} aria-disabled disableSticky>
                <Caption color="textPrimary">Selected ({selectedOptions.length})</Caption>
              </ListSubheader>
            )}
            {group === "Unselected" && unselectedOptions.length > 0 && (
              <ListSubheader className={classes.listSubheader} aria-disabled disableSticky>
                <Caption color="textPrimary">Unselected ({unselectedOptions.length})</Caption>
              </ListSubheader>
            )}
            {children}
          </React.Fragment>
        );
      }}
      renderOption={(option, { selected }) => (
        <CohereMenuItem className={classes.menuItem} key={option.id} selected={selected} value={option.id} withCheckbox>
          {option.label}
        </CohereMenuItem>
      )}
      classes={{ paper: classes.dropdown }}
      noOptionsText={!loading ? "No options available." : "Loading results..."}
    />
  );
}
