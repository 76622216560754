import {
  ProcedureCodeWithId,
  FormTable,
  Body3,
  TextField,
  Tooltip,
  Chip,
  ProcedureCodeWithAggregate,
  withAggregate,
  InlineButton,
  colorsLight,
  SingleSelectDropdown,
  MultiSelectDropdown,
  useFeature,
} from "@coherehealth/common";
import { AuthorizationResponse, AuthStatus, ProcedureCode } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Box, Grid, styled, useTheme } from "@material-ui/core";
import { NonCohereCodes } from "components/AuthBuilder/common";
import { ComponentProps, Dispatch, SetStateAction, useState } from "react";
import listRemove from "util/listRemove";
import ProcedureCodeWarning from "./ProcedureCodeWarning";
import {
  ServiceRequestFormContent,
  ContinuationFormContent,
  UnitsInitialWidthNotOnPx,
  UnitsContinuationWidth,
} from "../../../../common/SharedServiceRequestFormComponents";
import { createStyles, alpha, makeStyles, Theme } from "@material-ui/core/styles";
import { getPendingAuthStatuses, PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP, useCanEditUnits } from "util/serviceRequest";
import MuiErrorIcon from "@material-ui/icons/Error";
import DeleteIcon from "@material-ui/icons/Delete";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import usePxAttributeOptions from "hooks/usePxAttributeOptions";
import { useMediaQuery } from "@material-ui/core";
interface Props {
  formContent: ServiceRequestFormContent | ContinuationFormContent;
  setNonPalPDFOpen: Dispatch<SetStateAction<boolean>>;
  nonPalProcedureCodes: ProcedureCode[];
  nonCohereProcedureCodes: NonCohereCodes[];
  procedureCodes: ProcedureCodeWithId[];
  setProcedureCodes: Dispatch<ProcedureCodeWithId[]>;
  setProcedureCodeSelectionActive: (b: boolean) => void;
  attemptedSubmit?: boolean;
  isUnitsOnPx: boolean; //used to determine if we should show units or not
  isContinuation?: boolean;
  serviceLevelUnits?: string;
  autoFilled?: boolean;
  updatePxCodeUnits?: (rowIndex: number, units: string) => void;
  updatePxCodeSelectedDetails?: (rowIndex: number, selectedDetails: string[]) => void;
  hideHeaders?: boolean;
  noPadding?: boolean;
  onPatientSummary?: boolean;
  authorizationProcedureCodes?: ProcedureCode[];
  authStatus: AuthStatus;
  requestType?: string;
  withRowDivider?: boolean;
  setSemanticProcedureCodes?: Dispatch<SetStateAction<ProcedureCode[]>>;
  authorization?: AuthorizationResponse;
  healthPlanName: string;
  isPxCodesValid?: boolean;
  isUsedInPACheckRedesign?: boolean;
  setIsAdditionalUnitsRequested?: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
  showPxBasedAttributedField?: boolean;
  showPxAttributeValidation?: boolean;
}

export interface CssProps {
  isFaxAuthBuilderFlow?: boolean;
}

const UNITS_ON_PX_HEADERS = ["Code", "Quantity", "Description"];
const CONTINUATION_HEADERS = ["Code", "Previously approved ", "Additional requested", "Description"];
const CONTINUATION_HEADERS_PATIENT_SUMMARY = ["Code", "Previously approved ", "Description"];
const HEADERS = ["Code", "Description"];
const ADD_MORE_UNITS = "Add more units";
export default function ProcedureCodeTable({
  procedureCodes,
  setProcedureCodes,
  nonCohereProcedureCodes,
  nonPalProcedureCodes,
  formContent,
  setProcedureCodeSelectionActive,
  setNonPalPDFOpen,
  attemptedSubmit,
  isUnitsOnPx,
  isContinuation = false,
  serviceLevelUnits,
  updatePxCodeUnits,
  updatePxCodeSelectedDetails,
  autoFilled,
  hideHeaders,
  noPadding,
  onPatientSummary = false,
  authorizationProcedureCodes,
  authStatus,
  requestType,
  setSemanticProcedureCodes,
  withRowDivider = true,
  authorization,
  healthPlanName,
  isPxCodesValid,
  isUsedInPACheckRedesign,
  setIsAdditionalUnitsRequested,
  disabled,
  showPxBasedAttributedField,
  showPxAttributeValidation,
}: Props) {
  const servicesEditabilityEnabled = useFeature("servicesEditability") && onPatientSummary;
  const showAllNonPalWarning =
    !servicesEditabilityEnabled &&
    nonPalProcedureCodes.length > 0 &&
    !formContent.isInpatient &&
    !formContent.userSelectedOONException &&
    !formContent.userSelectedNonPalCode;
  const showAllNonCohereWarning = !servicesEditabilityEnabled && nonCohereProcedureCodes.length > 0;
  const theme = useTheme();
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);

  const invalidUnitsMessage = (units: number | undefined, maxUnits: number | undefined): string => {
    if (
      (Number.isNaN(Number(units)) || units === null || units === 0) &&
      attemptedSubmit &&
      !onPatientSummary &&
      !isPxCodesValid &&
      requestType === "CONTINUATION"
    ) {
      return ADD_MORE_UNITS;
    } else if (requestType !== "CONTINUATION" && !units && attemptedSubmit) {
      return ADD_MORE_UNITS;
    }
    const maxPxUnits = (maxUnits ?? 1) * Number(serviceLevelUnits);
    if (units && units > maxPxUnits && !isUnitsOnPx) {
      return `Exceeds Cohere maximum of ${maxPxUnits} ${
        Number(maxPxUnits) > 1 ? "units" : "unit"
      } over ${serviceLevelUnits} service ${Number(serviceLevelUnits) > 1 ? "dates" : "date"}`;
    }
    return "";
  };

  const inputClasses = useStylesInput();
  const classes = useStyles({ isFaxAuthBuilderFlow });
  const canEditUnits = useCanEditUnits({ authStatus });
  const { options: pxAttributeOptions } = usePxAttributeOptions(procedureCodes);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(1000));

  const units_on_px_contents = (
    id: string,
    rowIndex: number,
    code: string,
    units?: number,
    unitType?: string,
    description?: string,
    maxUnits?: number,
    attributeType?: string,
    relationshipTo?: string[],
    selectedDetails?: string[],
    isFocused?: boolean,
    setIsFocused?: Dispatch<SetStateAction<boolean>>
  ) => {
    const labelUnitType =
      unitType && PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP[unitType]
        ? `${PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP[unitType]}s`
        : "Units";

    const isAttributeType = showPxBasedAttributedField && attributeType === "Free text";

    const dropDownOptionInSortedOrder = pxAttributeOptions.get(code)?.sort((a, b) => a.id.localeCompare(b.id));

    return [
      {
        contents: (
          <>
            <Grid
              item
              container
              xs={12}
              className={!isAttributeType ? classes.tableContainer : classes.tableContainerRow}
              spacing={2}
            >
              <Grid item className={classes.ChipContainer} style={{ paddingTop: isAttributeType ? "25px" : undefined }}>
                <Chip style={{ height: theme.spacing(3) }} label={code} dataPublic={true} />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.tableFlexAuto}
                  error={Boolean(invalidUnitsMessage(units, maxUnits))}
                  type="text"
                  value={units || ""}
                  data-testid={"unit-field"}
                  inputClasses={autoFilled ? inputClasses : {}}
                  onChangeValue={function (newUnits): void {
                    if (updatePxCodeUnits !== undefined) {
                      updatePxCodeUnits(rowIndex, newUnits);
                    }
                  }}
                  label={labelUnitType}
                  disabled={!canEditUnits || disabled}
                  InputProps={{
                    endAdornment: Boolean(invalidUnitsMessage(units, maxUnits)) ? (
                      <Tooltip
                        classes={{ tooltip: classes.customWidth }}
                        title={invalidUnitsMessage(units, maxUnits)}
                        placement="top"
                      >
                        <ErrorIcon className={classes.customWidth} />
                      </Tooltip>
                    ) : undefined,
                  }}
                  inputProps={{
                    width: UnitsInitialWidthNotOnPx,
                  }}
                />
              </Grid>
              {showPxBasedAttributedField && attributeType === "Single-select" && (
                <Grid item xs={6}>
                  {/* Show SingleSelectDropdown if attributeType is "Single-select" */}
                  <SingleSelectDropdown
                    options={dropDownOptionInSortedOrder}
                    disabled={authStatus === "APPROVED" || authStatus === "PARTIALLY_APPROVED"}
                    label={
                      window.location.href.includes("fax_auth_builder")
                        ? "Select details (Optional)"
                        : isFocused && selectedDetails?.length
                        ? "Details"
                        : isFocused
                        ? "Details"
                        : !isFocused && selectedDetails?.length
                        ? "Details"
                        : "Select details"
                    }
                    onFocus={() => setIsFocused?.(true)}
                    error={
                      window.location.href.includes("fax_auth_builder")
                        ? undefined
                        : attemptedSubmit && showPxAttributeValidation && !selectedDetails?.[0]
                    }
                    helperText={
                      window.location.href.includes("fax_auth_builder")
                        ? undefined
                        : attemptedSubmit && showPxAttributeValidation && !selectedDetails?.[0]
                        ? "Required"
                        : ""
                    }
                    onBlur={() => setIsFocused?.(false)}
                    value={selectedDetails?.[0] || ""}
                    onChange={function (selectedDetails): void {
                      if (updatePxCodeSelectedDetails !== undefined) {
                        updatePxCodeSelectedDetails(rowIndex, selectedDetails ? [selectedDetails] : []);
                      }
                    }}
                  />
                </Grid>
              )}
              {showPxBasedAttributedField && attributeType === "Multi-select" && (
                <Grid item xs={6}>
                  <MultiSelectDropdown
                    options={dropDownOptionInSortedOrder}
                    selectedValues={selectedDetails}
                    onChange={function (selectedDetails): void {
                      if (updatePxCodeSelectedDetails !== undefined) {
                        updatePxCodeSelectedDetails(rowIndex, selectedDetails);
                      }
                    }}
                    disabled={authStatus === "APPROVED" || authStatus === "PARTIALLY_APPROVED"}
                    label={
                      window.location.href.includes("fax_auth_builder")
                        ? "Select details (Optional)"
                        : isFocused && selectedDetails?.length
                        ? "Details"
                        : isFocused
                        ? "Details"
                        : !isFocused && selectedDetails?.length
                        ? "Details"
                        : "Select details"
                    }
                    onFocus={() => setIsFocused?.(true)}
                    onBlur={() => setIsFocused?.(false)}
                    error={
                      window.location.href.includes("fax_auth_builder")
                        ? false
                        : attemptedSubmit && showPxAttributeValidation && !selectedDetails?.length
                    }
                    helperText={
                      window.location.href.includes("fax_auth_builder")
                        ? undefined
                        : attemptedSubmit && showPxAttributeValidation && !selectedDetails?.length
                        ? "Required"
                        : ""
                    }
                  />
                </Grid>
              )}
              {isAttributeType && (
                <Grid item xs={6}>
                  <TextField
                    {...(window.location.href.includes("fax_auth_builder") && { className: classes.pxInputFields })}
                    label={
                      window.location.href.includes("fax_auth_builder")
                        ? "Explain why an unlisted code is needed (optional)"
                        : isFocused && selectedDetails?.length
                        ? "Details"
                        : isFocused
                        ? "Details"
                        : !isFocused && selectedDetails?.length
                        ? "Details"
                        : "Explain why an unlisted code is needed"
                    }
                    fullWidth
                    disabled={authStatus === "APPROVED" || authStatus === "PARTIALLY_APPROVED"}
                    multiline
                    error={
                      window.location.href.includes("fax_auth_builder")
                        ? false
                        : attemptedSubmit && showPxAttributeValidation && !selectedDetails?.[0]
                    }
                    helperText={
                      window.location.href.includes("fax_auth_builder")
                        ? undefined
                        : attemptedSubmit && showPxAttributeValidation && !selectedDetails?.[0]
                        ? "Required"
                        : ""
                    }
                    value={selectedDetails?.[0] || ""}
                    onFocus={() => setIsFocused?.(true)}
                    onBlur={() => setIsFocused?.(false)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const newValue = event.target.value;
                      updatePxCodeSelectedDetails?.(rowIndex, newValue ? [newValue] : []);
                    }}
                  />
                </Grid>
              )}
              <Grid
                item
                className={classes.pxDescriptionContainerWithAttribute}
                style={{
                  height: isLargeScreen ? "72px" : undefined,
                  alignItems: attributeType === "Free text" && !isLargeScreen ? "baseline" : "center",
                }}
              >
                <Box component="span" className={classes.pxDescriptionSpan}>
                  <Body3 data-public>{description}</Body3>
                </Box>
                {!!canRemove && <RemoveButton key={`removeBtn${id}`} rowIndex={rowIndex} />}
              </Grid>
            </Grid>
          </>
        ),
        width: "90%",
      },
    ];
  };

  const continuationContents = (
    id: string,
    rowIndex: number,
    code: string,
    units?: number,
    unitType?: string,
    description?: string,
    maxUnits?: number,
    defaultUnits?: number,
    approvedUnits?: number,
    authUnits?: number,
    authApprovedUnits?: number
  ) => {
    const pxCode = authorization?.semanticProcedureCodes?.find(
      (pCode) =>
        pCode.code === code &&
        ((formContent.clinicalService?.id || "") === pCode.groupId || pCode.groupBy === "Unclassified")
    );
    const labelUnitType =
      unitType && PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP[unitType]
        ? `${PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP[unitType]}s`
        : "Units";
    if (onPatientSummary && authStatus !== "PENDING") {
      return [
        {
          contents: <Chip style={{ height: "24px" }} label={code} dataPublic={true} />,
        },
        {
          contents: (
            <TextField
              disabled
              type="text"
              value={authApprovedUnits ? authApprovedUnits : approvedUnits ? approvedUnits : 0}
              data-testid={"approved-units"}
              inputClasses={autoFilled ? inputClasses : {}}
              label={"Units"}
            />
          ),
          width: "154px",
        },
        {
          contents: <Body3 data-public>{description}</Body3>,
          width: "auto",
        },
      ];
    } else {
      return [
        {
          contents: (
            <>
              <Grid key={code} item container xs={12} className={classes.tableContainerRow} spacing={3}>
                <Grid item className={classes.ChipContainer}>
                  <Chip style={{ height: theme.spacing(3) }} label={code} dataPublic={true} />
                </Grid>
                <Grid item className={classes.continuationUnitsContainer}>
                  <Grid
                    className={classes.continuationUnitItem}
                    style={{
                      marginRight: theme.spacing(2),
                    }}
                  >
                    <TextField
                      disabled
                      type="text"
                      value={pxCode?.approvedUnits ?? 0}
                      data-testid={"approved-units"}
                      inputClasses={autoFilled ? inputClasses : {}}
                      label={`Previously approved ${labelUnitType.toLowerCase()}`}
                      dataPublic={true}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid className={classes.continuationUnitItem}>
                    <TextField
                      error={Boolean(invalidUnitsMessage(units, maxUnits))}
                      type="numeric"
                      disabled={!formContent.procedureCodes.map((pxCode) => pxCode.code).includes(code)}
                      value={units ?? ""}
                      data-testid={"additional-requested-field"}
                      inputClasses={autoFilled ? inputClasses : {}}
                      label={`Additional ${labelUnitType.toLowerCase()}`}
                      style={{
                        width: "100%",
                      }}
                      onChangeValue={function (newUnits): void {
                        if (updatePxCodeUnits !== undefined) {
                          updatePxCodeUnits(rowIndex, newUnits);
                        }
                        const numVal = Math.trunc(Number(newUnits));
                        if (setIsAdditionalUnitsRequested) {
                          setIsAdditionalUnitsRequested(!isNaN(numVal) && numVal !== 0);
                        }
                      }}
                      InputProps={{
                        endAdornment: Boolean(invalidUnitsMessage(units, maxUnits)) ? (
                          <Tooltip
                            classes={{ tooltip: classes.customWidth }}
                            title={invalidUnitsMessage(units, maxUnits)}
                            placement="top"
                          >
                            <ErrorIcon className={classes.customWidth} />
                          </Tooltip>
                        ) : undefined,
                      }}
                      dataPublic={true}
                    />
                  </Grid>
                </Grid>
                <Grid item className={classes.pxDescriptionContainer}>
                  <Box component="span" className={classes.pxDescriptionSpan}>
                    <Body3 data-public>{description}</Body3>
                  </Box>
                  {!!canRemove && <RemoveButton key={`removeBtn${id}`} rowIndex={rowIndex} />}
                </Grid>
              </Grid>
            </>
          ),
          width: "90%",
        },
      ];
    }
  };

  const notUnitsOnPxContents = (id: string, rowIndex: number, code: string, description?: string) => {
    return [
      {
        contents: (
          <>
            <Grid item container xs={12} className={classes.tableContainerRow} spacing={3}>
              <Grid item className={classes.ChipContainer}>
                <Chip style={{ height: theme.spacing(3) }} label={code} dataPublic={true} />
              </Grid>
              <Grid item className={classes.pxDescriptionContainer}>
                <Box component="span" className={classes.pxDescriptionSpan}>
                  <Body3 data-public>{description}</Body3>
                </Box>
                {!!canRemove && <RemoveButton key={`removeBtn${id}`} rowIndex={rowIndex} />}
              </Grid>
            </Grid>
          </>
        ),
        width: "90%",
      },
    ];
  };

  // Enables removal of the px code when the SR auth status is not approved.
  const canRemove = onPatientSummary ? getPendingAuthStatuses().includes(authStatus || "") : true;
  // display all procedure codes on the authorization in the edit view of patient summary page
  const pxCodesMap: Map<string, ProcedureCode> = procedureCodes.reduce((map, pxCode) => {
    map.set(pxCode.code, pxCode);
    return map;
  }, new Map());
  if (onPatientSummary) {
    authorizationProcedureCodes?.forEach((pxCode) => {
      if (!pxCodesMap.has(pxCode.code)) {
        pxCodesMap.set(pxCode.code, pxCode);
      }
    });
  }

  // State to track whether the SingleSelectDropdown is currently focused.
  // This is used to change the label from "Select details" to "Details" when the dropdown is focused.
  const [isFocused, setIsFocused] = useState(false);

  const allPxCodes = Array.from(pxCodesMap.values());

  return (
    <>
      <Row>
        {procedureCodes && procedureCodes.length > 0 && (
          <FormTable<ProcedureCodeWithAggregate>
            responsive={canRemove}
            noPadding={noPadding || false}
            withRowDivider={withRowDivider}
            tableData={allPxCodes.map((px) => withAggregate(px, authorizationProcedureCodes))}
            headers={
              hideHeaders
                ? []
                : isUnitsOnPx
                ? isContinuation
                  ? onPatientSummary
                    ? CONTINUATION_HEADERS_PATIENT_SUMMARY
                    : CONTINUATION_HEADERS
                  : UNITS_ON_PX_HEADERS
                : HEADERS
            }
            TableRowProps={{ hover: true }}
          >
            {isUnitsOnPx
              ? ({
                  rowData: {
                    id,
                    code,
                    units,
                    unitType,
                    description,
                    maxUnits,
                    defaultUnits,
                    approvedUnits,
                    authUnits,
                    authApprovedUnits,
                    attributeType,
                    relationshipTo,
                    selectedDetails,
                  },
                  rowIndex,
                }) =>
                  isContinuation
                    ? continuationContents(
                        id,
                        rowIndex,
                        code,
                        units,
                        unitType,
                        description,
                        maxUnits,
                        defaultUnits,
                        approvedUnits,
                        authUnits,
                        authApprovedUnits
                      )
                    : units_on_px_contents(
                        id,
                        rowIndex,
                        code,
                        units,
                        unitType,
                        description,
                        maxUnits,
                        attributeType,
                        relationshipTo,
                        selectedDetails,
                        isFocused,
                        setIsFocused
                      )
              : ({ rowData: { id, code, description }, rowIndex }) =>
                  notUnitsOnPxContents(id, rowIndex, code, description)}
          </FormTable>
        )}
      </Row>
      {showAllNonCohereWarning && !isUsedInPACheckRedesign && (
        <Row>
          <Spacer />
          <ProcedureCodeWarning
            nonCohereProcedureCodes={nonCohereProcedureCodes}
            message={
              nonCohereProcedureCodes &&
              nonCohereProcedureCodes.length === 1 &&
              nonCohereProcedureCodes[0].procedureCode.length === 1
                ? "This code requires submission through another organization"
                : "These codes require submission through another organization"
            }
            setNonPalPDFOpen={setNonPalPDFOpen}
          />
        </Row>
      )}
      {showAllNonPalWarning && !isUsedInPACheckRedesign && (
        <Row>
          <Spacer />
          <ProcedureCodeWarning
            nonPalProcedureCodes={nonPalProcedureCodes}
            message={
              nonPalProcedureCodes && nonPalProcedureCodes.length === 1
                ? "This code doesn't require submission"
                : "These codes don't require submission"
            }
            setNonPalPDFOpen={setNonPalPDFOpen}
          />
        </Row>
      )}
    </>
  );

  function RemoveButton({ rowIndex }: { rowIndex: number }) {
    return (
      <Box component="span" className={classes.pxRemoveBtnSpan}>
        <InlineButton
          className="removeButton"
          onClick={() => {
            if (procedureCodes.length === 0) {
              setProcedureCodeSelectionActive(false);
            }
            const updatedProcedureCodes = [...procedureCodes];
            const filteredPxCodes = listRemove(updatedProcedureCodes, rowIndex);
            const filteredSemanticPxCodes = listRemove(
              authorizationProcedureCodes ? [...authorizationProcedureCodes] : [],
              rowIndex
            );
            setProcedureCodes(filteredPxCodes);
            if (setSemanticProcedureCodes) {
              setSemanticProcedureCodes(filteredSemanticPxCodes);
            }
          }}
          startIcon={<DeleteIcon />}
        >
          <Box component="span" style={{ display: isFaxAuthBuilderFlow ? "none" : "block" }}>
            Remove
          </Box>
        </InlineButton>
      </Box>
    );
  }
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Spacer = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 0),
  width: "100%",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ErrorIcon = styled(MuiErrorIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const Row = (props: ComponentProps<typeof Grid>) => <Grid item xs={12} {...props} />;

const useStylesInput = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  const borderColorEmphasis = isDarkTheme ? "rgba(3, 171, 195, 0.8)" : theme.palette.primary.main;
  return createStyles({
    root: {
      "&.MuiFilledInput-root": {
        border: `1px solid ${borderColorEmphasis}`,
        borderColor: borderColorEmphasis,
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        transition: theme.transitions.create(["background-color"]),
        borderRadius: 4,
      },
    },
  });
});

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 250,
  },
  tableContainerRow: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      "& .removeButton": {
        opacity: 1,
      },
    },
    "& .removeButton": {
      opacity: 0,
      color: colorsLight.font.secondary,
      transition: theme.transitions.create("opacity"),
      minWidth: (props: CssProps) => (props.isFaxAuthBuilderFlow ? "25px" : "80px"),
    },
  },
  tableContainer: {
    display: "flex",
    flexWrap: "wrap",
    "&:hover": {
      "& .removeButton": {
        opacity: 1,
      },
    },
    "& .removeButton": {
      opacity: 0,
      color: colorsLight.font.secondary,
      transition: theme.transitions.create("opacity"),
      minWidth: (props: CssProps) => (props.isFaxAuthBuilderFlow ? "25px" : "80px"),
    },
  },
  tableFlexAuto: {
    flex: `0 0 ${UnitsInitialWidthNotOnPx}px`,
    width: UnitsInitialWidthNotOnPx,
  },
  ChipContainer: {
    flex: `0 0 96px`,
    width: "16px",
  },
  continuationUnitsContainer: {
    display: "flex",
    minWidth: "200px",
  },
  continuationUnitItem: {
    flex: `0 1 ${UnitsContinuationWidth}px`,
    minWidth: "100px",
  },
  pxDescriptionContainer: {
    display: "flex",
    flex: "1 1 30%",
    minWidth: "200px",
    alignItems: "center",
  },
  pxDescriptionContainerWithAttribute: {
    display: "flex",
    flex: "1 1 30%",
    minWidth: "200px",
  },
  pxDescriptionSpan: {
    flex: `1 1 99%`,
  },
  pxRemoveBtnSpan: {
    minWidth: (props: CssProps) => (props.isFaxAuthBuilderFlow ? "25px" : "80px"),
    marginLeft: theme.spacing(2),
  },
  pxInputFields: {
    "&>label": {
      textWrap: "auto",
      transform: "translate(12px, 12px) scale(1)",
    },
  },
}));
