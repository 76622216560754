import React from "react";
import Grid from "@material-ui/core/Grid";
import { Body2, colorsLight, Subtitle2, H5, TargetFieldLabel } from "@coherehealth/common";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core";
import { RecommendChangeRuleAction, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { newValueText } from "util/NudgeUtils";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

interface ContentNudgeProps {
  state: "accepted" | "pending";
  type: "main" | "secondary" | "dedicatedNudge";
  ruleNudge?: RecommendChangeRuleAction;
  unitsOnPxNudge?: number;
  serviceRequest?: ServiceRequestResponse;
  currentDedicatedNudgeTargetForAction?: TargetFieldLabel | undefined;
  unitPX?: boolean | undefined;
}

export default function ContentNudge({
  state,
  type,
  ruleNudge,
  unitsOnPxNudge,
  serviceRequest,
  currentDedicatedNudgeTargetForAction,
  unitPX,
}: ContentNudgeProps) {
  const accepted = state === "accepted";
  const main = type === "main";
  const classes = useStyles({ accepted, main });
  return (
    <div className={classes.outerDiv}>
      {type === "main" && <div className={classes.spacer}></div>}
      <Grid className={`${classes.box} ${state} ${type}`}>
        <div className={`${classes.content} ${type}`}>
          {state === "accepted" ? (
            <CheckIcon aria-label="Success Check" className={`${classes.icon} ${state} ${type}`} />
          ) : currentDedicatedNudgeTargetForAction !== "Unit reduction" || !unitPX ? (
            <ArrowForwardIcon aria-label="Primary Arrow" className={`${classes.icon} ${type}`} />
          ) : (
            false
          )}
          {type === "main" ? (
            <Body2 className={classes.mainText} data-public>
              {newValueText(ruleNudge, serviceRequest!)}
            </Body2>
          ) : type === "dedicatedNudge" ? (
            <>
              {currentDedicatedNudgeTargetForAction !== "Unit reduction" && !unitPX && (
                <H5 className={`${classes.mainText} ${type}`} data-public>
                  {newValueText(ruleNudge, serviceRequest!)}
                </H5>
              )}
              {currentDedicatedNudgeTargetForAction === "Unit reduction" && unitPX && (
                <H5 className={classes.sideText} data-public>
                  Up to
                </H5>
              )}
              <H5 className={`${classes.mainText} ${type}`} data-public>
                {newValueText(ruleNudge, serviceRequest!)}
              </H5>
              {currentDedicatedNudgeTargetForAction === "Unit reduction" && unitPX && (
                <H5 className={classes.sideText} data-public>
                  {"per code"}
                </H5>
              )}
            </>
          ) : (
            <Subtitle2 className={classes.secondaryText} data-public>
              {unitsOnPxNudge} {unitsOnPxNudge === 1 ? "unit" : "units"}
            </Subtitle2>
          )}
        </div>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  outerDiv: {
    display: "inline-block",
  },
  spacer: {
    display: "inline-block",
    width: "8px",
  },
  box: {
    "&.dedicatedNudge": {
      display: "inline-flex",
      paddingLeft: "8px",
      paddingRight: theme.spacing(2),
      height: theme.spacing(4),
      borderRadius: "4px",
    },
    "&.main": {
      display: "inline-flex",
      paddingLeft: "12px",
      paddingRight: theme.spacing(2),
      height: theme.spacing(4),
      borderRadius: "4px",
      "&.pending": {
        backgroundColor: colorsLight.primary.faint10,
      },
      "&.accepted": {
        backgroundColor: colorsLight.success.light,
      },
    },
    "&.secondary": {
      paddingLeft: "4px",
    },
  },
  content: {
    "&.main": {
      height: theme.spacing(4),
    },
    alignItems: "center",
    display: "flex",
  },
  icon: {
    color: colorsLight.primary.main,
    "&.secondary": {
      width: "16px",
      height: "16px",
    },
    "&.accepted": {
      color: colorsLight.success.main,
    },
  },
  mainText: {
    paddingLeft: "4px",
    "&.dedicatedNudge": {
      paddingLeft: "8px",
    },
  },
  secondaryText: {
    paddingLeft: "4px",
  },
  sideText: {
    paddingLeft: theme.spacing(0.5),
    color: colorsLight.font.main,
    fontFamily: "Gilroy-Regular",
    fontSize: theme.spacing(2.25),
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: theme.spacing(2.5),
    letterSpacing: "0.15px",
  },
}));
