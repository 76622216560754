import { useEffect, Dispatch, useContext, useState, useCallback, SetStateAction, RefObject } from "react";
import {
  AttachmentGuidelineTextHighlight,
  AttachmentGuidelineTextHighlightsSnapshot,
  AuthStatus,
  AuthorizationResponse,
  AuthorizationUpdatePayload,
  CopyPasteInfo,
  ContinuationConfigurationResponse,
  PatientStatus,
  PatientStayDate,
  PostDecisionConfigurationResponse,
  ReviewType,
  SearchInfo,
  ServiceRequestCommandPayload,
  ServiceRequestResponse,
  useAddServiceRequestOnAuthorization,
  useUpdateAuthorizationById,
  DropdownOption as ConfigDropdownOption,
  AutomatedReviewNote,
  AutomatedReviewNoteNode,
  Attachment,
  AutomatedReviewNoteFinding,
} from "@coherehealth/core-platform-api";
import { User, UserContext } from "UserContext";
import { useUserQmProfile } from "@coherehealth/qm-api";
import config from "api/config";
import { useSnackbar } from "notistack";
import {
  useFeature,
  Body1,
  parseDateFromISOString,
  patientDisplayName,
  formatDateStr,
  formatDateStrWithCustomFormat,
  today,
  parseDateFromISOStringWithoutFallback,
  UserClickInfoClinicalReviewPage,
  DropdownOption,
  useGetAuthorizationByIdWithFallback,
} from "@coherehealth/common";
import { compact } from "lodash";
import { getSortedListOfCoverages } from "util/patientUtils";
import { formatAnyPhone, singleLineAddress } from "util/demographicUtils";
import { capitalizeString } from "util/serviceRequest";
import { DischargeDetailsType } from "components/ServiceRequest/ReviewSection/DischargedToDetails";
import { UsePatientStayReturnValueProps } from "components/ServiceRequest/PatientStay/usePatientStayDateOnCRR";
import { PatientStayDateRange } from "common/SharedServiceRequestFormComponents";
import { GetDataError } from "restful-react";
import { useTheme } from "@mui/material";
import { ReviewOutcomeOption } from "components/ServiceRequest/ReviewSection/util/QueueManagementReviewUtil";
import hash from "hash.js";
import { useCreateReadmissionPayload } from "components/ServiceRequest/ReviewSection/common/hooks/useReadmission";

export const DoctorReviews = ["MdReview", "PeerToPeerReview"];

export type LeftPanelTabs = "REQUEST_INFORMATION" | "ATTACHMENTS" | "EVENT_HISTORY" | "CLAIM_HISTORY";

export type ReviewPageVersion =
  | "NewOutReachPage"
  | "LogOutreachPage"
  | "NewNotePage"
  | "AppealNotePage"
  | "ViewOnlyPage";

export type ReviewReasonOutcomeOption = { id: string; label: string; subLabel: string; outcome: string };

export const reviewOutcomeReasonOptions: ReviewReasonOutcomeOption[] = [
  { id: "7*00", label: "Pay in-network", subLabel: "7*00", outcome: "APPROVED" },
  { id: "8*00", label: "Pay OON", subLabel: "8*00", outcome: "APPROVED" },
  { id: "KD00", label: "Benefit extension", subLabel: "KD00", outcome: "APPROVED" },
  { id: "TF00", label: "Pend-UM auth instructions", subLabel: "TF00", outcome: "APPROVED" },
  { id: "6?00", label: "Geisinger employee Group", subLabel: "6?00", outcome: "APPROVED" },
];

export const ReviewOutcomes: Record<string, string> = {
  APPROVED: "Approved",
  DENIED: "Denied",
  WITHDRAWN: "Withdrawn",
  PENDING_MISSING_CLINICAL_INFO: "Pending for missing clinical information",
  PENDING_RN_REVIEW: "Pending for clinical review",
  PENDING_MD_REVIEW: "Pending for MD review",
  RECOMMENDED_APPROVAL: "Recommended approval",
  RECOMMENDED_PARTIAL_APPROVAL: "Recommended partial approval",
  RECOMMENDED_DENIAL: "Recommended denial",
  MISROUTE: "Misroute",
  UNWORKABLE: "Unworkable",
};

export const FacilityBasedReviewOutcomeReasonOptions: ReviewReasonOutcomeOption[] = [
  ...reviewOutcomeReasonOptions,
  { id: "D900", label: "Readmission", subLabel: "D900", outcome: "APPROVED" },
];

export const RIGHT_HAND_PANEL_SIZE = 1441;

export interface ReviewCommonProps
  extends ReviewReadOnlyCommonProps,
    Partial<UsePatientStayReturnValueProps>,
    Partial<DischargeDetailsProps> {
  onFinishEditing?: () => void;
  clinicalReviewView?: boolean;
  buttonPadding?: string;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  searchInfos?: SearchInfo[];
  setSearchInfos?: Dispatch<React.SetStateAction<SearchInfo[]>>;
  setPastedAttachmentTexts?: Dispatch<React.SetStateAction<CopyPasteInfo[] | undefined>>;
  submitHighlights?: () => Promise<void>;
  submittingHighlights?: boolean;
  getHighlightPayload?: () => AttachmentGuidelineTextHighlight[] | null;
  attachmentGuidelineTextHighlightsSnapshot?: AttachmentGuidelineTextHighlightsSnapshot;
  copiedAttachmentText?: CopyPasteInfo;
  pastedAttachmentTexts?: CopyPasteInfo[];
  facilityBasedFeatureEnabled?: boolean;
  continuationConfiguration?: ContinuationConfigurationResponse | null;
  authorization?: AuthorizationResponse;
  checkEmptyRequestedLoc?: boolean;
  canShowExtraOONTab?: boolean;
  embeddedOonReviewId?: string;
  disableFinishOonModalButton?: () => boolean;
  needsOonReview?: boolean;
  submitEmbeddedOonReview?: (embededMode?: boolean) => Promise<void>;
  validateOONReview?: () => boolean;
  saveOutOfNetworkReviewDraft?: (embededMode?: boolean) => Promise<void>;
  discardOutOfNetworkReview?: (embededMode?: boolean) => Promise<void>;
  oonAllowedReviewOutcomes?: ReviewOutcomeOption[];
}

export interface ReviewReadOnlyCommonProps {
  expanded?: boolean;
  toggleExpanded?(): void;
  reviewRef?: (node: HTMLElement | null) => void;
  expandableGuidelines?: boolean;
  unexpandable?: boolean;
  buttonPadding?: string;
  authorizationReviewView?: boolean;
  reviewDetailsCollapseStyle?: React.CSSProperties;
}

export const usePasteTracker = (
  copiedAttachmentText?: CopyPasteInfo,
  setPastedAttachmentTexts?: Dispatch<React.SetStateAction<CopyPasteInfo[] | undefined>>
) => {
  useEffect(() => {
    const handlePasteEvent = (event: ClipboardEvent) => {
      const textFromClipBoard = event.clipboardData?.getData("text");
      if (textFromClipBoard === copiedAttachmentText?.copiedText) {
        copiedAttachmentText &&
          setPastedAttachmentTexts?.((pastedAttachmentTexts) => {
            return [...(pastedAttachmentTexts || []), copiedAttachmentText];
          });
      }
    };

    document.addEventListener("paste", handlePasteEvent);
    return () => {
      document.removeEventListener("paste", handlePasteEvent);
    };
  }, [copiedAttachmentText, setPastedAttachmentTexts]);
};

export const useGetUser = () => {
  const { getUser } = useContext(UserContext);
  const [currentUser, setCurrentUser] = useState<User>();
  useEffect(() => {
    const setUser = async () => {
      const user = await getUser();
      if (user) {
        setCurrentUser(user);
      }
    };
    setUser();
  }, [getUser]);
  return currentUser;
};

export const useIsQmUserAudited = () => {
  const canMarkReviewAudited = useFeature("canMarkReviewAudited");
  const [isQmUserAudited, setIsQmUserAudited] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const { refetch: fetchQMUsers, error: fetchQMUsersError } = useUserQmProfile({
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });

  useEffect(() => {
    const getQmUser = async () => {
      const qmUser = canMarkReviewAudited ? await fetchQMUsers({}) : null;
      const isQmUserAudited = qmUser?.isAudited || false;
      setIsQmUserAudited(isQmUserAudited);
    };
    getQmUser();
  }, [canMarkReviewAudited, fetchQMUsers]);

  useEffect(() => {
    if (fetchQMUsersError) {
      enqueueSnackbar(`Failed to fetch users: ${fetchQMUsersError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, fetchQMUsersError]);

  return isQmUserAudited;
};

interface ModalMessageProps {
  state?: string;
}

export const ModalMessage = (props: ModalMessageProps) => {
  const theme = useTheme();
  return (
    <Body1 style={{ color: theme.palette.font.secondary }}>
      {props.state?.includes("WITHDRAWN") ? (
        <>
          Because of the reason you've selected,
          <span style={{ color: theme.palette.font.main, fontWeight: 600 }}>
            {" "}
            the service request will be withdrawn
          </span>{" "}
          and a letter will be sent to the member if their LOB requires it
        </>
      ) : props.state?.includes("VOIDED") ? (
        <>
          Because of the reason you've selected,
          <span style={{ color: theme.palette.font.main, fontWeight: 600 }}>
            {" "}
            the service request will be voided
          </span>{" "}
          and no letter will be sent to the member
        </>
      ) : props.state?.includes("DISMISSED") ? (
        <>
          Because of the reason you've selected,
          <span style={{ color: theme.palette.font.main, fontWeight: 600 }}>
            {" "}
            the service request will be dismissed
          </span>{" "}
          and a letter will be sent to the member if their LOB requires it
        </>
      ) : null}
    </Body1>
  );
};

export const SRNoLongerNeededButtonMessage = () => {
  const theme = useTheme();

  return (
    <Body1>
      <span style={{ color: theme.palette.font.secondary }}>
        Since this request was already decisioned, it will remain approved but will be{" "}
        <span style={{ color: theme.palette.font.main, fontWeight: 600 }}>marked as no longer needed.</span>
        &nbsp;No letter will be sent to the member.
        <div style={{ paddingTop: "18px" }}>This action cannot be undone.</div>
      </span>
    </Body1>
  );
};

export const useAuthorizationFetch = (serviceRequest: ServiceRequestResponse | null) => {
  const { enqueueSnackbar } = useSnackbar();
  const [authorization, setAuthorization] = useState<AuthorizationResponse>();
  const reviewTimelineCardFeature = useFeature("reviewTimelineCard");

  const {
    loading: authorizationFetchLoading,
    error: getAuthorizationError,
    refetch: getAuthorizationById,
  } = useGetAuthorizationByIdWithFallback({
    id: serviceRequest?.authorization?.id || "",
    lazy: true,
    ...(reviewTimelineCardFeature
      ? {
          queryParams: {
            view: "historyCard",
          },
        }
      : {}),
  });

  useEffect(() => {
    if (getAuthorizationError) {
      enqueueSnackbar(`Failed to load authorization: ${getAuthorizationError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, getAuthorizationError]);

  const fetchAuthorization = useCallback(async () => {
    if (serviceRequest?.authorization?.id) {
      const authorization = await getAuthorizationById();
      authorization && setAuthorization(authorization);
    }
  }, [getAuthorizationById, serviceRequest?.authorization?.id]);

  useEffect(() => {
    fetchAuthorization();
  }, [fetchAuthorization]);

  return { authorization, authorizationFetchLoading, fetchAuthorization };
};

export const useUpdateAuthorization = (authorization: Pick<AuthorizationUpdatePayload, "id">) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    mutate: patchAuthorization,
    error: patchAuthorizationError,
    loading: updateAuthorizationLoading,
  } = useUpdateAuthorizationById({ id: authorization?.id || "" });

  useEffect(() => {
    if (patchAuthorizationError) {
      enqueueSnackbar(`Failed to update Authorization: ${patchAuthorizationError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, patchAuthorizationError]);

  const updateAuthorization = async (
    auth: Partial<Omit<AuthorizationUpdatePayload, "id" | "dateCreated" | "patient">>
  ) => {
    await patchAuthorization({
      ...auth,
    });
  };

  return { updateAuthorization, updateAuthorizationLoading };
};

export interface DischargeDetailsProps {
  isDischarged: boolean | undefined;
  setIsDischarged: Dispatch<SetStateAction<boolean | undefined>>;
  dischargeDetails: DischargeDetailsType;
  setDischargeDetails: Dispatch<SetStateAction<DischargeDetailsType>>;
  buildAuthorizationDischargeDetails: () => Pick<AuthorizationResponse, "actualDischargeDateTime" | "dischargedTo">;
  isDischargeDetailsValid: boolean;
}

export const useDischargeDetails = (authorization?: AuthorizationResponse): DischargeDetailsProps => {
  const [isDischarged, setIsDischarged] = useState<boolean>();
  const [dischargeDetails, setDischargeDetails] = useState<DischargeDetailsType>({});
  useEffect(() => {
    if (authorization?.actualDischargeDateTime) {
      const actualDischargeDateTime = parseDateFromISOStringWithoutFallback(authorization?.actualDischargeDateTime);
      setDischargeDetails({
        actualDischargeDate: actualDischargeDateTime || today(),
        actualDischargeTime: actualDischargeDateTime
          ? ("0" + actualDischargeDateTime.getHours()).slice(-2) +
            ":" +
            ("0" + actualDischargeDateTime.getMinutes()).slice(-2)
          : undefined,
        dischargedTo: authorization?.dischargedTo || undefined,
      });
    }
    setIsDischarged(authorization?.patientStatus === "DISCHARGED");
  }, [authorization?.actualDischargeDateTime, authorization?.dischargedTo, authorization?.patientStatus]);

  const buildAuthorizationDischargeDetails = (): Pick<
    AuthorizationResponse,
    "actualDischargeDateTime" | "dischargedTo"
  > => {
    // don't return any fields if not discharged yet.
    if (!isDischarged) {
      return {};
    }
    const splitTime = dischargeDetails?.actualDischargeTime?.split(":");
    const date = dischargeDetails?.actualDischargeDate || today();

    if (splitTime && splitTime.length === 2) {
      date.setHours(Number(splitTime[0]));
      date.setMinutes(Number(splitTime[1]));
    }

    const timeZoneOffset = date.getTimezoneOffset();
    // offet time based on utc difference
    const newDate = new Date(date.getTime() - timeZoneOffset * 60 * 1000);

    const authorizationDischargeDetails: AuthorizationResponse = {
      actualDischargeDateTime: newDate.toISOString(),
      dischargedTo: dischargeDetails?.dischargedTo,
    };

    return authorizationDischargeDetails;
  };

  const isDischargeDetailsValid =
    !isDischarged || (!!dischargeDetails.dischargedTo && !!dischargeDetails.actualDischargeDate);

  return {
    isDischarged,
    setIsDischarged,
    dischargeDetails,
    setDischargeDetails,
    buildAuthorizationDischargeDetails,
    isDischargeDetailsValid,
  };
};

export const insertAtIndex = <T,>(arr: T[], index: number, ...items: T[]): T[] => {
  return [...arr.slice(0, index), ...items, ...arr.slice(index)];
};

interface TatInfo {
  tatDueDateTime: string | undefined;
  tatDaysRemaining: number | undefined;
  tatTimeRemainingPercentage: number | undefined;
  tatRemaining: number | undefined;
  formattedTatStartTime: string | undefined;
}

export const getTatInfo = (serviceRequest: ServiceRequestResponse, dateFormat?: string): TatInfo => {
  const tatDueStr = serviceRequest.urgency?.isExpedited
    ? serviceRequest.turnAroundTimeDueDate?.expeditedTatDueDate
    : serviceRequest.turnAroundTimeDueDate?.regularTatDueDate;
  const tatDue = parseDateFromISOString(tatDueStr);
  const tatStartString = serviceRequest.tatStartTimestamp || serviceRequest.intakeTimestamp;
  const tatStart = tatStartString ? parseDateFromISOString(tatStartString) : undefined;
  const tatRemaining = tatDue.getTime() - Date.now();
  const milliSecondsInADay = 86400000;
  const tatDaysRemaining = Math.floor(tatRemaining / milliSecondsInADay);
  const tatTimeRemainingPercentage =
    tatDueStr && tatStart && tatStart < tatDue
      ? Math.floor((tatRemaining / (tatDue.getTime() - tatStart.getTime())) * 100)
      : undefined;
  const formatStr = dateFormat || "MM/dd/yyyy 'at' hh:mm:ss a (z)";
  const tatDueDateTime = formatDateStrWithCustomFormat(tatDueStr, formatStr, true);
  const formattedTatStartTime = formatDateStrWithCustomFormat(tatStartString, formatStr, true);
  return {
    tatDueDateTime,
    tatDaysRemaining,
    tatTimeRemainingPercentage,
    tatRemaining,
    formattedTatStartTime,
  };
};

export const tatDisplayText = (serviceRequest: ServiceRequestResponse) => {
  const { tatDueDateTime, tatDaysRemaining = 0, tatTimeRemainingPercentage = 0 } = getTatInfo(serviceRequest);
  return `Due date (TAT): ${tatDueDateTime} \u2022 Time remaining: ${tatDaysRemaining > 0 ? tatDaysRemaining : "--"} ${
    tatDaysRemaining > 1 ? "days" : "day"
  } (${tatTimeRemainingPercentage > 0 ? tatTimeRemainingPercentage : "--"}%)`;
};

export const getTatStartDisplayText = (serviceRequest: ServiceRequestResponse) => {
  const { formattedTatStartTime } = getTatInfo(serviceRequest, "MM/dd/yyyy 'at' hh:mm:ss a (z)");
  const userUpdatedTat =
    Boolean(serviceRequest.userUpdatedTat) ||
    Boolean(serviceRequest.urgency?.isExpedited && serviceRequest.expeditedTatUpdateTimestamp);

  return `Submitted (TAT start): ${formattedTatStartTime} \u2022 User updated TAT: ${userUpdatedTat ? "Yes" : "No"}`;
};

export const getTatEndDisplayText = (serviceRequest: ServiceRequestResponse) => {
  const {
    tatDueDateTime,
    tatDaysRemaining = 0,
    tatTimeRemainingPercentage = 0,
  } = getTatInfo(serviceRequest, "MM/dd/yyyy 'at' hh:mm:ss a (z)");
  return `Due date (TAT end): ${tatDueDateTime} \u2022 Time remaining: ${
    tatDaysRemaining > 0 ? tatDaysRemaining : "--"
  } ${tatDaysRemaining > 1 ? "days" : "day"} (${tatTimeRemainingPercentage > 0 ? tatTimeRemainingPercentage : "--"}%)`;
};

export const getReviewTabLabel = (
  reviewType: ReviewType["reviewType"],
  authStatus?: AuthStatus | undefined,
  postDecisionP2PExpanded?: boolean
) => {
  switch (reviewType) {
    case "NurseReview":
      return "Clinical Review";
    case "OutOfNetworkReview":
      return "OON Review";
    case "MdReview":
      return "MD Review";
    case "PeerToPeerReview":
      if (authStatus === "POST_DENIAL_PEER_TO_PEER" && postDecisionP2PExpanded) {
        return "Post Decision Peer To Peer Review";
      } else {
        return "Peer to Peer Review";
      }
    case "AuditReview":
    case "NewHireAuditReview":
    case "OutOfScopeAuditReview":
      return "Audit Review";
  }
};

const NON_APPLICABLE = "N/A";

export const getOOSEmailSubject = (serviceRequest: ServiceRequestResponse) => {
  const sortedCoverages = getSortedListOfCoverages(serviceRequest.patient);
  const primaryActiveCoverage = sortedCoverages?.[0];
  const lob = primaryActiveCoverage?.coverageLineOfBusinessType || primaryActiveCoverage?.lineOfBusinessType;
  const patientName = serviceRequest.patient ? patientDisplayName(serviceRequest.patient) : NON_APPLICABLE;
  const patientDOB = formatDateStr(serviceRequest.patient?.dateOfBirth);

  return `${patientName}, ${lob ? lob + "," : ""} ${
    serviceRequest.urgency?.isExpedited ? "Expedited" : "Standard"
  }, ${patientDOB}`;
};

export const getOOSEmailBody = (serviceRequest: ServiceRequestResponse) => {
  const sortedAttachments = serviceRequest.attachments?.slice().sort(function (a, b) {
    const dateA = new Date(a.dateCreated || "");
    const dateB = new Date(b.dateCreated || "");
    return dateB.valueOf() - dateA.valueOf();
  });
  const dateTimeFormat = "MM/dd/yyyy, hh:mma";
  const sortedCoverages = getSortedListOfCoverages(serviceRequest.patient);
  const primaryActiveCoverage = sortedCoverages?.[0];
  const lob = primaryActiveCoverage?.coverageLineOfBusinessType || primaryActiveCoverage?.lineOfBusinessType;
  const patientName = serviceRequest.patient ? patientDisplayName(serviceRequest.patient) : NON_APPLICABLE;
  const patientDOB = formatDateStr(serviceRequest.patient?.dateOfBirth);

  return `Membership Type: ${lob || NON_APPLICABLE}
Expedited Review: ${serviceRequest.urgency?.isExpedited ? "Yes" : "No"}
Expedited Date/Time: ${
    serviceRequest.urgency?.isExpedited
      ? formatDateStrWithCustomFormat(serviceRequest.turnAroundTimeDueDate?.expeditedTatDueDate, dateTimeFormat)
      : NON_APPLICABLE
  }
Site of Service: ${serviceRequest.encounterType === "INPATIENT" ? "Inpatient" : "Outpatient"}

Member Information:
Name: ${patientName}
Member ID: ${serviceRequest.patient?.memberId}
DOB: ${patientDOB}
Policy Sold State: ${primaryActiveCoverage?.stateOfIssue || NON_APPLICABLE}

Provider Information:
Name: ${serviceRequest.orderingProvider?.name || NON_APPLICABLE}
TIN: ${
    serviceRequest.orderingProvider?.tinList?.[0] ? serviceRequest.orderingProvider?.tinList.join(", ") : NON_APPLICABLE
  }
NPI: ${serviceRequest.orderingProvider?.npi || NON_APPLICABLE}
Telephone number: ${
    serviceRequest.orderingProvider?.phoneNumbers
      ? formatAnyPhone(serviceRequest.orderingProvider?.phoneNumbers)
      : NON_APPLICABLE
  }
Address: ${singleLineAddress(serviceRequest.orderingProvider?.addresses?.[0]) || NON_APPLICABLE}

Facility Information:
Name: ${serviceRequest.facility?.name || NON_APPLICABLE}
TIN: ${serviceRequest.facility?.tinList?.[0] ? serviceRequest.facility?.tinList.join(", ") : NON_APPLICABLE}
Address: ${singleLineAddress(serviceRequest.facility?.addresses?.[0]) || NON_APPLICABLE}

Diagnosis Code(s):
${serviceRequest.primarySemanticDiagnosisCode?.code} ${
    serviceRequest.primarySemanticDiagnosisCode?.description
      ? "- " + serviceRequest.primarySemanticDiagnosisCode?.description
      : ""
  }
${
  serviceRequest.secondaryDiagnoses
    ? serviceRequest.secondaryDiagnoses?.map((dxCode) => dxCode.code + " - " + dxCode.description).join("\n")
    : ""
}
Procedure Code(s):
${serviceRequest.procedureCodes?.map((pxCode) => pxCode.code + " - " + pxCode.description).join("\n")}

Reason service is Out of scope: Non related DX Code

Date and Time Request Received: ${formatDateStrWithCustomFormat(serviceRequest.intakeTimestamp, dateTimeFormat)}
Case initiated by: ${
    serviceRequest.requestor?.firstName
      ? serviceRequest.requestor.firstName + " " + serviceRequest.requestor.lastName
      : serviceRequest.createdByName
  } via: ${serviceRequest.requestor?.channel ? capitalizeString(serviceRequest.requestor?.channel) : "Portal"}
Direct Secure Phone Number: ${serviceRequest.createdByPhoneNumber || NON_APPLICABLE}
Date of Service: ${compact([serviceRequest.startDate, serviceRequest.endDate]).map(formatDateStr).join(" \u2014 ")}
Date and Time Received Final Clinical Information: ${
    sortedAttachments?.[0]
      ? formatDateStrWithCustomFormat(sortedAttachments[0].dateCreated, dateTimeFormat)
      : NON_APPLICABLE
  }
`;
};

export const claimsReasonLabel = (code: string, isRequired = false) => {
  switch (code) {
    case "TF00":
    case "D900":
      return "Claims payment note (required)";
    case "6?00":
      return "Claims payment note (optional)";
    default:
      return `Claims payment note ${isRequired ? "(required)" : "(optional)"}`;
  }
};

export type CommonReviewOutcome =
  | "APPROVED"
  | "WITHDRAWN"
  | "PENDING_MISSING_CLINICAL_INFO"
  | "PENDING_SCHEDULING_OUTREACH"
  | "PENDING_RN_REVIEW"
  | "PENDING_MD_REVIEW"
  | "PENDING_NUDGE_OUTREACH"
  | "PENDING_ADMIN_VOID"
  | "RECOMMENDED_APPROVAL"
  | "RECOMMENDED_DENIAL"
  | "RECOMMENDED_PARTIAL_APPROVAL"
  | "MISROUTE"
  | "OUT_OF_SCOPE"
  | "UNWORKABLE"
  | "ADMINISTRATIVE_DENIAL"
  | "PARTIALLY_APPROVED"
  | "RECOMMEND_DENIAL"
  | "DENIED"
  | "PASS"
  | "FAIL";

export const reviewOutcomeLabel = (reviewOutcome: CommonReviewOutcome): string => {
  switch (reviewOutcome) {
    case "APPROVED":
      return "Approved";
    case "WITHDRAWN":
      return "Withdrawn";
    case "PENDING_MISSING_CLINICAL_INFO":
      return "Pending missing info";
    case "PENDING_SCHEDULING_OUTREACH":
      return "Pending peer to peer scheduling";
    case "PENDING_RN_REVIEW":
      return "Pending clinical review";
    case "PENDING_MD_REVIEW":
      return "Pending MD review";
    case "PENDING_NUDGE_OUTREACH":
      return "Pending nudge outreach";
    case "PENDING_ADMIN_VOID":
      return "Pending admin void";
    case "RECOMMENDED_APPROVAL":
      return "Recommended approval";
    case "RECOMMENDED_DENIAL":
      return "Recommended denial";
    case "RECOMMENDED_PARTIAL_APPROVAL":
      return "Recommended partial approval";
    case "MISROUTE":
      return "Misroute";
    case "OUT_OF_SCOPE":
      return "Out of scope";
    case "UNWORKABLE":
      return "Unworkable";
    case "ADMINISTRATIVE_DENIAL":
      return "Administrative denial";
    case "PARTIALLY_APPROVED":
      return "Partial approval";
    case "RECOMMEND_DENIAL":
      return "Recommended denial";
    case "DENIED":
      return "Denied";
    case "PASS":
      return "Pass";
    case "FAIL":
      return "Fail";
    default:
      return "";
  }
};

export const useContinueServiceRequestTillNextReviewDate = (
  authorizationId?: string,
  isFacilityBasedReview?: boolean
) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    mutate: createNewServiceRequestOnAuth,
    error: createNewServiceRequestOnAuthError,
    loading: loadingContinuation,
  } = useAddServiceRequestOnAuthorization({
    id: authorizationId || "",
  });
  const createReadmissionPayload = useCreateReadmissionPayload();
  useEffect(() => {
    if (createNewServiceRequestOnAuthError) {
      enqueueSnackbar(
        `Failed to create continuation for next review date: ${createNewServiceRequestOnAuthError.message}`,
        { variant: "error" }
      );
    }
  });

  const continueServiceRequestTillNextReviewDate = useCallback(
    async ({
      serviceRequestId,
      nextReviewDate,
      isDischarged,
      reviewOutcome,
      lastStayDateRange,
      triggerStatuses,
      patientStatus,
      patientStayDates,
      isReadmission,
      anchorAuthNumber,
    }: {
      serviceRequestId: string;
      nextReviewDate?: string;
      isDischarged?: boolean;
      reviewOutcome?: string;
      lastStayDateRange?: PatientStayDateRange;
      triggerStatuses: AuthStatus[];
      patientStatus?: PatientStatus;
      patientStayDates?: PatientStayDate[];
      isReadmission?: boolean;
      anchorAuthNumber?: string;
    }) => {
      const isNextReviewDateEnabled =
        !isDischarged &&
        triggerStatuses.some((status) => status === reviewOutcome) &&
        isFacilityBasedReview &&
        !!nextReviewDate;
      if (isNextReviewDateEnabled) {
        const payload: ServiceRequestCommandPayload = {
          authStatus: "PENDING",
          pendingReason: "RN_REVIEW",
          requestTiming: "CONCURRENT",
          urgency: { isExpedited: false },
          requestType: "CONTINUATION",
          workflowStep: "REVIEW_CONTINUATION",
          nextReviewDate: nextReviewDate,
          lastDecisionedDate: lastStayDateRange?.rangeEndDate?.toISOString(),
          requestedLevelOfCareCode: lastStayDateRange?.requestedLevelOfCareCode || undefined,
          encounterType: "INPATIENT",
          units: lastStayDateRange ? undefined : 0,
          continuationGeneratedFromServiceRequest: {
            id: serviceRequestId,
          },
          patientStatus,
          patientStayDates,
        };
        const readmissionPayload = createReadmissionPayload(payload, isReadmission, anchorAuthNumber);
        return await createNewServiceRequestOnAuth(readmissionPayload);
      }
    },
    [createNewServiceRequestOnAuth, createReadmissionPayload, isFacilityBasedReview]
  );

  return { continueServiceRequestTillNextReviewDate, loadingContinuation };
};

export const scrollToRef = (ref: RefObject<HTMLElement>) => {
  ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
};

export const isFetchedDataReady = ({
  error,
  loading,
  data,
}: {
  error: GetDataError<unknown> | null;
  loading: boolean;
  data: unknown;
}): boolean => {
  return !loading && !error && !!data;
};

/*
  During a post-denial P2P, an MD can overturn a Denial to Approval.
  Payers can choose to disable all overturns, or just post-TAT overturns.
  This function determines whether to show the 'Overturn' dropdown option.
*/
export const showOverturnOption = (
  postDecisionConfig: PostDecisionConfigurationResponse | null,
  serviceRequest: ServiceRequestResponse
): boolean => {
  const { tatRemaining = 0 } = getTatInfo(serviceRequest);
  const tatAlreadyPassed = tatRemaining < 0;
  const hideOverturn =
    postDecisionConfig?.disableOverturn || (tatAlreadyPassed && postDecisionConfig?.disablePostTatOverturn);
  return !hideOverturn;
};

/**
 * Validates whether or not a Clinical Review is for a Post-Denial Peer to Peer review or not.
 * @param clinicalReview
 * @param authStatus
 * @returns true is the clinical review is for a Post-Denial P2P
 */
export const isPostDenialP2P = (clinicalReview?: ReviewType | null, authStatus?: AuthStatus): boolean => {
  const isP2PReview = clinicalReview?.reviewType === "PeerToPeerReview";
  const isPostDenial = [
    "RECOMMENDED_PARTIAL_APPROVAL",
    "RECOMMENDED_DENIAL",
    "ADMINISTRATIVE_DENIAL",
    "POST_DENIAL_PEER_TO_PEER",
  ].includes(authStatus ?? "");
  return isP2PReview && isPostDenial;
};

export const tabProps = (value: string) => {
  return {
    id: `tabpanel-${value}`,
    "aria-controls": `tabpanel-${value}`,
    key: value,
    value: value,
  };
};

export const configToDropDownOption = (configOptions: ConfigDropdownOption[]): DropdownOption[] => {
  return configOptions.map((option) => ({ id: option.optionId, label: option.label }));
};

export const optionIdToConfigLabel = (option?: string, configOptions?: ConfigDropdownOption[]): string | undefined => {
  return configOptions?.find((opt) => opt.optionId === option)?.label;
};

export const optionIdToConfigLabelReview = (
  option?: string,
  configOptions?: ReviewOutcomeOption[]
): string | undefined => {
  return configOptions?.find((opt) => opt.id === option)?.label;
};
export const getIsOutOfNetworkCaption = (networkStatus: boolean | undefined): string => {
  if (networkStatus) {
    return "Out-of-network";
  }
  return "In-network";
};

export const isMedicalNecessityReview = (reviewType: ReviewType["reviewType"]) => {
  return ["NurseReview", "MdReview", "PeerToPeerReview"].includes(reviewType);
};

const topLevelNodeGenerator = (nodeDisplayName: string) => `<p><strong>${nodeDisplayName}</strong></p>`;

const innerItemGenerator = (nodeText: string) => `<li>${nodeText}</li>`;

/* 
  This works as a unique ID for each highlighted paragraph, it must match with the hash generated 
  when initializing highlights for the PdfViewer.
*/
const createAtributionHighlightHash = (findingName: string, reviewNoteFinding: AutomatedReviewNoteFinding): string => {
  const hashValue = hash
    .sha256()
    .update(JSON.stringify({ name: findingName, location: reviewNoteFinding.highlights }))
    .digest("hex");

  return hashValue.slice(0, 8);
};

const attributionGenerator = (findingName: string, attachmentName: string, finding: AutomatedReviewNoteFinding) => {
  if (!attachmentName) {
    return "";
  }

  const page = finding.attribution.pageStart ? `, p${finding.attribution.pageStart}` : "";
  const id = finding.highlights ? `, ${createAtributionHighlightHash(findingName, finding)}` : "";

  return `[${attachmentName}${page}${id}]`;
};

// Recursively handle nested structure
const renderNestedContent = (
  automatedReviewNoteNode: AutomatedReviewNoteNode,
  attachments: Attachment[] | undefined
): string => {
  let result = "<ul>";

  if (!automatedReviewNoteNode.children?.length) {
    // Handle terminal node
    for (const item of automatedReviewNoteNode.nodes || []) {
      const attachmentName =
        attachments?.find((attachment) => attachment.id === item.attribution.attachmentId)?.name || "";

      result += innerItemGenerator(
        `${item.text} ${attributionGenerator(automatedReviewNoteNode.findingName, attachmentName, item)}`
      );
    }

    result += `</ul></li>`;
  } else {
    // Recursively handle nested objects
    for (const child of automatedReviewNoteNode.children) {
      result += topLevelNodeGenerator(child.displayName || "");
      result += renderNestedContent(child, attachments);
    }
  }

  result += "</ul>";
  return result;
};

export function renderAutomatedReviewNote(
  automatedReviewNote: AutomatedReviewNote,
  attachments: Attachment[] | undefined
): string {
  if (!automatedReviewNote.length) {
    return "";
  }

  let richTextOutput = topLevelNodeGenerator("<u>Clinical Summary</u>");

  // Iterate through top-level keys
  for (const node of automatedReviewNote) {
    // Start the main rendering for each key
    richTextOutput += topLevelNodeGenerator(node.displayName || "");

    richTextOutput += renderNestedContent(node, attachments);
  }

  richTextOutput += topLevelNodeGenerator("<u>Decision Explanation</u>");

  return richTextOutput;
}
