import { Body1 } from "@coherehealth/common";
import { ReviewChatbotFinding } from "@coherehealth/core-platform-api";
import ChatbotAnswerSources from "./ChatbotAnswerSources";
import { ChatbotFeedbackMechanism } from "./ChatbotFeedbackMechanism";
import { useChatbotStyles } from "./chatbotReviewAssistantStyles";
import Markdown from "react-markdown";
import { IReviewChatbotFindingHolder } from "components/ClinicalReview/Review/hooks/useAutomatedReviewChatbotFindings";

interface Props {
  setFeedback: (id: string, updatedChatbotFinding: Partial<ReviewChatbotFinding>) => void;
  findingHolder: IReviewChatbotFindingHolder;
}

export default function ChatbotIncomingRegularResponse({ setFeedback, findingHolder }: Props) {
  const classes = useChatbotStyles({});

  return (
    <div className={classes.incomingMessageWrapper}>
      <div className={classes.incomingMessage}>
        <Body1>
          <Markdown className={classes.incomingMessageParagraph}>{findingHolder.chatbotfinding.value ?? ""}</Markdown>
        </Body1>

        <ChatbotAnswerSources chatbotFinding={findingHolder.chatbotfinding} />

        <ChatbotFeedbackMechanism
          chatbotFinding={findingHolder.chatbotfinding}
          setFeedback={(updatedChatbotFinding: Partial<ReviewChatbotFinding>) =>
            setFeedback(findingHolder.id, updatedChatbotFinding)
          }
        />
      </div>
    </div>
  );
}
