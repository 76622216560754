import React, { useState, useCallback, Dispatch, SetStateAction, useEffect, useMemo, useContext } from "react";
import { useTheme, Tab, Box, ButtonBase, makeStyles, Grid } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { StyledTabs } from "./ClinicalReviewPage";
import GuidelineReview from "../../ServiceRequest/ReviewSection/GuidelineReview/GuidelineReview";
import {
  ReviewType,
  ServiceRequestResponse,
  SearchInfo,
  CopyPasteInfo,
  usePostUserHighlights,
  AttachmentGuidelineTextHighlightsSnapshot,
  AuthorizationResponse,
  PatientStayDate,
  ContinuationConfigurationResponse,
  RecommendChangeRuleAction,
} from "@coherehealth/core-platform-api";
import MDReviewComponent from "components/ServiceRequest/ReviewSection/MDReview/MDReview";
import AuditReviewComponent from "components/ServiceRequest/ReviewSection/AuditReview/AuditReview";
import OOSEmailComponent from "components/ServiceRequest/ReviewSection/AuditReview/OOSEmail";
import PeerToPeerReviewComponent from "components/ServiceRequest/ReviewSection/MDReview/PeerToPeerReview";
import NurseReviewComponent from "components/ServiceRequest/ReviewSection/NurseReview/NurseReview";
import OutOfNetworkReviewComponent, {
  OutOfNetworkReviewEditable,
} from "components/ServiceRequest/ReviewSection/OutOfNetworkReview/OutOfNetworkReview";
import {
  Body2,
  InformativeModal,
  UserClickInfoClinicalReviewPage,
  useFeature,
  HighlightStateContext,
  CurrentReviewTabTypes,
  parseDateFromISOStringWithoutFallback,
  parseDateFromISOString,
  plusDays,
  useConfiguration,
} from "@coherehealth/common";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import { useReview, ReviewUpdate } from "../reviewUtils/useReviews";
import ApprovedServiceRequestImage from "../../images/ApprovedServiceRequestImage";
import { trackUserActivity } from "util/userActivityTracker";
import { useAuthorized } from "authorization";
import { ServiceCase, useGetServiceRequestCases, useStartWorkingCase } from "@coherehealth/qm-api";
import config from "api/config";
import { useLocation } from "react-router-dom";
import {
  useDischargeDetails,
  useGetUser,
  useIsQmUserAudited,
  getOOSEmailSubject,
  getOOSEmailBody,
  getReviewTabLabel,
  tabProps,
} from "../reviewUtils/utils";
import ReviewRedirectModal from "../reviewUtils/ReviewRedirectModal";
import usePatientStayDateOnCRR from "components/ServiceRequest/PatientStay/usePatientStayDateOnCRR";
import useOutreachAttempt from "components/ServiceRequest/ReviewSection/useOutreachAttempt";
import { getUpdatedStayDateRanges } from "components/AuthBuilder/FillFormsAuthSubmission/PatientStayCard";
import { isTerminalStatus } from "util/serviceRequest";
import { isSameDay } from "date-fns";
import usePatientStayDatesControl from "./hooks/usePatientStayCardControl";
import { warn } from "logger";
import { useEmbeddedOonReview } from "../reviewUtils/useEmbeddedOonReview";
import { useOutOfNetworkReviewManager } from "../reviewUtils/useOutOfNetworkReviewManager";
import GenericReviewHistory from "components/ServiceRequest/ReviewSection/GenericReviewHistory/GenericReviewHistory";
import { useAutomatedReviewNoteEditorState } from "./hooks/useAutomatedReviewNoteEditorState";
import { useAutomatedReviewChatbotFindings } from "./hooks/useAutomatedReviewChatbotFindings";
import ChatbotReviewAssistant from "components/ServiceRequest/ReviewSection/common/ChatbotReviewAssistant";
import { useSynchonousReviewChatbotFindings } from "./hooks/useSynchronousReviewChatbotFindings";
import { useSaveReviewChatbotFindings } from "./hooks/useSaveReviewChatbotFindings";
import { ClinicalReviewContext } from "../reviewUtils/clinicalReviewPageUtils";

interface CurrentClinicalReviewProps {
  review: ReviewType;
  existingReviews: ReviewType[] | null;
  serviceRequest: ServiceRequestResponse;
  authorization?: AuthorizationResponse;
  selectedGuidelines?: string[];
  setSelectedGuidelines?: Dispatch<SetStateAction<string[]>>;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  searchInfos?: SearchInfo[];
  setSearchInfos?: Dispatch<React.SetStateAction<SearchInfo[]>>;
  newDenialsWorkflow?: boolean;
  copiedAttachmentText?: CopyPasteInfo;
  pastedAttachmentTexts?: CopyPasteInfo[];
  setPastedAttachmentTexts?: Dispatch<React.SetStateAction<CopyPasteInfo[] | undefined>>;
  openAttachment?: (attachmentId: string) => void;
  attachmentGuidelineTextHighlightsSnapshot?: AttachmentGuidelineTextHighlightsSnapshot;
  facilityBasedFeatureEnabled?: boolean;
  continuationConfiguration?: ContinuationConfigurationResponse | null;
  checkEmptyRequestedLoc?: boolean;
  hasReviewRedirectRule?: boolean;
  reviewRedirectRule?: RecommendChangeRuleAction | undefined;
  showAutoApprovalModal: boolean;
  setAutoApprovalModalDismissed: React.Dispatch<React.SetStateAction<boolean>>;
  showAutoApprovalInfoBanner: boolean;
  openAttachmentByName?: (name: string) => void;
}

interface CurrentClinicalReviewContentProps extends Omit<CurrentClinicalReviewProps, "review"> {
  currentReview: ReviewType;
  existingReviews: ReviewType[] | null;
  setReview: ReviewUpdate;
}

const CurrentClinicalReview = ({ review, ...otherProps }: CurrentClinicalReviewProps) => {
  const [currentReview, setReview] = useReview({ reviewId: review.id, reviewType: review.reviewType });
  if (!currentReview || !setReview) {
    return null;
  }
  return <CurrentClinicalReviewContent currentReview={currentReview} setReview={setReview} {...otherProps} />;
};

const CurrentClinicalReviewContent = ({
  currentReview,
  existingReviews,
  serviceRequest,
  authorization,
  userClickInfoTracking,
  setUserClickInfoTracking,
  searchInfos,
  setSearchInfos,
  newDenialsWorkflow,
  setReview,
  copiedAttachmentText,
  pastedAttachmentTexts,
  setPastedAttachmentTexts,
  openAttachment,
  attachmentGuidelineTextHighlightsSnapshot,
  facilityBasedFeatureEnabled,
  continuationConfiguration,
  checkEmptyRequestedLoc,
  hasReviewRedirectRule,
  reviewRedirectRule,
  showAutoApprovalModal,
  setAutoApprovalModalDismissed,
  showAutoApprovalInfoBanner,
  openAttachmentByName,
}: CurrentClinicalReviewContentProps) => {
  const theme = useTheme();
  const [redirectRuleModalOpen, setRedirectRuleModalOpen] = useState<boolean>(false);
  const setSelectedGuidelines = (guidelineIds: string[]) => setReview({ selectedGuidelineIds: guidelineIds });
  const user = useGetUser();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [serviceCase, setServiceCase] = useState<ServiceCase>();

  const postDecisionP2PExpanded = useFeature("postDecisionP2PExpanded");
  const suppressRedirectModalDisplay = useFeature("updatedClinicalReviewNudges");
  const expandedReviewStayDatesEditsEnabled = useFeature("expandedReviewStayDatesEdits");
  const reviewHistoryAboveCurrentReviewNoteEnabled = useFeature("reviewHistoryAboveCurrentReviewNote");
  const auditReviewsAllowed = useFeature("auditReviews");
  const canAuditReview = useAuthorized("AUDIT_REVIEW") && auditReviewsAllowed;
  const oonReviewConfig = useConfiguration(
    "outOfNetworkReviewConfiguration",
    serviceRequest.healthPlanName,
    serviceRequest.delegatedVendor
  );
  const reviewChatBotConfig = useConfiguration(
    "reviewChatbotConfiguration",
    serviceRequest?.healthPlanName,
    serviceRequest?.delegatedVendor
  );
  const reviewChatbotEnabled = useFeature("reviewChatbotEnabled");
  const reviewChatbotBaseUrl = process.env.REACT_APP_REVIEW_CHATBOT_URL || "";
  const isReviewChatbotEnabled =
    Boolean(reviewChatBotConfig?.enabled) && reviewChatbotEnabled && Boolean(reviewChatbotBaseUrl);
  const reviewChatbotUrl = reviewChatbotBaseUrl.concat(serviceRequest.id);
  const connectToQM = search.has("connectToQM");
  const reviewLabel = getReviewTabLabel(currentReview.reviewType, serviceRequest.authStatus, postDecisionP2PExpanded);
  const classes = useTabStyles();

  const [sendOptionalDecisionLetter, setSendOptionalDecisionLetters] = useState<boolean>(false);

  const [noClinicalInformationProvided, setNoClinicalInformationProvided] = useState(
    currentReview?.noClinicalInformationProvided ?? serviceRequest?.noClinicalInformationProvided
  );

  //review chatbot state and hooks
  const { chatbotFindings, setChatbotFindings, appendSynchronousChatbotFinding } = useAutomatedReviewChatbotFindings(
    serviceRequest.id,
    currentReview.id,
    isReviewChatbotEnabled
  );

  const { handleChatbotMessage } = useSynchonousReviewChatbotFindings(
    serviceRequest.id,
    currentReview.id,
    appendSynchronousChatbotFinding
  );

  const { saveReviewChatbotFindings } = useSaveReviewChatbotFindings({ chatbotFindings, isReviewChatbotEnabled });

  const { automatedReviewNoteEditorState, setAutomatedReviewNoteEditorState } = useAutomatedReviewNoteEditorState({
    currentReview,
    attachments: serviceRequest?.attachments,
  });

  useEffect(() => {
    setReview({ noClinicalInformationProvided: noClinicalInformationProvided });
  }, [noClinicalInformationProvided, setReview]);

  const { linking, closeLinking, getCurrentLinked, getSubmitPayload, rightColumnTab, setRightColumnTab } =
    useContext(HighlightStateContext);

  const {
    reviewRightColumnPositionState: { handleTabChange, getTopLevelScrollDivRef },
  } = useContext(ClinicalReviewContext);

  const setRightColumnTabIndex = useCallback(
    (tabIndex: CurrentReviewTabTypes) => {
      setRightColumnTab?.(tabIndex);
    },
    [setRightColumnTab]
  );
  const closeAutoapprovalModal = useCallback(() => {
    setRightColumnTabIndex("REVIEW");
    setReview({
      decisionReasoning: "Meets auto approval criteria",
      criteriaDecision: "AUTO_APPROVED",
      /* TODO: fix the ReviewUpdate type. Function types are hard to union (contravariant) and we're not quite set up for success with this type - please fix! */
      // @ts-ignore
      reviewOutcome: "APPROVED",
    });
    trackUserActivity("autoapproval modal");
    setAutoApprovalModalDismissed(true);
  }, [setReview, setRightColumnTabIndex, setAutoApprovalModalDismissed]);

  const usePatientStayDateProps = usePatientStayDateOnCRR({
    version: "RequestedStay",
    serviceStartDate: serviceRequest.startDate,
    patientStayDates: serviceRequest.patientStayDates,
    checkEmptyRequestedLoc: checkEmptyRequestedLoc,
    previouslyDecisionedDays: authorization?.patientStayDates?.filter(({ reviewStatus = "" }) =>
      ["APPROVED", "DENIED"].includes(reviewStatus)
    ),
    admissionDate: serviceRequest.admissionDate,
  });
  const dischargeDetailsProps = useDischargeDetails(authorization);

  const [lastRequestedDate, indexWithLastRequestedDate] = useMemo<[Date | null, number]>(() => {
    let lastRequestedDate: Date | null = null;
    let indexWithLastRequestedDate = -1;
    usePatientStayDateProps.patientStayDateRanges.forEach(({ rangeEndDate }, index) => {
      if (!lastRequestedDate || (rangeEndDate && rangeEndDate > lastRequestedDate)) {
        lastRequestedDate = rangeEndDate;
        indexWithLastRequestedDate = index;
      }
    });
    return [lastRequestedDate, indexWithLastRequestedDate];
  }, [usePatientStayDateProps.patientStayDateRanges]);

  // Update the discharge date if the last patient stay date changes
  useEffect(
    () => {
      if (dischargeDetailsProps.isDischarged && expandedReviewStayDatesEditsEnabled) {
        if (
          !!lastRequestedDate &&
          dischargeDetailsProps.dischargeDetails.actualDischargeDate &&
          !isSameDay(plusDays(1, lastRequestedDate), dischargeDetailsProps.dischargeDetails.actualDischargeDate)
        ) {
          dischargeDetailsProps.setDischargeDetails((prev) => ({
            ...prev,
            actualDischargeDate: !!lastRequestedDate ? plusDays(1, lastRequestedDate) : prev.actualDischargeDate,
          }));
        }
      }
    },
    // This effect should only run if the latest requested day changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastRequestedDate?.getTime()]
  );

  // update the last patient stay date if the discharge date changes
  useEffect(() => {
    if (
      expandedReviewStayDatesEditsEnabled &&
      dischargeDetailsProps.isDischarged &&
      dischargeDetailsProps.dischargeDetails.actualDischargeDate
    ) {
      if (
        indexWithLastRequestedDate > -1 &&
        !!lastRequestedDate &&
        !isSameDay(plusDays(1, lastRequestedDate), dischargeDetailsProps.dischargeDetails.actualDischargeDate)
      ) {
        usePatientStayDateProps.setPatientStayDateRanges((prev) => {
          const updatedDateRanges = [...prev];
          updatedDateRanges[indexWithLastRequestedDate].rangeEndDate = !!dischargeDetailsProps.dischargeDetails
            .actualDischargeDate
            ? plusDays(-1, dischargeDetailsProps.dischargeDetails.actualDischargeDate)
            : updatedDateRanges[indexWithLastRequestedDate].rangeEndDate;
          return updatedDateRanges;
        });
      }
    }
    // This effect should only run if the actual discharge date changes, do not want to run if the patient stay dates change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dischargeDetailsProps.dischargeDetails.actualDischargeDate?.getTime()]);

  const { data: serviceCases, refetch: getServiceCases } = useGetServiceRequestCases({
    id: serviceRequest.id,
    base: config.QM_SERVICE_API_URL,
    queryParams: {
      caseType: "P2P_REVIEW",
    },
    lazy: true,
  });

  const { mutate: updatedServiceCase } = useStartWorkingCase({
    id: "",
    base: `${config.QM_SERVICE_API_URL}`,
  });
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (linking && event.key === "Enter") {
        event.preventDefault();
        closeLinking?.();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [closeLinking, linking]);

  useEffect(() => {
    if (connectToQM && config.HAS_QM_ENVIRONMENT && currentReview.reviewType === "PeerToPeerReview" && !serviceCase) {
      getServiceCases();
    }
  }, [connectToQM, currentReview.reviewType, getServiceCases, serviceCase]);

  useEffect(() => {
    if (hasReviewRedirectRule && !suppressRedirectModalDisplay) {
      setRedirectRuleModalOpen(true);
    }
  }, [hasReviewRedirectRule, suppressRedirectModalDisplay]);

  useEffect(() => {
    const assignCase = async () => {
      if (serviceCases && serviceCases.length > 0) {
        const serviceCase = serviceCases[0];
        if (
          user &&
          serviceCase &&
          serviceCase.assignee?.userName?.toLowerCase() !== user?.preferred_username?.toLowerCase()
        ) {
          const serviceCaseId = serviceCases[0]?.id;
          if (serviceCaseId) {
            const assignedServiceCase = await updatedServiceCase(
              {
                serviceCaseId: serviceCaseId,
                assignee: {
                  name: user?.name || undefined,
                  userName: user?.preferred_username || undefined,
                },
              },
              { pathParams: { id: serviceCaseId } }
            );
            setServiceCase(assignedServiceCase);
          }
        } else {
          setServiceCase(serviceCase);
        }
      }
    };
    assignCase();
  }, [serviceCases, updatedServiceCase, user]);

  const { mutate: postUserHighlights, loading: submittingHighlights } = usePostUserHighlights({});
  const submitHighlights = async () => {
    if (getSubmitPayload) {
      const highlightPayload = getSubmitPayload(warn);
      if (highlightPayload) {
        await postUserHighlights(highlightPayload);
      }
    }
  };
  const numCurrentLinked = (linking && getCurrentLinked?.()) || 0;
  const isQmUserAudited = useIsQmUserAudited();

  const [oosEmailSubject, setOOSEmailSubject] = useState<string>(getOOSEmailSubject(serviceRequest));
  const [oosEmailBody, setOOSEmailBody] = useState<string>(getOOSEmailBody(serviceRequest));
  const canShowOOSEmailTab = currentReview.reviewType === "OutOfScopeAuditReview" && canAuditReview;
  const outreachAttemptProps = useOutreachAttempt({
    serviceRequest,
    reviewId: currentReview.id,
    draftOutreachAttempt: currentReview?.draftOutreachAttempt,
  });

  const previouslyDecisionedDates = useMemo(
    () =>
      authorization?.serviceRequestsOnAuth
        ?.filter((serviceRequest) => {
          return (
            isTerminalStatus(serviceRequest) &&
            serviceRequest.patientStayDates &&
            serviceRequest.patientStayDates.length > 0
          );
        })
        ?.map((serviceRequest) => serviceRequest.patientStayDates)
        ?.filter((elem): elem is PatientStayDate[] => !!elem)
        ?.flat()
        ?.sort((a, b) => {
          if (typeof a?.date === "string" && typeof b?.date === "string") {
            return Date.parse(a.date) - Date.parse(b.date);
          }
          return 0;
        })
        ?.map((e) => parseDateFromISOStringWithoutFallback(e.date))
        ?.filter((e?: Date): e is Date => !!e),
    [authorization]
  );
  const setPatientStayDateRanges = usePatientStayDateProps.setPatientStayDateRanges;
  useEffect(() => {
    if (
      !expandedReviewStayDatesEditsEnabled &&
      dischargeDetailsProps.isDischarged &&
      dischargeDetailsProps.dischargeDetails.actualDischargeDate &&
      serviceRequest.admissionDate
    ) {
      setPatientStayDateRanges((prev) =>
        getUpdatedStayDateRanges({
          admissionDate: parseDateFromISOString(serviceRequest.admissionDate),
          dischargeDate: dischargeDetailsProps.dischargeDetails.actualDischargeDate,
          previouslyDecisionedDates,
          currentRequestedStayDateRanges: prev,
        })
      );
    }
  }, [
    expandedReviewStayDatesEditsEnabled,
    dischargeDetailsProps.dischargeDetails.actualDischargeDate,
    dischargeDetailsProps.isDischarged,
    previouslyDecisionedDates,
    serviceRequest.admissionDate,
    setPatientStayDateRanges,
  ]);

  const patientStayManager = usePatientStayDatesControl({
    serviceRequest,
    dischargeDetails: dischargeDetailsProps.dischargeDetails,
    patientStayDateRanges: usePatientStayDateProps.patientStayDateRanges,
    setAdmissionDate: usePatientStayDateProps.setAdmissionDate,
    setIsDischarged: dischargeDetailsProps.setIsDischarged,
    setDischargeDetails: dischargeDetailsProps.setDischargeDetails,
    setPatientStayDateRanges,
    previouslyDecisionedDates,
  });

  const userId = user?.sub;

  const {
    canShowOonTab: canShowExtraOONTab,
    embeddedOonReviewId,
    ...embeddedOonReviewProps
  } = useEmbeddedOonReview({
    serviceRequest,
    currentReviewType: currentReview.reviewType,
    currentReviewId: currentReview.id,
    existingReviews,
    isQmUserAudited,
    userClickInfoTracking,
    searchInfos,
    pastedAttachmentTexts,
    userId,
    setUserClickInfoTracking,
    setSearchInfos,
    currentReview,
  });

  const outOfNetworkReviewManagerProps = useOutOfNetworkReviewManager({
    outOfNetworkReviewId: currentReview.id,
    validateOutreachForm: outreachAttemptProps.validateOutreachForm,
    hasOutreachAttempt: outreachAttemptProps.hasOutreachAttempt,
    outreachAttempt: outreachAttemptProps.outreachAttempt,
    serviceRequest,
    isQmUserAudited,
    saveOutreachAttempt: outreachAttemptProps.saveOutreachAttempt,
    userClickInfoTracking,
    searchInfos,
    pastedAttachmentTexts,
    userId,
    setUserClickInfoTracking,
    setSearchInfos,
    outreachAttemptLoading: outreachAttemptProps.outreachAttemptLoading,
    overrideRequired: oonReviewConfig?.networkStatusOverrideRequired,
  });

  const isOutOfNetworkReview = currentReview.reviewType === "OutOfNetworkReview";
  useEffect(() => {
    if (isOutOfNetworkReview) {
      setRightColumnTabIndex("REVIEW");
    } else {
      setRightColumnTabIndex("GUIDELINES");
    }
  }, [isOutOfNetworkReview, setRightColumnTabIndex]);

  return (
    <>
      {linking ? (
        <div
          style={{
            height: "48px",
            background: "#41477A",
            color: theme.palette.primary.contrastText,
            paddingLeft: theme.spacing(2),
            paddingTop: "15px",
            position: "sticky",
            top: "0px",
            zIndex: 2,
            display: "inline-flex",
            width: "100%",
          }}
        >
          <Body2>Select indications to link this highlight to</Body2>
          <Body2 style={{ marginLeft: theme.spacing(3), opacity: 0.75 }}>{`Linked to ${numCurrentLinked} indication${
            numCurrentLinked !== 1 ? "s" : ""
          }`}</Body2>
          <ButtonBase
            style={{ height: "19px", marginLeft: "auto", marginRight: theme.spacing(2) }}
            onClick={closeLinking}
          >
            <CheckIcon />
            <Body2 style={{ marginLeft: theme.spacing(1) }}>Done</Body2>
          </ButtonBase>
        </div>
      ) : rightColumnTab ? (
        <StyledTabs
          value={rightColumnTab}
          onChange={(_, index: CurrentReviewTabTypes) => {
            handleTabChange(rightColumnTab, index);
            setRightColumnTabIndex(index);
          }}
          aria-label="Clinical Review tabs"
          style={{
            // Left-align tab and body text
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 2,
          }}
        >
          {canShowExtraOONTab && (
            <Tab
              label={getReviewTabLabel("OutOfNetworkReview")}
              {...tabProps("OON_REVIEW")}
              id="oon-review-tab"
              className={classes.panelTab}
            />
          )}
          {!isOutOfNetworkReview && (
            <Tab label={"Guidelines"} {...tabProps("GUIDELINES")} id="guidelines-tab" className={classes.panelTab} />
          )}
          <Tab label={reviewLabel} {...tabProps("REVIEW")} id="review-tab" className={classes.panelTab} />
          {canShowOOSEmailTab && (
            <Tab label={"OOS Email"} {...tabProps("OOSEMAIL")} id="oos-email-tab" className={classes.panelTab} />
          )}
          {isReviewChatbotEnabled && (
            <Tab
              label="Review Assistant"
              {...tabProps("REVIEW_ASSISTANT")}
              id="review-assist-tab"
              className={classes.panelTab}
            />
          )}
        </StyledTabs>
      ) : null}
      <div
        ref={getTopLevelScrollDivRef(rightColumnTab)}
        style={{ height: "calc(100% - 49px)", overflowY: rightColumnTab !== "REVIEW_ASSISTANT" ? "scroll" : undefined }}
      >
        {showAutoApprovalInfoBanner && (
          <div
            style={{
              padding: theme.spacing(3, 2),
              color: theme.palette.success.main,
              display: "flex",
              alignItems: "center",
            }}
          >
            <SuccessIcon />
            <Box m={0.5} />
            <Body2>This request meets auto-approval criteria</Body2>
          </div>
        )}
        {rightColumnTab === "GUIDELINES" && (
          <div
            style={{
              padding: theme.spacing(1, 3, 3, 3),
              height: "100%",
            }}
          >
            <GuidelineReview
              review={currentReview}
              noClinicalInformationProvided={noClinicalInformationProvided}
              setNoClinicalInformationProvided={setNoClinicalInformationProvided}
              serviceRequest={serviceRequest}
              setSelectedGuidelines={setSelectedGuidelines}
              openAttachment={openAttachment}
              id="tabpanel-0"
            />
          </div>
        )}
        {rightColumnTab === "REVIEW" && (
          <Grid container direction="column">
            {reviewHistoryAboveCurrentReviewNoteEnabled && <GenericReviewHistory authorization={authorization} />}

            {currentReview.reviewType === "NurseReview" ? (
              <NurseReviewComponent
                serviceRequest={serviceRequest}
                nurseReview={currentReview}
                expanded
                clinicalReviewView
                userClickInfoTracking={userClickInfoTracking}
                setUserClickInfoTracking={setUserClickInfoTracking}
                searchInfos={searchInfos}
                setSearchInfos={setSearchInfos}
                copiedAttachmentText={copiedAttachmentText}
                pastedAttachmentTexts={pastedAttachmentTexts}
                setPastedAttachmentTexts={setPastedAttachmentTexts}
                submitHighlights={submitHighlights}
                submittingHighlights={submittingHighlights}
                getHighlightPayload={getSubmitPayload}
                attachmentGuidelineTextHighlightsSnapshot={attachmentGuidelineTextHighlightsSnapshot}
                isQmUserAudited={isQmUserAudited}
                facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
                continuationConfiguration={continuationConfiguration}
                checkEmptyRequestedLoc={checkEmptyRequestedLoc}
                authorization={authorization}
                {...usePatientStayDateProps}
                {...dischargeDetailsProps}
                {...outreachAttemptProps}
                patientStayManager={patientStayManager}
                sendOptionalDecisionLetter={sendOptionalDecisionLetter}
                setSendOptionalDecisionLetter={setSendOptionalDecisionLetters}
                canShowExtraOONTab={canShowExtraOONTab}
                embeddedOonReviewId={embeddedOonReviewId}
                disableFinishOonModalButton={embeddedOonReviewProps.disableFinishModalButton}
                submitEmbeddedOonReview={embeddedOonReviewProps.submitOutOfNetworkReview}
                needsOonReview={embeddedOonReviewProps.needsOonReview}
                validateOONReview={embeddedOonReviewProps.validateReview}
                saveOutOfNetworkReviewDraft={embeddedOonReviewProps.saveReviewDraft}
                discardOutOfNetworkReview={embeddedOonReviewProps.discardReview}
                oonAllowedReviewOutcomes={embeddedOonReviewProps.oonAllowedReviewOutcomes}
                oonOutreachAttemptProps={embeddedOonReviewProps.outreachAttemptProps}
                automatedReviewNoteEditorState={automatedReviewNoteEditorState}
                setAutomatedReviewNoteEditorState={setAutomatedReviewNoteEditorState}
                saveReviewChatbotFindings={saveReviewChatbotFindings}
              />
            ) : currentReview.reviewType === "OutOfNetworkReview" ? (
              <OutOfNetworkReviewComponent
                oonReviewConfig={oonReviewConfig}
                serviceRequest={serviceRequest}
                outOfNetworkReview={currentReview}
                expanded
                clinicalReviewView
                copiedAttachmentText={copiedAttachmentText}
                setPastedAttachmentTexts={setPastedAttachmentTexts}
                facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
                authorization={authorization}
                outreachAttemptProps={outreachAttemptProps}
                {...outOfNetworkReviewManagerProps}
                saveReviewChatbotFindings={saveReviewChatbotFindings}
              />
            ) : currentReview.reviewType === "MdReview" && serviceRequest ? (
              <MDReviewComponent
                nextReviewDate={authorization?.nextReviewDate}
                serviceRequest={serviceRequest}
                mdReview={currentReview}
                existingReviews={existingReviews}
                newDenialsWorkflow={newDenialsWorkflow}
                expanded
                clinicalReviewView
                userClickInfoTracking={userClickInfoTracking}
                setUserClickInfoTracking={setUserClickInfoTracking}
                searchInfos={searchInfos}
                setSearchInfos={setSearchInfos}
                copiedAttachmentText={copiedAttachmentText}
                pastedAttachmentTexts={pastedAttachmentTexts}
                setPastedAttachmentTexts={setPastedAttachmentTexts}
                submitHighlights={submitHighlights}
                submittingHighlights={submittingHighlights}
                getHighlightPayload={getSubmitPayload}
                attachmentGuidelineTextHighlightsSnapshot={attachmentGuidelineTextHighlightsSnapshot}
                isQmUserAudited={isQmUserAudited}
                facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
                continuationConfiguration={continuationConfiguration}
                checkEmptyRequestedLoc={checkEmptyRequestedLoc}
                authorization={authorization}
                {...usePatientStayDateProps}
                {...dischargeDetailsProps}
                {...outreachAttemptProps}
                patientStayManager={patientStayManager}
                automatedReviewNoteEditorState={automatedReviewNoteEditorState}
                setAutomatedReviewNoteEditorState={setAutomatedReviewNoteEditorState}
                saveReviewChatbotFindings={saveReviewChatbotFindings}
              />
            ) : currentReview.reviewType === "PeerToPeerReview" ? (
              <PeerToPeerReviewComponent
                nextReviewDate={authorization?.nextReviewDate}
                serviceRequest={serviceRequest}
                serviceCase={serviceCase}
                p2pReview={currentReview}
                newDenialsWorkflow={newDenialsWorkflow}
                expanded
                clinicalReviewView
                userClickInfoTracking={userClickInfoTracking}
                setUserClickInfoTracking={setUserClickInfoTracking}
                searchInfos={searchInfos}
                setSearchInfos={setSearchInfos}
                copiedAttachmentText={copiedAttachmentText}
                pastedAttachmentTexts={pastedAttachmentTexts}
                setPastedAttachmentTexts={setPastedAttachmentTexts}
                submitHighlights={submitHighlights}
                submittingHighlights={submittingHighlights}
                getHighlightPayload={getSubmitPayload}
                attachmentGuidelineTextHighlightsSnapshot={attachmentGuidelineTextHighlightsSnapshot}
                existingReviews={existingReviews}
                facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
                continuationConfiguration={continuationConfiguration}
                checkEmptyRequestedLoc={checkEmptyRequestedLoc}
                authorization={authorization}
                {...usePatientStayDateProps}
                {...dischargeDetailsProps}
                {...outreachAttemptProps}
                sendOptionalDecisionLetter={sendOptionalDecisionLetter}
                setSendOptionalDecisionLetter={setSendOptionalDecisionLetters}
                saveReviewChatbotFindings={saveReviewChatbotFindings}
              />
            ) : (currentReview.reviewType === "AuditReview" && canAuditReview) ||
              currentReview.reviewType === "NewHireAuditReview" ||
              currentReview.reviewType === "OutOfScopeAuditReview" ? (
              <AuditReviewComponent
                serviceRequest={serviceRequest}
                auditReview={currentReview}
                expanded
                userClickInfoTracking={userClickInfoTracking}
                setUserClickInfoTracking={setUserClickInfoTracking}
                searchInfos={searchInfos}
                setSearchInfos={setSearchInfos}
                copiedAttachmentText={copiedAttachmentText}
                pastedAttachmentTexts={pastedAttachmentTexts}
                setPastedAttachmentTexts={setPastedAttachmentTexts}
                submitHighlights={submitHighlights}
                submittingHighlights={submittingHighlights}
                getHighlightPayload={getSubmitPayload}
                attachmentGuidelineTextHighlightsSnapshot={attachmentGuidelineTextHighlightsSnapshot}
              />
            ) : null}
          </Grid>
        )}
        {rightColumnTab === "OOSEMAIL" && canShowOOSEmailTab ? (
          <OOSEmailComponent
            auditReview={currentReview}
            expanded
            oosEmailSubject={oosEmailSubject}
            setOOSEmailSubject={setOOSEmailSubject}
            oosEmailBody={oosEmailBody}
            setOOSEmailBody={setOOSEmailBody}
          />
        ) : null}

        {rightColumnTab === "OON_REVIEW" && canShowExtraOONTab && embeddedOonReviewId && (
          <OutOfNetworkReviewEditable
            oonReviewConfig={oonReviewConfig}
            serviceRequest={serviceRequest}
            clinicalReviewView
            showNeedsOONToggle
            hideHeader
            hideFooter
            copiedAttachmentText={copiedAttachmentText}
            setPastedAttachmentTexts={setPastedAttachmentTexts}
            facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
            authorization={authorization}
            outOfNetworkReviewId={embeddedOonReviewId}
            {...embeddedOonReviewProps}
          />
        )}
        {rightColumnTab === "REVIEW_ASSISTANT" && (
          <ChatbotReviewAssistant
            chatbotFindings={chatbotFindings}
            setChatbotFindings={setChatbotFindings}
            reviewChatbotUrl={reviewChatbotUrl}
            handleChatbotMessage={handleChatbotMessage}
            currentReview={currentReview}
          />
        )}
      </div>
      <InformativeModal
        style={{ zIndex: 1000 }} // This is so it shows underneath the InformativeModal from ClinicalReviewPage
        open={showAutoApprovalModal || false}
        onClose={closeAutoapprovalModal}
        headerText={"Review meets auto approval criteria"}
        subHeaderText={"You may approve this case. No futher review necessary"}
        icon={<ApprovedServiceRequestImage />}
        primaryButtonText={"Continue to review"}
        primaryButtonAction={closeAutoapprovalModal}
      />
      {reviewRedirectRule && !suppressRedirectModalDisplay && (
        <ReviewRedirectModal
          open={redirectRuleModalOpen}
          setOpen={setRedirectRuleModalOpen}
          redirectRule={reviewRedirectRule}
        />
      )}
    </>
  );
};

export const useTabStyles = makeStyles(() => ({
  panelTab: {
    "&:hover span": {
      opacity: 0.7,
    },
    whiteSpace: "nowrap",
  },
}));

export default CurrentClinicalReview;
