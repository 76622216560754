import React, { ComponentProps } from "react";

import { H2, Modal, TertiaryButton, CopyToClipboard } from "@coherehealth/common";
import { Box, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import { useGetUser } from "./ClinicalReview/reviewUtils/utils";
import ClipboardMissingInfo from "./images/ClipboardMissingInfo";

export interface ClinicalAuthErrorDetails {
  status: number;
  message: string;
  serviceRequestId?: string;
}

interface Props {
  isOpen: boolean;
  setIsOpen(arg0: boolean): void;
  errorDetails: ClinicalAuthErrorDetails;
}

export default function DetailedHardRedirectClinicalAuthModal({ isOpen, setIsOpen, errorDetails }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const activeUser = useGetUser();

  let { status, message, serviceRequestId } = errorDetails;

  const stringifiedErrorDetails =
    `Error code: ${status}\n` +
    `Timestamp: ${new Date().toLocaleString("en-US", { timeZone: "America/New_York" })}\n` +
    `Active user: ${activeUser?.email || activeUser?.sub || "Unidentifiable User"}\n` +
    `Service request ID: ${serviceRequestId}\n` +
    `Details: ${message}`;

  return (
    <Modal open={isOpen} hideX>
      <Grid container spacing={3} component="form">
        <CenteredRow>
          <ClipboardMissingInfo />
        </CenteredRow>
        <CenteredRow>
          <H2>Contact Cohere Support to adjust your request</H2>
        </CenteredRow>
        <CenteredRow style={{ display: "flex", paddingBottom: ".4rem" }}>
          <Box
            sx={{
              padding: "8px",
              border: "1px solid #E5E5E5",
              width: "100%",
              height: "115px",
              bgcolor: "#F5F5F5",
              borderRadius: "4px",
              p: 1,
              fontFamily: "Gilroy-SemiBold",
              fontSize: "13px",
              lineHeight: "1.2307",
              whiteSpace: "pre-wrap",
              overflow: "auto",
            }}
          >
            {stringifiedErrorDetails}
          </Box>
        </CenteredRow>
        <Row style={{ textAlign: "right", alignItems: "right", paddingTop: "0" }}>
          <CopyToClipboard
            text={stringifiedErrorDetails}
            message={"Copy error report"}
            onClick={() => {
              enqueueSnackbar("Copied to clipboard", { variant: "success" });
            }}
          />
        </Row>
        <Row>
          <Divider style={{ backgroundColor: "#E5E5E5" }} />
        </Row>
        <CenteredRow>
          <TertiaryButton
            onClick={() => {
              setIsOpen(false);
              window.location.reload();
            }}
          >
            Go back
          </TertiaryButton>
        </CenteredRow>
      </Grid>
    </Modal>
  );
}

const CenteredRow = (props: ComponentProps<typeof Grid>) => (
  <Grid style={{ textAlign: "center", alignItems: "center" }} item xs={12} {...props} />
);
const Row = (props: ComponentProps<typeof Grid>) => <Grid item xs={12} {...props} />;
