import { ReviewType } from "@coherehealth/core-platform-api";
import { useCallback, useMemo, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import hash from "hash.js";
import {
  ReviewChatbotWebSocketResponse,
  WebSocketResponseSchema,
} from "components/ClinicalReview/reviewUtils/chatbotWebsocketTypeHelpers";

export type ReviewChatbotWebSocketMessage = {
  user_input: string;
};

const useChatbotReviewWebsocket = (
  wsUrl: string,
  onMessage: (data: ReviewChatbotWebSocketResponse) => void,
  currentReview: ReviewType
) => {
  const [currentUserInput, setCurrentUserInput] = useState<string | undefined>();
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(wsUrl, {
    onMessage: (event) => {
      try {
        // amazonq-ignore-next-line
        const parsedData = JSON.parse(event.data);
        const validatedData: ReviewChatbotWebSocketResponse = WebSocketResponseSchema.parse(parsedData);

        onMessage(validatedData);
        setCurrentUserInput(undefined);
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    },
    shouldReconnect: () => true,
    reconnectAttempts: 5,
    reconnectInterval: 3000,
  });

  const sessionId = useMemo(() => {
    const unhashedSessionId = `${currentReview.id}-${currentReview.reviewerDetails?.reviewerId || ""}`;
    return hash.sha256().update(unhashedSessionId).digest("hex");
  }, [currentReview]);

  const sendChatbotMessage = useCallback(
    (message: ReviewChatbotWebSocketMessage) => {
      sendJsonMessage({
        ...message,
        session_id: sessionId,
      });
      setCurrentUserInput(message.user_input);
    },
    [sendJsonMessage, sessionId]
  );

  return {
    isConnected: readyState === ReadyState.OPEN,
    sendChatbotMessage,
    lastMessage: lastJsonMessage,
    currentUserInput: currentUserInput,
  };
};

export { useChatbotReviewWebsocket };
