import { Box, IconButton } from "@material-ui/core";
import { makeStyles } from "tss-react/mui";
import { useEffect, useRef, useState, ReactNode } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

interface PatientStayTimelineControlsProps {
  children: ReactNode;
  timelineItems: any[];
}

export function PatientStayTimelineControls({ children, timelineItems }: PatientStayTimelineControlsProps) {
  const { classes } = useStyles();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showLeftIndicator, setShowLeftIndicator] = useState(false);
  const [showRightIndicator, setShowRightIndicator] = useState(false);

  const scrollLeft = () => {
    if (scrollRef.current) {
      const containerWidth = scrollRef.current.clientWidth;
      scrollRef.current.scrollLeft -= containerWidth;
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      const containerWidth = scrollRef.current.clientWidth;
      scrollRef.current.scrollLeft += containerWidth;
    }
  };

  const checkScrollIndicators = () => {
    if (scrollRef.current) {
      setShowLeftIndicator(scrollRef.current.scrollLeft > 0);
      const canScrollMore =
        scrollRef.current.scrollWidth > scrollRef.current.clientWidth + scrollRef.current.scrollLeft;
      setShowRightIndicator(canScrollMore);
    }
  };

  useEffect(() => {
    if (scrollRef.current && timelineItems.length > 0) {
      scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
    }
  }, [timelineItems]);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const handleScroll = () => {
        checkScrollIndicators();
      };

      scrollElement.addEventListener("scroll", handleScroll);

      checkScrollIndicators();

      return () => {
        scrollElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [timelineItems]);

  return (
    <Box className={classes.timelineContainer}>
      {showLeftIndicator && (
        <>
          <div className={classes.leftIndicator} id="left-indicator" />
          <IconButton
            className={classes.leftButton}
            onClick={scrollLeft}
            size="small"
            aria-label="scroll left"
            id="left-button"
          >
            <ChevronLeftIcon />
          </IconButton>
        </>
      )}
      {showRightIndicator && (
        <>
          <div className={classes.rightIndicator} id="right-indicator" />
          <IconButton
            className={classes.rightButton}
            onClick={scrollRight}
            size="small"
            aria-label="scroll right"
            id="right-button"
          >
            <ChevronRightIcon />
          </IconButton>
        </>
      )}
      <div ref={scrollRef} className={classes.timelineScroll}>
        {children}
      </div>
    </Box>
  );
}

const useStyles = makeStyles()((theme) => ({
  timelineContainer: {
    position: "relative",
    width: "100%",
    overflowX: "visible",
    overflowY: "hidden",
    "&:hover": {
      "& #left-indicator": {
        opacity: 1,
      },
      "& #right-indicator": {
        opacity: 1,
      },
      "& #left-button": {
        opacity: 1,
      },
      "& #right-button": {
        opacity: 1,
      },
    },
  },
  timelineScroll: {
    display: "flex",
    position: "relative",
    padding: "24px 0px 0px 0px",
    overflowX: "auto",
    overflowY: "hidden",
    scrollBehavior: "smooth",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  leftIndicator: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: 60,
    pointerEvents: "none",
    zIndex: 1,
    background: "linear-gradient(to right, white, transparent)",
    opacity: 0,
    transition: "opacity 0.25s ease",
  },
  rightIndicator: {
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
    width: 60,
    pointerEvents: "none",
    zIndex: 1,
    background: "linear-gradient(to left, white, transparent)",
    opacity: 0,
    transition: "opacity 0.25s ease",
  },
  leftButton: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 2,
    background: theme.palette.primary.main,
    color: "white",
    borderRadius: "0 3px 3px 0",
    opacity: 0,
    transition: "opacity 0.25s ease, background-color 0.25s ease",
    "&:hover": {
      background: theme.palette.primary.light,
    },
  },
  rightButton: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 2,
    background: theme.palette.primary.main,
    color: "white",
    borderRadius: "3px 0 0 3px",
    opacity: 0,
    transition: "opacity 0.25s ease, background-color 0.25s ease",
    "&:hover": {
      background: theme.palette.primary.light,
    },
  },
}));
