import { OnboardingPatientInfo } from "@coherehealth/core-platform-api";

export const isUniqueMemberId = (patientInfo?: OnboardingPatientInfo[]) => {
  let matches = [];
  if (patientInfo?.length && patientInfo[0]?.memberId && patientInfo[0]?.memberDateOfBirth) {
    for (let i = 0; patientInfo?.length >= i; i++) {
      if (patientInfo[i]?.memberId && patientInfo[i]?.memberDateOfBirth) {
        matches.push(patientInfo[i].memberId);
      }
    }
    //only run check if all member fields have been filled in (there are five)
    if (matches.length === membersRequiredCount) {
      return new Set(matches).size === matches?.length;
    } else {
      //return true as to not show error message when not all member fields have been filled in
      return true;
    }
  }
  return true;
};

export const membersRequiredCount = 5;
