import React, { Dispatch, useCallback, useContext, useEffect, useRef, useState } from "react";

import {
  SecondaryButton,
  DATE_FORMAT,
  formatDateStr,
  formatDateToISODate,
  useFeature,
  InlineButton,
  dateStringSort,
  useGetServiceRequestByIdWithFallback,
  H4,
} from "@coherehealth/common";
import {
  Patient,
  ServiceRequestResponse,
  TrackingNumber,
  useGetServiceRequests,
  useGetCandidateSuggestedValues,
  BulkUpdateCandidateFeedbackRequestBody,
  useBulkUpdateCandidateFeedback,
  useGetFaxForwardLinesAndOptions,
  useGetReferralRequests,
  useGetAuthorizations,
  AuthorizationResponse,
  usePatientSearch,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { error as logError, warn as logWarning } from "logger";
import { useSnackbar } from "notistack";
import parse from "date-fns/parse";
import { useBelongsToOpsGroup } from "authorization";

import { CommonFaxSidebarViewProps } from "./common";
import {
  ExistingServiceRequestResults,
  PatientResultsList,
  SearchFields,
  SearchFormState,
} from "./AttachToServiceRequest";
import { useTrackUserInteraction } from "../../../util/userActivityTracker";
import Divider from "@material-ui/core/Divider";
import FaxMetaDataDisplay from "./FaxMetaDataDisplay";
import { SuggestionContext } from "../../AuthBuilder/SuggestionContext";
import {
  compareAuthSuggestionsToSearch,
  compareMemberSuggestionsToSearch,
  initSuggestedAuth,
  initSuggestedMember,
  SuggestedAuth,
  SuggestedMember,
} from "../../../util/suggestionUtils";
import { first } from "lodash";
import { H6 } from "@coherehealth/design-system";
import { Grid } from "@material-ui/core";
import { useGetFaxIntakeConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { AvailableRequestTypes } from "./AttachToServiceRequest/RequestTypeSelectionTab";
import { translateServiceCaseHealthPlan } from "util/serviceCaseUtils";
import AuthorizationRequestResults from "./AttachToServiceRequest/AuthorizationRequestResults";
import { FaxAuthorizationContext } from "util/context/FaxAuthorizationContext";
import NoFaxLookupResultsCard from "./AttachToServiceRequest/NoFaxLookupResults";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 100,
    height: "100%",
  },
  header: {
    padding: theme.spacing(3, 0, 1),
  },
  searchHeader: {
    marginBottom: theme.spacing(3),
  },
  cancelButton: {
    backgroundColor: theme.palette.primary.contrastText,
    width: 560,
  },
  divider: (props: { showRequests?: boolean }) => ({
    margin: props.showRequests ? theme.spacing(3, 0, 3, 0) : theme.spacing(3, 0, 1, 0),
  }),
  otherOptionButtons: {
    padding: theme.spacing(1),
    fontSize: "15px",
  },
  verticalDivider: {
    margin: theme.spacing(0, 1.5),
    height: "28px",
  },
  noResultsDivider: {
    margin: theme.spacing(4, -3),
  },
}));

export type Variant = "FINISH_OCR_SR" | "AUTH_SEARCH_VIEW";
type DisplayStringKeys = "header" | "searchFieldsHeader" | "collapsedSearchFieldsHeader";

const variantDisplayStrings: Record<Variant, Record<DisplayStringKeys, string>> = {
  FINISH_OCR_SR: {
    header: "Create new authorization",
    searchFieldsHeader: "Find a patient: 2 fields required",
    collapsedSearchFieldsHeader: "Check for duplicate request before creating a new one",
  },
  AUTH_SEARCH_VIEW: {
    header: "Search by auth or tracking number",
    searchFieldsHeader: "",
    collapsedSearchFieldsHeader: "",
  },
};

const EMPTY_SEARCH_FORM = {
  trackingNumber: "",
  memberId: "",
  memberDob: "",
  memberLastName: "",
  patientSearchFieldsError: false,
};

const isSearchFormEmpty = (form: SearchFormState) => {
  return !form.trackingNumber && !form.memberId && !form.memberDob && !form.memberLastName;
};

interface Props extends CommonFaxSidebarViewProps {
  variant: Variant;
  selectedPatient?: Patient;
  setSelectedPatient: Dispatch<Patient | undefined>;
  setOcrServiceRequest?: Dispatch<ServiceRequestResponse | undefined>;
  setSelectedServiceId: Dispatch<string>;
}

export default function AttachToServiceRequestSidebar({
  fileData: { cohereId: faxCohereId, id: faxId, attachedTo, usesCohereTemplate, healthPlanName, externalReferenceId },
  refreshFileData,
  setSidebarView,
  variant,
  selectedPatient,
  setSelectedPatient,
  setOcrServiceRequest,
  fileData,
  serviceCase,
  setSelectedServiceId,
  url,
}: Props) {
  const { authContext, clearAuthContextAttachmentsAndRequests } = useContext(FaxAuthorizationContext);
  const { initialAttachedReferralRequestIds, initialAttachedServiceRequestIds } = authContext;

  const isGhpUser = useBelongsToOpsGroup("GEISINGER");
  const cohereSuggestedValueFeatureEnabled = useFeature("cohereSuggestedValueFeatureEnabled");
  const faxAuthCardRedesign = useFeature("faxAuthCardRedesign");
  const geisingerSuggestedValueFeatureEnabled = useFeature("geisingerSuggestedValueFeatureEnabled");
  const faxIntakeShowOnlyLatestEditableServiceRequest = useFeature("faxIntakeShowOnlyLatestEditableServiceRequest");
  const highmarkPatientSearchSuggestedValueFeatureEnabled = useFeature(
    "highmarkPatientSearchSuggestedValueFeatureEnabled"
  );
  const { data: faxIntakeConfiguration } = useGetFaxIntakeConfigurationByPayer(healthPlanName ?? "");

  const listAndCreateReferralsFeatureEnabled = faxIntakeConfiguration?.referralsCreationFromFaxEnabled ?? false;
  const { enqueueSnackbar } = useSnackbar();
  const handleSideBarClick = useCallback(() => {
    setSidebarView("CREATE_FAX_NOTICE");
  }, [setSidebarView]);

  const handlePatientSelect = useCallback(
    (patient: Patient) => {
      setSelectedPatient(patient);
    },
    [setSelectedPatient]
  );

  const serviceRequestId = first(attachedTo)?.id;

  const { data: serviceRequest, refetch: getServiceRequest } = useGetServiceRequestByIdWithFallback({
    id: serviceRequestId || "",
    lazy: true,
    queryParams: {
      expandAuthorization: true,
    },
  });

  const [isManualFaxForwardingEnabled, setIsManualFaxForwardingEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (serviceRequestId && selectedPatient) {
      getServiceRequest();
    }
  }, [getServiceRequest, selectedPatient, serviceRequestId]);

  const [searchFormState, setSearchFormState] = useState<SearchFormState>(EMPTY_SEARCH_FORM);

  const { mutate: getFaxForwardLinesAndOptions, loading: isManualFaxForwardingOptionsLoading } =
    useGetFaxForwardLinesAndOptions({
      queryParams: {
        featureConfigurationLevel: "HEALTH_PLAN",
        healthPlanName: fileData?.healthPlanName || translateServiceCaseHealthPlan(serviceCase?.healthPlan) || "",
      },
    });

  useEffect(() => {
    const fetchFaxForwardLinesAndOptions = async () => {
      try {
        if (serviceCase?.healthPlan) {
          const response = await getFaxForwardLinesAndOptions();
          if (response) {
            setIsManualFaxForwardingEnabled(response?.manualFaxForwardingEnabled);
          }
        }
      } catch (error) {
        logWarning(error);
      }
    };

    if (serviceCase?.healthPlan) {
      fetchFaxForwardLinesAndOptions();
    }
  }, [getFaxForwardLinesAndOptions, serviceCase?.healthPlan]);

  const [isSearchingByTrackingNumber, setIsSearchingByTrackNumber] = useState(false);
  const [searchedTrackingNumber, setSearchedTrackingNumber] = useState<string | undefined>(undefined);
  const [showOnlyRequestOfType, setShowOnlyRequestOfType] = useState<AvailableRequestTypes | undefined>();

  const clearSearchFields = () => {
    setSearchFormState(EMPTY_SEARCH_FORM);
    setIsSearchingByTrackNumber(false);
    setShowOnlyRequestOfType(undefined);
    setSearchedTrackingNumber(undefined);
  };

  useEffect(() => {
    if (variant === "FINISH_OCR_SR" && serviceRequest && setOcrServiceRequest) {
      setOcrServiceRequest(serviceRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant, serviceRequest]);

  useEffect(() => {
    if (selectedPatient && isSearchFormEmpty(searchFormState)) {
      setSearchFormState({
        memberId: selectedPatient.memberId || "",
        memberDob: formatDateStr(selectedPatient.dateOfBirth),
        memberLastName: selectedPatient.lastName || "",
        patientSearchFieldsError: false,
      });
    }
  }, [selectedPatient, searchFormState]);

  const [showRequests, setShowRequests] = useState(!!selectedPatient);
  const classes = useStyles({ showRequests: showRequests });
  /*
   * Get service requests associated with a patient
   */
  const {
    data: serviceRequestResults,
    loading: loadingServiceRequests,
    error: serviceRequestFetchError,
    refetch: getServiceRequests,
  } = useGetServiceRequests({
    lazy: true,
    queryParams: {
      view: "dashboardSummary",
    },
  });

  const {
    data: authorizationsResults,
    loading: loadingAuthorizations,
    refetch: getAuthorizations,
  } = useGetAuthorizations({
    lazy: true,
    queryParams: {
      view: "dashboardSummary",
      authStatus: "ne:DRAFT",
    },
  });

  const {
    data: referralRequestResults,
    loading: loadingReferralRequests,
    error: referralRequestFetchError,
    refetch: getReferralRequests,
  } = useGetReferralRequests({ lazy: true });

  useEffect(() => {
    if (
      serviceRequestResults &&
      serviceRequestResults.length > 0 &&
      serviceRequestResults[0].patient?.id !== selectedPatient?.id
    ) {
      // if we're not currently showing results, and then new results show up, set the patient accordingly
      setSelectedPatient(serviceRequestResults[0].patient);
    }
    if (serviceRequestFetchError) {
      logError(serviceRequestFetchError);
      enqueueSnackbar("Failed to get service requests", {
        variant: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceRequestResults, serviceRequestFetchError, enqueueSnackbar, setSelectedPatient]);

  useEffect(() => {
    if (referralRequestFetchError) {
      logError(referralRequestFetchError);
      enqueueSnackbar("Failed to get referral requests", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, referralRequestFetchError]);

  useEffect(() => {
    // refresh service request result list if the patient changes and we're not searching by tracking number
    if (selectedPatient && !searchFormState.trackingNumber) {
      if (faxAuthCardRedesign) {
        getAuthorizations({
          queryParams: {
            view: "faxIntakeAuthSummary",
            patient: `eq:${selectedPatient.id}`,
            authStatus: "ne:DRAFT",
            max: 100,
            offset: 0,
            sort: "lastUpdated:desc",
          },
        }).then(() => {
          setShowRequests(true);
        });
      } else {
        getServiceRequests({
          queryParams: { patient: `eq:${selectedPatient.id}`, authStatus: `ne:DRAFT`, view: "dashboardSummary" },
        }).then(() => {
          setShowRequests(true);
        });
      }

      getReferralRequests({
        queryParams: { patient: `eq:${selectedPatient.id}`, sort: "lastUpdated:desc" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatient]);

  useEffect(() => {
    if (searchFormState.trackingNumber) {
      const patient =
        referralRequestResults?.[0]?.patient ||
        serviceRequestResults?.[0]?.patient ||
        (Array.isArray(authorizationsResults) && authorizationsResults?.[0]?.patient);
      if (patient) {
        setSelectedPatient(patient as Patient);
      }
    }
  }, [
    referralRequestResults,
    setSelectedPatient,
    selectedPatient,
    searchFormState,
    serviceRequestResults,
    authorizationsResults,
    showRequests,
  ]);

  const [patients, setPatients] = useState<Patient[]>();
  const {
    mutate: patientSearch,
    loading: patientSearchLoading,
    error: patientSearchError,
  } = usePatientSearch({
    onMutate: (_, data) => {
      setPatients(data);
    },
  });

  useEffect(() => {
    if (patientSearchError) {
      logError(patientSearchError);
      enqueueSnackbar("Failed to get list of patients", {
        variant: "error",
      });
    }
  }, [patientSearchError, enqueueSnackbar]);

  const { data: suggestions, refetch: fetchSuggestions } = useGetCandidateSuggestedValues({
    attachmentId: faxId || "",
    queryParams: { rankResults: true },
    lazy: true,
  });

  useEffect(() => {
    if (isGhpUser) {
      if (geisingerSuggestedValueFeatureEnabled) {
        fetchSuggestions();
      }
    } else if (healthPlanName === "Highmark") {
      if (highmarkPatientSearchSuggestedValueFeatureEnabled) {
        fetchSuggestions();
      }
    } else {
      if (cohereSuggestedValueFeatureEnabled) {
        fetchSuggestions();
      }
    }
  }, [
    fetchSuggestions,
    isGhpUser,
    geisingerSuggestedValueFeatureEnabled,
    cohereSuggestedValueFeatureEnabled,
    highmarkPatientSearchSuggestedValueFeatureEnabled,
    healthPlanName,
  ]);

  const suggestedMember = useRef<SuggestedMember>();
  useEffect(() => {
    if (suggestions) {
      setSearchFormState((currentSearchFormState) => {
        suggestedMember.current = initSuggestedMember(suggestions);

        return {
          ...currentSearchFormState,
          memberId: currentSearchFormState.memberId || suggestedMember.current.memberId?.predictedValue || "",
          memberDob:
            currentSearchFormState.memberDob || formatDateStr(suggestedMember.current.memberDob?.predictedValue) || "",
          memberLastName:
            currentSearchFormState.memberLastName || suggestedMember.current.memberLastName?.predictedValue || "",
        };
      });
    }
  }, [suggestions]);

  const suggestedAuth = useRef<SuggestedAuth>();
  useEffect(() => {
    if (suggestions) {
      setSearchFormState((currentSearchFormState) => {
        suggestedAuth.current = initSuggestedAuth(suggestions);

        return {
          ...currentSearchFormState,
          trackingNumber:
            currentSearchFormState.trackingNumber || suggestedAuth.current.trackingNumber?.predictedValue || "",
        };
      });
    }
  }, [suggestions]);

  const trackUserActivityInteraction = useTrackUserInteraction();
  const { mutate: batchUpdateCandidateFeedback } = useBulkUpdateCandidateFeedback({});

  /*
   * Filter out any triage requests from the results and only return editable service requests
   */
  const filterServiceRequestData = (serviceRequestData: ServiceRequestResponse[]): ServiceRequestResponse[] => {
    if (faxIntakeShowOnlyLatestEditableServiceRequest) {
      const filteredServiceRequestData = serviceRequestData.filter(
        (sr) => sr.intakeRequestType !== "TRIAGE" && sr.isMostRecentEditableRequest
      );
      return filteredServiceRequestData.sort((a, b) =>
        dateStringSort(a?.intakeTimestamp || "", b?.intakeTimestamp || "", false)
      );
    } else {
      return serviceRequestData.filter((sr) => sr.intakeRequestType !== "TRIAGE");
    }
  };

  /*
   * Either searches for patients or service requests (from cohereId)
   */
  const search = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { trackingNumber, memberId, memberDob, memberLastName } = searchFormState;

      if (!!trackingNumber) {
        setSearchedTrackingNumber(undefined);
        const authSuggestionsFeedback = trackingNumber
          ? compareAuthSuggestionsToSearch(trackingNumber, suggestedAuth.current)
          : { accepted: [], rejected: [] };

        const batchUpdateCandidateFeedbackRequestPayload: BulkUpdateCandidateFeedbackRequestBody = {
          accepted: [...(authSuggestionsFeedback.accepted ?? [])],
          rejected: [...(authSuggestionsFeedback.rejected ?? [])],
        };

        await batchUpdateCandidateFeedback(batchUpdateCandidateFeedbackRequestPayload);

        // clear patient search fields, in case if there is anything there
        setSearchFormState({
          ...searchFormState,
          memberId: "",
          memberDob: "",
          memberLastName: "",
          patientSearchFieldsError: false,
        });
        trackUserActivityInteraction({
          event: "FAX_SERVICE_REQUEST_SEARCH",
          stage: usesCohereTemplate === "Yes" ? "COHERE_TEMPLATE_FAX_FLOW" : "NON_COHERE_TEMPLATE_FAX_FLOW",
          activityContext: {
            faxId,
            trackingNumber: trackingNumber as TrackingNumber,
          },
        });
        if (trackingNumber.startsWith("R-") && listAndCreateReferralsFeatureEnabled) {
          await getReferralRequests({
            queryParams: { cohereId: `eq:${trackingNumber}`, sort: "lastUpdated:desc" },
          })
            .then((results) => {
              setShowOnlyRequestOfType("referral");
              if (results && Array.isArray(results) && (results as AuthorizationResponse[]).length > 0) {
                setShowRequests(true);
              } else {
                setShowRequests(false);
              }
              setSelectedPatient(undefined);
            })
            .catch((err) => {
              setShowRequests(false);
              setSelectedPatient(undefined);
              if (err.message !== "Failed to fetch: Aborted") {
                throw err;
              }
            });
          setIsSearchingByTrackNumber(true);
          setSearchedTrackingNumber(trackingNumber);
        } else {
          if (faxAuthCardRedesign) {
            // since authorizations dont guarantee authnumber attributes
            // We must query the service request then the authorization... smh.
            // 🤮
            await getServiceRequests({
              queryParams: {
                cohereIdOrAuthNumber: `eq:${trackingNumber}`,
                authStatus: `ne:DRAFT`,
                view: "dashboardSummary",
                max: 1,
              },
            }).then((res) => {
              if (res?.[0]?.authorization?.id) {
                setShowRequests(true);
                getAuthorizations({
                  queryParams: {
                    view: "faxIntakeAuthSummary",
                    id: `eq:${res?.[0]?.authorization?.id}`,
                    max: 1,
                  },
                }).then((results) => {
                  if (!results || (Array.isArray(results) && (results as AuthorizationResponse[]).length === 0)) {
                    setShowRequests(false);
                    setSelectedPatient(undefined);
                  }
                });
              } else {
                setShowRequests(false);
                setSelectedPatient(undefined);
              }
              setSearchedTrackingNumber(trackingNumber);
            });
          } else {
            await getServiceRequests({
              queryParams: {
                cohereIdOrAuthNumber: `eq:${trackingNumber}`,
                authStatus: `ne:DRAFT`,
                view: "dashboardSummary",
              },
            });

            setShowRequests(true);
          }
          setShowOnlyRequestOfType("service");
          setIsSearchingByTrackNumber(true);
        }
      } else {
        setSearchedTrackingNumber(undefined);
        const memberSuggestionsFeedback = compareMemberSuggestionsToSearch(
          memberId,
          memberDob,
          memberLastName,
          suggestedMember.current
        );

        const batchUpdateCandidateFeedbackRequestPayload: BulkUpdateCandidateFeedbackRequestBody = {
          accepted: [...(memberSuggestionsFeedback.accepted ?? [])],
          rejected: [...(memberSuggestionsFeedback.rejected ?? [])],
        };

        await batchUpdateCandidateFeedback(batchUpdateCandidateFeedbackRequestPayload);

        // patient search, needs 2 of the 3 fields
        const filledFieldsCount = [memberId, memberDob, memberLastName].reduce(
          (count, field) => (!!field ? count + 1 : count),
          0
        );
        if (filledFieldsCount < 2) {
          setSearchFormState({ ...searchFormState, patientSearchFieldsError: true });
        } else {
          setSearchFormState({ ...searchFormState, patientSearchFieldsError: false });
          trackUserActivityInteraction({
            event: "FAX_PATIENT_SEARCH",
            stage: usesCohereTemplate === "Yes" ? "COHERE_TEMPLATE_FAX_FLOW" : "NON_COHERE_TEMPLATE_FAX_FLOW",
            activityContext: {
              faxId,
              trackingNumber: trackingNumber as TrackingNumber,
              patientSearchQuery: {
                memberId,
                dateOfBirth: formatDateToISODate(parse(memberDob, DATE_FORMAT, new Date())),
                lastName: memberLastName,
              },
            },
          });
          await patientSearch({
            memberId,
            dateOfBirth: formatDateToISODate(parse(memberDob, DATE_FORMAT, new Date())),
            lastName: memberLastName.toUpperCase(),
            healthPlanName: healthPlanName,
          });

          setIsSearchingByTrackNumber(false);
        }
      }
    },
    [
      batchUpdateCandidateFeedback,
      faxAuthCardRedesign,
      faxId,
      getAuthorizations,
      getReferralRequests,
      getServiceRequests,
      healthPlanName,
      listAndCreateReferralsFeatureEnabled,
      patientSearch,
      searchFormState,
      setSelectedPatient,
      trackUserActivityInteraction,
      usesCohereTemplate,
      setSearchedTrackingNumber,
    ]
  );

  serviceRequestResults?.forEach((sr) => {
    if (sr.authorization?.clinicalServices) {
      sr.clinicalServices = sr.authorization.clinicalServices;
    }
  });

  const handleAllFileAttachmentsComplete = useCallback(() => {
    refreshFileData?.();
    setSidebarView("CURRENTLY_ATTACHED_SERVICE_REQUESTS");
  }, [refreshFileData, setSidebarView]);

  const handleCancelRequestSearch = useCallback(() => {
    clearSearchFields();
    clearAuthContextAttachmentsAndRequests();

    if (initialAttachedServiceRequestIds.length > 0) {
      setSidebarView("CURRENTLY_ATTACHED_SERVICE_REQUESTS");
    } else {
      setShowRequests(false);
      setSelectedPatient(undefined);
      setPatients(undefined);
    }
  }, [clearAuthContextAttachmentsAndRequests, initialAttachedServiceRequestIds, setSelectedPatient, setSidebarView]);

  return (
    <>
      <div className={classes.container}>
        <>
          <FaxMetaDataDisplay
            fileData={fileData}
            serviceCase={serviceCase}
            showCaseId={true}
            showFaxId={true}
            showExternalId={true}
            url={url}
          />
          <Divider className={classes.divider} />
        </>
        <SuggestionContext.Provider
          value={{
            suggestedMember: suggestedMember.current,
            suggestedAuth: suggestedAuth.current,
          }}
        >
          {!showRequests && <H4 className={classes.header}>{variantDisplayStrings[variant].header}</H4>}
          <SearchFields
            search={search}
            searchFormState={searchFormState}
            setSearchFormState={setSearchFormState}
            loadingSearch={patientSearchLoading || loadingServiceRequests}
            collapsed={showRequests}
            resetSearch={() => {
              setShowRequests(false);
              setSelectedPatient(undefined);
              clearSearchFields();
            }}
            searchHeaderText={variantDisplayStrings[variant].searchFieldsHeader}
            collapsedHeaderText={variantDisplayStrings[variant].collapsedSearchFieldsHeader}
            hideTrackingNumberSearch={variant === "FINISH_OCR_SR"}
            isReferralRequestFeatureFlagEnabled={listAndCreateReferralsFeatureEnabled}
          />
        </SuggestionContext.Provider>
        {!showRequests && (
          <Grid container alignItems="center">
            <Grid item>
              <H6 color="textSecondary" marginRight={3} data-public>
                Other options:
              </H6>
            </Grid>

            <Grid item>
              <InlineButton
                className={classes.otherOptionButtons}
                onClick={() => setSidebarView("CREATE_FAX_NOTICE")}
                data-public
              >
                Create fax notice
              </InlineButton>
            </Grid>
            <Divider className={classes.verticalDivider} orientation="vertical" />
            <Grid item>
              <InlineButton
                onClick={() => setSidebarView("UNWORKABLE_FAX")}
                className={classes.otherOptionButtons}
                data-public
              >
                Unable to work
              </InlineButton>
            </Grid>

            {isManualFaxForwardingEnabled && !isManualFaxForwardingOptionsLoading && (
              <>
                <Divider className={classes.verticalDivider} orientation="vertical" />
                <Grid item>
                  <InlineButton
                    onClick={() => setSidebarView("FORWARDED_FAX")}
                    className={classes.otherOptionButtons}
                    data-public
                  >
                    Forward Fax
                  </InlineButton>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {selectedPatient &&
          showRequests &&
          (faxAuthCardRedesign ? (
            <>
              <AuthorizationRequestResults
                authorizations={(authorizationsResults as AuthorizationResponse[]) ?? []}
                referralRequests={referralRequestResults ?? undefined}
                patient={selectedPatient}
                onCancel={handleCancelRequestSearch}
                onAllFileAttachmentsComplete={handleAllFileAttachmentsComplete}
                faxCohereId={faxCohereId}
                faxId={faxId}
                showCreateNewServiceRequest={true}
                setSidebarView={setSidebarView}
                ocrServiceRequest={serviceRequest ?? undefined}
                setSelectedServiceId={setSelectedServiceId}
                serviceCase={serviceCase}
                externalReferenceId={externalReferenceId}
                healthPlanName={healthPlanName}
                showCreateNewReferralRequest={faxIntakeConfiguration?.referralsCreationFromFaxEnabled}
                loadingServiceRequests={loadingAuthorizations || loadingReferralRequests}
                isSearchingByTrackingNumber={isSearchingByTrackingNumber}
                showOnlyRequestOfType={showOnlyRequestOfType}
              />
            </>
          ) : (
            <ExistingServiceRequestResults
              serviceRequests={serviceRequestResults ? filterServiceRequestData(serviceRequestResults) : null}
              referralRequests={referralRequestResults ?? undefined}
              patient={selectedPatient}
              onCancel={handleCancelRequestSearch}
              onAllFileAttachmentsComplete={handleAllFileAttachmentsComplete}
              faxCohereId={faxCohereId}
              faxId={faxId}
              showCreateNewServiceRequest={true}
              setSidebarView={setSidebarView}
              ocrServiceRequest={serviceRequest ?? undefined}
              serviceCase={serviceCase}
              externalReferenceId={externalReferenceId}
              healthPlanName={healthPlanName}
              setSelectedServiceId={setSelectedServiceId}
              showCreateNewReferralRequest={faxIntakeConfiguration?.referralsCreationFromFaxEnabled}
              loadingServiceRequests={loadingServiceRequests || loadingReferralRequests}
              isSearchingByTrackingNumber={isSearchingByTrackingNumber}
              showOnlyRequestOfType={showOnlyRequestOfType}
              initialAttachedServiceRequestIds={initialAttachedServiceRequestIds}
              initialAttachedReferralRequestIds={initialAttachedReferralRequestIds}
            />
          ))}
        {!showRequests && !isSearchingByTrackingNumber && (
          <PatientResultsList
            patients={patients}
            createFaxNotice={handleSideBarClick}
            onPatientSelect={handlePatientSelect}
            patientSelectLoading={loadingServiceRequests || loadingReferralRequests || loadingAuthorizations}
            hideResults={!patients || patientSearchLoading}
            healthPlanName={fileData?.healthPlanName || translateServiceCaseHealthPlan(serviceCase?.healthPlan) || ""}
            isAttachmentSidebar
          />
        )}
        {!showRequests &&
          searchedTrackingNumber &&
          !patientSearchLoading &&
          !loadingServiceRequests &&
          !patientSearchLoading && (
            <>
              <Divider className={classes.noResultsDivider} />
              <NoFaxLookupResultsCard
                title={`No results found for ${searchedTrackingNumber}`}
                createFaxNotice={handleSideBarClick}
                hideResults={false}
              />
            </>
          )}
      </div>
      {!showRequests && variant !== "AUTH_SEARCH_VIEW" && (
        <FaxSidebarOuterControlPanelContainer>
          <FaxSidebarInnerControlPanelContainer>
            <SecondaryButton
              className={classes.cancelButton}
              warning
              onClick={() => {
                clearSearchFields();
                if (initialAttachedServiceRequestIds.length > 0) {
                  setSidebarView("CURRENTLY_ATTACHED_SERVICE_REQUESTS");
                } else {
                  setSidebarView("AUTH_SEARCH_VIEW");
                  setSelectedPatient(undefined);
                }
              }}
            >
              Cancel
            </SecondaryButton>
          </FaxSidebarInnerControlPanelContainer>
        </FaxSidebarOuterControlPanelContainer>
      )}
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const FaxSidebarOuterControlPanelContainer = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: 30,
  marginLeft: -24,
  marginBottom: -30,
  boxShadow:
    "0px 1px 10px 0px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)",

  backgroundColor: theme.palette.background.paper,
  width: 624,
  padding: theme.spacing(2, 3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const FaxSidebarInnerControlPanelContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));
