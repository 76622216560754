import { ClinicalReviewErrorDetails } from "../Modals/DetailedHardRedirectClinicalReviewModal";
import { GetDataError } from "restful-react";
import {
  Coverage,
  ErrorMessage,
  Patient,
  ServiceRequestResponse,
  ServiceRequestSearchResponse,
  useGetPrimaryCoverage,
} from "@coherehealth/core-platform-api";
import { intervalToDuration } from "date-fns";
import { useEffect, useState } from "react";
import { useFeature } from "@coherehealth/common";
import isString from "lodash/isString";
import { ClinicalAuthErrorDetails } from "../../../DetailedHardRedirectClinicalAuthModal";

export type createOrContinue = "CREATE" | "CONTINUE";

export const isServiceRequestCppEligible = (healthPlanName: string) => {
  return healthPlanName === "BCBS South Carolina";
};

export const nurseReviewButtonText = (state: createOrContinue, isCppEligible: boolean) => {
  if (state === "CREATE") {
    return isCppEligible ? "Add clinical preprocessing review" : "Add clinical review";
  } else {
    return isCppEligible ? "Continue clinical preprocessing review" : "Continue clinical review";
  }
};
export const extractErrorDetails = (
  err: GetDataError<ErrorMessage> | null,
  serviceRequestId?: string,
  reviewId?: string
): ClinicalReviewErrorDetails => {
  let errorDetails: ClinicalReviewErrorDetails = {
    serviceRequestId,
    reviewId: reviewId || "",
    status: err?.status || 500,
    message: "An unknown error occurred",
  };
  if ((err?.data as any)?.message) {
    errorDetails = { ...errorDetails, message: (err?.data as any)?.message };
  } else if ((err?.data as any)?.error?.message) {
    errorDetails = { ...errorDetails, message: (err?.data as any)?.error?.message };
  } else if (err?.message) {
    errorDetails = { ...errorDetails, message: err?.message };
  }
  return errorDetails;
};

export const extractAuthErrorDetails = (
  err: GetDataError<ErrorMessage> | null,
  serviceRequestId?: string
): ClinicalAuthErrorDetails => {
  let errorDetails: ClinicalAuthErrorDetails = {
    serviceRequestId,
    status: err?.status || 500,
    message: "An unknown error occurred",
  };
  if ((err?.data as any)?.message) {
    errorDetails = { ...errorDetails, message: (err?.data as any)?.message };
  } else if ((err?.data as any)?.error) {
    errorDetails = { ...errorDetails, message: (err?.data as any)?.error };
  } else if ((err?.data as any)?.error?.message) {
    errorDetails = { ...errorDetails, message: (err?.data as any)?.error?.message };
  } else if (err?.message) {
    errorDetails = { ...errorDetails, message: err?.message };
  }
  return errorDetails;
};

export const defaultClinicalReviewErrorState = (serviceRequestId: string, reviewId: string) => {
  return {
    status: 0,
    message: "No error",
    serviceRequestId,
    reviewId,
  };
};

const getPatientAge = (patient?: Patient | null): number => {
  return patient?.dateOfBirth
    ? intervalToDuration({ start: new Date(patient.dateOfBirth), end: new Date() }).years ?? 0
    : 0;
};

export const getIsMedicaidUnder21 = (patient?: Patient | null, primaryCoverage?: Coverage | null): boolean => {
  const patientAge = getPatientAge(patient);
  return !!primaryCoverage?.isMedicaid && patientAge < 21;
};

export const getIsGHPPreDenialP2PUnder21 = (serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse) => {
  return (
    serviceRequest?.authStatus === "PENDING" &&
    serviceRequest?.pendingReason === "PEER_TO_PEER_REVIEW" &&
    serviceRequest?.healthPlanName === "Geisinger" &&
    getPatientAge(serviceRequest?.patient as Patient) < 21
  );
};

export const useGetIsUsingClinicalConversation = (
  serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse
) => {
  const [isUsingClinicalConversation, setIsUsingClinicalConversation] = useState(false);
  const { data: patientPrimaryCoverage, refetch: fetchPrimaryCoverage } = useGetPrimaryCoverage({
    id: serviceRequest?.id ?? "",
    lazy: true,
  });
  const medicaidUnder21ClinicalConversationsFF = useFeature("medicaidUnder21ClinicalConversations");

  useEffect(() => {
    if (medicaidUnder21ClinicalConversationsFF && getIsGHPPreDenialP2PUnder21(serviceRequest)) {
      fetchPrimaryCoverage();
    }
  }, [serviceRequest, fetchPrimaryCoverage, medicaidUnder21ClinicalConversationsFF]);

  const isMedicaidUnder21 = getIsMedicaidUnder21(serviceRequest.patient as Patient, patientPrimaryCoverage);

  if (isMedicaidUnder21 && isMedicaidUnder21 !== isUsingClinicalConversation) {
    setIsUsingClinicalConversation(isMedicaidUnder21);
  }

  return isUsingClinicalConversation;
};

const anchorAuthRegex = /^[A-Z]{4}\d{4}$/;

export const validateAnchorAuthNumber = (str: string) => {
  return isString(str) && anchorAuthRegex.test(str);
};
