import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import { Body3 } from "@coherehealth/common";

import { useChatbotStyles } from "./chatbotReviewAssistantStyles";
import { ReviewChatbotFinding } from "@coherehealth/core-platform-api";

export interface ChatbotStylesI {
  displayFeedBackOptions: boolean | undefined;
}

export interface ChatbotFeedbackMechanismI {
  chatbotFinding: ReviewChatbotFinding;
  setFeedback: (updatedChatbotFinding: Partial<ReviewChatbotFinding>) => void;
}

const HELPFULNESS_FEEDBACK_COMMENTS_OPTIONS: {
  id: string;
  label: string;
  icon: any;
}[] = [
  {
    id: "NOT_ACCURATE",
    label: "Not Accurate",
    icon: <LooksOneIcon style={{ width: "16px", height: "16px", flexShrink: 0 }} />,
  },
  {
    id: "INCOMPLETE",
    label: "Incomplete",
    icon: <LooksTwoIcon style={{ width: "16px", height: "16px", flexShrink: 0 }} />,
  },
];

export const ChatbotFeedbackMechanism = ({ chatbotFinding, setFeedback }: ChatbotFeedbackMechanismI) => {
  const hasThumbsUp = chatbotFinding.helpfulnessFeedback === true;
  const hasThumbsDown = chatbotFinding.helpfulnessFeedback === false;

  const displayFeedBackOptions = hasThumbsDown;
  const classes = useChatbotStyles({ displayFeedBackOptions });

  const handleResponseReason = (commentId: string) => {
    const inititalComments = chatbotFinding.helpfulnessFeedbackComments || [];
    if (inititalComments.includes(commentId)) {
      setFeedback({ helpfulnessFeedbackComments: inititalComments.filter((r) => r !== commentId) });
    } else {
      setFeedback({ helpfulnessFeedbackComments: [...inititalComments, commentId] });
    }
  };

  const handleThumbUpClick = () => {
    setFeedback({ helpfulnessFeedback: true, helpfulnessFeedbackComments: [] });
  };

  const handleThumbDownClick = () => {
    setFeedback({ helpfulnessFeedback: false });
  };

  return (
    <div className={classes.feedbackMechnaismContainer}>
      <div className={classes.thumbsContainer}>
        <ThumbUpIcon
          className={`${classes.icon} ${hasThumbsUp ? classes.activeIcon : ""}`}
          onClick={handleThumbUpClick}
          style={{ width: "16px", height: "16px", flexShrink: 0 }}
        />
        <ThumbDownIcon
          className={`${classes.icon} ${hasThumbsDown ? classes.activeThumbDown : ""}`}
          onClick={handleThumbDownClick}
          style={{ width: "16px", height: "16px", flexShrink: 0 }}
        />
      </div>

      {displayFeedBackOptions && (
        <div className={classes.unsatifactoryOptsContainer}>
          {HELPFULNESS_FEEDBACK_COMMENTS_OPTIONS.map((option) => (
            <div
              className={`${classes.icon} ${
                chatbotFinding.helpfulnessFeedbackComments?.includes(option.id) ? classes.activeLookIcon : ""
              }`}
              onClick={() => {
                handleResponseReason(option.id);
              }}
            >
              {option.icon}
              <Body3>{option.label}</Body3>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
