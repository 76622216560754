import { useCallback, useContext, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AuthCategoryResponse,
  AuthorizationResponse,
  ServiceRequestCommandPayload,
  ServiceRequestResponse,
  useAddServiceRequestOnAuthorization,
  useCreateServiceRequestAttachment,
  useGetAuthValidityWindow,
} from "@coherehealth/core-platform-api";
import { extractClinicalServicesText, formatDateToISODate, plusDays } from "@coherehealth/common";
import { getAuthorizationCardActionButtons, MainActionButton } from "./AuthorizationCardButtons";
import { Card, Grid } from "@material-ui/core";
import { AuthorizationCardHeader } from "./AuthorizationCardHeader";
import { FaxAuthorizationCommonSection } from "./AuthorizationCardCommonSection";
import { AuthorizationOutpatientSection } from "./AuthorizationCardOutpatientSection";
import { AuthorizationInpatientSection } from "./AuthorizationCardInpatientSection";
import { useCalculateInitializedEndDate } from "components/AuthBuilder/common";
import { sideBySideContinuationWorkflowPath } from "util/serviceRequest";
import { FaxAttachmentContext } from "../../FaxAttachmentContext";
import { getParametersFaxIntake, setParams } from "util/queryParams";
import { useNavigate } from "react-router-dom";
import { getWorkFlowStep } from "util/workflowUtils";
import { useCreateReadmissionPayload } from "components/ServiceRequest/ReviewSection/common/hooks/useReadmission";

interface ViewProps {
  attachingFile?: boolean;
  isSelected?: boolean;
  loadingAuthorization?: boolean;
  faxCohereId?: string;
  onEditClick?: () => void;
  onRemove?: (serviceRequestId: string) => void;
  serviceCaseId?: string;
  authorization: AuthorizationResponse | null;
  latestServiceRequest: ServiceRequestResponse | null;
  showCreateContinuation?: boolean;
  showPatientAlert?: boolean;
  showRemoveButton?: boolean;
  showEditRequest?: boolean;
  disableAttachmentCta?: boolean;
  isFacilityBased?: boolean;
}

export const faxAuthorizationCardStyles = makeStyles((theme) => ({
  authorizationCard: {
    border: `1px solid ${theme.palette.divider}`,
    minHeight: 225,
    display: "flex",
    padding: "16px",
  },
  requestDetailsLink: {
    padding: theme.spacing(0, 1, 0, 0),
    whiteSpace: "nowrap",
  },
  cardHeaderLine1: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  cardHeaderItem: {
    display: "inline-block",
    marginRight: theme.spacing(1),
  },
  loadingSpinner: {
    display: "block",
    alignSelf: "center",
    margin: "auto",
  },
  h5BodyContainer: {
    width: "100%",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "100%",
    "&.MuiGrid-item": {
      margin: theme.spacing(0.5, 0),
    },
  },
  zeroMargin: {
    "&.MuiGrid-item": {
      margin: theme.spacing(0),
    },
  },
  customWidth: {
    maxWidth: 500,
  },
  cardButtons: {
    display: "flex",
    justifyContent: "space-between",
  },
  viewButtonContainer: {
    display: "flex",
    gap: "12px",
  },
  viewInfoLink: {
    minWidth: "fit-content",
    padding: 0,
  },
  textWrap: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  practiceContainer: { display: "flex", flexDirection: "column" },
  practiceLabelWrapper: {
    marginBottom: theme.spacing(0.5),
  },
  practiceTypography: { ...theme.typography.body1 },
}));

export function AuthorizationCard({
  attachingFile,
  onRemove,
  serviceCaseId,
  authorization,
  latestServiceRequest,
  showCreateContinuation,
  showPatientAlert,
  showRemoveButton,
  showEditRequest = false,
  isFacilityBased = false,
  disableAttachmentCta,
  faxCohereId,
  onEditClick,
}: ViewProps) {
  const { id } = authorization ?? {};
  const { data: authValidityWindow, refetch: refetchAuthValidityWindow } = useGetAuthValidityWindow({ lazy: true });
  const { caseId, faxDbId: faxId, queueId } = useContext(FaxAttachmentContext);
  const { mutate: createNewServiceRequestOnAuth, loading: loadingContinuation } = useAddServiceRequestOnAuthorization({
    id: latestServiceRequest?.authorization?.id || "",
    requestOptions: { headers: { Accept: "application/json" } },
  });
  const navigate = useNavigate();
  const createReadmissionPayload = useCreateReadmissionPayload();
  const { mutate: uploadSRFax } = useCreateServiceRequestAttachment({
    id: latestServiceRequest?.id || "",
    requestOptions: { headers: { Accept: "application/json" } },
  });

  const navigateToContinuationWorkflowStep = useCallback(
    (serviceRequestId: string, patientId: string, caseId?: string) => {
      const { pathname, search } = sideBySideContinuationWorkflowPath(faxId || "", patientId, serviceRequestId);

      const updatedSearchParams = getParametersFaxIntake(search, faxId, caseId, queueId);
      const workflowStep = getWorkFlowStep() || "";
      const url = `${pathname}?${setParams("faxStep", updatedSearchParams, workflowStep)}`;

      navigate(url);
    },
    [faxId, navigate, queueId]
  );

  const calculateInitializedEndDate = useCalculateInitializedEndDate(latestServiceRequest?.healthPlanName);
  const onContinuationClick = useCallback(async () => {
    // creates a new empty draft SR on the authorization and directs the user to the fill forms continuation page for the newly created draft
    if (latestServiceRequest?.id) {
      const payload: ServiceRequestCommandPayload = {
        authStatus: "DRAFT",
        urgency: { isExpedited: false },
        units: 0,
        requestType: "CONTINUATION",
        workflowStep: "FILL_FORMS_CONTINUATION",
        encounterType: latestServiceRequest.encounterType,
        continuationGeneratedFromServiceRequest: {
          id: latestServiceRequest.id,
        },
      };
      const today = new Date();
      let endDate;
      const initializedEndDate = calculateInitializedEndDate(
        today,
        latestServiceRequest.clinicalServices || [],
        latestServiceRequest.patient?.coverages || []
      );
      if (initializedEndDate) {
        endDate = initializedEndDate;
      }
      const maxDuration = Math.max(
        ...(latestServiceRequest.clinicalServices?.map((cs) => cs?.defaultDuration || 0) || [0])
      );
      if (maxDuration > 0) {
        endDate = plusDays(maxDuration, today);
      }
      if (latestServiceRequest?.patient?.id && latestServiceRequest?.startDate) {
        await refetchAuthValidityWindow({
          queryParams: { patient: latestServiceRequest.patient.id, startDate: latestServiceRequest.startDate },
        });
      }
      const lastValidDate = authValidityWindow?.validityWindowEndDate
        ? new Date(authValidityWindow.validityWindowEndDate)
        : endDate;
      payload.endDate = formatDateToISODate(lastValidDate);
      const readmissionPayload = createReadmissionPayload(
        payload,
        latestServiceRequest.isReadmission,
        latestServiceRequest.anchorAuthNumber
      );
      if (latestServiceRequest?.authorization?.id) {
        const addDraftSRResponse = await createNewServiceRequestOnAuth(readmissionPayload);
        if (faxCohereId) {
          const formData = new FormData();
          formData.set("faxId", faxCohereId);
          await uploadSRFax(formData as unknown as void, { pathParams: { id: addDraftSRResponse.id } });
        }
        navigateToContinuationWorkflowStep(addDraftSRResponse?.id, addDraftSRResponse?.patient?.id || "", caseId);
      }
    }
  }, [
    calculateInitializedEndDate,
    latestServiceRequest?.id,
    latestServiceRequest?.encounterType,
    latestServiceRequest?.clinicalServices,
    latestServiceRequest?.patient?.coverages,
    latestServiceRequest?.patient?.id,
    latestServiceRequest?.startDate,
    latestServiceRequest?.authorization?.id,
    latestServiceRequest?.isReadmission,
    latestServiceRequest?.anchorAuthNumber,
    authValidityWindow?.validityWindowEndDate,
    refetchAuthValidityWindow,
    createNewServiceRequestOnAuth,
    faxCohereId,
    navigateToContinuationWorkflowStep,
    caseId,
    uploadSRFax,
    createReadmissionPayload,
  ]);

  const cardButtonComponent = useMemo<JSX.Element>(() => {
    return (
      <MainActionButton
        serviceCaseId={serviceCaseId}
        id={id}
        showRemoveButton={showRemoveButton}
        onRemove={onRemove}
        latestServiceRequest={latestServiceRequest}
        disableAttachmentCta={disableAttachmentCta}
        attachingFile={attachingFile}
      />
    );
  }, [serviceCaseId, id, showRemoveButton, onRemove, latestServiceRequest, disableAttachmentCta, attachingFile]);

  const extractClinicalServiceText = useCallback(
    (clinicalServices?: AuthorizationResponse["clinicalServices"], authCategory?: AuthCategoryResponse) => {
      return extractClinicalServicesText(clinicalServices || [], authCategory);
    },
    []
  );

  const classes = faxAuthorizationCardStyles();

  return (
    <Card className={classes.authorizationCard}>
      <Grid container spacing={2}>
        <AuthorizationCardHeader
          classes={classes}
          extractClinicalServiceText={extractClinicalServiceText}
          serviceCaseId={serviceCaseId}
          authorization={authorization}
          latestServiceRequest={latestServiceRequest}
          showPatientAlert={!!showPatientAlert}
        />

        <FaxAuthorizationCommonSection
          authorization={authorization}
          latestServiceRequest={latestServiceRequest}
          classes={classes}
        />
        {Boolean(isFacilityBased) ? (
          <AuthorizationInpatientSection authorization={authorization} latestServiceRequest={latestServiceRequest} />
        ) : (
          <AuthorizationOutpatientSection authorization={authorization} latestServiceRequest={latestServiceRequest} />
        )}

        <Grid item xs={12} className={classes.cardButtons}>
          {cardButtonComponent}
          {getAuthorizationCardActionButtons(
            showCreateContinuation,
            onContinuationClick,
            loadingContinuation,
            showEditRequest,
            onEditClick
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
