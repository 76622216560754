import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import ProvidersCard from "./ProvidersCard";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import {
  AdditionalInsurance,
  AuthStatus,
  Patient,
  ServiceRequestResponse,
  useGetPlaceOfServices,
} from "@coherehealth/core-platform-api";
import PatientStayCard from "./PatientStayCard";
import {
  plusYears,
  today,
  useConfiguration,
  useFeature,
  useMuiContainerStyles,
  useParsedQueryString,
} from "@coherehealth/common";
import { validatePatientStayRange } from "util/authorization";
import { Box, Container, Divider, Grid, useTheme } from "@material-ui/core";
import ServicesAndProceduresCard, { ProcedureCodeBucketsState } from "./ServicesAndProceduresCard";
import { useServiceRequestConfigSpec } from "components/ServiceRequest/ConfigurableServiceRequestForm";
import { ServiceRequestFieldName } from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import ServiceRequestFormRedirectModal, {
  useServiceRequestFormRedirectDispatch,
} from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import RequestorCard, { Props as RequestorProps } from "components/Requestor/RequestorCard";
import { useAuthorized } from "authorization";
import DiagnosisCard from "./DiagnosisCard";
import listReplace from "util/listReplace";
import useGetFacilityBasedRequestConfigurationByPayer, {
  useGetOtherInsuranceConfigurationByPayer,
  useGetCareParticipantsConfigByHealthPlan,
} from "hooks/useGetFeatureConfigurations";
import * as datefns from "date-fns";
import { checkOnetimeRequestCoverage, getPatientRiskBearingEntity } from "util/patientUtils";
import { PriorAuthRequirements, convertStringToAuthBuilderWorkflowStep, getSortedClinicalServices } from "../common";
import { CohereCard } from "@coherehealth/design-system";
import { MIN_START_DATE, timePattern } from "util/dateUtils";
import AdditionalInsuranceSection from "../GatherRequirements/AdditionalInsuranceSection";
import {
  isAnyCareParticipantOONExceptionRequired,
  updateCareParticipants,
  validateEachFieldOfCareParticipant,
  validateOONExceptionCommentFieldForCareParticipants,
  validateOONExceptionReasonFieldForCareParticipants,
  validatePatientStayDates,
} from "util/serviceRequest";
import { useLocation } from "react-router";
import { shouldUseCohereCard } from "util/workflowUtils";
import {
  validatePerformingProviderAddress,
  validatePerformingProviderNPI,
  validatePerformingProviderTin,
} from "../validationUtil";
import { isExceptionCommentRequired } from "util/providerUtils";
import { useCalculateDateSelectionMessageDisplay } from "components/AuthBuilder/FillFormsAuthSubmission/utils";
import { ScrubTinsContext } from "../../ScrubTinsContext";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import { fieldIsValid } from "common/FormConfigUtils";
import { isEmpty } from "lodash";
import { startOfDay } from "date-fns";

interface Props extends ProcedureCodeBucketsState, RequestorProps {
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  attemptedSubmit: boolean;
  patientHealthPlanName: string;
  patient: Patient | null;
  workflowId?: string;
  setServiceRequestFormsCanBeSubmitted: Dispatch<SetStateAction<boolean[]>>;
  delegatedVendorName?: string;
  authStatus: AuthStatus;
  presubmissionReviewPage?: boolean;
  userFaxExtension?: string;
  requestorProps?: RequestorProps;
  showRequestorCard?: boolean;
  isCohereFaxForm?: boolean;
  isEdit?: boolean;
  priorAuthRequirements?: PriorAuthRequirements;
  setPriorAuthRequirements?: Dispatch<SetStateAction<PriorAuthRequirements>>;
  setPerformingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  performingProviderExceptionRequest?: boolean;
  setFacilityExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  facilityExceptionRequest?: boolean;
  setCareParticipantExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  careParticipantExceptionRequest?: boolean;
  serviceRequest?: Partial<ServiceRequestResponse>;
  hideExpeditedRequestCheckbox?: boolean;
  onFillFormsPage?: boolean;
  allowedPxCodePerPayer?: number;
  setPerformingProviderPracticeExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  performingProviderPracticeExceptionRequest?: boolean;
  setPerformingProviderPracticeOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
}

export default function AuthSubmissionForms({
  formContent,
  setFormContent,
  attemptedSubmit,
  patientHealthPlanName,
  patient,
  workflowId,
  setServiceRequestFormsCanBeSubmitted,
  delegatedVendorName,
  authStatus,
  presubmissionReviewPage,
  userFaxExtension,
  showRequestorCard,
  requestorProps,
  isCohereFaxForm,
  isEdit,

  /** Procedure Code Buckets */
  palProcedureCodes,
  nonPalProcedureCodes,
  nonCohereProcedureCodes,
  setPalProcedureCodes,
  setNonCohereProcedureCodes,
  setNonPalProcedureCodes,
  priorAuthRequirements,
  setPriorAuthRequirements,
  setPerformingProviderExceptionRequest,
  performingProviderExceptionRequest,
  setFacilityExceptionRequest,
  facilityExceptionRequest,
  setCareParticipantExceptionRequest,
  careParticipantExceptionRequest,
  serviceRequest,
  hideExpeditedRequestCheckbox,
  onFillFormsPage,
  allowedPxCodePerPayer,
  setPerformingProviderPracticeExceptionRequest,
  performingProviderPracticeExceptionRequest,
  setPerformingProviderPracticeOonCheckLoading,
}: Props) {
  const skipManualNpiTin = useFeature("skipManualNpiTin");
  const tinConfiguration = useConfiguration("tinConfiguration", patientHealthPlanName) ?? {};
  const userTinValidation = tinConfiguration?.userTinValidation;
  const canSkipManualNpiTin = useAuthorized("CAN_SKIP_MANUAL_NPI_TIN");

  const isGeisingerPatient = patientHealthPlanName === "Geisinger";
  const canSkipValidationForNpiTin = skipManualNpiTin && isGeisingerPatient && canSkipManualNpiTin;
  const { spacing } = useTheme();

  const isAuthorizedForRequestorForm = useAuthorized("REQUESTOR_FORM");
  const hasAllowedProcedureCodePerPayer = Boolean(
    allowedPxCodePerPayer && formContent?.procedureCodes.length <= allowedPxCodePerPayer
  );
  const hasPlaceOfService = Boolean(formContent.placeOfService);
  //TODO remove all isRecurring validation logic with removal of simplifiedServiceFrequency
  const hasOrderingProvider = Boolean(formContent.orderingProvider);
  const hasPerformingProvider = Boolean(formContent.performingProvider);
  // This weird condition may be due to something broken within react. Refer https://github.com/facebook/react/issues/21328 or https://github.com/facebook/react/issues/19476
  // Upgrading typescript eslint did not do the trick
  const hasFacilityNpi =
    formContent?.facility?.manuallyCreated && canSkipValidationForNpiTin && !formContent?.facility?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent.facility?.npi);
  const hasUnits = Boolean(formContent.units);
  const allPxsHaveUnits = Boolean(formContent.procedureCodes.every((px) => !!px.units));
  const hasPerformingProviderNpi =
    formContent?.performingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.performingProvider?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent.performingProvider?.npi);
  const hasFacilityTin =
    formContent?.facility?.manuallyCreated && canSkipValidationForNpiTin && !formContent?.facilitySelectedTin
      ? canSkipValidationForNpiTin
      : Boolean(formContent.facilitySelectedTin);
  const hasFacilityAddress = Boolean(
    formContent.facilitySelectedAddress?.address || formContent.selectedFacility?.selectedLocation?.address
  );
  const hasOrderingProviderTin =
    formContent?.orderingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.orderingProviderSelectedTin
      ? canSkipValidationForNpiTin
      : Boolean(formContent.orderingProviderSelectedTin);
  const hasOrderingProviderAddress = Boolean(
    formContent.orderingProviderSelectedAddress?.address ||
      formContent.selectedOrderingProvider?.selectedLocation?.address
  );
  const hasPerformingProviderPractice = Boolean(formContent?.selectedPerformingProviderPractice);
  const hasPerformingProviderPracticeTIN =
    formContent?.selectedPerformingProviderPractice?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.selectedPerformingProviderPractice?.selectedLocation?.tin
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.selectedPerformingProviderPractice?.selectedLocation?.tin);

  const hasPerformingProviderPracticeAddress = !isEmpty(
    formContent?.selectedPerformingProviderPractice?.selectedLocation?.address
  );
  const hasPerformingProviderPracticeNpi =
    formContent?.selectedPerformingProviderPractice?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.selectedPerformingProviderPractice?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.selectedPerformingProviderPractice?.npi);
  const finalDeterminationStates = ["APPROVED", "DENIED", "PARTIALLY_APPROVED", "WITHDRAWN", "VOIDED"];
  const [sameProviders, setSameProviders] = useState(false);
  const performingProviderTinLength = formContent.performingProvider?.tinList?.length;
  const hasPerformingProviderTin =
    formContent?.performingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.performingProviderSelectedTin
      ? canSkipValidationForNpiTin
      : Boolean(formContent.performingProviderSelectedTin);
  const hasPerformingProviderAddress = Boolean(
    formContent.performingProviderSelectedAddress?.address ||
      formContent.selectedPerformingProvider?.selectedLocation?.address
  );
  const hasOrderingProviderNpi =
    formContent?.orderingProvider?.manuallyCreated && canSkipValidationForNpiTin && !formContent?.orderingProvider?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent.orderingProvider?.npi);
  const hasFacility = Boolean(formContent.facility);
  const hasFollowUpFaxNumber = Boolean(formContent.followUpFaxNumber?.number);
  const followUpFaxNumberHasAcceptableDigits =
    !hasFollowUpFaxNumber || formContent.followUpFaxNumber?.number?.length === 10;
  const faxNumberIsAutofilled =
    (userFaxExtension?.length || 0) > 0 && userFaxExtension === formContent.followUpFaxNumber?.number;
  const hasPxCodesWithUnits = formContent.isInpatient
    ? formContent?.procedureCodes.length >= 0 && formContent?.procedureCodes.every((px) => !!px?.units)
    : formContent?.procedureCodes.length > 0 && formContent?.procedureCodes.every((px) => !!px?.units);

  const requiresFacilityOONExceptionComment =
    formContent.selectedFacility?.selectedLocation?.isOutOfNetwork &&
    formContent.selectedFacility?.selectedLocation?.outOfNetworkExceptionReason === "Other (comment is required)";

  const requiresPerformingProviderOONExceptionComment =
    formContent.selectedPerformingProvider?.selectedLocation?.isOutOfNetwork &&
    formContent.selectedPerformingProvider?.selectedLocation?.outOfNetworkExceptionReason ===
      "Other (comment is required)";

  const requiresPerformingProviderPracticeOONExceptionComment =
    formContent?.selectedPerformingProviderPractice?.selectedLocation?.isOutOfNetwork &&
    formContent?.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason ===
      "Other (comment is required)";

  const requiresOrderingProviderOONExceptionComment = isExceptionCommentRequired(formContent);

  const hasFacilityOONExceptionComment = formContent.selectedFacility?.selectedLocation?.outOfNetworkExceptionComment;

  const hasPerformingProviderOONExceptionComment =
    formContent.selectedPerformingProvider?.selectedLocation?.outOfNetworkExceptionComment;

  const hasPerformingProviderPracticeOONExceptionComment =
    formContent.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionComment;

  const hasOrderingProviderOONExceptionComment =
    formContent.selectedOrderingProvider?.selectedLocation?.outOfNetworkExceptionComment;

  const { otherInsuranceEnabled, otherInsurance } = useGetOtherInsuranceConfigurationByPayer({
    healthPlanName: patientHealthPlanName ?? "",
  });

  const { careParticipantEnabled, careParticipantConfig } = useGetCareParticipantsConfigByHealthPlan({
    healthPlanName: patientHealthPlanName ?? "",
  });

  const setCanBeSubmitted = useCallback(
    (canBeSubmitted: boolean) => setServiceRequestFormsCanBeSubmitted((prev) => listReplace(prev, 0, canBeSubmitted)),
    [setServiceRequestFormsCanBeSubmitted]
  );

  const { facilityBasedFeatureEnabled, includePatientStayDatesOnPlannedAdmission } =
    useGetFacilityBasedRequestConfigurationByPayer({
      healthPlanName: patientHealthPlanName,
      encounterType: formContent.isInpatient ? "INPATIENT" : "OUTPATIENT",
      priorAuthRequirements,
      skipRequestTimingCheck: true,
      srContent: formContent || priorAuthRequirements,
    });

  const { formFieldConfigurations, outOfNetworkCheckConfiguration, isLoading, pxCodeAttributeConfiguration } =
    useServiceRequestConfigSpec({
      ...formContent,
      patientId: patient?.id || "",
      healthPlanName: patientHealthPlanName,
    });

  // Default to "Cohere" if delegatedVendorName is null or an empty string
  const srDelegatedVendorName = serviceRequest?.delegatedVendor || "Cohere";

  // Determine the delegated vendor name, defaulting to "Cohere" if it's an empty string
  const configuredDelegatedVendorName =
    pxCodeAttributeConfiguration?.delegatedVendorName == null ||
    pxCodeAttributeConfiguration?.delegatedVendorName === ""
      ? "Cohere"
      : pxCodeAttributeConfiguration?.delegatedVendorName;

  // Check if the Px Code Attributed Field should be shown based on configuration and vendor name
  const showPxBasedAttributedField =
    formFieldConfigurations?.selectedDetails?.fieldSpec !== "OMIT" &&
    pxCodeAttributeConfiguration?.showPxCodeAttributeField &&
    configuredDelegatedVendorName === srDelegatedVendorName;

  const location = useLocation();
  const qs = useParsedQueryString();
  const workflowStepStr = qs["authBuilderStep"];
  const shouldUpdateAdditionalCareParticipants = authStatus !== "DRAFT" || workflowStepStr === "REVIEW";
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);

  const updateFormContentsWithAdditionalCareParticipants = useCallback(
    (careParticipantConfig) => {
      setFormContent((prev) => ({
        ...prev,
        additionalCareParticipants: updateCareParticipants(careParticipantConfig, prev),
      }));
    },
    [setFormContent]
  );

  useEffect(() => {
    if (careParticipantEnabled && careParticipantConfig && shouldUpdateAdditionalCareParticipants && !isLoading) {
      updateFormContentsWithAdditionalCareParticipants(careParticipantConfig);
    }
  }, [
    careParticipantConfig,
    careParticipantEnabled,
    workflowStepStr,
    shouldUpdateAdditionalCareParticipants,
    isLoading,
    updateFormContentsWithAdditionalCareParticipants,
  ]);

  const [orderingProviderExceptionRequest, setOrderingProviderExceptionRequest] = useState(false);

  const setExceptionRequest = useCallback(() => {
    if (formContent?.orderingProviderSelectedAddress?.outOfNetworkExceptionReason) {
      setOrderingProviderExceptionRequest(
        !!formContent?.orderingProviderSelectedAddress?.outOfNetworkExceptionReason || false
      );
    }
  }, [formContent]);

  useEffect(() => {
    setExceptionRequest();
  }, [formContent, setExceptionRequest]);

  const hasOrderingProviderExceptionRequestOnContinuation = formContent?.orderingProviderSelectedAddress?.isOutOfNetwork
    ? orderingProviderExceptionRequest
    : false;

  const formConfiguration = formFieldConfigurations;

  const showEndDate = Number(formContent.units) !== 1;

  const startDateCoverage = useMemo(
    () =>
      patient?.coverages && formContent?.admissionDate
        ? checkOnetimeRequestCoverage(patient.coverages, formContent?.admissionDate || "")
        : undefined,
    [patient?.coverages, formContent?.admissionDate]
  );

  const endDateCoverage = useMemo(
    () =>
      patient?.coverages && formContent?.endDate
        ? checkOnetimeRequestCoverage(
            patient.coverages,
            formContent?.endDate || "",
            formConfiguration.blockUserIfLessThanRecommendedEndDate?.fieldSpec === "REQUIRED"
          )
        : undefined,
    [patient?.coverages, formContent?.endDate, formConfiguration.blockUserIfLessThanRecommendedEndDate?.fieldSpec]
  );

  // StartEndDate restrictions
  const startDateExceedsMinStartDate = formContent.startDate >= MIN_START_DATE;
  const hasStartDate = Boolean(formContent.startDate);
  const hasEndDateIfApplicable = Boolean(formContent.endDate);
  const hasValidStartDate = startOfDay(formContent.startDate ?? today()) <= startOfDay(plusYears(10, today()));
  const hasValidEndDate = startOfDay(formContent.endDate ?? today()) <= startOfDay(plusYears(10, today()));
  const endDateExceedsStartDate = formContent.endDate ? formContent.endDate >= formContent.startDate : true;

  const validatePatientStayDateRangesEndDate = validatePatientStayRange(formContent.patientStayDateRanges ?? []);
  const hasMinStayDays =
    formContent.patientStayDateRanges?.length && formContent.patientStayDateRanges.length > 0
      ? formContent.patientStayDateRanges[0].rangeEndDate !== null
      : false;
  const hasValidStayDateRange = validatePatientStayDateRangesEndDate.filter((error) => error.isRowInValid)?.length;
  const isPatientStayDatesValid = hasMinStayDays && !hasValidStayDateRange;
  const hasAuthCategory = Boolean(formContent.authCategory);
  const isAdmissionTimeValid = timePattern.test(formContent?.admissionTime || "");
  const isDischargeTimeValid = timePattern.test(formContent?.dischargeTime || "");
  const hasAuthSubcategory = Boolean(formContent.authSubcategory);
  const hasAdmissionDate =
    Boolean(formContent?.admissionDate) &&
    !datefns.isAfter(new Date(formContent?.admissionDate ?? ""), new Date()) &&
    startDateCoverage?.inRange;

  const hasAdmissionTime = Boolean(formContent?.admissionTime) && isAdmissionTimeValid;
  const hasDischargeTime = Boolean(formContent?.dischargeTime) && isDischargeTimeValid;
  const hasDischargedTo = Boolean(formContent?.dischargedTo);
  const hasProviderOONExceptionReason = Boolean(formContent?.performingProviderSelectedAddress?.isOutOfNetwork);
  const hasProviderPracticeOONExceptionReason = Boolean(
    formContent?.selectedPerformingProviderPractice?.selectedLocation?.isOutOfNetwork
  );
  const hasFacilityOONExceptionReason = Boolean(formContent?.facilitySelectedAddress?.isOutOfNetwork);
  const hasOrderingProviderOONExceptionReason = Boolean(formContent?.orderingProviderSelectedAddress?.isOutOfNetwork);

  const hasDischargeDate =
    formContent?.dischargeDate !== undefined &&
    datefns.isValid(formContent?.dischargeDate) &&
    !datefns.isBefore(new Date(formContent?.dischargeDate ?? ""), new Date(formContent?.admissionDate ?? "")) &&
    !datefns.isAfter(new Date(formContent?.dischargeDate ?? ""), new Date());

  // If the form is empty we invalidate `!isExpectedAdmissionDateEdited` check
  const [isExpectedAdmissionDateEdited, setExpectedAdmissionDateEdited] = useState(!formContent.expectedAdmissionDate);

  const hasExpectedAdmissionDate =
    Boolean(formContent?.expectedAdmissionDate) &&
    ((datefns.isValid(formContent?.expectedAdmissionDate) &&
      datefns.isAfter(formContent?.expectedAdmissionDate ?? new Date(), datefns.subDays(new Date(), 1))) ||
      !isExpectedAdmissionDateEdited);

  const hasExpectedAdmissionDateForServiceBasedSR = facilityBasedFeatureEnabled
    ? Boolean(formContent?.expectedAdmissionDate) && datefns.isValid(formContent?.expectedAdmissionDate)
    : Boolean(formContent?.startDate) && datefns.isValid(formContent?.startDate);

  const expectedAdmissionDateValueForPlannedAdmission =
    formContent?.admissionDate || formContent?.expectedAdmissionDate;

  const dischargeDate = new Date(formContent?.expectedDischargeDate ?? "");
  const admissionDate = new Date(expectedAdmissionDateValueForPlannedAdmission ?? "");
  const { scrubTinsEnabled: hideTinField } = useContext(ScrubTinsContext);

  const hasExpectedDischargeDate =
    (formContent?.expectedDischargeDate !== undefined &&
      datefns.isValid(formContent?.expectedDischargeDate) &&
      !datefns.isBefore(dischargeDate, admissionDate)) ||
    datefns.isSameDay(dischargeDate, admissionDate);

  const hasSubCategories = formContent.authCategory?.subcategories?.length
    ? formContent.authCategory?.subcategories?.length > 0
    : false;
  const checkAuthSubCategory = hasSubCategories ? hasAuthSubcategory : true;
  const inPatientValidatePx =
    formContent.isInpatient &&
    facilityBasedFeatureEnabled &&
    (!formContent.procedureCodes || formContent.procedureCodes.length === 0);
  const validateCareParticipantField =
    formContent?.additionalCareParticipants &&
    formContent?.additionalCareParticipants?.length > 0 &&
    !validateEachFieldOfCareParticipant(formContent.additionalCareParticipants);

  const { showExceededDurationLimitMessageErrorState, showBelowDurationLimitMessageErrorState } =
    useCalculateDateSelectionMessageDisplay(formContent, patient?.coverages || [], patient, formConfiguration);

  const filteredProcedureCodes = formContent?.procedureCodes?.filter(
    (procedureCode) =>
      procedureCode.attributeType && (!procedureCode.selectedDetails || !procedureCode?.selectedDetails?.length)
  );

  // Check if there are required procedures with selected details
  const hasProceduresWithSelectedDetail =
    formFieldConfigurations?.selectedDetails?.fieldSpec === "REQUIRED" &&
    pxCodeAttributeConfiguration?.showPxCodeAttributeField
      ? filteredProcedureCodes.length > 0
      : false;

  const VALIDATION_MAP: Record<ServiceRequestFieldName, boolean> = {
    selectedDetails: fieldIsValid({ fieldSpec: "REQUIRED" }, !hasProceduresWithSelectedDetail),
    expeditedTatUpdateTimestamp: true,
    placeOfService: fieldIsValid(formConfiguration?.placeOfService, hasPlaceOfService),
    orderingProvider: fieldIsValid(formConfiguration.orderingProvider, hasOrderingProvider),
    orderingProviderTIN:
      hideTinField ||
      (!userTinValidation &&
        formContent?.orderingProvider?.source === "CMS" &&
        !formContent?.orderingProvider?.manuallyCreated) ||
      fieldIsValid(formConfiguration.orderingProviderTIN, hasOrderingProviderTin),
    orderingProviderNPI: fieldIsValid(formConfiguration.orderingProviderNPI, hasOrderingProviderNpi),
    orderingProviderAddress: fieldIsValid(formConfiguration.orderingProviderAddress, hasOrderingProviderAddress),
    performingProvider: fieldIsValid(formConfiguration?.performingProvider, hasPerformingProvider),
    performingProviderTIN: validatePerformingProviderTin(
      formConfiguration,
      formContent,
      performingProviderTinLength,
      hasPerformingProviderTin
    ),
    performingProviderNPI: validatePerformingProviderNPI(formConfiguration, formContent, hasPerformingProviderNpi),
    performingProviderAddress: validatePerformingProviderAddress(
      formConfiguration,
      formContent,
      patientHealthPlanName,
      hasPerformingProviderAddress
    ),
    performingProviderPractice: fieldIsValid(
      formConfiguration?.performingProviderPractice,
      hasPerformingProviderPractice
    ),
    performingProviderPracticeSelectedTIN:
      hideTinField ||
      (!userTinValidation &&
        formContent.selectedPerformingProviderPractice?.source === "CMS" &&
        !formContent?.selectedPerformingProviderPractice?.manuallyCreated) ||
      fieldIsValid(formConfiguration.performingProviderPracticeSelectedTIN, hasPerformingProviderPracticeTIN),
    performingProviderPracticeSelectedAddress: fieldIsValid(
      formConfiguration?.performingProviderPracticeSelectedAddress,
      hasPerformingProviderPracticeAddress
    ),
    performingProviderPracticeSelectedNPI: fieldIsValid(
      formConfiguration?.performingProviderPracticeSelectedNPI,
      hasPerformingProviderPracticeNpi
    ),
    facility: fieldIsValid(formConfiguration.facility, hasFacility),
    facilityNPI: fieldIsValid(formConfiguration?.facilityNPI, hasFacilityNpi),
    facilityTIN:
      hideTinField ||
      (!userTinValidation && formContent?.facility?.source === "CMS" && !formContent?.facility?.manuallyCreated) ||
      fieldIsValid(formConfiguration?.facilityTIN, hasFacilityTin),
    facilityAddress: fieldIsValid(formConfiguration?.facilityAddress, hasFacilityAddress),
    facilityOutOfNetworkExceptionReason:
      (facilityExceptionRequest && !formContent?.facilityOONExceptionRequired) ||
      (formContent?.facilityOONExceptionRequired && hasFacilityOONExceptionReason)
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, !!formContent?.facilitySelectedAddress?.outOfNetworkExceptionReason)
        : fieldIsValid(formConfiguration?.facilityOutOfNetworkExceptionReason),

    performingProviderOutOfNetworkExceptionReason:
      (performingProviderExceptionRequest && !formContent?.performingProviderOONExceptionRequired) ||
      (formContent?.performingProviderOONExceptionRequired && hasProviderOONExceptionReason)
        ? fieldIsValid(
            { fieldSpec: "REQUIRED" },
            !!formContent?.performingProviderSelectedAddress?.outOfNetworkExceptionReason
          )
        : fieldIsValid(formConfiguration?.performingProviderOutOfNetworkExceptionReason),
    performingProviderPracticeOutOfNetworkExceptionReason:
      (performingProviderPracticeExceptionRequest &&
        !formContent?.selectedPerformingProviderPracticeOONExceptionRequired) ||
      (formContent?.selectedPerformingProviderPracticeOONExceptionRequired && hasProviderPracticeOONExceptionReason)
        ? fieldIsValid(
            { fieldSpec: "REQUIRED" },
            !!formContent?.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason
          )
        : fieldIsValid(formConfiguration?.performingProviderPracticeOutOfNetworkExceptionReason),

    orderingProviderOutOfNetworkExceptionReason:
      (hasOrderingProviderExceptionRequestOnContinuation && !formContent?.orderingProviderOONExceptionRequired) ||
      (formContent?.orderingProviderOONExceptionRequired && hasOrderingProviderOONExceptionReason)
        ? fieldIsValid(
            { fieldSpec: "REQUIRED" },
            !!formContent?.orderingProviderSelectedAddress?.outOfNetworkExceptionReason
          )
        : fieldIsValid(formConfiguration?.orderingProviderOutOfNetworkExceptionReason),

    facilityOutOfNetworkExceptionComment:
      (requiresFacilityOONExceptionComment && !!hasFacilityOONExceptionComment) || !requiresFacilityOONExceptionComment,
    performingProviderOutOfNetworkExceptionComment:
      (requiresPerformingProviderOONExceptionComment && !!hasPerformingProviderOONExceptionComment) ||
      !requiresPerformingProviderOONExceptionComment,
    performingProviderPracticeOutOfNetworkExceptionComment:
      (requiresPerformingProviderPracticeOONExceptionComment && !!hasPerformingProviderPracticeOONExceptionComment) ||
      !requiresPerformingProviderPracticeOONExceptionComment,
    orderingProviderOutOfNetworkExceptionComment:
      (requiresOrderingProviderOONExceptionComment && !!hasOrderingProviderOONExceptionComment) ||
      !requiresOrderingProviderOONExceptionComment,
    careParticipantOutOfNetworkExceptionReason: formContent.additionalCareParticipants
      ? (careParticipantExceptionRequest &&
          !isAnyCareParticipantOONExceptionRequired(formContent.additionalCareParticipants)) ||
        validateOONExceptionReasonFieldForCareParticipants(formContent.additionalCareParticipants, false)
      : true,
    careParticipantOutOfNetworkExceptionComment: formContent.additionalCareParticipants
      ? validateOONExceptionCommentFieldForCareParticipants(formContent.additionalCareParticipants)
      : true,
    singleClinicalService: true,
    encounterType: true,
    primaryDiagnosis: true,
    secondaryDiagnoses: true,
    procedureCodes:
      allowedPxCodePerPayer && allowedPxCodePerPayer > 0
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasAllowedProcedureCodePerPayer)
        : true,
    recurring: true,
    startEndDate: fieldIsValid(
      formContent.isInpatient ? { fieldSpec: "OMIT" } : formConfiguration.startEndDate,
      hasStartDate && hasEndDateIfApplicable,
      startDateExceedsMinStartDate &&
        endDateExceedsStartDate &&
        hasValidStartDate &&
        hasValidEndDate &&
        endDateCoverage?.inRange
    ),
    units: fieldIsValid(formConfiguration?.units, hasUnits && allPxsHaveUnits),
    urgency: true,
    behavioralHealthReviewType: true,
    behavioralHealthAdmissionType: true,
    admissionDischargeDate: true,
    faxInputField: fieldIsValid(
      formConfiguration.faxInputField,
      hasFollowUpFaxNumber,
      followUpFaxNumberHasAcceptableDigits
    ),
    procedureCodeWithUnits: fieldIsValid(
      inPatientValidatePx ? { fieldSpec: "OMIT" } : formConfiguration.procedureCodes,
      hasPxCodesWithUnits
    ),
    patientStayDateRanges: validatePatientStayDates(
      facilityBasedFeatureEnabled,
      formContent?.isInpatient,
      isPatientStayDatesValid,
      includePatientStayDatesOnPlannedAdmission,
      formContent?.patientStatus
    ),
    authCategory:
      facilityBasedFeatureEnabled && formContent?.isInpatient
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasAuthCategory)
        : fieldIsValid(formConfiguration.authCategory, hasAuthCategory),
    authSubCategory:
      facilityBasedFeatureEnabled && formContent?.isInpatient && hasSubCategories
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, checkAuthSubCategory)
        : fieldIsValid(formConfiguration.authSubCategory, hasAuthSubcategory),
    prescribedDrug: true,
    outOfNetworkCheck: true,
    facilityOutOfNetworkStatusDisplay: true,
    performingProviderOutOfNetworkStatusDisplay: true,
    performingProviderPracticeOutOfNetworkStatusDisplay: true,
    orderingProviderOutOfNetworkStatusDisplay: true,
    careParticipantOutOfNetworkStatusDisplay: true,
    outOfNetworkOrderingProvider: true,
    userDeclaredOONException: true,
    admissionDate:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus !== "NOT_YET_ADMITTED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasAdmissionDate)
        : fieldIsValid(formConfiguration.admissionDate, hasAdmissionDate),
    admissionTime:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus !== "NOT_YET_ADMITTED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasAdmissionTime)
        : fieldIsValid(formConfiguration.admissionDate, hasAdmissionTime),
    dischargeDate:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus === "DISCHARGED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasDischargeDate)
        : fieldIsValid(formConfiguration.dischargeDate, hasDischargeDate),
    dischargeTime:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus === "DISCHARGED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasDischargeTime)
        : fieldIsValid(formConfiguration.dischargeTime, hasDischargeTime),
    dischargedTo:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus === "DISCHARGED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasDischargedTo)
        : fieldIsValid(formConfiguration.dischargedTo, hasDischargedTo),
    expectedAdmissionDate: facilityBasedFeatureEnabled
      ? formContent?.isInpatient && formContent?.patientStatus === "NOT_YET_ADMITTED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasExpectedAdmissionDate)
        : fieldIsValid(formFieldConfigurations.expectedAdmissionDate, hasExpectedAdmissionDate)
      : formContent?.isInpatient
      ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasExpectedAdmissionDateForServiceBasedSR)
      : fieldIsValid(formFieldConfigurations.expectedAdmissionDate, hasExpectedAdmissionDateForServiceBasedSR),
    expectedDischargeDate:
      !facilityBasedFeatureEnabled && formContent?.isInpatient
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasExpectedDischargeDate)
        : fieldIsValid(formFieldConfigurations.expectedDischargeDate, hasExpectedDischargeDate),
    additionalCareParticipants: !validateCareParticipantField,
    nonPalCheckbox: true,
    blockUserIfExceedsRecommendedEndDate: fieldIsValid(
      formFieldConfigurations.blockUserIfExceedsRecommendedEndDate,
      !showExceededDurationLimitMessageErrorState
    ),
    blockUserIfLessThanRecommendedEndDate: fieldIsValid(
      formFieldConfigurations.blockUserIfLessThanRecommendedEndDate,
      !showBelowDurationLimitMessageErrorState
    ),
    userSelectedOONException: true,
    admissionSource: true,
    patientStatus: true,
  };

  const validateFields = () => {
    const formConfig = formConfiguration;
    if (formConfig) {
      const formConfigKeys = Object.keys(formConfig) as ServiceRequestFieldName[];
      for (const key of formConfigKeys) {
        if (!VALIDATION_MAP[key]) {
          return false;
        }
      }
      return true;
    }
    //should never happen, but if it does we should block the user from trying to submit
    return false;
  };

  const isValid = validateFields();

  const handleFormValidation = useCallback(
    (isFormValid: boolean) => {
      setCanBeSubmitted(isFormValid);
    },
    [setCanBeSubmitted]
  );

  useEffect(() => {
    handleFormValidation(isValid);
  }, [isValid, handleFormValidation]);

  const authCategoryClinicalService = formContent?.clinicalServices?.find((clinicalService) =>
    Boolean(clinicalService.authCategory)
  );

  const clinicalServiceId = authCategoryClinicalService
    ? authCategoryClinicalService.id
    : formContent?.clinicalServices && formContent?.clinicalServices.length > 0
    ? getSortedClinicalServices(formContent.clinicalServices)[0].id
    : undefined;
  const { data: placeOfServiceData, loading: placeOfServiceLoading } = useGetPlaceOfServices({
    queryParams: {
      healthPlanName: patientHealthPlanName || undefined,
      encounterType: formContent.isInpatient ? "INPATIENT" : "OUTPATIENT",
      clinicalServiceId: facilityBasedFeatureEnabled && formContent.isInpatient ? undefined : clinicalServiceId,
    },
  });

  const { redirectModalProps, dispatchRedirectRuleCheck, dispatchUrgencyRuleCheck } =
    useServiceRequestFormRedirectDispatch({
      workflowId,
      formContent,
      clinicalService: formContent.clinicalServices?.[0],
      healthPlanName: patientHealthPlanName,
      patient,
      authStage: convertStringToAuthBuilderWorkflowStep("FILL_FORMS_GENERAL_AUTH_SUBMISSION"),
    });

  const containerClasses = useMuiContainerStyles();
  const maxWidth = !presubmissionReviewPage ? "lg" : false;
  const hasPalProcedureCodes =
    formContent.procedureCodes.length || (!facilityBasedFeatureEnabled && formContent.isInpatient);
  const patientRiskBearingEntity = getPatientRiskBearingEntity(patient || undefined, formContent.startDate) || "";

  const ServicesAndProceduresCardContainer = () => {
    return (
      <>
        <ServicesAndProceduresCard
          formConfiguration={formFieldConfigurations}
          formContent={formContent}
          setFormContent={setFormContent}
          attemptedSubmit={attemptedSubmit}
          patient={patient}
          authStatus={authStatus}
          workflowId={workflowId}
          showEndDate={showEndDate}
          dispatchRedirectRuleCheck={dispatchRedirectRuleCheck}
          dispatchUrgencyRuleCheck={dispatchUrgencyRuleCheck}
          palProcedureCodes={palProcedureCodes}
          setPalProcedureCodes={setPalProcedureCodes}
          nonPalProcedureCodes={nonPalProcedureCodes}
          setNonPalProcedureCodes={setNonPalProcedureCodes}
          nonCohereProcedureCodes={nonCohereProcedureCodes}
          setNonCohereProcedureCodes={setNonCohereProcedureCodes}
          facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
          hideExpeditedRequestCheckbox={hideExpeditedRequestCheckbox}
          serviceRequest={serviceRequest}
          allowedPxCodePerPayer={allowedPxCodePerPayer}
          showPxBasedAttributedField={showPxBasedAttributedField}
          showPxAttributeValidation={formConfiguration?.selectedDetails?.fieldSpec === "REQUIRED"}
          hasValidStartDate={hasValidStartDate}
          hasValidEndDate={hasValidEndDate}
        />
        <div style={{ marginTop: spacing(3) }}>
          {otherInsuranceEnabled &&
            !finalDeterminationStates.includes(serviceRequest?.authStatus || "") &&
            serviceRequest?.requestType === "INITIAL" && (
              <AdditionalInsuranceSection
                otherInsurance={otherInsurance}
                healthPlanName={patientHealthPlanName}
                defaultAdditionalInsurance={formContent.additionalInsurance}
                onChange={(additionalInsurance: AdditionalInsurance | undefined) => {
                  setFormContent({ ...formContent, additionalInsurance: additionalInsurance });
                }}
              />
            )}
        </div>
      </>
    );
  };

  return (
    <Container classes={containerClasses} maxWidth={maxWidth} disableGutters={presubmissionReviewPage}>
      {isAuthorizedForRequestorForm && showRequestorCard && requestorProps && (
        <Box paddingTop={isFaxAuthBuilderFlow ? 3 : 5}>
          <RequestorCard {...requestorProps} />
        </Box>
      )}
      <Box marginTop={2}>
        <Grid container spacing={3}>
          {isEdit && (
            <Grid item xs={12}>
              <DiagnosisCard
                authStatus={authStatus}
                error={attemptedSubmit && !VALIDATION_MAP["primaryDiagnosis"]}
                formConfiguration={formFieldConfigurations}
                formContent={formContent}
                isCohereFaxForm={isCohereFaxForm}
                setFormContent={setFormContent}
                workflowId={workflowId}
                serviceRequest={serviceRequest}
                healthPlanName={patientHealthPlanName}
                delegatedVendorName={
                  serviceRequest?.delegatedVendor ||
                  (authStatus === "DRAFT" ? patientRiskBearingEntity : undefined) ||
                  ""
                }
                isContinuation={serviceRequest?.requestType === "CONTINUATION"}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ProvidersCard
              authStatus={authStatus}
              formContent={formContent}
              isInpatient={formContent.isInpatient}
              sameProviders={sameProviders}
              setSameProviders={setSameProviders}
              placeOfService={formContent.placeOfService || null}
              setFormContent={setFormContent}
              attemptedSubmit={attemptedSubmit}
              facilityIsOptional={formConfiguration.facility.fieldSpec === "OPTIONAL"}
              performingIsOptional={formConfiguration.performingProvider.fieldSpec === "OPTIONAL"}
              placeOfServiceLoading={placeOfServiceLoading}
              availablePlacesOfService={placeOfServiceData || undefined}
              setPlaceOfService={(pos) => setFormContent((prev) => ({ ...prev, placeOfService: pos }))}
              dispatchRedirectRuleCheck={dispatchRedirectRuleCheck}
              VALIDATION_MAP={VALIDATION_MAP}
              formFieldConfigurations={formFieldConfigurations}
              outOfNetworkCheckConfiguration={outOfNetworkCheckConfiguration}
              patient={patient || undefined}
              delegatedVendorName={delegatedVendorName}
              isContinuation={serviceRequest?.requestType === "CONTINUATION"}
              workflowId={workflowId}
              faxNumberIsAutofilled={faxNumberIsAutofilled}
              priorAuthRequirements={priorAuthRequirements}
              setPriorAuthRequirements={setPriorAuthRequirements}
              isFacilityOutOfNetworkCommentRequired={requiresFacilityOONExceptionComment}
              isPerformingProviderOutOfNetworkCommentRequired={requiresPerformingProviderOONExceptionComment}
              authStage={convertStringToAuthBuilderWorkflowStep("FILL_FORMS_GENERAL_AUTH_SUBMISSION")}
              performingProviderExceptionRequest={performingProviderExceptionRequest || false}
              setPerformingProviderExceptionRequest={setPerformingProviderExceptionRequest}
              facilityExceptionRequest={facilityExceptionRequest || false}
              setFacilityExceptionRequest={setFacilityExceptionRequest}
              orderingProviderExceptionRequest={orderingProviderExceptionRequest || false}
              setOrderingProviderExceptionRequest={setOrderingProviderExceptionRequest}
              careParticipantExceptionRequest={careParticipantExceptionRequest || false}
              setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
              onFillFormsPage={onFillFormsPage}
              performingProviderPracticeExceptionRequest={performingProviderPracticeExceptionRequest || false}
              setPerformingProviderPracticeExceptionRequest={setPerformingProviderPracticeExceptionRequest}
              isPerformingProviderPracticeOutOfNetworkCommentRequired={
                requiresPerformingProviderPracticeOONExceptionComment
              }
              setPerformingProviderPracticeOonCheckLoading={setPerformingProviderPracticeOonCheckLoading}
            />
          </Grid>
          {facilityBasedFeatureEnabled && (
            <>
              {shouldUseCohereCard() ? (
                <Grid item xs={12}>
                  <CohereCard>
                    <PatientStayCard
                      formContent={formContent}
                      setFormContent={setFormContent}
                      healthPlanName={patientHealthPlanName}
                      delegatedVendorName={
                        serviceRequest?.delegatedVendor ||
                        (authStatus === "DRAFT" ? patientRiskBearingEntity : null) ||
                        ""
                      }
                      attemptedSubmit={attemptedSubmit}
                      timePattern={timePattern}
                      startDateCoverage={startDateCoverage}
                      formConfiguration={formFieldConfigurations}
                      authStatus={authStatus}
                      patient={patient}
                      dispatchUrgencyRuleCheck={dispatchUrgencyRuleCheck}
                      hideExpeditedRequestCheckbox={hideExpeditedRequestCheckbox}
                      disablePatientStatusChange={true}
                      shouldNotWaitForAuth={true}
                      encounterType={serviceRequest?.encounterType}
                      isContinuation={serviceRequest?.requestType === "CONTINUATION"}
                      isExpectedAdmissionDateEdited={isExpectedAdmissionDateEdited}
                      setExpectedAdmissionDateEdited={setExpectedAdmissionDateEdited}
                    />
                  </CohereCard>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <PatientStayCard
                    formContent={formContent}
                    setFormContent={setFormContent}
                    healthPlanName={patientHealthPlanName}
                    delegatedVendorName={
                      serviceRequest?.delegatedVendor ||
                      (authStatus === "DRAFT" ? patientRiskBearingEntity : null) ||
                      ""
                    }
                    attemptedSubmit={attemptedSubmit}
                    timePattern={timePattern}
                    startDateCoverage={startDateCoverage}
                    formConfiguration={formFieldConfigurations}
                    authStatus={authStatus}
                    patient={patient}
                    dispatchUrgencyRuleCheck={dispatchUrgencyRuleCheck}
                    hideExpeditedRequestCheckbox={hideExpeditedRequestCheckbox}
                    disablePatientStatusChange={true}
                    shouldNotWaitForAuth={true}
                    encounterType={serviceRequest?.encounterType}
                    isContinuation={serviceRequest?.requestType === "CONTINUATION"}
                    isExpectedAdmissionDateEdited={isExpectedAdmissionDateEdited}
                    setExpectedAdmissionDateEdited={setExpectedAdmissionDateEdited}
                  />
                </Grid>
              )}
            </>
          )}
          {!hasPalProcedureCodes &&
            serviceRequest?.healthPlanName === "Geisinger" &&
            otherInsuranceEnabled &&
            !finalDeterminationStates.includes(serviceRequest?.authStatus || "") &&
            (serviceRequest?.requestType === "INITIAL" || serviceRequest?.requestType === "CONTINUATION") && (
              <>
                <Grid item xs={12}>
                  <Divider style={{ marginTop: spacing(3) }} />
                </Grid>
                <Grid item xs={12} style={{ marginTop: spacing(3) }}>
                  <AdditionalInsuranceSection
                    otherInsurance={otherInsurance}
                    healthPlanName={patientHealthPlanName}
                    defaultAdditionalInsurance={
                      serviceRequest?.additionalInsurance || {
                        additionalInsurance: "NONE",
                        additionalInsuranceText: "None",
                      }
                    }
                    onChange={(additionalInsurance: AdditionalInsurance | undefined) => {
                      setFormContent({ ...formContent, additionalInsurance: additionalInsurance });
                    }}
                  />
                </Grid>
              </>
            )}
          {hasPalProcedureCodes && (
            <>
              {shouldUseCohereCard() ? (
                <Grid item xs={12}>
                  <CohereCard>{ServicesAndProceduresCardContainer()}</CohereCard>
                </Grid>
              ) : (
                <Grid item xs={12} style={{ marginTop: spacing(5) }}>
                  {ServicesAndProceduresCardContainer()}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
      <ServiceRequestFormRedirectModal
        {...redirectModalProps}
        formContent={formContent}
        setFormContent={setFormContent}
      />
    </Container>
  );
}
