import {
  DateSelect,
  InlineButton,
  MiddleTruncateLabelWithEndornment,
  SingleSelectDropdownCancel,
  useEffectDeepEquality,
  useFeature,
} from "@coherehealth/common";
import { LevelOfCareResponse, PatientStatus } from "@coherehealth/core-platform-api";
import { Body1, Caption } from "@coherehealth/design-system";
import { Box, Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { styled } from "@mui/material/styles";
import { PatientStayDateRange } from "common/SharedServiceRequestFormComponents";
import { differenceInCalendarDays } from "date-fns";
import { useState } from "react";
import { checkDateFormat } from "./PatientStays";
import { PatientStaysErrorState } from "./usePatientStayDateOnCRR";
import { useDateRowStyles } from "./patientStayDateRowStyles";

/**
 * PatientStay version is being used on authBuilder.
 * PatientStayResponsive version is being used on authBuilder with responsive design
 * RequestedStay version is being used on clinical review page.
 * DecisionStay version is being used on outcome model on clinical review model.
 */
export type StayPageVersion = "PatientStay" | "PatientStayResponsive" | "RequestedStay" | "DecisionStay";

interface PatientStayDateRowProps extends PatientStayDateRange {
  version: StayPageVersion;
  updateDateRange: (
    {
      rangeStartDate,
      rangeEndDate,
      requestedLevelOfCareCode,
      approvedLevelOfCareCode,
      reviewStatus,
    }: Partial<PatientStayDateRange>,
    rowIndex: number
  ) => void;
  rowIndex: number;
  disableRangeEndDate?: boolean;
  levelsOfCareResponse: LevelOfCareResponse[] | null;
  removePatientStayDateRange: (index: number) => void;
  isRnPartialAdminApproval: boolean;
  minDate?: Date | null;
  patientStaysErrorState?: PatientStaysErrorState;
  attemptedSubmit?: boolean;
  admissionDateErrorMessage?: string;
  patientStatus?: PatientStatus;
  disabled?: boolean;
  forceOneLine?: boolean;
}

const RequestedStayRemoveButton = styled(InlineButton)(({ theme }) => ({
  color: theme.palette.text.tertiary,
  transition: theme.transitions.create("opacity"),
  height: theme.spacing(7),
  minWidth: theme.spacing(7),
  borderRadius: 0,
  "&:hover": {
    color: theme.palette.text.tertiary,
  },
}));

const RemoveButton = styled(InlineButton)(({ theme }) => ({
  "&.MuiButton-root": {
    margin: theme.spacing(0, 2),
    color: theme.palette.text.tertiary,
    opacity: 0,
    transition: theme.transitions.create("opacity"),
    "&:hover": {
      color: theme.palette.text.secondary,
    },
    "&:active": {
      color: theme.palette.text.primary,
    },
  },
}));
const PatientStayDateRowContainer = styled("div")(({ theme }) => ({
  transition: theme.transitions.create("background-color"),
  borderRadius: 4,
  padding: theme.spacing(1),
  display: "flex",
  "&.hasHoverState:hover": {
    backgroundColor: theme.palette.gray.light,
    "& .removeButton": {
      opacity: 1,
    },
  },
}));

function getStayDatesWidth(version: StayPageVersion) {
  switch (version) {
    case "PatientStay":
      return 180;
    case "PatientStayResponsive":
      return 210;
    case "RequestedStay":
      return 160;
    case "DecisionStay":
      return 160;
    default:
      return 160;
  }
}

const PatientStayDateRow = ({
  version,
  updateDateRange,
  rowIndex,
  disableRangeEndDate,
  rangeStartDate,
  rangeEndDate,
  requestedLevelOfCareCode,
  approvedLevelOfCareCode,
  reviewStatus,
  levelsOfCareResponse,
  removePatientStayDateRange,
  patientStaysErrorState,
  attemptedSubmit,
  isRnPartialAdminApproval,
  minDate,
  admissionDateErrorMessage,
  patientStatus,
  disabled,
  forceOneLine,
}: PatientStayDateRowProps) => {
  const levelsOfCareOptions = (levelsOfCareResponse || []).map(({ code }) => ({
    id: code || "",
    label: code,
  }));

  const expandedReviewStayDatesEditsEnabled = useFeature("expandedReviewStayDatesEdits");
  const [initialRangeStartRange] = useState(rangeStartDate);

  const fromDateError =
    (attemptedSubmit &&
      ((!rangeStartDate && patientStaysErrorState?.dateEmptyError) || patientStaysErrorState?.fromDateError)) ||
    false;

  const toDateError =
    (attemptedSubmit &&
      ((!rangeEndDate && patientStaysErrorState?.dateEmptyError) || patientStaysErrorState?.toDateError)) ||
    (attemptedSubmit && !rangeEndDate) ||
    false;

  const displayReviewStatus = (["APPROVED", "DENIED"].includes(reviewStatus || "") && reviewStatus) || "";
  const datesWidth = getStayDatesWidth(version);
  const isResponsive = version === "PatientStayResponsive";
  const isFirstRow = rowIndex === 0;
  const isCurrentlyAdmitted = patientStatus === "CURRENTLY_ADMITTED";
  const isNotYetAdmitted = patientStatus === "NOT_YET_ADMITTED";

  const styles = useDateRowStyles({ forceOneLine });

  useEffectDeepEquality(() => {
    if (isFirstRow && !displayReviewStatus) {
      updateDateRange({ reviewStatus: null, approvedLevelOfCareCode: null }, 0);
    }
  }, [isFirstRow, displayReviewStatus]);

  const isFromDisabled =
    !!disabled || (!expandedReviewStayDatesEditsEnabled && initialRangeStartRange !== null && isFirstRow);

  return (
    <PatientStayDateRowContainer className={rowIndex > 0 ? "hasHoverState" : ""} data-testid="patientStayDateRow">
      <Box display="flex" alignItems="flex-start" justifyContent="space-between" width="100%">
        <Grid container direction="row" spacing={isResponsive ? 1 : 2} className={styles.gridContainer}>
          <Grid item>
            <DateSelect
              error={fromDateError}
              onDateChange={(newStartDate) => updateDateRange({ rangeStartDate: newStartDate }, rowIndex)}
              value={rangeStartDate}
              label="From"
              helperText={attemptedSubmit && (!rangeStartDate ? "Required" : admissionDateErrorMessage)}
              inputProps={{
                width: datesWidth,
              }}
              disabled={isFromDisabled}
              minDate={expandedReviewStayDatesEditsEnabled ? minDate || undefined : undefined}
              disablePortal={true}
              attemptedSubmit={attemptedSubmit}
              showInternalErrorsOnBlur
              allowedInternalErrors={["invalidDate", "maxDate"]}
            />
          </Grid>
          <Grid item component={Box} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Body1 component="span" style={{ lineHeight: "56px" }}>
              -
            </Body1>
          </Grid>
          <Grid item>
            <DateSelect
              minDate={rangeStartDate}
              minDateMessage={""}
              error={toDateError}
              onDateChange={(newEndDate) => updateDateRange({ rangeEndDate: newEndDate }, rowIndex)}
              value={rangeEndDate}
              helperText={attemptedSubmit && !rangeEndDate ? "Required" : ""}
              label="To"
              disabled={
                disabled || disableRangeEndDate
              } /* disable the last "to" date, is auto-set to discharged date */
              inputProps={{
                width: datesWidth,
              }}
              disablePortal={true}
              attemptedSubmit={attemptedSubmit}
              showInternalErrorsOnBlur
              allowedInternalErrors={["maxDate"]}
              testId={`to-date-range-field-${rowIndex}`}
            />
          </Grid>
          <Grid item>
            <Box display="flex" flexDirection="column" mt={1}>
              <Caption
                color="text.tertiary"
                paddingBottom={0.5}
                id={`number-of-days-label-${rowIndex}`}
                noWrap
                data-public
              >
                # of days
              </Caption>
              <Body1 aria-labelledby={`number-of-days-label-${rowIndex}`}>
                {rangeEndDate && rangeStartDate && checkDateFormat(rangeEndDate) && checkDateFormat(rangeStartDate)
                  ? differenceInCalendarDays(rangeEndDate, rangeStartDate) + 1
                  : "--"}
              </Body1>
            </Box>
          </Grid>
          {isResponsive && (
            <Grid item component={Box} display="flex" style={{ flex: "1 0 auto", minWidth: "180px", maxWidth: "100%" }}>
              <Box component="span" style={{ flex: "1 1 auto" }}>
                <SingleSelectDropdownCancel
                  label={<MiddleTruncateLabelWithEndornment leftLabel="Level of care " rightLabel={"(optional)"} />}
                  error={attemptedSubmit && patientStaysErrorState?.requestedLocEmptyError}
                  helperText={attemptedSubmit && patientStaysErrorState?.requestedLocEmptyError ? "Required" : ""}
                  options={levelsOfCareOptions}
                  value={requestedLevelOfCareCode ?? ""}
                  canCancel={!!requestedLevelOfCareCode}
                  onCancel={() =>
                    updateDateRange({ requestedLevelOfCareCode: null, approvedLevelOfCareCode: null }, rowIndex)
                  }
                  InputProps={{
                    style: {
                      minWidth: "160px",
                    },
                  }}
                  onChange={(newLevelOfCareCode) => {
                    updateDateRange(
                      {
                        requestedLevelOfCareCode: newLevelOfCareCode,
                      },
                      rowIndex
                    );
                  }}
                  dataPublic={true}
                  disabled={disabled}
                />
              </Box>
              <Box component="span" style={{ flex: "0 1 auto", minWidth: "40px" }}>
                <RemoveButton
                  className="removeButton"
                  startIcon={<DeleteIcon />}
                  onClick={() => removePatientStayDateRange(rowIndex)}
                  style={{
                    visibility: isFirstRow ? "hidden" : "visible",
                    height: "56px",
                    minWidth: "40px",
                    marginLeft: "4%",
                  }}
                  disabled={disabled}
                ></RemoveButton>
              </Box>
            </Grid>
          )}
          {!isResponsive && (
            <Grid item xs={version === "PatientStay" /* xs=true allows grid item to fill empty space in row */}>
              <SingleSelectDropdownCancel
                label={
                  version === "DecisionStay"
                    ? "Requested LOC"
                    : version === "PatientStay"
                    ? "Level of care (optional)"
                    : "LOC"
                }
                error={attemptedSubmit && patientStaysErrorState?.requestedLocEmptyError}
                helperText={attemptedSubmit && patientStaysErrorState?.requestedLocEmptyError ? "Required" : ""}
                options={levelsOfCareOptions}
                value={requestedLevelOfCareCode ?? ""}
                canCancel={!!requestedLevelOfCareCode}
                onCancel={() =>
                  updateDateRange({ requestedLevelOfCareCode: null, approvedLevelOfCareCode: null }, rowIndex)
                }
                InputProps={{
                  style: {
                    width: version === "PatientStay" ? undefined : "184px",
                    minWidth: version === "PatientStay" ? "150px" : undefined,
                  },
                }}
                onChange={(newLevelOfCareCode) => {
                  updateDateRange(
                    {
                      requestedLevelOfCareCode: newLevelOfCareCode,
                    },
                    rowIndex
                  );
                }}
                dataPublic={true}
                disabled={disabled}
              />
            </Grid>
          )}
          {version === "DecisionStay" && (
            <>
              <Grid item>
                <SingleSelectDropdownCancel
                  label="Decision"
                  options={[
                    { id: "APPROVED", label: "Approved" },
                    { id: "DENIED", label: isRnPartialAdminApproval ? "Admin Denial" : "Denied" },
                  ]}
                  value={displayReviewStatus}
                  error={attemptedSubmit && !reviewStatus && patientStaysErrorState?.decisionEmptyError}
                  canCancel={!!reviewStatus}
                  onCancel={() => updateDateRange({ reviewStatus: null, approvedLevelOfCareCode: null }, rowIndex)}
                  InputProps={{
                    style: {
                      width: "184px",
                    },
                  }}
                  onChange={(reviewStatus: string) =>
                    updateDateRange(
                      {
                        reviewStatus: reviewStatus as PatientStayDateRange["reviewStatus"],
                        approvedLevelOfCareCode: reviewStatus === "APPROVED" ? requestedLevelOfCareCode : null,
                      },
                      rowIndex
                    )
                  }
                  disabled={disabled}
                />
              </Grid>
              <Grid item>
                <SingleSelectDropdownCancel
                  label="Approved LOC"
                  options={reviewStatus === "DENIED" ? [{ id: "-", label: "-" }] : levelsOfCareOptions}
                  disabled={disabled || !requestedLevelOfCareCode || !reviewStatus || reviewStatus === "DENIED"}
                  value={reviewStatus === "DENIED" ? "-" : approvedLevelOfCareCode || ""}
                  error={
                    attemptedSubmit &&
                    !approvedLevelOfCareCode &&
                    reviewStatus !== "DENIED" &&
                    patientStaysErrorState?.approvedLocEmptyError
                  }
                  helperText={
                    attemptedSubmit &&
                    !approvedLevelOfCareCode &&
                    reviewStatus !== "DENIED" &&
                    patientStaysErrorState?.approvedLocEmptyError
                      ? "Required"
                      : ""
                  }
                  canCancel={!!approvedLevelOfCareCode}
                  onCancel={() => updateDateRange({ approvedLevelOfCareCode: null }, rowIndex)}
                  InputProps={{
                    style: {
                      width: "184px",
                    },
                  }}
                  onChange={(newLevelOfCareCode) => {
                    updateDateRange(
                      {
                        approvedLevelOfCareCode: newLevelOfCareCode,
                      },
                      rowIndex
                    );
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
        {version === "PatientStay" && (
          <RemoveButton
            className="removeButton"
            startIcon={<DeleteIcon />}
            onClick={() => removePatientStayDateRange(rowIndex)}
            style={{ visibility: isFirstRow ? "hidden" : "visible", height: "56px" }}
            disabled={disabled}
          >
            Remove
          </RemoveButton>
        )}
        {/* On the RequestedStay or DecisionStay versions, hide the Remove button on the first row of Non-Discharged requests */}
        {(version === "RequestedStay" || version === "DecisionStay") && (
          <RequestedStayRemoveButton
            className={"removeButton"}
            onClick={() => removePatientStayDateRange(rowIndex)}
            style={{
              visibility: isFirstRow && (isCurrentlyAdmitted || isNotYetAdmitted) ? "hidden" : "visible",
            }}
            disabled={disabled}
          >
            <DeleteIcon
              style={{
                width: "24px",
                visibility: isFirstRow && (isCurrentlyAdmitted || isNotYetAdmitted) ? "hidden" : "visible",
              }}
            />
          </RequestedStayRemoveButton>
        )}
      </Box>
    </PatientStayDateRowContainer>
  );
};

export default PatientStayDateRow;
