import { format, startOfToday, startOfDay, isValid, parseISO, addDays, parse, subDays, addYears } from "date-fns";
import { utcToZonedTime, format as zonedFormat } from "date-fns-tz";
import { DocSegmentation, FeedbackSegment, PatientStayDate, Segment } from "@coherehealth/core-platform-api";
import { first, last } from "lodash";

/**
 * Returns a datestamp for today at midnight
 */
export const today = startOfToday;
export const startOfDate = startOfDay;

// DATE_FORMAT is currently set to en-US by default.
export const DATE_FORMAT = "MM/dd/yyyy";
export const ISO_DATE_FORMAT = "yyyy-MM-dd";
export const DATETIME_FORMAT = "yyyy-MM-dd, hh:mma";
const DATE_FORMAT_MONTH_NAME = "MMM dd, yyyy";

/**
 * Parses date from string assuming ISO format (i.e. "YYYY-MM-DD")
 *
 * This ignores any time and should treat the date as from the local time zone
 */
export const parseDateFromISOString = (dateStr?: string): Date =>
  parseDateFromISOStringWithoutFallback(dateStr) || today();

export const parseDateFromISOStringWithoutFallback = (dateStr?: string): Date | undefined => {
  if (dateStr !== undefined) {
    const m = parseISO(dateStr);
    if (isValid(m)) {
      return m;
    }
  }
};

export const isTimestamp = (timestamp: number) => timestamp.toString().length === 13 && isValid(new Date(timestamp));

export const removeTimeFromDate = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const getHoursAndMinutesAndSecondsStrFromDate = (date: Date) => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

export const findMaxDate = (dateList: string[]): string | undefined => {
  return dateList.reduce(function (a, b) {
    return a > b ? a : b;
  }, dateList[0]);
};

export const addTimeTextIn24HourFormatToDate = (date: Date, timeString: string): Date => {
  const updatedDate = new Date(date);

  if (!timeString || !timeTextFieldIn24HoursFormatValidation(timeString)) {
    updatedDate.setHours(0, 0, 0, 0);
    return updatedDate;
  }

  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  updatedDate.setHours(hours, minutes, seconds || 0, 0);
  return updatedDate;
};

export const timeTextFieldIn24HoursFormatValidation = (hoursAndMinsAndSeconds: string) => {
  const parts = hoursAndMinsAndSeconds.split(":");

  if (parts.length !== 2 && parts.length !== 3) {
    return false;
  }

  const hour = parseInt(parts[0], 10);
  const minute = parseInt(parts[1], 10);
  const second = parseInt(parts[2], 10) || 0;

  if (isNaN(hour) || hour < 0 || hour > 23) {
    return false;
  }
  if (isNaN(minute) || minute < 0 || minute > 59) {
    return false;
  }
  if (isNaN(second) || second < 0 || second > 59) {
    return false;
  }

  return true;
};

/**
 * Formats an ISO datetime (typically UTC) into Eastern time (EST or EDT).
 * Why?
 * Well, just in case there is some unusual requirment to display some time in Eastern, even if
 * the user is in another time zone.
 */
export const formatDateStrWithTz = (dateStr?: string, tz?: string, format?: string): string => {
  const d = parseDateFromISOStringWithoutFallback(dateStr);
  if (d && isValid(d)) {
    const zonedTime = tz ? utcToZonedTime(d, tz) : d;
    return zonedFormat(zonedTime, format || "MM/dd/yyyy 'at' hh:mm:ss a (z)", {
      timeZone: tz,
    });
  }
  return "";
};
export const formatDateStrAsEastern = (dateStr?: string): string => formatDateStrWithTz(dateStr, "America/New_York");

export const dateAndTimeParsedFromDateStr = (dateStr?: string, tz?: string) => {
  const formattedDateStrWithTz = formatDateStrWithTz(dateStr, tz);
  const splitDateStr = formattedDateStrWithTz.split(" at ");
  return { date: splitDateStr[0], time: splitDateStr[1] };
};
/**
 * Formats date string to default DATE_FORMAT string
 */
export const formatDateStr = (dateStr?: string | Date): string => {
  const d = dateStr instanceof Date ? dateStr : parseDateFromISOStringWithoutFallback(dateStr);
  if (d && isValid(d)) {
    return format(d, DATE_FORMAT);
  }
  return "";
};

/**
 * Formats date string to API default ISO format
 */
export const formatDateToISODate = (dateStr?: string | Date): string => {
  const d = dateStr instanceof Date ? dateStr : parseDateFromISOStringWithoutFallback(dateStr);
  if (d && isValid(d)) {
    return format(d, ISO_DATE_FORMAT);
  }
  return "";
};

export const formatDateStrWithMonthName = (dateStr?: string): string => {
  const d = parseDateFromISOStringWithoutFallback(dateStr);
  if (d && isValid(d)) {
    return format(d, DATE_FORMAT_MONTH_NAME);
  }
  return "";
};

export const formatDateStrWithTime = (dateStr?: string): string => {
  const d = parseDateFromISOStringWithoutFallback(dateStr);
  if (d && isValid(d)) {
    return format(d, DATETIME_FORMAT).toLowerCase();
  }
  return "";
};

export const formatDateStrWithCustomFormat = (dateStr?: string, formatStr?: string, useZonedFormat?: boolean) => {
  const d = parseDateFromISOStringWithoutFallback(dateStr);
  return formatDateWithCustomFormat(d, formatStr, useZonedFormat);
};

export const formatDateWithCustomFormat = (date?: Date, formatStr?: string, useZonedFormat?: boolean) => {
  if (date && isValid(date)) {
    if (useZonedFormat) {
      return zonedFormat(date, formatStr || DATE_FORMAT);
    } else {
      return format(date, formatStr || DATE_FORMAT);
    }
  } else {
    return "";
  }
};

export const formatDateTimeStrToISO = (
  dateStr: string | undefined,
  hours: number,
  minutes: number,
  mValue: boolean
): string => {
  if (dateStr && isValid(parse(dateStr, DATE_FORMAT, new Date()))) {
    const date = dateStr?.split("/");
    const dateTime = new Date(
      Number(date[2]),
      Number(date[0]) - 1,
      Number(date[1]),
      mValue ? hours % 12 : (hours % 12) + 12,
      minutes
    );
    if (isValid(dateTime)) {
      return dateTime.toISOString();
    }
  }
  return "";
};

export const extractDateTimeMeridianStrings = (date: Date) => {
  return {
    date: formatDateStr(date),
    time: formatDateWithCustomFormat(date, "hh:mm"),
    meridian: date.getHours() >= 12 ? "PM" : "AM",
  };
};

export function isDateValidWithFormat(dateStr: string, format: string) {
  return isValid(parse(dateStr, format, new Date()));
}

/**
 * Add days to the given date. If given a null/invalid dateStr, the date will default to today.
 */
export const plusDays = (days: number, dateStr?: string | Date): Date => {
  const d = dateStr instanceof Date ? dateStr : parseDateFromISOStringWithoutFallback(dateStr);
  if (d && isValid(d)) {
    return addDays(d, days);
  } else {
    return addDays(today(), days);
  }
};

export const subtractDays = (days: number, dateStr?: string | Date): Date => {
  const d = dateStr instanceof Date ? dateStr : parseDateFromISOStringWithoutFallback(dateStr);
  if (d && isValid(d)) {
    return subDays(d, days);
  } else {
    return subDays(today(), days);
  }
};

export const plusYears = (years: number, date: Date): Date => {
  const newDate = addYears(startOfDay(date), years);
  return startOfDay(newDate);
};

/**
 * Calculate the difference in the two dates given
 */
export const diffDays = (startDate: Date | undefined, endDate: Date | undefined): number | string => {
  if (startDate === undefined || endDate === undefined) {
    return "-";
  }
  return Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
};
/**
 * Calculate the difference between a date string and now in days, hours, minutes
 */
export const diffDatefromNow = (startDate: string): { days: number; minutes: number; hours: number } => {
  const start = parseDateFromISOString(startDate);
  const end = new Date();
  return diffDates(start, end);
};

/**
 * Calculate the difference between two dates in days, hours, minutes
 */
export const diffDates = (startDate: Date, endDate: Date): { days: number; minutes: number; hours: number } => {
  let minutes = Math.floor((endDate.getTime() - startDate.getTime()) / 60000);
  let hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  return { days, hours, minutes };
};
/**
 * Calculate the latest segment to display
 */
export const getTheLatestSegments = (docSegmentation: DocSegmentation | null | undefined): Segment[] => {
  const feedbackSegments = docSegmentation?.feedbackSegments?.slice().sort(function (a, b) {
    const dateA = new Date(a.dateCreated || "");
    const dateB = new Date(b.dateCreated || "");
    return dateB.valueOf() - dateA.valueOf();
  });
  const originalSegments =
    feedbackSegments && feedbackSegments.length > 0 ? feedbackSegments[0].segments : docSegmentation?.originalSegments;
  return originalSegments || [];
};

/**
 * Calculate the latest feedbackSegment to display
 */

export const getTheLatestFeedbackSegment = (
  docSegmentation: DocSegmentation | null | undefined
): FeedbackSegment | undefined => {
  const feedbackSegments = docSegmentation?.feedbackSegments?.slice().sort(function (a, b) {
    const dateA = new Date(a.dateCreated || "");
    const dateB = new Date(b.dateCreated || "");
    return dateB.valueOf() - dateA.valueOf();
  });
  return feedbackSegments?.[0];
};

/**
 * Compare two ISO-formatted dates
 */
export const compareISODates = (date1: string | undefined, date2: string | undefined): number | undefined => {
  if (date1 && date2) {
    const dateA = new Date(date1);
    const dateB = new Date(date2);
    if (dateA?.getTime() > dateB?.getTime()) {
      return -1;
    } else if (dateA?.getTime() === dateB?.getTime()) {
      return 0;
    } else {
      return 1;
    }
  }
};

/**
 * Compare two ISO-formatted dates without timestamps
 */
export const compareISODatesWithoutTime = (date1: Date | undefined, date2: Date | undefined): number | undefined => {
  if (date1 && date2) {
    const dateA = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const dateB = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    if (dateA > dateB) {
      return -1;
    } else if (
      dateA.getFullYear() === dateB.getFullYear() &&
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getDate() === dateB.getDate()
    ) {
      return 0;
    } else {
      return 1;
    }
  }
};

/**
 * Generates a formatted string to represent the patient stay date range provided in the arguments
 * altogether a given format string to represent the desired format of the output dates.
 * For a given format string "MM/dd/yyyy" we'll get the following the results for each case:
 * - ["2021-01-02 00:00:00"]: "01/02/2021"
 * - ["2021-01-02 00:00:00", "2021-01-03 00:00:00", "2021-01-04 00:00:00"]: "01/02/2021 - 01/04/2021"
 * - []: ""
 * @param patientStayDates array of PatientStayDate objects
 * @returns a formatted string with the first-last patient stays dates
 */
export function getReviewDatesRange(patientStayDates?: PatientStayDate[]): string {
  const startDate = formatDateStr(first(patientStayDates)?.date);
  const endDate = formatDateStr(last(patientStayDates)?.date);
  if (!patientStayDates?.length) {
    return "";
  }
  if (patientStayDates?.length === 1) {
    return `${startDate}`;
  }
  return `${startDate} - ${endDate}`;
}

/**
 * Identifies if a string is ISO date time format.
 */

function isValidISODateTime(dateTimeString: string): boolean {
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;
  return iso8601Regex.test(dateTimeString);
}

/**
 * Formats an ISO datetime (typically UTC) into Eastern time (EST or EDT).
 * Why?
 * well there is a requirement that we display time in eastern, even if
 * the user is in another time zone because we send out audits in eastern timestamp.
 */
// @param jsonData - takes input as JSON object
// @param paths - takes array of string which specifies which fields must be modified in JSON object (i.e ["parentObject:chilldObject:child.attribute"])
// returns JSON object after converting epoch to human readable format on speciied fields
export const convertEpochToTimeStamps = (jsonData: unknown, paths: string[]): unknown => {
  for (const path of paths) {
    const pathElements = path.split(":");
    let current: unknown = jsonData;
    for (let i = 0; i < pathElements.length - 1; i++) {
      const element = pathElements[i];
      if (current && typeof current === "object" && current.hasOwnProperty(element)) {
        current = (current as Record<string, unknown>)[element];
      } else {
        break;
      }
    }
    const lastElement = pathElements[pathElements.length - 1];
    if (current && typeof current === "object" && current.hasOwnProperty(lastElement)) {
      let value = (current as Record<string, unknown>)[lastElement];

      if (typeof value === "string" && isValidISODateTime(value)) {
        value = parseDateFromISOStringWithoutFallback(value);
      }
      if (value instanceof Date || typeof value === "number") {
        (current as Record<string, unknown>)[lastElement] = convertEpochToEastern(
          ((current as Record<string, unknown>)[lastElement] as number) || 0
        );
      } else {
      }
    } else {
    }
  }
  return jsonData;
};

export const convertEpochToEastern = (epoch: number) => {
  // converts epoch to Eastern time (EDT/EST)
  const zonedDate = utcToZonedTime(epoch, "America/New_York");
  // formats the converted date (EST/EDT)
  return zonedFormat(zonedDate || 0, "MM/dd/yyyy 'at' hh:mm:ss a (zzzz)", {
    timeZone: "America/New_York",
  });
};

// Gets a date time string with the format YYYY-MM-DDThh:mm:00.000Z
export const getNoTimezoneDateTimeString = (dateStr?: string | Date, time?: string) => {
  let parsedDateStr = "";
  if (dateStr instanceof Date) {
    const isoDateTimeStr = dateStr.toISOString();
    if (isoDateTimeStr !== "") {
      const isoStrArray = isoDateTimeStr.split("T");
      parsedDateStr = isoStrArray[0]; //get date without time
    }
  } else {
    parsedDateStr = dateStr ?? "";
  }

  const splitTime = time?.split(":");
  if (parsedDateStr !== "" && !!splitTime && splitTime.length > 1) {
    return `${parsedDateStr}T${splitTime[0]}:${splitTime[1]}:00.000Z`; //sets seconds and mills as zero by default
  }
  return "";
};
