import React, { Dispatch, SetStateAction, MutableRefObject, useContext } from "react";
import { UploadFile } from "components/AddAttachments/FileUploadItem";
import {
  Body1,
  UserClickInfoClinicalReviewPage,
  AttachmentInfo,
  PdfViewerRenderError,
  AugmentedDocSegmentation,
  MultiSearchPdfsViewer,
  PdfViewer,
} from "@coherehealth/common";
import ImageViewer from "components/DocumentViewer/ImageViewer";
import {
  Attachment,
  SearchInfo,
  Segment,
  CopyPasteInfo,
  AttachmentGuidelineTextHighlightsSnapshot,
  ServiceRequestResponse,
  AppealResponse,
} from "@coherehealth/core-platform-api";
import { CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { CenteredTextContainer } from "./AttachmentViewerShell";
import { AttachmentViewerConfig } from "@coherehealth/common";
import { ClinicalReviewContext } from "../reviewUtils/clinicalReviewPageUtils";

interface AttachmentViewerProps {
  file: UploadFile;
  fileUrl: string | undefined;
  attachments: Attachment[];
  handleAttachmentClick: (index: number, landingPage?: number) => void;
  appealsForAuth?: AppealResponse[];
  attachmentIndexOpen: number;
  convertedToPDF?: boolean;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  setSearchInfos?: Dispatch<SetStateAction<SearchInfo[]>>;
  currentPage: MutableRefObject<number>;
  zoomLevel: MutableRefObject<number>;
  currentRotate: MutableRefObject<number>;
  openSidePanel?: boolean;
  setOpenSidePanel?: Dispatch<SetStateAction<boolean>>;
  withDocSegmentedSidePanel?: boolean;
  attachmentsInfo?: AttachmentInfo[];
  setAttachmentsInfo?: Dispatch<SetStateAction<AttachmentInfo[]>>;
  downloadError?: Error;
  handleSegmentChange?: (
    updatedSegment: Segment,
    isDeletion: boolean,
    updatedPage: number,
    numberOfPages: number
  ) => void;
  savingFeedbackUpdates?: boolean;
  segmentsUpdated?: boolean;
  docSegmentationInfo?: AugmentedDocSegmentation[] | null;
  setCopiedAttachmentText?: Dispatch<SetStateAction<CopyPasteInfo | undefined>>;
  setAttachmentGuidelineTextHighlightsSnapshot?: Dispatch<SetStateAction<AttachmentGuidelineTextHighlightsSnapshot>>;
  previousAttachmentsExpanded?: boolean;
  setPreviousAttachmentsExpanded?: Dispatch<React.SetStateAction<boolean>>;
  isContinuationRequest?: boolean;
  viewerConfig?: AttachmentViewerConfig;
  serviceRequest?: ServiceRequestResponse | null;
  attachmentSeachAcrossAttachmentsActivated?: boolean;
  loadingRemainingAttachments?: boolean;
  onAttachmentOpenLoaded?: (index?: number) => void;
}

export default function AttachmentViewer({
  file,
  fileUrl,
  attachments,
  handleAttachmentClick,
  appealsForAuth,
  attachmentIndexOpen,
  convertedToPDF,
  setUserClickInfoTracking,
  setSearchInfos,
  currentPage,
  zoomLevel,
  currentRotate,
  openSidePanel,
  setOpenSidePanel,
  withDocSegmentedSidePanel,
  attachmentsInfo,
  setAttachmentsInfo,
  downloadError,
  handleSegmentChange,
  savingFeedbackUpdates,
  segmentsUpdated,
  docSegmentationInfo,
  setCopiedAttachmentText,
  setAttachmentGuidelineTextHighlightsSnapshot,
  previousAttachmentsExpanded,
  setPreviousAttachmentsExpanded,
  isContinuationRequest,
  viewerConfig,
  serviceRequest,
  attachmentSeachAcrossAttachmentsActivated,
  loadingRemainingAttachments,
  onAttachmentOpenLoaded,
}: AttachmentViewerProps) {
  const splitFileName = file.name?.split(".") || "";
  const fileExtension = splitFileName[splitFileName.length - 1].toLowerCase();

  const {
    commonAttachmentsState: { registerJumpToPage },
  } = useContext(ClinicalReviewContext);

  let documentDisplay;
  if (!fileUrl) {
    if (!downloadError) {
      documentDisplay = (
        <CenteredTextContainer>
          <CircularProgress size={144} />
        </CenteredTextContainer>
      );
    } else {
      documentDisplay = (
        <PdfViewerRenderError
          error={downloadError}
          attachments={attachments}
          handleAttachmentClick={handleAttachmentClick}
          attachmentIndexOpen={attachmentIndexOpen}
          openSidePanel={openSidePanel}
          setOpenSidePanel={setOpenSidePanel}
          crrStyling
          withDocSegmentedSidePanel={withDocSegmentedSidePanel}
          viewerConfig={viewerConfig}
          setAttachmentsInfo={setAttachmentsInfo}
        />
      );
    }
  } else if (
    (file.contentType === "image/jpeg" ||
      (!file.contentType && (fileExtension === "jpg" || fileExtension === "jpeg"))) &&
    !convertedToPDF
  ) {
    documentDisplay = (
      <ImageViewer
        url={fileUrl}
        name={file.name}
        rotation={file.rotation}
        crrStyling
        attachments={attachments}
        handleAttachmentClick={handleAttachmentClick}
        openSidePanel={openSidePanel}
        setOpenSidePanel={setOpenSidePanel}
        withDocSegmentedSidePanel={withDocSegmentedSidePanel}
        attachmentsInfo={attachmentsInfo}
        setAttachmentsInfo={setAttachmentsInfo}
      />
    );
  } else if (
    file.contentType === "application/pdf" ||
    (!file.contentType && fileExtension === "pdf") ||
    convertedToPDF
  ) {
    documentDisplay = attachmentSeachAcrossAttachmentsActivated ? (
      <MultiSearchPdfsViewer
        attachments={attachments}
        handleAttachmentClick={handleAttachmentClick}
        appealsForAuth={appealsForAuth}
        attachmentIndexOpen={attachmentIndexOpen}
        setUserClickInfoTracking={setUserClickInfoTracking}
        previousAttachmentsExpanded={previousAttachmentsExpanded}
        setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
        setSearchInfos={setSearchInfos}
        currentPage={currentPage}
        zoomLevel={zoomLevel}
        currentRotate={currentRotate}
        openSidePanel={openSidePanel}
        setOpenSidePanel={setOpenSidePanel}
        withDocSegmentedSidePanel={withDocSegmentedSidePanel}
        attachmentsInfo={attachmentsInfo}
        setAttachmentsInfo={setAttachmentsInfo}
        handleSegmentChange={handleSegmentChange}
        savingFeedbackUpdates={savingFeedbackUpdates}
        segmentsUpdated={segmentsUpdated}
        docSegmentationInfo={docSegmentationInfo}
        setCopiedAttachmentText={setCopiedAttachmentText}
        setAttachmentGuidelineTextHighlightsSnapshot={setAttachmentGuidelineTextHighlightsSnapshot}
        isContinuationRequest={isContinuationRequest}
        viewerConfig={viewerConfig}
        serviceRequest={serviceRequest}
        loadingRemainingAttachments={loadingRemainingAttachments}
        onAttachmentOpenLoaded={onAttachmentOpenLoaded}
        registerJumpToPage={registerJumpToPage}
      />
    ) : (
      <PdfViewer
        fileName={file.name || ""}
        pdfUrl={fileUrl}
        attachments={attachments}
        handleAttachmentClick={handleAttachmentClick}
        attachmentIndexOpen={attachmentIndexOpen}
        setUserClickInfoTracking={setUserClickInfoTracking}
        previousAttachmentsExpanded={previousAttachmentsExpanded}
        setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
        setSearchInfos={setSearchInfos}
        currentPage={currentPage}
        zoomLevel={zoomLevel}
        currentRotate={currentRotate}
        crrStyling
        openSidePanel={openSidePanel}
        setOpenSidePanel={setOpenSidePanel}
        withDocSegmentedSidePanel={withDocSegmentedSidePanel}
        attachmentsInfo={attachmentsInfo}
        setAttachmentsInfo={setAttachmentsInfo}
        handleSegmentChange={handleSegmentChange}
        savingFeedbackUpdates={savingFeedbackUpdates}
        segmentsUpdated={segmentsUpdated}
        docSegmentationInfo={docSegmentationInfo}
        setCopiedAttachmentText={setCopiedAttachmentText}
        setAttachmentGuidelineTextHighlightsSnapshot={setAttachmentGuidelineTextHighlightsSnapshot}
        isContinuationRequest={isContinuationRequest}
        viewerConfig={viewerConfig}
        serviceRequest={serviceRequest}
        onAttachmentOpenLoaded={onAttachmentOpenLoaded}
        registerJumpToPage={registerJumpToPage}
      />
    );
  } else {
    <CenteredTextContainer>
      {viewerConfig?.closeDialogFunction && (
        <div style={{ position: "absolute", top: "8px", right: "8px" }}>
          <IconButton onClick={viewerConfig.closeDialogFunction} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <Body1>Viewing this document type is not supported</Body1>
    </CenteredTextContainer>;
  }

  return <>{documentDisplay}</>;
}
