import { useMemo } from "react";

import { Chip, ChipType, FullPageBanner, H2, H6, Sanitized } from "@coherehealth/common";
import { CardExtensionProcedureCode, SemanticProcedureCode } from "@coherehealth/core-platform-api";
import PurpleClipboard from "components/images/PurpleClipboard";
import { makeStyles } from "@material-ui/core/styles";

interface PriorAuthCheckBannerProps {
  semanticProcedureCodes: SemanticProcedureCode[];
  crdProcedureCodeResults: CardExtensionProcedureCode[] | undefined;
}

export default function PriorAuthCheckBanner({
  semanticProcedureCodes,
  crdProcedureCodeResults,
}: PriorAuthCheckBannerProps) {
  if (crdProcedureCodeResults === undefined || crdProcedureCodeResults.length === 0) {
    return null;
  }

  return (
    <PrioAuthCheckBannerContent
      semanticProcedureCodes={semanticProcedureCodes}
      crdProcedureCodeResults={crdProcedureCodeResults}
    />
  );
}

interface AggregatedMessageObject {
  message: string;
  codes: string[];
}

function PrioAuthCheckBannerContent({
  semanticProcedureCodes,
  crdProcedureCodeResults,
}: {
  semanticProcedureCodes: SemanticProcedureCode[];
  crdProcedureCodeResults: CardExtensionProcedureCode[];
}) {
  const aggregatedMessages: AggregatedMessageObject[] = useMemo(() => {
    return aggregateMessages(semanticProcedureCodes, crdProcedureCodeResults);
  }, [semanticProcedureCodes, crdProcedureCodeResults]);

  const omitChips =
    aggregatedMessages.length === 1 && aggregatedMessages[0].codes.length === semanticProcedureCodes.length;

  if (aggregatedMessages.length === 0) {
    return null;
  }

  return (
    <FullPageBanner bannerIcon={<PurpleClipboard />} backgroundColor="#EAE9F4" padding={0}>
      <H2 style={{ marginBottom: 8 }}>Information about your requested care</H2>
      <CodeSpecificMessages
        aggregatedMessages={aggregatedMessages}
        crdProcedureCodeResults={crdProcedureCodeResults}
        omitChips={omitChips}
      />
    </FullPageBanner>
  );
}

function CodeSpecificMessages({
  aggregatedMessages,
  crdProcedureCodeResults,
  omitChips,
}: {
  aggregatedMessages: AggregatedMessageObject[];
  crdProcedureCodeResults: CardExtensionProcedureCode[];
  omitChips: boolean;
}) {
  const classes = useStyles();

  return (
    <>
      {aggregatedMessages?.map((aggregateMessage) => {
        return (
          <div className={classes.messageAndChipContainer}>
            {!omitChips &&
              aggregateMessage.codes.map((code) => {
                const crdData = crdProcedureCodeResults.find((item) => item.code === code);
                return <CodeChip crdData={crdData} code={code} />;
              })}
            <H6 key={`${aggregateMessage.message}-${aggregateMessage.codes}`} style={{ marginTop: 8 }}>
              <Sanitized __html={aggregateMessage.message?.replaceAll("<p", "<div")?.replaceAll("</p>", "</div>")} />
            </H6>
          </div>
        );
      })}
    </>
  );
}

function CodeChip({ crdData, code }: { crdData: CardExtensionProcedureCode | undefined; code: string }) {
  const disabledStyle = !crdData?.isAuthRequired;
  const warningStyle = crdData?.isAuthRequired && crdData?.delegatedVendor?.toUpperCase() !== "COHERE";
  const chipType: ChipType = disabledStyle ? "disabled" : warningStyle ? "warning" : "default";
  return (
    <Chip
      size="small"
      label={code}
      type={chipType}
      key={`${chipType}-${code}`}
      style={{ padding: "4px 14px 2px", marginRight: "8px" }}
    />
  );
}

const sortAggregatedMessages = (
  aggregatedMessages: AggregatedMessageObject[],
  crdCodeData: CardExtensionProcedureCode[]
) => {
  const result = aggregatedMessages.sort((a, b) => {
    const crdDataA = crdCodeData.find((item) => a.codes.includes(item?.code || ""));
    const crdDataB = crdCodeData.find((item) => b.codes.includes(item?.code || ""));

    return innerSort(crdDataA, crdDataB);
  });
  return result;
};

const innerSort = (
  crdCodeDataA: CardExtensionProcedureCode | undefined,
  crdCodeDataB: CardExtensionProcedureCode | undefined
) => {
  if (crdCodeDataA?.isAuthRequired === crdCodeDataB?.isAuthRequired) {
    if (crdCodeDataA?.delegatedVendor === crdCodeDataB?.delegatedVendor) {
      return 0;
    }
    return crdCodeDataA?.delegatedVendor?.toUpperCase() === "COHERE" ? -1 : 1;
  }
  return crdCodeDataA?.isAuthRequired ? -1 : 1;
};

const aggregateMessages = (
  semanticProcedureCodes: SemanticProcedureCode[],
  crdCodeData: CardExtensionProcedureCode[]
): AggregatedMessageObject[] => {
  const result: AggregatedMessageObject[] = [];

  const noMessageCodes = semanticProcedureCodes
    .filter((semanticCode) => !crdCodeData?.find((crdCodeItem) => crdCodeItem.code === semanticCode.code))
    .map((item) => item.code);
  if (noMessageCodes.length === semanticProcedureCodes.length) {
    return result;
  }

  const uniqueMessages: string[] = Array.from(
    new Set(crdCodeData?.filter((item) => item.palCheckMessage !== undefined).map((item) => item.palCheckMessage!))
  );
  if (uniqueMessages.length === 0) {
    return result;
  }
  const condensedCodeMessageData = uniqueMessages.map((message) => {
    const messageSpecificCrdCodeData = crdCodeData?.filter((item) => item.palCheckMessage === message);
    const messageSpecificCodes: string[] = messageSpecificCrdCodeData
      ?.map((messageSpecificCrdItem) => messageSpecificCrdItem.code || "")
      ?.filter((item) => item !== undefined && Boolean(item.length));

    const sortedCodes = messageSpecificCodes?.sort((a, b) => {
      const crdDataA = crdCodeData?.find((item) => item.code === a);
      const crdDataB = crdCodeData?.find((item) => item.code === b);
      return innerSort(crdDataA, crdDataB);
    });

    return { message: message, codes: sortedCodes };
  });

  result.push(...condensedCodeMessageData);

  return sortAggregatedMessages(result, crdCodeData);
};

const useStyles = makeStyles(() => ({
  messageAndChipContainer: {
    marginBottom: 24,
    "&:last-child": {
      marginBottom: 0,
    },
  },
}));
