import { H6, Caption } from "@coherehealth/common";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { NetworkStatusOverrideSingleSelect, NetworkStatusOverrideOptionType } from "./NeworkStatusOverrideSingleSelect";
import { OutOfNetworkReview, OutOfNetworkReviewConfigurationResponse } from "@coherehealth/core-platform-api";

export interface NetworkStatusOverrideProps {
  facilityNetworkStatusOverride: NetworkStatusOverrideOptionType | undefined;
  setFacilityNetworkStatusOverride: (networkStatus: NetworkStatusOverrideOptionType | undefined) => void;
  performingProviderNetworkStatusOverride: NetworkStatusOverrideOptionType | undefined;
  setPerformingNetworkStatusOverride: (networkStatus: NetworkStatusOverrideOptionType | undefined) => void;
  oonReviewConfig?: OutOfNetworkReviewConfigurationResponse;
  oonErrorStates?: Partial<Record<keyof OutOfNetworkReview, boolean>>;
}

export const NetworkStatusOverride = (props: NetworkStatusOverrideProps): JSX.Element => {
  const {
    facilityNetworkStatusOverride,
    setFacilityNetworkStatusOverride,
    performingProviderNetworkStatusOverride,
    setPerformingNetworkStatusOverride,
    oonReviewConfig,
    oonErrorStates,
  } = props;

  const classes = useStyles();
  const providerOverrideError =
    oonErrorStates?.networkStatusOverride &&
    oonReviewConfig?.networkStatusOverrideRequired &&
    !performingProviderNetworkStatusOverride;
  const facilityOverrideError =
    oonErrorStates?.networkStatusOverride &&
    oonReviewConfig?.networkStatusOverrideRequired &&
    !facilityNetworkStatusOverride;
  return (
    <Box>
      <Grid container className={classes.formWrapper}>
        <Grid item xs={12} className={classes.header}>
          <H6 data-testid="network-status-override--header">Network status</H6>
        </Grid>
        <Grid item xs={12} className={classes.deck}>
          <Caption data-testid="network-status-override--deck">
            The following fields will be used for claims purposes, and is required when the current status is “Unknown”.
          </Caption>
        </Grid>
        <Grid item xs={12} className={classes.dropdownWrapper}>
          <NetworkStatusOverrideSingleSelect
            label={"Performing provider network status"}
            selectedOption={performingProviderNetworkStatusOverride}
            setSelectedOption={setPerformingNetworkStatusOverride}
            testId="network-status-override--performing-provider"
            oonReviewConfig={oonReviewConfig}
            error={providerOverrideError}
            helperText={providerOverrideError ? "Provider override selection is required" : ""}
          />
        </Grid>
        <Grid item xs={12} className={classes.dropdownWrapper}>
          <NetworkStatusOverrideSingleSelect
            label={"Facility network status"}
            selectedOption={facilityNetworkStatusOverride}
            setSelectedOption={setFacilityNetworkStatusOverride}
            testId="network-status-override--facility"
            oonReviewConfig={oonReviewConfig}
            error={facilityOverrideError}
            helperText={facilityOverrideError ? "Facility override selection is required" : ""}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing(0),
  },
  deck: {
    paddingBottom: theme.spacing(5 / 8),
    paddingTop: theme.spacing(1),
  },
  dropdownWrapper: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  formWrapper: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));
