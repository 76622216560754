import { useState, PropsWithChildren } from "react";
import { ServiceRequestResponse, MdReview, PeerToPeerReview, NurseReview } from "@coherehealth/core-platform-api";
import {
  Body1,
  H5,
  Caption,
  H6,
  ReviewTimeDateAuthorCaption,
  Sanitized,
  patientDisplayName,
  InlineButton,
  useFeature,
  parseDateFromISOStringWithoutFallback,
} from "@coherehealth/common";
import { Divider, Grid, useTheme, Theme, makeStyles } from "@material-ui/core";
import ClinicalReviewCard from "common/ClinicalReviewCard";
import RequestedProceduresInformation from "./RequestedProceduresInformation";
import { DividerContainer } from "./ServiceRequestInformation";
import ReplayIcon from "@mui/icons-material/Replay";
import ReturnRequestDetailsModal from "../ReturnRequestDetails/ReturnRequestDetailsModal";
import { getCoverageBasedOnDate } from "util/patientUtils";

interface Props {
  serviceRequest: ServiceRequestResponse | null;
  loadingSRData?: boolean;
  review: MdReview | PeerToPeerReview | NurseReview;
}

export default function CaseOverview({ serviceRequest, review }: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [openReturnRequestModal, setOpenReturnRequestModal] = useState<boolean>(false);
  const logrocketUnmasking = useFeature("logrocketUnmasking");

  const classes = useStyles({ review });
  const returnLetterCaseToReviewerFF = useFeature("returnLetterCaseToReviewer");

  const coverageStateOfIssue =
    serviceRequest?.patient?.coverage?.stateOfIssue ||
    getCoverageBasedOnDate(parseDateFromISOStringWithoutFallback(serviceRequest?.startDate), serviceRequest?.patient)
      ?.stateOfIssue;

  const reviewOutcome = (): string => {
    const reviewOutcome = review?.reviewOutcome ?? "";
    if (["DENIED", "RECOMMENDED_DENIAL"].includes(reviewOutcome)) {
      return "Denied";
    }
    if (["PARTIALLY_APPROVED", "RECOMMENDED_PARTIAL_APPROVAL"].includes(reviewOutcome)) {
      return "Partially approved";
    }
    return "";
  };

  const getPrimaryDiagnosisCaption = (): string => {
    const primaryDiagnosis = serviceRequest?.primaryDiagnosis;
    if (!primaryDiagnosis) {
      return "";
    }
    return [primaryDiagnosis.code, primaryDiagnosis.description].filter(Boolean).join(" - ");
  };

  const statusLabel =
    review.serviceStatusLabel ||
    review.coverageLevelDetails?.find((details) => details.serviceStatusLabel)?.serviceStatusLabel;
  return (
    <ClinicalReviewCard
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      header={
        <>
          <div className={classes.caseOverviewHeaderWrapper}>
            <H5 data-public={!!logrocketUnmasking ? true : undefined}>Case Overview</H5>
            {returnLetterCaseToReviewerFF && (
              <InlineButton
                startIcon={<ReplayIcon />}
                className={classes.returnToReviewerBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenReturnRequestModal(true);
                }}
              >
                Return to reviewer
              </InlineButton>
            )}
          </div>
          <Grid container className={classes.caseOverviewWrapper}>
            <Grid item>
              <Body1 className={classes.styledBody1}>LOB: {serviceRequest?.lobType}</Body1>
            </Grid>
            <Grid item className={classes.facilityStateField}>
              <Body1 className={classes.styledBody1}>Facility state: {serviceRequest?.associatedStateOfUnion}</Body1>
            </Grid>
            {!!coverageStateOfIssue && (
              <Grid item>
                <Body1 className={classes.styledBody1}>Coverage issue state: {coverageStateOfIssue}</Body1>
              </Grid>
            )}
          </Grid>
        </>
      }
    >
      {serviceRequest && isExpanded && (
        <>
          <DetailsField
            label={"Patient Name:"}
            value={serviceRequest.patient && patientDisplayName(serviceRequest.patient)}
          />
          <DetailsField label={"DOB:"} value={serviceRequest.patient?.dateOfBirth} />
          <DetailsField label={"Primary Diagnosis:"} value={getPrimaryDiagnosisCaption()} />
          <DetailsField
            label={"Secondary Diagnosis:"}
            value={serviceRequest.secondaryDiagnoses?.map((d) => d.code).join(", ") ?? ""}
          />
          <DividerContainer>
            <Divider />
          </DividerContainer>
          <RequestedProceduresInformation serviceRequest={serviceRequest} review={review} />
          <DividerContainer>
            <Divider />
          </DividerContainer>
          <div className={classes.reviewDetailsWrapper}>
            <H6>MD Clinical Review</H6>
          </div>
          {review && (
            <div className={classes.reviewTimeDateContainer}>
              <ReviewTimeDateAuthorCaption
                reviewDateCreated={review?.dateCreated || ""}
                reviewLastUpdated={review?.lastUpdated || ""}
                reviewCreatedByName={review?.createdByName || ""}
                clinicalReviewView
              />
            </div>
          )}
          <DetailsField label={"Outcome:"} value={reviewOutcome()} />
          {statusLabel &&
            ["RECOMMENDED_DENIAL", "RECOMMENDED_PARTIAL_APPROVAL", "DENIED", "PARTIALLY_APPROVED"].includes(
              review.reviewOutcome || ""
            ) && <DetailsField label={"Denial reason:"} value={statusLabel} />}
          <DetailsField
            label={"Guideline used:"}
            value={review?.usedGuidelines?.map((guideline) => guideline.guidelineTitle).join(", ")}
          />
          <DetailsField label={"Review notes:"} review={review} />
        </>
      )}
      <ReturnRequestDetailsModal
        isOpen={openReturnRequestModal}
        setIsOpen={setOpenReturnRequestModal}
        review={review}
        patientId={serviceRequest?.patient?.id ?? ""}
      />
    </ClinicalReviewCard>
  );
}

export const DetailsField = ({
  label,
  value,
  review,
  children,
}: PropsWithChildren<{
  label: string;
  value?: string;
  review?: MdReview | PeerToPeerReview | NurseReview | null;
}>) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div style={{ display: "flex", padding: review ? theme.spacing(0, 0, 0, 0) : theme.spacing(0, 0, 1, 0) }}>
      <Caption className={classes.styledCaption} data-public>
        {label}
      </Caption>
      {review ? (
        <Body1 className={classes.styledCommonBody1} component="div" data-public>
          <Sanitized __html={(review.reviewType !== "NurseReview" && review.determinationNote) || "<p>- -<p>"} />
        </Body1>
      ) : (
        <Body1 className={classes.styledCommonBody1} data-public>
          {value || "--"}
        </Body1>
      )}
      {children}
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  returnToReviewerBtn: {
    paddingTop: 0,
  },
  caseOverviewHeaderWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  caseOverviewWrapper: {
    marginTop: 6,
    marginBottom: 4,
  },
  facilityStateField: {
    padding: theme.spacing(0, 3),
  },
  reviewDetailsWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  styledBody1: {
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  styledCaption: {
    color: theme.palette.text.secondary,
    display: "inline-block",
    paddingBottom: theme.spacing(0.5),
    minWidth: "170px",
    marginRight: theme.spacing(5),
    verticalAlign: "top",
  },
  reviewTimeDateContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  styledCommonBody1: {
    paddingBottom: theme.spacing(0.5),
    display: "inline-block",
    wordWrap: "break-word",
  },
}));
