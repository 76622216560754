import { FindingRecordPostRequest, useBulkSaveFindingRecords } from "@coherehealth/core-platform-api";
import { useCallback } from "react";
import { IReviewChatbotFindingHolder } from "./useAutomatedReviewChatbotFindings";
import { mapChatbotFindingToFindingRecordRequest } from "components/ClinicalReview/reviewUtils/reviewChatbotUtils";

interface Props {
  chatbotFindings: IReviewChatbotFindingHolder[];
  isReviewChatbotEnabled: boolean;
}

const useSaveReviewChatbotFindings = ({ chatbotFindings, isReviewChatbotEnabled }: Props) => {
  const { mutate: bulkSaveFindingRecords } = useBulkSaveFindingRecords({});

  const saveReviewChatbotFindings = useCallback(async () => {
    if (!isReviewChatbotEnabled || chatbotFindings.length === 0) {
      return;
    }
    const bulkRequest: FindingRecordPostRequest[] = chatbotFindings.map((holder) => {
      return mapChatbotFindingToFindingRecordRequest(holder.chatbotfinding);
    });

    return bulkSaveFindingRecords(bulkRequest);
  }, [isReviewChatbotEnabled, chatbotFindings, bulkSaveFindingRecords]);

  return { saveReviewChatbotFindings };
};

export { useSaveReviewChatbotFindings };
