import { Body1, Caption } from "@coherehealth/common";

export type PatientInfoReadOnlyTextFieldT = {
  displayText?: string | string[];
  label?: string;
};
export const PatientInfoReadOnlyTextField = ({ displayText, label }: PatientInfoReadOnlyTextFieldT) => {
  return (
    <>
      <Caption>{label}</Caption>
      <Body1>{!displayText || displayText.length === 0 ? "--" : displayText}</Body1>
    </>
  );
};
