import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#E5E5E5",
    animation: "$bounce 1.5s infinite ease-in-out",
  },
  dot1: {
    animationDelay: "0s",
  },
  dot2: {
    animationDelay: "0.2s",
  },
  dot3: {
    animationDelay: "0.4s",
  },
  "@keyframes bounce": {
    "0%, 100%": {
      transform: "translateY(0)",
    },
    "50%": {
      transform: "translateY(-5px)",
    },
  },
}));
export default function LoadingIndicator() {
  const classes = useStyles();
  return (
    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box style={{ display: "flex", gap: 2 }}>
        {[...Array(3)].map((_, index) => (
          <Box key={index} className={`${classes.dot} ${classes[`dot${index + 1}` as keyof typeof classes]}`} />
        ))}
      </Box>
    </Box>
  );
}
