import React, { Dispatch, PropsWithChildren, useCallback, useState } from "react";
import {
  ServiceRequestResponse,
  PhoneNumber,
  RuleActions,
  RecommendChangeRuleAction,
  AuthorizationResponse,
  ServiceRequestSearchResponse,
  OutOfNetworkReviewConfigurationResponse,
  FacilityBasedRequestConfigurationResponse,
  Provider,
  Location,
} from "@coherehealth/core-platform-api";
import {
  Body1,
  Caption,
  formatDateStr,
  parseDateFromISOString,
  useFeature,
  POLICY_UNITED_STATES_OPTIONS,
  formatDateToISODate,
  plusDays,
  H6,
  TertiaryButton,
  colorsLight,
  useConfiguration,
} from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, makeStyles } from "@material-ui/core/styles";
import { GridSize } from "@material-ui/core";
import { formatPhone } from "util/demographicUtils";
import {
  requestTimingToString,
  calculateDecisionedLengthOfStay,
  calculateRequestedLengthOfStay,
} from "util/authorization";
import ContactInfoModal, { ContactInfoModalProps, ContactType } from "components/ContactInfoModal";
import {
  getAdditionalCareParticipantName,
  getDatesOfServiceString,
  getPendingAuthStatuses,
  safelyGetFormConfig,
  unitTypeLabel,
} from "util/serviceRequest";
import { differenceInDays, format } from "date-fns";
import {
  DEFAULT_FORM_CONFIG,
  FormConfiguration,
} from "../ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { isRecommendChangeActionWithAttribute } from "util/rule";
import ContentNudge from "./ContentNudge";
import { strToPhoneNumber } from "util/phoneUtils";
import { getLatestServiceRequest } from "util/authorization";
import { getPatientHealthPlanName } from "util/patientUtils";
import PatientStayModal from "components/ServiceRequest/PatientStay/PatientStayModal";
import { useGetOtherInsuranceConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { SRFormConfigFieldWrapper } from "common/FormConfigUtils";
import { LocationNetworkStatusPill } from "../utils/serviceRequestUtils";
import CareParticipantModal from "./CareParticipantModal";
import { PatientStayModalAuth } from "components/ServiceRequest/PatientStay/PatientStayModal/PatientsStayModalAuth";
import { usePatientStayTimelineEnabled } from "../PatientStay/hooks/usePatientStayTimelineEnabled";

interface Props {
  authorization?: AuthorizationResponse;
  serviceRequest: ServiceRequestResponse;
  hideFacilityState?: boolean;
  ruleActions?: RuleActions;
  isFacilityBasedAuth?: boolean;
  isFaxEdit?: boolean;
  clinicalReviewView?: boolean;
  usePatientDatesAuthModal?: boolean;
  shouldIncludeAdditionalCareParticipants: boolean;
}
const useStyle = makeStyles((theme) => ({
  OutOfNetworkExceptionReasonStyle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.75),
    color: theme.palette.text.secondary,
    display: "inline-block",
  },
  facilityBasedHeader: {
    padding: "19px 0px 5.5px",
  },
  bodyChildrenContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  labelContainer: {
    color: theme.palette.text.secondary,
    display: "inline-block",
  },
}));

const modalOpener = (
  provider: Provider | undefined,
  providerLocation: Location | null | undefined,
  setModalState: React.Dispatch<React.SetStateAction<ContactInfoModalProps>>,
  closeModal: () => void,
  modalType: ContactType
) => {
  setModalState({
    open: true,
    name: provider?.name || "None",
    phones: provider?.phoneNumbers,
    fax: providerLocation?.faxNumbers?.map(strToPhoneNumber),
    tinList: provider?.tinList,
    npi: provider?.npi,
    addresses: [providerLocation?.address],
    type: modalType,
    npiLabel: provider?.npi || "N/A",
    handleClose: closeModal,
  });
};
export default function RequestDetails({
  serviceRequest,
  hideFacilityState,
  ruleActions,
  authorization,
  isFacilityBasedAuth,
  isFaxEdit,
  shouldIncludeAdditionalCareParticipants,
}: Props) {
  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");
  const showPerformingProviderPracticeFieldFF = useFeature("showPerformingProviderPracticeField");
  const oonReviewConfig = useConfiguration(
    "outOfNetworkReviewConfiguration",
    serviceRequest.healthPlanName,
    serviceRequest.delegatedVendor
  );

  const startDate = serviceRequest?.startDate ? new Date(serviceRequest?.startDate) : new Date();
  const healthPlanName = getPatientHealthPlanName(serviceRequest?.patient, startDate) || "";
  const { otherInsuranceEnabled } = useGetOtherInsuranceConfigurationByPayer({
    healthPlanName: healthPlanName ?? "",
  });
  const facilityBasedConfig = useConfiguration(
    "facilityBasedRequestConfiguration",
    healthPlanName,
    serviceRequest?.delegatedVendor
  );

  return (
    <RequestDetailsContent
      serviceRequest={serviceRequest}
      authorization={authorization}
      oonReviewConfig={oonReviewConfig}
      isFacilityBasedAuth={isFacilityBasedAuth}
      ruleActions={ruleActions}
      isFaxEdit={isFaxEdit}
      simplifiedServiceFrequency={simplifiedServiceFrequency}
      showPerformingProviderPracticeFieldFF={showPerformingProviderPracticeFieldFF}
      hideFacilityState={hideFacilityState}
      otherInsuranceEnabled={otherInsuranceEnabled}
      facilityBasedConfig={facilityBasedConfig}
      shouldIncludeAdditionalCareParticipants={shouldIncludeAdditionalCareParticipants}
    />
  );
}

interface RequestDetailsContentProps {
  serviceRequest: ServiceRequestResponse;
  authorization: AuthorizationResponse | undefined;
  oonReviewConfig: OutOfNetworkReviewConfigurationResponse | undefined;
  isFacilityBasedAuth?: boolean;
  ruleActions?: RuleActions;
  isFaxEdit?: boolean;
  usePatientDatesAuthModal?: boolean;
  simplifiedServiceFrequency?: boolean;
  showPerformingProviderPracticeFieldFF?: boolean;
  hideFacilityState?: boolean;
  otherInsuranceEnabled?: boolean;
  facilityBasedConfig?: FacilityBasedRequestConfigurationResponse;
  shouldIncludeAdditionalCareParticipants: boolean;
}

export const RequestDetailsContent = ({
  serviceRequest,
  authorization,
  oonReviewConfig,
  isFacilityBasedAuth,
  ruleActions,
  isFaxEdit,
  usePatientDatesAuthModal = false,
  simplifiedServiceFrequency,
  showPerformingProviderPracticeFieldFF,
  hideFacilityState,
  otherInsuranceEnabled,
  facilityBasedConfig,
  shouldIncludeAdditionalCareParticipants,
}: RequestDetailsContentProps) => {
  const closeModal = () => setModalState((prev) => ({ ...prev, open: false }));
  const [modalState, setModalState] = useState<ContactInfoModalProps>({
    open: false,
    handleClose: () => {
      setModalState((prev) => ({ ...prev, open: false }));
    },
    type: "FACILITY",
  });

  const simplifiedServiceFrequencyOFF = !simplifiedServiceFrequency;

  const facilityOutOfNetworkStatusDisplayOff =
    serviceRequest.formConfiguration?.facilityOutOfNetworkStatusDisplay?.fieldSpec === "OMIT";
  const performingProviderOutOfNetworkStatusDisplayOff =
    serviceRequest.formConfiguration?.performingProviderOutOfNetworkStatusDisplay?.fieldSpec === "OMIT";
  const performingProviderPracticeOutOfNetworkStatusDisplayOff =
    serviceRequest.formConfiguration?.performingProviderPracticeOutOfNetworkStatusDisplay?.fieldSpec === "OMIT";
  const orderingProviderOutOfNetworkStatusDisplayOff =
    serviceRequest.formConfiguration?.orderingProviderOutOfNetworkStatusDisplay?.fieldSpec === "OMIT";

  const providerIsOutOfNetwork = serviceRequest.performingProviderLocation?.isOutOfNetwork;
  const oonExceptionPerformingProviderReason = serviceRequest.performingProviderLocation?.outOfNetworkExceptionReason;
  const oonExceptionPerformingProviderComment = serviceRequest.performingProviderLocation?.outOfNetworkExceptionComment;

  const performingProviderPracticeIsOutOfNetwork =
    serviceRequest.selectedPerformingProviderPractice?.selectedLocation?.isOutOfNetwork;
  const oonExceptionPerformingProviderPracticeReason =
    serviceRequest.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason;
  const oonExceptionPerformingProviderPracticeComment =
    serviceRequest.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionComment;

  const facilityIsOutOfNetwork = serviceRequest.facilityLocation?.isOutOfNetwork;
  const oonExceptionFacilityReason = serviceRequest.facilityLocation?.outOfNetworkExceptionReason;
  const oonExceptionFacilityComment = serviceRequest.facilityLocation?.outOfNetworkExceptionComment;

  const orderingProviderIsOutOfNetwork = serviceRequest.orderingProviderLocation?.isOutOfNetwork;
  const oonExceptionOrderingProviderReason = serviceRequest.orderingProviderLocation?.outOfNetworkExceptionReason;
  const oonExceptionOrderingProviderComment = serviceRequest.orderingProviderLocation?.outOfNetworkExceptionComment;

  const formConfiguration = safelyGetFormConfig(serviceRequest?.formConfiguration || DEFAULT_FORM_CONFIG);
  const latestSr = authorization ? getLatestServiceRequest(authorization)?.[1] : undefined;
  const outOfNetworkStatusOverrideEnabled = oonReviewConfig?.enabled && oonReviewConfig?.networkStatusOverride;

  const estimatedEndDate = serviceRequest.endDate
    ? formatDateStr(serviceRequest.endDate)
    : formatDateToISODate(plusDays(90, serviceRequest.startDate));

  const classes = useStyle();

  const orderingProviderOnClickHandler = useCallback(() => {
    modalOpener(
      serviceRequest?.orderingProvider,
      serviceRequest?.orderingProviderLocation,
      setModalState,
      closeModal,
      "ORDERING_PROVIDER"
    );
  }, [serviceRequest]);

  const performingProviderOnClickHandler = useCallback(() => {
    modalOpener(
      serviceRequest?.performingProvider,
      serviceRequest?.performingProviderLocation,
      setModalState,
      closeModal,
      "PERFORMING_PROVIDER"
    );
  }, [serviceRequest]);

  const facilityOnClickHandler = useCallback(() => {
    modalOpener(serviceRequest?.facility, serviceRequest?.facilityLocation, setModalState, closeModal, "FACILITY");
  }, [serviceRequest]);

  const performingProvidePracticeOnClickHandler = useCallback(() => {
    setModalState({
      open: true,
      name: serviceRequest.selectedPerformingProviderPractice?.name || "None",
      phones:
        serviceRequest?.selectedPerformingProviderPractice?.selectedLocation?.phoneNumbers &&
        serviceRequest?.selectedPerformingProviderPractice?.selectedLocation?.phoneNumbers.length > 0
          ? [serviceRequest.selectedPerformingProviderPractice.selectedLocation?.phoneNumbers[0]]
          : undefined,

      fax: serviceRequest.selectedPerformingProviderPractice?.selectedLocation?.faxNumbers?.map(strToPhoneNumber),
      type: "PERFORMING_PROVIDER_PRACTICE",
      tinList: serviceRequest.selectedPerformingProviderPractice?.selectedLocation?.tin
        ? [serviceRequest.selectedPerformingProviderPractice.selectedLocation.tin]
        : undefined,
      npi: serviceRequest.selectedPerformingProviderPractice?.npi,
      addresses: [serviceRequest.selectedPerformingProviderPractice?.selectedLocation?.address],
      npiLabel: serviceRequest.selectedPerformingProviderPractice?.npi || "N/A",
      handleClose: closeModal,
    });
  }, [serviceRequest]);

  return isFacilityBasedAuth ? (
    <>
      <FacilityBasedRequestDetails
        serviceRequest={serviceRequest}
        authorization={authorization}
        formConfiguration={formConfiguration}
        ruleActions={ruleActions}
        isFacilityBasedAuth={isFacilityBasedAuth}
        setModalState={setModalState}
        closeModal={closeModal}
        oonExceptionPerformingProviderReason={oonExceptionPerformingProviderReason}
        oonExceptionPerformingProviderComment={oonExceptionPerformingProviderComment}
        oonExceptionFacilityReason={oonExceptionFacilityReason}
        oonExceptionFacilityComment={oonExceptionFacilityComment}
        oonExceptionOrderingProviderReason={oonExceptionOrderingProviderReason}
        oonExceptionOrderingProviderComment={oonExceptionOrderingProviderComment}
        oonExceptionPerformingProviderPracticeReason={oonExceptionPerformingProviderPracticeReason}
        oonExceptionPerformingProviderPracticeComment={oonExceptionPerformingProviderPracticeComment}
        providerIsOutOfNetwork={providerIsOutOfNetwork}
        facilityIsOutOfNetwork={facilityIsOutOfNetwork}
        orderingProviderIsOutOfNetwork={orderingProviderIsOutOfNetwork}
        latestSr={latestSr}
        isFaxEdit={isFaxEdit}
        outOfNetworkStatusDisplayOff={facilityOutOfNetworkStatusDisplayOff}
        usePatientDatesAuthModal={usePatientDatesAuthModal}
        oonReviewConfig={oonReviewConfig}
        facilityBasedConfig={facilityBasedConfig}
        otherInsuranceEnabled={otherInsuranceEnabled}
      />
      <ContactInfoModal
        open={modalState.open}
        handleClose={modalState.handleClose}
        type={modalState.type}
        name={modalState.name}
        phones={modalState.phones}
        fax={modalState.fax}
        email={modalState.email}
        npi={modalState.npi}
        tinList={modalState.tinList}
        addresses={modalState.addresses}
        npiLabel={modalState.npiLabel}
      />
    </>
  ) : (
    <>
      <SRFormConfigFieldWrapper {...formConfiguration?.prescribedDrug}>
        <DetailsField label={"Quantity"} value={serviceRequest.prescribedDrug?.quantity || "-"} />
        <DetailsField label={"Days supply"} value={serviceRequest.prescribedDrug?.daysSupply + " days" || "-"} />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.primaryDiagnosis}>
        <DetailsField
          label={"Primary diagnosis"}
          value={
            serviceRequest.primaryDiagnosis
              ? `${serviceRequest.primaryDiagnosis?.code}
            ${serviceRequest.primaryDiagnosis?.description ? " - " + serviceRequest.primaryDiagnosis?.description : ""}
          `
              : "--"
          }
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            .find((action) => action.onAcceptAttribute === "primaryDiagnosis")}
        />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.secondaryDiagnoses}>
        <DetailsField
          label={"Secondary diagnosis"}
          value={
            serviceRequest.secondaryDiagnoses ? serviceRequest.secondaryDiagnoses.map((d) => d.code).join(", ") : "--"
          }
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            .find((action) => action.onAcceptAttribute === "secondaryDiagnosis")}
        />
      </SRFormConfigFieldWrapper>
      {/* Only show this field if it _is_ an oon exception request, otherwise hide the whole field */}
      {serviceRequest?.userSelectedOONException && (
        <>
          <SRFormConfigFieldWrapper {...formConfiguration?.userDeclaredOONException}>
            <DetailsField label={"Out-of-network exception"} value="Yes" />
          </SRFormConfigFieldWrapper>
        </>
      )}
      {simplifiedServiceFrequencyOFF && (
        <SRFormConfigFieldWrapper {...formConfiguration?.startEndDate}>
          <DetailsField
            label={"Expected service start date"}
            value={serviceRequest.startDate && formatDateStr(serviceRequest.startDate)}
          />

          {serviceRequest.recurrenceType === "RECURRING" && (
            <>
              <DetailsField label={"Expected service end date"} value={estimatedEndDate} />
            </>
          )}
        </SRFormConfigFieldWrapper>
      )}
      <SRFormConfigFieldWrapper {...formConfiguration?.encounterType}>
        <DetailsField
          label={"Care setting"}
          value={serviceRequest.encounterType === "INPATIENT" ? "Inpatient" : "Outpatient"}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            .find(
              (action) =>
                action.onAcceptAttribute === "encounterType" &&
                action.onAcceptValue?.value !== serviceRequest.encounterType
            )}
        />
      </SRFormConfigFieldWrapper>
      {simplifiedServiceFrequency && (
        <>
          <SRFormConfigFieldWrapper {...formConfiguration?.placeOfService}>
            <DetailsField
              label={"Place of service"}
              value={serviceRequest.placeOfService?.name || "--"}
              ruleNudge={ruleActions
                ?.filter(isRecommendChangeActionWithAttribute)
                .find((action) => action.onAcceptAttribute === "placeOfService")}
            />
          </SRFormConfigFieldWrapper>
        </>
      )}
      {simplifiedServiceFrequencyOFF && (
        <>
          <SRFormConfigFieldWrapper>
            <DetailsField
              label={"Procedure codes included"}
              value={serviceRequest.procedureCodes ? "Yes" : "No"}
              // From the check box on the first page of the SR creation that says "No procedure codes"
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper>
            <DetailsField label={"Procedure"} value={serviceRequest.clinicalService?.name} />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper>
            <DetailsField label={"Service type"} value={serviceRequest.serviceType} />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper {...formConfiguration?.placeOfService}>
            <DetailsField
              label={"Place of service"}
              value={serviceRequest.placeOfService?.name}
              ruleNudge={ruleActions
                ?.filter(isRecommendChangeActionWithAttribute)
                .find((action) => action.onAcceptAttribute === "placeOfService")}
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper>
            <DetailsField label={"Case type"} value={serviceRequest.clinicalService?.caseType} />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper {...formConfiguration?.admissionDischargeDate}>
            <DetailsField
              label={"Admission date"}
              value={serviceRequest.admissionDate && formatDateStr(serviceRequest.admissionDate)}
            />

            <DetailsField
              label={"Expected discharge date"}
              value={serviceRequest.expectedDischargeDate && formatDateStr(serviceRequest.expectedDischargeDate)}
            />

            <DetailsField
              label={"Inpatient days"}
              value={
                //subtracts the end date from the start date
                serviceRequest.expectedDischargeDate &&
                serviceRequest.admissionDate &&
                differenceInDays(
                  parseDateFromISOString(serviceRequest.expectedDischargeDate),
                  parseDateFromISOString(serviceRequest.admissionDate)
                ).toString()
              }
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper {...formConfiguration?.behavioralHealthAdmissionType}>
            <DetailsField label={"BH admission type"} value={serviceRequest.behavioralHealthAdmissionType} />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper {...formConfiguration?.behavioralHealthReviewType}>
            <DetailsField label={"BH review type"} value={serviceRequest.behavioralHealthReviewType} />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper {...formConfiguration?.recurring}>
            <DetailsField
              label={"Service frequency"}
              value={
                serviceRequest.recurrenceType && serviceRequest.recurrenceType === "RECURRING"
                  ? "Recurring"
                  : "One-time"
              }
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper {...formConfiguration?.units}>
            <DetailsField
              label={"Unit quantity"}
              value={serviceRequest.units ? serviceRequest.units.toString() : "1"}
            />

            {serviceRequest.recurrenceType === "RECURRING" && (
              <>
                <DetailsField
                  label={"Unit type"}
                  value={serviceRequest.unitType ? unitTypeLabel(serviceRequest.unitType) : undefined}
                />
              </>
            )}
          </SRFormConfigFieldWrapper>
        </>
      )}
      <SRFormConfigFieldWrapper {...formConfiguration?.orderingProvider}>
        <DetailsField
          label={"Ordering provider"}
          subTextChildren={
            <>
              {oonExceptionOrderingProviderReason && (
                <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                  Exception reason: {oonExceptionOrderingProviderReason}
                </Caption>
              )}
              {oonExceptionOrderingProviderComment && (
                <Caption className={classes.labelContainer} data-public>
                  Comment: {oonExceptionOrderingProviderComment}
                </Caption>
              )}
            </>
          }
          bodyChildren={
            <>
              {serviceRequest?.orderingProvider ? (
                <>
                  <Body1 className={classes.bodyChildrenContainer} data-public>
                    <span>{serviceRequest.orderingProvider?.name?.concat(" / ")}</span>
                    <span>{`NPI - ${serviceRequest.orderingProvider?.npi || "N/A"}`}</span>
                  </Body1>
                </>
              ) : (
                <Body1>--</Body1>
              )}
            </>
          }
        >
          {serviceRequest.orderingProvider && (
            <>
              <ContactButton onClick={orderingProviderOnClickHandler}>
                {contactInfoButtonText(serviceRequest.orderingProvider?.phoneNumbers, undefined)}
              </ContactButton>
              <LocationNetworkStatusPill
                location={serviceRequest.orderingProviderLocation ?? undefined}
                config={{
                  outOfNetworkStatusDisplayOff: orderingProviderOutOfNetworkStatusDisplayOff,
                  locationIsOutOfNetwork: orderingProviderIsOutOfNetwork,
                  outOfNetworkStatusOverrideEnabled,
                }}
              />
            </>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.performingProvider}>
        <DetailsField
          label={"Performing or attending provider"}
          subTextChildren={
            <>
              {oonExceptionPerformingProviderReason && (
                <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                  Exception reason: {oonExceptionPerformingProviderReason}
                </Caption>
              )}
              {oonExceptionPerformingProviderComment && (
                <Caption className={classes.labelContainer} data-public>
                  Comment: {oonExceptionPerformingProviderComment}
                </Caption>
              )}
            </>
          }
          bodyChildren={
            <>
              {serviceRequest.performingProvider ? (
                <>
                  <Body1 className={classes.bodyChildrenContainer} data-public>
                    <span>{serviceRequest.performingProvider?.name?.concat(" / ")}</span>
                    <span>{`NPI - ${serviceRequest.performingProvider?.npi || "N/A"}`}</span>
                  </Body1>
                </>
              ) : (
                <Body1>--</Body1>
              )}
            </>
          }
        >
          {serviceRequest.performingProvider && (
            <>
              <ContactButton onClick={performingProviderOnClickHandler}>
                {contactInfoButtonText(serviceRequest.performingProvider?.phoneNumbers, undefined)}
              </ContactButton>
              <LocationNetworkStatusPill
                location={serviceRequest?.performingProviderLocation ?? undefined}
                config={{
                  outOfNetworkStatusDisplayOff: performingProviderOutOfNetworkStatusDisplayOff,
                  locationIsOutOfNetwork: providerIsOutOfNetwork,
                  outOfNetworkStatusOverrideValue:
                    serviceRequest?.networkStatusOverride?.isOutOfNetworkPerformingProvider,
                  outOfNetworkStatusOverrideExists: Boolean(serviceRequest?.networkStatusOverride),
                  outOfNetworkStatusOverrideEnabled,
                }}
              />
            </>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>
      {serviceRequest.selectedPerformingProviderPractice && showPerformingProviderPracticeFieldFF && (
        <SRFormConfigFieldWrapper {...formConfiguration?.performingProviderPractice}>
          <DetailsField
            label={"Performing provider practice"}
            ruleNudge={ruleActions
              ?.filter(isRecommendChangeActionWithAttribute)
              .find((action) => action.onAcceptAttribute === "facility")}
            subTextChildren={
              <>
                {oonExceptionPerformingProviderPracticeReason && (
                  <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                    Exception reason: {oonExceptionPerformingProviderPracticeReason}
                  </Caption>
                )}
                {oonExceptionPerformingProviderPracticeComment && (
                  <Caption className={classes.labelContainer} data-public>
                    Comment: {oonExceptionPerformingProviderPracticeComment}
                  </Caption>
                )}
              </>
            }
            bodyChildren={
              <>
                {serviceRequest.selectedPerformingProviderPractice ? (
                  <>
                    <Body1 className={classes.bodyChildrenContainer} data-public>
                      <span>{serviceRequest.selectedPerformingProviderPractice?.name?.concat(" / ")}</span>
                      <span>{`NPI - ${serviceRequest.selectedPerformingProviderPractice?.npi || "N/A"}`}</span>
                    </Body1>
                  </>
                ) : (
                  <Body1>--</Body1>
                )}
              </>
            }
          >
            {serviceRequest.selectedPerformingProviderPractice && (
              <>
                <ContactButton onClick={performingProvidePracticeOnClickHandler}>
                  {contactInfoButtonText(serviceRequest.selectedPerformingProviderPractice?.phoneNumbers, undefined)}
                </ContactButton>
                <LocationNetworkStatusPill
                  location={serviceRequest.selectedPerformingProviderPractice?.selectedLocation ?? undefined}
                  config={{
                    outOfNetworkStatusDisplayOff: performingProviderPracticeOutOfNetworkStatusDisplayOff,
                    locationIsOutOfNetwork: performingProviderPracticeIsOutOfNetwork,
                    outOfNetworkStatusOverrideExists: Boolean(serviceRequest?.networkStatusOverride),
                    outOfNetworkStatusOverrideEnabled,
                  }}
                />
              </>
            )}
          </DetailsField>
        </SRFormConfigFieldWrapper>
      )}
      <SRFormConfigFieldWrapper {...formConfiguration?.facility}>
        <DetailsField
          label={"Performing facility or agency"}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            .find((action) => action.onAcceptAttribute === "facility")}
          subTextChildren={
            <>
              {oonExceptionFacilityReason && (
                <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                  Exception reason: {oonExceptionFacilityReason}
                </Caption>
              )}
              {oonExceptionFacilityComment && (
                <Caption className={classes.labelContainer} data-public>
                  Comment: {oonExceptionFacilityComment}
                </Caption>
              )}
            </>
          }
          bodyChildren={
            <>
              {serviceRequest.facility ? (
                <>
                  <Body1 className={classes.bodyChildrenContainer} data-public>
                    <span>{serviceRequest.facility?.name?.concat(" / ")}</span>
                    <span>{`NPI - ${serviceRequest.facility?.npi || "N/A"}`}</span>
                  </Body1>
                </>
              ) : (
                "--"
              )}
            </>
          }
        >
          {serviceRequest.facility && (
            <>
              <ContactButton onClick={facilityOnClickHandler}>
                {contactInfoButtonText(serviceRequest.facility?.phoneNumbers, undefined)}
              </ContactButton>
              <LocationNetworkStatusPill
                location={serviceRequest.facilityLocation ?? undefined}
                config={{
                  outOfNetworkStatusDisplayOff: facilityOutOfNetworkStatusDisplayOff,
                  locationIsOutOfNetwork: facilityIsOutOfNetwork,
                  outOfNetworkStatusOverrideValue: serviceRequest?.networkStatusOverride?.isOutOfNetworkFacility,
                  outOfNetworkStatusOverrideExists: Boolean(serviceRequest?.networkStatusOverride),
                  outOfNetworkStatusOverrideEnabled,
                }}
              />
            </>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>
      {!hideFacilityState && (
        <SRFormConfigFieldWrapper {...formConfiguration?.startEndDate}>
          <DetailsField
            label={"Facility state"}
            value={
              POLICY_UNITED_STATES_OPTIONS?.find(
                (opt) => opt.id === serviceRequest?.selectedFacility?.selectedLocation?.address?.state
              )?.label
            }
          />
        </SRFormConfigFieldWrapper>
      )}
      <SRFormConfigFieldWrapper {...formConfiguration?.userSelectedOONException}>
        <DetailsField
          label="Out-of-network waiver requested"
          value={Boolean(serviceRequest.userSelectedOONException) ? "Yes" : "No"}
        />
      </SRFormConfigFieldWrapper>
      {serviceRequest.encounterType === "OUTPATIENT" && (
        <DetailsField
          label={"Dates of service"}
          value={getDatesOfServiceString(serviceRequest, authorization, isFaxEdit)}
        />
      )}
      {serviceRequest.encounterType === "INPATIENT" && (
        <DetailsField
          label={serviceRequest?.expectedDischargeDate ? "Dates of service" : "Expected admission date"}
          value={
            serviceRequest?.expectedDischargeDate
              ? formatDateStr(serviceRequest.admissionDate ?? serviceRequest.startDate).concat(
                  serviceRequest.expectedDischargeDate
                    ? ` - ${formatDateStr(serviceRequest.expectedDischargeDate)}`
                    : ` - ${estimatedEndDate}`
                )
              : formatDateStr(serviceRequest?.expectedAdmissionDate ?? serviceRequest.startDate)
          }
        />
      )}
      {serviceRequest &&
        shouldIncludeAdditionalCareParticipants &&
        serviceRequest?.additionalCareParticipants?.map((careParticipant) => {
          return (
            <SRFormConfigFieldWrapper {...formConfiguration.additionalCareParticipants} key={careParticipant.id}>
              <DetailsField
                label={getAdditionalCareParticipantName(careParticipant.careParticipantType)}
                value={
                  careParticipant
                    ? `${careParticipant?.name ? careParticipant.name : `None`} / ${
                        `NPI - ` + (careParticipant?.npi || "N/A")
                      }`
                    : "--"
                }
                subTextChildren={
                  <>
                    {careParticipant.selectedLocation?.outOfNetworkExceptionReason && (
                      <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                        Exception reason: {careParticipant.selectedLocation?.outOfNetworkExceptionReason}
                      </Caption>
                    )}
                    {careParticipant.selectedLocation?.outOfNetworkExceptionComment && (
                      <Caption className={classes.labelContainer} data-public>
                        Comment: {careParticipant.selectedLocation?.outOfNetworkExceptionComment}
                      </Caption>
                    )}
                  </>
                }
              >
                {careParticipant && (
                  <CareParticipantModal
                    careParticipant={careParticipant}
                    setModalState={setModalState}
                    closeModal={closeModal}
                    contactInfoButtonText={contactInfoButtonText}
                    outOfNetworkStatusOverrideEnabled={outOfNetworkStatusOverrideEnabled}
                  />
                )}
              </DetailsField>
            </SRFormConfigFieldWrapper>
          );
        })}
      <SRFormConfigFieldWrapper {...formConfiguration?.faxInputField}>
        <DetailsField label={"Follow-up fax number"} value={formatPhone(serviceRequest.followUpFaxNumber)} />
      </SRFormConfigFieldWrapper>
      {serviceRequest.requestType === "INITIAL" && (
        <SRFormConfigFieldWrapper {...formConfiguration?.urgency}>
          <DetailsField label={"Expedited"} value={serviceRequest.urgency?.isExpedited ? "Yes" : "No"} />
        </SRFormConfigFieldWrapper>
      )}
      {otherInsuranceEnabled && (
        <DetailsField
          label={"Additional insurance"}
          value={serviceRequest.additionalInsurance?.additionalInsuranceText || "--"}
        />
      )}
      <ContactInfoModal
        open={modalState.open}
        handleClose={modalState.handleClose}
        type={modalState.type}
        name={modalState.name}
        phones={modalState.phones}
        fax={modalState.fax}
        email={modalState.email}
        npi={modalState.npi}
        tinList={modalState.tinList}
        addresses={modalState.addresses}
        npiLabel={modalState.npiLabel}
      />
    </>
  );
};

interface FacilityBasedRequestDetailsProps {
  serviceRequest: ServiceRequestResponse;
  authorization: AuthorizationResponse | undefined;
  formConfiguration: FormConfiguration;
  ruleActions: RuleActions | undefined;
  setModalState: Dispatch<React.SetStateAction<ContactInfoModalProps>>;
  isFacilityBasedAuth: boolean;
  oonExceptionPerformingProviderReason: string | undefined;
  oonExceptionPerformingProviderComment: string | undefined;
  oonExceptionFacilityReason: string | undefined;
  oonExceptionFacilityComment: string | undefined;
  oonExceptionOrderingProviderReason: string | undefined;
  oonExceptionOrderingProviderComment: string | undefined;
  oonExceptionPerformingProviderPracticeReason: string | undefined;
  oonExceptionPerformingProviderPracticeComment: string | undefined;
  providerIsOutOfNetwork: boolean | undefined;
  facilityIsOutOfNetwork: boolean | undefined;
  orderingProviderIsOutOfNetwork: boolean | undefined;
  closeModal: () => void;
  latestSr?: ServiceRequestResponse;
  isFaxEdit: boolean | undefined;
  outOfNetworkStatusDisplayOff: boolean | undefined;
  usePatientDatesAuthModal?: boolean;
  oonReviewConfig: OutOfNetworkReviewConfigurationResponse | undefined;
  facilityBasedConfig: FacilityBasedRequestConfigurationResponse | undefined;
  otherInsuranceEnabled?: boolean;
}

const FacilityBasedRequestDetails = ({
  serviceRequest,
  authorization,
  formConfiguration,
  ruleActions,
  setModalState,
  isFacilityBasedAuth,
  oonExceptionPerformingProviderReason,
  oonExceptionPerformingProviderComment,
  oonExceptionFacilityReason,
  oonExceptionFacilityComment,
  oonExceptionOrderingProviderReason,
  oonExceptionOrderingProviderComment,
  oonExceptionPerformingProviderPracticeComment,
  oonExceptionPerformingProviderPracticeReason,
  providerIsOutOfNetwork,
  facilityIsOutOfNetwork,
  orderingProviderIsOutOfNetwork,
  closeModal,
  latestSr,
  isFaxEdit,
  outOfNetworkStatusDisplayOff,
  usePatientDatesAuthModal,
  oonReviewConfig,
  facilityBasedConfig,
  otherInsuranceEnabled,
}: FacilityBasedRequestDetailsProps) => {
  const classes = useStyle();
  const showPerformingProviderPracticeFieldFF = useFeature("showPerformingProviderPracticeField");
  const showPatientStayTimeline = usePatientStayTimelineEnabled({ authorization, serviceRequest });
  const showDischargeDateAndTime = latestSr?.dischargeDate
    ? isFacilityBasedAuth && Boolean(latestSr.dischargeDate)
    : isFacilityBasedAuth && serviceRequest.patientStatus === "DISCHARGED";
  const outOfNetworkStatusOverrideEnabled = oonReviewConfig?.enabled && oonReviewConfig?.networkStatusOverride;

  const [patientStayModalOpen, setPatientStayModalOpen] = useState<boolean>(false);

  const latestPatientStatus = requestTimingToString(
    serviceRequest?.authStatus === "DRAFT" ? latestSr?.patientStatus : authorization?.patientStatus
  );

  const lengthOfDecisionedStays = calculateDecisionedLengthOfStay(authorization?.serviceRequestsOnAuth, serviceRequest);
  const lengthOfRequestedStays = calculateRequestedLengthOfStay(authorization?.serviceRequestsOnAuth, serviceRequest);

  const decisionedStaysText =
    lengthOfDecisionedStays > 0
      ? `${lengthOfRequestedStays > 0 ? "," : ""} ${lengthOfDecisionedStays} day${
          lengthOfDecisionedStays > 1 ? "s" : ""
        } previously decisioned`
      : "";
  const requestedStaysText = lengthOfRequestedStays
    ? `${lengthOfRequestedStays} day${lengthOfRequestedStays > 1 ? "s" : ""} requested`
    : ``;

  let showNextReviewDateOnPatientSummary = false;
  if (
    (serviceRequest?.patientStatus === "DISCHARGED" &&
      getPendingAuthStatuses().includes(serviceRequest?.authStatus || "")) ||
    (serviceRequest?.patientStatus === "CURRENTLY_ADMITTED" && serviceRequest?.authStatus !== "DRAFT")
  ) {
    showNextReviewDateOnPatientSummary = true;
  }

  const dischargedDateTime = latestSr?.dischargeDate
    ? getDischargedDateTime(latestSr, authorization, isFaxEdit)
    : getDischargedDateTime(serviceRequest, authorization, isFaxEdit);
  const dischargedTo = isFaxEdit
    ? serviceRequest.dischargedTo?.dischargedTo ?? ""
    : authorization?.dischargedTo?.dischargedTo ?? "";
  const showDischargeInfo = serviceRequest.patientStatus === "DISCHARGED" || latestSr?.dischargeDate;

  const orderingProviderOnClickHandler = useCallback(() => {
    modalOpener(
      serviceRequest?.orderingProvider,
      serviceRequest?.orderingProviderLocation,
      setModalState,
      closeModal,
      "ORDERING_PROVIDER"
    );
  }, [serviceRequest, setModalState, closeModal]);

  const performingProviderOnClickHandler = useCallback(() => {
    modalOpener(
      serviceRequest?.performingProvider,
      serviceRequest?.performingProviderLocation,
      setModalState,
      closeModal,
      "PERFORMING_PROVIDER"
    );
  }, [serviceRequest, setModalState, closeModal]);

  const facilityOnClickHandler = useCallback(() => {
    modalOpener(serviceRequest.facility, serviceRequest.facilityLocation, setModalState, closeModal, "FACILITY");
  }, [serviceRequest, setModalState, closeModal]);

  const performingProviderPracticeOnClickHandler = useCallback(() => {
    setModalState({
      open: true,
      name: serviceRequest.selectedPerformingProviderPractice?.name || "None",
      phones:
        serviceRequest?.selectedPerformingProviderPractice?.selectedLocation?.phoneNumbers &&
        serviceRequest?.selectedPerformingProviderPractice?.selectedLocation?.phoneNumbers.length > 0
          ? [serviceRequest.selectedPerformingProviderPractice.selectedLocation?.phoneNumbers[0]]
          : undefined,

      fax: serviceRequest.selectedPerformingProviderPractice?.selectedLocation?.faxNumbers?.map(strToPhoneNumber),
      type: "PERFORMING_PROVIDER_PRACTICE",
      tinList: serviceRequest.selectedPerformingProviderPractice?.selectedLocation?.tin
        ? [serviceRequest.selectedPerformingProviderPractice.selectedLocation.tin]
        : undefined,
      npi: serviceRequest.selectedPerformingProviderPractice?.npi,
      addresses: [serviceRequest.selectedPerformingProviderPractice?.selectedLocation?.address],
      npiLabel: serviceRequest.selectedPerformingProviderPractice?.npi || "N/A",
      handleClose: closeModal,
    });
  }, [closeModal, serviceRequest, setModalState]);

  return (
    <>
      <SRFormConfigFieldWrapper {...formConfiguration?.primaryDiagnosis}>
        <DetailsField
          label="Primary diagnosis"
          value={`${serviceRequest.primaryDiagnosis?.code}
            ${serviceRequest.primaryDiagnosis?.description ? " - " + serviceRequest.primaryDiagnosis?.description : ""}
          `}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            ?.find((action) => action.onAcceptAttribute === "primaryDiagnosis")}
        />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.secondaryDiagnoses}>
        <DetailsField
          label="Secondary diagnosis"
          value={
            serviceRequest.secondaryDiagnoses ? serviceRequest.secondaryDiagnoses.map((d) => d.code).join(", ") : "--"
          }
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            ?.find((action) => action.onAcceptAttribute === "secondaryDiagnosis")}
        />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.encounterType}>
        <DetailsField
          label="Care setting"
          value={serviceRequest.encounterType === "INPATIENT" ? "Inpatient" : "Outpatient"}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            ?.find((action) => action.onAcceptAttribute === "encounterType")}
        />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper forceShow={isFacilityBasedAuth}>
        <DetailsField
          label="Auth category"
          value={serviceRequest.authSubcategory || serviceRequest.authCategory?.name || ""}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            ?.find((action) => action.onAcceptAttribute === "authCategory")}
        />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.placeOfService}>
        <DetailsField
          label="Place of service"
          value={serviceRequest.placeOfService?.name}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            ?.find((action) => action.onAcceptAttribute === "placeOfService")}
        />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.orderingProvider}>
        <DetailsField
          label="Ordering provider"
          subTextChildren={
            <>
              {oonExceptionOrderingProviderReason && (
                <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                  Exception reason: {oonExceptionOrderingProviderReason}
                </Caption>
              )}
              {oonExceptionOrderingProviderComment && (
                <Caption className={classes.labelContainer} data-public>
                  Comment: {oonExceptionOrderingProviderComment}
                </Caption>
              )}
            </>
          }
          bodyChildren={
            <>
              {serviceRequest?.orderingProvider ? (
                <>
                  <div className={classes.bodyChildrenContainer}>
                    <Body1 data-public>{serviceRequest.orderingProvider?.name?.concat(" / ")}</Body1>
                  </div>
                  <div>
                    <Body1 data-public>{`NPI - ${serviceRequest.orderingProvider?.npi || "N/A"}`}</Body1>
                  </div>
                </>
              ) : undefined}
            </>
          }
        >
          {serviceRequest.orderingProvider && (
            <>
              <ContactButton onClick={orderingProviderOnClickHandler}>
                {contactInfoButtonText(serviceRequest.orderingProvider?.phoneNumbers, undefined)}
              </ContactButton>
              <LocationNetworkStatusPill
                location={serviceRequest?.orderingProviderLocation ?? undefined}
                config={{
                  outOfNetworkStatusDisplayOff,
                  locationIsOutOfNetwork: orderingProviderIsOutOfNetwork,
                  outOfNetworkStatusOverrideEnabled,
                }}
              />
            </>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.performingProvider} forceShow={isFacilityBasedAuth}>
        <DetailsField
          label="Performing or attending provider"
          subTextChildren={
            <>
              {oonExceptionPerformingProviderReason && (
                <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                  Exception reason: {oonExceptionPerformingProviderReason}
                </Caption>
              )}
              {oonExceptionPerformingProviderComment && (
                <Caption className={classes.labelContainer} data-public>
                  Comment: {oonExceptionPerformingProviderComment}
                </Caption>
              )}
            </>
          }
          bodyChildren={
            <>
              {serviceRequest.performingProvider ? (
                <>
                  <Body1 className={classes.bodyChildrenContainer} data-public>
                    <span>{serviceRequest.performingProvider?.name?.concat(" / ")}</span>
                    <span>{`NPI - ${serviceRequest.performingProvider?.npi || "N/A"}`}</span>
                  </Body1>
                </>
              ) : (
                <Body1>--</Body1>
              )}
            </>
          }
        >
          {serviceRequest.performingProvider && (
            <>
              <ContactButton onClick={performingProviderOnClickHandler}>
                {contactInfoButtonText(serviceRequest.performingProvider?.phoneNumbers, undefined)}
              </ContactButton>
              <LocationNetworkStatusPill
                location={serviceRequest?.performingProvider}
                config={{
                  outOfNetworkStatusDisplayOff,
                  locationIsOutOfNetwork: providerIsOutOfNetwork,
                  outOfNetworkStatusOverrideValue:
                    serviceRequest?.networkStatusOverride?.isOutOfNetworkPerformingProvider,
                  outOfNetworkStatusOverrideExists: Boolean(serviceRequest?.networkStatusOverride),
                  outOfNetworkStatusOverrideEnabled,
                }}
              />
            </>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>
      {serviceRequest.selectedPerformingProviderPractice && showPerformingProviderPracticeFieldFF && (
        <SRFormConfigFieldWrapper {...formConfiguration?.performingProviderPractice}>
          <DetailsField
            label="Performing provider practice"
            ruleNudge={ruleActions
              ?.filter(isRecommendChangeActionWithAttribute)
              ?.find((action) => action.onAcceptAttribute === "facility")}
            subTextChildren={
              <>
                {oonExceptionPerformingProviderPracticeReason && (
                  <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                    Exception reason: {oonExceptionPerformingProviderPracticeReason}
                  </Caption>
                )}
                {oonExceptionPerformingProviderPracticeComment && (
                  <Caption className={classes.labelContainer} data-public>
                    Comment: {oonExceptionPerformingProviderPracticeComment}
                  </Caption>
                )}
              </>
            }
            bodyChildren={
              <>
                {serviceRequest.selectedPerformingProviderPractice ? (
                  <>
                    <Body1 className={classes.bodyChildrenContainer} data-public>
                      <span>{serviceRequest.selectedPerformingProviderPractice?.name?.concat(" / ")}</span>
                      <span>{`NPI - ${serviceRequest.selectedPerformingProviderPractice?.npi || "N/A"}`}</span>
                    </Body1>
                  </>
                ) : undefined}
              </>
            }
          >
            {serviceRequest.selectedPerformingProviderPractice && (
              <>
                <ContactButton onClick={performingProviderPracticeOnClickHandler}>
                  {contactInfoButtonText(serviceRequest.selectedPerformingProviderPractice?.phoneNumbers, undefined)}
                </ContactButton>
                <LocationNetworkStatusPill
                  location={serviceRequest?.selectedPerformingProviderPractice?.selectedLocation ?? undefined}
                  config={{
                    outOfNetworkStatusDisplayOff,
                    locationIsOutOfNetwork: facilityIsOutOfNetwork,
                    outOfNetworkStatusOverrideValue: serviceRequest?.networkStatusOverride?.isOutOfNetworkFacility,
                    outOfNetworkStatusOverrideExists: Boolean(serviceRequest?.networkStatusOverride),
                    outOfNetworkStatusOverrideEnabled,
                  }}
                />
              </>
            )}
          </DetailsField>
        </SRFormConfigFieldWrapper>
      )}
      <SRFormConfigFieldWrapper {...formConfiguration?.facility}>
        <DetailsField
          label="Performing facility or agency"
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            ?.find((action) => action.onAcceptAttribute === "facility")}
          subTextChildren={
            <>
              {oonExceptionFacilityReason && (
                <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                  Exception reason: {oonExceptionFacilityReason}
                </Caption>
              )}
              {oonExceptionFacilityComment && (
                <Caption className={classes.labelContainer} data-public>
                  Comment: {oonExceptionFacilityComment}
                </Caption>
              )}
            </>
          }
          bodyChildren={
            <>
              {serviceRequest.facility ? (
                <>
                  <Body1 className={classes.bodyChildrenContainer} data-public>
                    <span>{serviceRequest.facility?.name?.concat(" / ")}</span>
                    <span>{`NPI - ${serviceRequest.facility?.npi || "N/A"}`}</span>
                  </Body1>
                </>
              ) : undefined}
            </>
          }
        >
          {serviceRequest.facility && (
            <>
              <ContactButton onClick={facilityOnClickHandler}>
                {contactInfoButtonText(serviceRequest.facility?.phoneNumbers, undefined)}
              </ContactButton>
              <LocationNetworkStatusPill
                location={serviceRequest?.facilityLocation ?? undefined}
                config={{
                  outOfNetworkStatusDisplayOff,
                  locationIsOutOfNetwork: facilityIsOutOfNetwork,
                  outOfNetworkStatusOverrideValue: serviceRequest?.networkStatusOverride?.isOutOfNetworkFacility,
                  outOfNetworkStatusOverrideExists: Boolean(serviceRequest?.networkStatusOverride),
                  outOfNetworkStatusOverrideEnabled,
                }}
              />
            </>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.startEndDate}>
        <DetailsField
          label={"Facility state"}
          value={POLICY_UNITED_STATES_OPTIONS?.find((opt) => opt.id === serviceRequest?.associatedStateOfUnion)?.label}
        />
      </SRFormConfigFieldWrapper>
      {serviceRequest.requestType === "INITIAL" && (
        <SRFormConfigFieldWrapper {...formConfiguration?.urgency}>
          <DetailsField label={"Expedited"} value={serviceRequest.urgency?.isExpedited ? "Yes" : "No"} />
        </SRFormConfigFieldWrapper>
      )}
      {!showPatientStayTimeline && (
        <>
          <H6 className={classes.facilityBasedHeader} data-public>
            Patient stay
          </H6>
          <SRFormConfigFieldWrapper forceShow={isFacilityBasedAuth}>
            <DetailsField label="Patient status" value={latestPatientStatus} />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper
            forceShow={isFacilityBasedAuth && serviceRequest.patientStatus === "NOT_YET_ADMITTED"}
          >
            <DetailsField
              label="Expected admission date"
              value={serviceRequest?.expectedAdmissionDate ? formatDateStr(serviceRequest?.expectedAdmissionDate) : ""}
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper
            forceShow={isFacilityBasedAuth && serviceRequest.patientStatus !== "NOT_YET_ADMITTED"}
          >
            <DetailsField
              label="Admission source (optional)"
              value={serviceRequest.admissionSource?.name || "--"}
              ruleNudge={ruleActions
                ?.filter(isRecommendChangeActionWithAttribute)
                ?.find((action) => action.onAcceptAttribute === "admissionSource")}
            />
            <DetailsField
              label="Admission date and time"
              value={
                serviceRequest.admissionDate && serviceRequest.admissionTime
                  ? format(
                      new Date(`${serviceRequest.admissionDate} ${serviceRequest.admissionTime}`),
                      "MM/dd/yyyy HH:mm"
                    )
                  : ""
              }
              ruleNudge={ruleActions?.filter(isRecommendChangeActionWithAttribute)?.find((action) => {
                return action.onAcceptAttribute === "admissionDate" || action.onAcceptAttribute === "admissionTime";
              })}
            />
          </SRFormConfigFieldWrapper>
          <SRFormConfigFieldWrapper
            forceShow={
              isFacilityBasedAuth &&
              (serviceRequest.patientStatus !== "NOT_YET_ADMITTED" ||
                facilityBasedConfig?.includePatientStayDatesOnPlannedAdmission)
            }
          >
            <DetailsField label="Length of stay" value={`${requestedStaysText}${decisionedStaysText}`}>
              {lengthOfDecisionedStays + lengthOfRequestedStays > 0 && (
                <ContactButton onClick={() => setPatientStayModalOpen(true)}>View details</ContactButton>
              )}
            </DetailsField>
          </SRFormConfigFieldWrapper>
          <SRFormConfigFieldWrapper
            forceShow={isFacilityBasedAuth && !!authorization?.nextReviewDate && showNextReviewDateOnPatientSummary}
          >
            <DetailsField label="Next review date" value={formatDateStr(authorization?.nextReviewDate)} />
          </SRFormConfigFieldWrapper>
          {showDischargeInfo && <H6 className={classes.facilityBasedHeader}>Discharge Information</H6>}
          <SRFormConfigFieldWrapper forceShow={showDischargeDateAndTime}>
            <DetailsField label="Discharge date and time" value={dischargedDateTime} />
            <DetailsField label="Discharged to" value={dischargedTo} />
          </SRFormConfigFieldWrapper>
          {otherInsuranceEnabled && (
            <DetailsField
              label={"Additional insurance"}
              value={serviceRequest.additionalInsurance?.additionalInsuranceText || "--"}
            />
          )}
          {usePatientDatesAuthModal && (
            <PatientStayModalAuth
              authorization={authorization}
              open={patientStayModalOpen}
              setOpen={setPatientStayModalOpen}
            />
          )}
          {!usePatientDatesAuthModal && (
            <PatientStayModal
              authorization={authorization}
              currentServiceRequest={serviceRequest}
              open={patientStayModalOpen}
              setOpen={setPatientStayModalOpen}
            />
          )}
        </>
      )}
    </>
  );
};

const getDischargedDateTime = (
  serviceRequest: ServiceRequestResponse,
  authorization?: AuthorizationResponse,
  isFaxEdit?: boolean
) => {
  if (isFaxEdit && serviceRequest.dischargeDate && serviceRequest.dischargeTime) {
    return format(new Date(`${serviceRequest.dischargeDate} ${serviceRequest.dischargeTime}`), "MM/dd/yyyy HH:mm");
  } else if (authorization?.actualDischargeDateTime) {
    return format(new Date(authorization.actualDischargeDateTime), "MM/dd/yyyy HH:mm");
  }
  return "";
};

const DetailsField = ({
  label,
  value,
  xs = 12,
  ruleNudge,
  children,
  subTextChildren,
  bodyChildren,
}: PropsWithChildren<{
  label: string;
  value?: string;
  ruleNudge?: RecommendChangeRuleAction;
  xs?: GridSize;
  subTextChildren?: JSX.Element;
  bodyChildren?: JSX.Element;
}>) => {
  const showContentBeingNudged = useFeature("showContentBeingNudged");
  const isNudgePresent = ruleNudge !== undefined;
  const classes = useStyles({ isNudgePresent, showContentBeingNudged });

  return (
    /*will change state to be based on if nudge has been accepted or not part of COH-2027*/
    <Grid
      item
      xs={xs}
      className={`${classes.grid}${isNudgePresent && showContentBeingNudged ? " nudgeLayout" : " nonNudgeLayout"}`}
      style={{ paddingLeft: 0 }}
    >
      <Grid item xs={12} className={classes.initialContainer}>
        <Grid item xs={4} className={classes.captionContainer}>
          <Caption className={classes.labelContainer} data-public>
            {label}
          </Caption>
        </Grid>
        <Grid item xs={8} className={classes.container}>
          <div className={classes.subContainer}>
            {value && <Body1 data-public>{value || "--"}</Body1>}
            {bodyChildren}
            {showContentBeingNudged && ruleNudge && (
              <ContentNudge ruleNudge={ruleNudge} state={"pending"} type={"main"} />
            )}
            {children}
          </div>
          <div className={classes.exceptionContainer}>{subTextChildren}</div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export function getRequestorPhone(serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse) {
  let requestorPhone: PhoneNumber;
  if (serviceRequest.requestor?.phoneNumber) {
    requestorPhone = serviceRequest.requestor?.phoneNumber;
  } else if (serviceRequest.requestor?.user?.phone) {
    requestorPhone = strToPhoneNumber(serviceRequest.requestor?.user?.phone);
  } else {
    requestorPhone = strToPhoneNumber(serviceRequest.createdByPhoneNumber);
  }
  return requestorPhone;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const ContactButton = styled(TertiaryButton)(({ theme }) => ({
  padding: "0",
  borderRadius: 4,
  "& :hover": {
    backgroundColor: colorsLight.action.hoverBackground,
  },
  "& :focus": {
    backgroundColor: colorsLight.action.hoverBackground,
  },
  justifyContent: "center",
  alignItems: "flex-start",
}));

const contactInfoButtonText = (phones?: PhoneNumber[], faxes?: PhoneNumber[]) => "View info";

const useStyles = makeStyles((theme) => ({
  grid: {
    wordWrap: "break-word",
    "&.nonNudgeLayout": {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
    "&.nudgeLayout": {
      paddingBottom: theme.spacing(2),
    },
    display: "flex",
  },
  valueText: {
    display: "flex",
    flexDirection: "row",
  },
  component: {
    "&.nudgeLayout": {
      minHeight: "32px",
      alignItems: "center",
      display: "flex",
    },
  },
  initialContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: theme.spacing(3),
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    flex: "1 0 230px",
    flexWrap: "wrap",
  },
  subContainer: {
    display: "flex",
    alignItems: "flex-start",
    maxWidth: theme.spacing(93),
    gap: theme.spacing(1.5),
    flexWrap: "wrap",
  },
  exceptionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.75),
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  labelContainer: {
    color: theme.palette.text.secondary,
    display: "inline-block",
  },
  captionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    maxWidth: "200px",
    minWidth: "65px",
  },
}));
