import { Body3 } from "@coherehealth/common";
import { useChatbotStyles } from "./chatbotReviewAssistantStyles";
import { ReviewChatbotFinding } from "@coherehealth/core-platform-api";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useCallback, useContext, useMemo, useRef } from "react";
import { ClinicalReviewContext } from "components/ClinicalReview/reviewUtils/clinicalReviewPageUtils";
import {
  getEligibleReviewChatbotSources,
  IReviewChatbotSource,
} from "components/ClinicalReview/reviewUtils/reviewChatbotUtils";

export interface IChatbotAnswerSourcesProps {
  chatbotFinding: ReviewChatbotFinding;
}

export default function ChatbotAnswerSources({ chatbotFinding }: IChatbotAnswerSourcesProps) {
  const currentTimeout = useRef<NodeJS.Timeout | null>(null);
  const classes = useChatbotStyles({});

  const {
    leftColumnTab,
    setLeftColumnTab,
    commonAttachmentsState: { openAttachment, getAttachmentNameById, jumpToPageRef },
  } = useContext(ClinicalReviewContext);

  const handleSourceClick = useCallback(
    (attachmentId: string, page: number) => {
      if (leftColumnTab !== "ATTACHMENTS") {
        setLeftColumnTab("ATTACHMENTS");
      }
      openAttachment(attachmentId);
      if (currentTimeout.current) {
        clearTimeout(currentTimeout.current);
      }

      if (jumpToPageRef.current) {
        currentTimeout.current = setTimeout(() => {
          jumpToPageRef.current?.(page - 1);
        }, 1000);
      }
    },
    [leftColumnTab, setLeftColumnTab, openAttachment, jumpToPageRef]
  );

  const eligibleSources: IReviewChatbotSource[] = useMemo(() => {
    return getEligibleReviewChatbotSources(chatbotFinding.sources, getAttachmentNameById);
  }, [chatbotFinding.sources, getAttachmentNameById]);

  if (eligibleSources.length === 0) {
    return null;
  }

  return (
    <div className={classes.sourcesWrapper}>
      <span className={classes.sourcesTitle} data-testid="review-chatbot-sources-title">
        SOURCES
      </span>
      {eligibleSources.map((source) => (
        <div
          className={classes.sourceItem}
          onClick={() => handleSourceClick(source.attachmentId, source.page)}
          key={`${source.attachmentId}-${source.attachmentName}-${source.page}`}
        >
          <Body3 className={classes.sourceItemText}>{`${source.attachmentName ?? source.attachmentId}, p${
            source.page
          }`}</Body3>
          <ArrowForwardIcon className={classes.sourceActionIcon} />
        </div>
      ))}
    </div>
  );
}
