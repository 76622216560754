import { useMemo } from "react";
import { Body1 } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";
import { NoticationBanner } from "common/NotificationBanner";
import PurpleClipboard from "components/images/PurpleClipboard";

export interface CareRecommendationBannerProps {
  bannerClass?: string;
}

const CareRecommendationBanner: React.FC<CareRecommendationBannerProps> = (props) => {
  const { bannerClass } = props;
  const classes = useStyles();
  const bodyText = useMemo(
    () => (
      <Body1 className={classes.bodyText}>
        Care recommendation requests are used to coordinate patient care outside the standard authorization process.
      </Body1>
    ),
    [classes.bodyText]
  );

  return (
    <NoticationBanner
      header="This is a care recommendation"
      icon={<PurpleClipboard />}
      contentComponent={bodyText}
      bannerClassName={bannerClass}
      iconClassName={classes.iconWrapper}
    />
  );
};

const useStyles = makeStyles(() => ({
  bodyText: {
    fontFamily: "Gilroy-Medium",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 17 / 15,
    letterSpacing: "0.15px",
  },
  iconWrapper: {
    maxWidth: 60,
    paddingRight: 20,
  },
}));

export default CareRecommendationBanner;
