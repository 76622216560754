import { useFeature } from "@coherehealth/common";
import {
  Attachment,
  MdReview,
  NurseReview,
  OutOfNetworkReview,
  PeerToPeerReview,
  ReviewType,
} from "@coherehealth/core-platform-api";
import { convertHtmlToRichTextEditorState } from "components/ClinicalReview/reviewUtils/automatedReviewNoteRichTextEditorUtil";
import { renderAutomatedReviewNote } from "components/ClinicalReview/reviewUtils/utils";
import { useState } from "react";

interface AutomatedReviewNoteEditorStateProps {
  currentReview?: ReviewType;
  attachments?: Attachment[];
}

// Type Guards to determine review subtype
const isMdReview = (review: ReviewType): review is MdReview | PeerToPeerReview =>
  ["MdReview", "PeerToPeerReview"].includes(review.reviewType);

const isNurseReview = (review: ReviewType): review is NurseReview => review.reviewType === "NurseReview";

const isOutOfNetworkReview = (review: ReviewType): review is OutOfNetworkReview =>
  review.reviewType === "OutOfNetworkReview";

export const getReviewComment = (currentReview?: ReviewType): string | undefined => {
  if (!currentReview) {
    return undefined;
  }

  if (isMdReview(currentReview) && "determinationNote" in currentReview) {
    return currentReview.determinationNote;
  }

  if (isNurseReview(currentReview) && "decisionReasoning" in currentReview) {
    return currentReview.decisionReasoning;
  }

  if (isOutOfNetworkReview(currentReview) && "oonExceptionReasoning" in currentReview) {
    return currentReview.oonExceptionReasoning;
  }

  return undefined;
};

const getCustomEditorState = (
  automatedReviewNoteEnabled: boolean,
  currentReview?: ReviewType,
  attachments?: Attachment[]
): any | undefined => {
  if (
    automatedReviewNoteEnabled &&
    currentReview &&
    (currentReview.reviewType === "MdReview" || currentReview.reviewType === "NurseReview") &&
    !getReviewComment(currentReview) &&
    currentReview.automatedReviewNote &&
    currentReview.reviewStatus !== "COMPLETE" &&
    currentReview.reviewStatus !== "DISCARDED"
  ) {
    return convertHtmlToRichTextEditorState(
      renderAutomatedReviewNote(currentReview.automatedReviewNote || [], attachments)
    );
  }

  return;
};

/*
 * This initializes the RTE state with the automated review note and keeps the state centralized in once place
 * so you don't lose your progress when switching between tabs.
 */
export const useAutomatedReviewNoteEditorState = ({
  currentReview,
  attachments,
}: AutomatedReviewNoteEditorStateProps) => {
  const automatedReviewNoteEnabled = useFeature("automatedReviewNote");

  const [automatedReviewNoteEditorState, setAutomatedReviewNoteEditorState] = useState<any>(
    getCustomEditorState(automatedReviewNoteEnabled, currentReview, attachments)
  );

  return {
    automatedReviewNoteEditorState,
    setAutomatedReviewNoteEditorState,
  };
};
