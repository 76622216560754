import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import {
  PrimaryButton,
  RichTextEditor,
  stripHTMl,
  InformativeModal,
  Caption,
  colorsLight,
  useFeature,
  RadioGroup,
} from "@coherehealth/common";
import {
  Guideline,
  CopyPasteInfo,
  OutreachAttempt,
  ServiceRequestResponse,
  AuthorizationResponse,
  ProcedureCode,
  MdReview,
  PeerToPeerReview,
  ReviewType,
} from "@coherehealth/core-platform-api";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme, Theme } from "@material-ui/core/styles";
import GuidelinesUsedSection, { UNLISTED_GUIDELINE_ID } from "./GuidelinesUsedSection";
import { useMediaQuery } from "@material-ui/core";
import {
  GenericDoctorReview,
  GenericDoctorUpdate,
  useReviewsDispatch,
} from "components/ClinicalReview/reviewUtils/useReviews";
import { RIGHT_HAND_PANEL_SIZE, usePasteTracker } from "components/ClinicalReview/reviewUtils/utils";
import { useLocation } from "react-router";
import ClipboardMissingInfo from "components/images/ClipboardMissingInfo";
import { PatientStayDateRange } from "common/SharedServiceRequestFormComponents";
import PatientStays from "components/ServiceRequest/PatientStay/PatientStays";
import { PatientStaysErrorState } from "components/ServiceRequest/PatientStay/usePatientStayDateOnCRR";
import DischargedToDetails, { DischargeDetailsType } from "../DischargedToDetails";
import { OutreachFormFields } from "../useOutreachAttempt";
import ReadmissionSelection from "components/ServiceRequest/ReviewSection/Readmission/ReadmissionSelection";
import { PatientStayManger } from "components/ClinicalReview/Review/hooks/usePatientStayCardControl";
import { ReviewControlPanel } from "../ReviewControlPanel/ReviewControlPanel";
import { useMDReviewStyles } from "./mdReviewStyles";
import { patientNotYetAdmittedForInpatient } from "util/serviceRequest";
import { getDateForDischargeBasedOnPreviousRanges } from "../../../../util/authorization";
import { ReviewOutreachForm } from "components/ClinicalReview/reviewUtils/ReviewOutreachForm";
import { ClinicalReviewContext } from "components/ClinicalReview/reviewUtils/clinicalReviewPageUtils";
import GenericDoctorReviewMultiCoverageOutcomes from "./MDReviewModal/genericDoctorReviewMultiCoverageReviewOutcomes";
import { ReviewOutcomeDropdown } from "./MDReviewModal/ModalFields/components/ReviewOutcomeDropdown";
import { DisplayReviewOutcome } from "./MDReviewModal/mdReviewModalTypes";

interface Props<T extends GenericDoctorReview> {
  serviceRequest: ServiceRequestResponse;
  review: T;
  setReview: GenericDoctorUpdate<T>;
  openSubmissionModal: () => void;
  onDiscard?: () => void;
  discarding?: boolean;
  onApproveWithoutP2P?: () => void;
  approveWithoutP2PLoading?: boolean;
  toggleExpanded?: () => void;
  saveDraft: () => void;
  currentlySavingDraft: boolean;
  setSameGuidelines?: Dispatch<SetStateAction<boolean | undefined>>;
  newDenialsWorkflow?: boolean;
  prePopulateDenialsTemplate?: boolean;
  selectedGuidelines?: string[];
  copiedAttachmentText?: CopyPasteInfo;
  setPastedAttachmentTexts?: Dispatch<React.SetStateAction<CopyPasteInfo[] | undefined>>;
  fromQueueManagment?: boolean;
  hideGuidelinesUsed?: boolean;
  outreachAttempt?: OutreachAttempt;
  setOutreachAttempt?: Dispatch<SetStateAction<OutreachAttempt>>;
  patientStayDateRanges?: PatientStayDateRange[];
  setPatientStayDateRanges?: Dispatch<SetStateAction<PatientStayDateRange[]>>;
  patientStaysErrorStates?: PatientStaysErrorState[];
  dischargeDetails?: DischargeDetailsType;
  setDischargeDetails?: Dispatch<SetStateAction<DischargeDetailsType>>;
  isDischarged?: boolean;
  setIsDischarged?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  facilityBasedFeatureEnabled?: boolean;
  outreachFormConfig?: OutreachFormFields;
  outreachFormErrors?: OutreachFormFields;
  setOutreachFormErrors?: React.Dispatch<React.SetStateAction<OutreachFormFields>>;
  validateOutreachForm?: () => boolean;
  hasOutreachAttempt?: boolean;
  setHasOutreachAttempt?: React.Dispatch<React.SetStateAction<boolean>>;
  saveDraftWithRedirect?: () => void;
  isReadmission: boolean;
  setIsReadmission: Dispatch<SetStateAction<boolean>>;
  anchorAuthNumber: string;
  setAnchorAuthNumber: Dispatch<SetStateAction<string>>;
  hasErrorWithAnchorAuth: boolean;
  setHasErrorWithAnchorAuth: Dispatch<SetStateAction<boolean>>;
  isReadmissionFeatureEnabled: boolean;
  setHasReadmissionChanged?: Dispatch<SetStateAction<boolean>>;
  authorization?: AuthorizationResponse;
  isStayDatesValid?: () => boolean;
  isDischargeDetailsValid: boolean;
  patientStayManager?: PatientStayManger;
  approvedUnits: number;
  updateSinglePxCodeUnit: (unitsUpdate: string, updatedPxCode: ProcedureCode) => void;
  updateApprovedUnitsByCS: (clinicalServiceId: string, approvedUnits: number) => void;
  approvedUnitsByClinicalService: Map<string, number>;
  pxCodesByClinicalService: Map<string, ProcedureCode[]>;
  existingReviews?: ReviewType[] | null;
  discardAndExitDisabled: boolean;
  automatedReviewNoteEditorState?: any;
  setAutomatedReviewNoteEditorState?: (state: any) => void;
  saveReviewChatbotFindings?: () => void;
  setReviewOutcomeSubmenuOptionsAvailable?: (state: any) => void;
  displayCaseOutcome?: DisplayReviewOutcome;
  setDisplayCaseOutcome?: Dispatch<SetStateAction<DisplayReviewOutcome>>;
  showDecisionExplanationDropdown?: boolean;
  reviewOutcomeSubmenuOptionsAvailable?: boolean;
}

export default function MDReviewEdit<T extends GenericDoctorReview>({
  serviceRequest,
  review,
  setReview,
  openSubmissionModal,
  onDiscard,
  discarding,
  onApproveWithoutP2P,
  approveWithoutP2PLoading,
  toggleExpanded,
  saveDraft,
  currentlySavingDraft,
  setSameGuidelines,
  newDenialsWorkflow,
  prePopulateDenialsTemplate,
  copiedAttachmentText,
  setPastedAttachmentTexts,
  fromQueueManagment,
  hideGuidelinesUsed,
  outreachAttempt,
  setOutreachAttempt,
  patientStayDateRanges,
  setPatientStayDateRanges,
  patientStaysErrorStates,
  isDischarged,
  setIsDischarged,
  dischargeDetails,
  setDischargeDetails,
  facilityBasedFeatureEnabled,
  outreachFormConfig,
  outreachFormErrors,
  setOutreachFormErrors,
  validateOutreachForm,
  hasOutreachAttempt,
  setHasOutreachAttempt,
  saveDraftWithRedirect,
  isReadmission,
  setIsReadmission,
  anchorAuthNumber,
  setAnchorAuthNumber,
  hasErrorWithAnchorAuth,
  setHasErrorWithAnchorAuth,
  isReadmissionFeatureEnabled,
  setHasReadmissionChanged,
  authorization,
  isStayDatesValid,
  isDischargeDetailsValid,
  patientStayManager,
  existingReviews,
  discardAndExitDisabled,
  automatedReviewNoteEditorState,
  setAutomatedReviewNoteEditorState,
  saveReviewChatbotFindings,
  setReviewOutcomeSubmenuOptionsAvailable,
  displayCaseOutcome,
  setDisplayCaseOutcome,
  showDecisionExplanationDropdown,
  reviewOutcomeSubmenuOptionsAvailable,
}: Props<T>) {
  const theme = useTheme();
  const { spacing } = theme;
  const mdReviewStyles = useMDReviewStyles();
  const isPatientNotYetAdmitted = patientNotYetAdmittedForInpatient(serviceRequest);
  const isPatientAdmitted = !isPatientNotYetAdmitted;
  const matchesRight = useMediaQuery(theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE));
  const setValue = (fieldName: keyof T) => (val: string) => {
    setReview?.({ [fieldName]: val });
  };
  const reviewsDispatch = useReviewsDispatch();
  let canSaveDraft = review?.reviewType === "PeerToPeerReview";
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const connectToQM = search.has("connectToQM");
  const changeToDiscardButtonForP2PReview = useFeature("changeToDiscardButtonForP2PReview");
  if (changeToDiscardButtonForP2PReview && connectToQM) {
    canSaveDraft = false;
  }
  const multiCoverageReviews = useFeature("multiCoverageReviews");
  const { isMultiCoverageReviewRequired } = useContext(ClinicalReviewContext);

  const multiCoverageContent = multiCoverageReviews && isMultiCoverageReviewRequired;

  const onHeadingClick = useCallback(() => {
    if (canSaveDraft && review.reviewStatus === "DRAFT") {
      saveDraft();
    } else {
      toggleExpanded?.();
    }
  }, [canSaveDraft, review?.reviewStatus, saveDraft, toggleExpanded]);

  const isMdReview = review?.reviewType === "MdReview";
  const isPostDenialP2P = review?.reviewType === "PeerToPeerReview" && review?.isPostDenialP2P;
  const [approveModalWithoutP2P, setApproveModalWithoutP2P] = useState<boolean>(false);
  const [attemptedSubmitted, setAttemptedSubmitted] = useState(false);
  const guidelinesFromReview: Guideline[] =
    review?.guidelines?.flatMap((guidelinePolicyMap) =>
      guidelinePolicyMap.policyType && guidelinePolicyMap.guidelines ? guidelinePolicyMap.guidelines : []
    ) || [];
  review?.guidelines?.forEach((guidelinePolicyMap) =>
    guidelinePolicyMap.guidelineMatchMap?.forEach((gMM) => {
      const guideline = guidelinesFromReview.find((guideline) => gMM?.guidelineId === guideline.id);
      if (guideline) {
        guideline.policyTitle = gMM.policyTitle;
      }
    })
  );
  const setUsedGuidelineIds = (updatedGuidelineId: string, updatedIndex: number) => {
    reviewsDispatch?.({
      type: "SET_USED_GUIDELINES",
      reviewId: review.id,
      payload: { updatedGuidelineId, updatedIndex },
    });
  };
  const addUsedGuidelineIds = () => {
    reviewsDispatch?.({
      type: "ADD_USED_GUIDELINES",
      reviewId: review.id,
    });
  };
  const deleteUsedGuidelineId = (deletedIndex: number) => {
    reviewsDispatch?.({
      type: "DELETE_USED_GUIDELINES",
      reviewId: review.id,
      payload: { deletedIndex },
    });
  };

  usePasteTracker(copiedAttachmentText, setPastedAttachmentTexts);
  const [stayDatesValid, setStayDatesValid] = useState<boolean | undefined>(false);
  useEffect(() => {
    if (!stayDatesValid || setPatientStayDateRanges) {
      const validate = isStayDatesValid?.();
      setStayDatesValid(validate);
    }
  }, [isStayDatesValid, setPatientStayDateRanges, stayDatesValid]);

  const hasErrorsWithPatientStayDates = !(stayDatesValid && isDischargeDetailsValid);

  const emptyDeterminationNote = !Boolean(stripHTMl(review?.determinationNote || ""));
  const loadingDiscardOrSave = discarding || currentlySavingDraft;
  const atLeastOneDefinedGuidelineId = () => (review.usedGuidelineIds || [""]).some((guidelineId) => !!guidelineId);

  const onFinishReview = () => {
    setAttemptedSubmitted(true);
    if (newDenialsWorkflow && prePopulateDenialsTemplate) {
      //mdReview.usedGuidelines will have the previous review guideline id's to prepopulate the newly created one
      const previousGuidelineIds = review.usedGuidelines?.map(({ id }) => id);
      const originalPreviousGuidelineIds = review.usedGuidelines?.map(({ originalVersionId }) => originalVersionId);
      const filteredNewGuidelinesIds = review.usedGuidelineIds?.filter(
        (guidelineId: string) => guidelineId !== UNLISTED_GUIDELINE_ID
      );
      if (setSameGuidelines) {
        if (
          (!previousGuidelineIds || previousGuidelineIds.length === 0) &&
          (!filteredNewGuidelinesIds || filteredNewGuidelinesIds.length === 0)
        ) {
          setSameGuidelines(true);
        } else if (previousGuidelineIds?.length !== filteredNewGuidelinesIds?.length) {
          setSameGuidelines(false);
        } else {
          const equalGuidelines = !!filteredNewGuidelinesIds?.every((guidelineId) => {
            if (
              !previousGuidelineIds?.includes(guidelineId) &&
              !originalPreviousGuidelineIds?.includes(
                guidelinesFromReview.find((guideline) => guideline.id === guidelineId)?.originalVersionId
              )
            ) {
              return false;
            }
            return true;
          });
          setSameGuidelines(equalGuidelines);
        }
      }
    }
    const isPatientStayCardValid = validatePatientStayCard ? validatePatientStayCard() : true;
    if (
      (!hasOutreachAttempt || validateOutreachForm?.()) &&
      ((isReadmission && anchorAuthNumber !== "") || isReadmission === false) &&
      hasErrorWithAnchorAuth === false &&
      !hasErrorsWithPatientStayDates &&
      isPatientStayCardValid
    ) {
      openSubmissionModal();
    }
  };

  const disableFinishButton = () => {
    if (loadingDiscardOrSave || (attemptedSubmitted && hasErrorsWithPatientStayDates)) {
      return true;
    }
    if (attemptedSubmitted && validatePatientStayCard && !validatePatientStayCard()) {
      return true;
    }
    const emptyDeterminationNote = !Boolean(stripHTMl(review?.determinationNote || ""));
    const emptyUsedGuidelines = !atLeastOneDefinedGuidelineId();
    if (newDenialsWorkflow && prePopulateDenialsTemplate) {
      if (isMdReview) {
        if (showDecisionExplanationDropdown) {
          if (!displayCaseOutcome) {
            return true;
          }
        }
        if (hideGuidelinesUsed) {
          return emptyDeterminationNote;
        } else {
          return emptyDeterminationNote || emptyUsedGuidelines;
        }
      } else {
        return emptyDeterminationNote;
      }
    }
    return false;
  };

  const isInpatientRequest = serviceRequest.encounterType === "INPATIENT";
  const { renderPatientStayCard, formContent, reviewStayDateEditsFlag, validatePatientStayCard } =
    patientStayManager || {};

  const reviewDeterminationNoteLabel = review?.automatedReviewNote
    ? "Review note • Pre-filled using text from attachments and previous reviews"
    : showDecisionExplanationDropdown
    ? "Additional details"
    : "Reviewer notes";

  return (
    <div data-testid="md-review-edit-container">
      <>
        <Grid container spacing={2} component="form">
          {newDenialsWorkflow && prePopulateDenialsTemplate && !hideGuidelinesUsed && isMdReview && (
            <Grid item xs={12}>
              <GuidelinesUsedSection
                guidelinesFromReview={guidelinesFromReview}
                usedGuidelineIds={
                  review.usedGuidelineIds && review.usedGuidelineIds.length > 0 ? review.usedGuidelineIds : [""]
                }
                setUsedGuidelineIds={setUsedGuidelineIds}
                addUsedGuidelineIds={addUsedGuidelineIds}
                deleteUsedGuidelineId={deleteUsedGuidelineId}
                selectedGuidelines={review.selectedGuidelineIds || []}
                usedGuidelines={review.usedGuidelines || []}
              />
            </Grid>
          )}
          {!multiCoverageContent && showDecisionExplanationDropdown && (
            <Grid item xs={12}>
              <ReviewOutcomeDropdown
                serviceRequest={serviceRequest}
                review={review}
                setReview={setReview}
                displayCaseOutcome={displayCaseOutcome}
                setDisplayCaseOutcome={setDisplayCaseOutcome}
                setReviewOutcomeSubmenuOptionsAvailable={setReviewOutcomeSubmenuOptionsAvailable}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <RichTextEditor
              readonly={false}
              testid="md-review-text-editor-additional-notes"
              label={reviewDeterminationNoteLabel}
              error={Boolean(attemptedSubmitted && emptyDeterminationNote)}
              helperText={Boolean(attemptedSubmitted && emptyDeterminationNote) ? "Required" : ""}
              customEditorState={automatedReviewNoteEditorState}
              htmlValue={automatedReviewNoteEditorState ? "" : review.determinationNote || ""}
              setHtmlValue={setValue("determinationNote")}
              setEditorState={setAutomatedReviewNoteEditorState}
            />
          </Grid>
          {facilityBasedFeatureEnabled && isInpatientRequest && reviewStayDateEditsFlag && (
            <Grid item xs={12} style={{ marginBottom: theme.spacing(2), padding: theme.spacing(1) }}>
              {renderPatientStayCard}
            </Grid>
          )}
          {patientStayDateRanges && setPatientStayDateRanges && facilityBasedFeatureEnabled && (
            <Grid item xs={12} style={{ marginBottom: theme.spacing(2), padding: theme.spacing(0) }}>
              <PatientStays
                version={"RequestedStay"}
                minimumFromDate={formContent?.startDate}
                admissionDate={formContent?.admissionDate || null}
                healthPlanName={serviceRequest.healthPlanName || ""}
                patientStayDateRanges={patientStayDateRanges}
                setPatientStayDateRanges={setPatientStayDateRanges}
                patientStaysErrorStates={patientStaysErrorStates}
                isDischarged={isDischarged}
                attemptedSubmit={attemptedSubmitted}
                authorization={authorization}
                patientStatus={formContent?.patientStatus}
                isReview={true}
                forceOneLine
              />
            </Grid>
          )}
          {facilityBasedFeatureEnabled && isPatientAdmitted && !reviewStayDateEditsFlag && (
            <>
              <Grid item xs={12}>
                <RadioGroup
                  row
                  style={{ padding: theme.spacing(2) }}
                  nowrap
                  label="Has the patient been discharged?"
                  options={[
                    { id: "false", label: "No" },
                    { id: "true", label: "Yes" },
                  ]}
                  value={`${isDischarged}`}
                  onChange={(opt) => {
                    setIsDischarged?.(opt === "true");

                    // Auto-populate discharge date
                    const defaultDischargeDate = getDateForDischargeBasedOnPreviousRanges({
                      decisionedStayDates: authorization?.patientStayDates,
                      currentStayDateRanges: patientStayDateRanges,
                    });
                    if (defaultDischargeDate) {
                      setDischargeDetails?.((prev) => ({
                        ...prev,
                        actualDischargeDate: defaultDischargeDate,
                      }));
                    }
                  }}
                />
              </Grid>

              {isDischarged && dischargeDetails && setDischargeDetails && (
                <DischargedToDetails
                  dischargeDetails={dischargeDetails}
                  setDischargeDetails={setDischargeDetails}
                  attemptedSubmit={attemptedSubmitted}
                />
              )}
            </>
          )}
          <ReviewOutreachForm
            showOutreachToggle={serviceRequest.healthPlanName === "Geisinger"}
            serviceRequest={serviceRequest}
            hasOutreachAttempt={hasOutreachAttempt}
            setHasOutreachAttempt={setHasOutreachAttempt}
            outreachAttempt={outreachAttempt}
            setOutreachAttempt={setOutreachAttempt}
            outreachFormConfig={outreachFormConfig}
            outreachFormErrors={outreachFormErrors}
            setOutreachFormErrors={setOutreachFormErrors}
            setReview={setReview}
          />
          {isReadmissionFeatureEnabled &&
            serviceRequest.encounterType === "INPATIENT" &&
            facilityBasedFeatureEnabled && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <ReadmissionSelection
                  isReadmission={isReadmission}
                  setIsReadmission={setIsReadmission}
                  anchorAuthNumber={anchorAuthNumber}
                  onAnchorAuthNumberChange={setAnchorAuthNumber}
                  hasErrorWithAnchorAuth={hasErrorWithAnchorAuth}
                  setHasErrorWithAnchorAuth={setHasErrorWithAnchorAuth}
                  attemptedSubmitted={attemptedSubmitted}
                  setHasReadmissionChanged={setHasReadmissionChanged}
                />
              </>
            )}
          {!isMdReview && fromQueueManagment && !isPostDenialP2P && (
            <>
              <Grid item xs={12}>
                <PrimaryButton
                  onClick={() => {
                    setApproveModalWithoutP2P(true);
                  }}
                  className={mdReviewStyles.approveRequestWithoutP2PButton}
                >
                  Approve request without P2P
                </PrimaryButton>
              </Grid>
              <Grid item xs={12} style={{ margin: theme.spacing(0, 0, 7, 0) }}>
                <Caption style={{ color: colorsLight.font.light }}>Page will redirect to MD review</Caption>
              </Grid>
            </>
          )}
          {multiCoverageContent && (
            <GenericDoctorReviewMultiCoverageOutcomes
              serviceRequest={serviceRequest}
              review={review}
              setReview={setReview as (update: Partial<MdReview> | Partial<PeerToPeerReview>) => void}
              attemptedSubmit={attemptedSubmitted}
              guidelinesFromReview={guidelinesFromReview}
              existingReviews={existingReviews}
            />
          )}
          <Grid item xs={12} style={{ paddingBottom: spacing(5) }}></Grid>
        </Grid>
      </>
      <>
        <ReviewControlBoxOuterContainer matchesRight={matchesRight}>
          <ReviewControlBoxInnerContainer>
            <ReviewControlPanel
              reviewType={review?.reviewType}
              handleDiscardAndExit={onDiscard}
              discarding={discarding}
              discardAndExitDisabled={loadingDiscardOrSave || discardAndExitDisabled}
              handleSaveAndExit={saveDraftWithRedirect}
              saveAndExitLoading={currentlySavingDraft}
              saveAndExitDisabled={loadingDiscardOrSave}
              handleFinishReview={onFinishReview}
              finishButtonDisabled={disableFinishButton()}
              canSaveDraft={canSaveDraft}
              handleHeadingClick={onHeadingClick}
              saveReviewChatbotFindings={saveReviewChatbotFindings}
            />
          </ReviewControlBoxInnerContainer>
        </ReviewControlBoxOuterContainer>
      </>
      <InformativeModal
        icon={<ClipboardMissingInfo />}
        open={approveModalWithoutP2P}
        onClose={() => {
          setApproveModalWithoutP2P(false);
        }}
        headerText={"Are you sure you want to approve the request without a P2P?"}
        additionalInfoText={"You will be redirected to MD review to complete the approval"}
        primaryButtonText={"Proceed to approve without P2P"}
        primaryButtonAction={() => {
          onApproveWithoutP2P?.();
        }}
        primaryButtonLoading={approveWithoutP2PLoading}
        primaryButtonDisabled={approveWithoutP2PLoading}
        tertiaryButtonText={"Go back"}
        tertiaryButtonAction={() => {
          setApproveModalWithoutP2P(false);
        }}
      />
    </div>
  );
}

interface ReviewControlBoxOuterContainerProps {
  matchesRight?: boolean;
  denial?: boolean;
  note?: boolean;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const ReviewControlBoxOuterContainer = styled(({ matchesRight, denial, note, ...passthrough }) => (
  <div {...passthrough} />
))<Theme, ReviewControlBoxOuterContainerProps>(({ theme, matchesRight, denial, note }) => ({
  position: "fixed",
  bottom: 0,
  marginLeft: note ? 0 : denial ? -30 : -16,
  marginRight: -16,
  boxShadow: "0px -5px 10px -5px rgba(0, 0, 0, 0.2)",
  backgroundColor: theme.palette.background.paper,
  zIndex: 1000,
  width: matchesRight ? 712 : "calc(100vw - 728px)",
}));

interface ReviewControlBoxInnerContainerProps {
  center?: boolean;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const ReviewControlBoxInnerContainer = styled(({ center, ...passthrough }) => <div {...passthrough} />)<
  Theme,
  ReviewControlBoxInnerContainerProps
>(({ theme, center }) => ({
  padding: theme.spacing(1.5, 0, 1.5),
  display: "flex",
  justifyContent: center ? "center" : "space-between",
  marginLeft: "12px",
  marginRight: "24px",
}));
