import React from "react";
import { ProcedureCode } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Divider, Grid, styled } from "@material-ui/core";
import ProcedureCodeRow from "./ProcedureCodeRow";
import { Caption, colorsLight } from "@coherehealth/common";
import { H6 } from "@coherehealth/design-system";

interface Props {
  requestedProcedureCodes: ProcedureCode[];
  serviceLevelUnits: boolean;
  approvedUnits: number;
  updateSinglePxCodeUnit: (unitsUpdate: string, updatedPxCode: ProcedureCode) => void;
  clinicalService?: string;
}

export default function PartialProcedureCodeUnitsApproval({
  requestedProcedureCodes,
  approvedUnits,
  serviceLevelUnits,
  clinicalService,
  updateSinglePxCodeUnit,
}: Props) {
  return (
    <>
      {clinicalService && (
        <H6 data-testid="clinical-service-name" style={{ paddingTop: "16px", paddingBottom: "16px" }}>
          {clinicalService}
        </H6>
      )}
      <DescriptionHeader>
        <Grid item container>
          {/* custom styles on margins because this isnt a true table */}
          <Grid item xs={2} style={{ marginLeft: "20px" }}>
            <Caption>Code</Caption>
          </Grid>
          {!serviceLevelUnits && (
            <Grid item xs={2} style={{ marginLeft: "-4px" }}>
              <Caption>Requested</Caption>
            </Grid>
          )}
          <Grid item xs={2}>
            <Caption>Approved</Caption>
          </Grid>
          <Grid item xs={2} style={{ marginLeft: "-8px" }}>
            <Caption>Description</Caption>
          </Grid>
        </Grid>
      </DescriptionHeader>
      <PxContainer item container>
        {requestedProcedureCodes.map((px, index) => {
          return (
            <React.Fragment key={`${px.code}-${index}`}>
              <ProcedureCodeRow
                procedureCode={px}
                approvedServiceLevelUnits={approvedUnits}
                serviceLevelUnits={serviceLevelUnits}
                updateProcedureCodeUnits={(unitsUpdate) => updateSinglePxCodeUnit(unitsUpdate, px)}
              />
              {index < requestedProcedureCodes.length - 1 && <RowDivider />}
            </React.Fragment>
          );
        })}
      </PxContainer>
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DescriptionHeader = styled("div")({
  width: "100%",
  marginBottom: "8px",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PxContainer = styled(Grid)({
  border: `1px solid ${colorsLight.gray.divider}`,
  boxSizing: "border-box",
  borderRadius: "3px",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RowDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  width: "100%",
}));
