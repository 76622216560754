import { AuthStatus, useGetAllHistoricPatientStayDates } from "@coherehealth/core-platform-api";
import { useFeature } from "@coherehealth/common";
import { isAuthStatus } from "util/serviceRequest";
import { useMemo } from "react";

export default function useFetchPatientStayDates(authorizationId?: string) {
  const expandedReviewStayDatesEdits = useFeature("expandedReviewStayDatesEdits");

  const { data: patientStayDatesHistory } = useGetAllHistoricPatientStayDates({
    authorizationId: authorizationId || "",
    lazy: !authorizationId || !expandedReviewStayDatesEdits,
  });

  const mappedPatientStayDatesHistory = useMemo(() => {
    return patientStayDatesHistory?.flatMap((stayDate) => {
      const status = isAuthStatus(stayDate.record?.status?.toUpperCase().replaceAll(" ", "_") || "")
        ? (stayDate.record?.status as AuthStatus)
        : "PENDING";
      return stayDate.record
        ? [
            {
              ...stayDate,
              record: {
                ...stayDate.record,
                status,
              },
            },
          ]
        : [];
    });
  }, [patientStayDatesHistory]);

  return {
    patientStayDatesHistory: mappedPatientStayDatesHistory || [],
    expandedReviewStayDatesEdits: expandedReviewStayDatesEdits || false,
  };
}
