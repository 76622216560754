import { appHeaderHeight, H1, H4, PrimaryButton } from "@coherehealth/common";
import {
  useGetHealthPlanHierarchyFeatureConfiguration,
  useUpdateApplicationConfiguration,
  ApplicationConfiguration,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { CircularProgress, Grid, styled, useTheme } from "@material-ui/core";
import WestIcon from "@mui/icons-material/West";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { CONFIGURATION_MANAGEMENT_HEADER_HEIGHT } from "./Header";
import { CONFIGURATION_MANAGEMENT_DRAWER_WIDTH } from "./SelectConfigurationDrawer";
import JsonEditor from "./JsonEditor";

interface Props {
  applicationConfigurationId?: string;
  featureConfigurationFieldName?: string;
  parentApplicationConfigurationId?: string;
  navigateToParentConfigurationButton: () => void;
}

export default function ConfigurationEditor({
  applicationConfigurationId,
  featureConfigurationFieldName,
  parentApplicationConfigurationId,
  navigateToParentConfigurationButton,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { palette, spacing } = useTheme();
  const [jsonConfiguration, setJsonConfiguration] = useState<string>("");
  const [dirtyFlag, setDirtyFlag] = useState(false);

  const {
    data: featureConfiguration,
    loading: featureConfigurationLoading,
    error: featureConfigurationError,
    refetch: refetchFeatureConfiguration,
  } = useGetHealthPlanHierarchyFeatureConfiguration({
    lazy: true,
    queryParams: {
      applicationConfigurationId: applicationConfigurationId || "",
      featureConfigurationName: featureConfigurationFieldName || "",
      parentApplicationConfigurationId: parentApplicationConfigurationId,
    },
  });

  useEffect(() => {
    if (applicationConfigurationId && featureConfigurationFieldName) {
      refetchFeatureConfiguration();
    }
  }, [
    featureConfigurationFieldName,
    applicationConfigurationId,
    refetchFeatureConfiguration,
    parentApplicationConfigurationId,
  ]);

  useEffect(() => {
    const keysToHide = [
      "id",
      "lastUpdated",
      "dateCreated",
      "createdBy",
      "updatedBy",
      "applicationConfiguration",
      "featureConfigurationLevel",
      "healthPlanName",
      "delegatedVendorName",
      "updatedBy",
      "dirtyFlag",
    ];
    const configurationJsonReplacer = (key: any, val: any) => {
      if (key === featureConfigurationFieldName) {
        keysToHide.forEach((key) => delete val[key]);
        return val;
      }
      return val;
    };
    const fallbackObject = {
      [featureConfigurationFieldName || ""]: {},
    };
    const rawObject = deepCopy(featureConfiguration || fallbackObject);
    if (featureConfigurationFieldName && rawObject[featureConfigurationFieldName]) {
      setDirtyFlag(rawObject[featureConfigurationFieldName]["dirtyFlag"]);
    }
    setJsonConfiguration(prettyJson(rawObject, configurationJsonReplacer));
  }, [featureConfiguration, featureConfigurationFieldName]);

  const {
    mutate: updateApplicationConfiguration,
    loading: updateApplicationConfigurationLoading,
    error: updateApplicationConfigurationError,
  } = useUpdateApplicationConfiguration({ id: applicationConfigurationId || "" });

  useEffect(() => {
    if (updateApplicationConfigurationError) {
      enqueueSnackbar(`Failed to update configuration: ${updateApplicationConfigurationError.message}`, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [updateApplicationConfigurationError, enqueueSnackbar]);

  const featureConfigurationDoesNotExistButCanBeBuilt =
    featureConfigurationError && featureConfigurationError.status === 404;
  const featureConfigurationDoesNotExistAndDoesNotHaveParentFeatureConfiguration =
    featureConfigurationError && featureConfigurationError.status === 428;

  const onSaveFeatureConfiguration = async () => {
    let formattedConfig = JSON.parse(jsonConfiguration);
    if (featureConfigurationFieldName && formattedConfig[featureConfigurationFieldName]) {
      // this is a hack to ensure feature configs with decision trees are marked dirty when the decision tree is updated.
      formattedConfig[featureConfigurationFieldName]["dirtyFlag"] = !dirtyFlag;
    }
    const response = await updateApplicationConfiguration({ ...formattedConfig });
    if (response) {
      enqueueSnackbar("Configuration updated", {
        variant: "success",
        preventDuplicate: true,
      });
    }
    refetchFeatureConfiguration();
  };

  const onCreateNewFeatureConfiguration = async () => {
    try {
      const payload = {
        [featureConfigurationFieldName || ""]: { applicationConfiguration: applicationConfigurationId },
      } as unknown as ApplicationConfiguration;
      const response = await updateApplicationConfiguration({ ...payload });
      if (response) {
        enqueueSnackbar("Configuration created", {
          variant: "success",
          preventDuplicate: true,
        });
      }
      refetchFeatureConfiguration();
    } catch {
      enqueueSnackbar("Error creating feature configuration: badly formatted request.", {
        variant: "success",
        preventDuplicate: true,
      });
    }
  };

  const onNavigateToParentConfiguration = () => {
    if (parentApplicationConfigurationId) {
      navigateToParentConfigurationButton();
      refetchFeatureConfiguration();
    }
  };

  return (
    <ConfigurationEditorContainer>
      {featureConfiguration ? (
        <>
          <JsonEditor
            json={jsonConfiguration}
            onUpdateJson={setJsonConfiguration}
            loading={featureConfigurationLoading}
          />
          <ButtonContainer>
            <PrimaryButton
              onClick={onSaveFeatureConfiguration}
              loading={updateApplicationConfigurationLoading}
              disabled={updateApplicationConfigurationLoading || featureConfigurationLoading}
            >
              Save
            </PrimaryButton>
          </ButtonContainer>
        </>
      ) : (
        <CenteredTextContainer>
          <StyledGridContainer container spacing={1}>
            {!applicationConfigurationId || !featureConfigurationFieldName ? (
              <Grid item xs={12}>
                <WestIcon sx={{ width: 150, height: 150, color: palette.primary.dark }} />
                <CenteredTitle>Select a configuration and feature.</CenteredTitle>
              </Grid>
            ) : (
              <>
                {featureConfigurationLoading ? (
                  <Grid item xs={12}>
                    <CircularProgress style={{ width: 100, height: 100, color: palette.primary.dark }} />
                    <CenteredTitle style={{ paddingTop: spacing(4) }}>Loading feature configuration...</CenteredTitle>
                  </Grid>
                ) : (
                  <>
                    {featureConfigurationDoesNotExistButCanBeBuilt && (
                      <>
                        <Grid item xs={12}>
                          <CenteredTitle>This configuration does not exist.</CenteredTitle>
                        </Grid>
                        <Grid item xs={12}>
                          <CenteredSubtitle>
                            Build an empty configuration by clicking the button below.
                          </CenteredSubtitle>
                        </Grid>
                        <Grid item alignContent="center" xs={12} style={{ paddingTop: spacing(3) }}>
                          <PrimaryButton
                            onClick={onCreateNewFeatureConfiguration}
                            loading={updateApplicationConfigurationLoading}
                            disabled={updateApplicationConfigurationLoading}
                          >
                            Create configuration
                          </PrimaryButton>
                        </Grid>
                      </>
                    )}
                    {featureConfigurationDoesNotExistAndDoesNotHaveParentFeatureConfiguration && (
                      <>
                        <Grid item xs={12}>
                          <CenteredTitle>
                            The health plan level configuration for this feature does not exist.
                          </CenteredTitle>
                        </Grid>
                        <Grid item xs={12}>
                          <CenteredSubtitle>
                            Click the button below to navigate to the health plan configuration for this feature.
                          </CenteredSubtitle>
                        </Grid>
                        <Grid item alignContent="center" xs={12} style={{ paddingTop: spacing(3) }}>
                          <PrimaryButton onClick={onNavigateToParentConfiguration}>
                            Go to health plan configuration
                          </PrimaryButton>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </StyledGridContainer>
        </CenteredTextContainer>
      )}
    </ConfigurationEditorContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ConfigurationEditorContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  top: appHeaderHeight() + CONFIGURATION_MANAGEMENT_HEADER_HEIGHT,
  left: CONFIGURATION_MANAGEMENT_DRAWER_WIDTH,
  width: `calc(100% - ${CONFIGURATION_MANAGEMENT_DRAWER_WIDTH}px)`,
  height: `calc(100% - ${appHeaderHeight() + CONFIGURATION_MANAGEMENT_HEADER_HEIGHT}px)`,
  padding: theme.spacing(4, 6),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ButtonContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const deepCopy = (obj: object) => JSON.parse(JSON.stringify(obj));

export const prettyJson = (o: object, replacer: any) => JSON.stringify(o, replacer, 2);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CenteredTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: "100%",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledGridContainer = styled(Grid)(({ theme }) => ({
  margin: "auto",
  paddingBottom: theme.spacing(16),
  textAlign: "center",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CenteredTitle = styled(H1)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CenteredSubtitle = styled(H4)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));
