import React, { useCallback, useEffect, useState } from "react";
import {
  useDiscardAuditReview,
  useSubmitAuditReview,
  useUserActivityBatchUpdate,
  useSubmitNewHireAuditReview,
  useDiscardNewHireAuditReview,
  useDiscardOutOfScopeAuditReview,
  useSubmitOutOfScopeAuditReview,
  ServiceRequestResponse,
  useUpdateAuditReview,
  useUpdateNewHireAuditReview,
  useUpdateOutOfScopeAuditReview,
  useDiscardFaxAuditReview,
  useSubmitFaxAuditReview,
  useUpdateFaxAuditReview,
  ReviewOutcome,
} from "@coherehealth/core-platform-api";
import { useNavigate, useSearchParams } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import GuidelineReview from "../GuidelineReview/GuidelineReview";
import AuditReviewEdit from "./AuditReviewEdit";
import AuditReviewReadOnly from "./AuditReviewReadonly";
import ReviewHeader from "../ReviewHeader/ReviewHeader";
import {
  InformativeModal,
  createBatchActivities,
  getEmptyUserClickTracking,
  queueMgmtBaseUrl,
} from "@coherehealth/common";
import { TrackUserActivityProps, useTrackUserInteraction } from "util/userActivityTracker";
import { guidelineSnapshotFromReview } from "../index";
import {
  useReview,
  GenericAuditReviewInfo,
  GenericAuditReviewUpdate,
  GenericAuditReview,
} from "components/ClinicalReview/reviewUtils/useReviews";
import ClipboardMissingInfo from "components/images/ClipboardMissingInfo";
import { ReviewCommonProps, ReviewReadOnlyCommonProps, useGetUser } from "components/ClinicalReview/reviewUtils/utils";
import { useCompleteServiceCase } from "@coherehealth/qm-api";
import config from "api/config";
import AuditReviewModal, { OOSAuditReviewOutcome } from "./AuditReviewModal";
import DetailedHardRedirectClinicalReviewModal, {
  ClinicalReviewErrorDetails,
} from "../Modals/DetailedHardRedirectClinicalReviewModal";
import { defaultClinicalReviewErrorState, extractErrorDetails } from "../util/ReviewSectionUtil";
import { redirectToPatientSummaryFromReview, routeToPatientSummaryFromReview } from "util/routeUtils/routeUtils";

/**
 * Please put common props to reviews inside ReviewCommon Props
 */
interface AuditReviewComponentProps<T extends GenericAuditReview> extends ReviewCommonProps {
  serviceRequest: ServiceRequestResponse;
  auditReview: T;
}

/**
 * Please put common read only props to reviews inside ReviewReadOnlyCommonProps
 */
interface AuditReviewReadonlyProps extends ReviewReadOnlyCommonProps {
  serviceRequest: ServiceRequestResponse;
  auditReview: GenericAuditReview;
}
interface AuditReviewEditableProps<T extends GenericAuditReview>
  extends Omit<AuditReviewComponentProps<T>, "auditReview"> {
  auditReviewId: string;
  auditReviewType: T["reviewType"];
}

interface AuditReviewEditorProps<T extends GenericAuditReview> extends AuditReviewComponentProps<T> {
  setAuditReview: GenericAuditReviewUpdate<T>;
}

type EventType = TrackUserActivityProps["event"];

const AuditReviewComponent = <T extends GenericAuditReview>({
  auditReview,
  ...otherProps
}: AuditReviewComponentProps<T>) => {
  if (auditReview?.reviewStatus === "DRAFT") {
    return (
      <AuditReviewEditable {...otherProps} auditReviewId={auditReview.id} auditReviewType={auditReview.reviewType} />
    );
  } else if (auditReview?.reviewStatus === "COMPLETE" || auditReview?.reviewStatus === "DISCARDED") {
    return <AuditReviewReadonly auditReview={auditReview} {...otherProps} />;
  } else {
    return null;
  }
};

const AuditReviewEditable = <T extends GenericAuditReview>({
  auditReviewId,
  auditReviewType,
  ...otherProps
}: AuditReviewEditableProps<T>) => {
  const reviewInfo: GenericAuditReviewInfo = { reviewId: auditReviewId, reviewType: auditReviewType };
  const [auditReview, setAuditReview] = useReview(reviewInfo);
  if (!auditReview || !setAuditReview) {
    return null;
  }
  return <AuditReviewEditor {...otherProps} auditReview={auditReview} setAuditReview={setAuditReview} />;
};

const AuditReviewEditor = <T extends GenericAuditReview>({
  serviceRequest,
  auditReview,
  setAuditReview,
  expanded,
  toggleExpanded,
  onFinishEditing,
  userClickInfoTracking,
  setUserClickInfoTracking,
  searchInfos,
  setSearchInfos,
  reviewRef,
  copiedAttachmentText,
  pastedAttachmentTexts,
  setPastedAttachmentTexts,
  submitHighlights,
  submittingHighlights,
  getHighlightPayload,
  attachmentGuidelineTextHighlightsSnapshot,
}: AuditReviewEditorProps<T>) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useGetUser();
  const userId = user?.sub;

  const [duplicateReviewModalOpen, setDuplicateReviewModalOpen] = useState<boolean>(false);
  const [openAuditFailModal, setOpenAuditFailModal] = useState(false);
  const [openHardRedirectOnSubmitModal, setOpenHardRedirectOnSubmitModal] = useState(false);
  const [openReviewOutcomeModal, setOpenReviewOutcomeModal] = useState(false);
  const [errorDetails, setErrorDetails] = useState<ClinicalReviewErrorDetails>(
    defaultClinicalReviewErrorState(serviceRequest.id, auditReview.id)
  );
  const [searchParams] = useSearchParams();
  const [reviewSubmissionLoading, setReviewSubmissionLoading] = useState<boolean>(false);

  const caseId = searchParams.get("caseId");
  const { mutate: completeServiceCase, loading: completingServiceCase } = useCompleteServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () => {
      window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
    },
  });

  const trackUserActivityInteraction = useTrackUserInteraction();

  const onFinishEditingInternal = useCallback(() => {
    toggleExpanded?.();
    onFinishEditing?.();
  }, [toggleExpanded, onFinishEditing]);

  const { mutate: postBatchAcivities, loading: postingBatchActivities } = useUserActivityBatchUpdate({});

  const {
    mutate: submit,
    error: submitError,
    loading: submitLoading,
  } = useSubmitAuditReview({
    reviewId: auditReview.id,
  });

  const {
    mutate: submitNewHireAuditReview,
    error: newHireAuditSubmitError,
    loading: newHireAuditSubmitting,
  } = useSubmitNewHireAuditReview({ reviewId: auditReview.id });

  const {
    mutate: submitOOSAuditReview,
    error: OOSAuditSubmitError,
    loading: OOSAuditSubmitting,
  } = useSubmitOutOfScopeAuditReview({ reviewId: auditReview.id });

  const {
    mutate: submitFaxAuditReview,
    error: FaxAuditSubmitError,
    loading: FaxAuditSubmitting,
  } = useSubmitFaxAuditReview({ reviewId: auditReview.id });

  useEffect(() => {
    if (submitError || newHireAuditSubmitError || FaxAuditSubmitError) {
      const submissionErrorCode =
        submitError?.status ||
        newHireAuditSubmitError?.status ||
        OOSAuditSubmitError?.status ||
        FaxAuditSubmitError?.status;
      const submissionErrorMsg =
        submitError?.message ||
        newHireAuditSubmitError?.message ||
        OOSAuditSubmitError?.message ||
        FaxAuditSubmitError?.message;
      if (submissionErrorCode === 409) {
        setDuplicateReviewModalOpen(true);
      } else {
        setErrorDetails(
          extractErrorDetails(
            submitError || newHireAuditSubmitError || OOSAuditSubmitError || FaxAuditSubmitError,
            serviceRequest.id,
            auditReview.id
          )
        );
        setOpenHardRedirectOnSubmitModal(true);
        enqueueSnackbar(`Error updating audit review: ${submissionErrorMsg}`, {
          variant: "error",
        });
      }
    }
  }, [
    enqueueSnackbar,
    submitError,
    newHireAuditSubmitError,
    OOSAuditSubmitError,
    FaxAuditSubmitError,
    serviceRequest.id,
    auditReview.id,
  ]);

  const submitMetaData = useCallback(
    async (eventName: EventType, submitResponse: GenericAuditReview) => {
      if (submitHighlights) {
        await submitHighlights();
      }
      userClickInfoTracking &&
        (await postBatchAcivities(
          createBatchActivities(
            eventName,
            "REVIEW_SUBMISSION",
            "INTERACTION",
            userClickInfoTracking,
            searchInfos,
            pastedAttachmentTexts,
            userId,
            submitResponse.id,
            serviceRequest.cohereId,
            serviceRequest.id
          )
        ));
      setUserClickInfoTracking?.(getEmptyUserClickTracking());
      setSearchInfos?.([]);
      onFinishEditingInternal();
      const snap = submitResponse.guidelines ? guidelineSnapshotFromReview(submitResponse) : undefined;
      await trackUserActivityInteraction({
        event: eventName,
        stage: "REVIEW_SUBMISSION",
        activityContext: { reviewId: submitResponse.id, serviceRequestId: serviceRequest?.id },
        afterGuidelineSnapshot: snap,
        attachmentGuidelineTextHighlightsSnapshot: {
          ...attachmentGuidelineTextHighlightsSnapshot,
          reviewer: submitResponse.createdByName,
          dateCompleted: submitResponse.dateCompleted,
          auditOutcome: submitResponse.auditOutcome,
          textHighlights: getHighlightPayload?.() || undefined,
        },
      });
    },
    [
      attachmentGuidelineTextHighlightsSnapshot,
      getHighlightPayload,
      pastedAttachmentTexts,
      postBatchAcivities,
      searchInfos,
      onFinishEditingInternal,
      serviceRequest.cohereId,
      serviceRequest.id,
      setSearchInfos,
      setUserClickInfoTracking,
      submitHighlights,
      trackUserActivityInteraction,
      userClickInfoTracking,
      userId,
    ]
  );

  const finishNewHireAuditReview = useCallback(async () => {
    if (auditReview.reviewType === "NewHireAuditReview" && auditReview.auditOutcome) {
      const res = await submitNewHireAuditReview({
        ...auditReview,
        auditOutcome: auditReview.auditOutcome,
        guidelines: auditReview.guidelines || [],
      });
      submitMetaData("NEW_HIRE_AUDIT_REVIEW", res).then(async () => {
        if (caseId) {
          await completeServiceCase({
            reviewOutcome:
              auditReview.auditOutcome === "PASS" ? auditReview.auditedReviewContext?.auditedReviewOutcome : undefined,
            dateCompleted: new Date().toISOString(),
            caseNotes: auditReview.auditNotes,
            auditOutcome: auditReview.auditOutcome,
            reviewId: auditReview.id,
          }).catch(() => {
            window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
          });
        } else {
          redirectToPatientSummaryFromReview({ serviceRequest, reviewId: auditReview.id, navigate });
        }
      });
    }
  }, [auditReview, caseId, completeServiceCase, navigate, serviceRequest, submitMetaData, submitNewHireAuditReview]);

  const finishOOSAuditReview = useCallback(async () => {
    if (auditReview.reviewType === "OutOfScopeAuditReview" && auditReview.auditOutcome && auditReview.reviewOutcome) {
      setReviewSubmissionLoading(true);
      const res = await submitOOSAuditReview({
        ...auditReview,
        auditOutcome: auditReview.auditOutcome,
        reviewOutcome: auditReview.reviewOutcome,
        guidelines: auditReview.guidelines || [],
      });
      submitMetaData("DX_OOS_AUDIT_REVIEW", res).then(async () => {
        if (caseId) {
          await completeServiceCase({
            reviewOutcome: auditReview.reviewOutcome,
            dateCompleted: new Date().toISOString(),
            caseNotes: auditReview.auditNotes,
            auditOutcome: auditReview.auditOutcome,
            reviewId: auditReview.id,
          }).catch(() => {
            setReviewSubmissionLoading(false);
            window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
          });
        } else {
          setReviewSubmissionLoading(false);
          redirectToPatientSummaryFromReview({ serviceRequest, reviewId: auditReview.id, navigate });
        }
      });
    }
  }, [auditReview, caseId, completeServiceCase, navigate, serviceRequest, submitMetaData, submitOOSAuditReview]);

  const finishFaxAuditReview = useCallback(async () => {
    if (auditReview.reviewType === "FaxAuditReview" && auditReview.auditOutcome) {
      setReviewSubmissionLoading(true);
      const res = await submitFaxAuditReview({
        ...auditReview,
        auditOutcome: auditReview.auditOutcome,
      });
      submitMetaData("FAX_AUDIT_REVIEW", res).then(async () => {
        if (caseId) {
          await completeServiceCase({
            dateCompleted: new Date().toISOString(),
            caseNotes: auditReview.auditNotes,
            auditOutcome: auditReview.auditOutcome,
            reviewId: auditReview.id,
            reviewOutcome: "PENDING_EXTERNAL_REVIEW",
          }).catch(() => {
            setReviewSubmissionLoading(false);
            window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
          });
        } else {
          setReviewSubmissionLoading(false);
          redirectToPatientSummaryFromReview({ serviceRequest, reviewId: auditReview.id, navigate });
        }
      });
    }
  }, [auditReview, caseId, completeServiceCase, navigate, serviceRequest, submitFaxAuditReview, submitMetaData]);

  const submitAuditReview = useCallback(async () => {
    if (auditReview.auditOutcome) {
      if (auditReview.reviewType === "NewHireAuditReview") {
        if (auditReview.auditOutcome === "FAIL") {
          setOpenAuditFailModal(true);
        } else {
          finishNewHireAuditReview();
        }
      } else if (auditReview.reviewType === "OutOfScopeAuditReview") {
        setOpenReviewOutcomeModal(true);
      } else if (auditReview.reviewType === "AuditReview") {
        setReviewSubmissionLoading(true);
        const res = await submit({
          ...auditReview,
          auditOutcome: auditReview.auditOutcome,
          guidelines: auditReview.guidelines || [],
        });
        submitMetaData("AUDIT_REVIEW", res).then(async () => {
          if (caseId) {
            await completeServiceCase({
              dateCompleted: new Date().toISOString(),
              caseNotes: auditReview.auditNotes,
              reviewId: auditReview.id,
              reviewOutcome: auditReview.auditOutcome as ReviewOutcome,
            }).catch(() => {
              setReviewSubmissionLoading(false);
              window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
            });
          } else {
            setReviewSubmissionLoading(false);
            redirectToPatientSummaryFromReview({ serviceRequest, reviewId: auditReview.id, navigate });
          }
        });
      }
    } else {
      throw new Error("Review outcome must not be empty to submit");
    }
  }, [
    auditReview,
    caseId,
    completeServiceCase,
    finishNewHireAuditReview,
    navigate,
    serviceRequest,
    submit,
    submitMetaData,
  ]);

  // Hooks & callbacks to discard a review
  const {
    mutate: discard,
    error: discardError,
    loading: discarding,
  } = useDiscardAuditReview({
    reviewId: auditReview.id,
  });

  const {
    mutate: discardNewHireAudit,
    error: discardNewHireAuditError,
    loading: discardingNewHireAudit,
  } = useDiscardNewHireAuditReview({ reviewId: auditReview.id });

  const {
    mutate: discardOOSAudit,
    error: discardOOSError,
    loading: discardingOOSAudit,
  } = useDiscardOutOfScopeAuditReview({ reviewId: auditReview.id });

  const {
    mutate: discardFaxAudit,
    error: discardFaxError,
    loading: discardingFaxAudit,
  } = useDiscardFaxAuditReview({ reviewId: auditReview.id });

  useEffect(() => {
    if (discardError || discardNewHireAuditError || discardOOSError || discardFaxError) {
      enqueueSnackbar(
        `Error discarding audit review: ${
          discardError?.message ||
          discardNewHireAuditError?.message ||
          discardOOSError?.message ||
          discardFaxError?.message
        }`,
        {
          variant: "error",
        }
      );
    }
  }, [discardError, discardNewHireAuditError, discardOOSError, discardFaxError, enqueueSnackbar]);

  const discardAuditReview = useCallback(async () => {
    const isNewHireAuditReview = auditReview.reviewType === "NewHireAuditReview";
    const isOOSAuditReview = auditReview.reviewType === "OutOfScopeAuditReview";
    const isFaxAuditReview = auditReview.reviewType === "FaxAuditReview";
    let res;
    if (isNewHireAuditReview) {
      res = await discardNewHireAudit({ ...auditReview });
    } else if (isOOSAuditReview) {
      res = await discardOOSAudit({ ...auditReview });
    } else if (isFaxAuditReview) {
      res = await discardFaxAudit({ ...auditReview });
    } else {
      res = await discard({
        ...auditReview,
      });
    }

    userClickInfoTracking &&
      (await postBatchAcivities(
        createBatchActivities(
          "AUDIT_REVIEW",
          "REVIEW_SUBMISSION",
          "INTERACTION",
          userClickInfoTracking,
          searchInfos,
          pastedAttachmentTexts,
          userId,
          res.id,
          serviceRequest.cohereId,
          serviceRequest.id
        )
      ));
    setUserClickInfoTracking?.(getEmptyUserClickTracking());
    setSearchInfos?.([]);
    onFinishEditingInternal();
    const { reviewType, ...rest } = res;
    setAuditReview(rest);
    redirectToPatientSummaryFromReview({ serviceRequest, caseId, reviewId: auditReview.id, navigate });
  }, [
    auditReview,
    caseId,
    discard,
    discardFaxAudit,
    discardNewHireAudit,
    discardOOSAudit,
    navigate,
    onFinishEditingInternal,
    pastedAttachmentTexts,
    postBatchAcivities,
    searchInfos,
    serviceRequest,
    setAuditReview,
    setSearchInfos,
    setUserClickInfoTracking,
    userClickInfoTracking,
    userId,
  ]);

  const {
    mutate: update,
    error: updateError,
    loading: updating,
  } = useUpdateAuditReview({
    reviewId: auditReview.id,
    serviceRequestId: serviceRequest.id,
  });

  const {
    mutate: updateNewHireAudit,
    error: updateNewHireAuditError,
    loading: updatingNewHireAudit,
  } = useUpdateNewHireAuditReview({
    reviewId: auditReview.id,
    serviceRequestId: serviceRequest.id,
  });

  const {
    mutate: updateOOSAudit,
    error: updateOOSAuditError,
    loading: updatingOOSAudit,
  } = useUpdateOutOfScopeAuditReview({
    reviewId: auditReview.id,
    serviceRequestId: serviceRequest.id,
  });

  const {
    mutate: updateFaxAudit,
    error: updateFaxAuditError,
    loading: updatingFaxAudit,
  } = useUpdateFaxAuditReview({
    reviewId: auditReview.id,
    serviceRequestId: serviceRequest.id,
  });

  useEffect(() => {
    if (updateError || updateNewHireAuditError || updateOOSAuditError || updateFaxAuditError) {
      enqueueSnackbar(
        `Error saving/updating audit review: ${
          updateError?.message ||
          updateNewHireAuditError?.message ||
          updateOOSAuditError?.message ||
          updateFaxAuditError?.message
        }`,
        {
          variant: "error",
        }
      );
    }
  }, [enqueueSnackbar, updateError, updateNewHireAuditError, updateOOSAuditError, updateFaxAuditError]);

  const saveAuditReviewWithRedirect = useCallback(async () => {
    const isNewHireAuditReview = auditReview.reviewType === "NewHireAuditReview";
    const isOOSAuditReview = auditReview.reviewType === "OutOfScopeAuditReview";
    const isFaxAuditReview = auditReview.reviewType === "FaxAuditReview";
    let res;
    if (isNewHireAuditReview) {
      res = await updateNewHireAudit({ ...auditReview });
    } else if (isOOSAuditReview) {
      res = await updateOOSAudit({ ...auditReview });
    } else if (isFaxAuditReview) {
      res = await updateFaxAudit({ ...auditReview });
    } else {
      res = await update({
        ...auditReview,
      });
    }
    const { reviewType, ...rest } = res;
    setAuditReview(rest);
    userClickInfoTracking &&
      (await postBatchAcivities(
        createBatchActivities(
          "AUDIT_REVIEW",
          "REVIEW_SUBMISSION",
          "INTERACTION",
          userClickInfoTracking,
          searchInfos,
          pastedAttachmentTexts,
          userId,
          res.id,
          serviceRequest.cohereId,
          serviceRequest.id
        )
      ));
    setUserClickInfoTracking?.(getEmptyUserClickTracking());
    setSearchInfos?.([]);
    onFinishEditingInternal();
    redirectToPatientSummaryFromReview({ serviceRequest, caseId, reviewId: auditReview.id, navigate });
  }, [
    auditReview,
    caseId,
    navigate,
    onFinishEditingInternal,
    pastedAttachmentTexts,
    postBatchAcivities,
    searchInfos,
    serviceRequest,
    setAuditReview,
    setSearchInfos,
    setUserClickInfoTracking,
    update,
    updateFaxAudit,
    updateNewHireAudit,
    updateOOSAudit,
    userClickInfoTracking,
    userId,
  ]);

  const finishReviewLoading = reviewSubmissionLoading || OOSAuditSubmitting || postingBatchActivities;

  return (
    <div style={{ backgroundColor: theme.palette.background.paper, paddingBottom: 8 }} ref={reviewRef}>
      <ReviewHeader
        toggleExpanded={toggleExpanded}
        expanded={expanded}
        reviewDateCreated={auditReview.dateCreated}
        reviewLastUpdated={auditReview.lastUpdated}
        reviewStatus={auditReview.reviewStatus}
        reviewOutcome={auditReview.auditOutcome}
        reviewId={auditReview.id}
        reviewType={auditReview.reviewType}
        reviewCreatedByName={auditReview.createdByName}
        reviewDateCompleted={auditReview.dateCompleted}
        reviewCompletedByName={auditReview.reviewerDetails?.reviewerName || auditReview.completedByName}
        clinicalReviewView
      />
      <ReviewDetailsCollapse in={expanded} timeout={100} unmountOnExit style={{ marginLeft: 16 }}>
        <AuditReviewEdit
          auditReview={auditReview}
          setAuditReview={setAuditReview}
          onDiscard={discardAuditReview}
          discarding={discarding || discardingNewHireAudit || discardingOOSAudit || discardingFaxAudit}
          onUpdatingDraft={saveAuditReviewWithRedirect}
          updating={updating || updatingNewHireAudit || updatingOOSAudit || updatingFaxAudit}
          onSubmit={auditReview.reviewType === "FaxAuditReview" ? finishFaxAuditReview : submitAuditReview}
          submitting={
            submitLoading ||
            !!submittingHighlights ||
            newHireAuditSubmitting ||
            completingServiceCase ||
            FaxAuditSubmitting
          }
          copiedAttachmentText={copiedAttachmentText}
          setPastedAttachmentTexts={setPastedAttachmentTexts}
        />
        <InformativeModal
          open={duplicateReviewModalOpen}
          onClose={() => {
            setDuplicateReviewModalOpen(false);
          }}
          headerText={"Review completed by another user"}
          additionalInfoText={"This review was completed by another user. Your review and notes will not be saved."}
          primaryButtonText={"Go to patient summary"}
          primaryButtonRoute={routeToPatientSummaryFromReview({
            serviceRequestId: serviceRequest.id,
            patientId: serviceRequest?.patient?.id,
          })}
        />
        {auditReview.reviewType === "NewHireAuditReview" && (
          <InformativeModal
            headerText={"Are you sure you want to mark this audit review as failed?"}
            subHeaderText={
              auditReview.auditedReviewContext?.auditedReviewerName
                ? `By clicking proceed, you will send your audit note to ${auditReview.auditedReviewContext?.auditedReviewerName} and assign this case back to them.`
                : undefined
            }
            icon={<ClipboardMissingInfo />}
            primaryButtonText="Proceed to reassign"
            primaryButtonAction={() => {
              finishNewHireAuditReview();
            }}
            primaryButtonLoading={newHireAuditSubmitting || completingServiceCase || postingBatchActivities}
            primaryButtonDisabled={newHireAuditSubmitting || completingServiceCase || postingBatchActivities}
            tertiaryButtonText={"Go back"}
            tertiaryButtonAction={() => setOpenAuditFailModal(false)}
            showDivider
            open={openAuditFailModal}
            onClose={() => setOpenAuditFailModal(false)}
          />
        )}
        {auditReview.reviewType === "OutOfScopeAuditReview" && (
          <AuditReviewModal
            open={openReviewOutcomeModal}
            setOpen={setOpenReviewOutcomeModal}
            reviewOutcome={auditReview.reviewOutcome}
            setReviewOutcome={(reviewOutcome: OOSAuditReviewOutcome) => setAuditReview({ reviewOutcome })}
            loading={finishReviewLoading}
            onSubmitReview={finishOOSAuditReview}
            authorizationNote={auditReview.authorizationNote || ""}
            setAuthorizationNote={(authorizationNote: string) => setAuditReview({ authorizationNote })}
            withdrawOptions={serviceRequest.reviewOutcomeWithdrawOptions}
            healthPlanName={serviceRequest.healthPlanName || ""}
            withdrawnReason={auditReview.withdrawnReason}
            setWithdrawnReason={(withdrawnReason: string) => setAuditReview({ withdrawnReason })}
            serviceRequest={serviceRequest}
          />
        )}
        <DetailedHardRedirectClinicalReviewModal
          isOpen={openHardRedirectOnSubmitModal}
          setIsOpen={setOpenHardRedirectOnSubmitModal}
          errorDetails={errorDetails}
        />
      </ReviewDetailsCollapse>
    </div>
  );
};

const AuditReviewReadonly = React.memo(
  ({
    serviceRequest,
    auditReview,
    expanded,
    toggleExpanded,
    reviewRef,
    expandableGuidelines,
    unexpandable,
    reviewDetailsCollapseStyle,
    authorizationReviewView,
    ...otherProps
  }: AuditReviewReadonlyProps) => {
    return (
      <div ref={reviewRef}>
        <ReviewHeader
          toggleExpanded={toggleExpanded}
          expanded={expanded}
          reviewDateCreated={auditReview.dateCreated}
          reviewLastUpdated={auditReview.lastUpdated}
          reviewStatus={auditReview.reviewStatus}
          reviewOutcome={auditReview.auditOutcome}
          reviewType={auditReview.reviewType}
          reviewId={auditReview.id}
          reviewCreatedByName={auditReview.createdByName}
          reviewCompletedByName={auditReview.reviewerDetails?.reviewerName || auditReview.completedByName}
          reviewDateCompleted={auditReview.dateCompleted}
          reviewText={auditReview.auditNotes}
          clinicalReviewView={!unexpandable}
          unexpandable={unexpandable}
          {...otherProps}
        />
        <ReviewDetailsCollapse
          in={expanded}
          timeout={100}
          unmountOnExit
          style={reviewDetailsCollapseStyle ? reviewDetailsCollapseStyle : unexpandable ? {} : { marginLeft: 16 }}
        >
          <AuditReviewReadOnly auditReview={auditReview} hideAuditNotes={authorizationReviewView} />
          <GuidelineReview
            id="tabpanel-0"
            review={auditReview}
            serviceRequest={serviceRequest}
            noClinicalInformationProvided={auditReview?.noClinicalInformationProvided}
            expandable={expandableGuidelines}
            authorizationReviewView={authorizationReviewView}
          />
        </ReviewDetailsCollapse>
      </div>
    );
  }
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ReviewDetailsCollapse = styled(Collapse)(({ theme }) => ({
  margin: theme.spacing(0, 3, 3),
}));

export default AuditReviewComponent;
