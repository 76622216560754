import {
  Attachment,
  PossibleAttachmentNudgeReasons,
  RuleActions,
  ServiceRequestResponse,
  useGetServiceRequestRuleActions,
} from "@coherehealth/core-platform-api";
import { Container, useTheme } from "@material-ui/core";
import { useMuiContainerStyles } from "@coherehealth/common";
import { AddAttachmentsCard } from "components/AddAttachments";
import RequestorCard from "components/Requestor/RequestorCard";
import { AuthBuilderRequestorProps } from "components/AuthBuilder/index";
import RequiredDocsNudge from "components/Nudges/AttachmentsPageRequiredDocsNudge";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { AuthFlowType } from "../common";
import ImpressionTracking from "common/ImpressionTracking";
import { MAX_FILE_SIZE_AGADIA } from "@coherehealth/common";
import { removeEmptyAttachments } from "util/attachmentUtil";
import { isRecommendChangeAction } from "util/rule";
import { Dispatch, SetStateAction, useEffect } from "react";
import { activityContextFromServiceRequest } from "util/userActivityTracker";

interface Props extends AuthBuilderRequestorProps {
  loadingAttachments: boolean;
  carePathJourneyId?: string;
  serviceRequests: ServiceRequestResponse[];
  serviceRequestIds?: string[];
  attachments: Attachment[];
  addAttachmentToServiceRequests: (attachment: Attachment) => void;
  possibleAttachmentNudgeReasons?: PossibleAttachmentNudgeReasons[];
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  setCanContinue: (b: boolean) => void;
  workflowId: string;
  authFlowType: AuthFlowType;
  setRemovedAttachments: React.Dispatch<React.SetStateAction<string[]>>;
  srLoadingActions?: boolean;
  srRuleActions?: RuleActions | null;
  srRuleActionsRefetch?: ReturnType<typeof useGetServiceRequestRuleActions>["refetch"];
}

export default function AttachmentsContainer({
  // loadingAttachments: Only render the attachments component once the get attachments hook has resolved
  // This prevents some internal state from initializing too early
  loadingAttachments,
  carePathJourneyId,
  serviceRequestIds,
  attachments,
  addAttachmentToServiceRequests,
  possibleAttachmentNudgeReasons = [],
  setFormContent,
  setCanContinue,
  requestorFormAuthorized,
  workflowId,
  authFlowType,
  serviceRequests,
  setRemovedAttachments,
  srLoadingActions,
  srRuleActions,
  srRuleActionsRefetch,
  ...requestorProps
}: Props) {
  const containerClasses = useMuiContainerStyles();
  const { spacing } = useTheme();
  const addReasonToPossibleAttachmentNudgeReasons = async (
    object: PossibleAttachmentNudgeReasons,
    isChecked: boolean,
    childId?: string
  ) => {
    if (isChecked) {
      if (possibleAttachmentNudgeReasons?.length === 0) {
        setFormContent((previousFormContent) => {
          return {
            ...previousFormContent,
            possibleAttachmentNudgeReasons: [object],
          };
        });
      } else {
        setFormContent((previousFormContent) => {
          let current = possibleAttachmentNudgeReasons[0];
          current.reasonText = object.reasonText;
          return {
            ...previousFormContent,
            possibleAttachmentNudgeReasons: [current],
          };
        });
      }
    } else {
      setFormContent((previousFormContent) => {
        return {
          ...previousFormContent,
          possibleAttachmentNudgeReasons: [],
        };
      });
    }
  };

  const afterUpload = (response: Attachment) => {
    addAttachmentToServiceRequests(response);
  };

  useEffect(() => {
    if (srLoadingActions) {
      setCanContinue(false);
    } else {
      setCanContinue(true);
    }
  }, [srLoadingActions, setCanContinue]);

  useEffect(() => {
    if (srRuleActionsRefetch) {
      srRuleActionsRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recommendedChangeActions = srRuleActions?.filter((ruleData) => isRecommendChangeAction(ruleData));

  return (
    <Container classes={containerClasses} maxWidth="lg">
      {requestorFormAuthorized && (
        <div style={{ paddingTop: spacing(5) }}>
          <RequestorCard {...requestorProps} />
        </div>
      )}
      <div style={{ marginTop: requestorFormAuthorized ? spacing(1) : spacing(5) }}>
        {!loadingAttachments && (
          <>
            <AddAttachmentsCard
              carePathJourneyId={carePathJourneyId || ""}
              serviceRequestIds={serviceRequestIds}
              workflowId={workflowId}
              initialAttachments={removeEmptyAttachments(attachments)}
              setAttachmentsCompleted={setCanContinue}
              afterUploadAttachments={afterUpload}
              allowMultipleAttachmentFiles={authFlowType === "MEDICAL"}
              fileSizeLimit={authFlowType === "PHARMACY" ? MAX_FILE_SIZE_AGADIA : undefined}
              setRemovedAttachments={setRemovedAttachments}
            />
            {!srLoadingActions && recommendedChangeActions && recommendedChangeActions.length > 0 && (
              <>
                {recommendedChangeActions.map((ruleData) => (
                  <ImpressionTracking
                    key={ruleData?.ruleId}
                    event="REQUIRED_DOCS_NUDGE"
                    stage="ADD_ATTACHMENTS"
                    interactionAccept={ruleData && isRecommendChangeAction(ruleData)}
                    activityContext={{
                      ...activityContextFromServiceRequest(serviceRequests[0]),
                      ruleId: ruleData?.ruleId,
                      workflowId: workflowId,
                    }}
                  >
                    <>
                      {ruleData && isRecommendChangeAction(ruleData) ? (
                        <RequiredDocsNudge
                          nudgeReasonText={ruleData?.message}
                          nudgeReasonID={ruleData?.ruleId}
                          nudgeReasonTitle={ruleData?.title}
                          existingNudgeReason={possibleAttachmentNudgeReasons?.[0]?.reasonText}
                          reasonAlreadyExists={Boolean(possibleAttachmentNudgeReasons?.[0]?.ruleId)}
                          setReasons={addReasonToPossibleAttachmentNudgeReasons}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  </ImpressionTracking>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </Container>
  );
}
